import * as ActionTypes from '../actions/index';
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';


const defaultState = fromJS({
    fetching: false,
    activeMultipliers:[],
    responseMessage:'',
    validationMessage:'',
    formData: fromJS({
        title: '', 
        targetGroup: '', 
        startDate: '', 
        endDate: '', 
        startTime:'',
        endTime:'',
        creationDate: '', 
        multiplierValue:'',
        selectedCategory:'',
        selectedProduct:'',
        allProducts:false,
        allDay:false,
      }),
    
});

function changePointsMultiplierFormSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}
function changePointsMultiplierFormData(state, action) {
    return state.setIn(["formData", action.payload.key], action.payload.value);
  }

  function createPointsMultiplier(state, action) {
    return state.set("fetching", true);
  }
  
  function createPointsMultiplierSuccess(state, action) {
    return (
      state
      .set("responseMessage", 'Multiplier was created successfully')
      .set("fetching", false)
    );
  
  }
  function createPointsMultiplierFail(state, action) {
    let msg = "";
   try {
    msg = action.payload.response.body.message;
   } catch (e) {
     msg = "Failed to create multiplier.";
   }
    return state
    .set("responseMessage", msg)
      .set("fetching", false);
  }

  function deletePointMultiplierById(state,action){
    return state
        .set('fetching', true)
}
function deletePointMultiplierByIdSuccess(state,action){
    return state
        .set('responseMessage','Multiplier deleted successfully')
        .set('fetching',false)
}

function deletePointMultiplierByIdFailed(state, action) {
    let errorMessage = null;
    try {
        errorMessage = action.payload.response.body.error.message
    } catch (e) {
        errorMessage = 'Failed to delete multiplier'
    }

    return state
    .set("responseMessage", errorMessage)
        .set('fetching', false)
}
function editPointMultiplierById(state, action) {
  return state
      .set('fetching', true);
}
function editPointMultiplierByIdSuccess(state, action) {
  return state
    .set('fetching', false)
    .set('responseMessage','Multiplier updated successfully')
    //.set("formData", fromJS(defaultState.get("formData")));
}
function editPointMultiplierByIdFailed(state, action) {
  return state
      .set('fetching', false);
}
function resetState(state, action) {
    return defaultState
}


export const pointsMultiplierForm = createReducer(defaultState, {
    [ActionTypes.CHANGE_POINT_MULTIPLIER_FORM_DATA]:changePointsMultiplierFormData,
    [ActionTypes.CREATE_POINTS_MULTIPLIER]:createPointsMultiplier,
    [ActionTypes.CREATE_POINTS_MULTIPLIER_SUCCESS]:createPointsMultiplierSuccess,
    [ActionTypes.CREATE_POINTS_MULTIPLIER_FAIL]:createPointsMultiplierFail,
    [ActionTypes.RESET_MULTIPLIERS_NEW_STATE]: resetState,
    [ActionTypes.CHANGE_POINTS_MULTIPLIER_FORM_SETTINGS]: changePointsMultiplierFormSettings,
    [ActionTypes.DELETE_POINT_MULTIPLIER]:deletePointMultiplierById,
    [ActionTypes.DELETE_POINT_MULTIPLIER_SUCCESS]:deletePointMultiplierByIdSuccess,
    [ActionTypes.DELETE_POINT_MULTIPLIER_FAIL]:deletePointMultiplierByIdFailed,
    [ActionTypes.EDIT_POINT_MULTIPLIER]:editPointMultiplierById,
    [ActionTypes.EDIT_POINT_MULTIPLIER_SUCCESS]:editPointMultiplierByIdSuccess,
    [ActionTypes.EDIT_POINT_MULTIPLIER_FAIL]:editPointMultiplierByIdFailed


});
