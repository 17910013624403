import * as ActionTypes from '../actions/index';
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    fetching: false,
    orders: [],
    totalOrders: '',
    fetchingTotalOrders: false,
    rowsPerPage: 10,
    page: 1,
    posting: false,
    deleting: false,
    order: 'desc',
    orderBy: 0, //table header array index that does the mapping of column name and its key for sort
    totalXpOrders: '',
    countingXpOrders: false
});

function deleteOrder(state, action) {
    return state
        .set('deleting', true);
}

function deleteOrderSuccess(state, action) {
    return state
        .set('deleting', false);
}

function deleteOrderFail(state, action) {
    return state
        .set('deleting', false);
}

function createNewOrder(state, action) {
    return state
        .set('posting', true);
}

function receiveCreateNewOrderResponse(state, action) {
    return state
        .set('posting', false);
}

function failedToCreateNewOrder(state, action) {
    return state
        .set('posting', false);
}

function changeOrdersStoreSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function getTotalOrders(state, action) {
    return state
        .set('fetchingTotalOrders', true);
}

function receiveTotalOrders(state, action) {
    return state
        .set('fetchingTotalOrders', false)
        .set('totalOrders', action.payload.body.count);
}

function failedToGetTotalOrders(state, action) {
    return state
        .set('fetchingTotalOrders', false);
}

function getOrders(state, action) {
    return state
        .set('fetching', true);
}

function receiveOrders(state, action) {
    return state
        .set('fetching', false)
        .set('orders', fromJS(action.payload.body));
}

function failedToGetOrders(state, action) {
    return state
        .set('fetching', false);
}

function logOff(state, action) {
    return defaultState;
}

function changeOrdersSortOrder(state, action) {
    return state
        .set('order', action.payload.order)
        .set('orderBy', action.payload.orderBy)
}

function resetState(state, action) {
    return defaultState;
}

function countXpOrders(state, action) {
    return state
        .set('countingXpOrders', true)
}

function countXpOrdersSuccess(state, action) {
    return state
        .set('countingXpOrders', false)
        .set('totalXpOrders', action.payload.body)
}

function countXpOrdersFailed(state, action) {
    return state
        .set('countingXpOrders', false)
}

export const orders = createReducer(defaultState, {
    [ActionTypes.DELETE_ORDER]: deleteOrder,
    [ActionTypes.DELETE_ORDER_SUCCESS]: deleteOrderSuccess,
    [ActionTypes.DELETE_ORDER_FAIL]: deleteOrderFail,
    [ActionTypes.CREATE_NEW_ORDER]: createNewOrder,
    [ActionTypes.RECEIVE_CREATE_NEW_ORDER_RESPONSE]: receiveCreateNewOrderResponse,
    [ActionTypes.FAILED_TO_CREATE_NEW_ORDER]: failedToCreateNewOrder,
    [ActionTypes.CHANGE_ORDERS_STORE_SETTINGS]: changeOrdersStoreSettings,
    [ActionTypes.GET_TOTAL_ORDERS]: getTotalOrders,
    [ActionTypes.RECEIVE_TOTAL_ORDERS]: receiveTotalOrders,
    [ActionTypes.FAILED_TO_GET_TOTAL_ORDERS]: failedToGetTotalOrders,
    [ActionTypes.GET_ORDERS]: getOrders,
    [ActionTypes.RECEIVE_ORDERS]: receiveOrders,
    [ActionTypes.FAILED_TO_GET_ORDERS]: failedToGetOrders,
    [ActionTypes.CHANGE_ORDERS_SORT_ORDER]: changeOrdersSortOrder,
    [ActionTypes.LOG_OFF]: logOff,
    [ActionTypes.RESET_ORDERS_STATE]: resetState,
    [ActionTypes.COUNT_XP_ORDERS]: countXpOrders,
    [ActionTypes.COUNT_XP_ORDERS_SUCCESS]: countXpOrdersSuccess,
    [ActionTypes.COUNT_XP_ORDERS_FAILED]: countXpOrdersFailed,
});
