import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import StoreFormComponent from "../../components/Stores/StoreFormComponent";
import {
    addingStore, changeNewStore, changeSelectedStore, changeStoreSettings, editingStore, gettingAllStores,
    requestStoreById,
    showStore, toDeleteStore
} from "../../actions/stores";
import _ from 'lodash';
import {isPhone} from "../../helpers/functions";
import {parseLogin, parseStores} from "../../helpers/parseImmutables";
import {useHistory, useLocation} from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";

//initialization for the search box component
const refs = {};

const StoreFormHOC = props => {
    const location = useLocation();
    const history = useHistory();
    const prevDeleting = usePrevious(props.stores.deleting);

    useEffect(() => {
        if(prevDeleting && !props.stores.deleting && !props.stores.sendResponse){
            history.goBack();
        }
    }, [props.stores.deleting, prevDeleting, props.stores.sendResponse, history])


    useEffect(() => {
        if(location.pathname.includes('edit')){
            props.requestStoreById(_.last(location.pathname.split('/')), props.login.userInfo.token);
        }
    }, [])

    const handleDragEnd = (position) => {
        if(location.pathname.includes('edit')){
            props.savedStore('position', {
                lat: position.latLng.lat(),
                lng: position.latLng.lng()
            })
        }
        else {
            props.temp_new_store('position', {
                lat: position.latLng.lat(),
                lng: position.latLng.lng()
            })
        }
    };

    const handleStoreInputChange = (evt) => {
        var target = evt.target;
        var value = target.value;
        var name = target.name;
        if (location.pathname.includes("edit")) {
            props.savedStore(name, value);
        }
        else if (location.pathname.includes("add")) {
            props.temp_new_store(name, value);
        }
    };

    const handleStoreSubmit = (evt) => {
        if (evt.key === "Enter" || typeof(evt.key) === "undefined") {
            evt.preventDefault();
            if (location.pathname.includes("edit")) {
                if(!props.stores.changedStore.location){
                    props.changeStoreSettings('sendResponse', 'Please fill the address before submit.');
                    return 0;
                }
                if(!isPhone(props.stores.changedStore.telephone)){
                    props.changeStoreSettings('sendResponse', 'Invalid telephone. Add a 10 digit telephone number.');
                    return 0;
                }
                props.submitChangedStore(props.stores.changedStore.id, {...props.stores.changedStore, token: props.login.userInfo.token});
            }
            else if (location.pathname.includes("add")) {
                if(!props.stores.newstore.location){
                    props.changeStoreSettings('sendResponse', 'Please fill the address before submit.');
                    return 0;
                }
                if(!isPhone(props.stores.newstore.telephone)){
                    props.changeStoreSettings('sendResponse', 'Invalid telephone. Add a 10 digit telephone number.')
                    return 0;
                }
                props.new_store(props.login.userInfo.brand.id, props.stores.newstore, props.login.userInfo.token);
            }
        }
    };

    const getStoreArray = () => {
        let optionValues = [];
        for (let i in props.stores.all) {
            if (props.stores.all[i].brid === props.login.userInfo.brid) {
                optionValues.push({key: i, value: props.stores.all[i].storeName})
            }
        }
        return optionValues;
    };

    const handleModifyStoreForm = (evt) => {
        props.printStore(evt.target.value, props.stores.all[evt.target.value]);
    };

    const handleDelete = () => {
        props.deleteStore(props.stores.changedStore.id, props.login.userInfo.token)
    };

    const onPlacesChanged = (places) => {
        let x = refs.searchBox.getPlaces();
        let position  = {};
        let address = "" ;
        try {
            position = {
                lng: x[0].geometry.location.lng(),
                lat: x[0].geometry.location.lat(),
            };
            address = x[0].formatted_address;
        }
        catch (e){
            position = {};
            address = "";
        }
        if(location.pathname.includes('edit')){
            props.savedStore('position', position);
            props.savedStore('location', address)
        }
        else {
            props.temp_new_store('position', position);
            props.temp_new_store('location', address)
        }
    };

    const handleCoordinates = (coordinates) => {
        try {
            if(location.pathname.includes('edit')){
                props.savedStore('location', coordinates[0].formatted_address)
            }
            else {
                props.temp_new_store('location', coordinates[0].formatted_address)
            }

        }
        catch (e){

        }
    };

    return (
        <StoreFormComponent
            handleCoordinates={handleCoordinates}
            refs={refs}
            onPlacesChanged={onPlacesChanged}
            handleDragEnd={handleDragEnd}
            handleDelete={handleDelete}
            storesSettings={props.stores}
            changeStoreSettings={props.changeStoreSettings}
            showStore={props.stores.showStore}
            selectedStore={props.stores.selectedStore}
            handleModifyStoreForm={handleModifyStoreForm}
            stores={getStoreArray()}
            isNew={location.pathname.includes('edit')?false:true}
            handleStoreSubmit={handleStoreSubmit}
            handleStoreInputChange={handleStoreInputChange}
            store={location.pathname.includes('edit')?props.stores.changedStore:props.stores.newstore}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
        stores: parseStores(state.stores),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        savedStore: (storeKey, storeValue) => {
            dispatch(changeSelectedStore(storeKey, storeValue))
        },
        temp_new_store: (storeKey, storeValue) => {
            dispatch(changeNewStore(storeKey, storeValue))
        },
        submitChangedStore: (id, store) => {
            dispatch(editingStore(id, store))
        },
        new_store: (storeBrand, store, token) => {
            dispatch(addingStore(storeBrand, store, token))
        },
        printStore: (id, store) => {
            dispatch(showStore(id, store))
        },
        getStores: () => {
            dispatch(gettingAllStores())
        },
        changeStoreSettings: (key, value) => {
            dispatch(changeStoreSettings(key, value))
        },
        deleteStore: (storeId, token) => {
            dispatch(toDeleteStore(storeId, token))
        },
        requestStoreById: (storeId, token) => {
            dispatch(requestStoreById(storeId, token))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreFormHOC);

