import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const SelectComp = (props) => {

    return (
        <FormControl fullWidth={props.fullWidth} margin={'normal'}>
            <InputLabel >{props.Label}</InputLabel>
            <Select
                onChange={(event)=>props.PropHandleInputChange(props.Name,event.target.value)}
                disabled={props.disabled}
                multiple={props.multiple}
                required={props.Required}
                value={props.TargetObj}
                name={props.Name}
            >
                {props.Values.map(
                    (Val) =>
                        <MenuItem value={Val.key} key={Val.key} >
                            {Val.value}
                        </MenuItem>
                )
                }
            </Select>

        </FormControl>
    );
};

SelectComp.propTypes = {
    multiple: PropTypes.bool,
    Required: PropTypes.bool,
    Label: PropTypes.string,
    MenuName: PropTypes.string,
    Name: PropTypes.string.isRequired,
    Values: PropTypes.array.isRequired,
    PropHandleInputChange: PropTypes.func.isRequired
};

export default SelectComp;
