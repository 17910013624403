import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function setCampaignOverviewActiveTab(payload) {
    return {
        type: ActionTypes.SET_CAMPAIGN_OVERVIEW_ACTIVE_TAB,
        payload: payload
    }
}

export function getCampaignById(campaignId, token) {
    return {
        type: ActionTypes.GET_CAMPAIGN_BY_ID,
        payload: {
            campaignId,
            token
        }
    }
}

export function getCampaignPlanById(campaignPlanId, token){
    return {
        type:ActionTypes.GET_CAMPAIGN_PLAN_BY_ID,
        payload:{
            campaignPlanId,
            token
        }
    }
}



//action that receives Campaign by id
export function receiveCampaignById(campaign) {
    return {
        type: ActionTypes.RECEIVE_CAMPAIGN_BY_ID,
        payload: campaign
    }
}

export function receiveCampaignPlanById(campaignPlan) {
    return {
        type: ActionTypes.RECEIVE_CAMPAIGN_PLAN_BY_ID,
        payload: campaignPlan
    }
}

//action that receives failed response from getting the Campaign by id
export function failedToGetCampaignById(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_CAMPAIGN_BY_ID,
        payload: response
    }
}


export function failedToGetCampaignPlanById(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_CAMPAIGN_BY_ID,
        payload: response
    }
}


export function deleteCampaignById(campaignId,token) {
    return {
        type: ActionTypes.DELETE_CAMPAIGN_BY_ID,
        payload:  {
            campaignId,
            token
        }
    }
}

export function deleteCampaignByIdSuccess(payload) {
    return {
        type: ActionTypes.DELETE_CAMPAIGN_BY_ID_SUCCESS,
        payload: payload
    }
}

export function deleteCampaignByIdFailed(payload) {
    return {
        type: ActionTypes.DELETE_CAMPAIGN_BY_ID_FAILED,
        payload: payload
    }
}
export function getActionById(actionId, token){
    return {
        type: ActionTypes.GET_ACTION_BY_ID,
        payload: {
            actionId,
            token
        }
    }
}

export function receiveActionById(action){
    return {
        type: ActionTypes.RECEIVE_ACTION_BY_ID,
        payload: action
    }
}

export function getActionByIdFailed(payload) {
    return {
        type: ActionTypes.GET_ACTION_BY_ID_FAILED,
        payload: payload
    }
}

export function resetState(){
    return {
        type: ActionTypes.OVERVIEW_RESET_STATE,
        payload: null
    }
}
export function getActionStatusById(messageId, token) {
    return {
        type: ActionTypes.GET_ACTION_STATUS_BY_ID,
        payload: {
            messageId,
            token
        }
    }
}
export function receiveActionStatusById(status) {
    return {
        type: ActionTypes.RECEIVE_ACTION_STATUS_BY_ID,
        payload: status
    }
}
export function failedToGetActionStatusById(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_ACTION_STATUS_BY_ID,
        payload: response
    }
}

export function deleteActionById(actionId) {
    return {
        type: ActionTypes.DELETE_ACTION_BY_ID,
        payload:  {
            actionId
        }
    }
}

export function deleteActionByIdSuccess(payload) {
    return {
        type: ActionTypes.DELETE_ACTION_BY_ID_SUCCESS,
        payload: payload
    }
}

export function deleteActionByIdFailed(payload) {
    return {
        type: ActionTypes.DELETE_ACTION_BY_ID_FAILED,
        payload: payload
    }
}
