const Colors = {
    COFFEE_ISLAND_YELLOW: '#f1cb42',
    COFFEE_BROWN: '#794518',
    DARK_GREY: '#3b3b3e',
    TEXT_COLOUR: '#717171',
    ORANGE: '#FFC325',
    BROWN: '#603100',
    BLACK: 'black',
    VALIDATION_RED: 'red',
    BARISTA_VALIDATION_RED: '#890002',
    DARK_TEXT_COLOR: '#000000',
    LIGHT_GREY_BORDER: '#d2d2d2',
    WHITE: '#ffffff',
    GREY_BLACK: '#2E2D2F',
    INPUT_UNDERLINE_GREY: '#a6a6a6',
    ALMOST_WHITE: '#fbfbfb',
    LIGHT_YELLOW_BACKGROUND: '#fff8eb',
    FOOTER_YELLOW: '#ffc325',
    HEADER_BACKGROUND: '#fff2d8',
    GREEN: 'green',
    GREY: 'grey',
    GOOGLE_BUTTON: '#C74636',
    FACEBOOK_BUTTON: '#44599C',
    CART_CIRCLE_RED: '#794400',
    CART_ITEM_ODD_ITEM_CREME: '#FDF8E6',
    CART_ITEM_EVEN_ITEM_CREME: '#F0E0BD',
    BENCH_BORDER_COLOUR: '#3D3D3E',
    MAIN_BODY_COLOR: '#f4f3f1',
};

export default Colors;