import React from 'react';
import moment from 'moment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Subject from "@material-ui/icons/Subject";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Store from "@material-ui/icons/Store";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from '@material-ui/core/Dialog';
import TabContainer from "../General/tabContainer";
import TableMUI from '@material-ui/core/Table';
import Table from '../General/table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {isAdmin} from "../../helpers/functions";

const OrderOverview = (props) => {
    return (
        <Paper>

            <Dialog
                open={props.isDeleteModalOpen}
                onClose={() => props._closeOrderDeleteModal()}
            >
                <DialogTitle>{"Delete Order"}</DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        Are you sure you want to delete the selected order?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={() => props._deleteOrder(props.order.id)} color="primary" autoFocus>
                        Delete
                    </Button>
                    <Button onClick={() => props._closeOrderDeleteModal()}
                            color="secondary">Cancel</Button>
                </DialogActions>
            </Dialog>
            <Tabs
                value={props.activeTab}
                onChange={(event, value) => props.setActiveTab({activeTab: value})}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab
                    icon={<Subject/>}
                    label="Overview"
                />
                <Tab
                    icon={<AccountCircle/>}
                    label="Client"
                />
                <Tab
                    icon={<AccountCircle/>}
                    label="teller"
                />
                <Tab
                    icon={<ShoppingCart/>}
                    label="Products"
                />
                <Tab
                    icon={<Store/>}
                    label="Store"
                />
            </Tabs>
            { props.activeTab === 0 && (
                <TabContainer>
                    <div className={'col col-md-4'}>
                        <TableMUI>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Date'}
                                    </TableCell>
                                    <TableCell>
                                        {moment(props.order.date).format('DD/MM/YYYY, h:mm:ss a')}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {"Total price"}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.totalValue + ' €'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {"Total points"}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.totalPoints}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {"Client"}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.mobilePerson.email}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {"Teller"}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.teller.email}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {"Store"}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.store.name}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </TableMUI>
                        {isAdmin(props.userRole) && (
                            <Button
                                className={'mt-3'}
                                onClick={() => props._openOrderDeleteModal()}
                                color="primary"
                                variant="contained"
                            >Delete</Button>
                        )}
                    </div>
                </TabContainer>
            )}
            { props.activeTab === 1 && (
                <TabContainer>
                    <div className={'col col-md-4'}>
                        <TableMUI>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Username'}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.mobilePerson.username}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Email'}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.mobilePerson.email}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'First Name'}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.mobilePerson.firstName}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Last Name'}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.mobilePerson.lastName}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Country'}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.mobilePerson.country}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Address'}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.mobilePerson.address}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Telephone'}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.mobilePerson.telephone}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Points'}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.mobilePerson.points}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </TableMUI>
                        <Button
                            className={'mt-3'}
                            onClick={() => props.changeRoute('/clients/' + props.order.mobilePerson.id)}
                            color="primary"
                            variant="contained"
                        >View</Button>
                    </div>
                </TabContainer>
            )}
            { props.activeTab === 2 && (
                <TabContainer>
                    <div className={'col col-md-4'}>
                        <TableMUI>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Username'}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.teller.username}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Email'}
                                    </TableCell>
                                    <TableCell>
                                        {props.order.teller.email}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Stores'}
                                    </TableCell>
                                    <TableCell>
                                        {props.tellerStores}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </TableMUI>
                    </div>
                </TabContainer>
            )}
            { props.activeTab === 3 && (
                <TabContainer>
                    <Table
                        handleRowSelect={props.handleOrderProductsRowSelect}
                        label="Product"
                        rows={props.productRows}
                        columns={props.productTableheaders}
                        fixedHeader={true}
                        selectable={false}
                        multiSelectable={false}
                        displaySelectAll={false}
                        showCheckboxes={false}
                        showRowHover={true}
                        clickableRows={isAdmin(props.userRole)}
                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        handleRowsPerPage={props.handleRowsPerPage}
                        handlePageChange={props.handlePageChange}
                        totalRows={props.totalRows}
                    />
                </TabContainer>
            )}
            { props.activeTab === 4&& (
                <TabContainer>
                    <div className={'col col-md-4'}>
                        <TableMUI>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Name'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {props.order.store.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Address'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {props.order.store.location}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Telephone'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {props.order.store.telephone}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {'Telephone'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {props.order.store.telephone}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </TableMUI>
                        {isAdmin(props.userRole) && (
                            <Button
                                className={'mt-3'}
                                onClick={() => props.changeRoute('/store/edit/' + props.order.store.id)}
                                color="primary"
                                variant="contained"
                            >Edit</Button>
                        )}
                    </div>
                </TabContainer>
            )}
        </Paper>
    )
};

export default OrderOverview;