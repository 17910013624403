import * as actions from '../actions/statistics';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";
import sampleCombine from "xstream/extra/sampleCombine";

export function requestEnabledOffersCompareStatistics(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_ENABLED_OFFERS_COMPARE_STATISTICS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/enabledOffers/statistics?' +
                'filter=' + JSON.stringify(action.payload.filter),
            category: 'requestEnabledOffersCompareStatistics',
            method: 'GET',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestEnabledOffersCompareStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getEnabledOffersCompareStatisticsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestEnabledOffersCompareStatisticsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestEnabledOffersCompareStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getEnabledOffersCompareStatisticsFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestOrdersCompareStatistics(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_ORDERS_COMPARE_STATISTICS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/orders/statistics?' +
                'filter=' + JSON.stringify(action.payload.filter),
            category: 'requestOrdersCompareStatistics',
            method: 'GET',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestOrdersCompareStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getOrdersCompareStatisticsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestOrdersCompareStatisticsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestOrdersCompareStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getOrdersCompareStatisticsFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestClientCompareStatistics(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CLIENT_COMPARE_STATISTICS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/people/clients/statistics?' +
                'filter=' + JSON.stringify(action.payload.filter),
            category: 'requestClientCompareStatistics',
            method: 'GET',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestClientCompareStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getClientCompareStatisticsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestClientCompareStatisticsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestClientCompareStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getClientCompareStatisticsFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestPointsCompareStatistics(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_POINTS_COMPARE_STATISTICS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/points/statistics?' +
                'filter=' + JSON.stringify(action.payload.filter),
            category: 'requestPointsCompareStatistics',
            method: 'GET',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestPointsCompareStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getPointsCompareStatisticsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestPointsCompareStatisticsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestPointsCompareStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getPointsCompareStatisticsFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestXpOrdersStatistics(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_XP_ORDERS_STATISTICS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/orders/experiences/statistics?' +
                'filter=' + JSON.stringify(action.payload.filter),
            category: 'requestXpOrdersStatistics',
            method: 'GET',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestXpOrdersStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getXpOrdersStatisticsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestXpOrdersStatisticsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestXpOrdersStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getXpOrdersStatisticsFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestClientStatistics(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CLIENT_STATISTICS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/people/clients/statistics?' +
                'filter=' + JSON.stringify(action.payload.filter),
            category: 'requestClientStatistics',
            method: 'GET',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestClientStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getClientStatisticsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestClientStatisticsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestClientStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getClientStatisticsFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestEnabledOffersStatistics(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_ENABLED_OFFERS_STATISTICS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/enabledOffers/statistics?' +
                'filter=' + JSON.stringify(action.payload.filter),
            category: 'requestEnabledOffersStatistics',
            method: 'GET',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestEnabledOffersStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getEnabledOffersStatisticsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestEnabledOffersStatisticsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestEnabledOffersStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getEnabledOffersStatisticsFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestPointsStatistics(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_POINTS_STATISTICS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/points/statistics?' +
                'filter=' + JSON.stringify(action.payload.filter),
            category: 'requestPointsStatistics',
            method: 'GET',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestPointsStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getPointsStatisticsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestPointsStatisticsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestPointsStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getPointsStatisticsFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestOrdersStatistics(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_ORDERS_STATISTICS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/orders/statistics?' +
            'filter=' + JSON.stringify(action.payload.filter),
            category: 'requestOrdersStatistics',
            method: 'GET',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestOrdersStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getOrdersStatisticsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestOrdersStatisticsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestOrdersStatistics')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getOrdersStatisticsFailed(response));

    return {
        ACTION: httpResponse$
    }
}
