import React from 'react';
import InputComp from "../InputComp";
import Table from "../General/table";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import People from "@material-ui/icons/People";
import TabContainer from "../General/tabContainer";

const TellerOverview = (props) => {
    return (
        <Paper>
            <Tabs
                value={props.activeTab}
                onChange={(evt, value) => props.setTellerOverviewActiveTab({activeTab: value})}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab
                    icon={<People/>}
                    label="Identity"
                >
                </Tab>

                <Tab
                    icon={<People/>}
                    label="Stores"
                >
                </Tab>
            </Tabs>
            { props.activeTab === 0 && (
                <TabContainer>
                    <div className={'col col-sm-4'}>
                        <form >
                            <InputComp
                                fullWidth={true}
                                margin={'normal'}
                                disabled={true}
                                Type="text"
                                Label="Username"
                                Name="username"
                                Required={true}
                                Value={props.teller.username}
                                Placeholder="username"
                                PropHandleInputChange={()=> null}
                            />
                            <InputComp
                                fullWidth={true}
                                margin={'normal'}
                                disabled={true}
                                Type="text"
                                Label="Email"
                                Name={props.teller.email}
                                Required={true}
                                Value={'mail@isg.gr'}
                                Placeholder="email"
                                PropHandleInputChange={()=> null}
                            />
                        </form>
                    </div>
                </TabContainer>
            )}
            { props.activeTab === 1 && (
                <TabContainer>
                    <Table
                        handleRowSelect={props.handleRowSelect}
                        label="Stores"
                        rows={props.storesTableRows}
                        columns={props.tableColumns}
                        fixedHeader={true}
                        selectable={false}
                        multiSelectable={false}
                        displaySelectAll={false}
                        showCheckboxes={false}
                        showRowHover={true}
                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        handleRowsPerPage={props.handleRowsPerPage}
                        handlePageChange={props.handlePageChange}
                        totalRows={props.totalRows}

                    />
                </TabContainer>
            )}
        </Paper>

    )
};

export default TellerOverview;