import React, {Component, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import Person from "@material-ui/icons/Person";
import Home from "@material-ui/icons/Home";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import Edit from "@material-ui/icons/Edit";
import {isAdmin} from "../helpers/functions";
import {useHistory, useLocation} from "react-router-dom";

const HeaderMenuComponent = props => {
    const [ath, setAuth] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();
    const location = useLocation();

    const handleChange = (event, checked) => {setAuth(checked) };

    const handleMenu = event => {setAnchorEl(event.currentTarget)};

    const handleClose = () => {setAnchorEl(null)};

    const open = Boolean(anchorEl);

    return (
        <div>
            <IconButton
                aria-owns={open ? 'menu-appbar' : null}
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                {
                    (!location.pathname.includes('tellers/add') && isAdmin(props.userRole)) && (
                        <MenuItem onClick={handleClose}>
                            <ListItem  onClick={() => history.push('/tellers/add')} button>
                                <ListItemIcon>
                                    <Person />
                                </ListItemIcon>
                                <ListItemText primary="New Teller" />
                            </ListItem>
                        </MenuItem>
                    )
                }
                {
                    (!location.pathname.includes('/admin/add') && isAdmin(props.userRole)) && (
                        <MenuItem onClick={handleClose}>
                            <ListItem  onClick={() => history.push('/admin/add')} button>
                                <ListItemIcon>
                                    <Person />
                                </ListItemIcon>
                                <ListItemText primary="New Admin" />
                            </ListItem>
                        </MenuItem>
                    )
                }
                {
                    (!location.pathname.includes('/user/edit') && isAdmin(props.userRole)) && (
                        <MenuItem onClick={handleClose}>
                            <ListItem  onClick={() => history.push('/user/edit')} button>
                                <ListItemIcon>
                                    <Edit />
                                </ListItemIcon>
                                <ListItemText primary="Edit User" />
                            </ListItem>
                        </MenuItem>
                    )
                }
                {location.pathname !== '/' && (
                    <MenuItem onClick={handleClose}>
                        <ListItem  onClick={() => history.push('/')} button>
                            <ListItemIcon>
                                <Home />
                            </ListItemIcon>
                            <ListItemText primary="Main Page" />
                        </ListItem>
                    </MenuItem>
                )}
                <MenuItem onClick={handleClose}>
                    <ListItem  onClick={() => { props.signOut(); history.push('/') }  } button>
                        <ListItemIcon>
                            <PowerSettingsNew />
                        </ListItemIcon>
                        <ListItemText primary="Sign Out" />
                    </ListItem>
                </MenuItem>
            </Menu>
        </div>
    )

}

export default HeaderMenuComponent;
