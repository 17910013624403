import React from 'react';
import ContentRouter from "../routers/contentRouter";
import IndicatorHOC from "../containers/General/IndicatorHOC";

const Content = (props) => {
    return (
        <div>
            <ContentRouter/>
            <IndicatorHOC/>
        </div>
    )
};


export default Content;
