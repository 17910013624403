import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import StarsIcon from "@material-ui/icons/Stars";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "../../assets/images/apple.png";
import EventNoteIcon from "@material-ui/icons/EventNote";
import FlagIcon from "@material-ui/icons/Flag";
import TabContainer from "../General/tabContainer";
import CachedIcon from "@material-ui/icons/Cached";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import { Chip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import AlarmOffIcon from "@material-ui/icons/AlarmOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from "react-router-dom";

import DoneIcon from "@material-ui/icons/Done";
import SendIcon from '@material-ui/icons/Send';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
const CampaignOverviewTabs = (props) => {
    const history = useHistory();
    return (
        <div>
        <Tabs
        value={props.campaignNewOverview.activeTab}
        onChange={(evt, value) => props.setCampaignOverviewActiveTab(value)}
        indicatorColor="primary"
        textColor="primary"
        centered
    >
        <Tab icon={<StarsIcon />} label="Overview"></Tab>
        <Tab icon={<EventNoteIcon />} label="Plan"></Tab>
        <Tab icon={<CachedIcon />} label="Progress"></Tab>
        {props.campaignNewOverview.form.status!=0 ? (
           <Button disabled>
                <AlarmOffIcon /> Campaign {props.campaignNewOverview.form.status==3? "finished": "started"}
            </Button>
        ) : (
            <Button
            disabled={(props.campaignNewOverview.fetching ||props.campaignNewOverview.fetchingActions) || 
                props.campaignNewOverview.form.actionIds.length != props.campaignNewOverview.form.campaignActions.length ?true:false }
                onClick={() =>
                    history.push(props.campaignNewOverview.form.id + "/edit")
                }
            >
                <EditIcon />
            </Button>
        )}
    </Tabs>

    {props.campaignNewOverview.fetching ? <CircularProgress /> : null}

    {props.campaignNewOverview.activeTab === 0 && (
        <TabContainer>
            <Card>
                <CardHeader avatar={<FlagIcon />} title="Information">
                    {" "}
                </CardHeader>
                <CardContent>
                    <Typography variant="subheading" align='right'>
                        Created on :{" "}
                        {props.campaignNewOverview.form.creationDate.split("T")[0]}
                        <br></br> Last modified on :{" "}
                        {props.campaignNewOverview.form.modifiedAt.split("T")[0]}
                    </Typography>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subheading"> Title</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subheading">
                                        {" "}
                                        {props.campaignNewOverview.form.title}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subheading"> Description</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subheading">
                                        {" "}
                                        {props.campaignNewOverview.form.description}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subheading"> Image</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                {props.campaignNewOverview.form.image.length==0?<Typography>NO IMAGE</Typography>:
                                    <img
                                        src={`data:image/png;base64,${props.campaignNewOverview.form.image}`}
                                        style={{
                                            maxWidth: 500,
                                            borderRadius: 5,
                                            maxHeight: 400,
                                        }}
                                    ></img>
                                    }

                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Button
                        // disabled={props.userRole !== "admin"}
                        className={"mt-3"}
                        onClick={props.toggleDeleteModal}
                        color="primary"
                        variant="contained"
                    >
                        Delete
                    </Button>
                </CardContent>
            </Card>
        </TabContainer>
    )}
    {props.campaignNewOverview.activeTab === 1 &&
    (props.campaignNewOverview.form.listIds.size === 0 ? (
        <Typography>Something went wrong</Typography>
    ) : (
        <TabContainer>
            <Card>
                <CardHeader avatar={<EventNoteIcon />} title="Plan details" />

                <CardContent>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell scope="row">
                                    <Typography variant="subheading">Start Date</Typography>
                                    <Typography>(YYYY-MM-DD)</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subheading">
                                        {
                                            props.campaignNewOverview.form.startDate.split(
                                                "T"
                                            )[0]
                                        }
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subheading"> End Date </Typography>{" "}
                                    <Typography>(YYYY-MM-DD)</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subheading">
                                        {props.campaignNewOverview.form.endDate.split("T")[0]}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow style={{ maxWidth: 100 }}>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subheading">
                                        {" "}
                                        Clients included
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                        maxWidth: 50,
                                        wordWrap: "normal",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {props.campaignNewOverview.form.listIds.length   }
                                    <Button onClick={props.toggleClientsModal}>
                                        See all clients
                                    </Button>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subheading"> Tags</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {props.campaignNewOverview.form.tags.map((tag) => (
                                        <Chip key={tag.Name} label={tag.Name} style={{ margin: 2 }} />
                                    ))}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <Typography variant="subheading"> Actions </Typography>
                                    <Typography>(click for overview)</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                               { props.campaignNewOverview.fetchingActions || 
                               props.campaignNewOverview.form.actionIds.length != props.campaignNewOverview.form.campaignActions.length? <CircularProgress style={{width:25,height:25}}/> :
                                    props.campaignNewOverview.form.campaignActions.map(
                                        (action,index) => (
                                            
                                            (action.actionType=="Push Notification" ? (
                                            <Button
                                            key={action.id}
                                                onClick={(event) => props.handleViewNotification(action,index)}
                                                style={{maxWidth:220,cursor: 'pointer'}}
                                            >
                                                <Typography variant='caption'>
                                                    <Chip
                                                        label={action.actionType + " " + action.platform}
                                                        style={{ margin: 2,cursor: 'pointer' }}
                                                    />
                                                    on {action.timestamp? moment(moment.utc(action.timestamp).toDate()).local().format('YYYY-MM-DDTHH:mm:ss').split("T")[0] +' at '+moment(moment.utc(action.timestamp).toDate()).local().format('YYYY-MM-DDTHH:mm:ss').split("T")[1]  : ''}
                                                </Typography></Button>)
                                                : action.actionType=="Newsletter"?
                                        
                                                (
                                        <Button
                                        key={action.id}
                                        onClick={(event) => props.handleViewNewsletter(action.htmlContent,index)}
                                        style={{maxWidth:220,cursor: 'pointer'}}
                                    >
                                        <Typography variant='caption'>
                                            <Chip
                                                label={action.actionType}
                                                style={{ margin: 2,cursor: 'pointer' }}
                                            />
                                            <br></br>
                                            on {action.timestamp? moment(moment.utc(action.timestamp).toDate()).local().format('YYYY-MM-DDTHH:mm:ss').split("T")[0] +' at '+moment(moment.utc(action.timestamp).toDate()).local().format('YYYY-MM-DDTHH:mm:ss').split("T")[1]  : ''}
                                        </Typography></Button>
                                        ) 
                                        :null)
                                    ))}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </TabContainer>
    ))}
    {props.campaignNewOverview.activeTab === 2 && (
        <TabContainer>
            <Card>
                <CardHeader avatar={<CachedIcon />} title="Campaign Progress" />

                <CardContent>
                    <Button
                        onClick={props.handleActionStatusRequest}
                        style={{ backgroundColor: "#F1CB42" }}
                       disabled= {(props.campaignNewOverview.fetching ||props.campaignNewOverview.fetchingActions)?true:false }
                    >
                        Check for updates
                    </Button>
                    {/* } */}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{"Action/Event"}</TableCell>
                                <TableCell>
                                    
                                <Tooltip title="Received" placement="top-start"> 
                                <IconButton >
                                 <DoneIcon  />
                                </IconButton>
                               </Tooltip>


                               <Tooltip title="Sent" placement="top-start"> 
                                <IconButton>
                                <SendIcon/>
                                </IconButton>
                               </Tooltip>

                               <Tooltip title="Remaining" placement="top-start"> 
                                <IconButton >
                                <QueryBuilderIcon/>
                                </IconButton>
                               </Tooltip>
                                    
                                </TableCell>
                                <TableCell>
                                    <QueryBuilderIcon />
                                    
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {props.campaignNewOverview.form.actionStatus.some(a=>a!=null) //&& props.campaignNewOverview.form.campaignActions
                                ? props.campaignNewOverview.form.actionStatus.map(
                                    (report,index) => report.map(status=>
                                        <TableRow>
                                            <TableCell>
                                   <Typography>    
                                    { status.isAndroid?<AndroidIcon/>:status.isIos? <img src={AppleIcon} style={{ maxWidth: 24 }} />: <AnnouncementIcon/>} 
                                    {"Send Push notification "}
                                     </Typography>  
                                             
                                            </TableCell>
                                            <TableCell  >
                                            <Typography style={{marginLeft:40}} > 
                                            {status.received==null?'0':status.received}
                                                /
                                                {status.successful}
                                                /
                                                {status.remaining==null?'0':status.remaining}
                                                </Typography>  

                                            </TableCell>
                                            <TableCell>
                                            <Typography> 
                                            {status.completed_at!=null?
                                                moment
                                                    .unix(status.completed_at)
                                                    .format("DD.MMM.YYYY hh:mm:ss")
                                                :"Queued"}
                                                     </Typography>  
                                            </TableCell>
                                            {/* <TableCell><Typography> {report.received}</Typography></TableCell> */}
                                        </TableRow>
                                    )
                                )
                                :
                                <Typography variant="button">NO UPDATES</Typography>
                                }
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </TabContainer>
    )}
    </div>
    )
}
export default CampaignOverviewTabs;
