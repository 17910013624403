import React from 'react';
import InputComp from '../InputComp';
import SelectComp from '../SelectComp';
import {BASE_URL} from "../../helpers/variables";
import Chip from '@material-ui/core/Chip';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from "@material-ui/core/IconButton";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CheckBoxComp from "../CheckBoxComp";

const styles = {
    button: {
        margin: 12,
    },
    image: {
    },
    exampleImageInput: {
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: '100%',
        opacity: 0,
    },
};

const ProductsFormComponent = (props) => {
    const { classes } = props;
    return (
        <div>
            <Dialog
                open={props.productsStore.isDeleteModalOpen}
                onClose={() => props.changeStoreSettings('isDeleteModalOpen', !props.productsStore.isDeleteModalOpen)}
            >
                <DialogTitle>{"Delete Product"}</DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        Are you sure you want to delete the selected product?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={props.handleDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                    <Button onClick={() =>
                        props.changeStoreSettings('isDeleteModalOpen', !props.productsStore.isDeleteModalOpen)}
                            color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                title="image"
                open={props.productsStore.isImageModalOpen}
                onClose={() => props.changeStoreSettings('isImageModalOpen', false)}
            >
                <DialogTitle>{"Image"}</DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        <img src={props.product.attachment.base64?props.product.attachment.base64: (BASE_URL +
                            'api/attachments/images/download/' + props.product.imageName)}
                             className={'img-fluid'}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={() => props.changeStoreSettings('isImageModalOpen', false)}
                             color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Card>
                <CardHeader
                    avatar={
                        <IconButton>
                            <ShoppingBasket />
                        </IconButton>
                    }
                    title={props.isNew?'Create Product':'Edit Product'}
                />
                <CardContent>
                    <div className="col col-lg-4 offset-lg-3 ">
                        <form id="form-product" onSubmit={props.handleSubmit}>
                            <InputComp Required={true} Placeholder="Product name" Type="text"
                                       fullWidth={true} margin={'normal'}
                                       Value={props.product.product_name} Name="product_name" Label="Name"
                                       PropHandleInputChange={props.handleTextInputChange}/>
                            <InputComp Required={true} Min={0.01} Placeholder="Product value" Type="number"
                                       fullWidth={true} margin={'normal'}
                                       Value={props.product.value} Step="0.01" Name="value" Label="Value"
                                       PropHandleInputChange={props.handleTextInputChange}/>
                            <InputComp Required={true} Placeholder="Product points" Type="number"
                                       fullWidth={true} margin={'normal'}
                                       Value={props.product.points} Step="1" Name="points" Label="Points"
                                       PropHandleInputChange={props.handleTextInputChange}/>
                            <InputComp Placeholder="Product description" Type="text" Value={props.product.description}
                                       fullWidth={true} margin={'normal'}
                                       Name="description" Label="Description"
                                       PropHandleInputChange={props.handleTextInputChange}/>
                            <SelectComp Required={false} Values={props.allCategories.map(item => {return {key: item.id, value: item.name}})} Label="Category"
                                        fullWidth={true} margin={'normal'}
                                        TargetObj={props.selectedMenuItem?parseInt(props.selectedMenuItem):''} Name="cid" MenuName="Select Category"
                                        PropHandleInputChange={props.handleInputChange}/>
                            <InputComp Required={false}
                                       fullWidth={true} margin={'normal'}
                                       Placeholder="format:dd-mm-yyyy hh:mm" Type="date" Name="startDate"
                                       Label="Start Date" Value={props.product.startDate}
                                       PropHandleInputChange={props.handleInputChange}/>
                            <InputComp Required={false}
                                       fullWidth={true} margin={'normal'}
                                       Placeholder="format:dd-mm-yyyy hh:mm" Type="date" Name="endDate"
                                       Label="End Date" Value={props.product.endDate}
                                       PropHandleInputChange={props.handleInputChange}/>
                            <InputComp Placeholder="Experience type" Type="text" Value={props.product.xpType}
                                       fullWidth={true} margin={'normal'}
                                       Name="xpType" Label="Experience type"
                                       PropHandleInputChange={props.handleTextInputChange}/>
                            <InputComp Required={false} Placeholder="Erp Id" Type="number"
                                       fullWidth={true} margin={'normal'}
                                       Value={props.product.erpId} Step="1" Name="erpId" Label="Erp Id"
                                       PropHandleInputChange={props.handleTextInputChange}/>
                            <CheckBoxComp
                                Checked={props.product.pointsApplyOnce}
                                Type="checkbox"
                                Name="pointsApplyOnce"
                                Label="Apply points only once" PropHandleInputChange={(name, checked) => {
                                    props.handleInputChange(name, checked)
                                }}
                            />
                            <CheckBoxComp
                                Checked={props.product.xp}
                                Name="xp"
                                Type="checkbox"
                                Label="Experience" PropHandleInputChange={(name, checked) => {
                                    props.handleInputChange(name, checked)
                                }}
                            />
                            <Button variant="contained" color="default" className={classes.button}>
                                Upload image
                                <CloudUploadIcon className={classes.rightIcon} />
                                <input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(evt) => props.handleImageInput(evt)} style={styles.exampleImageInput} />
                            </Button>
                        </form>
                        {
                            props.product.attachment.name || props.product.imageName?
                                <Chip
                                    label={props.product.attachment.name || props.product.imageName}
                                    onDelete={props.removeAttachment}
                                    onClick={()=>props.changeStoreSettings('isImageModalOpen', true)}
                                />
                                :
                                null
                        }
                    </div>
                </CardContent>
                <CardActions>
                    <Button
                        color="primary"
                        variant="contained"
                        form="form-product"
                        type="submit">{props.isNew?'Create':'Edit'}</Button>
                    {
                        !props.isNew && (
                            <Button
                                onClick={() => props.changeStoreSettings('isDeleteModalOpen', true)}
                                color="secondary"
                                variant="contained"
                            >Delete</Button>
                        )
                    }
                </CardActions>
            </Card>
            <Snackbar
                open={props.productsStore.sendResponse?true:false}
                message={props.productsStore.sendResponse}
                autoHideDuration={6000}
                onClose={() => {props.changeStoreSettings('sendResponse', '')}}
            />
        </div>
    )
};

export default  withStyles(styles)(ProductsFormComponent) ;
