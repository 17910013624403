import * as actions from '../actions/clients';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import flattenSequentially from "xstream/extra/flattenSequentially";
import {BASE_URL} from "../helpers/variables";
import {receiveTotalClients} from "../actions/clients";
import {failedToGetTotalClients} from "../actions/clients";
import { _getClientsSuggestions } from "../helpers/functions";
import {deleteClientByIdFailed} from "../actions/clients";

export function requestDeleteClientById(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.DELETE_CLIENT_BY_ID)
        .map(action => ({
            url: BASE_URL + 'api/people/clients/' + action.payload.clientId + '/hard' +
                '?access_token=' + action.payload.token ,
            category: 'requestDeleteClientById',
            method: 'DELETE',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestDeleteClientById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.deleteClientByIdSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function requestDeleteClientByIdFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestDeleteClientById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 204)
        .map(response => deleteClientByIdFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestClientsSuggestions(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CLIENTS_SUGGESTIONS)
        .map(action => ({
            url: BASE_URL + 'api/people/clients' +
                '?access_token=' + action.payload.token +
                ('&filter=' + JSON.stringify(_getClientsSuggestions(action.payload.filterText, action.payload.limit)) ),
            category: 'requestClientsSuggestions',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestClientsSuggestions')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getClientsSuggestionsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestClientsSuggestionsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestClientsSuggestions')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getClientsSuggestionsFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestGetTotalClients(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_TOTAL_CLIENTS)
        .map(action => ({
            url: BASE_URL + 'api/people/clients/count' +
            '?access_token=' + action.payload.token +
                (action.payload.whereFilter?( '&whereFilter=' + JSON.stringify(action.payload.whereFilter) ):''),
            category: 'requestGetTotalClients',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetTotalClients')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => receiveTotalClients(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function failedGetTotalClients(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetTotalClients')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => failedToGetTotalClients(response));

    return {
        ACTION: httpResponse$
    }
}

//cycle that requests the client by id
export function requestGetClientById(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CLIENT_BY_ID)
        .map(action => ({
            url: BASE_URL + 'api/people/clients/'+ action.payload.clientId +'?access_token=' + action.payload.token,
            category: 'requestGetClientById',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetClientById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveClientById(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the client by id
export function failedGetClientById(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetClientById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetClientById(response));

    return {
        ACTION: httpResponse$
    }
}

//cycle that requests the clients
export function requestGetClients(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CLIENTS)
        .map(action => ({
            url: BASE_URL + 'api/people/clients' +
            '?access_token=' + action.payload.token +
                ('&filter=' + JSON.stringify(action.payload.filter) ) +
            ((action.payload.filteredEmail)?('&filteredEmail='+action.payload.filteredEmail):''),
            category: 'requestGetClients',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetClients')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveClients(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function failedGetClients(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetClients')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetClients(response));

    return {
        ACTION: httpResponse$
    }
}





export function requestGetCampaignPlanClientById(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CAMPAIGN_PLAN_CLIENT_BY_ID)
        .map(action => ({
            url: BASE_URL + 'api/people/clients/'+ action.payload.clientId +'?access_token=' + action.payload.token,
            category: 'requestGetCampaignPlanClientById',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetCampaignPlanClientById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .compose(flattenSequentially)
        .filter(response => response.status === 200)
        .map(response => actions.receiveCampaignPlanClientById(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the client by id
export function failedToGetCampaignPlanClientById(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetCampaignPlanClientById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetCampaignPlanClientById(response));

    return {
        ACTION: httpResponse$
    }
}