import * as ActionTypes from '../actions/index';

//action that request get all discount types
export function getDiscounts(token) {
    return {
        type: ActionTypes.GET_DISCOUNTS,
        payload: token
    }
}

//action that receives discount types
export function receiveDiscounts(discounts) {
    return {
        type: ActionTypes.RECEIVE_DISCOUNTS,
        payload: discounts
    }
}

//action that receives failed response from getting the discounts
export function failedToGetDiscounts(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_DISCOUNTS,
        payload: response
    }
}