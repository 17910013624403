import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import Action from './action'

const Container = styled.div`
  margin:8px;
  border:1px solid lightgrey;
  border-radius: 3px;
  width:400px;
  dispay:flex;
  flex-grow:1;
`;
const Title = styled.h5`
  padding:8px;
`;
const ActionList = styled.div`
  display:'flex'
  flex-grow:1;
  padding:10px
  min-height:100px
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? '#794518' : 'white')};
`;

const Column = props => {
    return (
        <Container>
            <Title>{props.column.title}</Title>

            <Droppable droppableId={props.column.id}
                direction='horizontal'
                style={{ height: 300 }}
            >
                {(provided, snapshot) => (
                    <ActionList
                        direction={props.column.id}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        isDraggingOver={snapshot.isDraggingOver}
                        style={{ display: 'flex' }}
                    >

                        {props.actions.map((action, index) => (<div><Action key={action.id} action={action} index={index} inputChange={props.inputChange} column={props.column.id}
                            notificationModalToggle={props.notificationModalToggle} actionTypeHandle={props.actionTypeHandle}
                            newsletterModalToggle={props.newsletterModalToggle}
                        /></div>))}
                        {provided.placeholder}
                    </ActionList>
                )}
            </Droppable>
        </Container>
    )
}

export default Column;
