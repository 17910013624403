import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function deleteEnabledOffer(payload) {
    return {
        type: ActionTypes.DELETE_ENABLED_OFFER,
        payload: payload
    }
}

export function deleteEnabledOfferSuccess(payload) {
    return {
        type: ActionTypes.DELETE_ENABLED_OFFER_SUCCESS,
        payload: payload
    }
}

export function deleteEnabledOfferFailed(payload) {
    return {
        type: ActionTypes.DELETE_ENABLED_OFFER_FAILED,
        payload: payload
    }
}

export function getClientEnabledOfferById(payload) {
    return {
        type: ActionTypes.GET_CLIENT_ENABLED_OFFER_BY_ID,
        payload: payload
    }
}

export function getClientEnabledOfferByIdSuccess(payload) {
    return {
        type: ActionTypes.GET_CLIENT_ENABLED_OFFER_BY_ID_SUCCESS,
        payload: payload
    }
}

export function getClientEnabledOfferByIdFailed(payload) {
    return {
        type: ActionTypes.GET_CLIENT_ENABLED_OFFER_BY_ID_FAILED,
        payload: payload
    }
}

export function changeClientsOrdersOrder(payload) {
    return {
        type: ActionTypes.CHANGE_CLIENTS_ORDERS_ORDER,
        payload: payload
    }
}

export function getClientEnabledOffers(payload) {
    return {
        type: ActionTypes.GET_CLIENT_ENABLED_OFFERS,
        payload: payload
    }
}

export function getClientEnabledOffersSuccess(payload) {
    return {
        type: ActionTypes.GET_CLIENT_ENABLED_OFFERS_SUCCESS,
        payload: payload
    }
}

export function getClientEnabledOffersFailed(payload) {
    return {
        type: ActionTypes.GET_CLIENT_ENABLED_OFFERS_FAILED,
        payload: payload
    }
}

export function countClientOrders(payload) {
    return {
        type: ActionTypes.COUNT_CLIENT_ORDERS,
        payload: payload
    }
}

export function countClientOrdersSuccess(payload) {
    return {
        type: ActionTypes.COUNT_CLIENT_ORDERS_SUCCESS,
        payload: payload
    }
}

export function countClientOrdersFail(payload) {
    return {
        type: ActionTypes.COUNT_CLIENT_ORDERS_FAIL,
        payload: payload
    }
}

export function getClientOrders(payload) {
    return {
        type: ActionTypes.GET_CLIENT_ORDERS,
        payload: payload
    }
}

export function getClientOrdersSuccess(payload) {
    return {
        type: ActionTypes.GET_CLIENT_ORDERS_SUCCESS,
        payload: payload
    }
}

export function getClientOrdersFail(payload) {
    return {
        type: ActionTypes.GET_CLIENT_ORDERS_FAIL,
        payload: payload
    }
}

export function giveClientExtraPoints(payload) {
    return {
        type: ActionTypes.GIVE_CLIENT_EXTRA_POINTS,
        payload: payload
    }
}

export function giveClientExtraPointsSuccess(payload) {
    return {
        type: ActionTypes.GIVE_CLIENT_EXTRA_POINTS_SUCCESS,
        payload: payload
    }
}

export function giveClientExtraPointsFail(payload) {
    return {
        type: ActionTypes.GIVE_CLIENT_EXTRA_POINTS_FAIL,
        payload: payload
    }
}

export function setClientOverviewActiveTab(payload) {
    return {
        type: ActionTypes.SET_CLIENT_OVERVIEW_ACTIVE_TAB,
        payload: payload
    }
}

export function changeClientOverviewStoreSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_CLIENT_OVERVIEW_STORE_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

export const resetClientOverviewState = actionCreator(ActionTypes.RESET_CLIENT_OVERVIEW_STATE);
