import React  from 'react';
import TableComponent from "../General/table";
import {AddListContentButton} from "../General/addListContentButton";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import IconButton from "@material-ui/core/IconButton";
import {isAdmin} from "../../helpers/functions";
import {useHistory} from "react-router-dom";

const ProductList = (props) => {
    const history = useHistory();
    return(
        <Card>
            <CardHeader
                avatar={<IconButton>
                    <ShoppingBasket />
                </IconButton>}
                title="Products list"
            />
            <CardContent>
                <div className={'mt-3'}>
                    <TableComponent
                        handleRowSelect={props.handleRowSelect}
                        label="Products"
                        rows={props.products}
                        columns={props.tableColumns}
                        selectable={false}
                        fixedHeader={true}
                        multiSelectable={false}
                        displaySelectAll={false}
                        showCheckboxes={false}
                        showRowHover={true}
                        clickableRows={isAdmin(props.userRole)}
                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        handleRowsPerPage={props.handleRowsPerPage}
                        handlePageChange={props.handlePageChange}
                        totalRows={props.totalRows}
                    />
                </div>
            </CardContent>
            {isAdmin(props.userRole) && (
                <AddListContentButton onClick={() => history.push('/product/add')}/>
            )}
        </Card>
    )
};

export default ProductList;
