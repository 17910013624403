import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {toGetCategories} from "../../actions/categories";
import {parseLogin} from "../../helpers/parseImmutables";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const CategoryDeleteModal = props => {

    useEffect(() => {
        props.getAllCategories(props.brandId, props.token);
    }, [])

    return (
        <div>
            <Dialog
                open={props.categories.isDeleteModalOpen}
                onClose={props.cancelDelete}
            >
                <DialogTitle>{"Delete Category"}</DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        {props.deleteModalData.message}
                        {props.deleteModalData.selectItem}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    {props.deleteModalData.actions}
                </DialogActions>
            </Dialog>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        token: parseLogin(state.login).userInfo.token,
        brandId: parseLogin(state.login).userInfo.brand.id
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllCategories: (brandId, token, filter) => {
            dispatch(toGetCategories(brandId, token, filter))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDeleteModal);