import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import ClientList from "../../components/Clients/clientList";
import {
    changeClientsOrder,
    changeClientStoreSettings,
    getClients,
    getClientsSuggestions,
    getTotalClients, resetClientsState, selectClientSuggestion
} from "../../actions/clients";
import {_getClientsSuggestions, _getSkipFilter, getTablePage} from "../../helpers/functions";
import {parseClients, parseLogin} from "../../helpers/parseImmutables";
import moment from "moment";
import {useHistory} from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";


const tableColumns = [
    {name: 'Person Id', key: 'personId'},
    {name: 'First name', key: 'firstName'},
    {name: 'Last name', key: 'lastName'},
    {name: 'Username', key: 'username', excludeFromSorting: true},
    {name: 'Telephone', key: 'telephone'},
    {name: 'Address', key: 'address'},
    {name: 'Points', key: 'points'},
    {name: 'Leafs', key: 'leafs'},
    {name: 'Flowers', key: 'flowers'},
    {name: 'Badges', key: 'badges'},
    {name: 'Creation date', key: 'creationDate', excludeFromSorting: true},

];

const ClientListHOC = props => {
    const history = useHistory();
    const prevPage = usePrevious(props.clients.page);
    const prevRowsPerPage = usePrevious(props.clients.rowsPerPage);
    const prevOrder = usePrevious(props.clients.order);
    const prevOrderBy= usePrevious(props.clients.orderBy);

    useEffect(() => {
        return () => {
            props.resetClientsState();
        }
    }, [])

    useEffect(() => {
        props.getTotalClients(props.login.userInfo.token);
    }, [props.login.userInfo.token])

    useEffect(() => {
        if( props.clients.page !== prevPage
            || props.clients.rowsPerPage !== prevRowsPerPage
            || props.clients.order !== prevOrder
            || props.clients.orderBy !== prevOrderBy
        ){
            const whereFilter = _getClientsSuggestions(props.clients.filterField).where;
            props.getClients({
                token: props.login.userInfo.token,
                filter: {
                    where: whereFilter,
                    skip: _getSkipFilter(props.clients.page, props.clients.rowsPerPage),
                    limit: props.clients.rowsPerPage,
                    order: tableColumns[props.clients.orderBy].key + ' ' + props.clients.order.toUpperCase()
                }
            })
        }
    }, [
        props.clients.page, props.clients.rowsPerPage, prevPage, prevRowsPerPage,
        props.clients.order, prevOrder, props.clients.orderBy, prevOrderBy,
        props.clients.filterField, props.login.userInfo.token
    ])

    const getListRows = () => {
        let arr = [];
        let allClients = [...props.clients.clients];
        _.forEach(allClients, c => {
            arr.push(
                {
                    id: c.id,
                    data: [
                        c.id,
                        c.firstName,
                        c.lastName,
                        c.username,
                        c.telephone,
                        c.address,
                        c.points,
                        c.leafs,
                        c.flowers,
                        c.badges,
                        moment(c.creationDate).isValid()?moment(c.creationDate).format('YYYY-MM-DD HH:mm:ss'):'',
                    ],
                    label: `Person Id: ${c.id}`
                }
            )
        });
        return arr;
    };

    const handleRowSelect = (clientId) => {
        history.push('/clients/' + clientId)

    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.clients.totalClients / rowsPerPage);
        let currentPage = props.clients.page;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeStoreSettings('rowsPerPage', rowsPerPage);
        props.changeStoreSettings('page', currentPage);
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.clients.page,  props.clients.totalClients, props.clients.rowsPerPage);
        props.changeStoreSettings('page', newPage);
    };


    const onRequestSort = (payload) => {
        props.getTotalClients(props.login.userInfo.token);
        props.changeClientsOrder(payload);
    };

    const getSuggestions = () => {
        props.getClientsSuggestions({
            filterText: props.clients.filterField,
            limit: 5,
            token: props.login.userInfo.token
        })
    };

    const _handleFilterFieldChange = (value) => {
        props.changeStoreSettings('filterField', value);
    };

    const onSuggestionsClearRequested = () => {
        props.changeStoreSettings('suggestions', []);
    };

    const suggestionSelectedHandler = (suggestion) => {
        props.selectClientSuggestion(suggestion)
    };

    const handleSelectManySuggestions = (filterText) => {
        const whereFilter = _getClientsSuggestions(filterText).where;
        props.getTotalClients(props.login.userInfo.token, whereFilter);
        props.getClients({
            token:props.login.userInfo.token,
            filter: {
                where: whereFilter,
                skip: _getSkipFilter(props.clients.page, props.clients.rowsPerPage),
                limit: props.clients.rowsPerPage,
                order: tableColumns[props.clients.orderBy].key + ' ' + props.clients.order.toUpperCase()
            }
        });
    };

    const clearFilters = () => {
        props.changeStoreSettings('filterField', '');
        props.getTotalClients(props.login.userInfo.token);
        props.getClients({
            token:props.login.userInfo.token,
            filter: {
                skip: _getSkipFilter(props.clients.page, props.clients.rowsPerPage),
                limit: props.clients.rowsPerPage,
                order: tableColumns[props.clients.orderBy].key + ' ' + props.clients.order.toUpperCase()
            }
        });
    };

    return (
        <ClientList
            clearFilters={clearFilters}
            handleSelectManySuggestions={handleSelectManySuggestions}
            suggestionSelectedHandler={suggestionSelectedHandler}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            suggestions={props.clients.suggestions}
            getClientsSuggestions={props.getClientsSuggestions}
            _handleFilterFieldChange={_handleFilterFieldChange}
            getSuggestions={getSuggestions}
            filterField={props.clients.filterField}
            changeStoreSettings={props.changeStoreSettings}
            onRequestSort={onRequestSort}
            orderBy={props.clients.orderBy}
            order={props.clients.order}
            push={props.push}
            page={props.clients.page}
            totalRows={props.clients.totalClients}
            handlePageChange={handlePageChange}
            rowsPerPage={props.clients.rowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            handleRowSelect={handleRowSelect}
            rows={getListRows()}
            tableColumns={tableColumns}
            isFetching={false}
            userRole={props.login.userInfo.role.name}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
        clients: parseClients(state.clients)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getClients: (payload) => {
            dispatch(getClients(payload))
        },
        changeStoreSettings: (key, value) => {
            dispatch(changeClientStoreSettings(key, value))
        },
        getTotalClients: (token, whereFilter) => {
            dispatch(getTotalClients(token, whereFilter))
        },
        changeClientsOrder: (payload) => {
            dispatch(changeClientsOrder(payload))
        },
        getClientsSuggestions: (payload) => {
            dispatch(getClientsSuggestions(payload))
        },
        selectClientSuggestion: (payload) => {
            dispatch(selectClientSuggestion(payload))
        },
        resetClientsState: (payload) => {
            dispatch(resetClientsState(payload))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientListHOC);

