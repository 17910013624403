import React from 'react';
import SelectComp from "../SelectComp";
import InputComp from "../InputComp";
import TableComponent from "../General/table";
import CheckBoxComp from "../CheckBoxComp";
import Snackbar from '@material-ui/core/Snackbar';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from "@material-ui/core/IconButton";
import Stars from "@material-ui/icons/Stars";
import Button from "@material-ui/core/Button";

const PointsForm = (props) => {
    let stepItem;
    switch (props.pointsForm.currentStep) {
        case 0:
            stepItem = <div>

            </div>
    }
    return (
       <div className="point-system">
           <div className="point-system__card-1">
               <Card>
                   <CardHeader
                       avatar={
                           <IconButton>
                               <Stars />
                           </IconButton>
                       }
                       title="Point system"
                   />
                   <CardContent>
                       <Stepper activeStep={props.pointsForm.currentStep} orientation="vertical">
                           <Step>
                               <StepLabel>Set up point levels</StepLabel>
                               <StepContent>
                                   <div className={'row'}>
                                       <div className={'col-sm-2'}>
                                           <SelectComp
                                               TargetObj={props.pointsForm.totalLevels}
                                               Name="totalLevels"
                                               Label="Total levels"
                                               fullWidth={true}
                                               Selected={props.pointsForm.totalLevels}
                                               Required={true}
                                               Values={[{key: 3, value: 3}, {key: 4, value: 4}]}
                                               PropHandleInputChange={props._handleTotalLevelsSelect}
                                           />
                                       </div>
                                   </div>
                                   {props.tableRows.length > 0 && (
                                       <TableComponent
                                           handleRowSelect={() => null}
                                           label="Point system levels"
                                           columns={props.tableColumns}
                                           rows={props.tableRows}
                                           fixedHeader={true}
                                           selectable={false}
                                           multiSelectable={false}
                                           displaySelectAll={false}
                                           showCheckboxes={false}
                                           showRowHover={true}
                                           hidePagination={true}
                                       />
                                   )}
                               </StepContent>
                           </Step>
                           <Step>
                               <StepLabel>Set up combinations of products</StepLabel>
                               <StepContent>
                                   {
                                       props.pointsForm.combinationsOfProducts.length > 0?
                                           <TableComponent
                                               handleRowSelect={() => null}
                                               label="Combinations"
                                               columns={props.combinationTableColumns}
                                               rows={props.combinationsTableRows}
                                               fixedHeader={true}
                                               selectable={false}
                                               multiSelectable={false}
                                               displaySelectAll={false}
                                               showCheckboxes={false}
                                               showRowHover={true}
                                               hidePagination={true}
                                           />
                                           :
                                           null
                                   }
                                   <Button
                                       onClick={props._handleCombinationAddButton}
                                       color="primary"
                                       variant="flat"
                                   >Add combination</Button>
                               </StepContent>
                           </Step>
                           <Step>
                               <StepLabel>Set up point triggers</StepLabel>
                               <StepContent>
                                   <CheckBoxComp Checked={props.pointsForm.includePointsEarnedPerEuro} Type="checkbox" Name="includePointsEarnedPerEuro"
                                                 Label="Add points per euro during transaction" PropHandleInputChange={props._handleInputChange}/>
                                   <CheckBoxComp Checked={props.pointsForm.includePointsEarnedFromProducts} Type="checkbox" Name="includePointsEarnedFromProducts"
                                                 Label="Add points of products during transaction" PropHandleInputChange={props._handleInputChange}/>
                                   <CheckBoxComp Checked={props.pointsForm.includeCombinationsOfProducts} Type="checkbox" Name="includeCombinationsOfProducts"
                                                 Label="Add points for combinations of products during transaction" PropHandleInputChange={props._handleCombinationsCheckbox}/>
                                   <CheckBoxComp Checked={props.pointsForm.includeBirthday} Type="checkbox" Name="includeBirthday"
                                                 Label="Include birthday points" PropHandleInputChange={props._handleCombinationsCheckbox}/>
                                   <InputComp Required={true} Placeholder="Birthday points" Type="number" Name="birthdayPoints"
                                              fullWidth={false}
                                              Label="Birthday points" Value={props.pointsForm.birthdayPoints}
                                              PropHandleInputChange={props._handleTextInputChange}/>
                                   <CheckBoxComp Checked={props.pointsForm.includeJoinTheProduct} Type="checkbox" Name="includeJoinTheProduct"
                                                 Label="Include points when joining the program" PropHandleInputChange={props._handleCombinationsCheckbox}/>
                                   <div className={'row'}>
                                       <div className={'col-3'}>
                                           <InputComp Required={true} Placeholder="Join the product points" Type="number" Name="joinTheProductPoints"
                                                      fullWidth={true}
                                                      Label="Join the product points" Value={props.pointsForm.joinTheProductPoints}
                                                      PropHandleInputChange={props._handleTextInputChange}/>
                                           <InputComp
                                               Required={true}
                                               Placeholder="Base reward item value"
                                               Type="number" Name="baseRewardItemValue"
                                               fullWidth={true}
                                               Label="Base reward item value"
                                               Value={props.pointsForm.baseRewardItemValue}
                                               PropHandleInputChange={props._handleTextInputChange}/>
                                           <InputComp
                                               Required={true}
                                               Placeholder="Medium reward item value"
                                               Type="number" Name="mediumRewardItemValue"
                                               fullWidth={true}
                                               Label="Medium reward item value"
                                               Value={props.pointsForm.mediumRewardItemValue}
                                               PropHandleInputChange={props._handleTextInputChange}/>
                                           <InputComp
                                               Required={true}
                                               Placeholder="Final reward item value"
                                               Type="number" Name="finalRewardItemValue"
                                               fullWidth={true}
                                               Label="Final reward item value"
                                               Value={props.pointsForm.finalRewardItemValue}
                                               PropHandleInputChange={props._handleTextInputChange}/>
                                           <InputComp
                                               Required={true}
                                               Placeholder="First sign in reward"
                                               Type="number" Name="firstSignInReward"
                                               fullWidth={true}
                                               Label="First sign in reward"
                                               Value={props.pointsForm.firstSignInReward}
                                               PropHandleInputChange={props._handleTextInputChange}/>
                                       </div>
                                   </div>
                               </StepContent>
                           </Step>
                       </Stepper>
                       <div>
                       </div>
                   </CardContent>
                   <CardActions>
                       <Button
                           onClick={props.pointsForm.currentStep === 2?props._handleSubmit :props.handleNext}
                           color="primary"
                           variant="contained"
                       >{props.pointsForm.currentStep === 2 ? 'Finish' : 'Next'}</Button>
                       {props.pointsForm.currentStep > 0 && (
                           <Button
                               disabled={props.pointsForm.currentStep === 0}
                               onClick={props.handlePrev}
                               color="primary"
                               variant="flat"
                           >Back</Button>
                       )}
                   </CardActions>
               </Card>
           </div>
           <Snackbar
               open={props.pointsForm.responseMessage?true:false}
               message={props.pointsForm.responseMessage}
               autoHideDuration={6000}
               onClose={() => {props.changeStoreSettings('responseMessage', '')}}
           />
       </div>
    )
};

export default PointsForm;
