import React, { Component, useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import Snackbar from "@material-ui/core/Snackbar";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import StarsIcon from "@material-ui/icons/Stars";
import CardActions from "@material-ui/core/CardActions";
import NotificationDesignModal from "./notificationDesignModal";
import FormStepper from "./formStepper";
import EmailModal from "../General/EmailBuilder/emailModal";
import { CircularProgress } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
  stepContent: {
    overflow: "visible",
  },
  cardWrapper: {
    overflow: "visible",
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

const CampaignNewForm = (props) => {
  const [text, setText] = useState("");
  return (
    <div>
      <Card>
        <CardHeader
          avatar={
            <IconButton>
              <StarsIcon />
            </IconButton>
          }
          title="Campaign Form"
        />
        <CardContent>
          <div>
            <form
              id="form-teller"
              className="form-horizontal"
               onSubmit={(e)=>e.preventDefault()}
            >
             <FormStepper 
             {...props}/>
              {/* MODAL FOR PUSH NOTIFICATION */}
            <NotificationDesignModal
            {...props}/>
            <EmailModal
            {...props}
            emailFrom={props.emailFrom}
            emailSubject={props.emailSubject}
            emailDate={props.emailDate}
            emailTime={props.emailTime}
            emailContent={props.emailContent}
            testEmailAddress={props.testEmailAddress}
            emailModalOpen={props.emailModalOpen}
            />
            </form>
          </div>
        </CardContent>
        <CardActions>
          <Button
            onClick={
              props.currentStep === 2 ? props.handleSubmit : props.goNext
            }
            color="primary"
            variant="contained"
            type="text"
            name='submit'
            disabled={props.loadingCampaign}
          >
            {props.currentStep === 2 ? "Submit" : "Next"}
          </Button>
          {props.currentStep > 0 && (
            <Button
              disabled={props.currentStep === 0}
              onClick={props.goBack}
              color="primary"
              variant="flat"
            >
              {"Back"}
            </Button>
            
          )}
          {props.loadingCampaign && <CircularProgress style={{width:25,height:25}}/>}
        </CardActions>
      </Card>
      <Snackbar
        open={
          !!props.campaignNewForm.createCampaignResponseMessage ||
          !!props.campaignNewForm.createActionResponseMessage ||
          !!props.campaignNewForm.editCampaignResponseMessage ||
          !!props.campaignNewForm.editActionResponseMessage||
          !!props.campaignNewForm.validationMessage
        }
        message={
          props.campaignNewForm.createCampaignResponseMessage ||
          props.campaignNewForm.createActionResponseMessage ||
          props.campaignNewForm.editCampaignResponseMessage ||
          props.campaignNewForm.editActionResponseMessage   ||
          props.campaignNewForm.validationMessage
        }
        autoHideDuration={!!props.campaignNewForm.validationMessage? 4000:1000}
        onClose={props.responseMessageHandler}
      />
    </div>
  );
};
export default withStyles(styles)(CampaignNewForm);
