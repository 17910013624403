import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button/Button";
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
});

const TableList = props => {
    const [expanded, setExpanded] = useState(null);
    const { classes } = props;

    const handleChange = panel => (event, expanded) => {
        setExpanded(expanded ? panel : false)
    };

    return (
        <div className={classes.root}>
            {
                props.rows.map( (row, index) => {
                    return (
                        <ExpansionPanel expanded={expanded === index} onChange={handleChange(index)} key={index}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}>{`${row.label}`}</Typography>
                                <Typography className={classes.secondaryHeading}>

                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Table >
                                    <TableBody>
                                        {
                                            props.columns.map( (column, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell  component="th" scope="row">
                                                            { props.isSortable ?
                                                                column.name
                                                                :
                                                                column
                                                            }
                                                        </TableCell>
                                                        <TableCell>{row.data[index]}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </ExpansionPanelDetails>
                            <ExpansionPanelActions>
                                {props.clickableRows && (
                                    <Button
                                        size="small"
                                        color="primary"
                                        onClick={()=>props.handleRowSelect(row.id)}
                                    >
                                        {'Edit'}
                                    </Button>
                                )}
                            </ExpansionPanelActions>
                        </ExpansionPanel>
                    )
                })
            }
        </div>
    );
}

TableList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TableList);