//cycle that requests the clients
import xs from "xstream/index";
import * as actions from "../actions/orders";
import {BASE_URL} from "../helpers/variables";
import * as ActionTypes from "../actions";
import sampleCombine from "xstream/extra/sampleCombine";

export function requestCountXpOrders(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.COUNT_XP_ORDERS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/orders/experiences/count',
            category: 'requestCountXpOrders',
            method: 'GET',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCountXpOrders')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.countXpOrdersSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function requestCountXpOrdersFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCountXpOrders')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.countXpOrdersFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestDeleteOrder(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.DELETE_ORDER)
        .map(action => ({
            url: BASE_URL + 'api/orders/'+ action.payload.orderId + '?access_token=' + action.payload.token,
            category: 'requestDeleteOrder',
            method: 'DELETE',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestDeleteOrder')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.deleteOrderSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function errorDeleteOrder(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestDeleteOrder')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.deleteOrderFail(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestCreateNewOrder(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.CREATE_NEW_ORDER)
        .map(action => ({
            url: BASE_URL + 'api/orders/newOrder?access_token=' + action.payload.token,
            category: 'requestCreateNewOrder',
            method: 'POST',
            send: action.payload.order,
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCreateNewOrder')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveCreateNewOrderResponse(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function errorCreateNewOrder(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCreateNewOrder')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToCreateNewOrder(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestGetTotalOrders(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_TOTAL_ORDERS)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.brandId +'/orders/count' +
            '?access_token=' + action.payload.token +
            '&where=' + JSON.stringify({
                    and: [{deleted: false}, {or: [{xp: false}, {xp: null}] }]
                }) ,
            category: 'requestGetTotalOrders',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetTotalOrders')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveTotalOrders(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function failedGetTotalOrders(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetTotalOrders')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetTotalOrders(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestGetOrderById(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_ORDER_BY_ID)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.brandId +'/orders/custom/'+ action.payload.orderId + '?access_token=' + action.payload.token,
            category: 'requestGetOrderById',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetOrderById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveOrderById(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function failedGetOrderById(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetOrderById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToReceiveOrderById(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestGetOrders(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_ORDERS)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.brandId +'/orders?access_token=' + action.payload.token +
            '&filter=' +  JSON.stringify({
                where: {deleted: false},
                include: [
                    {
                        relation: 'mobilePerson',
                        scope: {
                            include: {
                                relation: "mobilePeople"
                            }
                        }
                    },
                    {
                        relation: "teller",
                        scope: {
                            include: {
                                relation: "teller",
                                scope: {
                                    include: "stores"
                                }
                            }
                        }
                    },
                    "store",
                    {
                        relation: "lineItems",
                        scope: {
                            include: {
                                relation: "product",
                                scope: {
                                    include: 'categories'
                                }
                            }
                        }
                    }
                ],
                order:   (action.payload.filter && action.payload.filter.order?(action.payload.filter.order):''),
                orderBy:   (action.payload.filter && action.payload.filter.orderBy?(action.payload.filter.orderBy):''),
                limit:   (action.payload.filter && action.payload.filter.limit?(action.payload.filter.limit):''),
                skip: (action.payload.filter && action.payload.filter.skip?(action.payload.filter.skip):'')
            }),
            category: 'requestGetOrders',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetOrders')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveOrders(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function failedGetOrders(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetOrders')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetOrders(response));

    return {
        ACTION: httpResponse$
    }
}