import * as actions from '../actions/offers';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";
import moment from "moment";

export function requestDeleteOffer(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_DELETE_OFFER)
        .map(action => ({
            url: BASE_URL + 'api/offers/' + action.payload.offerId + '?access_token=' + action.payload.token,
            category: 'requestDeleteOffer',
            method: 'DELETE',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestDeleteOffer')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.receiveDeleteOffer(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestDeleteOfferFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestDeleteOffer')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 204)
        .map(response => actions.receiveErrorDeleteOffer(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestEditOffer(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.SUBMIT_CHANGED_OFFER)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.changedOffer.brandId + '/offers/' +
                action.payload.changedOffer.oid +
                '/update?access_token=' +
                action.payload.token,
            category: 'requestEditOffer',
            method: 'PATCH',
            send: {
                minOrder:  action.payload.changedOffer.minOrder,
                offerId:  action.payload.changedOffer.oid,
                brandId:  action.payload.changedOffer.brandId,
                recurring:action.payload.changedOffer.recurring,
                name:  action.payload.changedOffer.offer_name,
                startDate: moment( action.payload.changedOffer.start_date_readable).toDate(),
                endDate: moment( action.payload.changedOffer.end_date_readable).toDate(),
                pointsRequired:  action.payload.changedOffer.points_required,
                discountId: parseInt( action.payload.changedOffer.discount_type),
                promoted:  action.payload.changedOffer.promoted,
                redeemPoints:  action.payload.changedOffer.redeemPoints,
                discount:  action.payload.changedOffer.discount,
                products:  action.payload.changedOffer.products.map(item => {return parseInt(item)?parseInt(item):item}),
            },
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestEditOffer')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveEditOffer(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestEditOfferFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestEditOffer')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorEditOffer(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestAddOffer(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.SUBMIT_NEW_OFFER)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.data.brid + '/offers/newOffer?access_token=' +
                action.payload.token,
            category: 'requestAddOffer',
            method: 'POST',
            send: {
                minOrder: action.payload.data.minOrder,
                brandId: action.payload.data.brid,
                name:action.payload.data.offer_name,
                recurring:action.payload.data.recurring,
                startDate: moment(action.payload.data.start_date_readable).toDate(),
                endDate: moment(action.payload.data.end_date_readable).toDate(),
                pointsRequired: action.payload.data.points_required,
                discountId: parseInt(action.payload.data.discount_type),
                promoted: action.payload.data.promoted,
                redeemPoints: action.payload.data.redeemPoints,
                discount: action.payload.data.discount,
                products: action.payload.data.products.map(item => {return parseInt(item)?parseInt(item):item})
            },
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestAddOffer')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveAddOffer(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestAddOfferFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestAddOffer')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorAddOffer(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestGetOffers(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_ALL_OFFERS)
        .map(action => ({
            url: BASE_URL + "api/brands/" + action.payload.brandId + '/offers?access_token=' + action.payload.token +
                '&filter[where][deleted]=false' +
                (action.payload.filter && action.payload.filter.limit?
                    ('&filter[limit]=' + action.payload.filter.limit):'') +
                (action.payload.filter && action.payload.filter.skip?
                    ('&filter[skip]=' + action.payload.filter.skip):''),
            category: 'requestGetOffers',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetOffers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveAllOffers(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestGetOffersFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetOffers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorGetOffers(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestCountOffers(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.COUNT_OFFERS)
        .map(action => ({
            url: BASE_URL + 'api/brands/' + action.payload.brandId + '/offers/count' +
            '?access_token=' + action.payload.accessToken +
            '&where[deleted]=false',
            category: 'requestCountOffers',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCountOffers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.countOffersSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestCountOffersFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCountOffers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.countOffersFail(response));

    return {
        ACTION: httpResponse$
    }
}

//cycle that requests the product by id
export function requestGetOfferById(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_OFFER_BY_ID)
        .map(action => ({
            url: BASE_URL + 'api/offers/' + action.payload.offerId + '?access_token=' + action.payload.token,
            category: 'requestGetOfferById',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetOfferById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveOfferById(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the offer by id
export function failedGetOfferById(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetOfferById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetOfferById(response));

    return {
        ACTION: httpResponse$
    }
}