import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import ProductsFormComponent from "../../components/Products/ProductsFormComponent";
import {
    addingProduct, changeProductFormSettings, deletingProduct, editChangedProduct, editingProduct, editNewProduct,
    getProductBydId,
    gettingAllProducts, showingEditProductForm
} from "../../actions/products";
import {toGetCategories} from "../../actions/categories";
import _ from 'lodash';
import {parseCategories, parseLogin, parseProducts} from "../../helpers/parseImmutables";
import {useHistory, useLocation} from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";


const ProductFormHOC = props => {
    const history = useHistory();
    const location = useLocation();

    const prevDeleting = usePrevious(props.products.deleting);

    useEffect(() => {
        props.getAllCategories(props.login.userInfo.brand.id, props.login.userInfo.token);
        if(location.pathname.includes('edit')){
            props.getProductBydId(_.last(location.pathname.split('/')), props.login.userInfo.token);
        }
    }, []);

    useEffect(() => {
        if(prevDeleting && !props.products.deleting && !props.products.sendResponse){
            history.goBack();
        }
    }, [props.products.deleting, prevDeleting, props.products.sendResponse])

    const handleProductSubmit = (evt) => {
        if (evt.key === "Enter" || typeof(evt.key) === "undefined") {
            evt.preventDefault();
            if (location.pathname.includes("add")) {
                if(!props.products.newProduct.cid){
                    props.changeStoreSettings('sendResponse', 'Please, select a category for the product.');
                    return false;
                }
                props.addProduct({...props.products.newProduct, token: props.login.userInfo.token},
                    props.login.userInfo.brand.id)
            }
            else if (location.pathname.includes("edit")) {
                if(!props.products.changedProduct.cid) {
                    props.changeStoreSettings('sendResponse', 'Please, select a category for the product.');
                    return false;
                }
                props.editProduct({...props.products.changedProduct,
                    brandId:props.login.userInfo.brand.id}, props.login.userInfo.token)
            }
        }
    };

    const handleTextInputChange = (evt) => {
        let name = evt.target.name;
        let value = evt.target.value;
        if (name === "points" || name === "value") {
            // value = parseFloat(value)
        }
        if (location.pathname.includes("add")) {
            props.tempNewProduct(name, value)
        }
        else if (location.pathname.includes("edit")) {
            props.tempChangedProduct(name, value)
        }
    };

    const handleProductInputChange = (name, value) => {
        if (name === "points" || name === "value") {
            // value = parseFloat(value)
        }
        if (location.pathname.includes("add")) {
            props.tempNewProduct(name, value)
        }
        else if (location.pathname.includes("edit")) {
            props.tempChangedProduct(name, value)
        }
    };

    const getCategoryArray = () => {
        let categoryArray = [];
        for (let index in props.categories.all) {
            if (props.categories.all[index].brid === props.login.userInfo.brid) {
                categoryArray.push({
                    key: props.categories.all[index].cid,
                    value: props.categories.all[index].catName
                });
            }
        }
        return categoryArray;
    };

    const showProduct = (evt) => {
        props.showEditProductForm(props.products.all[evt.target.value])
    };

    const handleDelete = (evt) => {
        props.deleteProduct(props.products.changedProduct.productId, props.login.userInfo.token);
    };

    const handleImageInput = (evt) => {
        let e = evt.target.files;
        let reader = new FileReader();
        let handleChange ;
        if(location.pathname.includes('edit')){
            handleChange = props.tempChangedProduct;
        }
        else {
            handleChange =props.tempNewProduct;
        }
        try {
            reader.readAsText(e[0]);
            reader.onload = function () {
                let attachment = {
                    name: e[0].name,
                    type: e[0].type,
                    file: e[0]
                };
                reader.readAsDataURL(e[0]);
                reader.onload = () => {
                    attachment = {...attachment, base64: reader.result};
                    handleChange('attachment', attachment);
                    handleChange('imageName', '');
                };
                reader.onerror = (error) => {
                    console.log('Error: ', error);
                }
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
        catch (e) {

        }
    };

    const handlePdfInput = (evt, name) => {
        let e = evt.target.files;
        let reader = new FileReader();
        let handleChange ;
        if(location.pathname.includes('edit')){
            handleChange = props.tempChangedProduct;
        }
        else {
            handleChange =props.tempNewProduct;
        }
        try {
            reader.readAsText(e[0]);
            reader.onload = function () {
                let attachment = {
                    name: e[0].name,
                    type: e[0].type,
                    pdfType: name,
                    file: e[0]
                };
                reader.readAsDataURL(e[0]);
                reader.onload = () => {
                    attachment = {...attachment, base64: reader.result};
                    handleChange(name, attachment);
                };
                reader.onerror = (error) => {
                    console.log('Error: ', error);
                }
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
        catch (e) {

        }
    };

    const removeAttachment = (attachmentType) => {
        let attachment = {
            name: '',
            type: '',
            file: '',
            base64: '',
        };
        if(location.pathname.includes('edit')){
            props.tempChangedProduct('attachment', attachment);
            props.tempChangedProduct('imageName', '');
        }
        else {
            props.tempNewProduct('attachment', attachment);
            props.tempNewProduct('imageName', '');
        }
    };

    return (
        <ProductsFormComponent
            removeAttachment={removeAttachment}
            handleImageInput={handleImageInput}
            handlePdfInput={handlePdfInput}
            handleTextInputChange={handleTextInputChange}
            allCategories={props.allCategories}
            handleDelete={handleDelete}
            productsStore={props.products}
            changeStoreSettings={props.changeStoreSettings}
            showEditing={props.products.showEditing}
            selectedProduct={props.products.showEditingProduct}
            showProduct={showProduct}
            isNew={location.pathname.includes('edit')?false:true}
            categories={getCategoryArray()}
            selectedMenuItem={location.pathname.includes('edit')?props.products.changedProduct.cid:props.products.newProduct.cid}
            product={location.pathname.includes('edit')?props.products.changedProduct:props.products.newProduct }
            handleInputChange={handleProductInputChange}
            handleSubmit={handleProductSubmit}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        products: parseProducts(state.products),
        categories: parseCategories(state.categories),
        allCategories: parseCategories(state.categories).all,
        login: parseLogin(state.login),
        router: state.router
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        tempChangedProduct: (productId, productVal) => {
            dispatch(editChangedProduct(productId, productVal))
        },
        tempNewProduct: (productId, productVal) => {
            dispatch(editNewProduct(productId, productVal))
        },
        editProduct: (data, token) => {
            dispatch(editingProduct(data, token));
        },
        addProduct: (data, productBrand) => {
            dispatch(addingProduct(data, productBrand));
        },
        getProducts: () => {
            dispatch(gettingAllProducts());
        },
        getAllCategories: (brandId, token) => {
            dispatch(toGetCategories(brandId, token))
        },
        showEditProductForm: (product) => {
            dispatch(showingEditProductForm(product));
        },
        changeStoreSettings: (key, value) => {
            dispatch(changeProductFormSettings(key, value))
        },
        deleteProduct: (pid,token) => {
            dispatch(deletingProduct(pid, token));
        },
        getProductBydId: (productId, token) => {
            dispatch(getProductBydId(productId, token))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductFormHOC);

