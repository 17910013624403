import React  from 'react';
import Table from "../General/table";
import Indicator from "../General/indicator";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import People from "@material-ui/icons/People";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ListSuggestions from "../General/listSuggestions";
import {AddListContentButton} from "../General/addListContentButton";
import {useHistory} from "react-router-dom";

const ClientList = (props) => {
    const history = useHistory();

    if(props.isFetching){
        return (
            <Indicator/>
        )
    }

    return(
        <Card>
            <CardHeader
                avatar={<IconButton>
                    <People />
                </IconButton>}
                title="Clients list"
            />
            <CardContent>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <ListSuggestions
                            variant={'outlined'}
                            placeholder={'Quick search clients'}
                            handleFilterTextChange={props._handleFilterFieldChange}
                            value={props.filterField}
                            handleSelectManySuggestions={props.handleSelectManySuggestions}
                            suggestionSelectedHandler={props.suggestionSelectedHandler}
                            suggestions={props.suggestions.map(s => ({
                                label: s.firstName + ' ' + s.lastName,
                                suggestion: s
                            }))}
                            onSuggestionsClearRequested={props.onSuggestionsClearRequested}
                            requestListSuggestions={props.getClientsSuggestions}
                        />
                        <Button
                            className={'ml-3'}
                            onClick={props.clearFilters}
                            color="primary"
                            variant="contained"
                        >Clear Filters</Button>
                    </div>
                </div>
                <div className={'mt-3'}>
                    <div className={'table-sm'}>
                        <Table
                            handleRowSelect={props.handleRowSelect}
                            label="Clients"
                            rows={props.rows}
                            columns={props.tableColumns}
                            fixedHeader={true}
                            selectable={false}
                            multiSelectable={false}
                            displaySelectAll={false}
                            showCheckboxes={false}
                            showRowHover={true}
                            page={props.page}
                            rowsPerPage={props.rowsPerPage}
                            handleRowsPerPage={props.handleRowsPerPage}
                            handlePageChange={props.handlePageChange}
                            totalRows={props.totalRows}
                            clickableRows={true}
                            isSortable={true}
                            orderBy={props.orderBy}
                            order={props.order}
                            onRequestSort={props.onRequestSort}

                        />
                    </div>
                </div>
            </CardContent>
            {props.userRole === 'admin' && (
                <AddListContentButton onClick={() =>history.push('/clients/add')}/>
            )}
        </Card>
    )
};

export default ClientList;
