export const PRODUCT_ATTACHMENT_TYPES = {
    IMAGE: 'image',
    PDF: 'pdf',
    RECIPE: 'video'
};

export const PDF_TYPES = {
    PRODUCT_ELEMENTS: 'stoixeia',
    PRODUCT_SPECIFICATIONS: 'prodiagrafes'
};
