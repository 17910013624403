import { fromJS } from "immutable";
import { createReducer } from "./helpers/createReducer";
import * as ActionTypes from "../actions/index";

const defaultState = fromJS({
  currentStep: 0,
  fetchingActionsEvents: false,
  actions: {},
  events: {},
  campaignActions: fromJS([]),

  activeAction: "",
  formData: fromJS({
    id:'',
    title: "", 
    description: "", 
    image: "", 
    importedUsers: [], 
    selectedUsers: "", 
    selectAll:false,
    actionDate: "",
    actionTime: "",
    actionTags: [],
    selectedActionId: "", 
    selectedActionType: "", 
    selectedEvents: [],
    selectedTags: [], 
    startDate: "",
    endDate: "",
    notificationTitle: "",
    notificationText: "",
    notificationImage: "",
    notificationBigImage: "",
    notificationImageType: "",
    notificationBigImageType: "",
    url:"",
    toAddTag:'',
    emailFrom: "",
    emailSubject: "",
    emailDate: "",
    emailTime: "",
    emailContent: "",
    testEmailAddress: "",
    actionsSelected:fromJS([]),
    pushPlatforms: fromJS([]), // to which platforms the actions will be done , also determines how many actions will be made
  }),
  readyForCampaign:false,
  creatingAction: false,
  createActionResponseMessage: "",
  createCampaignResponseMessage: "",
  creatingCampaign: false,
  editingCampaign:false,
  editingAction:false,
  editCampaignResponseMessage:"",
  editActionResponseMessage:"",
  deletingAction:false,
  validationMessage:"",
  sendingTest:false
});

function createCampaign(state, action) {
  return state
  .set("creatingCampaign", true)
  .set("readyForCampaign", false);

}

function createCampaignSuccess(state, action) {
  return state
    .set("creatingCampaign", false)
    .set("createCampaignResponseMessage", "Campaign was created successfully.")
    .set("currentStep", 0)
    .set("formData", fromJS(defaultState.get("formData")));
}

function createCampaignFail(state, action) {
  let msg = "";
  try {
    msg = action.payload.response.body.message.split(':')[1];
  } catch (e) {
    msg = "Failed to create Campaign.";
  }
  return state
    .set("campaignActions", fromJS([]))
    .set("creatingCampaign", false)
    .set("createCampaignResponseMessage", msg);
}

function changeCampaignFormData(state, action) {
  return state.setIn(["formData", action.payload.key], action.payload.value);
}

function changeCampaignFormSettings(state, action) {
  return state.set(action.payload.key, action.payload.value);
}

function logOff(state, action) {
  return defaultState;
}

function getActions(state, action) {
  return state.set("fetchingActionsEvents", true);
}

function getEvents(state, action) {
  return state.set("fetchingActionsEvents", true);
}

function receiveActions(state, action) {
  try {
    action.payload = action.payload.body;
  } catch (e) {
    action.payload = null;
  }
  return state
    .set("actions", fromJS(action.payload))
    .set("fetchingActionsEvents", false);
}

function receiveEvents(state, action) {
  try {
    action.payload = action.payload.body;
  } catch (e) {
    action.payload = null;
  }
  return state
    .set("events", fromJS(action.payload))
    .set("fetchingActionsEvents", false);
}
function failedToGetActionsEvents(state, action) {
  let msg = "";
  try {
    msg = action.payload.response.body.error.message;
  } catch (e) {
    msg = "Failed to get Action and Events.";
  }
  return (
    state
      .set("fetchingActionsEvents", false)
      .set("createActionResponseMessage", msg)
  );
}

function createAction(state, action) {
  return state.set("creatingAction", true);
}

function createActionSuccess(state, action) {
  return (
    state
      .set("fetchingActionsEvents", false)
      .set("creatingAction", false)
      .set("createActionResponseMessage", "Action was created successfully.")
      .set("campaignActions",state.get("campaignActions").push(fromJS(action.payload.body.id)))
  );

}
function createActionFail(state, action) {
  let msg = "";
 try {
  msg = action.payload.response.body.message;
 } catch (e) {
   msg = "Failed to create Action.";
 }
  return state
    .set("creatingAction", false)
    .set("createActionResponseMessage", msg)
    .set("readyForCampaign", false);
}

function resetState(state, action) {
  return defaultState.set("currentStep", 0);
}
function editCampaign(state, action) {
  return state.set("editingCampaign", true);
}

function editCampaignSuccess(state, action) {
  return state
    .set("editingCampaign", false)
    .set("editCampaignResponseMessage", "Campaign was updated successfully.")
    .set("currentStep", 0)
    // .set("formData", fromJS(defaultState.get("formData")));
}

function editCampaignFail(state, action) {
  let msg = "";
  try {
    msg = action.payload.response.body.message;
  } catch (e) {
    msg = "Failed to update Campaign.";
  }
  return state
    //.set("formData", fromJS(defaultState.get("formData")))
    .set("editingCampaign", false)
    .set("editCampaignResponseMessage", msg);
}

function editAction(state, action) {
  return state.set("editingCampaign", true);
}

function editActionSuccess(state, action) {
  return state
    .set("editingAction", false)
     .set("editActionResponseMessage", "Action was updated successfully.")
    .set("currentStep", 0)
    // .set("formData", fromJS(defaultState.get("formData")));
}

function editActionFail(state, action) {
  let msg = "";
  try {
    msg = action.payload.response.body.message;
  } catch (e) {
    msg = "Failed to update Action.";
  }
  return state
    .set("editingAction", false)
    .set("editActionResponseMessage", msg);
}


function sendTestEmail (state,action){
  return state
    .set("sendingTest", true)
}
function sendTestEmailSuccess(state, action) {
  return state
  .set("sendingTest", false)

}

function sendTestEmailFail(state, action) {
    return state
  .set("sendingTest", false)


}

export const campaignNewForm = createReducer(defaultState, {
  [ActionTypes.LOG_OFF]: logOff,
  [ActionTypes.CREATE_CAMPAIGN]: createCampaign,
  [ActionTypes.CREATE_ACTION]: createAction,
  [ActionTypes.CREATE_CAMPAIGN_SUCCESS]: createCampaignSuccess,
  [ActionTypes.CREATE_ACTION_SUCCESS]: createActionSuccess,
  [ActionTypes.GET_ACTIONS]: getActions,
  [ActionTypes.GET_EVENTS]: getEvents,
  [ActionTypes.RECEIVE_ACTIONS]: receiveActions,
  [ActionTypes.RECEIVE_EVENTS]: receiveEvents,
  [ActionTypes.CREATE_CAMPAIGN_FAIL]: createCampaignFail,
  [ActionTypes.CREATE_ACTION_FAIL]: createActionFail,
  [ActionTypes.CHANGE_CAMPAIGN_FORM_SETTINGS]: changeCampaignFormSettings,
  [ActionTypes.CHANGE_CAMPAIGN_FORM_DATA]: changeCampaignFormData,
  [ActionTypes.FAILED_TO_GET_ACTIONS_EVENTS]: failedToGetActionsEvents,
  [ActionTypes.EDIT_CAMPAIGN]:editCampaign,
  [ActionTypes.EDIT_CAMPAIGN_SUCCESS]:editCampaignSuccess,
  [ActionTypes.EDIT_CAMPAIGN_FAIL]:editCampaignFail,
  [ActionTypes.RESET_CAMPAIGNS_NEW_FORM_STATE]: resetState,
  [ActionTypes.EDIT_ACTION]:editAction,
  [ActionTypes.EDIT_ACTION_SUCCESS]:editActionSuccess,
  [ActionTypes.EDIT_ACTION_FAIL]:editActionFail,
  [ActionTypes.SEND_TEST_EMAIL]:sendTestEmail,
  [ActionTypes.SEND_TEST_EMAIL_SUCCESS]:sendTestEmailSuccess,
  [ActionTypes.SEND_TEST_EMAIL_FAIL]:sendTestEmailFail

});
