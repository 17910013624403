import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import RenderTextField from "../input/renderTextField";

const EditorForm =  (props) =>{
    const {submitForm, handleSubmit, handleCancel} = props;
    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <div className={'form-group'}>
                <Field name="username" label={'Username'} component={RenderTextField} type="text" fullWidth/>
            </div>
            <div className={'form-group'}>
                <Field name="email" label={'Email'} component={RenderTextField} type="email" fullWidth/>
            </div>
            {!props.editor.id && (
                <div className={'form-group'}>
                    <Field name="password" label={'Password'} component={RenderTextField} type="password" fullWidth/>
                </div>
            )}
            <div className={'form-group'}>
                <Button type={'submit'} variant="contained" color="primary" className={'mr-3'}>
                    {props.editor.id?'Edit':'Create'}
                </Button>
                <Button onClick={handleCancel} variant="contained" color="secondary">
                    {'Cancel'}
                </Button>
            </div>
        </form>
    )

};

export default reduxForm({
    form: 'editorForm',
    enableReinitialize: true
})(EditorForm);
