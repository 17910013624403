import React from 'react';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import LocalOffer from "@material-ui/icons/LocalOffer";
import CardContent from "@material-ui/core/CardContent";
import EditorForm from './editorForm';
import Snackbar from "@material-ui/core/Snackbar";
import {useHistory} from "react-router-dom";

const Editor = (props) => {
    const history = useHistory();
    return (
        <div className={'row'}>
            <div className={'col-12'}>
                <Card>
                    <CardHeader
                        avatar={
                            <IconButton>
                                <LocalOffer />
                            </IconButton>
                        }
                        title={props.editor.id?'Edit Editor':'Create Editor'}
                    />
                    <CardContent>
                        <div className="col col-lg-4 offset-lg-3 ">
                            <EditorForm
                                {...props}
                                handleCancel={() => history.goBack()}
                                initialValues={props.getInitialValues()}
                            />
                        </div>
                    </CardContent>
                </Card>
            </div>
            <Snackbar
                open={!!(props.errorMessage)}
                message={props.errorMessage}
                autoHideDuration={6000}
                onClose={() => {props.changeEditorState({
                    key: 'errorMessage',
                    value: ''
                })}}
            />
        </div>
    )
};

export default Editor;