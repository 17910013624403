import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function countOffers(payload) {
    return {
        type: ActionTypes.COUNT_OFFERS,
        payload: payload
    }
}

export function countOffersSuccess(payload) {
    return {
        type: ActionTypes.COUNT_OFFERS_SUCCESS,
        payload: payload
    }
}

export function countOffersFail(payload) {
    return {
        type: ActionTypes.COUNT_OFFERS_FAIL,
        payload: payload
    }
}

//action that request offer by id
export function getOfferById(offerId, token) {
    return {
        type: ActionTypes.GET_OFFER_BY_ID,
        payload: {
            offerId,
            token
        }
    }
}

//action that receives offer by id
export function receiveOfferById(offer) {
    return {
        type: ActionTypes.RECEIVE_OFFER_BY_ID,
        payload: offer
    }
}

//action that receives failed response get offer by id
export function failedToGetOfferById(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_OFFER_BY_ID,
        payload: response
    }
}

export function changeOfferSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_OFFER_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

export function resetDefault() {
    return {
        type: ActionTypes.RESET_DEFAULT_OFFER
    }
}


export function receiveDeleteOffer(offerId) {
    return {
        type: ActionTypes.RECEIVE_DELETE_OFFER,
        payload: offerId
    }
}

//action that receives error deleting offer
export function receiveErrorDeleteOffer(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_DELETE_OFFER,
        payload: response
    }
}

export function toDeleteOffer(offerId, token) {
    return {
        type: ActionTypes.REQUEST_DELETE_OFFER,
        payload: {offerId, token}
    };
}

export function toChangeOffer(selectedOffer, updatedOfferObj) {
    return {
        type: ActionTypes.TO_CHANGE_OFFER,
        payload: {
            selectedOffer,
            updatedOfferObj
        }
    }
}

export function changeStoredOffer(offerKey, offerValue) {
    return {
        type: ActionTypes.TEMP_CHANGED_OFFER,
        payload: {key: offerKey, value: offerValue}
    }
}

export function changeNewOffer(offerKey, offerValue) {
    return {
        type: ActionTypes.TEMP_NEW_OFFER,
        payload: {key: offerKey, value: offerValue}
    }
}

export function receiveAllOffers(offers) {
    return {
        type: ActionTypes.RECEIVE_ALL_OFFERS,
        payload: offers
    }
}

export function receiveAddOffer(response) {
    return {
        type: ActionTypes.RECEIVE_ADD_OFFER,
        payload: response
    }
}

//action that receives error while adding a new offer
export function receiveErrorAddOffer(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_ADD_OFFER,
        payload: response
    }
}

export function addingOffer(data, offerBrand, token) {
    return {
        type: ActionTypes.SUBMIT_NEW_OFFER,
        payload: {data: {...data, brid: offerBrand}, token}
    };
}

export function editingOffer(changedOffer, offerId, token) {
    return {
        type: ActionTypes.SUBMIT_CHANGED_OFFER,
        payload: {changedOffer, offerId, token}
    };
}

//action that receives failed response while editing offer
export function receiveErrorEditOffer(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_EDIT_OFFER,
        payload: response
    }
}

export function receiveEditOffer(offer) {
    return {
        type: ActionTypes.RECEIVE_EDIT_OFFER,
        payload: offer
    }
}

export function gettingAllOffers(brandId, token, filter) {
    return {
        type: ActionTypes.REQUEST_ALL_OFFERS,
        payload: {brandId, token, filter}
    };
}

//action that receives failed get offers response
export function receiveErrorGetOffers(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_GET_OFFERS,
        payload: response
    }
}

export const resetOffersState = actionCreator(ActionTypes.RESET_OFFERS_STATE);
