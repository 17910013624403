import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from "@material-ui/core/FormControl";

const RenderCheckboxField = ({
                                 input,
                                 label,
                                 meta: { touched, error },
                             }) => (
    <FormControl >
        <FormControlLabel
            control={
                <Checkbox

                    checked={!!input.value}
                    onChange={(evt, checked) => {
                        input.onChange(checked)}
                    }
                />
            }
            label={label}
        />
        {(touched && error) && (
            <FormHelperText>{error}</FormHelperText>
        )}
    </FormControl>

);

export default RenderCheckboxField
