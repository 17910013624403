import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import usePrevious from "../../hooks/usePrevious";
import _ from "lodash";
import { parseCategories, parseLogin, parsePointsMultiplier, parsePointsMultiplierForm, parseProducts } from "../../helpers/parseImmutables";
import { useHistory } from "react-router-dom";
import { getTablePage, _getSkipFilter } from "../../helpers/functions";
import PointsMultiplierForm from "../../components/PointsMultiplier/pointsMultiplierForm";
import {
    changePointsMultiplierFormSettings,
    changePointsMultiplierFormData,
    resetMultipliersState,
    deletePointMultiplierById
} from "../../actions/pointsMultiplierForm";
import { getMultipliers,getActiveMultipliers,changePointsMultiplierStoreSettings } from "../../actions/pointsMultiplier";
import { toGetCategories } from "../../actions/categories";
import { getProductBydId } from "../../actions/products";

import { useState } from "react";


const PointsMultiplierFormHOC = (props) => {
    const history = useHistory();
    const isEdit = props.isEdit;
    const prevFetching = usePrevious(props.pointsMultiplierForm.fetching);
    const prevResponseMessage= usePrevious(props.pointsMultiplierForm.responseMessage);
    const prevPage = usePrevious(props.pointsMultiplier.page);
    const prevRowsPerPage = usePrevious(props.pointsMultiplier.rowsPerPage);
    //const prevAllDay = usePrevious(props.pointsMultiplierForm.formData.allDay);
    const [deleteModalState, toggleDeleteModal] = useState(false);
    const [activeProductsInMultipliers, setActiveProducts] = useState();

    useEffect(() => {
        props.resetMultipliersState();
        // props.getMultipliers();
        props.getActiveMultipliers();
        props.toGetCategories(props.login.userInfo.brand.id, props.login.userInfo.token);
    }, []);

    
    useEffect(() => {
         // props.resetPointsMultiplierNewState();
      props.getMultipliers({
        filter: {
        offset:props.pointsMultiplier.page -1,
          limit: props.pointsMultiplier.rowsPerPage,
        },
      });
        //   return () => {
        //     props.resetPointsMultiplierNewState();
        // }
    }, []);
  
  
    useEffect(() => {
      
      if (
        props.pointsMultiplier.page !== prevPage ||
        props.pointsMultiplier.rowsPerPage !== prevRowsPerPage
      ) {
        props.getMultipliers({
          filter: {
            offset:props.pointsMultiplier.page - 1,
            limit: props.pointsMultiplier.rowsPerPage,
          },
        });
      }
    }, [
      props.pointsMultiplier.page,
      props.pointsMultiplier.rowsPerPage,
      prevPage,
      prevRowsPerPage,
    ]);

    useEffect(() => {
        if(!props.pointsMultiplierForm.fetching && prevFetching && 
            (props.pointsMultiplierForm.responseMessage == 'Multiplier was created successfully'|| 
        props.pointsMultiplierForm.responseMessage =="Multiplier deleted successfully" ||
        props.pointsMultiplierForm.responseMessage =="Multiplier updated successfully"))
        {
            history.push('../pointsMultiplier')
        }
    }, [props.pointsMultiplierForm.fetching ,prevFetching,props.pointsMultiplierForm.responseMessage,prevResponseMessage]);


    const handleInputChange = (evt, name) => {
        props.changePointsMultiplierFormData({
            key: evt.target.name,
            value: evt.target.value,
        });
    };

    const handleRowSelect = (multiplierId) => {
        history.push("/pointsMultiplier/" + multiplierId);
      };

      const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.pointsMultiplier.multipliersCount / rowsPerPage);
        let currentPage = props.pointsMultiplier.page;
        if (parseInt(currentPage) > newTotalPages) {
          currentPage = newTotalPages;
        }
        props.changePointsMultiplierStoreSettings("rowsPerPage", rowsPerPage);
        props.changePointsMultiplierStoreSettings("page", currentPage);
      };
      const handlePageChange = (iconType) => {
        let newPage = getTablePage(
          iconType,
          props.pointsMultiplier.page,
          props.pointsMultiplier.multipliersCount,
          props.pointsMultiplier.rowsPerPage // props.clients.rowsPerPage
        );
        props.changePointsMultiplierStoreSettings("page", newPage);
      };



const handleDeleteMultiplier = (multiplierId) => {
    props.deletePointMultiplierById(props.selectedMultiplierId)
}

     const getSelectedTargetForPointMultiplier = (multiplier) =>
  {
    
    var selection;
    if(multiplier.categoryId !=null)
    {
    
        var category = props.allCategories.find(c => c.id == multiplier.categoryId);
        selection = category;
       // setActiveProducts( [...activeProductsInMultipliers,selection]);
      } 
      else if(multiplier.productId !=null)
      {
        props.getProductBydId(multiplier.productId,props.login.userInfo.token);

        // selection = category && category.name;
      }
   
  }

    const handleValidationMessageClose = () => {

        props.changePointsMultiplierFormSettings("validationMessage", '');
        props.changePointsMultiplierFormSettings("responseMessage", '');

    }

    const validateForm = () => {
        var isValid = true;
        var failMessage = "";

        if (props.pointsMultiplierForm.formData.title.trim().length == 0) {
            failMessage = failMessage + "Title field cannot be empty.";
            isValid = false;
        }
        if (props.pointsMultiplierForm.formData.startDate.trim().length == 0 || props.pointsMultiplierForm.formData.endDate.trim().length == 0) { failMessage = failMessage + " Start/End date fields cannot be empty."; isValid = false; }

        if (
            //props.pointsMultiplierForm.formData.allDay == false &&
            (props.pointsMultiplierForm.formData.endTime.trim().length == 0 ||
                props.pointsMultiplierForm.formData.startTime.trim().length == 0)) { failMessage = failMessage + "Please select time range."; isValid = false; }

        if (
            //props.pointsMultiplierForm.formData.allDay == false &&
            props.pointsMultiplierForm.formData.endTime.trim().length != 0 &&
            props.pointsMultiplierForm.formData.startDate.trim().length != 0) {
            if (props.pointsMultiplierForm.formData.startTime >= props.pointsMultiplierForm.formData.endTime) {
                failMessage = failMessage + "End time must be after start time. "; isValid = false;
            }
        }

        if ((props.pointsMultiplierForm.formData.multiplierValue == '')) { failMessage = failMessage + "Please select multiplier amount. "; isValid = false; }

        if ((props.pointsMultiplierForm.formData.targetGroup == '')) { failMessage = failMessage + "Please select a target group. "; isValid = false; }
        
        if((props.pointsMultiplierForm.formData.targetGroup == 'categoryBased' && props.pointsMultiplierForm.formData.selectedCategory == '' )) { failMessage = failMessage + "Please select a category group."; isValid = false; }
        if((props.pointsMultiplierForm.formData.targetGroup == 'productBased' && (props.pointsMultiplierForm.formData.selectedCategory == '' ||props.pointsMultiplierForm.formData.selectedProduct == ''  ))) { failMessage = failMessage + "Please select a product."; isValid = false; }

        if (failMessage.length > 150) {
            failMessage = "Please fill all the required fields."
        }
        props.changePointsMultiplierFormSettings("validationMessage", failMessage);
        return isValid
    }
    const validatedSubmit = () => {
        if (validateForm()) {
            props.handleSubmit();
        }

    }
    return (
        <PointsMultiplierForm
            {...props}
            inputChange={handleInputChange}
            handleSubmit={validatedSubmit}
            handleValidationMessageClose={handleValidationMessageClose}
            getSelectedTargetForPointMultiplier={getSelectedTargetForPointMultiplier}
            isEdit = {isEdit}
            toggleDeleteModal={toggleDeleteModal}
            deleteModalState={deleteModalState}
            handleDeleteMultiplier={handleDeleteMultiplier}
            activeProductsInMultipliers={activeProductsInMultipliers}
            handleRowSelect={handleRowSelect}
            handleRowsPerPage={handleRowsPerPage}
            handlePageChange={handlePageChange}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
        pointsMultiplierForm: parsePointsMultiplierForm(state.pointsMultiplierForm),
        pointsMultiplier: parsePointsMultiplier(state.pointsMultiplier),
        allCategories: parseCategories(state.categories).all,
        products: parseProducts(state.products)

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePointsMultiplierFormData: (key, value) => {
            dispatch(changePointsMultiplierFormData(key, value));
        },
        changePointsMultiplierFormSettings: (key, value) => {
            dispatch(changePointsMultiplierFormSettings(key, value));
        },
        toGetCategories: (brandId, token) => {
            dispatch(toGetCategories(brandId, token))
        },
        resetMultipliersState: (payload) => {
            dispatch(resetMultipliersState(payload));
        },
        getMultipliers: (payload) => {
            dispatch(getMultipliers(payload));
        },
        getActiveMultipliers: (payload) => {
            dispatch(getActiveMultipliers(payload));
        },
        deletePointMultiplierById: (payload) => {
            dispatch(deletePointMultiplierById(payload));
        },
        getProductBydId: (productId,token) => {
            dispatch(getProductBydId(productId,token));
        },
        changePointsMultiplierStoreSettings: (key, value) => {
            dispatch(changePointsMultiplierStoreSettings(key, value));
          },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsMultiplierFormHOC);
