import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import ProductList from "../../components/Products/productList";
import {
    changeProductFormSettings, countProducts, gettingAllProducts, resetProductsState,
    showingEditProductForm
} from "../../actions/products";
import {toGetCategories} from "../../actions/categories";
import _ from 'lodash';
import {_getSkipFilter, getTablePage} from "../../helpers/functions";
import {parseCategories, parseLogin, parseProducts} from "../../helpers/parseImmutables";
import usePrevious from "../../hooks/usePrevious";
import {useHistory} from "react-router-dom";

const ProductListHOC = props => {
    const prevPage = usePrevious(props.products.page);
    const prevRowsPerPage = usePrevious(props.products.rowsPerPage);
    let history = useHistory();

    useEffect(() => {
        return () => {
            props.resetProductsState();
        }
    }, [])

    useEffect(() => {
        props.countProducts({
            token: props.login.userInfo.token,
            brandId: props.login.userInfo.brand.id
        });
    }, [])

    useEffect(() => {
        if(
            props.products.page !== prevPage
            || props.products.rowsPerPage !== prevRowsPerPage
        ){
            props.getProducts(props.login.userInfo.brand.id, props.login.userInfo.token, {
                skip: _getSkipFilter(props.products.page, props.products.rowsPerPage),
                limit: props.products.rowsPerPage
            });
        }
    },[
        prevPage,
        props.products.page,
        prevRowsPerPage,
        props.products.rowsPerPage,
        props.login.userInfo.brand.id,
        props.login.userInfo.token,
        props.getProducts,
        _getSkipFilter
    ])

    const getProductArray = (withCategoryFilter) => {
        let productArray = [];
        let arr = [];
        let allProducts = [...props.allProducts];
        if (withCategoryFilter) {
            for (let index in props.allProducts) {
                if (this.props.categories.all[props.allProducts[index].cid].brid === props.login.userInfo.brid && props.allProducts[index].cid === withCategoryFilter) {
                    productArray.push({
                        key: props.allProducts[index].pid,
                        value: props.allProducts[index].product_name
                    })
                }

            }
        }
        else {
            _.forEach(allProducts, product => {
                productArray.push({
                    id: product.id,
                    data: [
                        product.name,
                        product.description,
                        _.reduce(product.categories, (previous, current) => {
                            if(previous === '') return current.name;
                            return previous  + ', ' + current.name
                        }, ''),
                        product.points,
                        product.value,
                        product.erpId,
                    ],
                    label: product.name
                })
            });
        }
        return productArray;
    };

    const handleRowSelect = (productId) => {
        props.showEditProductForm(productId);
        history.push('/product/edit/' + productId)

    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.products.totalProducts / rowsPerPage);
        let currentPage = props.products.page;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeStoreSettings('rowsPerPage', rowsPerPage);
        props.changeStoreSettings('page', currentPage);
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.products.page, props.products.totalProducts, props.products.rowsPerPage);
        props.changeStoreSettings('page', newPage);
    };

    let tableColumns = [
        'product name',
        'description',
        'category',
        'points',
        'value',
        'erp id',
    ];
    return (
        <ProductList
            page={props.products.page}
            totalRows={props.products.totalProducts}
            handlePageChange={handlePageChange}
            rowsPerPage={props.products.rowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            isFetching={props.products.isFetching}
            handleRowSelect={handleRowSelect}
            tableColumns={tableColumns}
            products={getProductArray()}
            userRole={props.login.userInfo.role}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        allProducts: parseProducts(state.products).all,
        login: parseLogin(state.login),
        products: parseProducts(state.products),
        categories: parseCategories(state.categories)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProducts: (brandId, token, filter) => {
            dispatch(gettingAllProducts(brandId, token, filter));
        },
        getAllCategories: (brandId, token) => {
            dispatch(toGetCategories(brandId, token))
        },
        showEditProductForm: (product) => {
            dispatch(showingEditProductForm(product));
        },
        changeStoreSettings: (key, value) => {
            dispatch(changeProductFormSettings(key, value))
        },
        countProducts: (payload) => {
            dispatch(countProducts(payload))
        },
        resetProductsState: (payload) => {
            dispatch(resetProductsState(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductListHOC);

