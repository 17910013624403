import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import Editors from "../../components/Editors/editors";
import {changeEditorsOrder, changeEditorsState, countEditors, getEditors} from "../../actions/editors";
import {toJS} from "../../components/General/toJs";
import _ from "lodash";
import {_getSkipFilter, getTablePage} from "../../helpers/functions";
import {useHistory} from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";

const listColumns = [
    {name: 'Person Id', key: 'personId'},
    {name: 'Username', key: 'username', excludeFromSorting: true},
    {name: 'Email', key: 'email', excludeFromSorting: true}
];

const EditorsHOC = props => {
    const history = useHistory();
    const prevPage = usePrevious(props.page);
    const prevRowsPerPage = usePrevious(props.rowsPerPage);
    const prevOrderBy = usePrevious(props.orderBy);
    const prevOrder = usePrevious(props.order);

    useEffect(() => {
        props.countEditors();
    }, []);

    useEffect(() => {
        if(
            props.page !== prevPage
            || props.rowsPerPage !== prevRowsPerPage
            || props.orderBy !== prevOrderBy
            || props.order !== prevOrder
        ) {
            props.getEditors({
                filter: {
                    include: 'person',
                    skip: _getSkipFilter(props.page, props.rowsPerPage),
                    limit: props.rowsPerPage,
                    order: listColumns[props.orderBy].key + ' ' + props.order.toUpperCase()
                }
            });
        }
    }, [
        prevPage,
        prevRowsPerPage,
        props.page,
        props.rowsPerPage,
        props.orderBy,
        props.order,
        prevOrderBy,
        prevOrder,
    ]);

    const getListRows = () => {
        return props.editors.map(editor => ({
            id: editor.personId,
            data: [
                editor.personId,
                editor.person.username,
                editor.person.email,
            ],
            label: `Person id: ${editor.personId}`
        }))
    };

    const handleRowSelect = (personId) => {
        history.push('/editors/' + personId)

    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.totalEditors / rowsPerPage);
        let currentPage = props.page;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeEditorsState({key: 'rowsPerPage', value: rowsPerPage});
        props.changeEditorsState({key: 'page', value: currentPage});
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.page,  props.totalEditors, props.rowsPerPage);
        props.changeEditorsState({key: 'page', value: newPage});
    };

    const onRequestSort = (payload) => {
        props.countEditors();
        props.changeEditorsOrder(payload);
    };

    return (
        <Editors
            {...props}
            listRows={getListRows()}
            listColumns={listColumns}
            handlePageChange={handlePageChange}
            handleRowsPerPage={handleRowsPerPage}
            handleRowSelect={handleRowSelect}
            onRequestSort={onRequestSort}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        editors: state.editors.get('editors'),
        fetchingEditors: state.editors.get('fetching'),
        totalEditors: state.editors.get('totalEditors'),
        page: state.editors.get('page'),
        rowsPerPage: state.editors.get('rowsPerPage'),
        order: state.editors.get('order'),
        orderBy: state.editors.get('orderBy'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEditors: (payload) => {
            dispatch(getEditors(payload))
        },
        countEditors: (payload) => {
            dispatch(countEditors(payload))
        },
        changeEditorsState: (payload) => {
            dispatch(changeEditorsState(payload))
        },
        changeEditorsOrder: (payload) => {
            dispatch(changeEditorsOrder(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(EditorsHOC));