import React, {Component, useEffect} from "react";
import { connect } from "react-redux";
import _, { isArray } from 'lodash';

import {
  changeCampaignFormData,editCampaign,editAction,changeCampaignFormSettings
} from "../../actions/campaignsNewForm";
import {
  parseLogin,
  parseCampaigns,
  parseCampgaignNewForm,
  parseClients,
  parseCampaignNewOverview
} from "../../helpers/parseImmutables";
import {
  GreekToGreeklish
} from "../../helpers/functions";
import CampaignNewFormHOC from "./campaignNewFormHOC";
import { useHistory,useParams } from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";
import moment from "moment/moment";




const tableColumns = [
  //  { id: 'checkbox', numeric: true, disablePadding: true, label: '' },
  { id: "id", numeric: true, disablePadding: true, label: "Person ID" },
  { id: "username", numeric: false, disablePadding: false, label: "Username" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "telephone", numeric: true, disablePadding: false, label: "Telephone" },
];


const CampaignNewEditHOC = props => {
  const { id } = useParams();

  const history = useHistory();
  const prevEditCampaignResponseMessage=usePrevious(props.campaignNewForm.editCampaignResponseMessage);
  const prevEditActionResponseMessage=usePrevious(props.campaignNewForm.editActionResponseMessage);

useEffect(()=>{
if(props.campaignNewForm.editActionResponseMessage=="Action was updated successfully." && !prevEditActionResponseMessage){
  props.editCampaign({
    sendBody: {
      id:props.campaignNewForm.formData.id,
      title: props.campaignNewForm.formData.title,
      description: props.campaignNewForm.formData.description,
      image: props.campaignNewForm.formData.image,
      campaignPlanToUpdateDTO: {
        UtmCampaignName: props.campaignNewForm.formData.title
            .toLowerCase()
            .split(" ")
            .join("_"),

        //ActionIds: props.campaignNewForm.campaignActions,
        SelectAll: props.campaignNewForm.formData.selectAll,
        ListIds: props.campaignNewForm.formData.selectedUsers,
        Tags: props.campaignNewForm.formData.selectedTags,
        StartDate: props.campaignNewForm.formData.startDate,
        EndDate: props.campaignNewForm.formData.endDate,
      },
    },
  });
}
},[props.campaignNewForm.editActionResponseMessage,prevEditActionResponseMessage])

  useEffect(() => {
    if(!props.campaignNewForm.editCampaignResponseMessage && prevEditCampaignResponseMessage==="Campaign was updated successfully."
    && props.campaignNewForm.editActionResponseMessage==="Action was updated successfully.")
    {
history.push("../"+id);
    }
  },[prevEditCampaignResponseMessage,props.campaignNewForm.editCampaignResponseMessage,
    prevEditActionResponseMessage,props.campaignNewForm.editCampaignResponseMessage]);
    useEffect(()=> {
      var actionsSelectedArray = Array.from(props.campaignNewForm.formData.actionsSelected);
      if(props.campaignOverview.form.campaignActions.some(ca => ca.actionType == "Push Notification") && props.campaignNewForm.formData.notificationTitle=="" )
      {
        actionsSelectedArray.push('Push Notification');
        var pushNotification = props.campaignOverview.form.campaignActions.filter(ca => ca.actionType ==="Push Notification");
props.changeCampaignFormData({
      key: 'notificationTitle',
      value:pushNotification.length!=0? pushNotification[0].notificationTitle:" ",
    });

    props.changeCampaignFormData({
      key: 'notificationText',
      value:pushNotification.length!=0? pushNotification[0].notificationDescription:" ",
    });
    props.changeCampaignFormData({
      key: 'notificationImage',
      value:pushNotification.length!=0? pushNotification[0].notificationImage:" ",
    });
    props.changeCampaignFormData({
      key: 'notificationBigImage',
      value:pushNotification.length!=0? pushNotification[0].notificationBigImage:" ",
    });
    props.changeCampaignFormData({
      key: 'url',
      value:pushNotification.length!=0? pushNotification[0].url.split('?')[0]:" ",
    });
    props.changeCampaignFormData({
      key: 'actionDate',
      value:pushNotification.length!=0 ? moment(moment.utc( pushNotification[0].timestamp).toDate()).local().format('YYYY-MM-DDTHH:mm:ss').split('T')[0]:" ",
    });
    props.changeCampaignFormData({
      key: 'actionTime',
      value: pushNotification.length!=0 ? moment(moment.utc( pushNotification[0].timestamp).toDate()).local().format('YYYY-MM-DDTHH:mm:ss').split('T')[1]:" ",
    });
    props.changeCampaignFormData({
      key: 'actionsSelected',
      value: actionsSelectedArray,
    });
  }
  if(props.campaignOverview.form.campaignActions.some(ca => ca.actionType == "Newsletter") && props.campaignNewForm.formData.emailContent =="")
  {
    actionsSelectedArray.push('Newsletter');

    var newsletterAction = props.campaignOverview.form.campaignActions.filter(ca => ca.actionType ==="Newsletter");
    props.changeCampaignFormData({
      key: 'emailContent',
      value:newsletterAction.length!=0? newsletterAction[0].htmlContent:" ",
    });
    props.changeCampaignFormData({
      key: 'emailFrom',
      value:newsletterAction.length!=0? newsletterAction[0].sender:" ",
    });
    props.changeCampaignFormData({
      key: 'emailSubject',
      value:newsletterAction.length!=0? newsletterAction[0].subject:" ",
    });
    props.changeCampaignFormData({
      key: 'emailDate',
      value:newsletterAction.length!=0? moment(moment.utc( newsletterAction[0].timestamp).toDate()).local().format('YYYY-MM-DDTHH:mm:ss').split('T')[0]:" ",
    });
    props.changeCampaignFormData({
      key: 'emailTime',
      value:newsletterAction.length!=0? moment(moment.utc( newsletterAction[0].timestamp).toDate()).local().format('YYYY-MM-DDTHH:mm:ss').split('T')[1]:" ",
    });
    props.changeCampaignFormData({
      key: 'actionsSelected',
      value: actionsSelectedArray,
    });
  }
    },[props.campaignOverview.form.campaignActions,props.campaignNewForm.formData.notificationTitle,props.campaignNewForm.formData.emailContent])





  useEffect(() => {
    props.changeCampaignFormData({
      key: 'title',
      value: props.campaignOverview.form.title,
    });
    props.changeCampaignFormData({
      key: 'description',
      value: props.campaignOverview.form.description,
    });
    props.changeCampaignFormData({
      key: 'image',
      value: props.campaignOverview.form.image,
    });
    props.changeCampaignFormData({
      key: 'startDate',
      value: props.campaignOverview.form.startDate.split('T')[0],
    });
    props.changeCampaignFormData({
      key: 'endDate',
      value: props.campaignOverview.form.endDate.split('T')[0],
    });
    props.changeCampaignFormData({
      key: 'id',
      value: props.campaignOverview.form.id,
    });
    
    
    props.changeCampaignFormData({
      key: 'selectedActionType',
      value: props.campaignOverview.form.campaignActions.length!=0?props.campaignOverview.form.campaignActions[0].actionType: " ",
    });
  },[])


  const handleSubmit = (evt) => {
    // to dotnet
    // evt.preventDefault();
    // if(evt.currentTarget.name==='submit')
    // { }

    props.campaignOverview.form.campaignActions.map(action=>

action.actionType ==="Push Notification"?
(props.editAction({

   sendBody:{
    Id:action.id,
    utmCampaignSource:
    GreekToGreeklish(props.campaignNewForm.formData.title),
    ActionType: "Push Notification",
    SelectAll: props.campaignNewForm.formData.selectAll,
    ActionUserIds:props.campaignNewForm.formData.selectedUsers,
    Timestamp: moment.utc( moment(props.campaignNewForm.formData.actionDate + "T" + props.campaignNewForm.formData.actionTime )),
    tags: props.campaignNewForm.formData.actionTags,

    pushNotificationToUpdateDTO: {
      id:action.messageId,
      title: props.campaignNewForm.formData.notificationTitle,
      description: props.campaignNewForm.formData.notificationText,
      image: props.campaignNewForm.formData.notificationImage,
      imageType:props.campaignNewForm.formData.notificationImageType,
      bigImageType:props.campaignNewForm.formData.notificationBigImageType,
      bigImage: props.campaignNewForm.formData.notificationBigImage,
      url:props.campaignNewForm.formData.url.trim(),
    },
   }
 }))
 :action.actionType ==="Newsletter"?
 (props.editAction({

  sendBody:{
   Id:action.id,
   utmCampaignSource:
   GreekToGreeklish(props.campaignNewForm.formData.title),
   ActionType: "Newsletter",
   SelectAll: props.campaignNewForm.formData.selectAll,
   ActionUserIds:props.campaignNewForm.formData.selectedUsers,
   Timestamp: moment.utc( moment(props.campaignNewForm.formData.emailDate + "T" + props.campaignNewForm.formData.emailTime )),
   tags: props.campaignNewForm.formData.actionTags,
   emailToSendDTO: {
            id:action.messageId,
            emailFrom:  props.campaignNewForm.formData.emailFrom,
            emailSubject:  props.campaignNewForm.formData.emailSubject,
           // emailDate:  props.campaignNewForm.formData.emailDate,
           // emailTime:  props.campaignNewForm.formData.emailTime,
            emailContent:  props.campaignNewForm.formData.emailContent,
          },
  }
}))
:null
 )
  }

  return (
      <CampaignNewFormHOC
          handleSubmit={handleSubmit}
          initialValues={props.campaignOverview.form}

      />
  )
}


const mapStateToProps = (state) => {
  return {
    campaignOverview: parseCampaignNewOverview(state.campaignNewOverview),
    campaignNewForm:parseCampgaignNewForm(state.campaignNewForm),
    token: parseLogin(state.login).userInfo.token,
    userRole: state.login.getIn(['userInfo', 'role', 'name']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCampaignFormData: (key, value) => {
      dispatch(changeCampaignFormData(key, value));
    },
    editCampaign: (payload) => {
      dispatch(editCampaign(payload));
    },
    editAction:(payload)=>{
      dispatch(editAction(payload));
    },
    changeCampaignFormSettings: (key, value) => {
      dispatch(changeCampaignFormSettings(key, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignNewEditHOC);
