import { fromJS } from "immutable";
import { get } from "lodash";
import { PDF_TYPES } from "./constants/productAttachmentTypes";

export const parseEnabledOffers = (enabledOffers) => ({
  fetching: enabledOffers.get("fetching"),
  enabledOffers: enabledOffers
    .get("enabledOffers")
    .valueSeq()
    .toArray()
    .map((enabledOffer) => ({
      id: enabledOffer.get("id"),
      personId: enabledOffer.get("personId"),
      offerId: enabledOffer.get("offerId"),
      pointsWhenEnabled: enabledOffer.get("pointsWhenEnabled"),
      redeemed: enabledOffer.get("redeemed"),
      couponCode: enabledOffer.get("couponCode"),
      issueDate: enabledOffer.get("issueDate"),
      erpCouponId: enabledOffer.get("erpCouponId"),
      expired: enabledOffer.get("expired"),
      couponStartDate: enabledOffer.get("couponStartDate"),
      couponEndDate: enabledOffer.get("couponEndDate"),
      offer: {
        id: enabledOffer.getIn(["offer", "id"]),
        name: enabledOffer.getIn(["offer", "name"]),
        pointsRequired: enabledOffer.getIn(["offer", "pointsRequired"]),
        discount: enabledOffer.getIn(["offer", "discount"]),
        discountType: {
          id: enabledOffer.getIn(["offer", "discountType", "id"]),
          name: enabledOffer.getIn(["offer", "discountType", "name"]),
        },
      },
    })),
  totalEnabledOffers: enabledOffers.get("totalEnabledOffers"),
  rowsPerPage: enabledOffers.get("rowsPerPage"),
  page: enabledOffers.get("page"),
  orderBy: enabledOffers.get("orderBy"),
  order: enabledOffers.get("order"),
});

export const parseTellerOverview = (tellerOverview) => ({
  activeTab: tellerOverview.get("activeTab"),
});

export const parseTellers = (tellers) => ({
  fetchingTeller: tellers.get("fetchingTeller"),
  fetching: tellers.get("fetching"),
  tellers: tellers
    .get("tellers")
    .valueSeq()
    .toArray()
    .map((teller) => ({
      id: teller.get("id"),
      username: teller.get("username"),
      email: teller.get("email"),
      stores: teller
        .get("stores")
        .valueSeq()
        .toArray()
        .map((store) => ({
          id: store.get("id"),
          name: store.get("name"),
          location: store.get("location"),
          telephone: store.get("telephone"),
          position: {
            lng: store.getIn(["position", "lng"]),
            lat: store.getIn(["position", "lat"]),
          },
        })),
    })),
  rowsPerPage: tellers.get("rowsPerPage"),
  page: tellers.get("page"),
  totalTellers: tellers.get("totalTellers"),
  teller: {
    id: tellers.getIn(["teller", "id"]),
    username: tellers.getIn(["teller", "username"]),
    email: tellers.getIn(["teller", "teller"]),
    stores: tellers
      .getIn(["teller", "stores"])
      .valueSeq()
      .toArray()
      .map((store) => ({
        id: store.get("id"),
        name: store.get("name"),
        location: store.get("location"),
        telephone: store.get("telephone"),
        position: {
          lng: store.getIn(["position", "lng"]),
          lat: store.getIn(["position", "lat"]),
        },
      })),
  },
  tellerStoresRowsPerPage: tellers.get("tellerStoresRowsPerPage"),
  tellerStoresPage: tellers.get("tellerStoresPage"),
  fetchingCountTellers: tellers.get("fetchingCountTellers"),
});

export const parseClients = (clients) => ({
  deleting: clients.get("deleting"),
  filterField: clients.get("filterField"),
  orderBy: clients.get("orderBy"),
  order: clients.get("order"),
  fetching: clients.get("fetching"),
  fetchingTotalClients: clients.get("fetchingTotalClients"),
  page: clients.get("page"),
  rowsPerPage: clients.get("rowsPerPage"),
  totalClients: clients.get("totalClients"),
  clients: clients
    .get("clients")
    .valueSeq()
    .toArray()
    .map((client) => {
      return {
        id: client.get("id"),
        email: client.get("email"),
        emailVerified: client.get("emailVerified"),
        country: client.get("country"),
        address: client.get("address"),
        firstName: client.get("firstName"),
        lastName: client.get("lastName"),
        mobilePersonId: client.get("mobilePersonId"),
        points: client.get("points"),
        leafs: client.get("leafs"),
        flowers: client.get("flowers"),
        badges: client.get("badges"),
        telephone: client.get("telephone"),
        username: client.get("username"),
        creationDate: client.get("creationDate"),
      };
    }),
  suggestions: clients
    .get("suggestions")
    .valueSeq()
    .toArray()
    .map((suggestion) => ({
      id: suggestion.get("id"),
      email: suggestion.get("email"),
      emailVerified: suggestion.get("emailVerified"),
      country: suggestion.get("country"),
      address: suggestion.get("address"),
      firstName: suggestion.get("firstName"),
      lastName: suggestion.get("lastName"),
      mobilePersonId: suggestion.get("mobilePersonId"),
      points: suggestion.get("points"),
      leafs: suggestion.get("leafs"),
      flowers: suggestion.get("flowers"),
      badges: suggestion.get("badges"),
      telephone: suggestion.get("telephone"),
      username: suggestion.get("username"),
    })),
});

export const parseClientOverview = (clientOverview) => ({
  enabledOffer: {
    id: clientOverview.getIn(["enabledOffer", "id"]),
    personId: clientOverview.getIn(["enabledOffer", "personId"]),
    offerId: clientOverview.getIn(["enabledOffer", "offerId"]),
    pointsWhenEnabled: clientOverview.getIn([
      "enabledOffer",
      "pointsWhenEnabled",
    ]),
    redeemed: clientOverview.getIn(["enabledOffer", "redeemed"]),
    couponCode: clientOverview.getIn(["enabledOffer", "couponCode"]),
    issueDate: clientOverview.getIn(["enabledOffer", "issueDate"]),
    erpCouponId: clientOverview.getIn(["enabledOffer", "erpCouponId"]),
    expired: clientOverview.getIn(["enabledOffer", "expired"]),
    couponStartDate: clientOverview.getIn(["enabledOffer", "couponStartDate"]),
    couponEndDate: clientOverview.getIn(["enabledOffer", "couponEndDate"]),
  },
  deleteEnabledOfferResponse: clientOverview.get("deleteEnabledOfferResponse"),
  deletingEnabledOffer: clientOverview.get("deletingEnabledOffer"),
  fetchingClientEnabledOfferById: clientOverview.get(
    "fetchingClientEnabledOfferById"
  ),
  fetching: clientOverview.get("fetching"),
  ordersListPage: clientOverview.get("ordersListPage"),
  ordersListRowsPerPage: clientOverview.get("ordersListRowsPerPage"),
  fetchingEnabledOffers: clientOverview.get("fetchingEnabledOffers"),
  enabledOffers: clientOverview
    .get("enabledOffers")
    .valueSeq()
    .toArray()
    .map((offer) => ({
      id: offer.get("id"),
      brandId: offer.get("brandId"),
      name: offer.get("name"),
      startDate: offer.get("startDate"),
      endDate: offer.get("endDate"),
      promoted: offer.get("promoted"),
      pointsRequired: offer.get("pointsRequired"),
      discountId: offer.get("discountId"),
      discount: offer.get("discount"),
      deleted: offer.get("deleted"),
      redeemPoints: offer.get("redeemPoints"),
      recurring: offer.get("recurring"),
      createdDate: offer.get("createdDate"),
      discountType: {
        id: offer.getIn(["discountType", "id"]),
        name: offer.getIn(["discountType", "name"]),
      },
      couponCode: offer.get("couponCode"),
      erpCouponId: offer.get("erpCouponId"),
      issueDate: offer.get("issueDate"),
      enabledOfferId: offer.get("enabledOfferId"),
    })),
  orders: clientOverview
    .get("orders")
    .valueSeq()
    .toArray()
    .map((order) => ({
      id: order.get("id"),
      date: order.get("date"),
      mobilePersonId: order.get("mobilePersonId"),
      storeId: order.get("storeId"),
      tellerId: order.get("tellerId"),
      totalPoints: order.get("totalPoints"),
      totalValue: order.get("totalValue"),
      store: {
        id: order.getIn(["store", "id"]),
        name: order.getIn(["store", "name"]),
        telephone: order.getIn(["store", "telephone"]),
        location: order.getIn(["store", "location"]),
        deleted: order.getIn(["store", "deleted"]),
        brandId: order.getIn(["store", "brandId"]),
        brand: {
          id: order.getIn(["store", "brand", "id"]),
          avatar: order.getIn(["store", "brand", "avatar"]),
          description: order.getIn(["store", "brand", "description"]),
          email: order.getIn(["store", "brand", "email"]),
          name: order.getIn(["store", "brand", "name"]),
          telephone: order.getIn(["store", "brand", "telephone"]),
        },
        position: {
          lng: order.getIn(["store", "position", "lng"]),
          lat: order.getIn(["store", "position", "lat"]),
        },
      },
      lineItems: order
        .get("lineItems")
        .valueSeq()
        .toArray()
        .map((lineItem) => ({
          id: lineItem.get("id"),
          product: {
            id: lineItem.getIn(["product", "id"]),
            deleted: lineItem.getIn(["product", "deleted"]),
            description: lineItem.getIn(["product", "description"]),
            name: lineItem.getIn(["product", "name"]),
            points: lineItem.getIn(["product", "points"]),
            value: lineItem.getIn(["product", "value"]),
            startDate: lineItem.getIn(["product", "startDate"]),
            endDate: lineItem.getIn(["product", "endDate"]),
            imageName: lineItem.getIn(["product", "imageName"]),
            creationDate: lineItem.getIn(["product", "creationDate"]),
            expiryDate: lineItem.getIn(["product", "expiryDate"]),
            pointsApplyOnce: lineItem.getIn(["product", "pointsApplyOnce"]),
            erpId: lineItem.getIn(["product", "erpId"]),
          },
          price: lineItem.get("price"),
          quantity: lineItem.get("quantity"),
          productId: lineItem.get("productId"),
          orderId: lineItem.get("orderId"),
        })),
      teller: {
        email: order.getIn(["teller", "email"]),
        emailVerified: order.getIn(["teller", "emailVerified"]),
        id: order.getIn(["teller", "id"]),
        realm: order.getIn(["teller", "realm"]),
        username: order.getIn(["teller", "username"]),
      },
    })),
  form: {
    id: clientOverview.getIn(["form", "id"]),
    username: clientOverview.getIn(["form", "username"]),
    email: clientOverview.getIn(["form", "email"]),
    address: clientOverview.getIn(["form", "address"]),
    country: clientOverview.getIn(["form", "country"]),
    telephone: clientOverview.getIn(["form", "telephone"]),
    points: clientOverview.getIn(["form", "points"]),
    leafs: clientOverview.getIn(["form", "leafs"]),
    flowers: clientOverview.getIn(["form", "flowers"]),
    badges: clientOverview.getIn(["form", "badges"]),
    firstName: clientOverview.getIn(["form", "firstName"]),
    lastName: clientOverview.getIn(["form", "lastName"]),
  },
  totalOrders: clientOverview.get("totalOrders"),
  activeTab: clientOverview.get("activeTab"),
  clientExtraPoints: clientOverview.get("clientExtraPoints"),
  postingClientExtraPoints: clientOverview.get("postingClientExtraPoints"),
  postingClientExtraPointsFailMessage: clientOverview.get(
    "postingClientExtraPointsFailMessage"
  ),
  postingClientExtraPointsSuccessMessage: clientOverview.get(
    "postingClientExtraPointsSuccessMessage"
  ),
  totalOffers: clientOverview.get("totalOffers"),
  offersListRowsPerPage: clientOverview.get("offersListRowsPerPage"),
  offersListPage: clientOverview.get("offersListPage"),
  orderOrdersBy: clientOverview.get("orderOrdersBy"),
  ordersOrder: clientOverview.get("ordersOrder"),
});

export const parseOrders = (orders) => ({
  deleting: orders.get("deleting"),
  fetching: orders.get("fetching"),
  posting: orders.get("posting"),
  totalOrders: orders.get("totalOrders"),
  fetchingTotalOrders: orders.get("fetchingTotalOrders"),
  rowsPerPage: orders.get("rowsPerPage"),
  page: orders.get("page"),
  orders: orders
    .get("orders")
    .valueSeq()
    .toArray()
    .map((order) => ({
      id: order.get("id"),
      date: order.get("date"),
      totalPoints: order.get("totalPoints"),
      totalValue: order.get("totalValue"),
      store: {
        id: order.getIn(["store", "id"]),
        name: order.getIn(["store", "name"]),
        telephone: order.getIn(["store", "telephone"]),
        location: order.getIn(["store", "location"]),
        deleted: order.getIn(["store", "deleted"]),
        brandId: order.getIn(["store", "brandId"]),
        brand: {
          id: order.getIn(["store", "brand", "id"]),
          avatar: order.getIn(["store", "brand", "avatar"]),
          description: order.getIn(["store", "brand", "description"]),
          email: order.getIn(["store", "brand", "email"]),
          name: order.getIn(["store", "brand", "name"]),
          telephone: order.getIn(["store", "brand", "telephone"]),
        },
        position: {
          lng: order.getIn(["store", "position", "lng"]),
          lat: order.getIn(["store", "position", "lat"]),
        },
      },
      lineItems: order
        .get("lineItems")
        .valueSeq()
        .toArray()
        .map((lineItem) => ({
          id: lineItem.get("id"),
          deleted: lineItem.get("deleted"),
          description: lineItem.get("description"),
          startDate: lineItem.get("startDate"),
          endDate: lineItem.get("endDate"),
          imageName: lineItem.get("imageName"),
          quantity: lineItem.get("quantity"),
          value: lineItem.get("value"),
          price: lineItem.get("price"),
          name: lineItem.get("name"),
          points: lineItem.get("points"),
        })),
      teller: {
        email: order.getIn(["teller", "email"]),
        emailVerified: order.getIn(["teller", "emailVerified"]),
        id: order.getIn(["teller", "id"]),
        realm: order.getIn(["teller", "realm"]),
        username: order.getIn(["teller", "username"]),
      },
      mobilePerson: {
        email: order.getIn(["mobilePerson", "email"]),
        emailVerified: order.getIn(["mobilePerson", "emailVerified"]),
        id: order.getIn(["mobilePerson", "id"]),
        realm: order.getIn(["mobilePerson", "realm"]),
        username: order.getIn(["mobilePerson", "username"]),
      },
    })),
  orderBy: orders.get("orderBy"),
  order: orders.get("order"),
});

export const parseOrderOverview = (orderOverview) => ({
  isDeleteModalOpen: orderOverview.get("isDeleteModalOpen"),
  fetching: orderOverview.get("fetching"),
  lineItemsRowsPerPage: orderOverview.get("lineItemsRowsPerPage"),
  lineItemsPage: orderOverview.get("lineItemsPage"),
  order: {
    id: orderOverview.getIn(["order", "id"]),
    date: orderOverview.getIn(["order", "date"]),
    lineItems: orderOverview
      .getIn(["order", "lineItems"])
      .valueSeq()
      .toArray()
      .map((lineItem) => ({
        id: lineItem.get("id"),
        deleted: lineItem.get("deleted"),
        description: lineItem.get("description"),
        imageName: lineItem.get("imageName"),
        startDate: lineItem.get("startDate"),
        endDate: lineItem.get("endDate"),
        quantity: lineItem.get("quantity"),
        value: lineItem.get("value"),
        price: lineItem.get("price"),
        name: lineItem.get("name"),
        points: lineItem.get("points"),
        eprId: lineItem.get("erpId"),
        categories: lineItem.get("categories")
          ? lineItem
              .get("categories")
              .valueSeq()
              .toArray()
              .map((category) => ({
                id: category.get("id"),
                name: category.get("name"),
                brandId: category.get("brandId"),
                deleted: category.get("deleted"),
              }))
          : [],
      })),
    mobilePerson: {
      email: orderOverview.getIn(["order", "mobilePerson", "email"]),
      id: orderOverview.getIn(["order", "mobilePerson", "id"]),
      fistName: orderOverview.getIn(["order", "mobilePerson", "fistName"]),
      lastName: orderOverview.getIn(["order", "mobilePerson", "lastName"]),
      address: orderOverview.getIn(["order", "mobilePerson", "address"]),
      country: orderOverview.getIn(["order", "mobilePerson", "country"]),
      points: orderOverview.getIn(["order", "mobilePerson", "points"]),
      telephone: orderOverview.getIn(["order", "mobilePerson", "telephone"]),
      username: orderOverview.getIn(["order", "mobilePerson", "username"]),
    },
    store: {
      id: orderOverview.getIn(["order", "store", "id"]),
      name: orderOverview.getIn(["order", "store", "name"]),
      telephone: orderOverview.getIn(["order", "store", "telephone"]),
      location: orderOverview.getIn(["order", "store", "location"]),
      deleted: orderOverview.getIn(["order", "store", "deleted"]),
      brandId: orderOverview.getIn(["order", "store", "brandId"]),
      brand: {
        id: orderOverview.getIn(["order", "store", "brand", "id"]),
        avatar: orderOverview.getIn(["order", "store", "brand", "avatar"]),
        description: orderOverview.getIn([
          "order",
          "store",
          "brand",
          "description",
        ]),
        email: orderOverview.getIn(["order", "store", "brand", "email"]),
        name: orderOverview.getIn(["order", "store", "brand", "name"]),
        telephone: orderOverview.getIn([
          "order",
          "store",
          "brand",
          "telephone",
        ]),
      },
      position: {
        lng: orderOverview.getIn(["order", "store", "position", "lng"]),
        lat: orderOverview.getIn(["order", "store", "position", "lat"]),
      },
    },
    teller: {
      email: orderOverview.getIn(["order", "teller", "email"]),
      id: orderOverview.getIn(["order", "teller", "id"]),
      username: orderOverview.getIn(["order", "teller", "username"]),
      stores: orderOverview
        .getIn(["order", "teller", "stores"])
        .valueSeq()
        .toArray()
        .map((store) => ({
          id: store.get("id"),
          name: store.get("name"),
          telephone: store.get("telephone"),
          location: store.get("location"),
          deleted: store.get("deleted"),
          brandId: store.get("brandId"),
          brand: {
            id: store.getIn(["brand", "id"]),
            avatar: store.getIn(["brand", "avatar"]),
            description: store.getIn(["brand", "description"]),
            email: store.getIn(["brand", "email"]),
            name: store.getIn(["brand", "name"]),
            telephone: store.getIn(["brand", "telephone"]),
          },
          position: {
            lng: store.getIn(["position", "lng"]),
            lat: store.getIn(["position", "lat"]),
          },
        })),
    },
    totalPoints: orderOverview.getIn(["order", "totalPoints"]),
    totalValue: orderOverview.getIn(["order", "totalValue"]),
  },
  activeTab: orderOverview.get("activeTab"),
});

export const parseProducts = (products) => ({
  isFetching: products.get("isFetching"),
  all: products
    .get("all")
    .valueSeq()
    .toArray()
    .map((product) => ({
      deleted: product.get("deleted"),
      description: product.get("description"),
      id: product.get("id"),
      erpId: product.get("erpId"),
      imageName: product.get("imageName"),
      name: product.get("name"),
      startDate: product.get("startDate"),
      endDate: product.get("endDate"),
      points: product.get("points"),
      pointsApplyOnce: product.get("pointsApplyOnce"),
      value: product.get("value"),
      categories: product
        .get("categories")
        .valueSeq()
        .toArray()
        .map((category) => ({
          id: category.get("id"),
          name: category.get("name"),
          brandId: category.get("brandId"),
          deleted: category.get("deleted"),
        })),
    })),
  adding: products.get("adding"),
  msg: products.get("msg"),
  newProduct: {
    attachment: {
      name: products.getIn(["newProduct", "attachment", "name"]),
      type: products.getIn(["newProduct", "attachment", "type"]),
      file: products.getIn(["newProduct", "attachment", "file"]),
      base64: products.getIn(["newProduct", "attachment", "base64"]),
    },
    [PDF_TYPES.PRODUCT_SPECIFICATIONS]: {
      name: products.getIn([
        "newProduct",
        PDF_TYPES.PRODUCT_SPECIFICATIONS,
        "name",
      ]),
      type: products.getIn([
        "newProduct",
        PDF_TYPES.PRODUCT_SPECIFICATIONS,
        "type",
      ]),
      file: products.getIn([
        "newProduct",
        PDF_TYPES.PRODUCT_SPECIFICATIONS,
        "file",
      ]),
      base64: products.getIn([
        "newProduct",
        PDF_TYPES.PRODUCT_SPECIFICATIONS,
        "base64",
      ]),
    },
    [PDF_TYPES.PRODUCT_ELEMENTS]: {
      name: products.getIn(["newProduct", PDF_TYPES.PRODUCT_ELEMENTS, "name"]),
      type: products.getIn(["newProduct", PDF_TYPES.PRODUCT_ELEMENTS, "type"]),
      file: products.getIn(["newProduct", PDF_TYPES.PRODUCT_ELEMENTS, "file"]),
      base64: products.getIn([
        "newProduct",
        PDF_TYPES.PRODUCT_ELEMENTS,
        "base64",
      ]),
    },
    cid: products.getIn(["newProduct", "cid"]),
    product_name: products.getIn(["newProduct", "product_name"]),
    description: products.getIn(["newProduct", "description"]),
    startDate: products.getIn(["newProduct", "startDate"]),
    endDate: products.getIn(["newProduct", "endDate"]),
    value: products.getIn(["newProduct", "value"]),
    points: products.getIn(["newProduct", "points"]),
    pointsApplyOnce: products.getIn(["newProduct", "pointsApplyOnce"]),
    productId: products.getIn(["newProduct", "productId"]),
    imageName: products.getIn(["newProduct", "imageName"]),
    xp: products.getIn(["newProduct", "xp"]),
    xpType: products.getIn(["newProduct", "xpType"]),
    erpId: products.getIn(["newProduct", "erpId"]),
  },
  changedProduct: {
    attachment: {
      name: products.getIn(["changedProduct", "attachment", "name"]),
      type: products.getIn(["changedProduct", "attachment", "type"]),
      file: products.getIn(["changedProduct", "attachment", "file"]),
      base64: products.getIn(["changedProduct", "attachment", "base64"]),
    },
    [PDF_TYPES.PRODUCT_SPECIFICATIONS]: {
      filename: products.getIn([
        "changedProduct",
        PDF_TYPES.PRODUCT_SPECIFICATIONS,
        "filename",
      ]),
      type: products.getIn([
        "changedProduct",
        PDF_TYPES.PRODUCT_SPECIFICATIONS,
        "type",
      ]),
      file: products.getIn([
        "changedProduct",
        PDF_TYPES.PRODUCT_SPECIFICATIONS,
        "file",
      ]),
      base64: products.getIn([
        "changedProduct",
        PDF_TYPES.PRODUCT_SPECIFICATIONS,
        "base64",
      ]),
    },
    [PDF_TYPES.PRODUCT_ELEMENTS]: {
      filename: products.getIn([
        "changedProduct",
        PDF_TYPES.PRODUCT_ELEMENTS,
        "filename",
      ]),
      type: products.getIn([
        "changedProduct",
        PDF_TYPES.PRODUCT_ELEMENTS,
        "type",
      ]),
      file: products.getIn([
        "changedProduct",
        PDF_TYPES.PRODUCT_ELEMENTS,
        "file",
      ]),
      base64: products.getIn([
        "changedProduct",
        PDF_TYPES.PRODUCT_ELEMENTS,
        "base64",
      ]),
    },
    cid: products.getIn(["changedProduct", "cid"]),
    product_name: products.getIn(["changedProduct", "product_name"]),
    description: products.getIn(["changedProduct", "description"]),
    startDate: products.getIn(["changedProduct", "startDate"]),
    endDate: products.getIn(["changedProduct", "endDate"]),
    value: products.getIn(["changedProduct", "value"]),
    points: products.getIn(["changedProduct", "points"]),
    pointsApplyOnce: products.getIn(["changedProduct", "pointsApplyOnce"]),
    productId: products.getIn(["changedProduct", "productId"]),
    imageName: products.getIn(["changedProduct", "imageName"]),
    xp: products.getIn(["changedProduct", "xp"]),
    xpType: products.getIn(["changedProduct", "xpType"]),
    erpId: products.getIn(["changedProduct", "erpId"]),
  },
  deleting: products.get("deleting"),
  showEditing: products.get("showEditing"),
  showEditingProduct: products.get("showEditingProduct"),
  showEditingProductCategory: products.get("showEditingProductCategory"),
  editingProduct: products.get("editingProduct"),
  isDeleteModalOpen: products.get("isDeleteModalOpen"),
  fetchingProduct: products.get("fetchingProduct"),
  patching: products.get("patching"),
  sendResponse: products.get("sendResponse"),
  isImageModalOpen: products.get("isImageModalOpen"),
  rowsPerPage: products.get("rowsPerPage"),
  page: products.get("page"),
  totalProducts: products.get("totalProducts"),
  fetchingCountProducts: products.get("fetchingCountProducts"),
  fetchedProduct:products.get('fetchedProduct')
});

export const parseCategories = (categories) => ({
  adding: categories.get("adding"),
  all: categories
    .get("all")
    .valueSeq()
    .toArray()
    .map((category) => ({
      brandId: category.get("brandId"),
      deleted: category.get("deleted"),
      name: category.get("name"),
      erpId:category.get('erpId'),
      id: category.get("id"),
      products: category
        .get("products")
        .valueSeq()
        .toArray()
        .map((product) => ({
          deleted: product.get("deleted"),
          description: product.get("description"),
          id: product.get("id"),
          imageName: product.get("imageName"),
          name: product.get("name"),
          points: product.get("points"),
          pointsApplyOnce: product.get("pointsApplyOnce"),
          value: product.get("value"),
          erpId:product.get('erpId')
        })),
    })),
  categoryOverviewActiveTab: categories.get("categoryOverviewActiveTab"),
  changedCategory: {
    brid: categories.getIn(["changedCategory", "brid"]),
    catName: categories.getIn(["changedCategory", "catName"]),
    cid: categories.getIn(["changedCategory", "cid"]),
    products: categories
      .getIn(["changedCategory", "products"])
      .valueSeq()
      .toArray()
      .map((product) => ({
        deleted: product.get("deleted"),
        description: product.get("description"),
        id: product.get("id"),
        imageName: product.get("imageName"),
        name: product.get("name"),
        points: product.get("points"),
        value: product.get("value"),
      })),
    selectedStores: categories
      .getIn(["changedCategory", "selectedStores"])
      .valueSeq()
      .toArray()
      .map((pc) => pc.get()),
  },
  deleting: categories.get("deleting"),
  fetchedCategory: {
    brid: categories.getIn(["fetchedCategory", "brid"]),
    catName: categories.getIn(["fetchedCategory", "catName"]),
    cid: categories.getIn(["fetchedCategory", "cid"]),
    products: categories
      .getIn(["fetchedCategory", "products"])
      .valueSeq()
      .toArray()
      .map((product) => ({
        deleted: product.get("deleted"),
        description: product.get("description"),
        id: product.get("id"),
        imageName: product.get("imageName"),
        name: product.get("name"),
        points: product.get("points"),
        value: product.get("value"),
      })),
    selectedStores: categories
      .getIn(["fetchedCategory", "selectedStores"])
      .valueSeq()
      .toArray()
      .map((pc) => pc.get()),
  },
  fetchingCategory: categories.get("fetchingCategory"),
  isDeleteModalOpen: categories.get("isDeleteModalOpen"),
  isFetching: categories.get("isFetching"),
  moveProducts: categories.get("moveProducts"),
  moveProductsCategory: categories.get("moveProductsCategory"),
  moveProductsFailedResponse: categories.get("moveProductsFailedResponse"),
  movingProducts: categories.get("movingProducts"),
  msg: categories.get("msg"),
  newCategory: {
    brid: categories.getIn(["newCategory", "brid"]),
    catName: categories.getIn(["newCategory", "catName"]),
    cid: categories.getIn(["newCategory", "cid"]),
    products: categories
      .getIn(["newCategory", "products"])
      .valueSeq()
      .toArray()
      .map((product) => ({
        deleted: product.get("deleted"),
        description: product.get("description"),
        id: product.get("id"),
        imageName: product.get("imageName"),
        name: product.get("name"),
        points: product.get("points"),
        value: product.get("value"),
      })),
    selectedStores: categories
      .getIn(["newCategory", "selectedStores"])
      .valueSeq()
      .toArray()
      .map((pc) => pc.get()),
  },
  page: categories.get("page"),
  patching: categories.get("patching"),
  productsListPage: categories.get("productsListPage"),
  productsListRowsPerPage: categories.get("productsListRowsPerPage"),
  rowsPerPage: categories.get("rowsPerPage"),
  sendResponse: categories.get("sendResponse"),
  showEditing: categories.get("showEditing"),
  showEditingProductCategory: categories.get("showEditingProductCategory"),
  totalCategories: categories.get("totalCategories"),
  fetchingCountCategories: categories.get("fetchingCountCategories"),
});

export const parseStores = (stores) => ({
  isFetching: stores.get("isFetching"),
  adding: stores.get("adding"),
  all: stores
    .get("all")
    .valueSeq()
    .toArray()
    .map((store) => ({
      id: store.get("id"),
      name: store.get("name"),
      telephone: store.get("telephone"),
      location: store.get("location"),
      deleted: store.get("deleted"),
      brandId: store.get("brandId"),
      brand: {
        id: store.getIn(["brand", "id"]),
        avatar: store.getIn(["brand", "avatar"]),
        description: store.getIn(["brand", "description"]),
        email: store.getIn(["brand", "email"]),
        name: store.getIn(["brand", "name"]),
        telephone: store.getIn(["brand", "telephone"]),
      },
      position: {
        lng: store.getIn(["position", "lng"]),
        lat: store.getIn(["position", "lat"]),
      },
    })),
  newstore: {
    id: stores.getIn(["newstore", "id"]),
    brid: stores.getIn(["newstore", "brid"]),
    email: stores.getIn(["newstore", "email"]),
    location: stores.getIn(["newstore", "location"]),
    storeName: stores.getIn(["newstore", "storeName"]),
    telephone: stores.getIn(["newstore", "telephone"]),
    password: stores.getIn(["newstore", "password"]),
    position: {
      lat: stores.getIn(["newstore", "position", "lat"]),
      lng: stores.getIn(["newstore", "position", "lng"]),
    },
  },
  changedStore: {
    id: stores.getIn(["changedStore", "id"]),
    brid: stores.getIn(["changedStore", "brid"]),
    email: stores.getIn(["changedStore", "email"]),
    location: stores.getIn(["changedStore", "location"]),
    storeName: stores.getIn(["changedStore", "storeName"]),
    telephone: stores.getIn(["changedStore", "telephone"]),
    password: stores.getIn(["changedStore", "password"]),
    position: {
      lat: stores.getIn(["changedStore", "position", "lat"]),
      lng: stores.getIn(["changedStore", "position", "lng"]),
    },
  },
  selectedStore: stores.get("selectedStore"),
  showStore: stores.get("showStore"),
  deleting: stores.get("deleting"),
  isDeleteModalOpen: stores.get("isDeleteModalOpen"),
  fetchingStore: stores.get("fetchingStore"),
  patching: stores.get("patching"),
  sendResponse: stores.get("sendResponse"),
  fetchingTotalStores: stores.get("fetchingTotalStores"),
  totalStores: stores.get("totalStores"),
  rowsPerPage: stores.get("rowsPerPage"),
  page: stores.get("page"),
});

export const parseOffers = (offers) => ({
  isFetching: offers.get("isFetching"),
  all: offers
    .get("all")
    .valueSeq()
    .toArray()
    .map((offer) => ({
      titleEn: offer.get("titleEn"),
      titleEl: offer.get("titleEl"),
      descriptionEl: offer.get("descriptionEl"),
      descriptionEn: offer.get("descriptionEn"),
      id: offer.get("id"),
      brandId: offer.get("brandId"),
      minOrder: offer.get("minOrder"),
      name: offer.get("name"),
      recurring: offer.get("recurring"),
      startDate: offer.get("startDate"),
      endDate: offer.get("endDate"),
      promoted: offer.get("promoted"),
      pointsRequired: offer.get("pointsRequired"),
      discountId: offer.get("discountId"),
      discount: offer.get("discount"),
      deleted: offer.get("deleted"),
      brand: {
        name: offer.getIn(["brand", "name"]),
        id: offer.getIn(["brand", "id"]),
        description: offer.getIn(["brand", "description"]),
        email: offer.getIn(["brand", "email"]),
        telephone: offer.getIn(["brand", "telephone"]),
        avatar: offer.getIn(["brand", "avatar"]),
      },
      products: offer
        .get("products")
        .valueSeq()
        .toArray()
        .map((product) => ({
          deleted: product.get("deleted"),
          description: product.get("description"),
          id: product.get("id"),
          imageName: product.get("imageName"),
          name: product.get("name"),
          points: product.get("points"),
          value: product.get("value"),
        })),
      discountType: {
        id: offer.getIn(["discountType", "id"]),
        name: offer.getIn(["discountType", "name"]),
      },
    })),
  adding: offers.get("adding"),
  patching: offers.get("patching"),
  changedOffer: {
    titleEn: offers.getIn(["changedOffer", "titleEn"]),
    titleEl: offers.getIn(["changedOffer", "titleEl"]),
    descriptionEn: offers.getIn(["changedOffer", "descriptionEn"]),
    descriptionEl: offers.getIn(["changedOffer", "descriptionEl"]),
    minOrder: offers.getIn(["changedOffer", "minOrder"]),
    redeemPoints: offers.getIn(["changedOffer", "redeemPoints"]),
    discountType: offers.getIn(["changedOffer", "discountType"]),
    offer_name: offers.getIn(["changedOffer", "offer_name"]),
    start_date: offers.getIn(["changedOffer", "start_date"]),
    recurring: offers.getIn(["changedOffer", "recurring"]),
    end_date: offers.getIn(["changedOffer", "end_date"]),
    start_date_readable: offers.getIn(["changedOffer", "start_date_readable"]),
    end_date_readable: offers.getIn(["changedOffer", "end_date_readable"]),
    discount_type: offers.getIn(["changedOffer", "discount_type"]),
    discount: offers.getIn(["changedOffer", "discount"]),
    promoted: offers.getIn(["changedOffer", "promoted"]),
    points_required: offers.getIn(["changedOffer", "points_required"]),
    all_products: offers.getIn(["changedOffer", "all_products"]),
    allCategories: offers.getIn(["changedOffer", "allCategories"]),
    category: offers.getIn(["changedOffer", "category"]),
    products: offers
      .getIn(["changedOffer", "products"])
      .valueSeq()
      .toArray()
      .map((product) => product),
    categories: offers
      .getIn(["changedOffer", "categories"])
      .valueSeq()
      .toArray()
      .map((category) => category),
    oid: offers.getIn(["changedOffer", "oid"]),
    selectedStores: offers
      .getIn(["changedOffer", "selectedStores"])
      .valueSeq()
      .toArray()
      .map((store) => store),
  },
  newoffer: {
    titleEn: offers.getIn(["newoffer", "titleEn"]),
    titleEl: offers.getIn(["newoffer", "titleEl"]),
    descriptionEl: offers.getIn(["newoffer", "descriptionEl"]),
    descriptionEn: offers.getIn(["newoffer", "descriptionEn"]),
    minOrder: offers.getIn(["newoffer", "minOrder"]),
    redeemPoints: offers.getIn(["newoffer", "redeemPoints"]),
    discountType: offers.getIn(["newoffer", "discountType"]),
    offer_name: offers.getIn(["newoffer", "offer_name"]),
    start_date: offers.getIn(["newoffer", "start_date"]),
    end_date: offers.getIn(["newoffer", "end_date"]),
    recurring: offers.getIn(["newoffer", "recurring"]),
    start_date_readable: offers.getIn(["newoffer", "start_date_readable"]),
    end_date_readable: offers.getIn(["newoffer", "end_date_readable"]),
    discount_type: offers.getIn(["newoffer", "discount_type"]),
    discount: offers.getIn(["newoffer", "discount"]),
    promoted: offers.getIn(["newoffer", "promoted"]),
    points_required: offers.getIn(["newoffer", "points_required"]),
    all_products: offers.getIn(["newoffer", "all_products"]),
    allCategories: offers.getIn(["newoffer", "allCategories"]),
    category: offers.getIn(["newoffer", "category"]),
    products: offers
      .getIn(["newoffer", "products"])
      .valueSeq()
      .toArray()
      .map((product) => product),
    categories: offers
      .getIn(["newoffer", "categories"])
      .valueSeq()
      .toArray()
      .map((category) => category),
    oid: offers.getIn(["newoffer", "oid"]),
    selectedStores: offers
      .getIn(["newoffer", "selectedStores"])
      .valueSeq()
      .toArray()
      .map((store) => store),
  },
  selectedOffer: offers.get("selectedOffer"),
  toChange: offers.get("toChange"),
  updateOffers: offers.get("updateOffers"),
  deleting: offers.get("deleting"),
  isDeleteModalOpen: offers.get("isDeleteModalOpen"),
  fetchingOffer: offers.get("fetchingOffer"),
  sendResponse: offers.get("sendResponse"),
  rowsPerPage: offers.get("rowsPerPage"),
  page: offers.get("page"),
  totalOffers: offers.get("totalOffers"),
  fetchingCountOffers: offers.get("fetchingCountOffers"),
});

export const parseDiscounts = (discounts) => ({
  fetching: discounts.get("fetching"),
  discounts: discounts
    .get("discounts")
    .valueSeq()
    .toArray()
    .map((discount) => ({
      id: discount.get("id"),
      name: discount.get("name"),
    })),
});

export const parseGeneralReducer = (generalReducer) => ({
  drawer: generalReducer.get("drawer"),
  fetchingTotalProfit: generalReducer.get("fetchingTotalProfit"),
  totalProfit: generalReducer.get("totalProfit"),
});

export const parseLogin = (login) => ({
  tellerSendResponse: login.get("tellerSendResponse"),
  tellerForm: {
    username: login.getIn(["tellerForm", "username"]),
    email: login.getIn(["tellerForm", "email"]),
    password: login.getIn(["tellerForm", "password"]),
    stores: login
      .getIn(["tellerForm", "stores"])
      .valueSeq()
      .toArray()
      .map((store) => store),
  },
  isFetching: login.get("isFetching"),
  isAuth: login.get("isAuth"),
  isAuthOnBothSystems: !!(login.getIn(['userInfo', 'token']) && login.getIn(['userInfo', 'token_dotnet'])),
    username: login.get("username"),
  password: login.get("password"),
  userInfo: {
    role: {
      id: login.getIn(["userInfo", "role", "id"]),
      name: login.getIn(["userInfo", "role", "name"]),
    },
    username: login.getIn(["userInfo", "username"]),
    brand: {
      id: login.getIn(["userInfo", "brand", "id"]),
      description: login.getIn(["userInfo", "brand", "description"]),
      email: login.getIn(["userInfo", "brand", "email"]),
      avatar: login.getIn(["userInfo", "brand", "avatar"]),
      name: login.getIn(["userInfo", "brand", "name"]),
      telephone: login.getIn(["userInfo", "brand", "telephone"]),
      machineName: login.getIn(["userInfo", "brand", "machineName"]),
    },
    brid: login.getIn(["userInfo", "brid"]),
    firstName: login.getIn(["userInfo", "firstName"]),
    lastName: login.getIn(["userInfo", "lastName"]),
    email: login.getIn(["userInfo", "email"]),
    uid: login.getIn(["userInfo", "uid"]),
    emailVerified: login.getIn(["userInfo", "emailVerified"]),
    photoUrl: login.getIn(["userInfo", "photoUrl"]),
    aid: login.getIn(["userInfo", "aid"]),
    new_password: login.getIn(["userInfo", "new_password"]),
    retypedNewPassword: login.getIn(["userInfo", "retypedNewPassword"]),
    token: login.getIn(["userInfo", "token"]),
    token_dotnet: login.getIn(["userInfo", "token_dotnet"]),
  },
  newAdmin: {
    firstName: login.getIn(["newAdmin", "firstName"]),
    email: login.getIn(["newAdmin", "email"]),
    lastName: login.getIn(["newAdmin", "lastName"]),
    brid: login.getIn(["newAdmin", "brid"]),
    password: login.getIn(["newAdmin", "password"]),
    username: login.getIn(["newAdmin", "username"]),
  },
  adding: login.get("adding"),
  loginResponse: login.get("loginResponse"),
  sendNewAdminResponse: login.get("sendNewAdminResponse"),
  editResponse: login.get("editResponse"),
  isEditingPassword: login.get("isEditingPassword"),
  resetPasswordForm: {
    newPassword: login.getIn(["resetPasswordForm", "newPassword"]),
    retypedNewPassword: login.getIn([
      "resetPasswordForm",
      "retypedNewPassword",
    ]),
    resettingPassword: login.getIn(["resetPasswordForm", "resettingPassword"]),
    responseMessage: login.getIn(["resetPasswordForm", "responseMessage"]),
    resetToken: login.getIn(["resetPasswordForm", "resetToken"]),
  },
  forgotPasswordForm: {
    email: login.getIn(["forgotPasswordForm", "email"]),
    responseMessage: login.getIn(["forgotPasswordForm", "responseMessage"]),
    requesting: login.getIn(["forgotPasswordForm", "requesting"]),
  },
});

export const parsePointsForm = (pointsForm) => ({
  currentStep: pointsForm.get("currentStep"),
  totalLevels: pointsForm.get("totalLevels"),
  levels: pointsForm
    .get("levels")
    .valueSeq()
    .toArray()
    .map((level) => ({
      name: level.get("name"),
      pointsRequired: level.get("pointsRequired"),
      maxInactiveDate: level.get("maxInactiveDate"),
      pointsEarnedPerEuro: level.get("pointsEarnedPerEuro"),
    })),
  responseMessage: pointsForm.get("responseMessage"),
  failedResponseMessage: pointsForm.get("failedResponseMessage"),
  selectedCategories: pointsForm
    .get("selectedCategories")
    .valueSeq()
    .toArray()
    .map((category) => category),
  selectedProducts: pointsForm
    .get("selectedProducts")
    .valueSeq()
    .toArray()
    .map((product) => product),
  combinationsOfProducts: pointsForm
    .get("combinationsOfProducts")
    .valueSeq()
    .toArray()
    .map((cb) => ({
      points: cb.get("points"),
      products: cb
        .get("products")
        .valueSeq()
        .toArray()
        .map((s) => s),
      name: cb.get("name"),
      selectedCategories: cb
        .get("selectedCategories")
        .valueSeq()
        .toArray()
        .map((s) => s),
    })),
  posting: pointsForm.get("posting"),
  includePointsEarnedPerEuro: pointsForm.get("includePointsEarnedPerEuro"),
  includePointsEarnedFromProducts: pointsForm.get(
    "includePointsEarnedFromProducts"
  ),
  includeCombinationsOfProducts: pointsForm.get(
    "includeCombinationsOfProducts"
  ),
  baseRewardItemValue: pointsForm.get("baseRewardItemValue"),
  includeBirthday: pointsForm.get("includeBirthday"),
  birthdayPoints: pointsForm.get("birthdayPoints"),
  includeJoinTheProduct: pointsForm.get("includeJoinTheProduct"),
  firstSignInReward: pointsForm.get("firstSignInReward"),
  mediumRewardItemValue: pointsForm.get("mediumRewardItemValue"),
  finalRewardItemValue: pointsForm.get("finalRewardItemValue"),
  joinTheProductPoints: pointsForm.get("joinTheProductPoints"),
});

export const parsePointsOverview = (pointsOverview) => ({
  patching: pointsOverview.get("patching"),
  posting: pointsOverview.get("posting"),
  fetching: pointsOverview.get("fetching"),
  responseMessage: pointsOverview.get("responseMessage"),
  pointSystem: {
    id: pointsOverview.getIn(["pointSystem", "id"]),
    birthdayPoints: pointsOverview.getIn(["pointSystem", "birthdayPoints"]),
    mediumRewardItemValue: pointsOverview.getIn([
      "pointSystem",
      "mediumRewardItemValue",
    ]),
    finalRewardItemValue: pointsOverview.getIn([
      "pointSystem",
      "finalRewardItemValue",
    ]),
    firstSignInReward: pointsOverview.getIn([
      "pointSystem",
      "firstSignInReward",
    ]),
    baseRewardItemValue: pointsOverview.getIn([
      "pointSystem",
      "baseRewardItemValue",
    ]),
    joinTheProductPoints: pointsOverview.getIn([
      "pointSystem",
      "joinTheProductPoints",
    ]),
    brandId: pointsOverview.getIn(["pointSystem", "brandId"]),
    includeBirthday: pointsOverview.getIn(["pointSystem", "includeBirthday"]),
    includeJoinTheProduct: pointsOverview.getIn([
      "pointSystem",
      "includeJoinTheProduct",
    ]),
    includePointsEarnedPerEuro: pointsOverview.getIn([
      "pointSystem",
      "includePointsEarnedPerEuro",
    ]),
    includePointsEarnedFromProducts: pointsOverview.getIn([
      "pointSystem",
      "includePointsEarnedFromProducts",
    ]),
    includePointsFromCombinationsOfProducts: pointsOverview.getIn([
      "pointSystem",
      "includePointsFromCombinationsOfProducts",
    ]),
    combinationsOfProducts: pointsOverview
      .getIn(["pointSystem", "combinationsOfProducts"])
      .valueSeq()
      .toArray()
      .map((cb) => ({
        id: cb.get("id"),
        pointSystemId: cb.get("pointSystemId"),
        points: cb.get("points"),
        name: cb.get("name"),
        products: cb
          .get("products")
          .valueSeq()
          .toArray()
          .map((product) => ({
            deleted: product.get("deleted"),
            description: product.get("description"),
            startDate: product.get("startDate"),
            endDate: product.get("endDate"),
            id: product.get("id"),
            imageName: product.get("imageName"),
            name: product.get("name"),
            points: product.get("points"),
            value: product.get("value"),
          })),
      })),
    levels: pointsOverview
      .getIn(["pointSystem", "levels"])
      .valueSeq()
      .toArray()
      .map((level) => ({
        id: level.get("id"),
        pointsRequired: level.get("pointsRequired"),
        pointsEarnedPerEuro: level.get("pointsEarnedPerEuro"),
        name: level.get("name"),
        maxInactiveDate: level.get("maxInactiveDate"),
        pointSystemId: level.get("pointSystemId"),
      })),
  },
  extraCombinations: pointsOverview
    .get("extraCombinations")
    .valueSeq()
    .toArray()
    .map((cb) => ({
      points: cb.get("points"),
      products: cb.get("products"),
      name: cb.get("name"),
      selectedCategories: cb
        .get("selectedCategories")
        .valueSeq()
        .toArray()
        .map((s) => s),
      selectedProducts: cb
        .get("selectedProducts")
        .valueSeq()
        .toArray()
        .map((p) => p),
    })),
  activeTab: pointsOverview.get("activeTab"),
});
export const parseOrderForm = (orderForm) => ({
  errorMessage: orderForm.get("errorMessage"),
  currentStep: orderForm.get("currentStep"),
  userEmail: orderForm.get("userEmail"),
  selectedCategories: orderForm
    .get("selectedCategories")
    .valueSeq()
    .toArray()
    .map((category) => category),
  selectedProducts: orderForm
    .get("selectedProducts")
    .valueSeq()
    .toArray()
    .map((product) => product),
  orderItems: orderForm
    .get("orderItems")
    .valueSeq()
    .toArray()
    .map((item) => ({
      productId: item.get("productId"),
      amount: item.get("amount"),
    })),
  fetchedUser: {
    id: orderForm.getIn(["fetchedUser", "id"]),
    username: orderForm.getIn(["fetchedUser", "username"]),
    email: orderForm.getIn(["fetchedUser", "email"]),
  },
});

export const parseClientForm = (clientForm) => ({
  formData: {
    id: clientForm.getIn(["formData", "id"]),
    username: clientForm.getIn(["formData", "username"]),
    email: clientForm.getIn(["formData", "email"]),
    password: clientForm.getIn(["formData", "password"]),
    firstName: clientForm.getIn(["formData", "firstName"]),
    lastName: clientForm.getIn(["formData", "lastName"]),
    address: clientForm.getIn(["formData", "address"]),
    country: clientForm.getIn(["formData", "country"]),
    telephone: clientForm.getIn(["formData", "telephone"]),
  },
  createClientResponseMessage: clientForm.get("createClientResponseMessage"),
  creatingClient: clientForm.get("creatingClient"),
});

export const parseCampgaignNewForm = (campaignNewForm) => ({
  currentStep: campaignNewForm.get("currentStep"),
  campaignActions: campaignNewForm.get("campaignActions").valueSeq()
  .toArray(),
  actions: campaignNewForm
    .get("actions")
    .valueSeq()
    .toArray()
    .map((action) => ({
      actionType: action.get("actionType"),
      id: action.get("id"),
    })),
  events: campaignNewForm.get("events"),

  formData: {
    id: campaignNewForm.getIn(["formData", "id"]),
    title: campaignNewForm.getIn(["formData", "title"]),
    description: campaignNewForm.getIn(["formData", "description"]),
    image: campaignNewForm.getIn(["formData", "image"]),
    selectedUsers: campaignNewForm.getIn(["formData", "selectedUsers"]),
    selectAll:campaignNewForm.getIn(["formData", "selectAll"]),
    selectedActionId: campaignNewForm.getIn(["formData", "selectedActionId"]),
    selectedActionType: campaignNewForm.getIn(["formData","selectedActionType",]),
    selectedEvents: campaignNewForm.getIn(["formData", "selectedEvents"]),
    selectedTags: campaignNewForm.getIn(["formData", "selectedTags"]),
    startDate: campaignNewForm.getIn(["formData", "startDate"]),
    endDate: campaignNewForm.getIn(["formData", "endDate"]),
    notificationTitle: campaignNewForm.getIn(["formData", "notificationTitle"]),
    notificationText: campaignNewForm.getIn(["formData", "notificationText"]),
    notificationImage: campaignNewForm.getIn(["formData", "notificationImage"]),
    notificationBigImage: campaignNewForm.getIn(["formData", "notificationBigImage"]),
    notificationImageType:campaignNewForm.getIn(["formData", "notificationImageType"]),
    notificationBigImageType:campaignNewForm.getIn(["formData", "notificationBigImageType"]),
    emailFrom:campaignNewForm.getIn(["formData", "emailFrom"]),
    emailSubject: campaignNewForm.getIn(["formData", "emailSubject"]),
    emailDate: campaignNewForm.getIn(["formData", "emailDate"]),
    emailTime: campaignNewForm.getIn(["formData", "emailTime"]),
    emailContent: campaignNewForm.getIn(["formData", "emailContent"]),
    testEmailAddress: campaignNewForm.getIn(["formData", "testEmailAddress"]),
    actionDate: campaignNewForm.getIn(["formData", "actionDate"]),
    actionTime: campaignNewForm.getIn(["formData", "actionTime"]),
    actionTags: campaignNewForm.getIn(["formData", "actionTags"]),
    pushPlatforms: campaignNewForm.getIn(["formData", "pushPlatforms"]),
    actionsSelected: campaignNewForm.getIn(["formData", "actionsSelected"]),
    url: campaignNewForm.getIn(["formData", "url"]),
    toAddTag: campaignNewForm.getIn(["formData", "toAddTag"]),
  },
  readyForCampaign:campaignNewForm.get("readyForCampaign"),
  createCampaignResponseMessage: campaignNewForm.get("createCampaignResponseMessage"),
  createActionResponseMessage: campaignNewForm.get("createActionResponseMessage"),
  editCampaignResponseMessage: campaignNewForm.get("editCampaignResponseMessage"),
  editActionResponseMessage:campaignNewForm.get("editActionResponseMessage"),
  creatingAction: campaignNewForm.get("creatingAction"),
  editingAction: campaignNewForm.get("editingAction"),
  creatingCampaign: campaignNewForm.get("creatingCampaign"),
  editingCampaign: campaignNewForm.get("editingCampaign"),
  validationMessage: campaignNewForm.get("validationMessage"),
  sendingTest:campaignNewForm.get("sendingTest"),

});

export const parseCampaigns = (campaignsNew) => ({
  fetching: campaignsNew.get("fetching"),
  page:campaignsNew.get('page'),
  rowsPerPage:campaignsNew.get('rowsPerPage'),
  totalCampaigns:campaignsNew.get('totalCampaigns'),
  campaigns: campaignsNew
    .get("campaigns")
    .valueSeq()
    .toArray()
    .map((campaign) => ({
      id: campaign.get("id"),
      title: campaign.get("title"),
      image: campaign.get("image"),
      campaignPlanId: campaign.get("campaignPlanId"),
      creationDate: campaign.get("creationDate"),
      modifiedAt: campaign.get("modifiedAt"),
      status: campaign.get("status"),
    })),
});

export const parseCampaignNewOverview = (campaignNewOverview) => ({
  activeTab: campaignNewOverview.get("activeTab"),
  fetching: campaignNewOverview.get("fetching"),
  fetchingActions:campaignNewOverview.get("fetchingActions"),
  clients: campaignNewOverview.get("clients"),
  deletingCampaing:campaignNewOverview.get("deletingCampaing"),
  deleteCampaignResponse:campaignNewOverview.get("deleteCampaignResponse"),
  form: {
    id: campaignNewOverview.getIn(["form", "id"]),
    title: campaignNewOverview.getIn(["form", "title"]),
    description: campaignNewOverview.getIn(["form", "description"]),
    modifiedAt: campaignNewOverview.getIn(["form", "modifiedAt"]),
    creationDate: campaignNewOverview.getIn(["form", "creationDate"]),
    campaignPlanId: campaignNewOverview.getIn(["form", "campaignPlanId"]),
    image: campaignNewOverview.getIn(["form", "image"]),
    listIds: campaignNewOverview.getIn(["form", "listIds"]),
    actionIds: campaignNewOverview.getIn(["form", "actionIds"]),
    utmCampaignName: campaignNewOverview.getIn(["form", "utmCampaignName"]),
    startDate: campaignNewOverview.getIn(["form", "startDate"]),
    endDate: campaignNewOverview.getIn(["form", "endDate"]),
    tags: campaignNewOverview.getIn(["form", "tags"]),
    status: campaignNewOverview.getIn(["form", "status"]),

    // receivedAction:campaignNewOverview.getIn(['form','receivedAction']),
    campaignActions: campaignNewOverview.getIn(["form", "campaignActions"])
      .valueSeq()
      .toArray()
      .map((action) => ({
        id: action.get("id"),
        htmlContent:action.get("htmlContent"),
        actionType: action.get("actionType"),
        sender: action.get("sender"),
        subject:action.get("subject"),
        messageId: action.get("messageId"),
        notificationTitle: action.get("notificationTitle"),
        notificationDescription: action.get("notificationDescription"),
        notificationImage: action.get("notificationImage"),
        notificationBigImage: action.get("notificationBigImage"),
        platform: action.get("platform"),
        url: action.get("url"),
        timestamp:action.get("timestamp")
        // tag:action.get('tags'),
      })),

    actionStatus: campaignNewOverview.getIn(["form", "actionStatus"])
      .valueSeq()
      .toArray()
      .map((report) => report.map(status=>({
        successful: status.get("successful"),
        completed_at: status.get("completed_at"),
        received: status.get("received"),
        remaining :status.get("remaining"),
        isAndroid:status.get("isAndroid"),
        isIos:status.get("isIos"),
      })))
      ,
    actionTags: campaignNewOverview.getIn(["form", "actionTags"]),
    // actionStatus:campaignNewOverview.getIn(['form','actionStatus']).valueSeq().toArray()
  },
});

export const parsePointsMultiplier = (multiplier) => ({
  fetching: multiplier.get("fetching"),
  page:multiplier.get('page'),
  rowsPerPage:multiplier.get('rowsPerPage'),
  multipliersCount:multiplier.get('multipliersCount'),
  multipliers: multiplier
    .get("multipliers")
    .valueSeq()
    .toArray()
    .map((multiplier) => ({
      id: multiplier.get("id"),
      title: multiplier.get("title"),
      dateFrom: multiplier.get("dateFrom"),
      dateTo: multiplier.get("dateTo"),
      timeFrom: multiplier.get("timeFrom"),
      timeTo: multiplier.get("timeTo"),
      createdDate: multiplier.get("createdDate"),
      value: multiplier.get("value"),
      categoryId:multiplier.get('categoryId'),
      productId:multiplier.get('productId'),
      appliedToAllProducts:multiplier.get('appliedToAllProducts'),
      deletedDate:multiplier.get('deletedDate')

    })),
});

export const parsePointsMultiplierForm = (form) => ({
  fetching: form.get("fetching"),
  responseMessage:form.get('responseMessage'),
  validationMessage:form.get('validationMessage'),
  activeMultipliers:form.get('activeMultipliers'),
  formData: {
    title: form.getIn(["formData", "title"]),
    targetGroup: form.getIn(["formData", "targetGroup"]),
    startDate: form.getIn(["formData", "startDate"]),
    endDate: form.getIn(["formData", "endDate"]),
    startTime:form.getIn(["formData", "startTime"]),
    endTime:form.getIn(["formData", "endTime"]),
    creationDate: form.getIn(["formData", "creationDate"]),
    multiplierValue: form.getIn(["formData", "multiplierValue"]),
    //allDay:form.getIn(["formData", "allDay"]),
    selectedCategory:form.getIn(["formData", "selectedCategory"]),
    selectedProduct:form.getIn(["formData", "selectedProduct"]),

   
  },
});