import * as ActionTypes from '../actions/index';
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';


const defaultState = fromJS({
    fetching: false,
    multipliers:[] ,
    activeMultipliers:[],
    fetchingMultipliersCount: false,
    multipliersCount: '',
    rowsPerPage: 10,
    page: 1,
    posting: false,
    orderBy: 3, //table header array index that does the mapping of column name and its key for sort
    order: 'desc',
    filterField: '',
    
    
});

function changePointsMultiplierStoreSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function getMultipliersCount(state, action) {
    return state
        .set('fetchingMultipliersCount', true);
}
function receiveMultipliersCount(state, action) {
    return state
        .set('fetchingMultipliersCount', false)
        .set('multipliersCount', action.payload.body);
}
function failedToGetMultipliersCount(state,action)
{
    return state
        .set('fetchingMultipliersCount', false);
}

function getMultipliers(state, action) {
    return state
        .set('fetching', true);
}

function receiveMultipliers(state, action) {
    var countMultipliers;
    try {
       // action.payload = action.payload.body;
        countMultipliers= action.payload.body.count;
    }
    catch (e) {
        action.payload = null
        countMultipliers = ''
    }
    return state
        .set('multipliersCount',countMultipliers)
        .set('multipliers', fromJS(action.payload.body.items) )
        .set('fetching', false);
}

function failedToGetMultipliers(state, action) {
    return state
        .set('fetching', false);
}
function getActiveMultipliers(state, action) {
    return state
        .set('fetching', true);
}

function receiveActiveMultipliers(state, action) {
    try {
        action.payload = action.payload.body
    }
    catch (e) {
        action.payload = null
    }
    return state
        .set('activeMultipliers', fromJS(action.payload) )
        .set('fetching', false);
}

function failedToGetActiveMultipliers(state, action) {
    return state
        .set('fetching', false);
}
function resetState(state, action) {
    return defaultState
}


export const pointsMultiplier = createReducer(defaultState, {
    [ActionTypes.GET_MULTIPLIERS_COUNT]:getMultipliersCount,
    [ActionTypes.RECEIVE_MULTIPLIERS_COUNT]:receiveMultipliersCount,
    [ActionTypes.FAILED_TO_GET_MULTIPLIERS_COUNT]:failedToGetMultipliersCount,
    [ActionTypes.GET_MULTIPLIERS]:getMultipliers,
    [ActionTypes.RESET_MULTIPLIERS_NEW_STATE]: resetState,
    [ActionTypes.RECEIVE_MULTIPLIERS]:receiveMultipliers,
    [ActionTypes.FAILED_TO_GET_MULTIPLIERS]: failedToGetMultipliers,
    [ActionTypes.CHANGE_POINTS_MULTIPLIER_STORE_SETTINGS]: changePointsMultiplierStoreSettings,
[ActionTypes.GET_ACTIVE_MULTIPLIERS]:getActiveMultipliers,
[ActionTypes.RECEIVE_ACTIVE_MULTIPLIERS]:receiveActiveMultipliers,
[ActionTypes.FAILED_TO_GET_ACTIVE_MULTIPLIERS]:failedToGetActiveMultipliers,    

});
