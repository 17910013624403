import * as ActionTypes from '../actions/index';
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    fetching: false,
    order: {
        lineItems: [],
        id: '',
        teller: {
            email: '',
            id: '',
            username: '',
            stores: []
        },
        mobilePerson: {},
        store: {},
        totalValue: '',
        totalPoints: '',
        date: '',
    },
    lineItemsRowsPerPage: 10,
    lineItemsPage: 1,
    isDeleteModalOpen: false,
    activeTab: 0,
});

function deleteOrderSuccess(state, action) {
    return state
        .set('isDeleteModalOpen', false);
}

function openOrderDeleteModal(state, action) {
    return state
        .set('isDeleteModalOpen', true);
}

function closeOrderDeleteModal(state, action) {
    return state
        .set('isDeleteModalOpen', false);
}

function changeOrderOverviewStoreSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function getOrderById(state, action) {
    return state
        .set('fetching', true);
}

function receiveOrderById(state, action) {
    let order = {};
    try {
        order = {
            lineItems: action.payload.body.lineItems,
            id: action.payload.body.id,
            teller: {
                email: action.payload.body.teller.email?action.payload.body.teller.email:'',
                id: action.payload.body.teller.id?action.payload.body.teller.id:'',
                username: action.payload.body.teller.username?action.payload.body.teller.username:'',
                stores: action.payload.body.teller.stores?action.payload.body.teller.stores:''
            },
            mobilePerson: action.payload.body.mobilePerson,
            store: action.payload.body.store,
            totalValue: action.payload.body.totalValue,
            totalPoints: action.payload.body.totalPoints,
            date: action.payload.body.date
        }
    }
    catch (e){
        order = defaultState.order
    }
    return state
        .set('fetching', false)
        .set('order', fromJS(order));
}

function failedToReceiveOrderById(state, action) {
    return state
        .set('fetching', false);
}

function logOff(state, action) {
    return defaultState;
}

function setActiveTab(state, action) {
    return state
        .set('activeTab', action.payload.activeTab)
}

function resetState(state, action) {
    return defaultState;
}

export const orderOverview = createReducer(defaultState, {
    [ActionTypes.DELETE_ORDER_SUCCESS]: deleteOrderSuccess,
    [ActionTypes.OPEN_ORDER_DELETE_MODAL]: openOrderDeleteModal,
    [ActionTypes.CLOSE_ORDER_DELETE_MODAL]: closeOrderDeleteModal,
    [ActionTypes.CHANGE_ORDER_OVERVIEW_STORE_SETTINGS]: changeOrderOverviewStoreSettings,
    [ActionTypes.GET_ORDER_BY_ID]: getOrderById,
    [ActionTypes.RECEIVE_ORDER_BY_ID]: receiveOrderById,
    [ActionTypes.FAILED_TO_RECEIVE_ORDER_BY_ID]: failedToReceiveOrderById,
    [ActionTypes.LOG_OFF]: logOff,
    [ActionTypes.SET_ACTIVE_TAB]: setActiveTab,
    [ActionTypes.RESET_ORDER_OVERVIEW_STATE]: resetState
});
