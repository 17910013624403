import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PointsForm from "../../components/Points/pointsForm";
import {
    changeCombinationsSettings,
    changeLevelForm, changePointsFormSettings, changeTheCombinationsOfProducts,
    createPointSystem, resetPointFormState, resetPointsFormState
} from "../../actions/pointsForm";
import {parseCategories, parseLogin, parsePointsForm} from "../../helpers/parseImmutables";
import _ from 'lodash';
import {toGetCategories} from "../../actions/categories";
import InputComp from "../../components/InputComp";
import SelectComp from "../../components/SelectComp";
import Button from "@material-ui/core/Button";
import usePrevious from "../../hooks/usePrevious";
import {useHistory} from "react-router-dom";

const PointsFormHOC = props => {
    const prevPosting = usePrevious(props.pointsForm.posting);
    const history = useHistory();

    useEffect(() => {
        if(
            prevPosting
            && !props.pointsForm.posting
            && !props.pointsForm.failedResponseMessage
        ){
            history.push('/points')
        }
    }, [props.pointsForm.posting, prevPosting, props.pointsForm.failedResponseMessage])

    useEffect(() => {
        props.getAllCategories(props.brandId, props.token);

        return () => {
            props.stateReset()
        }
    }, [])


    const _handleSubmit = () => {
        if(props.pointsForm.totalLevels !== 3 && props.pointsForm.totalLevels !== 4){
            props.changeStoreSettings('responseMessage', 'Please Create levels before submitting');
            return 0;
        }
        let x = _.filter(props.pointsForm.levels, level => {
            if(level.name === '' || level.pointsRequired === '' || level.maxInactiveDate === '' || level.pointsEarnedPerEuro === '') {
                return true;
            }
        });
        if(x.length > 0) {
            props.changeStoreSettings('responseMessage', 'Please fill the remaining fields');
            return 0;
        }
        if(!props.pointsForm.birthdayPoints && props.pointsForm.includeBirthday){
            props.changeStoreSettings('responseMessage', 'Please fill the birthday points.');
            return 0;
        }
        if(!props.pointsForm.joinTheProductPoints && props.pointsForm.includeJoinTheProduct) {
            props.changeStoreSettings('responseMessage', 'Please fill the join the product points.');
            return 0;
        }

        props.createPointSystem({
            levels: props.pointsForm.levels,
            finalRewardItemValue: props.pointsForm.finalRewardItemValue,
            mediumRewardItemValue: props.pointsForm.mediumRewardItemValue,
            firstSignInReward: props.pointsForm.firstSignInReward,
            baseRewardItemValue: props.pointsForm.baseRewardItemValue,
            includePointsEarnedPerEuro: props.pointsForm.includePointsEarnedPerEuro,
            includePointsEarnedFromProducts: props.pointsForm.includePointsEarnedFromProducts,
            includeCombinationsOfProducts: props.pointsForm.includeCombinationsOfProducts,
            includeBirthday: props.pointsForm.includeBirthday,
            includeJoinTheProduct: props.pointsForm.includeJoinTheProduct,
            combinationsOfProducts: props.pointsForm.combinationsOfProducts,
            birthdayPoints: props.pointsForm.birthdayPoints,
            joinTheProductPoints: props.pointsForm.joinTheProductPoints,
        }, props.token, props.brandId)
    };

    const handleNext = () => {
        if(props.pointsForm.currentStep === 3)
            return 0;
        if(props.pointsForm.currentStep === 0){
            if(props.pointsForm.totalLevels !== 3 && props.pointsForm.totalLevels !== 4){
                props.changeStoreSettings('responseMessage', 'Please Create levels to proceed');
                return 0;
            }
            let x = _.filter(props.pointsForm.levels, level => {
                if(level.name === '' || level.pointsRequired === '' || level.maxInactiveDate === '' || level.pointsEarnedPerEuro === '') {
                    return true;
                }
            });
            if(x.length > 0) {
                props.changeStoreSettings('responseMessage', 'Please fill the remaining fields');
                return 0;
            }
        }
        if(props.pointsForm.currentStep === 1){
            if(props.pointsForm.combinationsOfProducts.length > 0){
                let emptyRows = [];
                emptyRows = _.filter(props.pointsForm.combinationsOfProducts, cb => {
                    if(cb.name === '' || cb.products.length === 0){
                        return true
                    }
                });
                if(emptyRows.length > 0){
                    props.changeStoreSettings('responseMessage', 'Please fill the remaining fields');
                    return true
                }
            }
        }
        props.changeStoreSettings('currentStep', props.pointsForm.currentStep + 1)
    };

    const handlePrev = () => {
        if(props.pointsForm.currentStep === 0)
            return 0;
        props.changeStoreSettings('currentStep', props.pointsForm.currentStep - 1)
    };

    const _handleSelectInputChance = (name, value) => {
        props.changeStoreSettings(name, value)
    };

    const _handleTotalLevelsSelect = (name, value) => {
        let levels = [];
        props.changeStoreSettings(name, value);
        let x = _.range(0, value, 1); //helper array to iterate 'value' times
        _.forEach(x, () => {
            levels.push({
                name: '',
                pointsRequired: '',
                maxInactiveDate: '',
                pointsEarnedPerEuro: ''
            })
        });
        props.changeStoreSettings('levels', levels);
    };

    const _handleLevelFormChange = (index, evt) => {
        props.changeLevelForm(index, evt.target.name, evt.target.value)
    };

    const _handleDateChange = (name, value, index) => {
        props.changeLevelForm(index, name, value);
    };

    const _handleSelectCategories = (name, value, index) => {
        let newCheckedProducts = [];
        let products = [];
        _.forEach(props.categories, category => {
            if(_.includes(value, category.id)){
                products = [...products, ...category.products]
            }
        });
        _.forEach(products, product => {
            if(_.includes(props.pointsForm.combinationsOfProducts[index].products, product.id)){
                newCheckedProducts.push(product.id)
            }
        });
        props.changeCombinationsSettings(index, name, value);
        props.changeCombinationsSettings(index, 'products', newCheckedProducts);
    };

    const _handleSelectProducts = (name, value, index) => {
        props.changeCombinationsSettings(index, name, value);
    };

    const _getProducts = () => {
        let products = [];
        _.forEach(props.categories, category => {
            if(_.includes(props.pointsForm.selectedCategories, category.id)){
                products = [...products, ...category.products]
            }
        });
        return products;
    };

    const _getProductsOfCombinations = (index) => {
        let products = [];
        _.forEach(props.categories, category => {
            if(_.includes(props.pointsForm.combinationsOfProducts[index].selectedCategories, category.id)){
                products = [...products, ...category.products]
            }
        });
        return products;
    };

    const _handleCombinationsCheckbox = (name, value) => {
        props.changeStoreSettings(name, value);
    };

    const _handleCombinationAddButton = () => {
        props.changeStoreSettings('combinationsOfProducts', [
            ...props.pointsForm.combinationsOfProducts,
            {points: 0, products: [], name: '', selectedCategories: [] }]);

    };

    const _handleTextInputChange = (evt) => {
        props.changeStoreSettings(evt.target.name, evt.target.value);
    };

    const _getLevelsArray = () => {
        let arr = [];
        _.forEach(props.pointsForm.levels, (level, index) => {
            arr.push(
                {
                    id: index,
                    data: [
                        <InputComp Required={true} Placeholder="Level name" Type="text" Name="name"
                                   fullWidth={false}
                                   Label="" Value={level.name}
                                   PropHandleInputChange={(evt) => _handleLevelFormChange(index, evt)}/>,
                        <InputComp Required={true} Placeholder="Points required" Type="number" Name="pointsRequired"
                                   fullWidth={false}
                                   Label="" Value={level.pointsRequired}
                                   PropHandleInputChange={(evt) => _handleLevelFormChange(index, evt)}/>,
                        <InputComp Required={true} Placeholder="Points earned per euro" Type="number" Name="pointsEarnedPerEuro"
                                   fullWidth={false}
                                   Label="" Value={level.pointsEarnedPerEuro}
                                   PropHandleInputChange={(evt) => _handleLevelFormChange(index, evt)}/>,
                        <InputComp Required={true} Placeholder="Points earned per euro" Type="number" Name="maxInactiveDate"
                                   fullWidth={false}
                                   Label="" Value={level.maxInactiveDate}
                                   PropHandleInputChange={(evt) => _handleLevelFormChange(index, evt)}/>

                    ]
                }
            )
        });
        return arr;
    };

    const _handleInputChange = (name, value) => {
        props.changeStoreSettings(name, value);
    };

    const _handleCombinationRemove = (index) => {
        let combinationsOfProducts = props.pointsForm.combinationsOfProducts;
        _.pullAt(combinationsOfProducts, index);
        props.changeStoreSettings('combinationsOfProducts',combinationsOfProducts);

    };

    const _getNestedItem = (index) => {
        let nestedItems = '';
        _.forEach(props.categories, category => {
            _.forEach(category.products, product => {
                if(props.pointsForm.combinationsOfProducts[index].products.includes(product.id)){
                    nestedItems += nestedItems === ''?(product.name):(', ' + product.name);
                }
            })
        });
        return nestedItems;
    };

    const _getCombinationRows = () => {
        let rows = [];
        _.forEach(props.pointsForm.combinationsOfProducts, (extra, index) => {
            rows.push({
                id: index,
                data: [
                    <InputComp Required={true} Placeholder="Level name" Type="text" Name="name"
                               fullWidth={false}
                               Label="" Value={extra.name}
                               PropHandleInputChange={(evt) => props.changeCombinationsSettings(index, evt.target.name, evt.target.value)}/>,
                    <div className={"d-flex justify-content-between"}>
                        <SelectComp Required={true}
                                    MenuName="Select categories"
                                    TargetObj={props.pointsForm.combinationsOfProducts[index].selectedCategories}
                                    Name="selectedCategories"
                                    multiple={true}
                                    Label="Categories"
                                    Values={props.categories.map(item => {return {key:item.id, value: item.name}})}
                                    PropHandleInputChange={(name, value) => _handleSelectCategories(name, value, index)}/>
                        <SelectComp Required={true}
                                    MenuName="Select products"
                                    TargetObj={props.pointsForm.combinationsOfProducts[index].products} Name="products"
                                    multiple={true}
                                    Label="Products"
                                    Values={_getProductsOfCombinations(index).map(item => {return {key:item.id, value: item.name}})}
                                    PropHandleInputChange={(name, value) => _handleSelectProducts(name, value, index)}/>
                    </div>,
                    <InputComp Required={true} Placeholder="Points" Type="number" Name="points"
                               fullWidth={false}
                               Label="" Value={extra.points}
                               PropHandleInputChange={(evt) => props.changeCombinationsSettings(index, evt.target.name, evt.target.value)}/>,
                    <Button
                        onClick={() => _handleCombinationRemove(index)}
                        color="secondary"
                        variant="flat"
                    >REMOVE</Button>
                ]
            })
        });
        return rows;
    };

    let tableColumns =  [
        'Name',
        'Points required',
        'Points Earned Per Euro',
        'Max inactive weeks'
    ];
    let combinationTableColumns = [
        'Name',
        'Products',
        'Points',
        ''
    ];
    return (
        <PointsForm
            _handleTextInputChange={_handleTextInputChange}
            combinationsTableRows={_getCombinationRows()}
            combinationTableColumns={combinationTableColumns}
            _getNestedItem={_getNestedItem}
            _handleCombinationRemove={_handleCombinationRemove}
            _handleCombinationAddButton={_handleCombinationAddButton}
            _handleCombinationsCheckbox={_handleCombinationsCheckbox}
            _handleSelectProducts={_handleSelectProducts}
            _handleInputChange={_handleInputChange}
            tableRows={_getLevelsArray()}
            tableColumns={tableColumns}
            _handleSelectCategories={_handleSelectCategories}
            categories={props.categories}
            products={_getProducts()}
            _handleSubmit={_handleSubmit}
            _handleDateChange={_handleDateChange}
            _handleLevelFormChange={_handleLevelFormChange}
            _handleTotalLevelsSelect={_handleTotalLevelsSelect}
            handleNext={handleNext}
            handlePrev={handlePrev}
            pointsForm={props.pointsForm}
            changeStoreSettings={props.changeStoreSettings}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        pointsForm: parsePointsForm(state.pointsForm),
        categories: parseCategories(state.categories).all,
        brandId: parseLogin(state.login).userInfo.brand.id,
        token: parseLogin(state.login).userInfo.token,

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeStoreSettings: (key, value) => {
            dispatch(changePointsFormSettings(key, value))
        },
        changeLevelForm: (index, key, value) => {
            dispatch(changeLevelForm(index, key, value))
        },
        getAllCategories: (brandId, token) => {
            dispatch(toGetCategories(brandId, token))
        },
        createPointSystem: (pointSystem, token, brandId) => {
            dispatch(createPointSystem(pointSystem, token, brandId))
        },
        changeTheCombinationsOfProducts: (index, key, value) => {
            dispatch(changeTheCombinationsOfProducts(index, key, value))
        },
        stateReset: () => {
            dispatch(resetPointFormState())
       },
        changeCombinationsSettings: (index, key, value) => {
            dispatch(changeCombinationsSettings(index, key, value))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsFormHOC);

