import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import RenderTextField from "../input/renderTextField";
import RenderCheckboxField from "../input/renderCheckboxField";

const EnabledOffersFilterForm =  (props) =>{
    const {filterEnabledOffers, handleSubmit, handleCancel} = props;
    return (
        <form onSubmit={handleSubmit(filterEnabledOffers)}>
            <div className={'form-group'}>
                <Field name="id" label={'Id'} component={RenderTextField} type="text" fullWidth/>
            </div>
            <div className={'form-group'}>
                <Field name="personId" label={'Person id'} component={RenderTextField} type="text" fullWidth/>
            </div>
            <div className={'form-group'}>
                <Field name="offerId" label={'Offer Id'} component={RenderTextField} type="text" fullWidth/>
            </div>
            <div className={'form-group'}>
                <Field name="couponCode" label={'Coupon code'} component={RenderTextField} type="text" fullWidth/>
            </div>
            <div className={'form-group'}>
                <Field name="erpCouponId" label={'Erp coupon id'} component={RenderTextField} type="text" fullWidth/>
            </div>
            <div className={'form-group'}>
                <Field name="issueDateFrom" shrink={true} label={'Issue date from'} component={RenderTextField}
                       type="date" fullWidth
                />
            </div>
            <div className={'form-group'}>
                <Field name="issueDateTo" shrink={true} label={'Issue date to'} component={RenderTextField} type="date"
                       fullWidth
                />
            </div>
            <div className={'form-group'}>
                <Field name="expired" label={'Expired'} component={RenderCheckboxField} type="text"/>
            </div>
            <div className={'form-group'}>
                <Field name="redeemed" label={'Redeemed'} component={RenderCheckboxField} type="text"/>
            </div>
            <div className={'form-group'}>
                <Button type={'submit'} variant="contained" color="primary" className={'mr-3'}>
                    {'Filter'}
                </Button>
                <Button onClick={handleCancel} variant="contained" color="secondary">
                    {'Cancel'}
                </Button>
            </div>
        </form>
    )

};

export default reduxForm({
    destroyOnUnmount: false,
    enableReinitialize: true,
    form: 'enabledOffersFilterForm',
})(EnabledOffersFilterForm);
