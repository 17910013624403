import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import {logActivity} from "../../actions/login";
import {toJS} from "./toJs";

const ActivityTrackerHOC = (WrappedComponent) => {
        const ActivityTracker  = (props) => {

            //
            useEffect(() => {
                if(props.userRole.name === 'editor'){
                    props.logActivity({
                        sendBody: {
                            personId: props.personId,
                            dateOfAction: new Date(),
                            pathname: props.location.pathname,
                        }
                    });
                }
            }, []);

            // componentWillReceiveProps(nextProps){
            //     if(nextProps.userRole.name === 'editor'){
            //         this.props.logActivity({
            //             sendBody: {
            //                 personId: nextProps.personId,
            //                 dateOfAction: new Date(),
            //                 pathname: nextProps.location.pathname
            //             }
            //         });
            //     }
            // }

            return (
                <WrappedComponent {...props}/>
            )
        }

        const mapStateToProps = (state) => {
            return {
                userRole: state.login.getIn(['userInfo', 'role']),
                personId: state.login.getIn(['userInfo', 'uid']),
            }
        };

        const mapDispatchToProps = (dispatch) => {
            return {
                logActivity: (payload) => {
                    dispatch(logActivity(payload))
                }
            }
        };

        return connect(mapStateToProps, mapDispatchToProps)(toJS(ActivityTracker));
    }
;

export default ActivityTrackerHOC