import React, {Component} from 'react';
import {connect} from 'react-redux';
import ClientForm from "../../components/Clients/clientForm";
import {parseClientForm, parseLogin} from "../../helpers/parseImmutables";
import {changeClientFormData, changeClientFormSettings, createClient} from "../../actions/clientForm";
import {isPhone} from "../../helpers/functions";

const ClientFormHOC = props => {

    const handleTextInputChange = (evt) => {
        props.changeClientFormData({
            key: evt.target.name,
            value: evt.target.value
        })
    };

    const validate = () => {
        if(props.clientForm.formData.telephone && !isPhone(props.clientForm.formData.telephone)){
            props.changeClientFormSettings({
                key: 'createClientResponseMessage',
                value: 'Invalid telephone'
            });
            return false;
        }
        return true;
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        const isValid = validate();
        if(!isValid) return false;
        props.createClient({
            sendBody: {
                username: props.clientForm.formData.username,
                email: props.clientForm.formData.email,
                password: props.clientForm.formData.password,
                firstName: props.clientForm.formData.firstName,
                lastName: props.clientForm.formData.lastName,
                address: props.clientForm.formData.address,
                country: props.clientForm.formData.country,
                telephone: props.clientForm.formData.telephone,
            },
            token: props.token
        })
    };

    return (
        <ClientForm
            {...props}
            handleSubmit={handleSubmit}
            handleTextInputChange={handleTextInputChange }
        />
    )
}

const mapStateToProps = (state) => {
  return {
      clientForm: parseClientForm(state.clientForm),
      token: parseLogin(state.login).userInfo.token
  }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeClientFormData: (payload) => {
            dispatch(changeClientFormData(payload))
        },
        changeClientFormSettings: (payload) => {
            dispatch(changeClientFormSettings(payload))
        },
        createClient: (payload) => {
            dispatch(createClient(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientFormHOC)