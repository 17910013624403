import * as Action from "../actions/index";
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    drawer: true,
    fetchingTotalProfit: false,
    totalProfit: ''
});

function getTotalProfit(state, action) {
    return state
        .set('fetchingTotalProfit', true);
}

function receiveTotalProfit(state, action) {
    return state
        .set('fetchingTotalProfit', false)
        .set('totalProfit', action.payload.body.totalProfit);
}

function failedToGetTotalProfit(state, action) {
    return state
        .set('fetchingTotalProfit', false);
}

function toggleDrawer(state, action) {
    return state
        .set('drawer', !state.get('drawer'));
}

export const generalReducer = createReducer(defaultState, {
    [Action.GET_TOTAL_PROFIT]: getTotalProfit ,
    [Action.RECEIVE_TOTAL_PROFIT]: receiveTotalProfit ,
    [Action.FAILED_TO_GET_TOTAL_PROFIT]: failedToGetTotalProfit ,
    [Action.TOGGLE_DRAWER]: toggleDrawer ,
});
