import * as actions from '../actions/editor';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";
import sampleCombine from "xstream/extra/sampleCombine";
import {reset} from 'redux-form/immutable';

export function requestUpdateEditor(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.UPDATE_EDITOR)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/people/editors/' + action.payload.personId,
            category: 'requestUpdateEditor',
            method: 'PATCH',
            send: action.payload.sendBody,
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestUpdateEditor')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.updateEditorSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestUpdateEditorFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestUpdateEditor')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.updateEditorFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function resetEditorFormAfterPost(sources) {
    const action$ = sources.ACTION
        .filter(action => action.type === ActionTypes.CREATE_EDITOR_SUCCESS)
        .map(action => reset('editorForm'));
    return {
        ACTION: action$
    }
}

export function requestEditor(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_EDITOR)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/people/editors/' + action.payload.personId,
            category: 'requestEditor',
            method: 'GET',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestEditor')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getEditorSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestEditorFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestEditor')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getEditorFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestCreateEditor(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.CREATE_EDITOR)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/people/editors',
            category: 'requestCreateEditor',
            method: 'post',
            send: action.payload.sendBody,
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCreateEditor')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.createEditorSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestCreateEditorFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCreateEditor')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.createEditorFailed(response));

    return {
        ACTION: httpResponse$
    }
}