import React from 'react';
import CategoryFormHOC from '../../containers/Categories/categoryFormHOC';
import Table from '../General/table';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Edit from "@material-ui/icons/Edit";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import TabContainer from "../General/tabContainer";


const CategoryOverview = (props) => {
    return (
        <Paper>
            <Tabs
                value={props.activeTab}
                onChange={(evt, value) => props.changeStoreSettings('categoryOverviewActiveTab', value)}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab
                    icon={<Edit/>}
                    label="Edit"
                />
                <Tab
                    icon={<ShoppingCart/>}
                    label="Products"
                />

            </Tabs>
            { props.activeTab === 0 && (
                <TabContainer>
                    <CategoryFormHOC/>
                </TabContainer>
            )}
            { props.activeTab === 1 && (
                <TabContainer>
                    <Table
                        handleRowSelect={props.handleProductRowClick}
                        label={'Products'}
                        rows={props.productTableRows}
                        columns={props.productTableHeaders}
                        fixedHeader={true}
                        selectable={false}
                        multiSelectable={false}
                        displaySelectAll={false}
                        showCheckBoxes={false}
                        showRowHover={true}

                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        handleRowsPerPage={props.handleRowsPerPage}
                        handlePageChange={props.handlePageChange}
                        totalRows={props.totalRows}
                    />
                </TabContainer>
            )}
        </Paper>
    )
};

export default CategoryOverview;