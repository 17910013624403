import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import usePrevious from "../../hooks/usePrevious";
import _ from "lodash";
import { parseCampaigns, parseLogin, parsePointsMultiplier } from "../../helpers/parseImmutables";
import { useHistory } from "react-router-dom";
import { getTablePage, _getSkipFilter } from "../../helpers/functions";
import PointsMultiplier from "../../components/PointsMultiplier/pointsMultiplier";
import {
  getMultipliersCount,
  getMultipliers,
  resetPointsMultiplierNewState,
  changePointsMultiplierStoreSettings
} from "../../actions/pointsMultiplier";
import { getProductBydId } from "../../actions/products";
import { getCategoryById } from "../../actions/categories";
const tableColumns = [
  { name: "Title", key: "multiPlierTitle" },
  { name: "Target group", key: "targetGroup" },
  { name: "Multiplier's Value", key: "multiplierValue" },
  {name: "Ends on",key:'endDate'},
  { name: "Creation Date (YYYY-MM-DD)", key: "CreationDate" },
];

const PointsMultiplierHOC = (props) => {
  const history = useHistory();
  const prevPage = usePrevious(props.pointsMultiplier.page);
  const prevRowsPerPage = usePrevious(props.pointsMultiplier.rowsPerPage);
  useEffect(() => {
        props.resetPointsMultiplierNewState();
    props.getMultipliers({
      filter: {
        offset:props.pointsMultiplier.page -1,
        limit: props.pointsMultiplier.rowsPerPage,
      },
    });
        // props.getMultipliersCount();
        return () => {
          props.resetPointsMultiplierNewState();
      }
  }, []);


  useEffect(() => {
    
    if (
      props.pointsMultiplier.page !== prevPage ||
      props.pointsMultiplier.rowsPerPage !== prevRowsPerPage
    ) {
      props.getMultipliers({
        filter: {
          offset:props.pointsMultiplier.page - 1,
          limit: props.pointsMultiplier.rowsPerPage,
        },
      });
    }
  }, [
    props.pointsMultiplier.page,
    props.pointsMultiplier.rowsPerPage,
    prevPage,
    prevRowsPerPage,
  ]);

  const getMultiplierRows = () => {
    // props.getProductBydId()
    let rows = [];
    let allMultipliers = [...props.pointsMultiplier.multipliers];
    _.forEach(allMultipliers, (multiplier) => {

      var targetGroup = multiplier.categoryId != null?"Category based": multiplier.productId!=null?"Product based":"All products";
 
      rows.push({
        id: multiplier.id,
        title: multiplier.title,
       // targetGroup: multiplier.targetGroup,
        createdDate: multiplier.createdDate,
        targetGroup:targetGroup,
        value: multiplier.value,
        dateFrom: multiplier.dateFrom,
        dateTo: multiplier.dateTo,
        deletedDate: multiplier.deletedDate,
      });
    });
    return rows;
  };

  const handleRowSelect = (multiplierId) => {
    history.push("/pointsMultiplier/" + multiplierId);
  };
  const handleRowsPerPage = (rowsPerPage) => {
    rowsPerPage = parseInt(rowsPerPage);
    let newTotalPages = _.ceil(props.pointsMultiplier.multipliersCount / rowsPerPage);
    let currentPage = props.pointsMultiplier.page;
    if (parseInt(currentPage) > newTotalPages) {
      currentPage = newTotalPages;
    }
    props.changePointsMultiplierStoreSettings("rowsPerPage", rowsPerPage);
    props.changePointsMultiplierStoreSettings("page", currentPage);
  };
  const handlePageChange = (iconType) => {
    let newPage = getTablePage(
      iconType,
      props.pointsMultiplier.page,
      props.pointsMultiplier.multipliersCount,
      props.pointsMultiplier.rowsPerPage // props.clients.rowsPerPage
    );
    props.changePointsMultiplierStoreSettings("page", newPage);
  };

  return (
    <PointsMultiplier
      tableColumns={tableColumns}
      rows={getMultiplierRows()}
      handleRowSelect={handleRowSelect}
      handleRowsPerPage={handleRowsPerPage}
      handlePageChange={handlePageChange}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: parseLogin(state.login).userInfo.token,
    pointsMultiplier: parsePointsMultiplier(state.pointsMultiplier),

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMultipliers: (payload) => {
      dispatch(getMultipliers(payload));
    },
    getProductBydId: (productId, token) => {
      dispatch(getProductBydId(productId, token));
    },
    getCategoryById: (productId, token) => {
      dispatch(getCategoryById(productId, token));
    },
    changePointsMultiplierStoreSettings: (key, value) => {
      dispatch(changePointsMultiplierStoreSettings(key, value));
    },
    getMultipliersCount: (payload) => {
      dispatch(getMultipliersCount(payload));
    },
    resetPointsMultiplierNewState: (payload) => {
      dispatch(resetPointsMultiplierNewState(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsMultiplierHOC);
