import React, {useState} from 'react';
import InputComp from "../InputComp";
import Table from "../General/table";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import People from "@material-ui/icons/People";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import AddCircle from "@material-ui/icons/AddCircle";
import TabContainer from "../General/tabContainer";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Stars from "@material-ui/icons/Stars";
import { withStyles } from '@material-ui/core/styles'
import TableMUI from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Indicator from "../General/indicator";
import ClientPassedEnabledOffers from "./clientPassedEnabledOffers";
import ClientEnabledOffers from "./clientEnalbedOffers";

const styles = theme => ({
    card: {
        // background: Colors.CART_ITEM_ODD_ITEM_CREME,
    },
    clientInfoCard: {
        // background: Colors.CART_ITEM_ODD_ITEM_CREME,
        height: '100%'
    }
});

const ClientOverview = (props) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);


    const openDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false)
    };

    const { classes } = props;
    return(
        <Paper>
            <Dialog
                open={deleteModalOpen}
                onClose={closeDeleteModal}
            >
                <DialogTitle>{"Delete Client"}</DialogTitle>
                <DialogContent>
                    {props.deletingClient && (
                        <Indicator isVisible={true}/>
                    )}
                    <DialogContentText>
                        Are you sure you want to delete the selected client?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={props.handleClientDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                    <Button onClick={closeDeleteModal}
                            color="secondary">Cancel</Button>
                </DialogActions>
            </Dialog>
            <Tabs
                value={props.activeTab}
                onChange={(evt, value) => props.setClientOverviewActiveTab({activeTab: value})}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab
                    icon={<People/>}
                    label="Identity"
                >
                </Tab>
                <Tab
                    icon={<ShoppingBasket/>}
                    label="Orders"
                >
                </Tab>
                <Tab
                    icon={<AddCircle/>}
                    label="Add points"
                >
                </Tab>
                <Tab
                    icon={<ShoppingBasket/>}
                    label="Enabled Offers"
                >
                </Tab>
                <Tab
                    icon={<ShoppingBasket/>}
                    label="Passed Enabled Offers"
                >
                </Tab>
            </Tabs>
            { props.activeTab === 0 && (
                <TabContainer>

                    <div className={'client-overview'}>
                        <div className={'client-overview__info'}>
                            <Card className={classes.clientInfoCard}>
                                <CardHeader
                                    avatar={<IconButton>
                                        <AccountCircle />
                                    </IconButton>}
                                    title="Information"
                                />
                                <CardContent>
                                    <TableMUI>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {'Username'}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {props.form.username}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {'Email'}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {props.form.email}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {'First name'}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {props.form.firstName}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {'Last name'}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {props.form.lastName}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {'Address'}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {props.form.address}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {'Country'}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {props.form.country}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {'Telephone'}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {props.form.telephone}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </TableMUI>
                                    <Button
                                        disabled={props.userRole !== 'admin'}
                                        className={'mt-3'}
                                        onClick={openDeleteModal}
                                        color="primary"
                                        variant="contained"
                                    >Delete</Button>
                                </CardContent>
                            </Card>
                        </div>
                        <div className={'client-overview__rewards'}>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={<IconButton>
                                        <Stars />
                                    </IconButton>}
                                    title="Points"
                                />
                                <CardContent>
                                    Points: {props.form.points}
                                </CardContent>
                            </Card>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={<IconButton>
                                        <Stars />
                                    </IconButton>}
                                    title="Leafs"
                                />
                                <CardContent>
                                    Leafs: {props.form.leafs}
                                </CardContent>
                            </Card>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={<IconButton>
                                        <Stars />
                                    </IconButton>}
                                    title="Flowers"
                                />
                                <CardContent>
                                    Flowers: {props.form.flowers}
                                </CardContent>
                            </Card>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={<IconButton>
                                        <Stars />
                                    </IconButton>}
                                    title="Badges"
                                />
                                <CardContent>
                                    Badges: {props.form.badges}
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </TabContainer>
            )}
            { props.activeTab === 1 && (
                <TabContainer>
                    <Table
                        handleRowSelect={props.handleOrderRowSelect}
                        label="Orders"
                        rows={props.orderRows}
                        columns={props.orderTableColumns}
                        fixedHeader={true}
                        selectable={false}
                        multiSelectable={false}
                        displaySelectAll={false}
                        showCheckboxes={false}
                        showRowHover={true}
                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        handleRowsPerPage={props.handleRowsPerPage}
                        handlePageChange={props.handlePageChange}
                        totalRows={props.totalRows}
                        clickableRows={true}
                        isSortable={true}
                        orderBy={props.orderOrdersBy}
                        order={props.ordersOrder}
                        onRequestSort={props.onRequestSortOrders}
                    />
                </TabContainer>
            )}
            { props.activeTab === 2 && (
                <TabContainer>
                    <div className={'col col-md-2'}>
                        <form onSubmit={props._submitClientExtraPoints}>
                            <InputComp Type="number" Name="clientExtraPoints"
                                       fullWidth={true} margin={'normal'}
                                       Label="Points"
                                       Value={props.clientExtraPoints}
                                       PropHandleInputChange={(evt) => props.changeClientOverviewStoreSettings(
                                           evt.target.name, evt.target.value)}/>
                            <Button
                                disabled={props.userRole !== 'admin'}
                                color="primary"
                                variant="contained"
                                type="submit">Add</Button>
                        </form>
                    </div>
                </TabContainer>
            )}
            { props.activeTab === 3 && (
                <TabContainer>
                    <ClientEnabledOffers
                        {...props}
                    />
                </TabContainer>
            )}
            { props.activeTab === 4 && (
                <TabContainer>
                    <ClientPassedEnabledOffers
                        {...props}
                    />
                </TabContainer>
            )}
            <Snackbar
                open={!!props.postingClientExtraPointsSuccessMessage}
                message={props.postingClientExtraPointsSuccessMessage}
                autoHideDuration={6000}
                onClose={() => {props.changeClientOverviewStoreSettings('postingClientExtraPointsSuccessMessage', '')}}
            />
            <Snackbar
                open={!!props.postingClientExtraPointsFailMessage}
                message={props.postingClientExtraPointsFailMessage}
                autoHideDuration={6000}
                onClose={() => {props.changeClientOverviewStoreSettings('postingClientExtraPointsFailMessage', '')}}
            />
        </Paper>
    )
};

export default withStyles(styles)(ClientOverview);
