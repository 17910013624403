import * as ActionTypes from '../actions/index';
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';

const form = {
    id: '',
    campaignPlanId:'',
    title: '',
    description:'',
    listIds: [],
    actionIds:[],
    utmCampaignName:'',
    image: '',
    startDate:'',
    endDate:'',
    campaignActions:[],
    tags:[],
    actionTags:[],
    receivedAction:'',
    status:"",
    actionStatus:[],
    modifiedAt:'',
    creationDate:''
};

const defaultState = fromJS({
    fetching: false,
    activeTab: 0,
    form: fromJS(form),
    clients: fromJS([]),
    deletingCampaing:false,
    deleteCampaignResponse:'',
    fetchingActions:false,
    deletingAction:false,
    campaignPlan:'',

});

function getCampaignById(state, action) {
    return state
        .set('fetching', true);
}

function receiveCampaignById(state, action) {
    return state
        .set('fetching', false)
        .setIn(['form', 'id'], action.payload.body.id !== null? action.payload.body.id: '')
        .setIn(['form', 'title'], action.payload.body.title !== null? action.payload.body.title: '')
        .setIn(['form', 'description'], action.payload.body.description !== null? action.payload.body.description: '')
        .setIn(['form', 'creationDate'], action.payload.body.creationDate !== null? action.payload.body.creationDate: '')
        .setIn(['form', 'modifiedAt'], action.payload.body.modifiedAt !== null? action.payload.body.modifiedAt: '')
        .setIn(['form', 'image'], action.payload.body.image !== null? action.payload.body.image: '')
        .setIn(['form', 'campaignPlanId'], action.payload.body.campaignPlanId !== null? action.payload.body.campaignPlanId: '')
        .setIn(['form', 'status'], action.payload.body.status !== null? action.payload.body.status: '')

}

function failedToGetCampaignById(state, action) {
    return state
        .set('fetching', false)
    // .set('form', fromJS(form))
}

function getCampaignPlanById(state, action) {
    return state
        .set('fetching', true);
}

function receiveCampaignPlanById(state, action) {
    return state
        .set('fetching', false)
        .setIn(['form', 'listIds'], action.payload.body.listIds !== null? action.payload.body.listIds: '')
        .setIn(['form', 'actionIds'], action.payload.body.actionIds !== null? action.payload.body.actionIds: '')
        .setIn(['form', 'tags'], action.payload.body.tags !== null? action.payload.body.tags: '')
        .setIn(['form', 'startDate'], action.payload.body.startDate !== null? action.payload.body.startDate: '')
        .setIn(['form', 'endDate'], action.payload.body.endDate !== null? action.payload.body.endDate: '')

}
function failedToGetCampaignPlanById(state, action) {
    return state
        .set('fetching', false)
    // .set('form', fromJS(form))
}


function logOff(state, action) {
    return defaultState;
}

function setCampaignOverviewActiveTab(state, action) {
    return state
        .set('activeTab', action.payload)
}

function resetState(state, action) {

    return defaultState
        .set('activeTab', 0)
    // .setIn(['form','actionIds'],[])
}

function deleteCampaignById(state,action){
    return state
        .set('deletingCampaing', true)
}
function deleteCampaignByIdSuccess(state,action){
    return state
        .set('deletingCampaing',false)
}

function deleteCampaignByIdFailed(state, action) {
    let errorMessage = null;
    try {
        errorMessage = action.payload.response.body.error.message
    } catch (e) {
        errorMessage = 'Failed to delete Campaign'
    }

    return state
        .set('deletingCampaing', false)
        .set('deleteCampaignResponse', errorMessage)
}

function getActionById(state,action) {
    return state
        .set('fetchingActions', true);
}
function receiveActionById(state,action) {
    try {
        action.payload = action.payload.body
    }
    catch (e) {
        action.payload = null
    }
    return state
        .set('fetchingActions', false)
        // .setIn(['form', 'campaignActions']
        .setIn(['form', 'campaignActions'],state.getIn(['form', 'campaignActions']).push(fromJS(action.payload)))
        .setIn(['form', 'actionTags'],action.payload.tags!== null? action.payload.tags: '')

}
function getActionByIdFailed(state,action) {
    let errorMessage = null;
    try {
        errorMessage = action.payload.response.body.error.message
    } catch (e) {
        errorMessage = 'Failed to get action'
    }

    return state
        .set('fetchingActions', false)
        .set('getActionResponseMessage', errorMessage)
}
function getActionStatusById(state, action) {
    return state
        .set('fetching', true);
}

function receiveActionStatusById(state, action) {
    return state
        .set('fetching', false)
        .setIn(['form', 'actionStatus'],state.getIn(['form', 'actionStatus']).push(fromJS(action.payload.body.result)))

}

function failedToGetActionStatusById(state, action) {
    return state
        .set('fetching', false)
    // .set('form', fromJS(form))
}
function deleteActionById(state,action) {
    return state
        .set('deletingAction', true);
  }
  function deleteActionByIdSuccess(state,action) {
    try {
        action.payload = action.payload.body
    }
    catch (e) {
        action.payload = null
    }
    return state
        .set('deletingAction', false)
        
  
  }
  function deleteActionByIdFailed(state,action) {
    let errorMessage = null;
    try {
        errorMessage = action.payload.response.body.error.message
    } catch (e) {
        errorMessage = 'Failed to get action'
    }
  
    return state
        .set('deletingAction', false)
        
  }
  


export const campaignNewOverview = createReducer(defaultState, {
    // [ActionTypes.CHANGE_CAMPAIGN_OVERVIEW_STORE_SETTINGS]: changeClientOverviewStoreSettings,
    [ActionTypes.GET_CAMPAIGN_BY_ID]: getCampaignById,
    [ActionTypes.RECEIVE_CAMPAIGN_BY_ID]: receiveCampaignById,
    [ActionTypes.FAILED_TO_GET_CAMPAIGN_BY_ID]: failedToGetCampaignById,
    [ActionTypes.LOG_OFF]: logOff,
    [ActionTypes.OVERVIEW_RESET_STATE]: resetState,
    [ActionTypes.GET_ACTION_STATUS_BY_ID]:getActionStatusById,
    [ActionTypes.RECEIVE_ACTION_STATUS_BY_ID]:receiveActionStatusById,
    [ActionTypes.FAILED_TO_GET_ACTION_STATUS_BY_ID]: failedToGetActionStatusById,
    [ActionTypes.SET_CAMPAIGN_OVERVIEW_ACTIVE_TAB]: setCampaignOverviewActiveTab,
    [ActionTypes.GET_CAMPAIGN_PLAN_BY_ID]:getCampaignPlanById,
    [ActionTypes.RECEIVE_CAMPAIGN_PLAN_BY_ID]:receiveCampaignPlanById,
    [ActionTypes.FAILED_TO_GET_CAMPAIGN_PLAN_BY_ID]: failedToGetCampaignPlanById,
    [ActionTypes.DELETE_CAMPAIGN_BY_ID]: deleteCampaignById,
    [ActionTypes.DELETE_CAMPAIGN_BY_ID_SUCCESS]: deleteCampaignByIdSuccess,
    [ActionTypes.DELETE_CAMPAIGN_BY_ID_FAILED]: deleteCampaignByIdFailed,
    [ActionTypes.GET_ACTION_BY_ID]: getActionById,
    [ActionTypes.RECEIVE_ACTION_BY_ID]:receiveActionById,
    [ActionTypes.GET_ACTION_BY_ID_FAILED]:getActionByIdFailed,
    [ActionTypes.DELETE_ACTION_BY_ID]:deleteActionById,
[ActionTypes.DELETE_ACTION_BY_ID_SUCCESS]:deleteActionByIdSuccess,
[ActionTypes.DELETE_ACTION_BY_ID_FAILED]:deleteActionByIdFailed,
});
