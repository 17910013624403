import React, {Component, useEffect} from 'react';
import { connect } from 'react-redux';
import CategoryOverview from "../../components/Categories/categoryOverview";
import _ from 'lodash';
import {getTableListPostitions, getTablePage} from "../../helpers/functions";
import {changeCategorySettings, getCategoryById} from "../../actions/categories";
import {parseCategories, parseLogin} from "../../helpers/parseImmutables";
import {useHistory, useLocation} from "react-router-dom";

const CategoryOverviewHOC = props => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(!location.pathname.includes('add')){
            props.getCategoryById(_.last(location.pathname.split('/')), props.login.userInfo.token);
        }
    }, [])

    const getProductRows = () => {
        let rows = [];
        let allProducts = [...props.categoryProducts];
        let position = getTableListPostitions(props.productsListRowsPerPage, props.productsListPage);
        allProducts = _.slice(allProducts, position.start, position.end);
        _.forEach(allProducts, product => {
            rows.push({
                id: product.id,
                data: [
                    product.name,
                    product.description,
                    props.categoryName,
                    product.points,
                    product.value
                ],
                label: product.name
            })
        });
        return rows
    };

    const handleProductRowClick = (productId) => {
        history.push('/product/edit/'+ productId)
    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.categoryProducts.length / rowsPerPage);
        let currentPage = props.productsListPage;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeStoreSettings('productsListRowsPerPage', rowsPerPage);
        props.changeStoreSettings('productsListPage', currentPage);
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.productsListPage,  props.categoryProducts.length, props.productsListRowsPerPage);
        props.changeStoreSettings('productsListPage', newPage);
    };

    let productTableHeaders = [
        'Product name',
        'description',
        'Category',
        'Points',
        'Value'
    ];
    return (
        <CategoryOverview
            activeTab={props.activeTab}
            changeStoreSettings={props.changeStoreSettings}
            page={props.productsListPage}
            totalRows={props.categoryProducts.length}
            handlePageChange={handlePageChange}
            rowsPerPage={props.productsListRowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            handleProductRowClick={handleProductRowClick}
            productTableHeaders={productTableHeaders}
            productTableRows={getProductRows()}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
        activeTab: parseCategories(state.categories).categoryOverviewActiveTab,
        categoryName: parseCategories(state.categories).fetchedCategory.catName,
        categoryProducts: parseCategories(state.categories).changedCategory.products,
        productsListPage: parseCategories(state.categories).productsListPage,
        productsListRowsPerPage: parseCategories(state.categories).productsListRowsPerPage,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeStoreSettings: (key, value) => {
            dispatch(changeCategorySettings(key, value))
        },
        getCategoryById: (categoryId, token) => {
            dispatch(getCategoryById(categoryId, token))
        },
    }
};

export default  connect(mapStateToProps, mapDispatchToProps)(CategoryOverviewHOC);