import React from 'react'
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import classNames from "classnames";
import {withStyles} from "@material-ui/core/styles";
import Colors from "../../helpers/constants/colors";

const styles = theme => ({
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    cell: {
        // color: Colors.DARK_TEXT_COLOR
    }
});

const TableRowComponent = (props) => {
    const { classes } = props;
    return(
        <TableRow hover className={classNames(props.isClickable?"mui-table-row":'', classes.row)} onClick={
            ()=> {
                props.isClickable && (
                    props.handleRowSelect(props.row.id)
                )
            }}>
            {
                props.row.data.map((data, ind) => {
                    return <TableCell className={classes.cell} key={ind}>{data}</TableCell>
                })
            }
        </TableRow>
    )
};

export default withStyles(styles, { withTheme: true })(TableRowComponent);