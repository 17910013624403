import React from 'react';
import InputComp from "./InputComp";
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";

const styles = theme => ({
    card: {
        maxWidth: 400,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
});

const AccountSettingsForm = (props) => {
    const { classes } = props;
    return (
        <div >
                <Card>
                    <CardHeader
                        avatar={
                            <IconButton>
                                <Edit />
                            </IconButton>
                        }
                        title="Edit Profile"
                    />
                    <CardContent>
                        <div className="row no-gutters" >
                            <div className="col col-lg-4 offset-lg-3">
                                <form id="form-edit-admin" onSubmit={props.handleSubmit}>
                                    <InputComp Type="password" Name="new_password"
                                               fullWidth={true} margin={'normal'}
                                               Label="New password"
                                               Value={props.login.userInfo.new_password}
                                               PropHandleInputChange={props.handleInputChange}/>
                                    <InputComp Type="password" Name="retypedNewPassword"
                                               fullWidth={true} margin={'normal'}
                                               Label="Retype New password"
                                               Value={props.login.userInfo.retypedNewPassword}
                                               PropHandleInputChange={props.handleInputChange}/>
                                </form>
                            </div>
                        </div>
                        <CardActions>
                            <Button
                                color="primary"
                                variant="contained"
                                form="form-edit-admin"
                                type="submit">Edit</Button>
                        </CardActions>
                    </CardContent>
                </Card>
            <Snackbar
                open={!!props.editResponse}
                message={props.editResponse}
                autoHideDuration={6000}
                onClose={() => {props.changeStoreSettings('editResponse', '')}}
            />
        </div>
    )
};


export default withStyles(styles)(AccountSettingsForm);
