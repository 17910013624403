import * as ActionTypes from '../actions/index';
import {fromJS} from "immutable";
// import {createReducer} from "./index"
import {createReducer} from './helpers/createReducer';

// const defaultCampaign = {
//     cid: "",
//     campaign_name: "",
//     title: "",
//     imageName: '',
//    campaign_plan:'',
//     endDate: "",
// };




const defaultState = fromJS({
    fetching: false,
    campaigns:[] ,
    fetchingTotalCampaigns: false,
    totalCampaigns: '',
    rowsPerPage: 10,
    page: 1,
    posting: false,
    orderBy: 1, //table header array index that does the mapping of column name and its key for sort
    order: 'desc',
    filterField: '',
    // suggestions: fromJS([]),
    
});

function changeCampaignStoreSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function getTotalCampaigns(state, action) {
    return state
        .set('fetchingTotalCampaigns', true);
}
function receiveTotalCampaigns(state, action) {
    return state
        .set('fetchingTotalCampaigns', false)
        .set('totalCampaigns', action.payload.body);
}
function failedToGetTotalCampaigns(state,action)
{
    return state
        .set('fetchingTotalCampaigns', false);
}

function getCampaigns(state, action) {
    return state
        .set('fetching', true);
}

function receiveCampaigns(state, action) {
    try {
        action.payload = action.payload.body
    }
    catch (e) {
        action.payload = null
    }
    return state
        .set('campaigns', fromJS(action.payload) )
        .set('fetching', false);
}

function failedToGetCampaigns(state, action) {
    return state
        .set('fetching', false);
}

function resetState(state, action) {
    return defaultState
}


export const campaignsNew = createReducer(defaultState, {
    [ActionTypes.GET_TOTAL_CAMPAIGNS]:getTotalCampaigns,
    [ActionTypes.RECEIVE_TOTAL_CAMPAIGNS]:receiveTotalCampaigns,
    [ActionTypes.FAILED_TO_GET_TOTAL_CAMPAIGNS]:failedToGetTotalCampaigns,
    [ActionTypes.GET_CAMPAIGNS]:getCampaigns,
    [ActionTypes.RESET_CAMPAIGNS_NEW_STATE]: resetState,
    [ActionTypes.RECEIVE_CAMPAIGNS]:receiveCampaigns,
    [ActionTypes.FAILED_TO_GET_CAMPAIGNS]: failedToGetCampaigns,
    [ActionTypes.CHANGE_CAMPAIGN_STORE_SETTINGS]: changeCampaignStoreSettings,


});
