import React, {Component, useEffect} from 'react';
import { connect } from 'react-redux';
import OrderForm from "../../components/Orders/orderForm";
import {parseCategories, parseLogin, parseOrderForm, parseOrders} from "../../helpers/parseImmutables";
import {changeOrderFormStoreSettings, changeOrderItems} from "../../actions/orderForm";
import {getClients} from "../../actions/clients";
import {isEmail} from "../../helpers/functions";
import _ from 'lodash';
import {toGetCategories} from "../../actions/categories";
import InputComp from "../../components/InputComp";
import {createNewOrder} from "../../actions/orders";
import moment from 'moment';
import Button from "@material-ui/core/Button";
import {changePeopleState, getPeopleSuggestions} from "../../actions/people";
import {useHistory} from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";

const parsePeopleSuggestions = (suggestions) => {
    return  suggestions.valueSeq().toArray().map(item => ({
        id: item.get('id'),
        email: item.get('email'),
        username: item.get('username'),
    }));
};

const OrderFormHOC = props => {
    const history = useHistory();
    const prevPosting = usePrevious(props.postingOrder);

    useEffect(() => {
        if(prevPosting && !props.postingOrder && !props.orderForm.errorMessage){
            history.push('/orders');
        }
    }, [prevPosting, props.postingOrder, props.errorMessage])

    useEffect(() => {
        props.getAllCategories(props.brandId, props.token);
    }, []);

    const goNext = () => {
        if(props.orderForm.currentStep === 3)
            return 0;
        props.changeStoreSettings('currentStep', props.orderForm.currentStep + 1)
    };

    const goBack = () => {
        if(props.orderForm.currentStep === 0)
            return 0;
        props.changeStoreSettings('currentStep', props.orderForm.currentStep - 1)
    };

    const _validate = () => {
        let invalidOrderItem = _.find(props.orderForm.orderItems, oi => {
            return !oi.amount || parseInt(oi.amount) === 0;
        });
        if(invalidOrderItem) {
            props.changeStoreSettings('errorMessage', 'Please fill the remaining fields.');
            return false;
        }
        return true;
    };

    const _getOrderDto = () => {
        return {
            date: moment().format(),
            lineItems: props.orderForm.orderItems.map(i => ({
                quantity: parseInt(i.amount),
                productId: i.productId
            })),
            personId: props.orderForm.fetchedUser.id,
            adminId: props.adminId,
            brandId: props.brandId,
            saveOrder: true
        }
    };

    const _handleSubmit = () => {
        let isValid = _validate;
        if(!isValid()){
            return false;
        }
        props.createNewOrder({
            order: _getOrderDto(),
            token: props.token
        })
    };

    const _handleInputChange = (evt) => {
        props.changeStoreSettings(evt.target.name, evt.target.value)
    };

    const _addUser = () => {
        if(!isEmail(props.orderForm.userEmail)){
            return false;
        }
        props.getClients({
            filteredEmail: props.orderForm.userEmail,
            token: props.token
        })
    };

    const _handleSelectCategories = (name, value) => {
        let newCheckedProducts = [];
        let orderItems = [];
        let products = [];
        _.forEach(props.categories, category => {
            if(_.includes(value, category.id)){
                products = [...products, ...category.products]
            }
        });
        _.forEach(products, product => {
            let orderI ;
            if(_.includes(props.orderForm.selectedProducts, product.id)){
                newCheckedProducts.push(product.id);
            }
        });
        orderItems = _.filter(props.orderForm.orderItems, o => { return newCheckedProducts.includes(o.productId)})
        props.changeStoreSettings(name, value);
        props.changeStoreSettings('selectedProducts', newCheckedProducts);
        props.changeStoreSettings('orderItems', orderItems);
    };

    const _handleSelectProducts = (name, value) => {
        let oi = _.filter(props.orderForm.orderItems, o => { return value.includes(o.productId)});
        _.forEach(value, v => {
            if(!props.orderForm.selectedProducts.includes(v)){
                oi.push({productId: v, amount: 0})
            }
        });
        props.changeStoreSettings(name, value);
        props.changeStoreSettings('orderItems',oi);
    };

    const _getProducts = () => {
        let products = [];
        _.forEach(props.categories, category => {
            if(_.includes(props.orderForm.selectedCategories, category.id)){
                products = [...products, ...category.products]
            }
        });
        return products;
    };

    const _getProductsTableColumns = () => {
        return [
            'name',
            'amount',
            ''
        ]
    };

    const _getProductName = (productId) => {
        let productName = '';
        _.forEach(props.categories, c => {
            _.forEach(c.products, p => {
                if(p.id === productId){
                    productName = p.name;
                    return false;
                }
            });
            if(productName){
                return false;
            }
        });
        return productName;
    };

    const _removeProductFromTable = (productIndex) => {
        let selectedProducts = [...props.orderForm.selectedProducts];
        let orderItems = [...props.orderForm.orderItems];
        selectedProducts.splice(productIndex, 1);
        orderItems.splice(productIndex, 1);
        props.changeStoreSettings('selectedProducts', selectedProducts);
        props.changeStoreSettings('orderItems', orderItems);
    };

    const _getProductsTablesRows = () => {
        return props.orderForm.orderItems.map((i, index) => ({
            id: i,
            data: [
               _getProductName(i.productId),
                <InputComp Required={true} Placeholder="Amount" Type="number" Name="amount"
                           Min={0}
                           fullWidth={false}
                           Label="" Value={i.amount}
                           PropHandleInputChange={(evt) => props.changeOrderItems({index: index, key: evt.target.name, value: evt.target.value})}/>,

                <Button
                    onClick={() => _removeProductFromTable(index)}
                    color="secondary"
                    variant="flat"
                >{"REMOVE"}</Button>
            ]
        }));
    };

    const _handleFilterFieldChange = (value) => {
        props.changeStoreSettings('userEmail', value);
    };

    const suggestionSelectedHandler = (suggestion) => {
        props.changeStoreSettings('fetchedUser', suggestion)

    };

    const onSuggestionsClearRequested = () => {
        props.changePeopleState('suggestions', []);
    };

    const _getPeopleSuggestions = () => {
        const filter = {
            where: {
                and: [
                    {email:  {like: "%25" + props.orderForm.userEmail + "%25"}},
                    {or: [{deleted: null}, {deleted: undefined}, {deleted: false}]}
                ]
            },
            limit: 5
        };
        props.getPeopleSuggestions({filter: filter})
    };

    const _removeFetchedSuggestionUser = () => {
        props.changeStoreSettings('fetchedUser', {})
    };

    return(
        <OrderForm
            {...props}
            _changeStoreSettings={props.changeStoreSettings}
            productsTablesRows={_getProductsTablesRows()}
            productsTableColumns={_getProductsTableColumns()}
            categories={props.categories}
            products={_getProducts()}
            _handleSelectCategories={_handleSelectCategories }
            _handleSelectProducts={_handleSelectProducts }
            _addUser={_addUser}
            _handleInputChange={_handleInputChange}
            _handleSubmit={_handleSubmit}
            goNext={goNext}
            goBack={goBack}
            orderForm={props.orderForm}
            peopleSuggestions={parsePeopleSuggestions(props.peopleSuggestions)}
            _handleFilterFieldChange={_handleFilterFieldChange}
            suggestionSelectedHandler={suggestionSelectedHandler}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            _getPeopleSuggestions={_getPeopleSuggestions}
            _removeFetchedSuggestionUser={_removeFetchedSuggestionUser}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        postingOrder: parseOrders(state.orders).posting,
        orderForm: parseOrderForm(state.orderForm),
        categories: parseCategories(state.categories).all,
        token: parseLogin(state.login).userInfo.token,
        adminId: parseLogin(state.login).userInfo.uid,
        brandId: parseLogin(state.login).userInfo.brand.id,
        peopleSuggestions: state.people.get('suggestions')
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeStoreSettings: (key, value) => {
            dispatch(changeOrderFormStoreSettings(key, value))
        },
        getClients: (payload) => {
            dispatch(getClients(payload))
        },
        getAllCategories: (brandId, token) => {
            dispatch(toGetCategories(brandId, token))
        },
        changeOrderItems: (payload) => {
            dispatch(changeOrderItems(payload))
        },
        createNewOrder: (payload) => {
            dispatch(createNewOrder(payload))
        },
        getPeopleSuggestions: (payload) => {
            dispatch(getPeopleSuggestions(payload))
        },
        changePeopleState: (payload) => {
            dispatch(changePeopleState(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderFormHOC);