import * as actions from '../actions/campaignsNewForm';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL,BASE_URL_DOTNET} from "../helpers/variables";
import sampleCombine from "xstream/extra/sampleCombine";
import flattenSequentially from "xstream/extra/flattenSequentially";
import flattenConcurrently from "xstream/extra/flattenConcurrently";


export function requestCreateCampaign(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.CREATE_CAMPAIGN)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/campaigns',
            category: 'requestCreateCampaign',
            method: 'POST',
            send:action.payload.sendBody,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCreateCampaign')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.createCampaignSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}
export function createCampaignFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCreateCampaign')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.createCampaignFail(response));

    return {
        ACTION: httpResponse$
    }
}





export function requestCreateAction(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.CREATE_ACTION)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/actions'
            
            ,
            category: 'requestCreateAction',
            method: 'POST',
            send:action.payload.sendBody,
            // withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCreateAction')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .compose(flattenConcurrently)
        .filter(response => response.status === 200)
        .map(response =>  actions.createActionSuccess(response)); //

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}
export function createActionFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCreateAction')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.createActionFail(response));

    return {
        ACTION: httpResponse$
    }
}
export function getActions(sources)
{
const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_ACTIONS)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/actions' 
            ,
            category: 'getActions',
            method: 'GET',
            withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('getActions')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveActions(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function getEvents(sources)
{
const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_EVENTS)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/' 
            ,
            category: 'getEvents',
            method: 'GET',
            withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('getEvents')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveEvents(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function failedToGetActionsEvents(sources)
{
    let httpResponse$ = sources.HTTP
        .select('getActions')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetActionsEvents(response));

    return {
        ACTION: httpResponse$
    }
}


export function requestEditCampaign(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.EDIT_CAMPAIGN)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/campaigns',
            category: 'requestEditCampaign',
            method: 'PATCH',
            send:action.payload.sendBody,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestEditCampaign')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.editCampaignSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}
export function editCampaignFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestEditCampaign')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.editCampaignFail(response));

    return {
        ACTION: httpResponse$
    }
}



export function requestEditAction(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.EDIT_ACTION)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/actions',
            category: 'requestEditAction',
            method: 'PATCH',
            send:action.payload.sendBody,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestEditAction')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.editActionSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}
export function editActionFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestEditAction')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.editActionFail(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestTestEmail(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.SEND_TEST_EMAIL)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/actions/sendTestEmail',
            category: 'sendTestEmail',
            method: 'POST',
            send:action.payload.sendBody,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('sendTestEmail')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.sendTestEmailSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}
export function testEmailFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('sendTestEmail')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.sendTestEmailFail(response));

    return {
        ACTION: httpResponse$
    }
}
