import React from 'react';
import TextField from '@material-ui/core/TextField';


const MaterialInput = (props) => {
    switch (props.Type) {
        case "text":
            return <TextField
                onKeyPress={props.onKeyPress}
                margin={props.margin}
                disabled={props.disabled}
                type={props.Type}
                step={props.Step}
                required={props.Required}
                min={props.Min}
                pattern={props.Pattern}
                name={props.Name}
                value={props.Value}
                onChange={props.PropHandleInputChange}
                label={props.Label}
                fullWidth={props.fullWidth}
            />;
        case "date":
            return (
                <TextField
                    InputLabelProps={{
                        shrink: true
                    }}
                    margin={props.margin}
                    required={props.Required}
                    disabled={props.disabled}
                    fullWidth={props.fullWidth}
                    name={props.Name}
                    pattern={props.Pattern}
                    value={props.Value}
                    onChange={(event)=>props.PropHandleInputChange(event.target.name, event.target.value)}
                    min={props.Min}
                    label={props.Label}
                    type="date"
                />
            )
        default:
                return <TextField
                    margin={props.margin}
                    type={props.Type}
                    disabled={props.disabled}
                    step={props.Step}
                    required={props.Required}
                    min={props.Min}
                    pattern={props.Pattern}
                    name={props.Name}
                    value={props.Value}
                    onChange={props.PropHandleInputChange}
                    label={props.Label}
                    fullWidth={props.fullWidth}
                />;
    }
};

export default MaterialInput;