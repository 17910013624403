import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HeaderMenuComponent from "./HeaderMenuComponent";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';

const HeaderComponent = (props) => {
    const {classes, theme} = props;

    return (
        <AppBar
            position="absolute"
            className={classNames(classes.appBar, props.drawer && classes.appBarShift)}
        >
            <Toolbar disableGutters={!props.drawer}>
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={props.toggleDrawer}
                    className={classNames(classes.menuButton, props.drawer && classes.hide)}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography variant="title" color="inherit" className={classes.flex}>
                    {props.title}
                </Typography>
                <HeaderMenuComponent
                    userRole={props.login.userInfo.role}
                    push={props.push}
                    signOut={props.signOut}/>
            </Toolbar>
        </AppBar>
    )
}

export default HeaderComponent;