import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import usePrevious from "../../hooks/usePrevious";
import _ from "lodash";
import Campaigns from "../../components/Campaigns/campaigns";
import {
  getCampaigns,
  changeCampaignStoreSettings,
  getTotalCampaigns,
  resetCampaignsNewState,
} from "../../actions/campaignsNew";
import {
  getCampaignPlanById,
  getCampaignById,
} from "../../actions/campaignNewOverview";
import { parseCampaigns, parseLogin } from "../../helpers/parseImmutables";
import { useHistory } from "react-router-dom";
import { getTablePage, _getSkipFilter } from "../../helpers/functions";

const tableColumns = [
  { name: "Id", key: "personId" },
  { name: "Title", key: "CampaignTitle" },
  { name: "Image", key: "Image" },
  // {name: 'Plan', key: 'CampaignPlan'},
  { name: "Creation Date (YYYY-MM-DD)", key: "CreationDate" },
  { name: "Status", key: "Status" },
];

const CampaignHOC = (props) => {
  const history = useHistory();
  const prevPage = usePrevious(props.campaigns.page);
  const prevRowsPerPage = usePrevious(props.campaigns.rowsPerPage);
  useEffect(() => {
    props.resetCampaignsNewState();
    props.getTotalCampaigns();
    return () => {
      props.resetCampaignsNewState();
  }
    // props.getCampaigns();
  }, []);

  useEffect(() => {
    
    if (
      props.campaigns.page !== prevPage ||
      props.campaigns.rowsPerPage !== prevRowsPerPage
    ) {
      props.getCampaigns({
        filter: {
          skip: _getSkipFilter(
            props.campaigns.page,
            props.campaigns.rowsPerPage
          ),
          limit: props.campaigns.rowsPerPage,
        },
      });
    }
  }, [
    props.campaigns.page,
    props.campaigns.rowsPerPage,
    prevPage,
    prevRowsPerPage,
  ]);

  const getCampaignRows = () => {
    let rows = [];
    let AllCampaigns = [...props.campaigns.campaigns];
    _.forEach(AllCampaigns, (campaign) => {
      rows.push({
        id: campaign.id,
        title: campaign.title,
        image: campaign.image,
        creationDate: campaign.creationDate,
        status: campaign.status,
      });
    });
    return rows;
  };

  const handleRowSelect = (campaignId, campaignPlanId) => {
    history.push("/campaigns/" + campaignId);
  };
  const handleRowsPerPage = (rowsPerPage) => {
    rowsPerPage = parseInt(rowsPerPage);
    let newTotalPages = _.ceil(props.campaigns.totalCampaigns / rowsPerPage);
    let currentPage = props.campaigns.page;
    if (parseInt(currentPage) > newTotalPages) {
      currentPage = newTotalPages;
    }
    props.changeCampaignStoreSettings("rowsPerPage", rowsPerPage);
    props.changeCampaignStoreSettings("page", currentPage);
  };
  const handlePageChange = (iconType) => {
    let newPage = getTablePage(
      iconType,
      props.campaigns.page,
      props.campaigns.totalCampaigns,
      props.campaigns.rowsPerPage // props.clients.rowsPerPage
    );
    props.changeCampaignStoreSettings("page", newPage);
  };

  return (
    <Campaigns
      tableColumns={tableColumns}
      rows={getCampaignRows()}
      handleRowSelect={handleRowSelect}
      handleRowsPerPage={handleRowsPerPage}
      handlePageChange={handlePageChange}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    token: parseLogin(state.login).userInfo.token,
    campaigns: parseCampaigns(state.campaignsNew),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCampaigns: (payload) => {
      dispatch(getCampaigns(payload));
    },
    getCampaignPlanById: (campaignPlanId, token) => {
      dispatch(getCampaignPlanById(campaignPlanId, token));
    },
    getCampaignById: (campaignId, token) => {
      dispatch(getCampaignById(campaignId, token));
    },
    changeCampaignStoreSettings: (key, value) => {
      dispatch(changeCampaignStoreSettings(key, value));
    },
    getTotalCampaigns: (payload) => {
      dispatch(getTotalCampaigns(payload));
    },
    resetCampaignsNewState: (payload) => {
      dispatch(resetCampaignsNewState(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignHOC);
