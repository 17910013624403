import React, {Component, useEffect} from "react";
import { connect } from "react-redux";
import {
  changeCampaignFormSettings,
  changeCampaignFormData,
  createAction,
} from "../../actions/campaignsNewForm";
import { getCampaigns } from "../../actions/campaignsNew";
import {
  parseLogin,
  parseCampaigns,
  parseCampgaignNewForm,
  parseClients,
} from "../../helpers/parseImmutables";
import {
  arrayUnique,
  GreekToGreeklish
} from "../../helpers/functions";
import _ from "lodash";
import CampaignNewFormHOC from "./campaignNewFormHOC";
import usePrevious from "../../hooks/usePrevious";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";

const CampaignNewCreateHOC = props => {
  const history = useHistory();

  const prevCampaignResponseMessage= usePrevious(props.campaignNewForm.createCampaignResponseMessage);



  useEffect(()=>
  {
    if( !props.campaignNewForm.createCampaignResponseMessage && prevCampaignResponseMessage==="Campaign was created successfully.")
  {
    history.push("/campaigns");
  }
  },[ prevCampaignResponseMessage,props.campaignNewForm.createCampaignResponseMessage])

  const handleSubmit = (evt) => {
    // to dotnet

    //  evt.preventDefault();
    // if(evt.currentTarget.name==='submit')
    // { }
    arrayUnique(props.campaignNewForm.formData.actionsSelected).forEach(action =>{
      if(action==="Newsletter"){
      props.createAction({
        sendBody: {
          Platform:'newsletter',
          utmCampaignSource:
          GreekToGreeklish(props.campaignNewForm.formData.title),
                //  +"&ppg_source="+ platform,

          ActionType:'Newsletter' ,
          ActionId:null,
          ActionUserIds:props.campaignNewForm.formData.selectedUsers,
          Timestamp:props.campaignNewForm.formData.emailDate==""||props.campaignNewForm.formData.emailTime==""?null:
              moment.utc( moment(props.campaignNewForm.formData.emailDate + "T" + props.campaignNewForm.formData.emailTime )),
          tags: props.campaignNewForm.formData.actionTags,
          SelectAll: props.campaignNewForm.formData.selectAll,

          emailToSendDTO: {
            emailFrom:  props.campaignNewForm.formData.emailFrom,
            emailSubject:  props.campaignNewForm.formData.emailSubject,
            emailContent:  props.campaignNewForm.formData.emailContent,
            
          },
        },
      })
    }
      if(action === "Push Notification"){
      props.campaignNewForm.formData.pushPlatforms.forEach(platform =>
        {
      props.createAction({
        sendBody: {
          Platform:platform,
          utmCampaignSource:
          GreekToGreeklish(props.campaignNewForm.formData.title),
                //  +"&ppg_source="+ platform,

          ActionType:'Push Notification',
          ActionId:null,// props.campaignNewForm.formData.selectedActionId,
          ActionUserIds:props.campaignNewForm.formData.selectedUsers,
          Timestamp:props.campaignNewForm.formData.actionDate==""||props.campaignNewForm.formData.actionTime==""?null:
              moment.utc( moment(props.campaignNewForm.formData.actionDate + "T" + props.campaignNewForm.formData.actionTime )),
          tags: props.campaignNewForm.formData.actionTags,
          SelectAll: props.campaignNewForm.formData.selectAll,

          pushNotificationToCreateDTO: {
            title: props.campaignNewForm.formData.notificationTitle,
            description: props.campaignNewForm.formData.notificationText,
            imageType:props.campaignNewForm.formData.notificationImageType,
            image: props.campaignNewForm.formData.notificationImage,
            bigImageType:props.campaignNewForm.formData.notificationBigImageType,
            bigImage: props.campaignNewForm.formData.notificationBigImage,
            url:props.campaignNewForm.formData.url.trim(),
          },
        },
      })
    })}
    
     } 
     )

  
    props.changeCampaignFormSettings('readyForCampaign',true );

  };

  return (
      <CampaignNewFormHOC
          handleSubmit={handleSubmit}
      />
  )
}


const mapStateToProps = (state) => {
  return {
    campaignNewForm: parseCampgaignNewForm(state.campaignNewForm),
    campaigns: parseCampaigns(state.campaignsNew),
    clients: parseClients(state.clients),
    token: parseLogin(state.login).userInfo.token_dotnet,
    login: parseLogin(state.login),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCampaignFormSettings: (key, value) => {
      dispatch(changeCampaignFormSettings(key, value));
    },
    changeCampaignFormData: (key, value) => {
      dispatch(changeCampaignFormData(key, value));
    },
    createAction: (payload) => {
      dispatch(createAction(payload));
    },
    getCampaigns: (payload) => {
      dispatch(getCampaigns(payload))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignNewCreateHOC);
