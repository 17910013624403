import React, {useState} from 'react';
import Table from "../General/table";
import Snackbar from '@material-ui/core/Snackbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Stars from "@material-ui/icons/Stars";
import FilterList from "@material-ui/icons/FilterList";
import GroupWork from "@material-ui/icons/GroupWork";
import TabContainer from "../General/tabContainer";
import TableMUI from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Edit from '@material-ui/icons/Edit';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton/IconButton";
import InputComp from "../InputComp";
import CheckBoxComp from "../CheckBoxComp";
import {useHistory} from "react-router-dom";

const PointsOverview = props => {
    const [editingField, setEditingFieldState] = useState(null);
    const [editingFieldValue, setEditingFieldValue] = useState('');
    const history = useHistory();

    const handleEditSubmit = () => {
        props.updatePointSystem({
            sendBody: {
                [editingField]: editingFieldValue
            },
            brandId: props.brandId
        });
        setEditingFieldValue('');
        setEditingFieldState(null);
    };

    const handleEditingFieldChange = (value) => {
        setEditingFieldValue(value);
    };

    const setEditingField = (editingField, editingFieldValue) => {
        setEditingFieldState(editingField);
        setEditingFieldValue(editingFieldValue);
    };

    const resetEditingField = () => {
        setEditingFieldState(null);
        setEditingFieldValue('');
    };

    return (
        <Paper>
            <Tabs
                value={props.activeTab}
                onChange={(evt, value) => props.setPointsOverviewActiveTab({activeTab: value})}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab
                    icon={<Stars/>}
                    label="Overview"
                >
                </Tab>
                <Tab
                    icon={<FilterList/>}
                    label="Levels"
                >
                </Tab>
                <Tab
                    icon={<GroupWork/>}
                    label="Combinations of products"
                >
                </Tab>

            </Tabs>
            { props.activeTab === 0 && (
                <TabContainer>
                    {
                        props.pointSystem.id ?
                            <div className="row">
                                <div className={'col col-lg-5'}>
                                    <TableMUI>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell padding="checkbox" style={{width: '20%'}}>
                                                    {editingField === 'includePointsEarnedPerEuro'?
                                                        <div>
                                                            <IconButton
                                                                onClick={handleEditSubmit}>
                                                                <Done/>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={resetEditingField}>
                                                                <Clear/>
                                                            </IconButton>
                                                        </div>
                                                        :
                                                        <IconButton
                                                            onClick={() => setEditingField('includePointsEarnedPerEuro', props.pointSystem.includePointsEarnedPerEuro)}>
                                                            <Edit/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell component={'th'} scope={'row'} padding={"none"} style={{width: '40%'}}>
                                                    {'Include points earned per euro'}
                                                </TableCell>
                                                <TableCell style={{width: '40%'}}>
                                                    {
                                                        editingField === 'includePointsEarnedPerEuro'?
                                                            <CheckBoxComp
                                                                Checked={editingFieldValue}
                                                                Type="checkbox"
                                                                Name="includePointsEarnedPerEuro"
                                                                Label=""
                                                                PropHandleInputChange={(key, value) => handleEditingFieldChange(value)}
                                                            />
                                                            :
                                                            props.pointSystem.includePointsEarnedPerEuro?'yes':'no'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding="checkbox" style={{width: '20%'}}>
                                                    {editingField === 'includePointsEarnedFromProducts'?
                                                        <div>
                                                            <IconButton
                                                                onClick={handleEditSubmit}>
                                                                <Done/>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={resetEditingField}>
                                                                <Clear/>
                                                            </IconButton>
                                                        </div>
                                                        :
                                                        <IconButton
                                                            onClick={() => setEditingField('includePointsEarnedFromProducts', props.pointSystem.includePointsEarnedFromProducts)}>
                                                            <Edit/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell component={'th'} scope={'row'} padding={"none"} style={{width: '40%'}}>
                                                    {'Include points earned from products'}
                                                </TableCell>
                                                <TableCell style={{width: '40%'}}>
                                                    {
                                                        editingField === 'includePointsEarnedFromProducts'?
                                                            <CheckBoxComp
                                                                Checked={editingFieldValue}
                                                                Type="checkbox"
                                                                Name="includePointsEarnedFromProducts"
                                                                Label=""
                                                                PropHandleInputChange={(key, value) => handleEditingFieldChange(value)}
                                                            />
                                                            :
                                                            props.pointSystem.includePointsEarnedFromProducts?'yes':'no'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding="checkbox" style={{width: '20%'}}>
                                                    {editingField === 'includePointsFromCombinationsOfProducts'?
                                                        <div>
                                                            <IconButton
                                                                onClick={handleEditSubmit}>
                                                                <Done/>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={resetEditingField}>
                                                                <Clear/>
                                                            </IconButton>
                                                        </div>
                                                        :
                                                        <IconButton
                                                            onClick={() => setEditingField('includePointsFromCombinationsOfProducts', props.pointSystem.includePointsFromCombinationsOfProducts)}>
                                                            <Edit/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell component={'th'} scope={'row'} padding={"none"} style={{width: '40%'}}>
                                                    {'Include points earned from combinations of products'}
                                                </TableCell>
                                                <TableCell style={{width: '40%'}}>
                                                    {
                                                        editingField === 'includePointsFromCombinationsOfProducts'?
                                                            <CheckBoxComp
                                                                Checked={editingFieldValue}
                                                                Type="checkbox"
                                                                Name="includePointsFromCombinationsOfProducts"
                                                                Label=""
                                                                PropHandleInputChange={(key, value) => handleEditingFieldChange(value)}
                                                            />
                                                            :
                                                            props.pointSystem.includePointsFromCombinationsOfProducts?'yes':'no'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding="checkbox" style={{width: '20%'}}>
                                                    {editingField === 'includeBirthday'?
                                                        <div>
                                                            <IconButton
                                                                onClick={handleEditSubmit}>
                                                                <Done/>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={resetEditingField}>
                                                                <Clear/>
                                                            </IconButton>
                                                        </div>
                                                        :
                                                        <IconButton
                                                            onClick={() => setEditingField('includeBirthday', props.pointSystem.includeBirthday)}>
                                                            <Edit/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell component={'th'} scope={'row'} padding={"none"} style={{width: '40%'}}>
                                                    {'Include points for birthday'}
                                                </TableCell>
                                                <TableCell style={{width: '40%'}}>
                                                    {
                                                        editingField === 'includeBirthday'?
                                                            <CheckBoxComp
                                                                Checked={editingFieldValue}
                                                                Type="checkbox"
                                                                Name="includeBirthday"
                                                                Label=""
                                                                PropHandleInputChange={(key, value) => handleEditingFieldChange(value)}
                                                            />
                                                            :
                                                            props.pointSystem.includeBirthday?'yes':'no'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding="checkbox" style={{width: '20%'}}>
                                                    {editingField === 'birthdayPoints'?
                                                        <div>
                                                            <IconButton
                                                                onClick={handleEditSubmit}>
                                                                <Done/>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={resetEditingField}>
                                                                <Clear/>
                                                            </IconButton>
                                                        </div>
                                                        :
                                                        <IconButton
                                                            onClick={() => setEditingField('birthdayPoints', props.pointSystem.birthdayPoints)}>
                                                            <Edit/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell component={'th'} scope={'row'} padding={"none"} style={{width: '40%'}}>
                                                    {'Birthday points'}
                                                </TableCell>
                                                <TableCell style={{width: '40%'}}>
                                                    {
                                                        editingField === 'birthdayPoints'?
                                                            <InputComp
                                                                Type="number"
                                                                fullWidth={true}
                                                                margin={'normal'}
                                                                Value={editingFieldValue}
                                                                Name="editingFieldValue"
                                                                Label="Birthday points"
                                                                PropHandleInputChange={(evt) => handleEditingFieldChange(evt.target.value)}
                                                            />
                                                            :
                                                            props.pointSystem.birthdayPoints
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding="checkbox" style={{width: '20%'}}>
                                                    {editingField === 'includeJoinTheProduct'?
                                                        <div>
                                                            <IconButton
                                                                onClick={handleEditSubmit}>
                                                                <Done/>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={resetEditingField}>
                                                                <Clear/>
                                                            </IconButton>
                                                        </div>
                                                        :
                                                        <IconButton
                                                            onClick={() => setEditingField('includeJoinTheProduct', props.pointSystem.includeJoinTheProduct)}>
                                                            <Edit/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell component={'th'} scope={'row'} padding={"none"} style={{width: '40%'}}>
                                                    {'Include points for joining the program'}
                                                </TableCell>
                                                <TableCell style={{width: '40%'}}>
                                                    {
                                                        editingField === 'includeJoinTheProduct'?
                                                            <CheckBoxComp
                                                                Checked={editingFieldValue}
                                                                Type="checkbox"
                                                                Name="includeJoinTheProduct"
                                                                Label=""
                                                                PropHandleInputChange={(key, value) => handleEditingFieldChange(value)}
                                                            />
                                                            :
                                                            (props.pointSystem.includeJoinTheProduct?'yes':'no')
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding="checkbox" style={{width: '20%'}}>
                                                    {editingField === 'joinTheProductPoints'?
                                                        <div>
                                                            <IconButton
                                                                onClick={handleEditSubmit}>
                                                                <Done/>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={resetEditingField}>
                                                                <Clear/>
                                                            </IconButton>
                                                        </div>
                                                        :
                                                        <IconButton
                                                            onClick={() => setEditingField('joinTheProductPoints', props.pointSystem.joinTheProductPoints)}>
                                                            <Edit/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell component={'th'} scope={'row'} padding={"none"} style={{width: '40%'}}>
                                                    {'Join the product points'}
                                                </TableCell>
                                                <TableCell style={{width: '40%'}}>
                                                    {
                                                        editingField === 'joinTheProductPoints'?
                                                            <InputComp
                                                                Type="number"
                                                                fullWidth={true}
                                                                margin={'normal'}
                                                                Value={editingFieldValue}
                                                                Name="joinTheProductPoints"
                                                                Label="Join the product points"
                                                                PropHandleInputChange={(evt) => handleEditingFieldChange(evt.target.value)}
                                                            />
                                                            :
                                                            props.pointSystem.joinTheProductPoints
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding="checkbox" style={{width: '20%'}}>
                                                    {editingField === 'baseRewardItemValue'?
                                                        <div>
                                                            <IconButton
                                                                onClick={handleEditSubmit}>
                                                                <Done/>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={resetEditingField}>
                                                                <Clear/>
                                                            </IconButton>
                                                        </div>
                                                        :
                                                        <IconButton
                                                            onClick={() => setEditingField('baseRewardItemValue', props.pointSystem.baseRewardItemValue)}>
                                                            <Edit/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell component={'th'} scope={'row'} padding={"none"} style={{width: '40%'}}>
                                                    {'Base reward item value'}
                                                </TableCell>
                                                <TableCell style={{width: '40%'}}>
                                                    {
                                                        editingField === 'baseRewardItemValue'?
                                                            <InputComp
                                                                Type="number"
                                                                fullWidth={true}
                                                                margin={'normal'}
                                                                Value={editingFieldValue}
                                                                Name="baseRewardItemValue"
                                                                Label="Base reward item value"
                                                                PropHandleInputChange={(evt) => handleEditingFieldChange(evt.target.value)}
                                                            />
                                                            :
                                                            props.pointSystem.baseRewardItemValue
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding="checkbox" style={{width: '20%'}}>
                                                    {editingField === 'mediumRewardItemValue'?
                                                        <div>
                                                            <IconButton
                                                                onClick={handleEditSubmit}>
                                                                <Done/>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={resetEditingField}>
                                                                <Clear/>
                                                            </IconButton>
                                                        </div>
                                                        :
                                                        <IconButton
                                                            onClick={() => setEditingField('mediumRewardItemValue', props.pointSystem.mediumRewardItemValue)}>
                                                            <Edit/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell component={'th'} scope={'row'} padding={"none"} style={{width: '40%'}}>
                                                    {'Medium reward item value'}
                                                </TableCell>
                                                <TableCell style={{width: '40%'}}>
                                                    {
                                                        editingField === 'mediumRewardItemValue'?
                                                            <InputComp
                                                                Type="number"
                                                                fullWidth={false}
                                                                margin={'normal'}
                                                                Value={editingFieldValue}
                                                                Name="mediumRewardItemValue"
                                                                Label="Medium reward item value"
                                                                PropHandleInputChange={(evt) => handleEditingFieldChange(evt.target.value)}
                                                            />
                                                            :
                                                            props.pointSystem.mediumRewardItemValue
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding="checkbox" style={{width: '20%'}}>
                                                    {editingField === 'finalRewardItemValue'?
                                                        <div>
                                                            <IconButton
                                                                onClick={handleEditSubmit}>
                                                                <Done/>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={resetEditingField}>
                                                                <Clear/>
                                                            </IconButton>
                                                        </div>
                                                        :
                                                        <IconButton
                                                            onClick={() => setEditingField('finalRewardItemValue', props.pointSystem.finalRewardItemValue)}>
                                                            <Edit/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell component={'th'} scope={'row'} padding={"none"} style={{width: '40%'}}>
                                                    {'Final reward item value'}
                                                </TableCell>
                                                <TableCell style={{width: '40%'}}>
                                                    {
                                                        editingField === 'finalRewardItemValue'?
                                                            <InputComp
                                                                Type="number"
                                                                fullWidth={true}
                                                                margin={'normal'}
                                                                Value={editingFieldValue}
                                                                Name="finalRewardItemValue"
                                                                Label="Final reward item value"
                                                                PropHandleInputChange={(evt) => handleEditingFieldChange(evt.target.value)}
                                                            />
                                                            :
                                                            props.pointSystem.finalRewardItemValue
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell padding="checkbox" style={{width: '20%'}}>
                                                    {editingField === 'firstSignInReward'?
                                                        <div>
                                                            <IconButton
                                                                onClick={handleEditSubmit}>
                                                                <Done/>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={resetEditingField}>
                                                                <Clear/>
                                                            </IconButton>
                                                        </div>
                                                        :
                                                        <IconButton
                                                            onClick={() => setEditingField('firstSignInReward', props.pointSystem.firstSignInReward)}>
                                                            <Edit/>
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell component={'th'} scope={'row'} padding={"none"} style={{width: '40%'}}>
                                                    {'First sign in reward'}
                                                </TableCell>
                                                <TableCell style={{width: '40%'}}>
                                                    {
                                                        editingField === 'firstSignInReward'?
                                                            <InputComp
                                                                Type="number"
                                                                fullWidth={true}
                                                                margin={'normal'}
                                                                Value={editingFieldValue}
                                                                Name="firstSignInReward"
                                                                Label="First sign in reward"
                                                                PropHandleInputChange={(evt) => handleEditingFieldChange(evt.target.value)}
                                                            />
                                                            :
                                                            props.pointSystem.firstSignInReward
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </TableMUI>
                                </div>
                            </div>
                            :
                            <div><strong>Point system is not set</strong> </div>
                    }
                    <Button
                        className={'mt-3'}
                        onClick={() =>history.push('/points/add')}
                        color="primary"
                        variant="contained"
                    >Create Point system</Button>
                </TabContainer>
            )}
            { props.activeTab === 1 && (
                <TabContainer>
                    <Table
                        handleRowSelect={() => null}
                        label="Product"
                        rows={props.levelTableRows}
                        columns={props.levelTableHeaders}
                        fixedHeader={true}
                        selectable={false}
                        multiSelectable={false}
                        displaySelectAll={false}
                        showCheckboxes={false}
                        showRowHover={true}

                        hidePagination={true}
                    />
                </TabContainer>
            )}
            { props.activeTab === 2 && (
                <TabContainer>
                    <Table
                        handleRowSelect={() => null}
                        label="Combinations of Products"
                        rows={props.combinationsTableRows}
                        columns={props.combinationOfProductsTableHeaders}
                        fixedHeader={true}
                        selectable={false}
                        multiSelectable={false}
                        displaySelectAll={false}
                        showCheckboxes={false}
                        showRowHover={true}

                        hidePagination={true}
                    />
                    <Button
                        className={'mt-3'}
                        onClick={props._addCombination}
                        color="primary"
                        variant="contained"
                    >Add combination</Button>
                    {
                        props.extraCombinations.length > 0?
                            <Button className={'mt-3 ml-3'}
                                    onClick={props._handleExtraCombinationsAddition}
                                    color="secondary"
                                    variant={"contained"}
                            >Update</Button>
                            :
                            null
                    }
                </TabContainer>
            )}
            <Snackbar
                open={!!props.responseMessage}
                message={props.responseMessage}
                autoHideDuration={6000}
                onClose={() => {props.changeStoreSettings('responseMessage', '')}}
            />
        </Paper>
    )
};

export default PointsOverview;
