import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function countCategories(payload) {
    return {
        type: ActionTypes.COUNT_CATEGORIES,
        payload: payload
    }
}

export function countCategoriesSuccess(payload) {
    return {
        type: ActionTypes.COUNT_CATEGORIES_SUCCESS,
        payload: payload
    }
}

export function countCategoriesFail(payload) {
    return {
        type: ActionTypes.COUNT_CATEGORIES_FAIL,
        payload: payload
    }
}

//action that requests category by if
export function getCategoryById(categoryId, token) {
    return {
        type: ActionTypes.GET_CATEGORY_BY_ID,
        payload: {
            categoryId,
            token
        }
    }
}

//action that receives category by id
export function receiveCategoryById(category) {
    return {
        type: ActionTypes.RECEIVE_CATEGORY_BY_ID,
        payload: category
    }
}

//action that receives failed response get category by id
export function failedToGetCategoryById(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_CATEGORY_BY_ID,
        payload: response
    }
}

export function changeCategorySettings(key, value) {
    return {
        type: ActionTypes.CHANGE_CATEGORY_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

export function toGetCategories(brandId, token, filter) {
    return {
        type: ActionTypes.REQUEST_ALL_CATEGORIES,
        payload: {brandId, token , filter}
    };
}

//action that receives failed response get categories
export function receiveErrorGetCategories(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_GET_CATEGORIES,
        payload: response
    }
}

export function receiveAllCategories(categories) {
    return {
        type: ActionTypes.RECEIVE_ALL_CATEGORIES,
        payload: categories
    }
}

export function editNewCategory(catId, catVal) {
    return {
        type: ActionTypes.EDIT_NEW_CATEGORY,
        payload: {key: catId, value: catVal}
    }
}

export function editStoredCategory(catId, catVal) {
    return {
        type: ActionTypes.EDIT_STORED_CATEGORY,
        payload: {key: catId, value: catVal}
    }
}

export function addingCategory(data, categoryBrand, token) {
    return {
        type: ActionTypes.REQUEST_ADD_CATEGORY,
        payload: {data: {...data, brid: categoryBrand}, token}
    };
}

export function receiveAddCategory(response) {
    return {
        type: ActionTypes.RECEIVE_ADD_CATEGORY,
        payload: response
    }
}

//action that failed to add category
export function receiveErrorAddCategory(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_ADD_CATEGORY,
        payload: response
    }
}

export function editingCategory(data, token) {
    return {
        type: ActionTypes.REQUEST_EDIT_CATEGORY,
        payload: {data, token}
    };
}

//action that receives failed response while editing category
export function receiveErrorEditCategory(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_EDIT_CATEGORY,
        payload: response
    }
}


export function receiveEditCategory(response) {
    return {
        type: ActionTypes.RECEIVE_EDIT_CATEGORY,
        payload: response
    }
}

export function showingEditCategoryForm(category, cid) {
    return {
        type: ActionTypes.SHOW_EDIT_CATEGORY,
        payload: {
            value: category,
            key: cid
        }
    }
}

export function toDeleteCategory(catId, token) {
    return {
        type: ActionTypes.REQUEST_DELETE_CATEGORY,
        payload: {catId, token}
    };
}

//action that receives error while deleting category
export function receiveErrorDeleteCategory(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_DELETE_CATEGORY,
        payload: response
    }
}

export function receiveDeleteCategory(catId) {
    return {
        type: ActionTypes.RECEIVE_DELETE_CATEGORY,
        payload: catId
    }
}

export const resetCategoriesState = actionCreator(ActionTypes.RESET_CATEGORIES_STATE);
