import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";
import {failedToGetTotalProfit, receiveTotalProfit, resetState} from "../actions/generalActions";

export function expiredToken(sources) {
    let httpResponse$ = sources.HTTP
        .select()
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter( response => response.status === 401 )
        .map(response => resetState());

    return {
        ACTION: httpResponse$,
    };
}

export function requestGetTotalProfit(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_TOTAL_PROFIT)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.brandId +'/totalProfit?access_token=' + action.payload.token,
            category: 'requestGetTotalProfit',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetTotalProfit')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => receiveTotalProfit(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function failedGetTotalProfit(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetTotalProfit')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => failedToGetTotalProfit(response));

    return {
        ACTION: httpResponse$
    }
}