import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

const style = {
    position: 'fixed',
    bottom: '3%',
    right: '3%'
};


export const AddListContentButton = (props) => {
    return (
        <Button
            color="primary"
            variant="fab"
            onClick={props.onClick}
            style={style}>
            <AddIcon />
        </Button>
    )
};

export default AddListContentButton;