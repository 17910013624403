import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import usePrevious from "../../hooks/usePrevious";
import _ from "lodash";
import { parseCampaigns, parseCategories, parseLogin,parsePointsMultiplier, parsePointsMultiplierForm } from "../../helpers/parseImmutables";
import { useHistory } from "react-router-dom";
import { getTablePage, _getSkipFilter } from "../../helpers/functions";
import {
    changePointsMultiplierFormSettings,
    changePointsMultiplierFormData,
    resetMultipliersState,
    createPointsMultiplier,
  } from "../../actions/pointsMultiplierForm";
import { toGetCategories } from "../../actions/categories";
import PointsMultiplierFormHOC from "./pointsMultiplierFormHOC";
import moment from "moment";



const PointsMultiplierCreateHOC = (props) => {
  const history = useHistory();
//   useEffect(() => {
//     props.resetMultipliersState();
//      props.toGetCategories(props.login.userInfo.brand.id, props.login.userInfo.token);
//   }, []);


  const handleSubmit = (evt, name) => {
    var targetGroup = props.pointsMultiplierForm.formData.targetGroup;
    var multiplierBody;
    // TO ALL DAY
    if(targetGroup =='categoryBased')
    {
      multiplierBody = {
        title: props.pointsMultiplierForm.formData.title, 
        dateFrom:new Date(props.pointsMultiplierForm.formData.startDate), 
        dateTo:new Date(props.pointsMultiplierForm.formData.endDate),  
         timeFrom:moment(props.pointsMultiplierForm.formData.startTime,'h:mm a'), 
         timeTo:moment(props.pointsMultiplierForm.formData.endTime,'h:mm a'), 
         value:props.pointsMultiplierForm.formData.multiplierValue, 
         categoryId:props.pointsMultiplierForm.formData.selectedCategory, 
         token:props.login.userInfo.token
     }
    }
    if(targetGroup =='productBased')
    {
      multiplierBody = {
        title: props.pointsMultiplierForm.formData.title, 
        dateFrom:new Date(props.pointsMultiplierForm.formData.startDate), 
        dateTo:new Date(props.pointsMultiplierForm.formData.endDate),  
         timeFrom:moment(props.pointsMultiplierForm.formData.startTime,'h:mm a'), 
         timeTo:moment(props.pointsMultiplierForm.formData.endTime,'h:mm a'), 
         value:props.pointsMultiplierForm.formData.multiplierValue, 
         productId:props.pointsMultiplierForm.formData.selectedProduct, 
         token:props.login.userInfo.token
     }
    }
    if(targetGroup =='allProducts')
    {
      multiplierBody = {
        title: props.pointsMultiplierForm.formData.title, 
        dateFrom:new Date(props.pointsMultiplierForm.formData.startDate), 
         dateTo:new Date(props.pointsMultiplierForm.formData.endDate),  
         timeFrom:moment(props.pointsMultiplierForm.formData.startTime,'h:mm a').format(), 
         timeTo:moment(props.pointsMultiplierForm.formData.endTime,'h:mm a').format(), 
         value:props.pointsMultiplierForm.formData.multiplierValue, 
         appliedToAllProducts:true, 
         token:props.login.userInfo.token
     }
    }
    
    props.createPointsMultiplier(multiplierBody)
  };

  return (
    <PointsMultiplierFormHOC
      handleSubmit={handleSubmit}
      isEdit = {false}
      selectedMultiplierId={null}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    login: parseLogin(state.login),
    pointsMultiplierForm: parsePointsMultiplierForm(state.pointsMultiplierForm),
    allCategories: parseCategories(state.categories).all,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePointsMultiplierFormData: (key, value) => {
        dispatch(changePointsMultiplierFormData(key, value));
      },
      toGetCategories: (brandId, token) => {
        dispatch(toGetCategories(brandId, token))
    },
    resetMultipliersState: (payload) => {
        dispatch(resetMultipliersState(payload));
      },
      createPointsMultiplier: (payload) => {
        dispatch(createPointsMultiplier(payload));
      },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsMultiplierCreateHOC);
