import React  from 'react';
import Table from "../General/table";
import {AddListContentButton} from "../General/addListContentButton";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import People from "@material-ui/icons/People";
import IconButton from "@material-ui/core/IconButton";
import {useHistory} from "react-router-dom";

const TellerList = (props) => {
    const history = useHistory();
    return(
        <Card>
            <CardHeader
                avatar={<IconButton>
                    <People />
                </IconButton>}
                title="Tellers list"
            />
            <CardContent>
                <div className={'mt-3'}>
                    <Table
                        handleRowSelect={props.handleRowSelect}
                        label="Tellers"
                        rows={props.rows}
                        columns={props.tableColumns}
                        fixedHeader={true}
                        selectable={false}
                        multiSelectable={false}
                        displaySelectAll={false}
                        showCheckboxes={false}
                        showRowHover={true}
                        clickableRows={true}
                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        handleRowsPerPage={props.handleRowsPerPage}
                        handlePageChange={props.handlePageChange}
                        totalRows={props.totalRows}
                    />
                </div>
                <AddListContentButton onClick={() =>history.push('/tellers/add')}/>
            </CardContent>
        </Card>
    )
};

export default TellerList;
