import React from 'react';
import {StandaloneSearchBox} from "react-google-maps/lib/components/places/StandaloneSearchBox";
import {withScriptjs} from "react-google-maps";
import InputComp from "../InputComp";


const MapSearchBox = withScriptjs((props) => {
    return (
        <StandaloneSearchBox
            box={props.box}
            handleInputChnage={props.handleInputChange}
            ref={ref => props.refs.searchBox = ref}
            onPlacesChanged={props.onPlacesChanged}
        >
            <InputComp
                margin={props.margin}
                fullWidth={props.fullWidth}
                Type="text"
                Label={props.box.label}
                Required={props.box.required}
                Name={props.box.name}
                Value={props.box.value}
                Placeholder={props.box.placeholder}
                PropHandleInputChange={props.handleInputChange}
            />
        </StandaloneSearchBox>
    )
});

export default MapSearchBox;