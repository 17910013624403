import React from 'react';
import InputComp from '../InputComp.js';
import SelectComp from '../SelectComp';
import CheckBoxComp from '../CheckBoxComp';

import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LocalOffer from "@material-ui/icons/LocalOffer";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from '@material-ui/core/Dialog';


const OffersFormComponent = (props) => {
    return (
        <div>
            <Dialog
                open={props.offersStore.isDeleteModalOpen}
                onClose={() => props.changeStoreSettings('isDeleteModalOpen', !props.offersStore.isDeleteModalOpen)}
            >
                <DialogTitle>{"Delete Offer"}</DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        Are you sure you want to delete the selected offer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={props.handleDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                    <Button onClick={() => props.changeStoreSettings('isDeleteModalOpen',
                        !props.offersStore.isDeleteModalOpen)}
                            color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={'col-12'}>
                <Card>
                    <CardHeader
                        avatar={
                            <IconButton>
                                <LocalOffer />
                            </IconButton>
                        }
                        title={props.isNew?'New Offer':'Edit Offer'}
                    />
                    <CardContent>
                        <div className="col col-lg-4 offset-lg-3 ">
                            <form id="form-offer" onSubmit={props.handleSubmit}>
                                <InputComp Required={true} Placeholder="Offer name" Type="text" Name="offer_name"
                                           disabled={!props.isNew}
                                           fullWidth={true} margin={'normal'}
                                           Label="Name" Value={props.TargetOffer.offer_name}
                                           PropHandleInputChange={props.handleInputChange}/>
                                <InputComp Required={true} Placeholder="Greek Title" Type="text"
                                           Name="titleEl"
                                           disabled={!props.isNew}
                                           fullWidth={true} margin={'normal'}
                                           Label="Greek title" Value={props.TargetOffer.titleEl}
                                           PropHandleInputChange={props.handleInputChange}/>
                                <InputComp Required={true} Placeholder="English Description" Type="text"
                                           Name="titleEn"
                                           disabled={!props.isNew}
                                           fullWidth={true} margin={'normal'}
                                           Label="English title" Value={props.TargetOffer.titleEn}
                                           PropHandleInputChange={props.handleInputChange}/>
                                <InputComp Required={true} Placeholder="Greek Description" Type="text"
                                           Name="descriptionEl"
                                           disabled={!props.isNew}
                                           fullWidth={true} margin={'normal'}
                                           Label="Greek description" Value={props.TargetOffer.descriptionEl}
                                           PropHandleInputChange={props.handleInputChange}/>
                                <InputComp Required={true} Placeholder="English description" Type="text"
                                           Name="descriptionEn"
                                           disabled={!props.isNew}
                                           fullWidth={true} margin={'normal'}
                                           Label="English description" Value={props.TargetOffer.descriptionEn}
                                           PropHandleInputChange={props.handleInputChange}/>
                                <InputComp Required={false}
                                           disabled={!props.isNew}
                                           fullWidth={true} margin={'normal'}
                                           Placeholder="format:dd-mm-yyyy hh:mm" Type="date" Name="start_date_readable"
                                           Label="Start Date" Value={props.TargetOffer.start_date}
                                           PropHandleInputChange={props.handleDateChange}/>
                                <InputComp Required={false}
                                           disabled={!props.isNew}
                                           fullWidth={true} margin={'normal'}
                                           Placeholder="format:dd-mm-yyyy hh:mm" Type="date" Name="end_date_readable"
                                           Label="End Date" Value={props.TargetOffer.end_date}
                                           PropHandleInputChange={props.handleDateChange}/>

                                <InputComp Required={true} Placeholder="Points required" Type="number"
                                           fullWidth={true} margin={'normal'}
                                           disabled={!props.isNew}
                                           Min={0.01}
                                           Step={"0.01"}
                                           Name="points_required" Label="Points required"
                                           Value={props.TargetOffer.points_required.toString()}
                                           PropHandleInputChange={props.handleInputChange}/>
                                <InputComp Required={false} Placeholder="Minimum order" Type="number"
                                           fullWidth={true} margin={'normal'}
                                           disabled={!props.isNew}
                                           Min={0.01}
                                           Step={"0.01"}
                                           Name="minOrder" Label="Minimum order"
                                           Value={props.TargetOffer.minOrder?props.TargetOffer.minOrder:''}
                                           PropHandleInputChange={props.handleInputChange}/>
                                <InputComp Required={true} Placeholder="Discount" Type="number" Name="discount"
                                           Min={0.01} margin={'normal'}
                                           disabled={!props.isNew}
                                           fullWidth={true}
                                           Step="0.01"
                                           Label="Discount"
                                           Value={props.TargetOffer.discount.toString()}
                                           PropHandleInputChange={props.handleInputChange}
                                />
                                <SelectComp TargetObj={props.TargetOffer.discount_type?parseInt(props.TargetOffer.discount_type):''} Name="discount_type" Label="Discount type"
                                            fullWidth={true} margin={'normal'}
                                            disabled={!props.isNew}
                                            Selected={props.TargetOffer.discount_type} Required={true}
                                            Values={props.discounts.discounts.map(item => {return {key: item.id, value: item.name} })}
                                            PropHandleInputChange={props.handleSelectChange}/>
                                <SelectComp Required={false} MenuName="Select category" TargetObj={props.TargetOffer.categories} Name="categories"
                                            disabled={props.TargetOffer.allCategories || !props.isNew}
                                            fullWidth={true} margin={'normal'}
                                            multiple={true}
                                            Label="Categories" Selected={props.TargetOffer.category} Values={props.categories.all.map(item => {return {key:item.id, value: item.name}})}
                                            PropHandleInputChange={props.handleSelectedProducts}/>
                                <CheckBoxComp
                                    Disabled={!props.isNew}
                                    Checked={props.TargetOffer.allCategories}
                                    Type="checkbox"
                                    Name="allCategories"
                                    Label="Include all categories"
                                    PropHandleInputChange={props.includeAllCategories}
                                />
                                {(props.TargetOffer.categories.length === 0  ) ? null
                                    : <SelectComp Required={false} MenuName="Select product"
                                                  disabled={props.TargetOffer.all_products || !props.isNew}
                                                  fullWidth={true}
                                                  TargetObj={props.TargetOffer.products} Name="products"
                                                  multiple={true}
                                                  Label="Product" Selected={props.TargetOffer.category}
                                                  Values={props.getProducts(props.TargetOffer)}
                                                  PropHandleInputChange={props.handleSelectedProducts}/>
                                }
                                <CheckBoxComp
                                    Disabled={!props.isNew}
                                    Checked={props.TargetOffer.all_products}
                                    Type="checkbox"
                                    Name="all_products"
                                    Label="Include all products of the selected categories"
                                    PropHandleInputChange={props.includeAllProducts}
                                />
                                <CheckBoxComp
                                    Disabled={!props.isNew}
                                    Checked={props.TargetOffer.promoted}
                                    Type="checkbox"
                                    Name="promoted"
                                    Label="Promoted"
                                    PropHandleInputChange={props.handleCheckBoxChange}
                                />
                                <CheckBoxComp
                                    Disabled={!props.isNew}
                                    Checked={props.TargetOffer.redeemPoints}
                                    Type="checkbox"
                                    Name="redeemPoints"
                                    Label="Redeem Points"
                                    PropHandleInputChange={props.handleCheckBoxChange}
                                />
                                <CheckBoxComp
                                    Disabled={!props.isNew}
                                    Checked={props.TargetOffer.recurring}
                                    Type="checkbox"
                                    Name="recurring"
                                    Label="Recurring"
                                    PropHandleInputChange={props.handleCheckBoxChange}/>
                            </form>
                        </div>
                    </CardContent>
                    <CardActions>
                        {props.isNew && (
                            <Button
                                color="primary"
                                variant="contained"
                                form="form-offer"
                                type="submit">{'Create'}</Button>
                        )}
                        {
                            !props.isNew && (
                                <Button
                                    onClick={() => props.changeStoreSettings('isDeleteModalOpen', true)}
                                    color="secondary"
                                    variant="contained"
                                >Delete</Button>
                            )
                        }
                    </CardActions>
                </Card>
            </div>

            <Snackbar
                open={props.offersStore.sendResponse?true:false}
                message={props.offersStore.sendResponse}
                autoHideDuration={6000}
                onClose={() => {props.changeStoreSettings('sendResponse', '')}}
            />
        </div>
    )
};



export default OffersFormComponent;
