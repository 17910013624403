import * as ActionTypes from './index';

export function clearStatisticsCompareValues(payload) {
    return {
        type: ActionTypes.CLEAR_STATISTICS_COMPARE_VALUES,
        payload: payload
    }
}

export function getEnabledOffersCompareStatistics(payload) {
    return {
        type: ActionTypes.GET_ENABLED_OFFERS_COMPARE_STATISTICS,
        payload: payload
    }
}

export function getEnabledOffersCompareStatisticsSuccess(payload) {
    return {
        type: ActionTypes.GET_ENABLED_OFFERS_COMPARE_STATISTICS_SUCCESS,
        payload: payload
    }
}

export function getEnabledOffersCompareStatisticsFailed(payload) {
    return {
        type: ActionTypes.GET_ENABLED_OFFERS_COMPARE_STATISTICS_FAILED,
        payload: payload
    }
}

export function getOrdersCompareStatistics(payload) {
    return {
        type: ActionTypes.GET_ORDERS_COMPARE_STATISTICS,
        payload: payload
    }
}

export function getOrdersCompareStatisticsSuccess(payload) {
    return {
        type: ActionTypes.GET_ORDERS_COMPARE_STATISTICS_SUCCESS,
        payload: payload
    }
}

export function getOrdersCompareStatisticsFailed(payload) {
    return {
        type: ActionTypes.GET_ORDERS_COMPARE_STATISTICS_FAILED,
        payload: payload
    }
}

export function getClientCompareStatistics(payload) {
    return {
        type: ActionTypes.GET_CLIENT_COMPARE_STATISTICS,
        payload: payload
    }
}

export function getClientCompareStatisticsSuccess(payload) {
    return {
        type: ActionTypes.GET_CLIENT_COMPARE_STATISTICS_SUCCESS,
        payload: payload
    }
}

export function getClientCompareStatisticsFailed(payload) {
    return {
        type: ActionTypes.GET_CLIENT_COMPARE_STATISTICS_FAILED,
        payload: payload
    }
}

export function getPointsCompareStatistics(payload) {
    return {
        type: ActionTypes.GET_POINTS_COMPARE_STATISTICS,
        payload: payload
    }
}

export function getPointsCompareStatisticsSuccess(payload) {
    return {
        type: ActionTypes.GET_POINTS_COMPARE_STATISTICS_SUCCESS,
        payload: payload
    }
}

export function getPointsCompareStatisticsFailed(payload) {
    return {
        type: ActionTypes.GET_POINTS_COMPARE_STATISTICS_FAILED,
        payload: payload
    }
}

export function getXpOrdersStatistics(payload) {
    return {
        type: ActionTypes.GET_XP_ORDERS_STATISTICS,
        payload: payload
    }
}

export function getXpOrdersStatisticsSuccess(payload) {
    return {
        type: ActionTypes.GET_XP_ORDERS_STATISTICS_SUCCESS,
        payload: payload
    }
}

export function getXpOrdersStatisticsFailed(payload) {
    return {
        type: ActionTypes.GET_XP_ORDERS_STATISTICS_FAILED,
        payload: payload
    }
}

export function getClientStatistics(payload) {
    return {
        type: ActionTypes.GET_CLIENT_STATISTICS,
        payload: payload
    }
}

export function getClientStatisticsSuccess(payload) {
    return {
        type: ActionTypes.GET_CLIENT_STATISTICS_SUCCESS,
        payload: payload
    }
}

export function getClientStatisticsFailed(payload) {
    return {
        type: ActionTypes.GET_CLIENT_STATISTICS_FAILED,
        payload: payload
    }
}

export function getEnabledOffersStatistics(payload) {
    return {
        type: ActionTypes.GET_ENABLED_OFFERS_STATISTICS,
        payload: payload
    }
}

export function getEnabledOffersStatisticsSuccess(payload) {
    return {
        type: ActionTypes.GET_ENABLED_OFFERS_STATISTICS_SUCCESS,
        payload: payload
    }
}

export function getEnabledOffersStatisticsFailed(payload) {
    return {
        type: ActionTypes.GET_ENABLED_OFFERS_STATISTICS_FAILED,
        payload: payload
    }
}

export function getPointsStatistics(payload) {
    return {
        type: ActionTypes.GET_POINTS_STATISTICS,
        payload: payload
    }
}

export function getPointsStatisticsSuccess(payload) {
    return {
        type: ActionTypes.GET_POINTS_STATISTICS_SUCCESS,
        payload: payload
    }
}

export function getPointsStatisticsFailed(payload) {
    return {
        type: ActionTypes.GET_POINTS_STATISTICS_FAILED,
        payload: payload
    }
}

export function getOrdersStatistics(payload) {
    return {
        type: ActionTypes.GET_ORDERS_STATISTICS,
        payload: payload
    }
}

export function getOrdersStatisticsSuccess(payload) {
    return {
        type: ActionTypes.GET_ORDERS_STATISTICS_SUCCESS,
        payload: payload
    }
}

export function getOrdersStatisticsFailed(payload) {
    return {
        type: ActionTypes.GET_ORDERS_STATISTICS_FAILED,
        payload: payload
    }
}