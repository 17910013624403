import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import FlareIcon from "@material-ui/icons/Flare";
import AddListContentButton from "../General/addListContentButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Pagination from "../General/pagination.js";
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { useHistory } from "react-router-dom";
import { normalizeTargetGroupText } from "../../helpers/functions";

const PointsMultiplier = (props) => {
  const history = useHistory();



  return (
    <Card>
      <CardHeader avatar={<ControlPointIcon  style={{transform:'rotate(45deg)'}}/>} title="Multipliers list" />

      {/* {props.userRole === "admin" && ( )} */}
        <AddListContentButton
          onClick={() => history.push("/pointsMultiplier/add")}
        />
     
      <Table>
        <TableHead>
          <TableRow>
            {props.tableColumns.map((column) => (
              <TableCell key={column.key}> {column.name} </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {props.pointsMultiplier.fetching === true ? (
            <CircularProgress />
          ) : (
            props.rows.map((multiplier) => {
              if(multiplier.deletedDate ==null)
              {
              return (
                <TableRow
                  key={multiplier.id}
                  onClick={() =>
                   props.handleRowSelect(multiplier.id)
                  }
                  hover={true}
                  style={{ cursor: "pointer",}}
                >
                  <TableCell>
                    <Typography variant="subheading">
                      {multiplier.title}
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography variant="subheading">
                  {multiplier.targetGroup}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subheading">
                     x {multiplier.value}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subheading">
                      {multiplier.dateTo.split("T")[0]}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subheading">
                      {multiplier.createdDate.split("T")[0]}
                    </Typography>
                  </TableCell>
                 
                </TableRow>
              );
            }
            })
          )}
        </TableBody>
      </Table>
      <Pagination
        page={props.pointsMultiplier.page}
        totalRows={props.pointsMultiplier.multipliersCount}
        handlePageChange={props.handlePageChange}
        rowsPerPage={props.pointsMultiplier.rowsPerPage}
        handleRowsPerPage={props.handleRowsPerPage}
        //   colSpan={props.tableColumns.length}
      />
    </Card>
  );
};

export default PointsMultiplier;
