import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export const changeEditorsOrder = actionCreator(ActionTypes.CHANGE_EDITORS_ORDER);

export const changeEditorsState = actionCreator(ActionTypes.CHANGE_EDITORS_STATE);

export const countEditors = actionCreator(ActionTypes.COUNT_EDITORS);

export const countEditorsSuccess = actionCreator(ActionTypes.COUNT_EDITORS_SUCCESS);

export const countEditorsFailed = actionCreator(ActionTypes.COUNT_EDITORS_FAILED);

export const getEditors = actionCreator(ActionTypes.GET_EDITORS);

export const getEditorsSuccess = actionCreator(ActionTypes.GET_EDITORS_SUCCESS);

export const getEditorsFailed = actionCreator(ActionTypes.GET_EDITORS_FAILED);