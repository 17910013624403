import React, {Component} from 'react';
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";
import { compose, withProps, lifecycle } from 'recompose';
import _ from 'lodash';

class MapWithAMarker extends Component {
    shouldComponentUpdate(nextProps){
        if(this.props.markerPosition.lat !== nextProps.markerPosition.lat || (this.props.markerPosition.lng !== nextProps.markerPosition.lng) || (!_.isEqual(this.props.multipleMarkersPosition, nextProps.multipleMarkersPosition) )){
            return true;
        }
        else {
            return false;
        }
    }

    geo = (handleCoordinates ) => {
        let cb = (results, status) => {
            if (status == 'OK') {
                // this.props.handleCoordinates(results)
                console.log('here result of geocoder', results);
                console.log(this.props);
            } else {
                // console.log('Geocode was not successful for the following reason: ' + status);
            }
        };
        return cb;
    };
    render(){
        const MyMapComponent = compose(
            withProps({
                multipleMarkers: this.props.multipleMarkers,
                multipleMarkersPosition: this.props.multipleMarkersPosition,
                handleCoordinates: this.props.handleCoordinates,
                isMarkerShown: this.props.isMarkerShown,
                markerPosition:this.props.markerPosition,
                onDragEnd:this.props.onDragEnd,
                googleMapURL:"https://maps.googleapis.com/maps/api/js?key=AIzaSyC2HVuMFUP1m-0YxdgCHZE70CYAzVFXBIs&v=3.exp&libraries=geometry,drawing,places",
                loadingElement: (<div style={{ height: '100%' }} />),
                containerElement: (<div style={{ height: '400px' }} />),
                mapElement: (<div style={{ height: '100%' }} />)
            }),
            withScriptjs,
            withGoogleMap,
            lifecycle({
                componentDidMount() {
                    let geocoder = new window.google.maps.Geocoder();
                    let  geo = (handleCoordinates ) => {
                        let cb = (results, status) => {
                            if (status == 'OK') {
                                this.props.handleCoordinates(results);
                                console.log('here result of geocoder', results);
                                console.log(this.props);
                            } else {
                                // console.log('Geocode was not successful for the following reason: ' + status);
                            }
                        };
                        return cb;
                    };
                    geocoder.geocode( {latLng: this.props.markerPosition}, geo(this.props.handleCoordinates));
                }
            })
        )(props=>
            <GoogleMap
                panTo={(props.markerPosition.lng || props.markerPosition.lat)?props.markerPosition: { lat: 39.074208 , lng: 21.824312}}
                defaultZoom={(props.markerPosition.lng || props.markerPosition.lat)?17 : 7}
                center={(props.markerPosition.lng || props.markerPosition.lat)?props.markerPosition: { lat: 39.074208 , lng: 21.824312}}
                defaultCenter={{ lat: 39.074208 , lng: 21.824312}}
            >
                {
                    props.multipleMarkers?
                        props.multipleMarkersPosition.map( (pos, index) => {
                            return (
                                <Marker
                                    key={index}
                                    draggable={false}
                                    position={{ lat:pos.lat, lng: pos.lng}}
                                />
                            )
                        })
                        :
                        null
                }
                {props.isMarkerShown && <Marker
                    onDragEnd={props.onDragEnd}
                    draggable={true}
                    position={{ lat: props.markerPosition.lat?props.markerPosition.lat:39.074208, lng: props.markerPosition.lng?props.markerPosition.lng:21.824312}}
                />}
            </GoogleMap>);

        return(
            <MyMapComponent/>
        );
    }
}

export default MapWithAMarker;