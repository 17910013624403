import * as actions from '../actions/campaignNewOverview';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL,BASE_URL_DOTNET} from "../helpers/variables";
import sampleCombine from "xstream/extra/sampleCombine";
import flattenSequentially from "xstream/extra/flattenSequentially";
import flattenConcurrently from "xstream/extra/flattenConcurrently";


export function getCampaignById(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CAMPAIGN_BY_ID)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/campaigns/' + action.payload.campaignId,
            category: 'getCampaignById',
            method: 'GET',
            withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('getCampaignById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveCampaignById(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function failedGetCampaignById(sources) {
    let httpResponse$ = sources.HTTP
        .select('getCampaignById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetCampaignById(response));

    return {
        ACTION: httpResponse$
    }
}



export function getCampaignPlanById(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CAMPAIGN_PLAN_BY_ID)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/campaigns/campaignPlan/'+ action.payload.campaignPlanId,
            category: 'getCampaignPlanById',
            method: 'GET',
            withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('getCampaignPlanById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveCampaignPlanById(response));
    

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function failedGetCampaignPlanById(sources) {
    let httpResponse$ = sources.HTTP
        .select('getCampaignPlanById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetCampaignById(response));

    return {
        ACTION: httpResponse$
    }
}


export function deleteCampaignById(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.DELETE_CAMPAIGN_BY_ID)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/campaigns/'+ action.payload.campaignId,
            category: 'deleteCampaignById',
            method: 'DELETE',
            withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('deleteCampaignById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.deleteCampaignByIdSuccess(response));
    

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function deleteCampaignByIdFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('deleteCampaignById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.deleteCampaignByIdFailed(response));

    return {
        ACTION: httpResponse$
    }
}
export function getActionById(sources)
{
const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_ACTION_BY_ID)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/actions/' + action.payload.actionId
            ,
            category: 'getActionΒyId',
            method: 'GET',
            withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('getActionΒyId')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        // .compose(flattenSequentially)
        .compose(flattenConcurrently)

        .filter(response => response.status === 200)
        .map(response => actions.receiveActionById(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}




export function getActionStatusById(sources)
{
const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_ACTION_STATUS_BY_ID)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/actions/status/' + action.payload.messageId//ACTION ID
            // '?access_token=' + token +
            //  (
            //     action.payload && action.payload.whereFilter?
            //        ( `&where=` + JSON.stringify(action.payload.where)):''
            // )
            ,
            category: 'getActionStatusById',
            method: 'GET',
            withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('getActionStatusById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        // .compose(flattenSequentially)
        .compose(flattenConcurrently)

        .filter(response => response.status === 200)
        .map(response => actions.receiveActionStatusById(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}
export function failedToGetActionStatusById(sources) {
    let httpResponse$ = sources.HTTP
        .select('getActionStatusById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetActionStatusById(response));

    return {
        ACTION: httpResponse$
    }
}

export function deleteActionById(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.DELETE_ACTION_BY_ID)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/actions/'+ action.payload.actionId,
            category: 'deleteActionById',
            method: 'DELETE',
            withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('deleteActionById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .compose(flattenConcurrently)
        .filter(response => response.status === 200|| response.status === 204 )
        .map(response => actions.deleteActionByIdSuccess(response));
    

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function deleteActionByIdFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('deleteActionById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .compose(flattenConcurrently)
        .filter(response => response.status !== 200 && response.status !== 204)
        .map(response => actions.deleteActionByIdFailed(response));

    return {
        ACTION: httpResponse$
    }
}