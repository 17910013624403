import {fromJS} from "immutable";
import * as ActionTypes from '../actions/index';
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    fetching: false,
    enabledOffers: fromJS([]),
    totalEnabledOffers: '',
    rowsPerPage: 10,
    page: 1,
    orderBy: 6,
    order: 'desc'
});

function getEnabledOffers(state, action) {
    return state
        .set('fetching', true)
}

function getEnabledOffersSuccess(state, action) {
    return state
        .set('fetching', false)
        .set('enabledOffers', fromJS(action.payload.body))
}

function getEnabledOffersFailed(state, action) {
    return state
        .set('fetching', false)
}

function changeEnabledOffersState(state, action) {
    return state
        .set(action.payload.key, action.payload.value)
}

function changeEnabledOffersOrder(state, action) {
    return state
        .set('order', action.payload.order)
        .set('orderBy', action.payload.orderBy)
}

function countEnabledOffers(state, action) {
    return state;
}

function countEnabledOffersSuccess(state, action) {
    return state
        .set('totalEnabledOffers', action.payload.body.count)
}

function countEnabledOffersFailed(state, action) {
    return state;
}

function resetState(state, action) {
    return defaultState;
}

export const enabledOffers = createReducer(defaultState, {
    [ActionTypes.GET_ENABLED_OFFERS]: getEnabledOffers,
    [ActionTypes.GET_ENABLED_OFFERS_SUCCESS]: getEnabledOffersSuccess,
    [ActionTypes.GET_ENABLED_OFFERS_FAILED]: getEnabledOffersFailed,
    [ActionTypes.CHANGE_ENABLED_OFFERS_STATE]: changeEnabledOffersState,
    [ActionTypes.CHANGE_ENABLED_OFFERS_ORDER]: changeEnabledOffersOrder,
    [ActionTypes.COUNT_ENABLED_OFFERS]: countEnabledOffers,
    [ActionTypes.COUNT_ENABLED_OFFERS_SUCCESS]: countEnabledOffersSuccess,
    [ActionTypes.COUNT_ENABLED_OFFERS_FAILED]: countEnabledOffersFailed,
    [ActionTypes.RESET_ENABLED_OFFERS_STATE]: resetState
});