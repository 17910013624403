import React, {Component, useEffect} from 'react';
import Table from "../General/table";
import {useParams} from "react-router-dom";

const ClientPassedEnabledOffers  = props => {
    const {id} = useParams();

    useEffect(() => {
        props.getEnabledOffers({
            filter: {
                where: {
                    and: [ {personId: id}, {or: [{expired: true}, {redeemed: true}]} ]
                },
                include: 'offer'
            }
        })
    }, []);

    return (
        <Table
            handleRowSelect={props.handleOfferRowSelect}
            label="Passed Enabled Offers"
            rows={props.offersTableRows}
            columns={props.offersTableColumns}
            fixedHeader={true}
            selectable={false}
            multiSelectable={false}
            displaySelectAll={false}
            showCheckboxes={false}
            showRowHover={true}
            clickableRows={true}
            page={props.offersListPage}
            rowsPerPage={props.offersListRowsPerPage}
            handleRowsPerPage={props.handleOfferListRowsPerPage}
            handlePageChange={props.handleOfferListPageChange}
            totalRows={props.offersTotalRows}

        />
    )
}

export default ClientPassedEnabledOffers;