import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

//action that requests the total stores of the brand
export function getTotalStores(brandId, token) {
    return {
        type: ActionTypes.GET_TOTAL_STORES,
        payload: {
            brandId,
            token
        }
    }
}

//action that receives total stores of the brand
export function receiveTotalStores(totalStores) {
    return {
        type: ActionTypes.RECEIVE_TOTAL_STORES,
        payload: totalStores
    }
}

//action that receives failed response from requesting the total stores of the brand
export function failedToGetTotalStores(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_TOTAL_STORES,
        payload: response
    }
}

//function that requests store by id
export function requestStoreById(storeId, token) {
    return {
        type: ActionTypes.REQUEST_STORE_BY_ID,
        payload: {
            storeId,
            token
        }
    }
}

//function that receives store by id
export function receiveStoreById(store) {
    return {
        type: ActionTypes.RECEIVE_STORE_BY_ID,
        payload: store
    }
}

//function that receives bad response get store by id
export function failedToGetStoreById(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_STORE_BY_ID,
        payload: response
    }
}


export function  changeStoreSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_STORE_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

export function resetDefaultStore() {
    return {
        type: ActionTypes.RESET_DEFAULT_STORE
    }
}


export function receiveDeleteStore(storeId) {
    return {
        type: ActionTypes.RECEIVE_DELETE_STORE,
        payload: storeId
    }
}

//action that receives error while deleting a store
export function receiveErrorDeleteStore(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_DELETE_STORE,
        payload: response
    }
}

export function toDeleteStore(storeId, token) {
    return {
        type: ActionTypes.REQUEST_DELETE_STORE,
        payload: {storeId, token}
    };
}

export function showStore(id, store) {
    return {
        type: ActionTypes.SHOW_STORE,
        payload: {key: id, value: store}
    }
}

export function changeSelectedStore(storeKey, storeValue) {
    return {
        type: ActionTypes.CHANGE_SELECTED_STORE,
        payload: {key: storeKey, value: storeValue}
    }
}

export function receiveAllStores(stores) {
    return {
        type: ActionTypes.RECEIVE_ALL_STORES,
        payload: stores
    }
}

export function submitExistingStore() {
    return {
        type: ActionTypes.SUBMIT_EXISTING_STORE
    }
}


export function changeNewStore(storeKey, storeValue) {
    return {
        type: ActionTypes.TEMP_NEW_STORE,
        payload: {key: storeKey, value: storeValue}
    }
}

export function receiveAddStore(response) {
    return {
        type: ActionTypes.RECEIVE_ADD_STORE,
        payload: response
    }
}

export function receiveEditStore(store) {
    return {
        type: ActionTypes.RECEIVE_EDIT_STORE,
        payload: store
    }
}

export function addingStore(storeBrand, data, token) {
    return {
        type: ActionTypes.ADD_NEW_STORE,
        payload: {data: {...data, brid: storeBrand}, token}
    };
}

//action that receives error while adding new store
export function receiveErrorAddStore(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_ADD_STORE,
        payload: response
    }
}

export function editingStore(dataId, data) {
    return {
        type: ActionTypes.SUBMIT_EXISTING_STORE,
        payload: {dataId, data}
    };
}

//action that receives error while editing a store
export function receiveErrorEditStore(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_EDIT_STORE,
        payload: response
    }
}

export function gettingAllStores(brandId, token, filter) {
    return {
        type: ActionTypes.REQUEST_ALL_STORES,
        payload: {brandId, token, filter}
    }
}

//action that receives error get stores
export function receiveErrorGetStores(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_GET_STORES,
        payload: response
    }
}

export const resetStoresState = actionCreator(ActionTypes.RESET_STORES_STATE);