import * as ActionTypes from './index';

export function resetState(payload) {
    return {
        type: ActionTypes.RESET_STATE,
        payload: payload
    }
}

//action that requests the total profit of the brand
export function getTotalProfit(brandId, token) {
    return {
        type: ActionTypes.GET_TOTAL_PROFIT,
        payload: {
            brandId,
            token
        }
    }
}

//action that receives total profit of the brand
export function receiveTotalProfit(totalProfit) {
    return {
        type: ActionTypes.RECEIVE_TOTAL_PROFIT,
        payload: totalProfit
    }
}

//action that receives failed response from getting the total profit of a brand
export function failedToGetTotalProfit(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_TOTAL_PROFIT,
        payload: response
    }
}

//action to close drawer
export function toggleDrawer(payload) {
    return {
        type: ActionTypes.TOGGLE_DRAWER,
        payload
    }
}

