import React  from 'react';
import Table from "../General/table";
import {AddListContentButton} from "../General/addListContentButton";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ViewComfy from "@material-ui/icons/ViewComfy";
import IconButton from "@material-ui/core/IconButton";
import {isAdmin} from "../../helpers/functions";
import {useHistory} from "react-router-dom";

const CategoryList = (props) => {
    const history = useHistory();
    return(
            <Card>
                <CardHeader
                    avatar={<IconButton>
                        <ViewComfy />
                    </IconButton>}
                    title="Categories list"
                />
                <CardContent>
                    <div className={'mt-3'}>
                        <Table
                            handleRowSelect={props.handleRowSelect}
                            label="Categories"
                            columns={props.tableColumns}
                            rows={props.categories}
                            fixedHeader={true}
                            selectable={false}
                            multiSelectable={false}
                            displaySelectAll={false}
                            showCheckboxes={false}
                            showRowHover={true}
                            clickableRows={isAdmin(props.userRole)}
                            page={props.page}
                            rowsPerPage={props.rowsPerPage}
                            handleRowsPerPage={props.handleRowsPerPage}
                            handlePageChange={props.handlePageChange}
                            totalRows={props.totalRows}
                        />
                    </div>
                </CardContent>
                {isAdmin(props.userRole) && (
                    <AddListContentButton onClick={() =>history.push('/category/add')}/>
                )}
            </Card>
    )
};


export default CategoryList;
