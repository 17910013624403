import React from 'react';
import {
    CartesianGrid,
    Legend,
    Line,
    ComposedChart,
    Area,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    Bar
} from "recharts";
import moment from "moment";
import Colors from "../../helpers/constants/colors";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {withStyles} from "@material-ui/core/styles";
import _ from 'lodash';
import {dateTypeMapper} from "../../helpers/functions";

const getData = (props) => {
    return  _.zipWith(props.pointsStatistics, props.pointsCompareStatistics, props.ordersStatistics, (p, c, o) => ({
        total: p?p.total: 0,
        date: p?moment(p.date).format():null,
        compareTotal: c?c.total:0,
        compareDate: c?moment(c.date).format():null,
        orders: o?o.orders: 0,
    }));
};

const CustomToolTip = (props) => {
    try {
        return (
            <div className={'recharts-tooltip-wrapper'}>
                <div className={'custom-tooltip'}>
                    {(props.active && props.payload) && (
                        <div>
                            <div>
                                <p className={'label'}>{`date: ${moment(props.payload[0].payload.date).format('YYYY/MM/DD HH:mm')}`}</p>
                                <p className={'intro'} style={{color: props.payload[0].fill}}>{`total order points: ${props.payload[0].payload.total}`}</p>
                                <p className={'intro'} style={{color: props.payload[1].fill}}>{`orders: ${props.payload[1].payload.orders}`}</p>
                            </div>
                            {props.compare && (
                                <div>
                                    <p className={'label'}>{`compared date: ${moment(props.payload[0].payload.compareDate).format('YYYY/MM/DD HH:mm')}`}</p>
                                    <p className={'intro'} style={{color: props.payload[3].fill}}>{`compared total points: ${props.payload[3].payload.compareTotal}`}</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>

            </div>
        )
    } catch (e) {
        return null
    }
};

const PointsChart  = (props) => {
    const { classes } = props;
    const data = getData(props);
    return (
        <Card className={classes.card}>
            <CardContent>
                <ResponsiveContainer  width={'100%'} height={300}>
                    <ComposedChart data={data}>
                        <XAxis
                            dataKey="date"
                            tickFormatter={(tick) => {return dateTypeMapper(tick, props.dateType)}}
                        />
                        <YAxis yAxisId="left" label={{ value: "Points", angle: -90, position: 'insideLeft' }} />
                        <YAxis yAxisId="right" orientation="right" label={{ value: "Orders", angle: -90, position: 'insideRight' }}/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip content={<CustomToolTip compare={props.compare}/>} />
                        <Legend />
                        <Area
                            yAxisId="left"
                            isAnimationActive={false}
                            dataKey="total"
                            name={'Total order points'}
                            fill={Colors.COFFEE_BROWN}
                            stroke={Colors.COFFEE_BROWN}
                        />
                        <Bar yAxisId="right" isAnimationActive={false} stackId="a" dataKey="orders" name={'orders'} fill={'#ff7300'} />
                        <Line yAxisId="right" isAnimationActive={false} dataKey={(dataKeys) => {return dataKeys.orders / 2}} name={'orders'}  stroke={Colors.GREY} />
                        {(props.pointsCompareStatistics.length > 0) && (
                            <Area
                                yAxisId="left"
                                isAnimationActive={false}
                                strokeDasharray="3 4 5 2"
                                dataKey="compareTotal"
                                name={'Compare total points'}
                                fill={Colors.BENCH_BORDER_COLOUR}
                                stroke={Colors.BENCH_BORDER_COLOUR}
                            />
                        )}
                    </ComposedChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default withStyles({})(PointsChart);