import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import FlareIcon from "@material-ui/icons/Flare";
import AddListContentButton from "../General/addListContentButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Pagination from "../General/pagination.js";

import { useHistory } from "react-router-dom";

const Campaigns = (props) => {
  const history = useHistory();

  return (
    <Card>
      <CardHeader avatar={<FlareIcon></FlareIcon>} title="Campaigns list" />

      {/* {props.userRole === "admin" && ( )} */}
        <AddListContentButton
          onClick={() => history.push("/campaignsNew/add")}
        />
     
      <Table>
        <TableHead>
          <TableRow>
            {props.tableColumns.map((column) => (
              <TableCell key={column.key}> {column.name} </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {props.campaigns.fetching === true ? (
            <CircularProgress />
          ) : (
            props.rows.map((campaign) => {
              return (
                <TableRow
                  key={campaign.id}
                  onClick={() =>
                    props.handleRowSelect(campaign.id, campaign.campaignPlanId)
                  }
                  hover={true}
                  style={{ cursor: "pointer",}}
                >
                  <TableCell>
                    <Typography>{campaign.id}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subheading">
                      {campaign.title}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {campaign.image.length==0?<Typography>NO IMAGE</Typography>:
                    <img
                      src={`data:image/png;base64,${campaign.image}`}
                      style={{ maxWidth: 150, borderRadius: 5, maxHeight: 150 }}
                    ></img>}
                  </TableCell>
                  <TableCell>
                    <Typography variant="subheading">
                      {campaign.creationDate.split("T")[0]}
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography >
                      {campaign.status==0?"Unsent":campaign.status==1?"In progress":campaign.status==2?"Sent":campaign.status==3?"Finished":null}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <Pagination
        page={props.campaigns.page}
        totalRows={props.campaigns.totalCampaigns}
        handlePageChange={props.handlePageChange}
        rowsPerPage={props.campaigns.rowsPerPage}
        handleRowsPerPage={props.handleRowsPerPage}
        //   colSpan={props.tableColumns.length}
      />
    </Card>
  );
};

export default Campaigns;
