import React from 'react';
import {AddListContentButton} from "../General/addListContentButton";
import Table from "../General/table";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import IconButton from "@material-ui/core/IconButton";
import People from "@material-ui/icons/People";
import CardHeader from "@material-ui/core/CardHeader";
import {useHistory} from "react-router-dom";

const Editors = (props) => {
    const history = useHistory();
    return (
        <Card>
            <CardHeader
                avatar={<IconButton>
                    <People />
                </IconButton>}
                title="Editors list"
            />
            <CardContent>
                <Table
                    handleRowSelect={props.handleRowSelect}
                    label="Editors"
                    rows={props.listRows}
                    columns={props.listColumns}

                    fixedHeader={true}
                    selectable={false}
                    multiSelectable={false}

                    displaySelectAll={false}
                    showCheckboxes={false}
                    showRowHover={true}

                    page={props.page}
                    rowsPerPage={props.rowsPerPage}
                    handleRowsPerPage={props.handleRowsPerPage}
                    handlePageChange={props.handlePageChange}
                    totalRows={props.totalEditors}
                    clickableRows={true}

                    isSortable={true}
                    orderBy={props.orderBy}
                    order={props.order}
                    onRequestSort={props.onRequestSort}
                />
            </CardContent>
            <AddListContentButton onClick={() => history.push('/editors/add')}/>
        </Card>
    )
};

export default Editors;