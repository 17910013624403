import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import FlareIcon from "@material-ui/icons/Flare";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { useHistory } from "react-router-dom";
import { Button, CardActions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Snackbar, TextField } from "@material-ui/core";
import moment from "moment";
import { normalizeTargetGroupText } from "../../helpers/functions";
import Pagination from "../General/pagination.js";



const PointsMultiplierForm = (props) => {
    const history = useHistory();

    return (
        <Card>
             <Dialog open={props.deleteModalState} onClose={() =>props.toggleDeleteModal(!props.deleteModalState)}>
                <DialogTitle>{"Delete multiplier"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the selected multiplier?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={() =>{props.toggleDeleteModal(!props.deleteModalState)
                                    props.handleDeleteMultiplier()}}
                        name="deleteModalYes"
                    >Yes</Button>
                    <Button
                        onClick={() =>props.toggleDeleteModal(!props.deleteModalState)}
                        name="deleteModal"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <CardHeader avatar={<ControlPointIcon  style={{transform:'rotate(45deg)'}}/>} title="Create new multiplier" />
            <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                <div>
                    <form id="form-teller" className="form-horizontal" onSubmit={props.handleSubmit}>


                        <TextField
                            value={props.pointsMultiplierForm.formData.title}
                            id="outlined-textarea"
                            label="Multiplier title*"
                            type="text"
                            name="title"
                            onChange={(evt) => props.inputChange(evt)}
                            variant="outlined"
                            style={{ width: 300 }}
                            helperText={props.pointsMultiplierForm.formData.title.trim() === "" ? 'Required field' : ''}
                        ></TextField>

                        <Typography variant="subheading" style={{ paddingTop: 10 }}>Start Date*</Typography>
                        <input
                            type="date"
                            id="start"
                            style={{ width: 300 }}
                            max="2100-01-01"
                            min={moment().format("YYYY-MM-DD")}
                            value={props.pointsMultiplierForm.formData.startDate}
                            name="startDate"
                            onChange={props.inputChange}
                        />

                        <Typography variant="subheading" style={{ paddingTop: 10 }}>End Date*</Typography>
                        <input
                            type="date"
                            style={{ width: 300 }}
                            id="start"
                            max="2100-01-01"
                            min={moment().format("YYYY-MM-DD")}
                            value={props.pointsMultiplierForm.formData.endDate}
                            name="endDate"
                            onChange={props.inputChange}
                        />
                        <div style={{ display: "flex" }}>
                            <div>
                                <Typography variant="subheading" style={{ paddingTop: 10 }}>Start time*</Typography>
                                <input
                                    type="time"
                                    name="startTime"
                                    label="Start time"
                                    //disabled={props.pointsMultiplierForm.formData.allDay}
                                    value={props.pointsMultiplierForm.formData.startTime}
                                    onChange={props.inputChange}
                                    style={{ maxWidth: 150, alignSelf: "flex-end" }}
                                />
                            </div>

                            <div style={{ marginLeft: 75 }}>
                                <Typography variant="subheading" style={{ paddingTop: 10 }}>End time*</Typography>
                                <input
                                    type="time"
                                    name="endTime"
                                    title="End time"
                                    //disabled={props.pointsMultiplierForm.formData.allDay}
                                    value={props.pointsMultiplierForm.formData.endTime}
                                    style={{ maxWidth: 150, alignSelf: "flex-end" }}
                                    onChange={props.inputChange}
                                />

                            </div>

                        </div>
                        {/* <Typography variant="subheading" style={{ paddingTop: 10 }}>All day
                            <Checkbox
                                checked={props.pointsMultiplierForm.formData.allDay}
                                name='allDay'
                                style={{ marginLeft: 10 }}
                                label="All day"
                                onChange={() => props.inputChange({ target: { name: 'allDay', value: !props.pointsMultiplierForm.formData.allDay } })}
                            /></Typography> */}
                        <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 20, paddingTop: 10 }}>
                            <InputLabel id="demo-simple-select-label">Multiplier value</InputLabel>
                            <Select
                                value={props.pointsMultiplierForm.formData.multiplierValue}
                                style={{ width: 300 }}
                                placeholder="Target group"
                                name='multiplierValue'
                                onChange={(evt) => props.inputChange(evt)}
                            >
                                <MenuItem value={2}>{'x2'}</MenuItem>
                                <MenuItem value={4}>{'x4'}</MenuItem>
                                <MenuItem value={6}>{'x6'}</MenuItem>
                                <MenuItem value={8}>{'x8'}</MenuItem>
                            </Select>
                        </div>



                        <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 20 }}>
                            <InputLabel id="demo-simple-select-label">Target group</InputLabel>
                            <Select
                                value={props.pointsMultiplierForm.formData.targetGroup}
                                style={{ width: 300 }}
                                placeholder="Target group"
                                name='targetGroup'
                                onChange={(evt) => props.inputChange(evt)}
                            >
                                <MenuItem value="categoryBased">Category based</MenuItem>
                                <MenuItem value="productBased">Product based</MenuItem>
                                <MenuItem value="allProducts">All products</MenuItem>
                            </Select>
                        </div>
                        {
                            (props.pointsMultiplierForm.formData.targetGroup === 'categoryBased' ||
                                props.pointsMultiplierForm.formData.targetGroup === 'productBased') &&
                            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 20 }}>
                                <InputLabel>Select category</InputLabel>
                                <Select
                                    value={props.pointsMultiplierForm.formData.selectedCategory}
                                    style={{ width: 300 }}
                                    placeholder="Select category"
                                    name='selectedCategory'
                                    onChange={(evt) => props.inputChange(evt)}
                                >
                                    {props.allCategories.map(c => {
                                        
                                            return <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                                        

                                    })}
                                </Select>
                            </div>
                        }

                        {
                            props.pointsMultiplierForm.formData.targetGroup === 'productBased' &&
                            props.pointsMultiplierForm.formData.selectedCategory !== '' &&
                            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 20 }}>
                                <InputLabel id="demo-simple-select-label">Select product</InputLabel>
                                <Select
                                    value={props.pointsMultiplierForm.formData.selectedProduct}
                                    style={{ width: 300 }}
                                    placeholder="Target group"
                                    name='selectedProduct'
                                    onChange={(evt) => props.inputChange(evt)}
                                >
                                    {props.allCategories.length > 0 &&
                                    props.allCategories.filter(c => c.id == props.pointsMultiplierForm.formData.selectedCategory)[0].products.map(p => {
                                     
                                            return <MenuItem value={p.id}>{p.name}</MenuItem>
                                    })}
                                </Select>
                            </div>
                        }
                    </form>
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <caption>Active multipliers</caption>
                <Paper
              component="ul"
              style={{ maxWidth: 600, maxHeight: 400, padding: 5,overflow:'auto',paddingBottom:50 }}
            >
              
            <Table >
            
        <TableHead>
          <TableRow>
          <TableCell key={'name'}> {'Name'} </TableCell>
          <TableCell key={'targetGroup'}> {'Target group'} </TableCell>
          {/* <TableCell key={'target'}> {'Target'} </TableCell> */}
          <TableCell key={'multiplierValue'}> {'Multiplier value'} </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.pointsMultiplier.fetching === true ? (
            <CircularProgress />
          ) : (
            props.pointsMultiplier.multipliers.map((multiplier) => {
               
                if(multiplier.dateTo>= moment().format())
                {
              return (
                <TableRow
                  key={multiplier.id}
                  hover={true}
                  style={{ cursor: "pointer",}}
                  onClick={() =>
                    props.handleRowSelect(multiplier.id)
                   }
                >
                  <TableCell>
                    <Typography variant="subheading">
                      {multiplier.title}
                    </Typography>
                  </TableCell>
                  
                  <TableCell>
                    <Typography variant="subheading">
                     {/* {props.getSelectedTargetForPointMultiplier(multiplier)} */}
                     {multiplier.categoryId != null?"Category based": multiplier.productId!=null?"Product based":"All products"}
                    </Typography>
                  </TableCell>
                  {/* <TableCell>
                    <Typography variant="subheading">
                        
                       {props.getSelectedTargetForPointMultiplier(multiplier)}
                      {multiplier.categoryId != null?props.activeProductsInMultipliers.filter(c => c.id == multiplier.categoryId)[0].name:
                       multiplier.productId!=null?props.activeProductsInMultipliers.filter(c => c.id == multiplier.productId)[0].name:'-'}
                    </Typography>
                  </TableCell> */}
                  <TableCell>
                    <Typography variant="subheading">
                      x {multiplier.value}
                    </Typography>
                  </TableCell>
                 
                </TableRow>
              );
            }
            })
          )}
        </TableBody>
      </Table>
      <Pagination
        page={props.pointsMultiplier.page}
        totalRows={props.pointsMultiplier.multipliersCount}
        handlePageChange={props.handlePageChange}
        rowsPerPage={props.pointsMultiplier.rowsPerPage}
        handleRowsPerPage={props.handleRowsPerPage}
        //   colSpan={props.tableColumns.length}
      />
            </Paper>
            </div>
                </div>
            <CardActions>
                <Button
                    onClick={props.handleSubmit}
                    color="primary"
                    variant="contained"
                    type="text"
                    name='submit'
                // disabled={props.loadingCampaign}
                >
                   {props.isEdit? 'Update':'Create'}
                </Button>
                {props.isEdit &&
                <Button
                    onClick={() =>props.toggleDeleteModal(!props.deleteModalState)}
                   // color="#f00"
                    variant="contained"
                    type="text"
                    name='submit'
                >
                 Delete
                </Button>
                    }


                {(props.pointsMultiplierForm.fetching) && <CircularProgress style={{width:25,height:25}}/>}
            </CardActions>
            <Snackbar
        open={
          !!props.pointsMultiplierForm.validationMessage || !!props.pointsMultiplierForm.responseMessage
        }
        message={
          props.pointsMultiplierForm.validationMessage||props.pointsMultiplierForm.responseMessage
        }
        autoHideDuration={4000}
        onClose={props.handleValidationMessageClose}
      />
        </Card>
        
    );
};

export default PointsMultiplierForm;
