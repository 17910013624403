import React, {Component} from 'react';
import MapWithAMarker from "../../components/General/mapWithAMarker";

const  MapWithAMarkerHOC = props => {
    return (
        <MapWithAMarker
            multipleMarkers={props.multipleMarkers}
            multipleMarkersPosition={props.multipleMarkersPosition}
            handleCoordinates={props.handleCoordinates}
            markerPosition={props.markerPosition}
            onDragEnd={props.onDragEnd}
            isMarkerShown={(props.markerPosition.lng || props.markerPosition.lat)?true:false}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC2HVuMFUP1m-0YxdgCHZE70CYAzVFXBIs&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
        />
    )
}

export default MapWithAMarkerHOC;

