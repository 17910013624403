import {fromJS} from "immutable";
import * as ActionTypes from '../actions/index';
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    fetching: false,
    discounts: []
});

function getDiscounts(state, action) {
    return state
        .set('fetching', true);
}

function receiveDiscounts(state, action) {
    return state
        .set('fetching', false)
        .set('discounts', fromJS(action.payload));
}

function failedToGetDiscounts(state, action) {
    return state
        .set('fetching', false);
}

function logOff(state, action) {
    return defaultState;
}

export const discounts = createReducer(defaultState, {
    [ActionTypes.GET_DISCOUNTS]: getDiscounts ,
    [ActionTypes.RECEIVE_DISCOUNTS]: receiveDiscounts ,
    [ActionTypes.FAILED_TO_GET_DISCOUNTS]: failedToGetDiscounts ,
    [ActionTypes.LOG_OFF]: logOff ,
});