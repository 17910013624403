import React from 'react';
import InputComp from '../InputComp.js';
import MapWithAMarkerHOC from "../../containers/General/mapWithAMarkerHOC";
import MapSearchBoxHOC from "../../containers/General/mapSearchBoxHOC";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from "@material-ui/core/IconButton";
import Store from "@material-ui/icons/Store";
import Button from "@material-ui/core/Button";

const StoreFormComponent = (props) => {
    return (
        <div>
            <Dialog
                open={props.storesSettings.isDeleteModalOpen}
                onClose={() => props.changeStoreSettings('isDeleteModalOpen', !props.storesSettings.isDeleteModalOpen)}
            >
                <DialogTitle>{"Delete Store"}</DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        Are you sure you want to delete the selected store?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={props.handleDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                    <Button onClick={() =>
                        props.changeStoreSettings('isDeleteModalOpen', !props.storesSettings.isDeleteModalOpen)}
                            color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Card>
                <CardHeader
                    avatar={
                        <IconButton>
                            <Store />
                        </IconButton>
                    }
                    title={props.isNew?'New Store':'Edit Store'}
                />
                <CardContent>
                    <div className="col col-lg-4 offset-lg-3 ">
                        <form id="form1" onSubmit={props.handleStoreSubmit}>
                            <InputComp Type="text" Label="Name" Name="storeName" Required={true}
                                       fullWidth={true} margin={'normal'}
                                       Value={props.store.storeName} Placeholder="store name"
                                       PropHandleInputChange={props.handleStoreInputChange}/>
                            <InputComp Type="text" Label="Telephone" Required={true}
                                       fullWidth={true} margin={'normal'}
                                       Name="telephone" Value={props.store.telephone} Placeholder="10 digit telephone"
                                       PropHandleInputChange={props.handleStoreInputChange}/>
                        </form>
                        <MapSearchBoxHOC
                            fullWidth={true} margin={'normal'}
                            handleInputChange={props.handleStoreInputChange}
                            box={{
                                name: 'location',
                                value: props.store.location,
                                required: true,
                                placeholder: 'address',
                                label: 'Address'
                            }}
                            refs={props.refs}
                            onPlacesChanged={props.onPlacesChanged}
                        />
                    </div>
                    <div className="col" style={{marginTop: '10px'}}>
                        <MapWithAMarkerHOC
                            handleCoordinates={props.handleCoordinates}
                            markerPosition={props.store.position}
                            onDragEnd={props.handleDragEnd}
                        />
                    </div>
                </CardContent>
                <CardActions>
                    <Button
                        color="primary"
                        variant="contained"
                        form="form1"
                        type="submit">{props.isNew?'Create':'Edit'}</Button>
                    {
                        !props.isNew && (
                            <Button
                                onClick={() => props.changeStoreSettings('isDeleteModalOpen', true)}
                                color="secondary"
                                variant="contained"
                            >Delete</Button>
                        )
                    }
                </CardActions>
            </Card>
            <Snackbar
                open={props.storesSettings.sendResponse?true:false}
                message={props.storesSettings.sendResponse}
                autoHideDuration={6000}
                onClose={() => {props.changeStoreSettings('sendResponse', '')}}
            />
        </div>
    )
};

StoreFormComponent.propTypes = {

};

export default StoreFormComponent;
