import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import TellerOverview from "../../components/Tellers/tellerOverview";
import _ from 'lodash';
import {parseLogin, parseTellerOverview, parseTellers} from "../../helpers/parseImmutables";
import {changeTellersStoreSettings, getTelleById} from "../../actions/tellers";
import {getTableListPostitions, getTablePage} from "../../helpers/functions";
import {resetTellerOverviewState, setTellerOverviewActiveTab} from "../../actions/tellerOverview";
import {useHistory, useLocation} from "react-router-dom";

const TellerOverviewHOC = props => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        return () => {
            props.resetTellerOverviewState();
        }
    }, [])

    useEffect(() => {
        props.getTellerById(_.last(location.pathname.split('/')), props.token);
    }, [])

    const getTellerStoreTableRows = () => {
        let rows = [];
        let allStores = props.teller.stores;
        let position = getTableListPostitions(props.tellerStoresRowsPerPage, props.tellerStoresPage);
        allStores = _.slice(allStores, position.start, position.end);
        _.forEach(allStores, store => {
            rows.push({
                id: store.id,
                data: [
                    store.name,
                    store.location,
                    store.telephone
                ],
                label: store.name
            })
        });
        return rows;
    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.teller.stores.length / rowsPerPage);
        let currentPage = props.tellerStoresPage;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeStoreSettings('tellerStoresRowsPerPage', rowsPerPage);
        props.changeStoreSettings('tellerStoresPage', currentPage);
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.teller.tellerStoresPage,  props.teller.stores.length, props.tellerStoresRowsPerPage);
        props.changeStoreSettings('tellerStoresPage', newPage);
    };

    const handleRowSelect = (storeId) => {
        history.push('/store/edit/' + storeId)
    };

    let tellerStoreTableHeaders = [
        'store name',
        'location',
        'telephone'
    ];

    return(
        <TellerOverview
            setTellerOverviewActiveTab={props.setTellerOverviewActiveTab}
            activeTab={props.tellerOverview.activeTab}
            handleRowSelect={handleRowSelect}
            page={props.tellerStoresPage}
            totalRows={props.teller.stores.length}
            handlePageChange={handlePageChange}
            rowsPerPage={props.tellerStoresRowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            teller={props.teller}
            storesTableRows={getTellerStoreTableRows()}
            tableColumns={tellerStoreTableHeaders}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        teller: parseTellers(state.tellers).teller,
        tellerStoresPage: parseTellers(state.tellers).tellerStoresPage,
        tellerStoresRowsPerPage: parseTellers(state.tellers).tellerStoresRowsPerPage,
        token: parseLogin(state.login).userInfo.token,
        tellerOverview: parseTellerOverview(state.tellerOverview)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTellerById: (tellerId, token) => {
            dispatch(getTelleById(tellerId, token))
        },
        changeStoreSettings: (key, value) => {
            dispatch(changeTellersStoreSettings(key, value))
        },
        setTellerOverviewActiveTab: (payload) => {
            dispatch(setTellerOverviewActiveTab(payload))
        },
        resetTellerOverviewState: (payload) => {
            dispatch(resetTellerOverviewState(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TellerOverviewHOC);