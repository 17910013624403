import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import CategoryFormComponent from "../../components/Categories/CategoryFormComponent";
import {
    addingCategory, changeCategorySettings, editingCategory, editNewCategory, editStoredCategory, getCategoryById,
    showingEditCategoryForm, toDeleteCategory, toGetCategories
} from "../../actions/categories";
import {bulkUpdateProducts, gettingAllProducts} from "../../actions/products";
import {gettingAllOffers} from "../../actions/offers";
import {gettingAllStores} from "../../actions/stores";
import _ from 'lodash';
import SelectComp from "../../components/SelectComp";
import {parseCategories, parseLogin, parseOffers, parseProducts, parseStores} from "../../helpers/parseImmutables";
import Button from "@material-ui/core/Button";
import usePrevious from "../../hooks/usePrevious";
import {useLocation} from "react-router-dom";

const CategoryFormHOC = props => {
    const prevMovingProducts = usePrevious(props.categories.movingProducts);
    const prevMoveProductsFailedResponse = usePrevious(props.categories.moveProductsFailedResponse);
    const location = useLocation();

    useEffect(() => {
        if(
            (prevMovingProducts && !props.categories.movingProducts)
            && (!prevMoveProductsFailedResponse && !props.categories.moveProductsFailedResponse)){
            handleDelete();
        }
    }, [
        prevMovingProducts, props.categories.movingProducts,
        prevMoveProductsFailedResponse, props.categories.moveProductsFailedResponse
    ])

    useEffect(() => {
        // props.getStores(props.login.userInfo.brand.id);
        // if(!location.pathname.includes('add')){
        //     props.getCategoryById(_.last(location.pathname.split('/')), props.login.userInfo.token);
        // }
    }, [])

    const handleCategorySubmit = (evt) => {
        if (evt.key === "Enter" || typeof(evt.key) === "undefined") {
            evt.preventDefault();
            if (location.pathname.includes("add")) {
                props.addCategory(props.categories.newCategory, props.login.userInfo.brand.id,  props.login.userInfo.token)
            }
            else if (!location.pathname.includes("add")) {
                props.editCategory({...props.categories.changedCategory, brandId: props.login.userInfo.brand.id}, props.login.userInfo.token)
            }
        }
    };

    const HandleCategoryInputChange = (evt) => {
        if (location.pathname.includes("add")) {
            props.temp_new_cat(evt.target.name, evt.target.value)
        }
        else if (!location.pathname.includes("add")) {
            props.temp_stored_cat(evt.target.name, evt.target.value)
        }
    };

    const getCategoryArray = () => {
        let categoryArray = [];
        for (let index in props.categories.all) {
            if (props.categories.all[index].brid === props.login.userInfo.brid) {
                categoryArray.push({
                    key: props.categories.all[index].cid,
                    value: props.categories.all[index].catName
                });
            }
        }
        return categoryArray;
    };

    const showCat = (evt) => {
        props.showEditCategoryForm(props.categories.all[evt.target.value], evt.target.value);
        // props.fetchCat(props.categories.all[evt.target.value])
    };

    const handleDelete = () => {
        props.deleteCategory(props.categories.changedCategory.cid, props.login.userInfo.token);
    };

    const handleSelectedStores = (evt) => {
        let options = evt.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        if(value.includes('all'))
            value = ['all'];
        if (location.pathname.includes("add")) {
            props.temp_new_cat(evt.target.name, value)
        }
        else if (!location.pathname.includes("add")) {
            props.temp_stored_cat(evt.target.name, value)
        }
    } ;

    const isCategoryEmpty = (categoryId) => {
        let isEmpty = true;
        try {
            _.forEach(props.categories.all, category =>{
                if(category.id === categoryId){
                    if(category.products.length > 0){
                        isEmpty = false;
                        return false;
                    }
                    else {
                        isEmpty = true;
                        return false;
                    }
                }
            })
        }
        catch (e) {
            return isEmpty;
        }
        return isEmpty
    };

    const getDeleteModalData = () => {
        let data = {
            actions: [],
            message: '',
            selectItem: ''
        };
        if(props.categories.moveProducts){
            data.message = 'Select category';
            data.actions = [
                <Button
                    onClick={() => cancelDelete()}
                    color="primary"
                    variant="contained"
                >{'Cancel'}</Button>,
                <Button
                    onClick={moveProductsAndDelete}
                    color="primary"
                    variant="contained"
                >{'Delete'}</Button>
            ];
            data.selectItem = <SelectComp
                fullWidth={true}
                Required={false}
                Values={_.filter(props.categories.all.map(item => {return {key: item.id, value: item.name}}), i => {return i.key !== props.categories.showEditingProductCategory})}
                Label="Category"
                TargetObj={props.categories.moveProductsCategory}
                Name="moveProductsCategory"
                MenuName="Select Category"
                PropHandleInputChange={(name, value) => props.changeStoreSettings(name, value)}
            />
        }
        else {
            if(isCategoryEmpty(props.categories.changedCategory.cid)){
                data.actions = [
                    <Button
                        onClick={() => cancelDelete()}
                        color="primary"
                        variant="contained"
                    >{'Cancel'}</Button>,
                    <Button
                        onClick={handleDelete}
                        color="primary"
                        variant="contained"
                    >{'Delete'}</Button>
                ];
                data.message = 'Are you sure you want to delete the selected category?'
            }
            else {
                data.message = 'The selected category contains products. Do you wish to move them to another ' +
                    'category before deletion? Select YES to move to new category and delete or NO to ' +
                    'proceed with category deletion along with its products.'
                data.actions = [
                    <Button
                        onClick={() => cancelDelete()}
                        color="primary"
                        variant="contained"
                    >{'Cancel'}</Button>,
                    <Button
                        onClick={moveProducts}
                        color="primary"
                        variant="contained"
                    >{'Yes'}</Button>,
                    <Button
                        onClick={handleDelete}
                        color="primary"
                        variant="contained"
                    >{'No'}</Button>,
                ]
            }
        }
        return data;
    };

    const cancelDelete = () => {
        props.changeStoreSettings('isDeleteModalOpen', !props.categories.isDeleteModalOpen)
        props.changeStoreSettings('moveProducts', false)
        props.changeStoreSettings('moveProductsCategory', false)
    };

    const moveProducts = () => {
        props.changeStoreSettings('moveProducts', true)
    };

    const moveProductsAndDelete = () => {
        let products = [];
        let category = _.find(props.categories.all, c => {return c.id === props.categories.showEditingProductCategory})
        if(!category){
            return false;
        }
        _.forEach(category.products, p => {
           products.push(p.id);
        });
        props.bulkUpdateProducts({
            products: products,
            categoryId: props.categories.moveProductsCategory,
            brandId: props.login.userInfo.brand.id,
            token: props.login.userInfo.token
        });
    };

    return(
        <CategoryFormComponent
            cancelDelete={cancelDelete}
            deleteModalData={getDeleteModalData()}
            handleSelectedStores={handleSelectedStores}
            stores={props.stores.all}
            handleDelete={handleDelete}
            changeStoreSettings={props.changeStoreSettings}
            categories={props.categories}
            showCat={showCat}
            allCategories={getCategoryArray()}
            isNew={!location.pathname.includes('add')?false:true}
            category={!location.pathname.includes('add')?props.categories.changedCategory:props.categories.newCategory }
            handleSubmit={handleCategorySubmit}
            handleInputChange={HandleCategoryInputChange}
        />
    )
};

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
        router: state.router,
        stores: parseStores(state.stores),
        categories:  parseCategories(state.categories),
        products: parseProducts(state.products),
        offers: parseOffers(state.offers)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        temp_new_cat: (key, value) => {
            dispatch(editNewCategory(key, value))
        },
        temp_stored_cat: (key, value) => {
            dispatch(editStoredCategory(key, value))
        },
        addCategory: (category, categoryBrand, token) => {
            dispatch(addingCategory(category, categoryBrand, token))
        },
        editCategory: (cat, token) => {
            dispatch(editingCategory(cat,token))
        },
        showEditCategoryForm: (cat, id) => {
            dispatch(showingEditCategoryForm(cat, id))
        },
        getAllCategories: () => {
            dispatch(toGetCategories())
        },
        changeStoreSettings: (key, value) => {
            dispatch(changeCategorySettings(key, value))
        },
        deleteCategory: (catId, token) => {
            dispatch(toDeleteCategory(catId, token))
        },
        getProducts: () => {
            dispatch(gettingAllProducts());
        },
        getOffers: () => {
            dispatch(gettingAllOffers())
        },
        getStores: (brandId) => {
            dispatch(gettingAllStores(brandId))
        },
        getCategoryById: (categoryId, token) => {
            dispatch(getCategoryById(categoryId, token))
        },
        bulkUpdateProducts: (sendBody) => {
            dispatch(bulkUpdateProducts(sendBody))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFormHOC);
