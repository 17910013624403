import * as actions from '../actions/pointsMultiplierForm';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL,BASE_URL_DOTNET} from "../helpers/variables";
import sampleCombine from "xstream/extra/sampleCombine";
import flattenSequentially from "xstream/extra/flattenSequentially";
import flattenConcurrently from "xstream/extra/flattenConcurrently";

export function requestCreatePointsMultiplier(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.CREATE_POINTS_MULTIPLIER)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL + 'api/multipliers'+'?access_token=' + token,
            category: 'requestCreatePointsMultiplier',
            method: 'POST',
            send:action.payload,
            // headers:{Authorization: token},
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCreatePointsMultiplier')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.createPointsMultiplierSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}
export function createPointsMultiplierFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCreatePointsMultiplier')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.createPointsMultiplierFail(response));

    return {
        ACTION: httpResponse$
    }
}

export function deleteMultiplierById(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.DELETE_POINT_MULTIPLIER)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL + 'api/multipliers/' + action.payload + '?access_token=' + token,
            category: 'deleteMultiplierById',
            method: 'DELETE',
            withCredentials: true,
            //headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('deleteMultiplierById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .compose(flattenConcurrently)
        .filter(response => response.status === 200|| response.status === 204 )
        .map(response => actions.deletePointMultiplierByIdSuccess(response));
    

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function deleteMultiplierByIdFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('deleteMultiplierById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .compose(flattenConcurrently)
        .filter(response => response.status !== 200 && response.status !== 204)
        .map(response => actions.deletePointMultiplierByIdFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestEditMultiplier(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.EDIT_POINT_MULTIPLIER)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL + 'api/multipliers/'+ action.payload.id + '?access_token=' + token,
            category: 'requestEditMultiplier',
            method: 'PUT',
            send:action.payload.sendBody,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestEditMultiplier')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.editPointMultiplierByIdSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}
export function editMultiplierFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestEditMultiplier')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.editPointMultiplierByIdFailed(response));

    return {
        ACTION: httpResponse$
    }
}