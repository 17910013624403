import React from 'react';
import './styles/styles.css'
import {connect} from 'react-redux';
import {changeLoginForm} from './actions/login';
import {Route, Router, Switch} from 'react-router';
import LayoutHOC from './containers/LayoutHOC';
import {parseLogin} from "./helpers/parseImmutables";
import LoggedOutRouter from "./routers/loggedOutRouter";
import './styles/general.css';
import {BrowserRouter} from "react-router-dom";


const App = (props) => {

    let renderComp;
    if (props.isAuth === true) {
        renderComp = <div>
            <Switch>
                <Route path="/" component={LayoutHOC}/>
            </Switch>
        </div>
    } else if (props.isAuth === false) {
        renderComp = <div>
            <Switch>
                <Route path="/" component={LoggedOutRouter}/>
            </Switch>
        </div>
    }
    return (
        <BrowserRouter >
            {renderComp}
        </BrowserRouter>
    );
}


const mapStateToProps = (state) => {
    return {
        isAuth: parseLogin(state.login).isAuth,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeInfo: (key, value) => {
            dispatch(changeLoginForm(key, value))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
