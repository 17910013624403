import * as actions from '../actions/categories';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";

export function requestDeleteCategory(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_DELETE_CATEGORY)
        .map(action => ({
            url:BASE_URL + 'api/categories/' + action.payload.catId + '?access_token=' + action.payload.token,
            category: 'requestDeleteCategory',
            method: 'DELETE',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestDeleteCategory')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveDeleteCategory(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestDeleteCategoryFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestDeleteCategory')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorDeleteCategory(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestEditCategory(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_EDIT_CATEGORY)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.data.brandId + '/categories/' + action.payload.data.cid +
                '?access_token=' + action.payload.token,
            category: 'requestEditCategory',
            method: 'PUT',
            send: {
                name: action.payload.data.catName,
            },
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestEditCategory')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveEditCategory(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestEditCategoryFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestEditCategory')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorEditCategory(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestAddCategories(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_ADD_CATEGORY)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.data.brid + '/categories?access_token=' + action.payload.token,
            category: 'requestAddCategories',
            method: 'POST',
            send: {
                name: action.payload.data.catName,
            },
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestAddCategories')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveAddCategory(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestAddCategoriesFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestAddCategories')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorAddCategory(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestGetCategories(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_ALL_CATEGORIES)
        .map(action => ({
            url: BASE_URL + "api/brands/" + action.payload.brandId + '/categories?access_token=' +
                action.payload.token +
                '&filter[where][deleted]=false' +
                (action.payload.filter?('&filter[skip]=' + action.payload.filter.skip):'') +
                (action.payload.filter?('&filter[limit]=' + action.payload.filter.limit):''),
            category: 'requestGetCategories',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetCategories')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveAllCategories(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestGetCategoriesFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetCategories')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorGetCategories(response));

    return {
        ACTION: httpResponse$
    }
}

//cycle that requests the product by id
export function requestCountCategories(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.COUNT_CATEGORIES)
        .map(action => ({
            url: BASE_URL + 'api/brands/' + action.payload.brandId + '/categories/count' +
            '?access_token=' + action.payload.accessToken +
            '&where[deleted]=false',
            category: 'requestCountCategories',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCountCategories')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.countCategoriesSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestCountCategoriesFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCountCategories')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.countCategoriesFail(response));

    return {
        ACTION: httpResponse$
    }
}

//cycle that requests the product by id
export function requestGetCategoryById(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CATEGORY_BY_ID)
        .map(action => ({
            url: BASE_URL + 'api/categories/' + action.payload.categoryId + '?access_token=' + action.payload.token,
            category: 'requestGetCategoryById',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetCategoryById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveCategoryById(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the product by id
export function failedGetCategoryById(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetCategoryById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetCategoryById(response));

    return {
        ACTION: httpResponse$
    }
}