import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import TellerForm from "../components/tellerForm";
import {changeLoginForm, handleTellerForm} from "../actions/login";
import {isEmail} from "../helpers/functions";
import {gettingAllStores} from "../actions/stores";
import {createTeller} from "../actions/tellers";
import {parseLogin, parseStores} from "../helpers/parseImmutables";

const TellerFormHOC = props => {

    useEffect(() => {
        props.getStores(props.brandId, props.token);
    }, [])

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if(!isEmail(props.tellerForm.email)){
            props.changeLoginForm('tellerSendResponse','Email is not valid.');
            return false;
        }
        if(props.tellerForm.stores.length === 0){
            props.changeLoginForm('tellerSendResponse','Please select at least one store.');
            return false;
        }
        props.createTeller(props.tellerForm, props.token)
    };

    return (
        <TellerForm
            stores={props.stores}
            changeLoginForm={props.changeLoginForm}
            tellerSendResponse={props.tellerSendResponse}
            handleSubmit={handleSubmit}
            tellerForm={props.tellerForm}
            handleTellerForm={props.handleTellerForm}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        brandId: parseLogin(state.login).userInfo.brand.id,
        token: parseLogin(state.login).userInfo.token,
        stores: parseStores(state.stores).all,
        tellerSendResponse: parseLogin(state.login).tellerSendResponse,
        tellerForm: parseLogin(state.login).tellerForm
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        createTeller: (teller, token) => {
          dispatch(createTeller(teller, token))
        },
        getStores: (brandId, token) => {
            dispatch(gettingAllStores(brandId, token))
        },
        changeLoginForm: (key, value) => {
            dispatch(changeLoginForm(key, value))
        },
        handleTellerForm: (key, value) => {
            dispatch(handleTellerForm(key, value))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TellerFormHOC);

