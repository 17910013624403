import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CheckBoxComp = (props) => {
    return(
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.Checked}
                    onChange={(evt) => props.PropHandleInputChange(evt.target.name, evt.target.checked)}
                    disabled={props.Disabled}
                    name={props.Name}
                />
            }
            label={props.Label}
        />
    )
};

CheckBoxComp.propTypes={
    Checked: PropTypes.bool,
    Label: PropTypes.string,
    Name: PropTypes.string.isRequired,
    PropHandleInputChange: PropTypes.func.isRequired
};

export default CheckBoxComp;
