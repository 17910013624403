import _ from 'lodash';
import moment from 'moment';

//function that checks if input is email
export function isEmail(input){
   let reguralExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
   try {
       if(input.match(reguralExp)){
           return true;
       }
       else{
           return false;
       }
   }
   catch (e) {
       return false;
   }
}

//function that checks if input is phone
export function isPhone(input){
    let reguralExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/im;
    try {
        if(input.match(reguralExp)){
            return true;
        }
        else{
            return false;
        }
    }
    catch (e) {
        return false;
    }
}

//get table rows positions
export function getTableListPostitions(rowsPerPage, page) {
    let start = (page - 1) * rowsPerPage;
    let end = start + rowsPerPage;

    return {start, end}
}

//get new table page
export function getTablePage(iconType, currentPage, totalRows, rowsPerPage) {
    let page = currentPage;
    switch (iconType) {
        case 'firstPage':
            page = 1;
            break;
        case 'lastPage':
            page = _.ceil(totalRows/rowsPerPage);
            break;
        case 'leftPage':
            page = page  > 1 ? page - 1 : 1;
            break;
        case 'rightPage':
            page = page < _.ceil(totalRows/rowsPerPage) ? page + 1 : _.ceil(totalRows/rowsPerPage);
    }
    return page;
}

//calculates the skip filter
export const _getSkipFilter = (page, viewAmount) => (page - 1) * viewAmount;

//function that returns the filter JSON object for requesting the clients suggestions
export const _getClientsSuggestions = (filterText, limit) => {

    const res = {
        where: {
            or: [
                {personId:  {like: "%25" + filterText + "%25"} },
                {lastName:  {like: "%25" + filterText + "%25"} },
                {firstName:  {like: "%25" + filterText + "%25"} },
                {telephone:  {like: "%25" + filterText + "%25"} },
                {country:  {like: "%25" + filterText + "%25"} },
                {address:  {like: "%25" + filterText + "%25"} },
                {badges:  {like: "%25" + filterText + "%25"} },
                {flowers:  {like: "%25" + filterText + "%25"} },
                {leafs:  {like: "%25" + filterText + "%25"} },
                {points:  {like: "%25" + filterText + "%25"} }
            ]
        },
        limit: limit
    };

    return res;

};


export const _getCampaignsSuggestions = (filterText, limit) => {

    const res = {
        where: {
            or: [
                {Id:  {like: "%25" + filterText + "%25"} },
                {Title:  {like: "%25" + filterText + "%25"} }

            ]
        },
        limit: limit
    };

    return res;

};

export const scrollToElement = (element) => {
    element.scrollIntoView(true);
};

export const dateTypeMapper = (date, dateType) => {
    if(dateType === 'hour'){
        return moment(date).format('HH:mm')
    }
    if(dateType === 'day'){
        return moment(date).format('DD/MM')
    }
    if(dateType === 'week'){
        return moment(date).format('DD/MM')
    }
    if(dateType === 'month'){
        return moment(date).format('MMM')
    }
    return date;
};

export const actionCreator = (type) => {
    return (payload) => {
        return {
            type: type,
            payload: payload
        }
    }
};

export const isAdmin = (userRole) => {
    try {
        return userRole.name === 'admin';
    } catch (e) {
        return false
    }
};


export const GreekToGreeklish = (titleUtm)=>
  {

   let  str  = titleUtm.replace(/^\s+|\s$/g, '').toLowerCase();   
   var emojis = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;


    const from = [ "ου", "ΟΥ", "Ού", "ού", "αυ", "ΑΥ", "Αύ", "αύ", "ευ", "ΕΥ", "Εύ", "εύ", "α", "Α", "ά", "Ά", "β", "Β", "γ", "Γ", "δ", "Δ", "ε", "Ε", "έ", "Έ", "ζ", "Ζ", "η", "Η", "ή", "Ή", "θ", "Θ", "ι", "Ι", "ί", "Ί", "ϊ", "ΐ", "Ϊ", "κ", "Κ", "λ", "Λ", "μ", "Μ", "ν", "Ν", "ξ", "Ξ", "ο", "Ο", "ό", "Ό", "π", "Π", "ρ", "Ρ", "σ", "Σ", "ς", "τ", "Τ", "υ", "Υ", "ύ", "Ύ", "ϋ", "ΰ", "Ϋ", "φ", "Φ", "χ", "Χ", "ψ", "Ψ", "ω", "Ω", "ώ", "Ώ" ];
    const to   = [ "ou", "ou", "ou", "ou", "au", "au", "au", "au", "eu", "eu", "eu", "eu", "a", "a", "a", "a", "b", "b", "g", "g", "d", "d", "e", "e", "e", "e", "z", "z", "i", "i", "i", "i", "th", "th", "i", "i", "i", "i", "i", "i", "i", "k", "k", "l", "l", "m", "m", "n", "n", "ks", "ks", "o", "o", "o", "o", "p", "p", "r", "r", "s", "s", "s", "t", "t", "y", "y", "y", "y", "y", "y", "y", "f", "f", "x", "x", "ps", "ps", "o", "o", "o", "o" ];

    for ( var i = 0; i < from.length; i++ ) {

        while( str.indexOf( from[i]) !== -1 ){

            str = str.replace( from[i], to[i] );    

        }

    }
    return str.split(" ").join("_").replace(emojis, '');

  }

  export const arrayUnique=(array) => {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }

    return a;
  }


 export const normalizeTargetGroupText = (targetGroupMachineName) =>
  {
    if(targetGroupMachineName !=null)
    {
      if(targetGroupMachineName =='categoryBased')
      {
        return 'Category based'
      }
      else if(targetGroupMachineName =='productBased')
      {
        return 'Product based'
      }
      else if(targetGroupMachineName =='allProducts')
      {
        return 'All products'
      }
    }

  }

  