import {createStore, applyMiddleware} from 'redux';
import { createBrowserHistory } from "history";
import rootReducer  from '../reducers';
import {persistStore, persistReducer, createMigrate} from 'redux-persist'
import storage from 'redux-persist/es/storage' // default: localStorage if web, AsyncStorage if react-native
import { run } from '@cycle/run';
import { createCycleMiddleware } from 'redux-cycles';
import { makeHTTPDriver } from '@cycle/http';
import main from '../cycles/cycles';
import Immutable from "immutable";
import {createLogger} from "redux-logger";
import immutableTransform from 'redux-persist-transform-immutable';
import {migrations} from "./migrations";

export const history = createBrowserHistory({basename: "/s/loyalty"});
const config = {
    transforms: [immutableTransform({
        blacklist: ['brand', 'router']
    })],
    key: 'rootLoyalty',
    storage,
    version: 2,
    migrate: createMigrate(migrations)
};

const cycleMiddleware = createCycleMiddleware();
const { makeActionDriver, makeStateDriver } = cycleMiddleware;

let allMiddlewares;

let logger;

logger = createLogger({
    stateTransformer: (state) => {
        let newState = {};

        for (let i of Object.keys(state)) {
            if (Immutable.Iterable.isIterable(state[i])) {
                newState[i] = state[i].toJS();

            } else {
                newState[i] = state[i];
            }
        }
        return newState;
    }
});


if(process.env.NODE_ENV !== 'production') {

    allMiddlewares = applyMiddleware(logger, cycleMiddleware);

}else{
    allMiddlewares = applyMiddleware( cycleMiddleware);
}

const store = createStore(
    persistReducer(config, rootReducer),
    allMiddlewares,

);

export const persistor = persistStore(store);

run(main, {
    ACTION: makeActionDriver(),
    STATE: makeStateDriver(),
    HTTP: makeHTTPDriver()
});

export default store;