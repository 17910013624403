import React, {Component} from 'react';
import {connect} from 'react-redux';
import Indicator from "../../components/General/indicator";
import {
    parseCategories,
    parseClientForm,
    parseClientOverview,
    parseClients,
    parseEnabledOffers,
    parseGeneralReducer,
    parseLogin,
    parseOffers,
    parseOrderOverview,
    parseOrders,
    parsePointsForm,
    parsePointsOverview,
    parseProducts,
    parseStores,
    parseTellers,
} from "../../helpers/parseImmutables";

const IndicatorHOC = props => {

    let isVisible = false;
    if(
        props.isFetchingStores || props.isAddingStore || props.isPatchingStore
        || props.isFetchingProducts || props.isAddingProduct || props.isPatchingProduct
        || props.isFetchingOffers || props.isAddingOffer || props.isPatchingOffer
        || props.isFetchingCategories || props.isAddingCategory || props.isPatchingCategory
        || props.isFetchingClients || props.isFetchingClient || props.postingClientExtraPoints
        || props.fetchingClientEnabledOfferById
        || props.isAddingAdmin || props.isEditingAdmin
        || props.isFetchingOrders || props.isFetchingOrder
        || props.isDeletingOrder || props.isAddingOrder
        || props.isFetchingTellers || props.isFetchingTeller
        || props.isFetchingPointSystem || props.isAddingPointSystem || props.isPatchingPointSystem
        || props.postingClient
        || props.deletingClient
        || props.fetchingEnabledOffers
        || props.postingEditor || props.fetchingEditor || props.patchingEditor
        || props.fetchingOrdersStatistics
        || props.fetchingPointsStatistics ||props.fetchingEnabledOffersStatistics
        || props.fetchingClientStatistics ||props.fetchingXpOrdersStatistics
        || props.fetchingPointsCompareStatistics
        || props.fetchingClientCompareStatistics
        || props.fetchingOrdersCompareStatistics
        || props.fetchingEnabledOffersCompareStatistics
        || props.fetchingEditors
        || props.isFetchingCampaigns
    ){
        isVisible = true;
    }
    return (
        <Indicator
            isVisible={isVisible}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        isFetchingStores: parseStores(state.stores).isFetching,
        isAddingStore: parseStores(state.stores).adding,
        isPatchingStore: parseStores(state.stores).patching,

        isFetchingProducts: parseProducts(state.products).isFetching,
        isAddingProduct: parseProducts(state.products).adding,
        isPatchingProduct: parseProducts(state.products).patching,

        isFetchingOffers: parseOffers(state.offers).isFetching,
        isAddingOffer: parseOffers(state.offers).adding,
        isPatchingOffer: parseOffers(state.offers).patching,

        isFetchingCategories: parseCategories(state.categories).isFetching,
        isAddingCategory: parseCategories(state.categories).adding,
        isPatchingCategory: parseCategories(state.categories).patching,

        isFetchingClients: parseClients(state.clients).fetching,
        deletingClient: parseClients(state.clients).deleting,
        isFetchingClient: parseClientOverview(state.clientOverview).fetching,
        postingClientExtraPoints: parseClientOverview(state.clientOverview).postingClientExtraPoints,
        fetchingClientEnabledOfferById: parseClientOverview(state.clientOverview).fetchingClientEnabledOfferById,

        isAddingAdmin: parseLogin(state.login).adding,
        isEditingAdmin: parseLogin(state.login).isEditingPassword,

        isFetchingOrders: parseOrders(state.orders).fetching,
        isFetchingOrder: parseOrderOverview(state.orderOverview).fetching,
        isDeletingOrder: parseOrders(state.orders).deleting,
        isAddingOrder: parseOrders(state.orders).posting,

        isFetchingTellers: parseTellers(state.tellers).fetching,
        isFetchingTeller: parseTellers(state.tellers).fetchingTeller,

        isFetchingPointSystem: parsePointsOverview(state.pointsOverview).fetching,
        isPatchingPointSystem: parsePointsOverview(state.pointsOverview).patching,
        isAddingPointSystem: parsePointsForm(state.pointsForm).posting,

        generalReducer: parseGeneralReducer(state.generalReducer).drawer,

        postingClient: parseClientForm(state.clientForm).creatingClient,
        fetchingEnabledOffers: parseEnabledOffers(state.enabledOffers).fetching,

        // isFetchingCampaigns:parseCampaigns(state.campaignsNew).fetching,


        postingEditor: state.editor.get('posting'),
        patchingEditor: state.editor.get('patching'),
        fetchingEditor: state.editor.get('fetchingEditor'),

        fetchingOrdersStatistics: state.statistics.get('fetchingOrdersStatistics'),
        fetchingPointsStatistics: state.statistics.get('fetchingPointsStatistics'),
        fetchingEnabledOffersStatistics: state.statistics.get('fetchingEnabledOffersStatistics'),
        fetchingClientStatistics: state.statistics.get('fetchingClientStatistics'),
        fetchingXpOrdersStatistics: state.statistics.get('fetchingXpOrdersStatistics'),

        fetchingPointsCompareStatistics: state.statistics.get('fetchingPointsCompareStatistics'),
        fetchingClientCompareStatistics: state.statistics.get('fetchingClientCompareStatistics'),
        fetchingOrdersCompareStatistics: state.statistics.get('fetchingOrdersCompareStatistics'),
        fetchingEnabledOffersCompareStatistics: state.statistics.get('fetchingEnabledOffersCompareStatistics'),

        fetchingEditors: state.editors.get('fetching')
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorHOC);

