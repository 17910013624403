import React, { createElement, setState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import iOSpreviewTop from "../../assets/images/iOSpreviewTop.png";
import iOSpreviewRight from "../../assets/images/iOSpreviewRight.png";
import iOSpreviewLeft from "../../assets/images/iOSpreviewLeft.png";
import iOSextentedTop from "../../assets/images/iOSextentedTop.png";
import iOSextentedLeft from "../../assets/images/iOSextentedLeft.png";
import iOSextentedRight from "../../assets/images/iOSextentedRight.png";
import noImage from "../../assets/images/unnamed.png";

const styles = (theme) => ({
    root: {
      width: "90%",
    },
    button: {
      marginTop: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    actionsContainer: {
      marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
      padding: theme.spacing.unit * 3,
    },
    stepContent: {
      overflow: "visible",
    },
    cardWrapper: {
      overflow: "visible",
    },
    chip: {
      margin: theme.spacing.unit,
    },
  });
const IosNotification = (props) => {
    return (
    <div>
 <div //ios preview
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        maxWidth: "364px",
                        maxHeight: "300px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography variant="button" align='center'> ios </Typography>

                      {/* <div
                        style={{
                          flex: 1,
                          maxWidth: "364px",
                          height: "55px",
                        }}
                      > */}
                        <img src={iOSpreviewTop} ></img>
                        {/* style={{ marginTop: 0 }} */}
                      {/* </div> */}

                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          maxHeight: "70px",
                          marginLeft: -1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          backgroundColor: "#9e9e9e",
                        }}
                      >
                        <img src={iOSpreviewLeft} style={{width:23}}></img>
                        <div
                          style={{
                            flex: 1,
                            border: "2px solid rgba(0, 0, 0, 0.05)",
                            borderRadius: 10,
                            backgroundColor: "white",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Typography
                          variant='caption'
                            style={{
                              marginTop: 10,
                              marginLeft: 10,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: 220,
            color:'black'

                            }}
                          >
                            {props.notificationTitle}
                            <br></br>
                            {props.notificationText}{" "}
                          </Typography>
                          {props.notificationImage ? (
                            <img
                              style={{
                                marginTop: -35,
                                marginRight: 20,
                                maxWidth: 50,
                                maxHeight: 50,
                                borderRadius: 5,
                              }}
                              // src={
                              //   props.notificationImage
                              // }
            src={`data:image/png;base64,${props.notificationImage}`}

                              align="right"
                            />
                          ) : (
                            <img
                              style={{
                                marginTop: -40,
                                marginRight: 20,
                                maxHeight: 50,
                                maxWidth: 50,
                              }}
                              src={noImage}
                              align="right"
                            ></img>
                          )}
                        </div>

                        <img src={iOSextentedRight} style={{width:27}}></img>
                        {/* iOSpreviewRight */}
                      </div>
                    </div>




    <div
         //iOS extended preview
  style={{
    display: "flex",
    flexDirection: "column",
    flex: 2,
    maxWidth: "368px",
    maxHeight: "400px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }}
>
  {/* <div
    style={{
      flex: 2,
    }}
  > */}
    <img
      src={iOSextentedTop}
      style={{ marginTop: 8 }}
    ></img>
  {/* </div> */}
  <div
    style={{
      maxWidth: "368px",
      display: "flex",
      flex: 2,
      maxHeight: "150px",
    }}
  >
    <img src={iOSextentedLeft} style={{width:25}}></img>
    <div
      style={{
        flex: 2,
        backgroundColor: "#9e9e9e",
      }}
    >
      <div
        style={{
          maxWidth: "368px",
          flex: 2,
          border: "2px solid rgba(0, 0, 0, 0.05)",
          borderRadius: 10,
          backgroundColor: "white",
          align: "center",
          textAlign: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {props.notificationBigImage ? (
          <img
            style={{
              textAlign: "center",
              padding: 5,
              maxWidth: 100,
              maxHeight: 100,
              borderRadius: 10,
            }}
            // src={
            //   props.notificationImage
            // }
            src={`data:image/png;base64,${props.notificationBigImage}`}
          />
        ) : (
          <img
            style={{
              textAlign: "center",
              padding: 5,
              maxWidth: 100,
              maxHeight: 100,
              borderRadius: 5,
            }}
            src={noImage}
          ></img>
        )}
        <Typography
        variant='caption'
          style={{
            textAlign: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: 300,
            color:'black'
          }}
        >
          {props.notificationTitle}
          <br></br>
          {props.notificationText}{" "}
        </Typography>
      </div>{" "}
    </div>
    <img src={iOSextentedRight} style={{width:27}}></img>
  </div>
</div>
</div>)}
    export default withStyles(styles)(IosNotification);