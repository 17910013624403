export const migrations =  {
  0: (state) => {
      return {

      }
  },
  1: (state) => {
    return {

    }
  },
  2: (state) => {
    return {

    }
  },
  
};