import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function countTellers(payload) {
    return {
        type: ActionTypes.COUNT_TELLERS,
        payload: payload
    }
}

export function countTellersSuccess(payload) {
    return {
        type: ActionTypes.COUNT_TELLERS_SUCCESS,
        payload: payload
    }
}

export function countTellersFail(payload) {
    return {
        type: ActionTypes.COUNT_TELLERS_FAIL,
        payload: payload
    }
}

//action that requests get a teller user
export function getTelleById(tellerId, token) {
    return {
        type: ActionTypes.GET_TELLER_BY_ID,
        payload: {
            tellerId,
            token
        }
    }
}

//action that receives teller by id
export function receiveTellerById(teller) {
    return {
        type: ActionTypes.RECEIVE_TELLER_BY_ID,
        payload: teller
    }
}

//action that receives failed response get teller by id
export function failedToReceiveTellerById(response) {
    return {
        type: ActionTypes.FAILED_TO_RECEIVE_TELLER_BY_ID,
        payload: response
    }
}

//action that changes store settings
export function changeTellersStoreSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_TELLERS_STORE_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

//action that requests to create new teller user
export function createTeller(teller, token) {
    return {
        type: ActionTypes.CREATE_TELLER,
        payload: {
            teller,
            token
        }
    }
}

//action that receive create teller response
export function receiveCreateTellerResponse(response) {
    return {
        type: ActionTypes.RECEIVE_CREATE_TELLER_RESPONSE,
        payload: response
    }
}

//action that receives failed response while trying to create teller
export function failedToCreateTeller(response) {
    return {
        type: ActionTypes.FAILED_TO_CREATE_TELLER,
        payload: response
    }
}

//action that requests the teller users
export function getTellers(brandId, token, filter) {
    return {
        type: ActionTypes.GET_TELLERS,
        payload: {
            brandId,
            token,
            filter
        }
    }
}

//action that receives tellers
export function receiveTellers(response) {
    return {
        type: ActionTypes.RECEIVE_TELLERS,
        payload: response
    }
}

//action that receives failed response get tellers
export function failedToReceiveTellers(response) {
    return {
        type: ActionTypes.FAILED_TO_RECEIVE_TELLERS,
        payload: response
    }
}

export const resetTellersState = actionCreator(ActionTypes.RESET_TELLERS_STATE);