import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import PointsOverview from "../../components/Points/pointsOverview";
import {
    changeExtraCombinationsSettings, changePointOverviewStoreSettings, createPsCombinations,
    getCurrentPointSystem, resetPointsOverviewState, setPointsOverviewActiveTab, updatePointSystem
} from "../../actions/pointsOverview";
import {parseCategories, parseLogin, parsePointsOverview} from "../../helpers/parseImmutables";
import _ from 'lodash';
import {toGetCategories} from "../../actions/categories";
import InputComp from "../../components/InputComp";
import SelectComp from "../../components/SelectComp";
import Button from "@material-ui/core/Button";
import usePrevious from "../../hooks/usePrevious";

const PointsOverviewHOC = props => {
    const prevPosting = usePrevious(props.pointsOverview.posting);

    useEffect(() => {
        return () => {
            props.resetPointsOverviewState();
        }
    }, [])

    useEffect(() => {
        props.getCurrentPointSystem(props.brandId, props.token);
        props.getAllCategories(props.brandId, props.token);
    }, []);

    useEffect(() => {
        if(props.pointsOverview.posting && !prevPosting){

            props.getCurrentPointSystem(props.brandId, props.token);
        }
    }, [props.pointsOverview.posting, prevPosting])

    const _getLevelTableRows = () => {
        let rows = [];
        _.forEach(props.pointsOverview.pointSystem.levels, level => {
            rows.push({
                id: level.id,
                data: [
                    level.name,
                    level.pointsRequired,
                    level.pointsEarnedPerEuro,
                    level.maxInactiveDate
                ],
                label: level.name
            })
        });
        return rows;
    };

    const _getCombinationsTableRows = () => {
        let rows = [];
        _.forEach(props.pointsOverview.pointSystem.combinationsOfProducts, cb => {
            rows.push({
                id: cb.id,
                data: [
                    cb.name,
                    cb.products.reduce((currentV, nextV) => {
                        if(!currentV)
                            return nextV.name;
                        else return currentV + ', ' + nextV.name
                    }, ''),
                    cb.points,
                    ''
                ],
                label: cb.name
            })
        });
        _.forEach(props.pointsOverview.extraCombinations, (extra, index) => {
            rows.push({
                id: index,
                data: [
                    <InputComp Required={true} Placeholder="Level name" Type="text" Name="name"
                               fullWidth={false}
                               Label="" Value={extra.name}
                               PropHandleInputChange={(evt) => props.changeExtraCombinationsSettings(index, evt.target.name, evt.target.value)}/>,
                    <div className={"d-flex justify-content-between"}>
                        <SelectComp Required={true}
                                    fullWidth={true}
                                    MenuName="Select categories"
                                    TargetObj={props.pointsOverview.extraCombinations[index].selectedCategories}
                                    Name="selectedCategories"
                                    multiple={true}
                                    Label="Categories"
                                    Values={props.categories.map(item => {return {key:item.id, value: item.name}})}
                                    PropHandleInputChange={(name, value) => _handleSelectCategories(name, value, index)}/>
                        <SelectComp Required={true}
                                    fullWidth={true}
                                    MenuName="Select categories"
                                    TargetObj={props.pointsOverview.extraCombinations[index].selectedProducts} Name="selectedProducts"
                                    multiple={true}
                                    Label="Products"
                                    Values={_getProducts(index).map(item => {return {key:item.id, value: item.name}})}
                                    PropHandleInputChange={(name, value) => _handleSelectProducts(name, value, index)}/>
                    </div>,
                    <InputComp Required={true} Placeholder="Points" Type="number" Name="points"
                               fullWidth={false}
                               Label="" Value={extra.points}
                               PropHandleInputChange={(evt) => props.changeExtraCombinationsSettings(index, evt.target.name, evt.target.value)}/>,
                    <Button
                        color="secondary"
                        variant="flat"
                        onClick={() => _handleCombinationRemove(index)}
                       >
                        Remove
                    </Button>
                ]
            })
        });
        return rows;
    };

    const _handleSelectCategories = (name, value, index) => {
        let newCheckedProducts = [];
        let products = [];
        _.forEach(props.categories, category => {
            if(_.includes(value, category.id)){
                products = [...products, ...category.products]
            }
        });
        _.forEach(products, product => {
            if(_.includes(props.pointsOverview.extraCombinations[index].selectedProducts, product.id)){
                newCheckedProducts.push(product.id)
            }
        });
        props.changeExtraCombinationsSettings(index, name, value);
        props.changeExtraCombinationsSettings(index, 'selectedProducts', newCheckedProducts);
    };

    const _handleSelectProducts = (name, value, index) => {
        props.changeExtraCombinationsSettings(index, name, value);
    };

    const _getProducts = (index) => {
        let products = [];
        _.forEach(props.categories, category => {
            if(_.includes(props.pointsOverview.extraCombinations[index].selectedCategories, category.id)){
                products = [...products, ...category.products]
            }
        });
        return products;
    };

    const _addCombination = () => {
        let extraComb = {
            name: '',
            products: [],
            points: 0,
            selectedCategories: [],
            selectedProducts: []
        };
        props.changeStoreSettings('extraCombinations', [...props.pointsOverview.extraCombinations, extraComb])
    };

    const _handleCombinationRemove = (index) => {
        let combinationsOfProducts = props.pointsOverview.extraCombinations;
        _.pullAt(combinationsOfProducts, index);
        props.changeStoreSettings('extraCombinations',combinationsOfProducts);
    };

    const _handleExtraCombinationsAddition = () => {
        let emptyRowsExist = false;
        _.forEach(props.pointsOverview.extraCombinations, extra => {
            if(!extra.name || extra.selectedProducts.length === 0 || !extra.points){
                emptyRowsExist = true;
                return false;
            }
        });
        if(emptyRowsExist){
            props.changeStoreSettings('responseMessage', 'Please, fill the remaining empty fields')
            return 0;
        }
        props.createPsCombinations(_getPointSystemExtraCombinationsDto(), props.brandId, props.token);
    };

    const _getPointSystemExtraCombinationsDto = () => {
        return props.pointsOverview.extraCombinations.map(i => {
            return {
                name: i.name,
                points: i.points,
                products: i.selectedProducts
            }
        })
    };

    let levelTableHeaders = [
        'Name',
        'Required points',
        'Points earned per euro',
        'Max inactive period in weeks',
    ];
    let combinationOfProductsTableHeaders = [
        'Name',
        'Products',
        'Points',
        ''
    ];
    return (
        <PointsOverview
            activeTab={props.pointsOverview.activeTab}
            setPointsOverviewActiveTab={props.setPointsOverviewActiveTab}
            changeStoreSettings={props.changeStoreSettings}
            responseMessage={props.pointsOverview.responseMessage}
            _handleExtraCombinationsAddition={_handleExtraCombinationsAddition}
            extraCombinations={props.pointsOverview.extraCombinations}
            _addCombination={_addCombination}
            combinationsTableRows={_getCombinationsTableRows()}
            combinationOfProductsTableHeaders={combinationOfProductsTableHeaders}
            pointSystem={props.pointsOverview.pointSystem}
            levelTableRows={_getLevelTableRows()}
            levelTableHeaders={levelTableHeaders}
            push={props.push}
            brandId={props.brandId}
            updatePointSystem={props.updatePointSystem}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        categories: parseCategories(state.categories).all,
        brandId: parseLogin(state.login).userInfo.brand.id,
        token: parseLogin(state.login).userInfo.token,
        pointsOverview: parsePointsOverview(state.pointsOverview),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentPointSystem: (brandId, token) => {
            dispatch(getCurrentPointSystem(brandId, token))
        },
        changeStoreSettings: (key, value) => {
            dispatch(changePointOverviewStoreSettings(key, value))
        },
        changeExtraCombinationsSettings: (index, key, value) => {
            dispatch(changeExtraCombinationsSettings(index, key, value))
        },
        getAllCategories: (brandId, token) => {
            dispatch(toGetCategories(brandId, token))
        },
        createPsCombinations: (combinations, brandId, token) => {
            dispatch(createPsCombinations(combinations, brandId, token))
        },
        setPointsOverviewActiveTab: (payload) => {
            dispatch(setPointsOverviewActiveTab(payload))
        },
        updatePointSystem: (payload) => {
            dispatch(updatePointSystem(payload))
        },
        resetPointsOverviewState: payload => {
            dispatch(resetPointsOverviewState(payload))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsOverviewHOC);

