import React from 'react';
import {Route, Switch} from 'react-router';
import LoginContainer from "../containers/LoginContainer";
import ResetPasswordHOC from '../containers/resetPasswordHOC';
import ForgotPasswordHOC from '../containers/Auth/forgotPasswordHOC';

const LoggedOutRouter = (props) => {
  return (
      <Switch>
          {/*<Route exact path="/forgot-password" component={ForgotPasswordHOC}/>*/}
          {/*<Route exact path="/reset-password/:id" component={ResetPasswordHOC}/>*/}
          <Route path="/" component={LoginContainer}/>
      </Switch>
  )
};

export default LoggedOutRouter;