import * as ActionTypes from '../actions/index';
import _ from  'lodash';
import moment from 'moment';
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';

const defaultChangedOffer = {
    redeemPoints: false,
    titleEl: "",
    titleEn: "",
    descriptionEl: "",
    descriptionEn: "",
    discountType: "",
    minOrder: "",
    offer_name: "",
    start_date: "",
    end_date: "",
    start_date_readable: "",
    end_date_readable: "",
    discount_type: "",
    discount: "",
    promoted: false,
    points_required: "",
    all_products: false,
    allCategories: false,
    category: "",
    recurring: "",
    products: [],
    categories:[],
    oid: "",
    selectedStores: []
};

const defaultState = fromJS({
    isFetching: false,
    all: [],
    adding: false,
    patching: false,
    changedOffer: defaultChangedOffer,
    newoffer: defaultChangedOffer,
    selectedOffer: "",
    toChange: false,
    updateOffers: false,
    deleting: false,
    isDeleteModalOpen: false,
    fetchingOffer: false,
    sendResponse: '',
    rowsPerPage: 10,
    page: 1,
    totalOffers: '',
    fetchingCountOffers: false
});

function countOffers(state, action) {
    return state
        .set('fetchingCountOffers', true);
}

function countOffersSuccess(state, action) {
    return state
        .set('fetchingCountOffers', false)
        .set('totalOffers', action.payload.body.count);
}

function countOffersFail(state, action) {
    return state
        .set('fetchingCountOffers', false);
}

function getOfferById(state, action) {
    return state
        .set('fetchingOffer', true);
}

function receiveOfferById(state, action) {
    try {
        action.payload = JSON.parse(action.payload.text);
    }
    catch (e) {
        action.payload = null;
        return {
            ...state
        }
    }
    let products = [];
    let categories = [];
    let catIds  = [];
    _.forEach(action.payload.products, p => {
        products.push(p.id);
        _.forEach(p.categories, i => {
            if(!catIds.includes(i.id)){
                categories.push(i.id);
                catIds.push(i.id);
            }
        })
    });
    return state
        .setIn(['changedOffer', 'titleEl'], action.payload.titleEl)
        .setIn(['changedOffer', 'titleEn'], action.payload.titleEn)
        .setIn(['changedOffer', 'descriptionEl'], action.payload.descriptionEl)
        .setIn(['changedOffer', 'descriptionEn'], action.payload.descriptionEn)
        .setIn(['changedOffer', 'oid'], action.payload.id)
        .setIn(['changedOffer', 'redeemPoints'], action.payload.redeemPoints)
        .setIn(['changedOffer', 'discountType'], "")
        .setIn(['changedOffer', 'minOrder'], action.payload.minOrder)
        .setIn(['changedOffer', 'recurring'], action.payload.recurring)
        .setIn(['changedOffer', 'offer_name'], action.payload.name)
        .setIn(['changedOffer', 'start_date'], moment(action.payload.startDate).format('YYYY-MM-DD'))
        .setIn(['changedOffer', 'end_date'], moment(action.payload.endDate).format('YYYY-MM-DD'))
        .setIn(['changedOffer', 'start_date_readable'], moment(action.payload.startDate).format('YYYY-MM-DD'))
        .setIn(['changedOffer', 'end_date_readable'], moment(action.payload.endDate).format('YYYY-MM-DD'))
        .setIn(['changedOffer', 'discount_type'], action.payload.discountId?action.payload.discountId.toString():'')
        .setIn(['changedOffer', 'discount'], action.payload.discount)
        .setIn(['changedOffer', 'promoted'], action.payload.promoted)
        .setIn(['changedOffer', 'points_required'], action.payload.pointsRequired)
        .setIn(['changedOffer', 'all_products'], false)
        .setIn(['changedOffer', 'allCategories'], false)
        .setIn(['changedOffer', 'categories'], fromJS(categories))
        .setIn(['changedOffer', 'products'], fromJS(products))
        .setIn(['changedOffer', 'selectedStores'], fromJS([]))
        .set('fetchingOffer', false);
}

function failedToGetOfferById(state, action) {
    return state
        .set('fetchingOffer', false)
        .set('sendResponse', 'Failed to get offer.');
}

function changeOfferSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function requestDeleteOffer(state, action) {
    return state
        .set('deleting', true);
}

function receiveDeleteOffer(state, action) {
    return state
        .set('selectedOffer', '')
        .set('isDeleteModalOpen', false)
        .set('deleting', false)
        .set('changedOffer', fromJS(defaultChangedOffer))
        .set('newoffer', fromJS(defaultChangedOffer));
}

function receiveErrorDeleteOffer(state, action) {
    return state
        .set('deleting', false)
        .set('sendResponse', 'Failed to delete offer');
}

function resetDefault(state, action) {
    return defaultState;
}

function changeStoredOffer(state, action) {
    return state
        .setIn(['changedOffer', action.payload.key], fromJS(action.payload.value));
}

function changeNewOffer(state, action) {
    return state
        .setIn(['newoffer', action.payload.key], fromJS(action.payload.value));
}

function submitNewOffer(state, action) {
    return state
        .set('adding', true);
}

function submitChangedOffer(state, action) {
    return state
        .set('patching', true);
}

function toChangeOffer(state, action) {
    return state
        .set('toChange', true)
        .set('selectedOffer', action.payload.selectedOffer)
        .set('updateOffers', true);
}

function requestAllOffers(state, action) {
    return state
        .set('isFetching', true);
}

function receiveAllOffers(state, action) {
    return state
        .set('isFetching', false)
        .set('all', fromJS(action.payload.body));
}

function receiveErrorGetOffers(state, action) {
    return state
        .set('isFetching', false);
}

function receiveAddOffer(state, action) {
    return state
        .set('adding', false)
        .set('newoffer', fromJS(defaultChangedOffer))
        .set('sendResponse', 'Offer was created successfully.');
}

function receiveErrorAddOffer(state, action) {
    return state
        .set('adding', false)
        .set('sendResponse', 'Failed to create new offer.');
}

function receiveEditOffer(state, action) {
    return state
        .set('patching', false)
        .set('toChange', state.get('toChange'))
        .set('changedOffer', fromJS(defaultChangedOffer))
        .set('selectedOffer', '')
        .set('updateOffers', false);
}

function receiveErrorEditOffer(state, action) {
    return state
        .set('patching', false)
        .set('sendResponse', 'Failed to update offer.');
}

export const offers = createReducer(defaultState, {
    [ActionTypes.COUNT_OFFERS]: countOffers ,
    [ActionTypes.COUNT_OFFERS_SUCCESS]: countOffersSuccess ,
    [ActionTypes.COUNT_OFFERS_FAIL]: countOffersFail ,
    [ActionTypes.GET_OFFER_BY_ID]: getOfferById ,
    [ActionTypes.RECEIVE_OFFER_BY_ID]: receiveOfferById ,
    [ActionTypes.FAILED_TO_GET_OFFER_BY_ID]: failedToGetOfferById ,
    [ActionTypes.CHANGE_OFFER_SETTINGS]: changeOfferSettings ,
    [ActionTypes.REQUEST_DELETE_OFFER]: requestDeleteOffer ,
    [ActionTypes.RECEIVE_DELETE_OFFER]: receiveDeleteOffer ,
    [ActionTypes.RECEIVE_ERROR_DELETE_OFFER]:  receiveErrorDeleteOffer,
    [ActionTypes.RESET_DEFAULT_OFFER]: resetDefault ,
    [ActionTypes.TEMP_CHANGED_OFFER]: changeStoredOffer ,
    [ActionTypes.TEMP_NEW_OFFER]: changeNewOffer ,
    [ActionTypes.SUBMIT_NEW_OFFER]: submitNewOffer ,
    [ActionTypes.SUBMIT_CHANGED_OFFER]: submitChangedOffer ,
    [ActionTypes.TO_CHANGE_OFFER]: toChangeOffer ,
    [ActionTypes.REQUEST_ALL_OFFERS]: requestAllOffers ,
    [ActionTypes.RECEIVE_ALL_OFFERS]: receiveAllOffers ,
    [ActionTypes.RECEIVE_ERROR_GET_OFFERS]:  receiveErrorGetOffers,
    [ActionTypes.RECEIVE_ADD_OFFER]: receiveAddOffer ,
    [ActionTypes.RECEIVE_ERROR_ADD_OFFER]: receiveErrorAddOffer ,
    [ActionTypes.RECEIVE_EDIT_OFFER]: receiveEditOffer ,
    [ActionTypes.RECEIVE_ERROR_EDIT_OFFER]: receiveErrorEditOffer ,
    [ActionTypes.RESET_OFFERS_STATE]: resetDefault
});
