import React, {useEffect} from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import RenderTextField from "../input/renderTextField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import RenderSelectField from "../input/renderSelectField";
import moment from "moment";
import usePrevious from "../../hooks/usePrevious";

const getDateRange = (dateRange) =>{
    let dateFrom, dateTo = null;
    if(dateRange === 'today'){
        dateFrom = moment().format('YYYY-MM-DD');
        dateTo = moment().format('YYYY-MM-DD');
    }
    if(dateRange === 'yesterday'){
        dateFrom = moment().subtract(1, 'day').format('YYYY-MM-DD');
        dateTo = moment().subtract(1, 'day').format('YYYY-MM-DD');
    }
    if(dateRange === 'lastWeek'){
        dateFrom = moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD');
        dateTo = moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD');
    }
    if(dateRange === 'lastMonth'){
        dateFrom = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        dateTo = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    }
    if(dateRange === 'lastSevenDays'){
        dateFrom = moment().subtract(7, 'day').format('YYYY-MM-DD');
        dateTo = moment().subtract(1,'day').format('YYYY-MM-DD');
    }
    if(dateRange === 'lastThirtyDays'){
        dateFrom = moment().subtract(30, 'day').format('YYYY-MM-DD');
        dateTo = moment().subtract(1,'day').format('YYYY-MM-DD');
    }
    return {
        dateFrom: dateFrom,
        dateTo: dateTo
    }
};

const DateFilterForm = props => {
    const prevFiltersForm = usePrevious(props.filtersForm);

    useEffect(() => {
        if(
            props.filtersForm
            && props.filtersForm.dateRange
            && prevFiltersForm
            && prevFiltersForm.dateRange
            && (props.filtersForm.dateRange !== prevFiltersForm.dateRange)
        ) {
            const dateRange = getDateRange(props.filtersForm.dateRange);
            props.change('dateFrom', dateRange.dateFrom);
            props.change('dateTo', dateRange.dateTo);
        }
    }, [
        props.filtersForm, prevFiltersForm
    ])

    const {handleFilter, handleSubmit} = props;
    return (
        <form onSubmit={handleSubmit(handleFilter)}>
            <div className={'row'}>
                <div className={'form-group col-2'}>
                    <Field name={'dateRange'} label={'Date Range'} component={RenderSelectField} fullWidth>
                        <MenuItem value={''} >{'Custom'}</MenuItem>
                        <MenuItem value={'today'}>{'Today'}</MenuItem>
                        <MenuItem value={'yesterday'}>{'Yesterday'}</MenuItem>
                        <MenuItem value={'lastWeek'}>{'Last week'}</MenuItem>
                        <MenuItem value={'lastMonth'}>{'Last month'}</MenuItem>
                        <MenuItem value={'lastSevenDays'}>{'Last 7 days'}</MenuItem>
                        <MenuItem value={'lastThirtyDays'}>{'Last 30 days'}</MenuItem>
                    </Field>
                </div>
                <div className={'form-group col-2'}>
                    <Field name="dateFrom" shrink={true} label={'Date from'} component={RenderTextField}
                           type="date" fullWidth required
                           disabled={props.filtersForm && props.filtersForm.dateRange}
                    />
                </div>
                <div className={'form-group col-2'}>
                    <Field name="dateTo" shrink={true} label={'Date to'} component={RenderTextField} type="date"
                           fullWidth required
                           disabled={props.filtersForm && props.filtersForm.dateRange}
                    />
                </div>
                <div className={'form-group col-2'} >
                    <Field name="type" label={'Type'} component={RenderSelectField} fullWidth required>
                        <MenuItem value={'hour'} >{'Per hour'}</MenuItem>
                        <MenuItem value={'day'} >{'Per day'}</MenuItem>
                        <MenuItem value={'week'} >{'Per week'}</MenuItem>
                        <MenuItem value={'month'} >{'Per month'}</MenuItem>
                    </Field>
                </div>
                <div className={'form-group col-2'}>
                    <Field name="compareWith" label={'Compare with'} component={RenderSelectField} fullWidth>
                        <MenuItem value={''} >{''}</MenuItem>
                        <MenuItem value={'previousPeriod'} >{'Previous Period'}</MenuItem>
                        <MenuItem value={'previousYear'} >{'Previous Year'}</MenuItem>
                    </Field>
                </div>
                <div className={'form-group col-2'} >
                    <Button type={'submit'} variant="contained" color="primary" className={'mr-3'}>
                        {'Filter'}
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    enableReinitialize: false,
    destroyOnUnmount: false,
    form: 'dateFilterForm',
})(DateFilterForm);
