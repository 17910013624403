import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function countProducts(payload) {
    return {
        type: ActionTypes.COUNT_PRODUCTS,
        payload: payload
    }
}

export function countProductsSuccess(payload) {
    return {
        type: ActionTypes.COUNT_PRODUCTS_SUCCESS,
        payload: payload
    }
}

export function countProductsFail(payload) {
    return {
        type: ActionTypes.COUNT_PRODUCTS_FAIL,
        payload: payload
    }
}

//action that requests change category of products that belong to the same category
export function bulkUpdateProducts(sendBody) {
    return {
        type: ActionTypes.BULK_UPDATE_PRODUCTS,
        payload: sendBody
    }
}

//action that receives update response from bulk update products
export function receiveBulkUpdateProducts(response) {
    return {
        type: ActionTypes.RECEIVE_BULK_UPDATE_PRODUCTS,
        payload: response
    }
}

//action that receives failed request bulk update
export function failedToBulkUpdateProducts(response) {
    return {
        type: ActionTypes.FAILED_TO_BULK_UPDATE_PRODUCTS,
        payload: response
    }
}


//action that request get product by id
export function getProductBydId(productId, token) {
    return {
        type: ActionTypes.GET_PRODUCT_BY_ID,
        payload: {
            productId,
            token
        }
    }
}

//action that receives product by id
export function receiveProductById(product) {
    return {
        type: ActionTypes.RECEIVE_PRODUCT_BY_ID,
        payload: product
    }
}

//action that failed to receive product by id
export function failedToReceiveProductById(response) {
    return {
        type: ActionTypes.FAILED_TO_RECEIVE_PRODUCT_BY_ID,
        payload: response
    }
}

export function changeProductFormSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_PRODUCT_FORM_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

export function resetDefaultProductCat() {
    return {
        type: ActionTypes.RESET_DEFAULT_PRODUCT_AND_CATEGORY
    }
}

export function editNewProduct(productId, productVal) {
    return {
        type: ActionTypes.EDIT_NEW_PRODUCT,
        payload: {key: productId, value: productVal}
    }
}

export function editChangedProduct(productId, productVal) {
    return {
        type: ActionTypes.EDIT_STORED_PRODUCT,
        payload: {key: productId, value: productVal}
    }
}

export function receiveAllProducts(products) {
    return {
        type: ActionTypes.RECEIVE_ALL_PRODUCTS,
        payload: products
    }
}

export function gettingAllProducts(brandId, token, filter) {
    return {
        type: ActionTypes.REQUEST_ALL_PRODUCTS,
        payload: {brandId, token, filter}
    };
}

//action that receives error getting the products
export function receiveErrorGetProducts(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_GET_PRODUCTS,
        payload: response
    }
}

export function addingProduct(data, productBrand) {
    return {
        type: ActionTypes.REQUEST_ADD_PRODUCT,
        payload: {...data, brid: productBrand}
    };
}

//action that receives error while adding new product
export function receiveErrorAddProduct(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_ADD_PRODUCT,
        payload: response
    }
}

export function receiveAddProduct(response) {
    return {
        type: ActionTypes.RECEIVE_ADD_PRODUCT,
        payload: response
    }
}

export function editingProduct(data, token) {
    return {
        type: ActionTypes.REQUEST_EDIT_PRODUCT,
        payload: {data, token}
    }
}

//action that receives error while editing a product
export function receiveErrorEditProduct(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_EDIT_PRODUCT,
        payload: response
    }
}


export function receiveEditProduct(response) {
    return {
        type: ActionTypes.RECEIVE_EDIT_PRODUCT,
        payload: response
    }
}

export function deletingProduct(pid, token) {
    return {
        type: ActionTypes.REQUEST_DELETE_PRODUCT,
        payload: {pid, token}
    };
}

//action that receives error while deleting selected product
export function receiveErrorDeleteProduct(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_DELETE_PRODUCT,
        payload: response
    }
}

export function receiveDeleteProduct(productId) {
    return {
        type: ActionTypes.RECEIVE_DELETE_PRODUCT,
        payload: productId
    }
}

export function showingEditProductForm(product) {
    return {
        type: ActionTypes.SHOW_EDIT_PRODUCT,
        payload: product

    }
}

export const resetProductsState = actionCreator(ActionTypes.RESET_PRODUCTS_STATE);
