import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import TellerList from "../../components/Tellers/tellerList";
import {changeTellersStoreSettings, countTellers, getTellers, resetTellersState} from "../../actions/tellers";
import {_getSkipFilter, getTablePage} from "../../helpers/functions";
import {parseLogin, parseTellers} from "../../helpers/parseImmutables";
import {useHistory} from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";

const TellerListHOC = props => {
    const history = useHistory();
    const prevPage = usePrevious(props.tellers.page);
    const prevRowsPerPage = usePrevious(props.tellers.rowsPerPage);

    useEffect(() => {
        return () => {
            props.resetTellersState();
        }
    }, [])

    useEffect(() => {
        props.countTellers({
            brandId: props.brandId,
            token: props.token
        });
    }, []);

    useEffect(() => {
        if(
            props.tellers.page !== prevPage
            || props.tellers.rowsPerPage !== prevRowsPerPage
        ) {
            props.getTellers(props.brandId, props.token,
                {
                    skip: _getSkipFilter(props.tellers.page, props.tellers.rowsPerPage),
                    limit: props.tellers.rowsPerPage
                });
        }
    }, [
        prevPage,
        prevRowsPerPage,
        props.tellers.page,
        props.tellers.rowsPerPage,
        props.brandId,
        props.token
    ]);

    const getListRows = () => {
        let arr = [];
        let allTellers = [...props.tellers.tellers];
        _.forEach(allTellers, c => {
            let storeString = '';
            _.forEach(c.stores, (store, index) => {
               if(index === 0){
                   storeString = store.name;
               }
               else {
                   storeString += ', ' + store.name;
               }
            });
            arr.push(
                {
                    id: c.id,
                    data: [
                        c.username,
                        c.email,
                        storeString
                    ],
                    label: `${c.email}`
                }
            )
        });
        return arr;
    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.tellers.totalTellers / rowsPerPage);
        let currentPage = props.tellers.page;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeStoreSettings('rowsPerPage', rowsPerPage);
        props.changeStoreSettings('page', currentPage);
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.tellers.page,  props.tellers.totalTellers, props.tellers.rowsPerPage);
        props.changeStoreSettings('page', newPage);
    };

    const handleRowSelect = (tellerId) => {
        history.push('/tellers/' + tellerId)
    };

    let tableColumns = [
        'Username',
        'Email',
        'Stores',
    ];
    return (
        <TellerList
            handleRowSelect={handleRowSelect}
            page={props.tellers.page}
            totalRows={props.tellers.totalTellers}
            handlePageChange={handlePageChange}
            rowsPerPage={props.tellers.rowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            rows={getListRows()}
            tableColumns={tableColumns}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        tellers: parseTellers(state.tellers),
        brandId: parseLogin(state.login).userInfo.brand.id,
        token: parseLogin(state.login).userInfo.token
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTellers: (brandId, token, filter) => {
            dispatch(getTellers(brandId, token, filter))
        },
        changeStoreSettings: (key, value) => {
            dispatch(changeTellersStoreSettings(key, value))
        },
        countTellers: (payload) => {
            dispatch(countTellers(payload))
        },
        resetTellersState: (payload) => {
            dispatch(resetTellersState(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TellerListHOC);

