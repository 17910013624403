import * as actions from '../actions/people';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";
import sampleCombine from 'xstream/extra/sampleCombine';

export function requestPeopleSuggestions(sources) {

    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_PEOPLE_SUGGESTIONS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/people?filter=' + JSON.stringify(action.payload.filter),
            category: 'requestPeopleSuggestions',
            method: 'GET',
            headers: {Authorization: token},
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestPeopleSuggestions')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getPeopleSuggestionsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function requestPeopleSuggestionsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestPeopleSuggestions')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getPeopleSuggestionsFailed(response));

    return {
        ACTION: httpResponse$
    }
}