import * as ActionTypes from './index';

export function changePeopleState(payload) {
    return {
        type: ActionTypes.CHANGE_PEOPLE_STATE,
        payload: payload
    }
}

export function getPeopleSuggestions(payload) {
    return {
        type: ActionTypes.GET_PEOPLE_SUGGESTIONS,
        payload: payload
    }
}

export function getPeopleSuggestionsSuccess(payload) {
    return {
        type: ActionTypes.GET_PEOPLE_SUGGESTIONS_SUCCESS,
        payload: payload
    }
}

export function getPeopleSuggestionsFailed(payload) {
    return {
        type: ActionTypes.GET_PEOPLE_SUGGESTIONS_FAILED,
        payload: payload
    }
}