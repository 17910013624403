import * as ActionTypes from './index'
import {actionCreator} from "../helpers/functions";

export function deleteClientById(payload) {
    return {
        type: ActionTypes.DELETE_CLIENT_BY_ID,
        payload: payload
    }
}

export function deleteClientByIdSuccess(payload) {
    return {
        type: ActionTypes.DELETE_CLIENT_BY_ID_SUCCESS,
        payload: payload
    }
}

export function deleteClientByIdFailed(payload) {
    return {
        type: ActionTypes.DELETE_CLIENT_BY_ID_FAILED,
        payload: payload
    }
}

export function selectClientSuggestion(payload) {
    return {
        type: ActionTypes.SELECT_CLIENT_SUGGESTION,
        payload: payload
    }
}

export function getClientsSuggestions(payload) {
    return {
        type: ActionTypes.GET_CLIENTS_SUGGESTIONS,
        payload: payload
    }
}

export function getClientsSuggestionsSuccess(payload) {
    return {
        type: ActionTypes.GET_CLIENTS_SUGGESTIONS_SUCCESS,
        payload: payload
    }
}

export function getClientsSuggestionsFailed(payload) {
    return {
        type: ActionTypes.GET_CLIENTS_SUGGESTIONS_FAILED,
        payload: payload
    }
}

export function changeClientsOrder(payload) {
    return {
        type: ActionTypes.CHANGE_CLIENTS_ORDER,
        payload: payload
    }
}

//action that changes store settings
export function changeClientStoreSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_CLIENT_STORE_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

//action that requests the total clients
export function getTotalClients(token, whereFilter) {
    return {
        type: ActionTypes.GET_TOTAL_CLIENTS,
        payload: {
            token,
            whereFilter
        }
    }
}

//action that receives the total clients
export function receiveTotalClients(totalClients) {
    return {
        type: ActionTypes.RECEIVE_TOTAL_CLIENTS,
        payload: totalClients
    }
}

//action that receives failed response from getting the total clients
export function failedToGetTotalClients(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_TOTAL_CLIENTS,
        payload: response
    }
}

//action that requests client by id
export function getClientById(clientId, token) {
    return {
        type: ActionTypes.GET_CLIENT_BY_ID,
        payload: {
            clientId,
            token
        }
    }
}

//action that receives client by id
export function receiveClientById(client) {
    return {
        type: ActionTypes.RECEIVE_CLIENT_BY_ID,
        payload: client
    }
}

//action that receives failed response from getting the client by id
export function failedToGetClientById(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_CLIENT_BY_ID,
        payload: response
    }
}

//action that requests mobile users
export function getClients(payload) {
    return {
        type: ActionTypes.GET_CLIENTS,
        payload: payload
    }
}

//action that receives mobile users
export function receiveClients(clients) {
    return {
        type: ActionTypes.RECEIVE_CLIENTS,
        payload: clients
    }
}

//action that receives failed response get mobile users
export function failedToGetClients(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_CLIENTS,
        payload: response
    }
}






//action that requests client by id
export function getCampaignPlanClientById(payload) {
    return {
        type: ActionTypes.GET_CAMPAIGN_PLAN_CLIENT_BY_ID,
        payload: payload
    }
}

//action that receives client by id
export function receiveCampaignPlanClientById(payload) {
    return {
        type: ActionTypes.RECEIVE_CAMPAIGN_PLAN_CLIENT_BY_ID,
        payload: payload
    }
}

//action that receives failed response from getting the client by id
export function failedToGetCampaignPlanClientById(payload) {
    return {
        type: ActionTypes.FAILED_TO_GET_CAMPAIGN_PLAN_CLIENT_BY_ID,
        payload: payload
    }
}

export const resetClientsState = actionCreator(ActionTypes.RESET_CLIENTS_STATE)
