import React, {Component} from 'react';
import {connect} from 'react-redux';
import HeaderComponent from "../components/HeaderComponent";
import {toSignOut} from "../actions/login";
import {toggleDrawer} from "../actions/generalActions";
import {parseGeneralReducer, parseLogin} from "../helpers/parseImmutables";
import {useLocation} from "react-router-dom";

const HeaderHOC  = props => {
    const location = useLocation();

    const userSignOut = () => {
        props.signOut(props.login.userInfo.token);
    };

    const getHeaderTitle = () => {
        let title = 'Administrator Panel';
        if(location.pathname.includes('enabled-offer')) {
            title = 'Enabled Offers'
        }
        else if(location.pathname.includes('offer')){
            title = 'Offers'
        }
        else if(location.pathname.includes('store')){
            title = 'Stores'
        }
        else if(location.pathname.includes('category')){
            title = 'Categories'
        }
        else if(location.pathname.includes('product')) {
            title = 'Products'
        }
        else if(location.pathname.includes('orders')) {
            title = 'Orders'
        }
        else if(location.pathname.includes('clients')) {
            title = 'Clients'
        }
        else if(location.pathname.includes('tellers')) {
            title = 'Tellers'
        }
        else if(location.pathname.includes('points')) {
            title = 'Point System'
        }
        else if(location.pathname.includes('editors')){
            title = 'Editors'
        }
        else if(location.pathname.includes('campaign')){
            title = 'Campaigns'
        }
        return title;
    };

    return (
        <HeaderComponent
            {...props}
            drawer={props.drawer}
            title={getHeaderTitle()}
            toggleDrawer={props.toggleDrawer}
            signOut={userSignOut}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
        drawer: parseGeneralReducer(state.generalReducer).drawer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: (accessToken) => {
            dispatch(toSignOut(accessToken))
        },
        toggleDrawer: (payload) => {
            dispatch(toggleDrawer(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderHOC);

