import * as ActionTypes from '../actions/index';
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    fetching: false,
    clients: [],
    fetchingTotalClients: false,
    totalClients: '',
    rowsPerPage: 10,
    page: 1,
    orderBy: 1, //table header array index that does the mapping of column name and its key for sort
    order: 'desc',
    filterField: '',
    suggestions: fromJS([]),
    deleting: false,

});

function changeClientStoreSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function getTotalClients(state, action) {
    return state
        .set('fetchingTotalClients', true);
}

function receiveTotalClients(state, action) {
    return state
        .set('fetchingTotalClients', false)
        .set('totalClients', action.payload.body.count);
}

function failedToGetTotalClients(state, action) {
    return state
        .set('fetchingTotalClients', false);
}

function getClients(state, action) {
    return state
        .set('fetching', true);
}

function receiveClients(state, action) {
    try {
        action.payload = action.payload.body
    }
    catch (e) {
        action.payload = null
    }
    return state
        .set('clients', fromJS(action.payload))
        .set('fetching', false);
}

function failedToGetClients(state, action) {
    return state
        .set('fetching', false);
}

function logOff(state, action) {
    return defaultState;
}

function changeClientsOrder(state, action) {
    return state
        .set('order', action.payload.order)
        .set('orderBy', action.payload.orderBy)
        .set('filterField', '')
}

function getClientsSuggestions(state) {
    return state;
}

function getClientsSuggestionsSuccess(state, action) {
    return state
        .set('suggestions', fromJS(action.payload.body))
}

function getClientsSuggestionsFailed(state) {
    return state
        .set('suggestions', fromJS([]))
}

function selectClientSuggestion(state, action) {
    return state
        .set('clients', fromJS([action.payload]))
        .set('totalClients', 1)
        .set('page', 1)
}

function resetState(state, action) {
    return defaultState
}

function deleteClientById(state, action) {
    return  state
        .set('deleting', true)
}

function deleteClientByIdSuccess(state, action) {
    return state
        .set('deleting', false)
}

function deleteClientByIdFailed(state, action) {
    return state
        .set('deleting', false)
}

function getCampaignPlanClientById(state,action){
    return state
    .set('fetching', true);
}
function receiveCampaignPlanClientById(state,action){
    let clients = [];
    try {
        clients =  action.payload.body
    }
    catch (e) {
    }

    return state
        .set('clients', state.get("clients").push(fromJS(clients)))
        .set('fetching', false);

}
function failedToGetCampaignPlanClientById(state,action){
    return state
    .set('fetching', false);
}


export const clients = createReducer(defaultState, {
    [ActionTypes.CHANGE_CLIENT_STORE_SETTINGS]: changeClientStoreSettings ,
    [ActionTypes.GET_TOTAL_CLIENTS]: getTotalClients ,
    [ActionTypes.RECEIVE_TOTAL_CLIENTS]: receiveTotalClients ,
    [ActionTypes.FAILED_TO_GET_TOTAL_CLIENTS]: failedToGetTotalClients ,
    [ActionTypes.GET_CLIENTS]: getClients ,
    [ActionTypes.RECEIVE_CLIENTS]: receiveClients ,
    [ActionTypes.FAILED_TO_GET_CLIENTS]: failedToGetClients ,
    [ActionTypes.LOG_OFF]: logOff ,
    [ActionTypes.CHANGE_CLIENTS_ORDER]: changeClientsOrder,
    [ActionTypes.GET_CLIENTS_SUGGESTIONS]: getClientsSuggestions,
    [ActionTypes.GET_CLIENTS_SUGGESTIONS_SUCCESS]: getClientsSuggestionsSuccess,
    [ActionTypes.GET_CLIENTS_SUGGESTIONS_FAILED]: getClientsSuggestionsFailed,
    [ActionTypes.SELECT_CLIENT_SUGGESTION]: selectClientSuggestion,
    [ActionTypes.RESET_CLIENTS_STATE]: resetState,
    [ActionTypes.DELETE_CLIENT_BY_ID]: deleteClientById,
    [ActionTypes.DELETE_CLIENT_BY_ID_SUCCESS]: deleteClientByIdSuccess,
    [ActionTypes.DELETE_CLIENT_BY_ID_FAILED]: deleteClientByIdFailed,
    [ActionTypes.GET_CAMPAIGN_PLAN_CLIENT_BY_ID]:getCampaignPlanClientById,
    [ActionTypes.RECEIVE_CAMPAIGN_PLAN_CLIENT_BY_ID]:receiveCampaignPlanClientById,
    [ActionTypes.FAILED_TO_GET_CAMPAIGN_PLAN_CLIENT_BY_ID]:failedToGetCampaignPlanClientById,
});
