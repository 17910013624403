import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import InputComp from "./InputComp";
import CardHeader from '@material-ui/core/CardHeader';
import Colors from "../helpers/constants/colors";

const style = {
    container: {
        height: '100vh',
        backgroundColor: Colors.MAIN_BODY_COLOR

    },
    refresh: {
        position: 'fixed',
        top: '80%',
        left: '50%',
        transform: 'translateX(-50%)'
    },
};

const ResetPassword = (props) => {
    return (
        <div style={style.container}>
            <div className="container d-flex h-100">
                <div className="row justify-content-center align-self-center  ml-auto mr-auto">
                    <Card className="p-3">
                        <CardHeader
                            title=" Password reset"
                            subheader={'Type your new password'}
                        />
                        <CardContent>
                            <form id="resetPasswordForm"  role="form" onSubmit={props.handleSubmit}>
                                <InputComp Required={true} Type="password" Label="New password"
                                           fullWidth={true} margin={'normal'}
                                           Value={props.resetPasswordForm.newPassword}
                                           Name="newPassword" PropHandleInputChange={props.handleInputChange}/>
                                <InputComp Required={true} fullWidth={true} margin={'normal'}
                                           Type="password" Label="Retype new password"
                                           Value={props.resetPasswordForm.retypedNewPassword}
                                           Name="retypedNewPassword" PropHandleInputChange={props.handleInputChange}/>

                            </form>
                        </CardContent>
                        <CardActions>
                            <Button
                                color="primary"
                                variant="contained"
                                form="resetPasswordForm"
                                type="submit">Reset</Button>
                        </CardActions>
                    </Card>
                </div>
                <Snackbar
                    open={!!props.resetPasswordForm.responseMessage}
                    message={props.resetPasswordForm.responseMessage}
                    autoHideDuration={30000}
                    onClose={ () => { props.changePasswordResetFormSettings('responseMessage', '')}}
                />
            </div>
            {
                props.resetPasswordForm.resettingPassword?
                    <CircularProgress
                        size={40}
                        left={50}
                        top={50}
                        status="loading"
                        style={style.refresh}
                    />
                    :
                    null
            }
        </div>
    )
};

export default ResetPassword;