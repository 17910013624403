import * as ActionTypes from '../actions/index';
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';

const defaultCategory = {
    cid: "",
    brid: "",
    catName: "",
    selectedStores: [],
    products: []
};

const defaultState = fromJS({
    all: [],
    isFetching: false,
    fetchedCategory: defaultCategory,
    newCategory: defaultCategory,
    changedCategory: defaultCategory,
    adding: false,
    msg: null,
    showEditing: false,
    showEditingProductCategory: null,
    deleting: false,
    isDeleteModalOpen: false,
    fetchingCategory: false,
    patching: false,
    sendResponse: '',
    moveProducts: false, //property that reflects the category delete strategy when the category is not empty
    moveProductsCategory: '' ,//property that reflects the new desired category of the  products,
    movingProducts: false,
    moveProductsFailedResponse: '',
    rowsPerPage: 10,
    page: 1,
    totalCategories: '',
    productsListRowsPerPage: 10,
    productsListPage: 1,
    categoryOverviewActiveTab: 0,
    fetchingCountCategories: false
});

function countCategories(state, action) {
    return state
        .set('fetchingCountCategories', true);
}

function countCategoriesSuccess(state, action) {
    return state
        .set('fetchingCountCategories', false)
        .set('totalCategories', action.payload.body.count);
}

function countCategoriesFail(state, action) {
    return state
        .set('fetchingCountCategories', false);
}

function bulkUpdateProducts(state, action) {
    return state
        .set('movingProducts', true);
}

function receiveBulkUpdateProducts(state, action) {
    return state
        .set('movingProducts', false);
}

function failedToBulkUpdateProducts(state, action) {
    return state
        .set('movingProducts', false)
        .set('moveProductsFailedResponse', 'Failed to move products');
}

function getCategoryById(state, action) {
    return state
        .set('fetchingCategory', true);
}

function receiveCategoryById(state, action) {
    try {
        action.payload = JSON.parse(action.payload.text)
    }
    catch (e) {
        action.payload = defaultCategory
    }
    return state
        .setIn(['changedCategory', 'cid'], action.payload.id)
        .setIn(['changedCategory', 'brid'], "")
        .setIn(['changedCategory', 'catName'], action.payload.name)
        .setIn(['changedCategory', 'products'], fromJS(action.payload.products))
        .setIn(['fetchedCategory', 'cid'], action.payload.id)
        .setIn(['fetchedCategory', 'brid'], "")
        .setIn(['fetchedCategory', 'catName'], action.payload.name)
        .setIn(['fetchedCategory', 'products'], fromJS(action.payload.products))
        .set('fetchingCategory', false);
}

function failedToGetCategoryById(state, action) {
    return state
        .set('fetchingCategory', false)
        .set('sendResponse', 'Failed to get category.');
}

function changeCategorySettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function requestAllCategories(state, action) {
    return state
        .set('isFetching', true);
}

function receiveAllCategories(state, action) {
    return state
        .set('all', fromJS(action.payload.body))
        .set('isFetching', false);
}

function receiveErrorGetCategories(state, action) {
    return state
        .set('isFetching', false);
}

function editNewCategory(state, action) {
    return state
        .setIn(['newCategory', action.payload.key], fromJS(action.payload.value));
}

function editStoredCategory(state, action) {
    return state
        .setIn(['changedCategory', action.payload.key], fromJS(action.payload.value));
}

function requestAddCategory(state, action) {
    return state
        .set('adding', true);
}

function receiveAddCategory(state, action) {
    return state
        .set('adding', false)
        .set('newCategory', fromJS(defaultCategory))
        .set('showEditing', false)
        .set('showEditingProductCategory', null)
        .set('sendResponse', 'Category was created successfully.');
}

function receiveErrorAddCategory(state, action) {
    return state
        .set('adding', false)
        .set('sendResponse', 'Failed to create category.');
}

function requestEditCategory(state, action) {
    return state
        .set('patching', true);
}

function receiveEditCategory(state, action) {
    return state
        .set('patching', false)
        .set('sendResponse', 'Category was edited successfully')
        .set('showEditing', false)
}

function receiveErrorEditCategory(state, action) {
    return state
        .set('patching', false)
        .set('sendResponse', 'Failed to update category.');
}

function showingEditCategoryForm(state, action) {
    return state
        .set('showEditing', true)
        .set('showEditingProductCategory', action.payload.key);
}

function requestDeleteCategory(state, action) {
    return state
        .set('deleting', true);
}

function receiveDeleteCategory(state, action) {
    return defaultState;
}

function receiveErrorDeleteCategory(state, action) {
    return state
        .set('deleting', false)
        .set('sendResponse', 'Failed to delete category');
}

function resetState(state, action) {
    return defaultState
}

export const categories = createReducer(defaultState, {
    [ActionTypes.COUNT_CATEGORIES]: countCategories,
    [ActionTypes.COUNT_CATEGORIES_SUCCESS]: countCategoriesSuccess,
    [ActionTypes.COUNT_CATEGORIES_FAIL]: countCategoriesFail,
    [ActionTypes.BULK_UPDATE_PRODUCTS]: bulkUpdateProducts,
    [ActionTypes.RECEIVE_BULK_UPDATE_PRODUCTS]: receiveBulkUpdateProducts,
    [ActionTypes.FAILED_TO_BULK_UPDATE_PRODUCTS]: failedToBulkUpdateProducts,
    [ActionTypes.GET_CATEGORY_BY_ID]: getCategoryById,
    [ActionTypes.RECEIVE_CATEGORY_BY_ID]: receiveCategoryById,
    [ActionTypes.FAILED_TO_GET_CATEGORY_BY_ID]: failedToGetCategoryById,
    [ActionTypes.CHANGE_CATEGORY_SETTINGS]: changeCategorySettings,
    [ActionTypes.REQUEST_ALL_CATEGORIES]: requestAllCategories,
    [ActionTypes.RECEIVE_ALL_CATEGORIES]: receiveAllCategories,
    [ActionTypes.RECEIVE_ERROR_GET_CATEGORIES]: receiveErrorGetCategories,
    [ActionTypes.EDIT_NEW_CATEGORY]: editNewCategory,
    [ActionTypes.EDIT_STORED_CATEGORY]: editStoredCategory,
    [ActionTypes.REQUEST_ADD_CATEGORY]: requestAddCategory,
    [ActionTypes.RECEIVE_ADD_CATEGORY]: receiveAddCategory,
    [ActionTypes.RECEIVE_ERROR_ADD_CATEGORY]: receiveErrorAddCategory,
    [ActionTypes.REQUEST_EDIT_CATEGORY]: requestEditCategory,
    [ActionTypes.RECEIVE_EDIT_CATEGORY]: receiveEditCategory,
    [ActionTypes.RECEIVE_ERROR_EDIT_CATEGORY]: receiveErrorEditCategory,
    [ActionTypes.SHOW_EDIT_CATEGORY]: showingEditCategoryForm,
    [ActionTypes.REQUEST_DELETE_CATEGORY]: requestDeleteCategory,
    [ActionTypes.RECEIVE_DELETE_CATEGORY]: receiveDeleteCategory,
    [ActionTypes.RECEIVE_ERROR_DELETE_CATEGORY]: receiveErrorDeleteCategory,
    [ActionTypes.RESET_CATEGORIES_STATE]: resetState,
});
