import React from 'react';
import InputComp from "../InputComp";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from "@material-ui/core/IconButton";
import Person from "@material-ui/icons/Person";
import Button from "@material-ui/core/Button";

const ClientForm = (props) => {
    return (
        <div className="col-md-12">
            <Card>
                <CardHeader
                    avatar={
                        <IconButton>
                            <Person />
                        </IconButton>
                    }
                    title="Create Client"
                />
                <CardContent>
                    <div className="row no-gutters">
                        <div className="col col-lg-4 offset-lg-3">
                            <form id="form-teller" className="form-horizontal" onSubmit={props.handleSubmit}>
                                <InputComp Value={props.clientForm.formData.username} Label="Username"
                                           Name="username" Type="text"
                                           margin={'normal'}
                                           Required={true}
                                           fullWidth={true}
                                           placeholder="username"
                                           PropHandleInputChange={props.handleTextInputChange}
                                />
                                <InputComp Value={props.clientForm.formData.email} Label="Email"
                                           Name="email" Type="email"
                                           margin={'normal'}
                                           Required={true}
                                           fullWidth={true}
                                           placeholder="email"
                                           PropHandleInputChange={props.handleTextInputChange}
                                />
                                <InputComp Value={props.clientForm.formData.password} Label="Password"
                                           Name="password" Type="password"
                                           margin={'normal'}
                                           Required={true}
                                           fullWidth={true}
                                           placeholder="password"
                                           PropHandleInputChange={props.handleTextInputChange}
                                />
                                <InputComp Value={props.clientForm.formData.firstName} Label="First name"
                                           Name="firstName" Type="text"
                                           margin={'normal'}
                                           fullWidth={true}
                                           placeholder="firstName"
                                           PropHandleInputChange={props.handleTextInputChange}
                                />
                                <InputComp Value={props.clientForm.formData.lastName} Label="Last name"
                                           Name="lastName" Type="text"
                                           margin={'normal'}
                                           fullWidth={true}
                                           placeholder="lastName"
                                           PropHandleInputChange={props.handleTextInputChange}
                                />
                                <InputComp Value={props.clientForm.formData.address} Label="Address"
                                           Name="address" Type="text"
                                           margin={'normal'}
                                           fullWidth={true}
                                           placeholder="address"
                                           PropHandleInputChange={props.handleTextInputChange}
                                />
                                <InputComp Value={props.clientForm.formData.country} Label="Country"
                                           Name="country" Type="text"
                                           margin={'normal'}
                                           fullWidth={true}
                                           placeholder="country"
                                           PropHandleInputChange={props.handleTextInputChange}
                                />
                                <InputComp Value={props.clientForm.formData.telephone} Label="Telephone"
                                           Name="telephone" Type="tel"
                                           margin={'normal'}
                                           fullWidth={true}
                                           placeholder="telephone"
                                           PropHandleInputChange={props.handleTextInputChange}
                                />
                            </form>
                        </div>
                    </div>
                </CardContent>
                <CardActions>
                    <Button
                        color="primary"
                        variant="contained"
                        form="form-teller"
                        type="submit">Create</Button>
                </CardActions>
            </Card>
            <Snackbar
                open={!!props.clientForm.createClientResponseMessage}
                message={props.clientForm.createClientResponseMessage}
                autoHideDuration={6000}
                onClose={() => {props.changeClientFormSettings({
                    key: 'createClientResponseMessage',
                    value: ''
                })}}
            />
        </div>
    )
};

export default ClientForm;