import React from 'react';
import {Route, Switch} from 'react-router';
import AuthorizationHOC from '../containers/authorizationHOC';
import OfferListHOC from '../containers/Offers/offerListHOC';
import OfferFormHOC from '../containers/Offers/offerFormHOC';
import CategoryListHOC from '../containers/Categories/categoryListHOC';
import ProductListHOC from '../containers/Products/productListHOC';
import StoreListHOC from '../containers/Stores/storeListHOC';
import CategoryFormHOC from '../containers/Categories/categoryFormHOC';
import ProductFormHOC from '../containers/Products/productFormHOC';
import StoreFormHOC from '../containers/Stores/storeFormHOC';
import AdminFormHOC from '../containers/adminFormHOC';
import AccountSettingsHOC from '../containers/accountSettingsFormHOC';
import {BASIC_ROLE_GROUP} from "../helpers/variables";
import ClientListHOC from "../containers/Clients/clientListHOC"
import DashboardHOC from "../containers/dashboardHOC";
import ClientFormHOC from  '../containers/Clients/clientFormHOC';
import ClientOverviewHOC from "../containers/Clients/clientOverviewHOC"
import CategoryOverviewHOC from '../containers/Categories/categoryOverviewHOC';
import OrderListHOC from '../containers/Orders/orderListHOC';
import OrderOverviewHOC from '../containers/Orders/orderOverviewHOC'
import TellerFormHOC from '../containers/tellerFormHOC';
import TellerListHOC from '../containers/Tellers/tellerListHOC';
import TellerOverviewHOC from "../containers/Tellers/tellerOverviewHOC";
import PointsOverviewHOC from '../containers/Points/pointsOverviewHOC';
import PointsFormHOC from '../containers/Points/pointsFormHOC';
import OrderFormHOC from '../containers/Orders/orderFormHOC';
import EnabledOfferHOC from '../containers/EnabledOffer/enableedOfferHOC';
import EnabledOffersHOC from '../containers/EnabledOffers/enabledOffersHOC';
import EditorsHOC from '../containers/Editors/editorsHOC';
import EditorHOC from '../containers/Editor/editorHOC';
import CampaignHOC from '../containers/Campaigns/campaignsHOC'
import CampaignOverviewHOC from '../containers/Campaigns/campaignOverviewHOC'
import CampaignNewEditHOC from '../containers/Campaigns/campaignNewEditHOC'
import CampaignNewCreateHOC from '../containers/Campaigns/campaignNewCreateHOC'
import PointsMultiplierHOC from '../containers/PointsMultiplier/pointsMultiplierHOC.js'
import PointsMultiplierEditHOC from '../containers/PointsMultiplier/pointsMultiplierEditHOC'
import PointsMultiplierCreateHOC from '../containers/PointsMultiplier/pointsMultiplierCreateHOC'

const ContentRouter = (props) => {
    return (
        <Switch>
            <Route exact path="/" component={AuthorizationHOC(['admin', 'editor'])(DashboardHOC)}/>
            <Route exact path="/tellers" component={AuthorizationHOC(BASIC_ROLE_GROUP)(TellerListHOC)}/>
            <Route exact path="/editors/add" component={AuthorizationHOC(BASIC_ROLE_GROUP)(EditorHOC)}/>
            <Route exact path="/editors/:id" component={AuthorizationHOC(BASIC_ROLE_GROUP)(EditorHOC)}/>
            <Route path="/editors" component={AuthorizationHOC(BASIC_ROLE_GROUP)(EditorsHOC)}/>
            <Route exact path="/tellers/add" component={AuthorizationHOC(BASIC_ROLE_GROUP)(TellerFormHOC)}/>
            <Route exact path="/tellers/:id" component={AuthorizationHOC(BASIC_ROLE_GROUP)(TellerOverviewHOC)}/>
            <Route exact path="/offer" component={AuthorizationHOC(BASIC_ROLE_GROUP)(OfferListHOC)}/>
            <Route exact path="/offer/add" component={AuthorizationHOC(BASIC_ROLE_GROUP)(OfferFormHOC)}/>
            <Route exact path="/offer/edit/:id" component={AuthorizationHOC(BASIC_ROLE_GROUP)(OfferFormHOC)}/>
            <Route exact path="/store" component={AuthorizationHOC(BASIC_ROLE_GROUP)(StoreListHOC)}/>
            <Route exact path="/store/add" component={AuthorizationHOC(BASIC_ROLE_GROUP)(StoreFormHOC)}/>
            <Route exact path="/store/edit/:id" component={AuthorizationHOC(BASIC_ROLE_GROUP)(StoreFormHOC)}/>
            <Route exact path="/product" component={AuthorizationHOC(['admin', 'editor'])(ProductListHOC)}/>
            <Route exact path="/product/add" component={AuthorizationHOC(BASIC_ROLE_GROUP)(ProductFormHOC)}/>
            <Route exact path="/product/edit/:id" component={AuthorizationHOC(['admin', 'editor'])(ProductFormHOC)}/>
            <Route exact path="/category" component={AuthorizationHOC(['admin', 'editor'])(CategoryListHOC)}/>
            <Route exact path="/category/add" component={AuthorizationHOC(BASIC_ROLE_GROUP)(CategoryFormHOC)}/>
            <Route exact path="/category/edit/:id" component={AuthorizationHOC(BASIC_ROLE_GROUP)(CategoryFormHOC)}/>
            <Route exact path="/category/:id" component={AuthorizationHOC(BASIC_ROLE_GROUP)(CategoryOverviewHOC)}/>
            <Route exact path="/admin/add" component={AuthorizationHOC(BASIC_ROLE_GROUP)(AdminFormHOC)}/>
            <Route exact path="/user/edit" component={AuthorizationHOC(BASIC_ROLE_GROUP)(AccountSettingsHOC)}/>
            <Route exact path="/clients" component={AuthorizationHOC(['admin', 'editor'])(ClientListHOC)}/>
            <Route exact path="/clients/add" component={AuthorizationHOC(BASIC_ROLE_GROUP)(ClientFormHOC)}/>
            <Route exact path="/clients/:id" component={AuthorizationHOC(['admin', 'editor'])(ClientOverviewHOC)}/>
            <Route exact path="/orders" component={AuthorizationHOC(['admin', 'editor'])(OrderListHOC)}/>
            <Route exact path="/orders/add" component={AuthorizationHOC(BASIC_ROLE_GROUP)(OrderFormHOC)}/>
            <Route exact path="/orders/:id" component={AuthorizationHOC(['admin', 'editor'])(OrderOverviewHOC)}/>
            <Route exact path="/points" component={AuthorizationHOC(BASIC_ROLE_GROUP)(PointsOverviewHOC)}/>
            <Route exact path="/points/add" component={AuthorizationHOC(BASIC_ROLE_GROUP)(PointsFormHOC)}/>
            <Route exact path="/enabled-offer/:id" component={AuthorizationHOC(['admin', 'editor'])(EnabledOfferHOC)}/>
            <Route exact path="/enabled-offers" component={AuthorizationHOC(['admin', 'editor'])(EnabledOffersHOC)}/>
            <Route exact path="/campaigns" component={AuthorizationHOC(['admin', 'editor'])(CampaignHOC)}/>
            <Route exact path="/campaignsNew/add" component={AuthorizationHOC(['admin', 'editor'])(CampaignNewCreateHOC)}/>
            <Route exact path="/campaigns/:id" component={AuthorizationHOC(['admin', 'editor'])(CampaignOverviewHOC)}/>
            <Route exact path="/campaigns/:id/edit" component={AuthorizationHOC(['admin', 'editor'])(CampaignNewEditHOC)}/>
            
            <Route exact path="/pointsMultiplier" component={AuthorizationHOC(['admin', 'editor'])(PointsMultiplierHOC)}/>
            <Route exact path="/pointsMultiplier/add" component={AuthorizationHOC(['admin', 'editor'])(PointsMultiplierCreateHOC)}/>
            <Route exact path="/pointsMultiplier/:id" component={AuthorizationHOC(['admin', 'editor'])(PointsMultiplierEditHOC)}/>
            
        </Switch>
    )
};


export default ContentRouter;
