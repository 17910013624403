import * as actions from '../actions/pointsMultiplier';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL,BASE_URL_DOTNET} from "../helpers/variables";
import sampleCombine from "xstream/extra/sampleCombine";
import flattenSequentially from "xstream/extra/flattenSequentially";
import flattenConcurrently from "xstream/extra/flattenConcurrently";

export function getMultipliers(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_MULTIPLIERS)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL + 'api/multipliers' +'?access_token=' + token
            +
            (action.payload?
                '&offset=' + action.payload.filter.offset:'&offset=0')
                +
            (action.payload?
                '&limit=' + action.payload.filter.limit:'&limit=10')
               +
            (action.payload.filter.where?
                '&where=' + action.payload.filter.where:'&where='),
            
            
            category: 'getMultipliers',
            method: 'GET',
            withCredentials: true,
            // headers:{Authorization: token},
            withCredentials: true
        }));
        

    let httpResponse$ = sources.HTTP
        .select('getMultipliers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveMultipliers(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function failedToGetMultipliers(sources) {
    let httpResponse$ = sources.HTTP
        .select('getMultipliers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetMultipliers(response));

    return {
        ACTION: httpResponse$
    }
}

export function getActiveMultipliers(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_ACTIVE_MULTIPLIERS)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL + 'api/multipliers/active' +'?access_token=' + token,
            // +
            // (action.payload?
            //     '?filter[skip]=' + action.payload.filter.skip:'?filter[skip]=0')
            //     +
            // (action.payload?
            //     '&filter[limit]=' + action.payload.filter.limit:'&filter[limit]=10')
            //    +
            // (action.payload.filter.where?
            //     '&filter[where]=' + action.payload.filter.where:'&filter[where]=')
            
            
            category: 'getActiveMultipliers',
            method: 'GET',
            withCredentials: true,
            // headers:{Authorization: token},
            withCredentials: true
        }));
        

    let httpResponse$ = sources.HTTP
        .select('getActiveMultipliers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveActiveMultipliers(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function failedToGetActiveMultipliers(sources) {
    let httpResponse$ = sources.HTTP
        .select('getActiveMultipliers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetActiveMultipliers(response));

    return {
        ACTION: httpResponse$
    }
}
export function getMultipliersCount(sources){
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_MULTIPLIERS_COUNT)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/campaigns/count' ,
            category: 'getMultipliersCount',
            method: 'GET',
            withCredentials: true,
            headers:{Authorization: token},
            // withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('getMultipliersCount')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveMultipliersCount(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function failedToGetMultipliersCount(sources) {
    let httpResponse$ = sources.HTTP
        .select('getMultipliersCount')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetMultipliersCount(response));

    return {
        ACTION: httpResponse$
    }
}