import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function countXpOrders(payload) {
    return {
        type: ActionTypes.COUNT_XP_ORDERS,
        payload: payload
    }
}

export function countXpOrdersSuccess(payload) {
    return {
        type: ActionTypes.COUNT_XP_ORDERS_SUCCESS,
        payload: payload
    }
}

export function countXpOrdersFailed(payload) {
    return {
        type: ActionTypes.COUNT_XP_ORDERS_FAILED,
        payload: payload
    }
}

export function changeOrdersSortOrder(payload) {
    return {
        type: ActionTypes.CHANGE_ORDERS_SORT_ORDER,
        payload: payload
    }
}

//action that request to delete  an order
export function deleteOrder(payload) {
    return {
        type: ActionTypes.DELETE_ORDER,
        payload: payload
    }
}

//action that receives successful response from deleting an order
export function deleteOrderSuccess(payload) {
    return {
        type: ActionTypes.DELETE_ORDER_SUCCESS,
        payload: payload
    }
}

//action that receives failed response delete order
export function deleteOrderFail(payload) {
    return {
        type: ActionTypes.DELETE_ORDER_FAIL,
        payload: payload
    }
}

//action that request create a new order
export function createNewOrder(payload) {
    return {
        type: ActionTypes.CREATE_NEW_ORDER,
        payload: payload
    }
}

//action that receives create new order response
export function receiveCreateNewOrderResponse(payload) {
    return {
        type: ActionTypes.RECEIVE_CREATE_NEW_ORDER_RESPONSE,
        payload: payload
    }
}

//action that failed to create a new order
export function failedToCreateNewOrder(payload) {
    return {
        type: ActionTypes.FAILED_TO_CREATE_NEW_ORDER,
        payload: payload
    }
}

//action that changesStoreSettings
export function changeOrdersStoreSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_ORDERS_STORE_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

//action that requests get the total orders of the brand
export function getTotalOrders(brandId, token) {
    return {
        type: ActionTypes.GET_TOTAL_ORDERS,
        payload: {
            brandId,
            token
        }
    }
}

//action that receives total orders
export function receiveTotalOrders(totalOrders) {
    return {
        type: ActionTypes.RECEIVE_TOTAL_ORDERS,
        payload: totalOrders
    }
}

//action that receives failed response from getting the total orders
export function failedToGetTotalOrders(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_TOTAL_ORDERS,
        payload: response
    }
}

//action that request order by id
export function getOrderById(brandId, orderId, token) {
    return {
        type: ActionTypes.GET_ORDER_BY_ID,
        payload: {
            brandId,
            orderId,
            token
        }
    }
}

//action that receives order by id
export function receiveOrderById(order) {
    return {
        type: ActionTypes.RECEIVE_ORDER_BY_ID,
        payload: order
    }
}

//action that receives failed response from getting order by id
export function failedToReceiveOrderById(response) {
    return {
        type: ActionTypes.FAILED_TO_RECEIVE_ORDER_BY_ID,
        payload: response
    }
}

//action that requests the orders
export function getOrders(brandId, token, filter) {
    return {
        type: ActionTypes.GET_ORDERS,
        payload: {
            brandId,
            token,
            filter
        }
    }
}

//action that receives orders
export function receiveOrders(orders) {
    return {
        type: ActionTypes.RECEIVE_ORDERS,
        payload: orders
    }
}

//action that receives error from get orders
export function failedToGetOrders(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_ORDERS,
        payload: response
    }
}

export const resetOrdersState = actionCreator(ActionTypes.RESET_ORDERS_STATE)