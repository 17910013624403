import * as ActionTypes from './index';

//action that changes order form store settings
export function changeOrderFormStoreSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_ORDER_FORM_STORE_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

//action that changes the order items
export function changeOrderItems(payload) {
    return {
        type: ActionTypes.CHANGE_ORDER_ITEMS,
        payload: payload
    }
}