import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export const logActivity = actionCreator(ActionTypes.LOG_ACTIVITY);

export const logActivitySuccess = actionCreator(ActionTypes.LOG_ACTIVITY_SUCCESS);

export const logActivityFailed = actionCreator(ActionTypes.LOG_ACTIVITY_FAILED);

//action that requests set new password
export function requestSetNewPassword(newPassword, resetToken) {
    return {
        type: ActionTypes.REQUEST_SET_NEW_PASSWORD,
        payload: {
            newPassword,
            resetToken
        }
    }
}

//action that receives response from setting new password
export function receiveResponseSetNewPassword(response) {
    return {
        type: ActionTypes.RECEIVE_RESPONSE_SET_NEW_PASSWORD,
        payload: response
    }
}

//action that receives failed response set new password
export function failedToReceiveResponseSetNewPassword(response) {
    return {
        type: ActionTypes.FAILED_TO_RECEIVE_RESPONSE_SET_NEW_PASSWORD,
        payload: response
    }
}

//action that request reset password with email
export function requestResetForgottenPassword(email) {
    return {
        type: ActionTypes.REQUEST_RESET_FORGOTTEN_PASSWORD,
        payload: email
    }
}

//action that receives response from requesting reset forgottenPassword
export function receiveResetForgottenPassword(response) {
    return {
        type: ActionTypes.RECEIVE_RESET_FORGOTTEN_PASSWORD,
        payload: response
    }
}

//action that failed to receive reset forgotten password response
export function failedToReceiveResetForgottenPassword(response) {
    return {
        type: ActionTypes.FAILED_TO_RECEIVE_FORGOTTEN_PASSWORD,
        payload: response
    }
}

//action that changes forgot password reset form settings
export function changeForgotPasswordFormSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_FORGOT_PASSWORD_FORM_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

//action that changes password reset Form
export function changePasswordResetFormSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_PASSWORD_RESET_FORM_SETTINGS,
        payload:  {
            key,
            value
        }
    }
}

//action that handles teller form changes
export function handleTellerForm(key, value) {
    return {
        type: ActionTypes.HANDLE_TELLER_FORM,
        payload: {
            key,
            value
        }
    }
}

//action that receives failed response from logging in
export function failedToLogIn(response) {
    return {
        type: ActionTypes.FAILED_TO_LOG_IN,
        payload: response
    }
}

//action that logs off
export function logOff() {
    return {
        type: ActionTypes.LOG_OFF
    }
}

export function receiveAddAdmin() {
    return {
        type: ActionTypes.RECEIVE_ADD_ADMIN
    }
}

export function addNewAdmin(newAdmin) {
    return {
        type: ActionTypes.REQUEST_ADD_ADMIN,
        payload: newAdmin
    };
}

//action that receives error creating new admin
export function receiveErrorAddAdmin(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_ADD_ADMIN,
        payload: response
    }
}

export function tempNewAdmin(newAdminKey, newAdminValue) {
    return {
        type: ActionTypes.TEMP_NEW_ADMIN,
        payload: {key: newAdminKey, value: newAdminValue}
    }
}

export function resetUser() {
    return {
        type: ActionTypes.RESET_USER,
    }
}

export function toUpdateUserPassword(newPassword, token) {
    return {
        type: ActionTypes.TO_UPDATE_USER_PASSWORD,
        payload: {newPassword, token}
    };
}

//action that requests edit user
export function editUserPassword() {
    return {
        type: ActionTypes.EDIT_USER_PASSWORD
    }
}

//action that receives edit user
export function receiveEditPassword() {
    return {
        type: ActionTypes.RECEIVE_EDIT_PASSWORD
    }
}

//action that receives failed edit attempt to change user password
export function receiveErrorEditPassword(response) {
    return {
        type: ActionTypes.RECEIVE_ERROR_EDIT_PASSWORD,
        payload: response
    }
}


export function changeUserInfo(userProp, propValue) {
    return {
        type: ActionTypes.CHANGE_USER_INFO,
        payload: {key: userProp, value: propValue}
    }
}

export function toSignOut(accessToken) {
    return {
        type: ActionTypes.LOG_OFF,
        payload: {accessToken}
    };
}

export function changeLoginForm(inKey, inValue) {
    return {
        type: ActionTypes.CHANGE_STATE,
        payload: {
            key: inKey,
            value: inValue
        }
    }
}

export function userAuth(userName, password) {
    return {
        type: ActionTypes.REQUEST_USER,
        payload: {userName, password}
    };
}



export function receiveAuth(user) {
    return {
        type: ActionTypes.RECEIVE_AUTH,
        payload: user
    }
}

export function receiveAuthDotnet(user) {
    return {
        type: ActionTypes.RECEIVE_AUTH_DOTNET,
        payload: user
    }
}

export const resetLoginState = actionCreator(ActionTypes.RESET_LOGIN_STATE);
