import {fromJS} from "immutable";
import * as ActionTypes from '../actions/index';
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    ordersStatistics: fromJS([]),
    fetchingOrdersStatistics: false,
    fetchingPointsStatistics: false,
    pointsStatistics: fromJS([]),
    fetchingEnabledOffersStatistics: false,
    enabledOffersStatistics: fromJS([]),
    fetchingClientStatistics: false,
    clientStatistics: fromJS([]),
    fetchingXpOrdersStatistics: false,
    xpOrdersStatistics: fromJS([]),
    fetchingPointsCompareStatistics: false,
    pointsCompareStatistics: fromJS([]),
    fetchingClientCompareStatistics: false,
    clientCompareStatistics: fromJS([]),
    fetchingOrdersCompareStatistics: false,
    ordersCompareStatistics: fromJS([]),
    fetchingEnabledOffersCompareStatistics: false,
    enabledOffersCompareStatistics: fromJS([])
});

function getOrdersStatistics(state, action) {
    return state
        .set('fetchingOrdersStatistics', true)
}

function getOrdersStatisticsSuccess(state, action) {
    return state
        .set('ordersStatistics', fromJS(action.payload.body))
        .set('fetchingOrdersStatistics', false)
}

function getOrdersStatisticsFailed(state, action) {
    return state
        .set('fetchingOrdersStatistics', false)
}

function getPointsStatistics(state, action) {
    return state
        .set('fetchingPointsStatistics', true)
}

function getPointsStatisticsSuccess(state, action) {
    return state
        .set('fetchingPointsStatistics', false)
        .set('pointsStatistics', fromJS(action.payload.body))

}

function getPointsStatisticsFailed(state, action) {
    return state
        .set('fetchingPointsStatistics', false)

}

function getEnabledOffersStatistics(state, action) {
    return state
        .set('fetchingEnabledOffersStatistics', true)
}

function getEnabledOffersStatisticsSuccess(state, action) {
    return state
        .set('fetchingEnabledOffersStatistics', false)
        .set('enabledOffersStatistics', fromJS(action.payload.body))
}

function getEnabledOffersStatisticsFailed(state, action) {
    return state
        .set('fetchingEnabledOffersStatistics', false)
}

function getClientStatistics(state, action) {
    return state
        .set('fetchingClientStatistics', true)
}

function getClientStatisticsSuccess(state, action) {
    return state
        .set('fetchingClientStatistics', false)
        .set('clientStatistics', fromJS(action.payload.body))
}

function getClientStatisticsFailed(state, action) {
    return state
        .set('fetchingClientStatistics', false)
}

function getXpOrdersStatistics(state, action) {
    return state
        .set('fetchingXpOrdersStatistics', true)
}

function getXpOrdersStatisticsSuccess(state, action) {
    return state
        .set('fetchingXpOrdersStatistics', false)
        .set('xpOrdersStatistics', fromJS(action.payload.body))
}

function getXpOrdersStatisticsFailed(state, action) {
    return state
        .set('fetchingXpOrdersStatistics', false)
}

function getPointsCompareStatistics(state, action) {
    return state
        .set('fetchingPointsCompareStatistics', true);
}

function getPointsCompareStatisticsSuccess(state, action) {
    return state
        .set('fetchingPointsCompareStatistics', false)
        .set('pointsCompareStatistics', fromJS(action.payload.body));
}

function getPointsCompareStatisticsFailed(state, action) {
    return state
        .set('fetchingPointsCompareStatistics', false);
}

function getClientCompareStatistics(state, action) {
    return state
        .set('fetchingClientCompareStatistics', true)
}

function getClientCompareStatisticsSuccess(state, action) {
    return state
        .set('fetchingClientCompareStatistics', false)
        .set('clientCompareStatistics', fromJS(action.payload.body))
}

function getClientCompareStatisticsFailed(state, action) {
    return state
        .set('fetchingClientCompareStatistics', false)

}

function getOrdersCompareStatistics(state, action) {
    return state
        .set('fetchingOrdersCompareStatistics', true)
}

function getOrdersCompareStatisticsSuccess(state, action) {
    return state
        .set('fetchingOrdersCompareStatistics', false)
        .set('ordersCompareStatistics', fromJS(action.payload.body))
}

function getOrdersCompareStatisticsFailed(state, action) {
    return state
        .set('fetchingOrdersCompareStatistics', false)
}

function getEnabledOffersCompareStatistics(state, action) {
    return state
        .set('fetchingEnabledOffersCompareStatistics', true)
}

function getEnabledOffersCompareStatisticsSuccess(state, action) {
    return state
        .set('fetchingEnabledOffersCompareStatistics', false)
        .set('enabledOffersCompareStatistics', fromJS(action.payload.body))
}

function getEnabledOffersCompareStatisticsFailed(state, action) {
    return state
        .set('fetchingEnabledOffersCompareStatistics', false)
}

function clearStatisticsCompareValues(state, action) {
    return state
        .set('pointsCompareStatistics', fromJS([]))
        .set('clientCompareStatistics', fromJS([]))
        .set('ordersCompareStatistics', fromJS([]))
        .set('enabledOffersCompareStatistics', fromJS([]))
}

export const statistics = createReducer(defaultState, {
    [ActionTypes.GET_ORDERS_STATISTICS]: getOrdersStatistics,
    [ActionTypes.GET_ORDERS_STATISTICS_SUCCESS]: getOrdersStatisticsSuccess,
    [ActionTypes.GET_ORDERS_STATISTICS_FAILED]: getOrdersStatisticsFailed,
    [ActionTypes.GET_POINTS_STATISTICS]: getPointsStatistics,
    [ActionTypes.GET_POINTS_STATISTICS_SUCCESS]: getPointsStatisticsSuccess,
    [ActionTypes.GET_POINTS_STATISTICS_FAILED]: getPointsStatisticsFailed,
    [ActionTypes.GET_ENABLED_OFFERS_STATISTICS]: getEnabledOffersStatistics,
    [ActionTypes.GET_ENABLED_OFFERS_STATISTICS_SUCCESS]: getEnabledOffersStatisticsSuccess,
    [ActionTypes.GET_ENABLED_OFFERS_STATISTICS_FAILED]: getEnabledOffersStatisticsFailed,
    [ActionTypes.GET_CLIENT_STATISTICS]: getClientStatistics,
    [ActionTypes.GET_CLIENT_STATISTICS_SUCCESS]: getClientStatisticsSuccess,
    [ActionTypes.GET_CLIENT_STATISTICS_FAILED]: getClientStatisticsFailed,
    [ActionTypes.GET_XP_ORDERS_STATISTICS]: getXpOrdersStatistics,
    [ActionTypes.GET_XP_ORDERS_STATISTICS_SUCCESS]: getXpOrdersStatisticsSuccess,
    [ActionTypes.GET_XP_ORDERS_STATISTICS_FAILED]: getXpOrdersStatisticsFailed,
    [ActionTypes.GET_POINTS_COMPARE_STATISTICS]: getPointsCompareStatistics,
    [ActionTypes.GET_POINTS_COMPARE_STATISTICS_SUCCESS]: getPointsCompareStatisticsSuccess,
    [ActionTypes.GET_POINTS_COMPARE_STATISTICS_FAILED]: getPointsCompareStatisticsFailed,
    [ActionTypes.GET_CLIENT_COMPARE_STATISTICS]: getClientCompareStatistics,
    [ActionTypes.GET_CLIENT_COMPARE_STATISTICS_SUCCESS]: getClientCompareStatisticsSuccess,
    [ActionTypes.GET_CLIENT_COMPARE_STATISTICS_FAILED]: getClientCompareStatisticsFailed,
    [ActionTypes.GET_ORDERS_COMPARE_STATISTICS]: getOrdersCompareStatistics,
    [ActionTypes.GET_ORDERS_COMPARE_STATISTICS_SUCCESS]: getOrdersCompareStatisticsSuccess,
    [ActionTypes.GET_ORDERS_COMPARE_STATISTICS_FAILED]: getOrdersCompareStatisticsFailed,
    [ActionTypes.GET_ENABLED_OFFERS_COMPARE_STATISTICS]: getEnabledOffersCompareStatistics,
    [ActionTypes.GET_ENABLED_OFFERS_COMPARE_STATISTICS_SUCCESS]: getEnabledOffersCompareStatisticsSuccess,
    [ActionTypes.GET_ENABLED_OFFERS_COMPARE_STATISTICS_FAILED]: getEnabledOffersCompareStatisticsFailed,
    [ActionTypes.CLEAR_STATISTICS_COMPARE_VALUES]: clearStatisticsCompareValues,
});