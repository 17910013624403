import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export const updateEditor = actionCreator(ActionTypes.UPDATE_EDITOR);

export const updateEditorSuccess = actionCreator(ActionTypes.UPDATE_EDITOR_SUCCESS);

export const updateEditorFailed = actionCreator(ActionTypes.UPDATE_EDITOR_FAILED);

export const getEditor = actionCreator(ActionTypes.GET_EDITOR);

export const getEditorSuccess = actionCreator(ActionTypes.GET_EDITOR_SUCCESS);

export const getEditorFailed = actionCreator(ActionTypes.GET_EDITOR_FAILED);


export function changeEditorState(payload) {
    return {
        type: ActionTypes.CHANGE_EDITOR_STATE,
        payload: payload
    }
}

export function createEditor(payload) {
    return {
        type: ActionTypes.CREATE_EDITOR,
        payload: payload
    }
}

export function createEditorSuccess(payload) {
    return {
        type: ActionTypes.CREATE_EDITOR_SUCCESS,
        payload: payload
    }
}

export function createEditorFailed(payload) {
    return {
        type: ActionTypes.CREATE_EDITOR_FAILED,
        payload: payload
    }
}

export const resetEditorState = actionCreator(ActionTypes.RESET_EDITOR_STATE);
