import * as actions from '../actions/products';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL, BASE_URL_DOTNET} from "../helpers/variables";
import moment from "moment";
import { PDF_TYPES } from '../helpers/constants/productAttachmentTypes';
import sampleCombine from "xstream/extra/sampleCombine";

export function requestDeleteProduct(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_DELETE_PRODUCT)
        .map(action => ({
            url: BASE_URL + 'api/products/' + action.payload.pid + '?access_token=' + action.payload.token,
            category: 'requestDeleteProduct',
            method: 'DELETE',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestDeleteProduct')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveDeleteProduct(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestDeleteProductFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestDeleteProduct')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorDeleteProduct(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestEditProduct(sources) {
   
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_EDIT_PRODUCT)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.data.brandId + '/products/'+ action.payload.data.productId +
                '?access_token=' + action.payload.token,
            category: 'requestEditProduct',
            method: 'PATCH',
            send: {
                id: action.payload.data.productId?action.payload.data.productId:null,
                erpId: action.payload.data.erpId?action.payload.data.erpId:null,
                xp:  action.payload.data.xp,
                xpType:  action.payload.data.xpType,
                attachment: action.payload.data.attachment.imageName?null:action.payload.data.attachment,
                name: action.payload.data.product_name,
                description: action.payload.data.description,
                startDate: moment(action.payload.data.startDate).format(),
                endDate: moment(action.payload.data.endDate).format(),
                value: action.payload.data.value,
                points: action.payload.data.points,
                pointsApplyOnce: action.payload.data.pointsApplyOnce,
                categories: [{categoryId: parseInt(action.payload.data.cid)}],
                categoryId: action.payload.data.cid?parseInt(action.payload.data.cid): null
            },
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestEditProduct')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveEditProduct(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestEditProductFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestEditProduct')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorEditProduct(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestAddProduct(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_ADD_PRODUCT)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.brid + '/products?access_token=' +
                action.payload.token,
            category: 'requestAddProduct',
            method: 'POST',
            send: {
                name:  action.payload.product_name,
                xp:  action.payload.xp,
                erpId:  action.payload.erpId ? action.payload.erpId : null,
                xpType:  action.payload.xpType,
                description:  action.payload.description,
                value:  action.payload.value,
                startDate: moment(action.payload.startDate).format(),
                endDate: moment(action.payload.endDate).format(),
                points: action.payload.points,
                pointsApplyOnce: action.payload.pointsApplyOnce,
                categoryId: action.payload.cid?parseInt(action.payload.cid): null,
                attachment: action.payload.attachment,
                [PDF_TYPES.PRODUCT_ELEMENTS]: action.payload[PDF_TYPES.PRODUCT_ELEMENTS],
                [PDF_TYPES.PRODUCT_SPECIFICATIONS]: action.payload[PDF_TYPES.PRODUCT_SPECIFICATIONS],
            },
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestAddProduct')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveAddProduct(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestAddProductFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestAddProduct')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorAddProduct(response));

    return {
        ACTION: httpResponse$
    }
}
// eddwwwwwwwwwwwwwwwwwwwwwww
export function requestGetProducts(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_ALL_PRODUCTS)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL + "api/brands/" 
            + action.payload.brandId +
             '/products?access_token=' +                action.payload.token  +
                (action.payload.filter && action.payload.filter.limit?
                    ('&filter[limit]=' + action.payload.filter.limit):'') +
                (action.payload.filter && action.payload.filter.skip?
                    ('&filter[skip]=' + action.payload.filter.skip):''),
            category: 'requestGetProducts',
            method: 'GET',
            withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetProducts')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveAllProducts(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestGetProductsFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetProducts')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorGetProducts(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestCountProducts(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.COUNT_PRODUCTS)
        .map(action => ({
            url: BASE_URL + 'api/brands/' + action.payload.brandId + '/products/count' +
            '?access_token=' + action.payload.token,
            category: 'requestCountProducts',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCountProducts')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.countProductsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestCountProductsFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCountProducts')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.countProductsFail(response));

    return {
        ACTION: httpResponse$
    }
}

//action that requests change category of products that belong to the same category
export function requestBulkUpdateProducts(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.BULK_UPDATE_PRODUCTS)
        .map(action => ({
            url: BASE_URL + 'api/brands/' + action.payload.brandId + '/products?access_token=' + action.payload.token,
            category: 'requestBulkUpdateProducts',
            method: 'PATCH',
            send: {
                categoryId: action.payload.categoryId,
                products: action.payload.products
            },
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestBulkUpdateProducts')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveBulkUpdateProducts(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//action that receives failed request bulk update
export function receiveFailedToBulkUpdateProducts(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestBulkUpdateProducts')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToBulkUpdateProducts(response));

    return {
        ACTION: httpResponse$
    }
}



//cycle that requests the product by id
export function requestGetProductById(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_PRODUCT_BY_ID)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL + 'api/products/'
            + action.payload.productId
             + '?access_token=' + action.payload.token,
            category: 'requestGetProductById',
            method: 'GET',
            withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetProductById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveProductById(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the product by id
export function failedGetProductById(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetProductById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToReceiveProductById(response));

    return {
        ACTION: httpResponse$
    }
}