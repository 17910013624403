import React from 'react';
import LeftSidebar from "./LeftSidebar";
import Content from "./content";
import HeaderHOC from "../containers/headerHOC"
import { withStyles } from '@material-ui/core/styles';

const drawerWidth = 260;

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        overflowY: 'auto',
        position: 'relative',
        display: 'flex',
        height: '100%'
    },
    appBar: {
        height: '64px',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        height: '64px',
    },
    content: {
        width: '100vh',
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    toolbarImg: {
        height: '100%'
    },
    flex: {
        flexGrow: 1
    },
    '@media screen and (max-width: 768px)': {
        drawerPaperClose: {
            width: '0px'
        }
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
});


const Layout = (props) => {
    const { classes, theme } = props;
    return (
        <div className={classes.root}>
            <HeaderHOC classes={classes} theme={theme}/>
            <LeftSidebar classes={classes} theme={theme} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Content/>
            </main>

        </div>
    )
};

export default withStyles(styles, { withTheme: true })(Layout);
