import React, {Component, useEffect} from 'react';
import { connect } from 'react-redux';
import OrderOverview from "../../components/Orders/orderOverview";
import {deleteOrder, getOrderById} from "../../actions/orders";
import _ from 'lodash';
import {toGetCategories} from "../../actions/categories";
import {showingEditProductForm} from "../../actions/products";
import {getTableListPostitions, getTablePage} from "../../helpers/functions";
import {
    changeOrderOverviewStoreSettings, closeOrderDeleteModal,
    openOrderDeleteModal, resetOrderOverviewState, setActiveTab
} from "../../actions/orderOverview";
import {parseCategories, parseLogin, parseOrderOverview, parseOrders} from "../../helpers/parseImmutables";
import {useHistory, useLocation} from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";

const OrderOverviewHOC = props => {
    const history = useHistory();
    const prevDeletingOrder = usePrevious(props.deletingOrder);
    const location = useLocation();

    useEffect(() => {
        return () => {
            props.resetOrderOverviewState();
        }
    }, [])

    useEffect(() => {
        if(prevDeletingOrder && !props.deletingOrder){
            history.push('/orders')
        }
    }, [prevDeletingOrder, props.deletingOrder])

    useEffect(() => {
        props.getOrderById(props.brandId, _.last(location.pathname.split('/')), props.token);
        props.getAllCategories(props.brandId, props.token);
    }, [])

    const getProductsRows = () => {
        let rows = [];
        let allLineItems = [...props.orderOverview.order.lineItems];
        let position = getTableListPostitions(props.orderOverview.lineItemsRowsPerPage, props.orderOverview.lineItemsPage);
        allLineItems = _.slice(allLineItems, position.start, position.end);
        _.forEach(allLineItems, lineItem => {
            rows.push({
                id: lineItem.id,
                data: [
                    lineItem.name,
                    lineItem.description,
                    _.reduce(lineItem.categories, (previous, current) => {
                        if(previous === '') return current.name;
                        return previous  + ', ' + current.name
                    }, ''),
                    lineItem.points,
                    lineItem.value,
                    lineItem.quantity,
                    lineItem.eprId,
                ],
                label: lineItem.name
            })
        });
        return rows;
    };

    const getTellerStores = () => {
      let stores = '';
      _.forEach(props.orderOverview.order.teller.stores, (store, index) => {
          if(index === 0){
              stores = store.name
          }
          else {
              stores += ', ' + store.name
          }
      });
        return stores;
    };

    const handleOrderProductsRowSelect = (productId) => {
        props.showEditProductForm(productId);
        // props.fetchProduct(props.products.all[productId]);
        history.push('/product/edit/' + productId)

    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.orderOverview.order.lineItems.length / rowsPerPage);
        let currentPage = props.orderOverview.lineItemsPage;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeStoreSettings('lineItemsRowsPerPage', rowsPerPage);
        props.changeStoreSettings('lineItemsPage', currentPage);
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.orderOverview.lineItemsPage,  props.orderOverview.order.lineItems.length, props.orderOverview.lineItemsRowsPerPage);
        props.changeStoreSettings('lineItemsPage', newPage);
    };

    const _deleteOrder = (orderId) => {
        props.deleteOrder({
            token: props.token,
            orderId: orderId
        })
    };

    let productTableheaders = [
        'product name',
        'description',
        'category',
        'points',
        'value',
        'quantity',
        'Erp Id'
    ];

    return(
        <OrderOverview
            activeTab={props.orderOverview.activeTab}
            setActiveTab={props.setActiveTab}
            isDeleteModalOpen={props.orderOverview.isDeleteModalOpen}
            _openOrderDeleteModal={props.openOrderDeleteModal}
            _closeOrderDeleteModal={props.closeOrderDeleteModal}
            _deleteOrder={_deleteOrder}
            page={props.orderOverview.lineItemsPage}
            totalRows={props.orderOverview.order.lineItems.length}
            handlePageChange={handlePageChange}
            rowsPerPage={props.orderOverview.lineItemsRowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            changeRoute={history.push}
            handleOrderProductsRowSelect={handleOrderProductsRowSelect}
            tellerStores={getTellerStores()}
            productRows={getProductsRows()}
            productTableheaders={productTableheaders}
            order={props.orderOverview.order}
            userRole={props.userRole}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        categories: parseCategories(state.categories).all,
        token: parseLogin(state.login).userInfo.token,
        brandId: parseLogin(state.login).userInfo.brand.id,
        orderOverview: parseOrderOverview(state.orderOverview),
        deletingOrder: parseOrders(state.orders).deleting,
        userRole: parseLogin(state.login).userInfo.role
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllCategories: (brandId, token) => {
            dispatch(toGetCategories(brandId, token))
        },
        getOrderById: (brandId, orderId, token) => {
            dispatch(getOrderById(brandId, orderId, token))
        },
        showEditProductForm: (product) => {
            dispatch(showingEditProductForm(product));
        },
        changeStoreSettings: (key, value) => {
            dispatch(changeOrderOverviewStoreSettings(key, value))
        },
        deleteOrder: (payload) => {
            dispatch(deleteOrder(payload))
        },
        openOrderDeleteModal: (payload) => {
            dispatch(openOrderDeleteModal(payload))
        },
        closeOrderDeleteModal: (payload) => {
            dispatch(closeOrderDeleteModal(payload))
        },
        setActiveTab: (payload) => {
            dispatch(setActiveTab(payload))
        },
        resetOrderOverviewState: (payload) => {
            dispatch(resetOrderOverviewState(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderOverviewHOC);
