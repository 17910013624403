import * as actions from '../actions/login';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL,BASE_URL_DOTNET} from "../helpers/variables";
import sampleCombine from "xstream/extra/sampleCombine";

export function requestLogActivity(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.LOG_ACTIVITY)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL + 'api/activityLogs',
            category: 'requestLogActivity',
            method: 'POST',
            send: action.payload.sendBody,
            headers: { Authorization: token}
        }));
    const httpResponse$ = sources.HTTP
        .select('requestLogActivity')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.logActivitySuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestLogActivityFailed(sources) {
    const httpResponse$ = sources.HTTP
        .select('requestLogActivity')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.logActivityFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestCreateAdmin(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_ADD_ADMIN)
        .map(action => ({
            url: BASE_URL + 'api/people/brandAdmins?access_token=' + action.payload.accessToken,
            category: 'requestCreateAdmin',
            method: 'POST',
            send: action.payload,
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCreateAdmin')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveAddAdmin());

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestCreateAdminFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCreateAdmin')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorAddAdmin(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestSignUserOut(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.LOG_OFF)
        .map(action => ({
            url: BASE_URL + 'api/people/logout?access_token=' + action.payload.accessToken,
            category: 'requestSignUserOut',
            method: 'POST',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestSignUserOut')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.resetUser(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestSignUserOutFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestSignUserOut')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.resetUser(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestSignUserIn(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_USER)
        .map(action => ({
            url: BASE_URL + 'api/people/login',
            category: 'requestSignUserIn',
            method: 'POST',
            send: {username: action.payload.userName, password: action.payload.password},
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestSignUserIn')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveAuth(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestSignUserInDotNet(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_USER)
        .map(action => ({
            url: BASE_URL_DOTNET + 'api/people/login',
            category: 'requestSignUserInDotnet',
            method: 'POST',
            send: {username: action.payload.userName, password: action.payload.password},
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestSignUserInDotnet')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveAuthDotnet(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestSignUserInFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestSignUserIn')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToLogIn(response));

    return {
        ACTION: httpResponse$
    }
}


export function requestUpdatePassword(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.TO_UPDATE_USER_PASSWORD)
        .map(action => ({
            url: BASE_URL + 'api/people/change-password'+ '?access_token=' + action.payload.token,
            category: 'requestUpdatePassword',
            method: 'POST',
            send: action.payload.newPassword,
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestUpdatePassword')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.receiveEditPassword(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestUpdatePasswordSuccessAfter(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestUpdatePassword')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.toSignOut());

    return {
        ACTION: httpResponse$
    }
}

export function requestUpdatePasswordFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestUpdatePassword')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 204)
        .map(response => actions.receiveErrorEditPassword(response));

    return {
        ACTION: httpResponse$
    }
}