import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import AndroidNotification from "../General/androidNotification";
import IosNotification from "../General/iosNotification";
import WebNotification from "../General/webNotification";
import {useHistory} from "react-router-dom";
import CampaignOverviewTabs from "./campaignOverviewTabs";

const CampaignOverview = (props) => {
    const PlatformMapper = {
        android: AndroidNotification,
        ios: IosNotification,
        web: WebNotification,
    }
    const history = useHistory();

    const Platform = props.platformView==="android"? PlatformMapper.android: props.platformView=='ios'? PlatformMapper.ios : PlatformMapper.web
    return (
        <Paper>
            <Dialog open={props.modalState} onClose={props.toggleDeleteModal}>
                <DialogTitle>{"Delete Campaign"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the selected Campaign?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={props.toggleDeleteModal}
                        name="deleteModalYes"
                    >Yes</Button>
                    <Button
                        onClick={props.toggleDeleteModal}
                        name="deleteModal"
                        color="secondary"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
           <CampaignOverviewTabs
        //    {...props}
        setCampaignOverviewActiveTab={props.setCampaignOverviewActiveTab}
        handleViewNotification={props.handleViewNotification}
           campaignNewOverview={props.campaignNewOverview}
           userRole={props.userRole}
           toggleDeleteModal={props.toggleDeleteModal}
           toggleClientsModal={props.toggleClientsModal}
           handleActionStatusRequest={props.handleActionStatusRequest}
           handleViewNewsletter= {props.handleViewNewsletter}

           />



            <Dialog
                open={props.notificationModal}
                onClose={props.handleViewNotification}

            >
                <DialogTitle>{"Push Notification"}</DialogTitle>
                <DialogContent>
                    <Platform
                        // {...props}
                        notificationTitle={props.notificationTitle}
                        notificationText= {props.notificationText}
                        notificationImage={props.notificationImage}
                        notificationBigImage={props.notificationBigImage}
                        url={props.url}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                open={props.clientsModalState}
                onClose={props.toggleClientsModal}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>{"Client Ids in campaign"}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{wordWrap: 'break-word'}}>
                        {props.campaignNewOverview.form.listIds.size === 0 ? (
                            <Typography>Something went wrong</Typography>
                        ) : (
                            props.campaignNewOverview.form.listIds.map(
                                (user,index) => user.UserId + "," 
                            )
                            
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.toggleClientsModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );

   
};
export default CampaignOverview;
