import React, {Component, useState} from 'react';
import { connect } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';
import {toggleDrawer} from "../actions/generalActions";
import {parseGeneralReducer} from "../helpers/parseImmutables";
import classNames from 'classnames';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dashboard from '@material-ui/icons/Dashboard';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Store from '@material-ui/icons/Store';
import ViewComfy from '@material-ui/icons/ViewComfy';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Stars from '@material-ui/icons/Stars';
import People from '@material-ui/icons/People';
import Settings from '@material-ui/icons/SettingsApplications';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import images from '../assets/images/images';
import Collapse from "@material-ui/core/Collapse/Collapse";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import {useHistory} from "react-router-dom";

const LeftSidebar = props => {
    const [open, setIsOpen] = useState(false);
    const history = useHistory();

    const handleClick = () => { setIsOpen(!open) };

    const handleMenuClick = (url) => {
        history.push(url);
    };


    const { classes, theme } = props;
    return(
        <Drawer
            variant="permanent"
            classes={{
                paper: classNames(classes.drawerPaper, !props.drawer && classes.drawerPaperClose),
            }}
            open={props.drawer}
        >
            <div className={classes.toolbar}>
                <img
                    onClick={() => history.push('/')}
                    style={{cursor: 'pointer'}}
                    className={ classNames(classes.toolbarImg,'img-responsive')}
                    src={images.ciLogo} alt=""
                />
                <IconButton onClick={props.toggleDrawer}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>
            <Divider />
            <List>
                <ListItem  onClick={() => handleMenuClick('/')} button>
                    <ListItemIcon>
                        <Dashboard />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem  onClick={() => handleMenuClick('/clients')} button>
                    <ListItemIcon>
                        <People />
                    </ListItemIcon>
                    <ListItemText primary="Clients" />
                </ListItem>
                <ListItem  onClick={() => handleMenuClick('/orders')} button>
                    <ListItemIcon>
                        <ShoppingCart />
                    </ListItemIcon>
                    <ListItemText primary="Orders" />
                </ListItem>
                <ListItem  onClick={() => handleMenuClick('/enabled-offers')} button>
                    <ListItemIcon>
                        <LocalOffer />
                    </ListItemIcon>
                    <ListItemText primary="Enabled Offers" />
                </ListItem>
                <ListItem  onClick={() => handleMenuClick('/campaigns')} button >
                    <ListItemIcon>
                        <Stars />
                    </ListItemIcon>
                    <ListItemText inset primary="Campaigns" />
                </ListItem>
                {open && (
                    <Divider />
                )}
                <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                        <Settings />
                    </ListItemIcon>
                    <ListItemText inset primary="Configuration" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {props.userRole === 'admin' && (
                            <ListItem  onClick={() => handleMenuClick('/points')} button  className={classes.nested}>
                                <ListItemIcon>
                                    <Stars />
                                </ListItemIcon>
                                <ListItemText inset primary="Points" />
                            </ListItem>
                            
                        )}
                        <ListItem  onClick={() => handleMenuClick('/pointsMultiplier')} button className={classes.nested}>
                            
                            <ListItemIcon>
                                    <ControlPointIcon  style={{transform:'rotate(45deg)'}}/>
                                </ListItemIcon>
                                <ListItemText primary="Points Multiplier" />
                            </ListItem>
                        {props.userRole === 'admin' && (
                            <ListItem  onClick={() => handleMenuClick('/tellers')} button className={classes.nested}>
                                <ListItemIcon>
                                    <People />
                                </ListItemIcon>
                                <ListItemText primary="Tellers" />
                            </ListItem>
                        )}
                        {props.userRole === 'admin' && (
                            <ListItem  onClick={() => handleMenuClick('/editors')} button className={classes.nested}>
                                <ListItemIcon>
                                    <People />
                                </ListItemIcon>
                                <ListItemText primary="Editors" />
                            </ListItem>
                        )}
                        {props.userRole === 'admin' && (
                            <ListItem  onClick={() => handleMenuClick('/store')} button className={classes.nested}>
                                <ListItemIcon>
                                    <Store />
                                </ListItemIcon>
                                <ListItemText primary="Stores" />
                            </ListItem>
                        )}
                        <ListItem  onClick={() => handleMenuClick('/category')} button className={classes.nested}>
                            <ListItemIcon>
                                <ViewComfy />
                            </ListItemIcon>
                            <ListItemText primary="Categories" />
                        </ListItem>
                        {props.userRole === 'admin' && (
                            <ListItem  onClick={() => handleMenuClick('/offer')} button className={classes.nested}>
                                <ListItemIcon>
                                    <LocalOffer />
                                </ListItemIcon>
                                <ListItemText primary="Offers" />
                            </ListItem>
                        )}
                        <ListItem  onClick={() => handleMenuClick('/product')} button className={classes.nested}>
                            <ListItemIcon>
                                <ShoppingBasket />
                            </ListItemIcon>
                            <ListItemText primary="Products" />
                        </ListItem>
                        
                    </List>
                </Collapse>
            </List>
            <Divider />
        </Drawer>
    )

}

const mapStateToProps = (state) => {
    return {
        drawer: parseGeneralReducer(state.generalReducer).drawer,
        userRole: state.login.getIn(['userInfo', 'role', 'name'])
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleDrawer: (payload) => {
            dispatch(toggleDrawer(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
