import * as actions from '../actions/login';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";

//cycle that request set new password
export function setNewPassword(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_SET_NEW_PASSWORD)
        .map(action => ({
            url: BASE_URL + 'api/people/resetLostPassword?access_token=' + action.payload.resetToken,
            category: 'setNewPassword',
            method: 'POST',
            send: {newPassword: action.payload.newPassword},
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('setNewPassword')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.receiveResponseSetNewPassword(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request set new password
export function failedToSetNewPassword(sources) {
    let httpResponse$ = sources.HTTP
        .select('setNewPassword')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 204)
        .map(response => actions.failedToReceiveResponseSetNewPassword(response));

    return {
        ACTION: httpResponse$
    }
}

//cycle that request reset forgotten password
export function resetForgottenPassword(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_RESET_FORGOTTEN_PASSWORD)
        .map(action => ({
            url: BASE_URL + 'api/people/forgotPassword',
            category: 'resetForgottenPassword',
            method: 'POST',
            send: {email: action.payload},
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('resetForgottenPassword')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.receiveResetForgottenPassword(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request reset forgotten password
export function failedToResetForgottenPassword(sources) {
    let httpResponse$ = sources.HTTP
        .select('resetForgottenPassword')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 204)
        .map(response => actions.failedToReceiveResetForgottenPassword(response));

    return {
        ACTION: httpResponse$
    }
}