import React, { Component, Fragment, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import NewsletterBuilder from "./newsletterBuilder";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
  stepContent: {
    overflow: "visible",
  },
  cardWrapper: {
    overflow: "visible",
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

const EmailBuilderModal =(props) =>{

 return (

<Dialog
                open={props.modalState}

                onClose={props.toggleModalState}
                fullWidth={true}
                fullScreen={true}
              >
                <DialogTitle >{"Newsletter design "}
                </DialogTitle>
                <DialogContent  >
 

                <NewsletterBuilder 
                {...props}
                />
                </DialogContent>
                
              </Dialog>
    )
              }

export default withStyles(styles)(EmailBuilderModal);
