import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import usePrevious from "../../hooks/usePrevious";
import _ from "lodash";
import { parseCampaigns, parseCategories, parseLogin,parsePointsMultiplier, parsePointsMultiplierForm, parseProducts } from "../../helpers/parseImmutables";
import { useHistory } from "react-router-dom";
import { getTablePage, _getSkipFilter } from "../../helpers/functions";
import {
    changePointsMultiplierFormSettings,
    changePointsMultiplierFormData,
    resetMultipliersState,
    editPointMultiplierById,
  } from "../../actions/pointsMultiplierForm";
import { toGetCategories } from "../../actions/categories";
import { useParams } from "react-router-dom";
import PointsMultiplierFormHOC from "./pointsMultiplierFormHOC";
import { getProductBydId } from "../../actions/products";

import moment from "moment";



const PointsMultiplierEditHOC = (props) => {
    const {id} = useParams()
  const history = useHistory();
  

  useEffect(() => {
    props.resetMultipliersState();
    // props.getMultipliers();
    // props.getActiveMultipliers();
  //  props.toGetCategories(props.login.userInfo.brand.id, props.login.userInfo.token);
}, []);


  useEffect(()=>{
    var selectedMultiplier = props.pointsMultiplier.multipliers.find( c => c.id == id);
    if(selectedMultiplier){

    
if(selectedMultiplier.productId !=null && props.products.fetchedProduct != '')
{
  props.changePointsMultiplierFormData({
    key: 'selectedCategory',
    value: props.products.fetchedProduct.categories[0].id,
});
}
}
  },[props.products.fetchedProduct])

useEffect(() => {
    var selectedMultiplier = props.pointsMultiplier.multipliers.find( c => c.id == id);
    
    if(selectedMultiplier != null)
    {
    props.changePointsMultiplierFormData({
        key: 'title',
        value: selectedMultiplier.title,
    });
  
    props.changePointsMultiplierFormData({
        key: 'startDate',
        value: selectedMultiplier.dateFrom.split('T')[0],
    });
    props.changePointsMultiplierFormData({
        key: 'endDate',
        value: selectedMultiplier.dateTo.split('T')[0],
    });
    props.changePointsMultiplierFormData({
        key: 'startTime',
        value: moment(selectedMultiplier.timeFrom,'h:mm a').utc(true).local().format('HH:mm'),
    });
    props.changePointsMultiplierFormData({
        key: 'endTime',
        value: moment(selectedMultiplier.timeTo,'HH:mm ').utc(true).local().format('HH:mm'),
    });
    props.changePointsMultiplierFormData({
        key: 'multiplierValue',
        value: parseInt(selectedMultiplier.value),
    });

    if(selectedMultiplier.categoryId !=null)
    {
      props.changePointsMultiplierFormData({
        key: 'targetGroup',
        value: 'categoryBased',
    });
     props.changePointsMultiplierFormData({
      key: 'selectedCategory',
      value: selectedMultiplier.categoryId,
  });
    }
    
    if(selectedMultiplier.productId != null)
    {
      props.changePointsMultiplierFormData({
        key: 'targetGroup',
        value: 'productBased',
    });
  //for populating category field in useEffect
   props.getProductBydId(
      selectedMultiplier.productId,
      props.login.userInfo.token,
  )
       props.changePointsMultiplierFormData({
        key: 'selectedProduct',
        value: selectedMultiplier.productId,
    });
    }


    if(selectedMultiplier.appliedToAllProducts)
    {
      props.changePointsMultiplierFormData({
        key: 'targetGroup',
        value: 'allProducts',
    });
  }
  
  }
  }, []);

  const handleSubmit = (evt, name) => {
    var targetGroup = props.pointsMultiplierForm.formData.targetGroup;
    var multiplierBody;
    // TO ALL DAY
    if(targetGroup =='categoryBased')
    {
      multiplierBody = {
        title: props.pointsMultiplierForm.formData.title, 
        dateFrom:new Date(props.pointsMultiplierForm.formData.startDate), 
        dateTo:new Date(props.pointsMultiplierForm.formData.endDate),  
         timeFrom:moment(props.pointsMultiplierForm.formData.startTime,'h:mm a'), 
         timeTo:moment(props.pointsMultiplierForm.formData.endTime,'h:mm a'), 
         value:props.pointsMultiplierForm.formData.multiplierValue, 
         categoryId:props.pointsMultiplierForm.formData.selectedCategory, 
         productId:null,
         token:props.login.userInfo.token
     }
    }
    if(targetGroup =='productBased')
    {
      multiplierBody = {
        title: props.pointsMultiplierForm.formData.title, 
        dateFrom:new Date(props.pointsMultiplierForm.formData.startDate), 
        dateTo:new Date(props.pointsMultiplierForm.formData.endDate),  
         timeFrom:moment(props.pointsMultiplierForm.formData.startTime,'h:mm a'), 
         timeTo:moment(props.pointsMultiplierForm.formData.endTime,'h:mm a'), 
         value:props.pointsMultiplierForm.formData.multiplierValue, 
         productId:props.pointsMultiplierForm.formData.selectedProduct, 
         categoryId:null,
         token:props.login.userInfo.token
     }
    }
    if(targetGroup =='allProducts')
    {
      multiplierBody = {
        title: props.pointsMultiplierForm.formData.title, 
         dateFrom:new Date(props.pointsMultiplierForm.formData.startDate), 
         dateTo:new Date(props.pointsMultiplierForm.formData.endDate),  
         timeFrom:moment(props.pointsMultiplierForm.formData.startTime,'h:mm a'), 
         timeTo:moment(props.pointsMultiplierForm.formData.endTime,'h:mm a'), 
         value:props.pointsMultiplierForm.formData.multiplierValue, 
         productId:null,
         categoryId:null,
         appliedToAllProducts:true, 
         token:props.login.userInfo.token
     }
    }
    props.editPointMultiplierById({id:id,sendBody:multiplierBody})
  };

  return (
    <PointsMultiplierFormHOC
      handleSubmit={handleSubmit}
      isEdit={true}
      selectedMultiplierId={id}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    login: parseLogin(state.login),
    pointsMultiplierForm: parsePointsMultiplierForm(state.pointsMultiplierForm),
    pointsMultiplier: parsePointsMultiplier(state.pointsMultiplier),
    allCategories: parseCategories(state.categories).all,
    products :parseProducts(state.products)

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePointsMultiplierFormData: (key, value) => {
        dispatch(changePointsMultiplierFormData(key, value));
      },
      getProductBydId: (productId, token) => {
        dispatch(getProductBydId(productId, token));
      },
      toGetCategories: (brandId, token) => {
        dispatch(toGetCategories(brandId, token))
    },
    resetMultipliersState: (payload) => {
        dispatch(resetMultipliersState(payload));
      },
      editPointMultiplierById: (payload) => {
        dispatch(editPointMultiplierById(payload));
      },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsMultiplierEditHOC);
