import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";
import * as actions from '../actions/pointsOverview';
import sampleCombine from "xstream/extra/sampleCombine";

export function requestUpdatePointSystem(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.UPDATE_POINT_SYSTEM)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/brands/'+ action.payload.brandId +'/currentPointSystem/',
            category: 'requestUpdatePointSystem',
            method: 'PATCH',
            send: action.payload.sendBody,
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestUpdatePointSystem')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.updatePointSystemSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestUpdatePointSystemFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestUpdatePointSystem')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.updatePointSystemFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestCreatePsCombinations(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.CREATE_PS_COMBINATIONS)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.brandId +'/currentPointSystem/combinations?access_token=' + action.payload.token,
            category: 'requestCreatePsCombinations',
            method: 'POST',
            send: action.payload.combinations,
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCreatePsCombinations')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveCreatePsCombinationsResponse(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function receiveErrorCreatePsCombinations(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCreatePsCombinations')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToCreatePsCombinations(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestGetCurrentPointSystem(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CURRENT_POINT_SYSTEM)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.brandId +'/currentPointSystem?access_token=' + action.payload.token,
            category: 'requestGetCurrentPointSystem',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetCurrentPointSystem')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveCurrentPointSystem(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get current point system
export function receiveErrorGetCurrentPointSystem(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetCurrentPointSystem')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToReceiveCurrentPointSystem(response));

    return {
        ACTION: httpResponse$
    }
}