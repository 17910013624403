import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import {changeLoginForm, resetLoginState, userAuth} from '../actions/login';
import  InputComp  from '../components/InputComp';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import {parseLogin} from "../helpers/parseImmutables";
import CardHeader from '@material-ui/core/CardHeader';
import {useHistory} from "react-router-dom";

const LoginContainer = props => {
    const history = useHistory();

    useEffect(() => {
        return () => {
            props.resetLoginState();
        }
    }, [])

    const HandleSubmit = (evt) => {
        evt.preventDefault();
        props.userLogIn(props.login.username, props.login.password)
    };

    const HandleInputChange = (evt) => {
        props.changeInfo(evt.target.name, evt.target.value)
    };

    const handleForgotPassword = (url) => {
        history.push(url)
    }

    const style = {
        container: {
            height: '100vh',
        },
        refresh: {
            position: 'fixed',
            top: '80%',
            left: '50%',
            transform: 'translateX(-50%)'
        },
    };

    return (
        <div style={style.container}>
            <div className="container d-flex h-100">
                <div className="row justify-content-center align-self-center  ml-auto mr-auto">
                    <Card className="p-3">
                        <CardHeader
                            title="Log In"
                            subheader={'Use your Administrator account'}
                        />
                        <CardContent>
                            <form id="loginForm"  role="form" onSubmit={HandleSubmit}>
                                <InputComp Required={true} Type="text" Label="Username"
                                           fullWidth={true} margin={'normal'}
                                           Value={props.login.username}
                                           Name="username" PropHandleInputChange={HandleInputChange}/>
                                <InputComp Required={true} fullWidth={true} margin={'normal'}
                                           Type="password" Label="Password" Value={props.login.password}
                                           Name="password" PropHandleInputChange={HandleInputChange}/>

                            </form>
                        </CardContent>
                        <CardActions>
                            <Button
                                color="primary"
                                variant="contained"
                                form="loginForm"
                                type="submit">Sign in</Button>
                        </CardActions>
                    </Card>
                </div>
                <Snackbar
                    open={props.login.loginResponse?true:false}
                    message={props.login.loginResponse}
                    autoHideDuration={30000}
                    onClose={() => {props.changeInfo('loginResponse', '')}}
                />
            </div>
            {
                props.login.isFetching?
                    <CircularProgress
                        size={40}
                        status="loading"
                        style={style.refresh}
                    />
                    :
                    null
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
        router: state.router
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeInfo: (key, value) => {
            dispatch(changeLoginForm(key, value))
        },
        userLogIn: (userName, password) => {
            dispatch(userAuth(userName, password))
        },
        resetLoginState: (payload) => {
            dispatch(resetLoginState(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
