import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment";
import Colors from "../../helpers/constants/colors";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {withStyles} from "@material-ui/core/styles";
import _ from "lodash";
import {dateTypeMapper} from "../../helpers/functions";

const getData = (props) => {
    return  _.zipWith(props.clientStatistics, props.clientCompareStatistics, (p, c) => ({
        total: p?p.total: 0,
        totalDeleted: p?p.totalDeleted: 0,
        date: p?moment(p.date).format():null,
        compareTotal: c?c.total:0,
        compareTotalDeleted: c?c.totalDeleted:0,
        compareDate: c?moment(c.date).format():null
    }));
};

const CustomToolTip = (props) => {
    try {
        return (
            <div className={'recharts-tooltip-wrapper'}>
                <div className={'custom-tooltip'}>
                    {(props.active && props.payload) && (
                        <div>
                            <div>
                                <p className={'label'}>{`date: ${moment(props.payload[0].payload.date).format('YYYY/MM/DD HH:mm')}`}</p>
                                <p className={'intro'} style={{color: props.payload[0].color}}>{`new: ${props.payload[0].payload.total}`}</p>
                                <p className={'intro'} style={{color: props.payload[1].color}}>{`deleted: ${props.payload[1].payload.totalDeleted}`}</p>
                            </div>
                            {props.compare && (
                                <div>
                                    <p className={'label'}>{`compared date: ${moment(props.payload[0].payload.compareDate).format('YYYY/MM/DD HH:mm')}`}</p>
                                    <p className={'intro'} style={{color: props.payload[2].stroke}}>{`compared new: ${props.payload[2].payload.compareTotal}`}</p>
                                    <p className={'intro'} style={{color: props.payload[3].stroke}}>{`compared deleted: ${props.payload[3].payload.compareTotalDeleted}`}</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>

            </div>
        )
    } catch (e) {
        return null
    }
};

const ClientsChart  = (props) => {
    const { classes } = props;
    const data = getData(props);
    return (
        <Card className={classes.card}>
            <CardContent>
                <ResponsiveContainer  width={'100%'} height={300}>
                    <LineChart data={data}>
                        <XAxis
                            dataKey="date"
                            tickFormatter={(tick) => {return dateTypeMapper(tick, props.dateType)}}
                        />
                        <YAxis label={{ value: "Clients", angle: -90, position: 'insideLeft' }} />
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip content={<CustomToolTip compare={props.compare}/>}/>
                        <Legend />
                        <Line isAnimationActive={false} dataKey="total" name={'New'} stroke={Colors.COFFEE_BROWN} />
                        <Line isAnimationActive={false} dataKey="totalDeleted" name={'Deleted'} stroke={Colors.VALIDATION_RED} />
                        {(props.clientCompareStatistics.length > 0) && (
                            <Line isAnimationActive={false} dataKey="compareTotal" name={'Compare New'} stroke={Colors.COFFEE_BROWN} strokeDasharray="3 4 5 2"/>
                        )}
                        {(props.clientCompareStatistics.length > 0) && (
                            <Line isAnimationActive={false} dataKey="compareTotalDeleted" name={'Compare Deleted'} stroke={Colors.VALIDATION_RED} strokeDasharray="3 4 5 2"/>
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default withStyles({})(ClientsChart);