import React  from 'react';
import TableComponent from "../General/table";
import {AddListContentButton} from "../General/addListContentButton";
import MapWithAMarkerHOC from "../../containers/General/mapWithAMarkerHOC";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Store from "@material-ui/icons/Store";
import IconButton from "@material-ui/core/IconButton";
import TableMUI from '@material-ui/core/Table/Table';
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {useHistory} from "react-router-dom";

const StoreList = (props) => {
    const history = useHistory();
    return(
        <div className="brand">
            <Card>
                <CardHeader
                    avatar={<IconButton>
                        <Store />
                    </IconButton>}
                    title="Brand information"
                />
                <CardContent>
                    <div className="col col-lg-4">
                        <TableMUI>
                            <TableBody>
                                <TableRow>
                                    <TableCell component={'th'} scope={'row'}>
                                        {'name'}
                                    </TableCell>
                                    <TableCell>
                                        {props.brand.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={'th'} scope={'row'}>
                                        {'description'}
                                    </TableCell>
                                    <TableCell>
                                        {props.brand.description}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={'th'} scope={'row'}>
                                        {'telephone'}
                                    </TableCell>
                                    <TableCell>
                                        {props.brand.telephone}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={'th'} scope={'row'}>
                                        {'email'}
                                    </TableCell>
                                    <TableCell>
                                        {props.brand.email}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </TableMUI>
                    </div>
                </CardContent>
            </Card>
            <Card>
                <CardHeader
                    avatar={<IconButton>
                        <Store />
                    </IconButton>}
                    title="Brand Stores"
                />
                <CardContent>
                    <div className={'mt-3'}>
                        <TableComponent
                            handleRowSelect={props.handleRowSelect}
                            label="Stores"
                            columns={props.tableColumns}
                            rows={props.stores}

                            selectable={false}
                            fixedHeader={true}
                            multiSelectable={false}

                            displaySelectAll={false}
                            showCheckboxes={false}
                            showRowHover={true}
                            clickableRows={true}
                            page={props.page}
                            rowsPerPage={props.rowsPerPage}
                            handleRowsPerPage={props.handleRowsPerPage}
                            handlePageChange={props.handlePageChange}
                            totalRows={props.totalRows}
                        />
                    </div>
                </CardContent>
            </Card>
            <Card>
                <CardHeader
                    avatar={<IconButton>
                        <Store />
                    </IconButton>}
                    title="Brand Stores location"
                />
                <CardContent>
                    <MapWithAMarkerHOC
                        multipleMarkers={true}
                        multipleMarkersPosition={props.storesPositions}
                        handleCoordinates={() => null}
                        markerPosition={{lat: '', lng: ''}}
                        onDragEnd={() => null}
                    />

                </CardContent>
            </Card>
            <AddListContentButton onClick={() =>history.push('/store/add')}/>
        </div>

    )
};


export default StoreList;
