import React from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from "@material-ui/core/FormHelperText";

const RenderSelectField = ({
                               input,
                               label,
                               children,
                               meta: { touched, error },
                               ...custom
                           }) => (
    <FormControl fullWidth={custom.fullWidth} required={custom.required}>
        <InputLabel >{label}</InputLabel>
        <Select
            error={!!(touched && error)}
            {...input}
            {...custom}
        >
            {children}
        </Select>
        {(touched && error) && (
            <FormHelperText >{error}</FormHelperText>
        )}
    </FormControl>
);

export default RenderSelectField;