import React, {useState} from 'react';
import Paper from "@material-ui/core/Paper";
import { withStyles } from '@material-ui/core/styles';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableMUI from "@material-ui/core/Table";
import moment from "moment";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Indicator from "../General/indicator";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import {isAdmin} from "../../helpers/functions";
import {useHistory} from "react-router-dom";

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing.unit * 3,
    }),
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    button: {
        margin: theme.spacing.unit,
    },
    list: {
        maxHeight: 50 * 2,
        overflowY: 'auto'
    },

});

const EnabledOffer = props => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const history = useHistory();

    const openDeleteModal = () => {setDeleteModalOpen(true)};


    const closeDeleteModal = () => {setDeleteModalOpen(false)};

    const { classes, enabledOffer} = props;

    return (
        <Paper className={classes.root} elevation={4}>
            <Dialog
                open={deleteModalOpen}
                onClose={closeDeleteModal}
            >
                <DialogTitle>{"Delete Client"}</DialogTitle>
                <DialogContent>
                    {props.deletingClient && (
                        <Indicator isVisible={true}/>
                    )}
                    <DialogContentText >
                        Are you sure you want to delete the selected enabled offer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={() => {
                        props.deleteEnabledOffer({enabledOfferId: props.enabledOffer.id})
                    }} color="primary" autoFocus>
                        Delete
                    </Button>
                    <Button onClick={closeDeleteModal}
                            color="secondary">Cancel</Button>
                </DialogActions>
            </Dialog>
            <div className={'row'}>
                <div className={'col-12 col-lg-4'}>
                    <TableMUI>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {'Coupon code'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {enabledOffer.couponCode}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {'Coupon start date'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {enabledOffer.couponStartDate?
                                        moment(enabledOffer.couponStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                                        : ''
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {'Coupon end date'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {enabledOffer.couponEndDate?
                                        moment(enabledOffer.couponEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
                                        :''
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {'Issue date'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {enabledOffer.issueDate?
                                        moment(enabledOffer.issueDate).format('DD/MM/YYYY')
                                        :''
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {'Expired'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {enabledOffer.expired?'true':'false'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {'Redeemed'}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {enabledOffer.redeemed?'true':'false'}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        <div className={'mt-3'}>
                            <Button
                                onClick={() => history.goBack()}
                                color="primary"
                                variant="contained"
                            >{'Go back'}</Button>
                            {isAdmin(props.useRole) && (
                                <Button
                                    onClick={openDeleteModal}
                                    className={'ml-3'}
                                    color="secondary"
                                    variant="contained"
                                >{'Delete'}</Button>
                            )}
                        </div>
                    </TableMUI>
                </div>
            </div>
            <Snackbar
                open={!!(props.deleteEnabledOfferResponse)}
                message={props.deleteEnabledOfferResponse}
                autoHideDuration={6000}
                onClose={() => {props.changeClientOverviewStoreSettings('deleteEnabledOfferResponse', '')}}
            />
        </Paper>
    )

};

export default withStyles(styles)(EnabledOffer);
