//editors
export const CHANGE_EDITORS_ORDER = 'CHANGE_EDITORS_ORDER';
export const CHANGE_EDITORS_STATE = 'CHANGE_EDITORS_STATE';
export const COUNT_EDITORS = 'COUNT_EDITORS';
export const COUNT_EDITORS_SUCCESS = 'COUNT_EDITORS_SUCCESS';
export const COUNT_EDITORS_FAILED = 'COUNT_EDITORS_FAILED';
export const GET_EDITORS = 'GET_EDITORS';
export const GET_EDITORS_SUCCESS = 'GET_EDITORS_SUCCESS';
export const GET_EDITORS_FAILED = 'GET_EDITORS_FAILED';

//editor
export const UPDATE_EDITOR = 'UPDATE_EDITOR';
export const UPDATE_EDITOR_SUCCESS = 'UPDATE_EDITOR_SUCCESS';
export const UPDATE_EDITOR_FAILED = 'UPDATE_EDITOR_FAILED';
export const GET_EDITOR = 'GET_EDITOR';
export const GET_EDITOR_SUCCESS = 'GET_EDITOR_SUCCESS';
export const GET_EDITOR_FAILED = 'GET_EDITOR_FAILED';
export const CHANGE_EDITOR_STATE = 'CHANGE_EDITOR_STATE';
export const CREATE_EDITOR = 'CREATE_EDITOR';
export const CREATE_EDITOR_SUCCESS = 'CREATE_EDITOR_SUCCESS';
export const CREATE_EDITOR_FAILED = 'CREATE_EDITOR_FAILED';
export const RESET_EDITOR_STATE = 'RESET_EDITOR_STATE';

//statistics
export const CLEAR_STATISTICS_COMPARE_VALUES= 'CLEAR_STATISTICS_COMPARE_VALUES';
export const GET_ENABLED_OFFERS_COMPARE_STATISTICS= 'GET_ENABLED_OFFERS_COMPARE_STATISTICS';
export const GET_ENABLED_OFFERS_COMPARE_STATISTICS_SUCCESS = 'GET_ENABLED_OFFERS_COMPARE_STATISTICS_SUCCESS';
export const GET_ENABLED_OFFERS_COMPARE_STATISTICS_FAILED = 'GET_ENABLED_OFFERS_COMPARE_STATISTICS_FAILED';
export const GET_ORDERS_COMPARE_STATISTICS = 'GET_ORDERS_COMPARE_STATISTICS';
export const GET_ORDERS_COMPARE_STATISTICS_SUCCESS = 'GET_ORDERS_COMPARE_STATISTICS_SUCCESS';
export const GET_ORDERS_COMPARE_STATISTICS_FAILED = 'GET_ORDERS_COMPARE_STATISTICS_FAILED';
export const GET_CLIENT_COMPARE_STATISTICS = 'GET_CLIENT_COMPARE_STATISTICS';
export const GET_CLIENT_COMPARE_STATISTICS_SUCCESS = 'GET_CLIENT_COMPARE_STATISTICS_SUCCESS';
export const GET_CLIENT_COMPARE_STATISTICS_FAILED = 'GET_CLIENT_COMPARE_STATISTICS_FAILED';
export const GET_POINTS_COMPARE_STATISTICS = 'GET_POINTS_COMPARE_STATISTICS';
export const GET_POINTS_COMPARE_STATISTICS_SUCCESS = 'GET_POINTS_COMPARE_STATISTICS_SUCCESS';
export const GET_POINTS_COMPARE_STATISTICS_FAILED = 'GET_POINTS_COMPARE_STATISTICS_FAILED';
export const GET_XP_ORDERS_STATISTICS = 'GET_XP_ORDERS_STATISTICS';
export const GET_XP_ORDERS_STATISTICS_SUCCESS = 'GET_XP_ORDERS_STATISTICS_SUCCESS';
export const GET_XP_ORDERS_STATISTICS_FAILED = 'GET_XP_ORDERS_STATISTICS_FAILED';
export const GET_CLIENT_STATISTICS = 'GET_CLIENT_STATISTICS';
export const GET_CLIENT_STATISTICS_SUCCESS = 'GET_CLIENT_STATISTICS_SUCCESS';
export const GET_CLIENT_STATISTICS_FAILED = 'GET_CLIENT_STATISTICS_FAILED';
export const GET_ENABLED_OFFERS_STATISTICS = 'GET_ENABLED_OFFERS_STATISTICS';
export const GET_ENABLED_OFFERS_STATISTICS_SUCCESS = 'GET_ENABLED_OFFERS_STATISTICS_SUCCESS';
export const GET_ENABLED_OFFERS_STATISTICS_FAILED = 'GET_ENABLED_OFFERS_STATISTICS_FAILED';
export const GET_POINTS_STATISTICS = 'GET_POINTS_STATISTICS';
export const GET_POINTS_STATISTICS_SUCCESS = 'GET_POINTS_STATISTICS_SUCCESS';
export const GET_POINTS_STATISTICS_FAILED = 'GET_POINTS_STATISTICS_FAILED';
export const GET_ORDERS_STATISTICS = 'GET_ORDERS_STATISTICS';
export const GET_ORDERS_STATISTICS_SUCCESS = 'GET_ORDERS_STATISTICS_SUCCESS';
export const GET_ORDERS_STATISTICS_FAILED = 'GET_ORDERS_STATISTICS_FAILED';

//enabled offers
export const COUNT_ENABLED_OFFERS = 'COUNT_ENABLED_OFFERS';
export const COUNT_ENABLED_OFFERS_SUCCESS = 'COUNT_ENABLED_OFFERS_SUCCESS';
export const COUNT_ENABLED_OFFERS_FAILED = 'COUNT_ENABLED_OFFERS_FAILED';
export const CHANGE_ENABLED_OFFERS_ORDER = 'CHANGE_ENABLED_OFFERS_ORDER';
export const CHANGE_ENABLED_OFFERS_STATE = 'CHANGE_ENABLED_OFFERS_STATE';
export const GET_ENABLED_OFFERS = 'GET_ENABLED_OFFERS';
export const GET_ENABLED_OFFERS_SUCCESS = 'GET_ENABLED_OFFERS_SUCCESS';
export const GET_ENABLED_OFFERS_FAILED = 'GET_ENABLED_OFFERS_FAILED';
export const RESET_ENABLED_OFFERS_STATE = 'RESET_ENABLED_OFFERS_STATE';

//people
export const CHANGE_PEOPLE_STATE = 'CHANGE_PEOPLE_STATE';
export const GET_PEOPLE_SUGGESTIONS = 'GET_PEOPLE_SUGGESTIONS';
export const GET_PEOPLE_SUGGESTIONS_SUCCESS = 'GET_PEOPLE_SUGGESTIONS_SUCCESS';
export const GET_PEOPLE_SUGGESTIONS_FAILED = 'GET_PEOPLE_SUGGESTIONS_FAILED';

//tellerOverview
export const SET_TELLER_OVERVIEW_ACTIVE_TAB = 'SET_TELLER_OVERVIEW_ACTIVE_TAB';
export const RESET_TELLER_OVERVIEW_STATE = 'RESET_TELLER_OVERVIEW_STATE';

//clientForm
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAIL = 'CREATE_CLIENT_FAIL';
export const CHANGE_CLIENT_FORM_SETTINGS = 'CHANGE_CLIENT_FORM_SETTINGS';
export const CHANGE_CLIENT_FORM_DATA = 'CHANGE_CLIENT_FORM_DATA';
//pointOverview
export const UPDATE_POINT_SYSTEM = 'UPDATE_POINT_SYSTEM';
export const UPDATE_POINT_SYSTEM_SUCCESS = 'UPDATE_POINT_SYSTEM_SUCCESS';
export const UPDATE_POINT_SYSTEM_FAILED = 'UPDATE_POINT_SYSTEM_FAILED';
export const SET_POINTS_OVERVIEW_ACTIVE_TAB = 'SET_POINTS_OVERVIEW_ACTIVE_TAB';
export const CREATE_PS_COMBINATIONS = 'CREATE_PS_COMBINATIONS';
export const RECEIVE_CREATE_PS_COMBINATIONS_RESPONSE = 'RECEIVE_CREATE_PS_COMBINATIONS_RESPONSE';
export const FAILED_TO_CREATE_PS_COMBINATIONS = 'FAILED_TO_CREATE_PS_COMBINATIONS';
export const CHANGE_POINT_OVERVIEW_STORE_SETTINGS = 'CHANGE_POINT_OVERVIEW_STORE_SETTINGS';
export const CHANGE_EXTRA_COMBINATIONS_SETTINGS = 'CHANGE_EXTRA_COMBINATIONS_SETTINGS';
export const GET_CURRENT_POINT_SYSTEM = 'GET_CURRENT_POINT_SYSTEM';
export const RECEIVE_CURRENT_POINT_SYSTEM = 'RECEIVE_CURRENT_POINT_SYSTEM';
export const FAILED_TO_RECEIVE_CURRENT_POINT_SYSTEM = 'FAILED_TO_RECEIVE_CURRENT_POINT_SYSTEM';
export const RESET_POINTS_OVERVIEW_STATE = 'RESET_POINTS_OVERVIEW_STATE';
//pointsForm
export const CHANGE_COMBINATIONS_SETTINGS = 'CHANGE_COMBINATIONS_SETTINGS';
export const RESET_POINT_FORM_STATE = 'RESET_POINT_FORM_STATE';
export const CHANGE_THE_COMBINATIONS_OF_PRODUCTS = 'CHANGE_THE_COMBINATIONS_OF_PRODUCTS';
export const FAILED_TO_RECEIVE_CREATE_POINT_SYSTEM = 'FAILED_TO_RECEIVE_CREATE_POINT_SYSTEM';
export const RECEIVE_CREATE_POINT_SYSTEM_RESPONSE = 'RECEIVE_CREATE_POINT_SYSTEM_RESPONSE';
export const CREATE_POINT_SYSTEM = 'CREATE_POINT_SYSTEM';
export const CHANGE_POINTS_FORM_SETTINGS = 'CHANGE_POINTS_FORM_SETTINGS';
export const CHANGE_LEVEL_FORM = 'CHANGE_LEVEL_FORM';

//orderForm
export const CHANGE_ORDER_FORM_STORE_SETTINGS = 'CHANGE_ORDER_FORM_STORE_SETTINGS';
export const CHANGE_ORDER_ITEMS = 'CHANGE_ORDER_ITEMS';

//clientOverview
export const DELETE_ENABLED_OFFER = 'DELETE_ENABLED_OFFER';
export const DELETE_ENABLED_OFFER_SUCCESS = 'DELETE_ENABLED_OFFER_SUCCESS';
export const DELETE_ENABLED_OFFER_FAILED = 'DELETE_ENABLED_OFFER_FAILED';
export const GET_CLIENT_ENABLED_OFFER_BY_ID = 'GET_CLIENT_ENABLED_OFFER_BY_ID';
export const GET_CLIENT_ENABLED_OFFER_BY_ID_SUCCESS = 'GET_CLIENT_ENABLED_OFFER_BY_ID_SUCCESS';
export const GET_CLIENT_ENABLED_OFFER_BY_ID_FAILED = 'GET_CLIENT_ENABLED_OFFER_BY_ID_FAILED';
export const CHANGE_CLIENTS_ORDERS_ORDER = 'CHANGE_CLIENTS_ORDERS_ORDER';
export const GET_CLIENT_ENABLED_OFFERS = 'GET_CLIENT_ENABLED_OFFERS';
export const GET_CLIENT_ENABLED_OFFERS_SUCCESS = 'GET_CLIENT_ENABLED_OFFERS_SUCCESS';
export const GET_CLIENT_ENABLED_OFFERS_FAILED = 'GET_CLIENT_ENABLED_OFFERS_FAILED';
export const COUNT_CLIENT_ORDERS = 'COUNT_CLIENT_ORDERS';
export const COUNT_CLIENT_ORDERS_SUCCESS = 'COUNT_CLIENT_ORDERS_SUCCESS';
export const COUNT_CLIENT_ORDERS_FAIL = 'COUNT_CLIENT_ORDERS_FAIL';
export const GET_CLIENT_ORDERS = 'GET_CLIENT_ORDERS';
export const GET_CLIENT_ORDERS_SUCCESS = 'GET_CLIENT_ORDERS_SUCCESS';
export const GET_CLIENT_ORDERS_FAIL = 'GET_CLIENT_ORDERS_FAIL';
export const GIVE_CLIENT_EXTRA_POINTS = 'GIVE_CLIENT_EXTRA_POINTS';
export const GIVE_CLIENT_EXTRA_POINTS_SUCCESS = 'GIVE_CLIENT_EXTRA_POINTS_SUCCESS';
export const GIVE_CLIENT_EXTRA_POINTS_FAIL = 'GIVE_CLIENT_EXTRA_POINTS_FAIL';
export const SET_CLIENT_OVERVIEW_ACTIVE_TAB = 'SET_CLIENT_OVERVIEW_ACTIVE_TAB';
export const CHANGE_CLIENT_OVERVIEW_STORE_SETTINGS = 'CHANGE_CLIENT_OVERVIEW_STORE_SETTINGS';
export const RESET_CLIENT_OVERVIEW_STATE = 'RESET_CLIENT_OVERVIEW_STATE';
//orderOverview
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const OPEN_ORDER_DELETE_MODAL = 'OPEN_ORDER_DELETE_MODAL';
export const CLOSE_ORDER_DELETE_MODAL = 'CLOSE_ORDER_DELETE_MODAL';
export const CHANGE_ORDER_OVERVIEW_STORE_SETTINGS = 'CHANGE_ORDER_OVERVIEW_STORE_SETTINGS';
export const RESET_ORDER_OVERVIEW_STATE = 'RESET_ORDER_OVERVIEW_STATE';

//orders
export const COUNT_XP_ORDERS = 'COUNT_XP_ORDERS';
export const COUNT_XP_ORDERS_SUCCESS = 'COUNT_XP_ORDERS_SUCCESS';
export const COUNT_XP_ORDERS_FAILED = 'COUNT_XP_ORDERS_FAILED';
export const CHANGE_ORDERS_SORT_ORDER = 'CHANGE_ORDERS_SORT_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL';
export const FAILED_TO_CREATE_NEW_ORDER = 'FAILED_TO_CREATE_NEW_ORDER';
export const RECEIVE_CREATE_NEW_ORDER_RESPONSE = 'RECEIVE_CREATE_NEW_ORDER_RESPONSE';
export const CREATE_NEW_ORDER = 'CREATE_NEW_ORDER';
export const CHANGE_ORDERS_STORE_SETTINGS = 'CHANGE_ORDERS_STORE_SETTINGS';
export const GET_TOTAL_ORDERS = 'GET_TOTAL_ORDERS';
export const RECEIVE_TOTAL_ORDERS = 'RECEIVE_TOTAL_ORDERS';
export const FAILED_TO_GET_TOTAL_ORDERS = 'FAILED_TO_GET_TOTAL_ORDERS';
export const FAILED_TO_RECEIVE_ORDER_BY_ID = 'FAILED_TO_RECEIVE_ORDER_BY_ID';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const RECEIVE_ORDER_BY_ID = 'RECEIVE_ORDER_BY_ID';
export const GET_ORDERS = 'GET_ORDERS';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const FAILED_TO_GET_ORDERS = 'FAILED_TO_GET_ORDERS';
export const RESET_ORDERS_STATE = 'RESET_ORDERS_STATE';
//tellers
export const COUNT_TELLERS = 'COUNT_TELLERS';
export const COUNT_TELLERS_SUCCESS = 'COUNT_TELLERS_SUCCESS';
export const COUNT_TELLERS_FAIL = 'COUNT_TELLERS_FAIL';
export const GET_TELLER_BY_ID = 'GET_TELLER_BY_ID';
export const RECEIVE_TELLER_BY_ID = 'RECEIVE_TELLER_BY_ID';
export const FAILED_TO_RECEIVE_TELLER_BY_ID = 'FAILED_TO_RECEIVE_TELLER_BY_ID';
export const CHANGE_TELLERS_STORE_SETTINGS = 'CHANGE_TELLERS_STORE_SETTINGS';
export const GET_TELLERS = 'GET_TELLERS';
export const RECEIVE_TELLERS = 'RECEIVE_TELLERS';
export const FAILED_TO_RECEIVE_TELLERS = 'FAILED_TO_RECEIVE_TELLERS';
export const RESET_TELLERS_STATE = 'RESET_TELLERS_STATE';

//login
export const LOG_ACTIVITY = 'LOG_ACTIVITY';
export const LOG_ACTIVITY_SUCCESS = 'LOG_ACTIVITY_SUCCESS';
export const LOG_ACTIVITY_FAILED = 'LOG_ACTIVITY_FAILED';
export const TO_SIGN_OUT = 'TO_SIGN_OUT';
export const SIGN_USER_IN = 'SIGN_USER_IN';
export const TO_UPDATE_USER_PASSWORD = 'TO_UPDATE_USER_PASSWORD';
export const CHANGE_STATE = 'CHANGE_STATE';
export const REQUEST_USER = 'REQUEST_USER';
// export const REQUEST_USER_DOTNET = 'REQUEST_USER_DOTNET';
export const RECEIVE_AUTH = 'RECEIVE_AUTH';
export const RECEIVE_AUTH_DOTNET = 'RECEIVE_AUTH_DOTNET';
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';
export const RESET_USER = 'RESET_USER';
export const TEMP_NEW_ADMIN = 'TEMP_NEW_ADMIN';
export const REQUEST_ADD_ADMIN = 'REQUEST_ADD_ADMIN';
export const RECEIVE_ADD_ADMIN = 'RECEIVE_ADD_ADMIN';
export const FAILED_TO_RECEIVE_RESPONSE_SET_NEW_PASSWORD = 'FAILED_TO_RECEIVE_RESPONSE_SET_NEW_PASSWORD';
export const RECEIVE_RESPONSE_SET_NEW_PASSWORD = 'RECEIVE_RESPONSE_SET_NEW_PASSWORD';
export const REQUEST_SET_NEW_PASSWORD = 'REQUEST_SET_NEW_PASSWORD';
export const FAILED_TO_RECEIVE_FORGOTTEN_PASSWORD = 'FAILED_TO_RECEIVE_FORGOTTEN_PASSWORD';
export const RECEIVE_RESET_FORGOTTEN_PASSWORD = 'RECEIVE_RESET_FORGOTTEN_PASSWORD';
export const REQUEST_RESET_FORGOTTEN_PASSWORD = 'REQUEST_RESET_FORGOTTEN_PASSWORD';
export const CHANGE_FORGOT_PASSWORD_FORM_SETTINGS = 'CHANGE_FORGOT_PASSWORD_FORM_SETTINGS';
export const CHANGE_PASSWORD_RESET_FORM_SETTINGS = 'CHANGE_PASSWORD_RESET_FORM_SETTINGS';
export const CREATE_TELLER = 'CREATE_TELLER';
export const RECEIVE_CREATE_TELLER_RESPONSE = 'RECEIVE_CREATE_TELLER_RESPONSE';
export const FAILED_TO_CREATE_TELLER = 'FAILED_TO_CREATE_TELLER';
export const HANDLE_TELLER_FORM = 'HANDLE_TELLER_FORM';
export const RECEIVE_ERROR_ADD_ADMIN = 'RECEIVE_ERROR_ADD_ADMIN';
export const LOG_OFF = 'LOG_OFF';
export const FAILED_TO_LOG_IN = 'FAILED_TO_LOG_IN';
export const EDIT_USER_PASSWORD = 'EDIT_USER_PASSWORD';
export const RECEIVE_EDIT_PASSWORD = 'RECEIVE_EDIT_PASSWORD';
export const RECEIVE_ERROR_EDIT_PASSWORD = 'RECEIVE_ERROR_EDIT_PASSWORD';
export const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE';
//discounts
export const GET_DISCOUNTS = 'GET_DISCOUNTS';
export const RECEIVE_DISCOUNTS = 'RECEIVE_DISCOUNTS';
export const FAILED_TO_GET_DISCOUNTS = 'FAILED_TO_GET_DISCOUNTS';
//stores
export const ADD_NEW_STORE = 'ADD_NEW_STORE';
export const TEMP_NEW_STORE = 'TEMP_NEW_STORE';
export const REQUEST_ALL_STORES = 'REQUEST_ALL_STORES';
export const RECEIVE_ALL_STORES = 'RECEIVE_ALL_STORES';
export const CHANGE_SELECTED_STORE = 'CHANGE_SELECTED_STORE';
export const SHOW_STORE = 'SHOW_STORE';
export const SUBMIT_EXISTING_STORE = 'SUBMIT_EXISTING_STORE';
export const RECEIVE_ADD_STORE = 'RECEIVE_ADD_STORE';
export const RECEIVE_EDIT_STORE = 'RECEIVE_EDIT_STORE';
export const RESET_DEFAULT_STORE = 'RESET_DEFAULT';
export const REQUEST_DELETE_STORE = 'REQUEST_DELETE_STORE';
export const RECEIVE_DELETE_STORE = 'RECEIVE_DELETE_STORE';
export const CHANGE_STORE_SETTINGS = 'CHANGE_STORE_SETTINGS';
export const GET_TOTAL_STORES = 'GET_TOTAL_STORES';
export const RECEIVE_TOTAL_STORES = 'RECEIVE_TOTAL_STORES';
export const FAILED_TO_GET_TOTAL_STORES = 'FAILED_TO_GET_TOTAL_STORES';
export const RECEIVE_ERROR_DELETE_STORE = 'RECEIVE_ERROR_DELETE_STORE';
export const RECEIVE_ERROR_EDIT_STORE = 'RECEIVE_ERROR_EDIT_STORE';
export const RECEIVE_ERROR_ADD_STORE = 'RECEIVE_ERROR_ADD_STORE';
export const REQUEST_STORE_BY_ID = 'REQUEST_STORE_BY_ID';
export const RECEIVE_STORE_BY_ID = 'RECEIVE_STORE_BY_ID';
export const FAILED_TO_GET_STORE_BY_ID = 'FAILED_TO_GET_STORE_BY_ID';
export const RECEIVE_ERROR_GET_STORES = 'RECEIVE_ERROR_GET_STORES';
export const RESET_STORES_STATE = 'RESET_STORES_STATE';

//products
export const REQUEST_ALL_PRODUCTS = 'REQUEST_ALL_PRODUCTS';
export const RECEIVE_ALL_PRODUCTS = 'RECEIVE_ALL_PRODUCTS';
export const REQUEST_ADD_PRODUCT = 'REQUEST_ADD_PRODUCT';
export const RECEIVE_ADD_PRODUCT = 'RECEIVE_ADD_PRODUCT';
export const REQUEST_DELETE_PRODUCT = 'REQUEST_DELETE_PRODUCT';
export const RECEIVE_DELETE_PRODUCT = 'RECEIVE_DELETE_PRODUCT';
export const SHOW_EDIT_PRODUCT = 'SHOW_EDIT_PRODUCT';
export const REQUEST_EDIT_PRODUCT = 'REQUEST_EDIT_PRODUCT';
export const RECEIVE_EDIT_PRODUCT = 'RECEIVE_EDIT_PRODUCT';
export const EDIT_NEW_PRODUCT = 'EDIT_NEW_PRODUCT';
export const EDIT_STORED_PRODUCT = 'EDIT_STORED_PRODUCT';
export const RESET_DEFAULT_PRODUCT_AND_CATEGORY = 'RESET_DEFAULT_PRODUCT_AND_CATEGORY';
export const CHANGE_PRODUCT_FORM_SETTINGS = 'CHANGE_PRODUCT_FORM_SETTINGS';
export const COUNT_PRODUCTS = 'COUNT_PRODUCTS';
export const COUNT_PRODUCTS_SUCCESS = 'COUNT_PRODUCTS_SUCCESS';
export const COUNT_PRODUCTS_FAIL = 'COUNT_PRODUCTS_FAIL';
export const FAILED_TO_BULK_UPDATE_PRODUCTS = 'FAILED_TO_BULK_UPDATE_PRODUCTS';
export const RECEIVE_BULK_UPDATE_PRODUCTS = 'RECEIVE_BULK_UPDATE_PRODUCTS';
export const BULK_UPDATE_PRODUCTS = 'BULK_UPDATE_PRODUCTS';
export const RECEIVE_ERROR_DELETE_PRODUCT = 'RECEIVE_ERROR_DELETE_PRODUCT';
export const RECEIVE_ERROR_EDIT_PRODUCT = 'RECEIVE_ERROR_EDIT_PRODUCT';
export const RECEIVE_ERROR_ADD_PRODUCT = 'RECEIVE_ERROR_ADD_PRODUCT';
export const RECEIVE_ERROR_GET_PRODUCTS = 'RECEIVE_ERROR_GET_PRODUCTS';
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID';
export const RECEIVE_PRODUCT_BY_ID = 'RECEIVE_PRODUCT_BY_ID';
export const FAILED_TO_RECEIVE_PRODUCT_BY_ID = 'FAILED_TO_RECEIVE_PRODUCT_BY_ID';
export const RESET_PRODUCTS_STATE = 'RESET_PRODUCTS_STATE';
//categories
export const REQUEST_ALL_CATEGORIES = 'REQUEST_ALL_CATEGORIES';
export const RECEIVE_ALL_CATEGORIES = 'RECEIVE_ALL_CATEGORIES ';
export const EDIT_NEW_CATEGORY = 'EDIT_NEW_CATEGORY';
export const EDIT_STORED_CATEGORY = 'EDIT_STORED_CATEGORY';
export const REQUEST_ADD_CATEGORY = 'REQUEST_ADD_CATEGORY';
export const RECEIVE_ADD_CATEGORY = 'RECEIVE_ADD_CATEGORY';
export const REQUEST_EDIT_CATEGORY = 'REQUEST_EDIT_CATEGORY';
export const RECEIVE_EDIT_CATEGORY = 'RECEIVE_EDIT_CATEGORY';
export const SHOW_EDIT_CATEGORY = 'SHOW_EDIT_CATEGORY';
export const REQUEST_DELETE_CATEGORY = 'REQUEST_DELETE_CATEGORY';
export const RECEIVE_DELETE_CATEGORY = 'RECEIVE_DELETE_CATEGORY';
export const CHANGE_CATEGORY_SETTINGS = 'CHANGE_CATEGORY_SETTINGS';
export const COUNT_CATEGORIES = 'COUNT_CATEGORIES';
export const COUNT_CATEGORIES_SUCCESS =  'COUNT_CATEGORIES_SUCCESS';
export const COUNT_CATEGORIES_FAIL = 'COUNT_CATEGORIES_FAIL';
export const RECEIVE_ERROR_DELETE_CATEGORY = 'RECEIVE_ERROR_DELETE_CATEGORY';
export const RESET_CATEGORIES_STATE = 'RESET_CATEGORIES_STATE';
export const RECEIVE_ERROR_EDIT_CATEGORY = 'RECEIVE_ERROR_EDIT_CATEGORY';
export const RECEIVE_ERROR_ADD_CATEGORY = 'RECEIVE_ERROR_ADD_CATEGORY';
export const RECEIVE_ERROR_GET_CATEGORIES = 'RECEIVE_ERROR_GET_CATEGORIES';
export const GET_CATEGORY_BY_ID = 'GET_CATEGORY_BY_ID';
export const RECEIVE_CATEGORY_BY_ID = 'RECEIVE_CATEGORY_BY_ID';
export const FAILED_TO_GET_CATEGORY_BY_ID = 'FAILED_TO_GET_CATEGORY_BY_ID';
//offers
export const TEMP_NEW_OFFER = 'TEMP_NEW_OFFER';
export const REQUEST_ALL_OFFERS = 'REQUEST_ALL_OFFERS';
export const RECEIVE_ALL_OFFERS = 'RECEIVE_ALL_OFFERS';
export const TO_CHANGE_OFFER = 'TO_CHANGE_OFFER';
export const SUBMIT_CHANGED_OFFER = 'SUBMIT_CHANGED_OFFER';
export const TEMP_CHANGED_OFFER = 'TEMP_CHANGED_OFFER';
export const SUBMIT_NEW_OFFER = 'SUBMIT_NEW_OFFER';
export const RECEIVE_ADD_OFFER = 'RECEIVE_ADD_OFFER';
export const RECEIVE_EDIT_OFFER = 'RECEIVE_EDIT_OFFER';
export const RESET_DEFAULT_OFFER = 'RESET_DEFAULT';
export const REQUEST_DELETE_OFFER = 'REQUEST_DELETE_OFFER';
export const RECEIVE_DELETE_OFFER = 'RECEIVE_DELETE_OFFER ';
export const CHANGE_OFFER_SETTINGS = 'CHANGE_OFFER_SETTINGS';
export const COUNT_OFFERS = 'COUNT_OFFERS';
export const COUNT_OFFERS_SUCCESS = 'COUNT_OFFERS_SUCCESS';
export const COUNT_OFFERS_FAIL = 'COUNT_OFFERS_FAIL';
export const RECEIVE_ERROR_DELETE_OFFER = 'RECEIVE_ERROR_DELETE_OFFER';
export const RECEIVE_ERROR_EDIT_OFFER = 'RECEIVE_ERROR_EDIT_OFFER';
export const RECEIVE_ERROR_ADD_OFFER = 'RECEIVE_ERROR_ADD_OFFER';
export const RECEIVE_ERROR_GET_OFFERS = 'RECEIVE_ERROR_GET_OFFERS';
export const GET_OFFER_BY_ID = 'GET_OFFER_BY_ID';
export const RECEIVE_OFFER_BY_ID = 'RECEIVE_OFFER_BY_ID';
export const FAILED_TO_GET_OFFER_BY_ID = 'FAILED_TO_GET_OFFER_BY_ID';
export const RESET_OFFERS_STATE = 'RESET_OFFERS_STATE';
//clients
export const DELETE_CLIENT_BY_ID = 'DELETE_CLIENT_BY_ID';
export const DELETE_CLIENT_BY_ID_SUCCESS = 'DELETE_CLIENT_BY_ID_SUCCESS';
export const DELETE_CLIENT_BY_ID_FAILED = 'DELETE_CLIENT_BY_ID_FAILED';
export const SELECT_CLIENT_SUGGESTION = 'SELECT_CLIENT_SUGGESTION';
export const GET_CLIENTS_SUGGESTIONS = 'GET_CLIENTS_SUGGESTIONS';
export const GET_CLIENTS_SUGGESTIONS_SUCCESS = 'GET_CLIENTS_SUGGESTIONS_SUCCESS';
export const GET_CLIENTS_SUGGESTIONS_FAILED = 'GET_CLIENTS_SUGGESTIONS_FAILED';
export const CHANGE_CLIENTS_ORDER = 'CHANGE_CLIENTS_ORDER';
export const CHANGE_CLIENT_STORE_SETTINGS = 'CHANGE_CLIENT_STORE_SETTINGS';
export const GET_TOTAL_CLIENTS = 'GET_TOTAL_CLIENTS';
export const RECEIVE_TOTAL_CLIENTS = 'RECEIVE_TOTAL_CLIENTS';
export const FAILED_TO_GET_TOTAL_CLIENTS = 'FAILED_TO_GET_TOTAL_CLIENTS';
export const FAILED_TO_GET_CLIENT_BY_ID = 'FAILED_TO_GET_CLIENT_BY_ID';
export const RECEIVE_CLIENT_BY_ID = 'RECEIVE_CLIENT_BY_ID';
export const GET_CLIENT_BY_ID = 'GET_CLIENT_BY_ID';
export const GET_CLIENTS = 'GET_CLIENTS';
export const RECEIVE_CLIENTS = 'RECEIVE_CLIENTS';
export const FAILED_TO_GET_CLIENTS = 'FAILED_TO_GET_CLIENTS';
export const GET_CAMPAIGN_PLAN_CLIENT_BY_ID = 'GET_CAMPAIGN_PLAN_CLIENT_BY_ID';
export const RECEIVE_CAMPAIGN_PLAN_CLIENT_BY_ID = 'RECEIVE_CAMPAIGN_PLAN_CLIENT_BY_ID';
export const FAILED_TO_GET_CAMPAIGN_PLAN_CLIENT_BY_ID = 'FAILED_TO_GET_CAMPAIGN_PLAN_CLIENT_BY_ID';
export const RESET_CLIENTS_STATE = 'RESET_CLIENTS_STATE';

//GeneralActions
export const RESET_STATE = 'RESET_STATE';
export const GET_TOTAL_PROFIT = 'GET_TOTAL_PROFIT';
export const RECEIVE_TOTAL_PROFIT = 'RECEIVE_TOTAL_PROFIT';
export const FAILED_TO_GET_TOTAL_PROFIT = 'FAILED_TO_GET_TOTAL_PROFIT';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';



//NewCampaigns
export const GET_TOTAL_CAMPAIGNS = 'GET_TOTAL_CAMPAIGNS';
export const RECEIVE_TOTAL_CAMPAIGNS = 'RECEIVE_TOTAL_CAMPAIGNS';//count
export const FAILED_TO_GET_TOTAL_CAMPAIGNS = 'FAILED_TO_GET_TOTAL_CAMPAIGNS';
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAIL = 'CREATE_CAMPAIGN_FAIL';
export const CHANGE_CAMPAIGN_FORM_SETTINGS = 'CHANGE_CAMPAIGN_FORM_SETTINGS';
export const CHANGE_CAMPAIGN_FORM_DATA ='CHANGE_CAMPAIGN_FORM_DATA';
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const RECEIVE_CAMPAIGNS = 'RECEIVE_CAMPAIGNS';
export const CHANGE_CAMPAIGN_STORE_SETTINGS = 'CHANGE_CAMPAIGN_STORE_SETTINGS';
export const FAILED_TO_GET_CAMPAIGNS = 'FAILED_TO_GET_CAMPAIGNS';
export const RESET_CAMPAIGNS_NEW_STATE = 'RESET_CAMPAIGNS_NEW_STATE';
export const GET_CAMPAIGN_BY_ID = 'GET_CAMPAIGN_BY_ID';
export const RECEIVE_CAMPAIGN_BY_ID = 'RECEIVE_CAMPAIGN_BY_ID';
export const FAILED_TO_GET_CAMPAIGN_BY_ID = 'FAILED_TO_GET_CAMPAIGN_BY_ID';
export const DELETE_CAMPAIGN_BY_ID = 'DELETE_CAMPAIGN_BY_ID';
export const DELETE_CAMPAIGN_BY_ID_SUCCESS = 'DELETE_CAMPAIGN_BY_ID_SUCCESS';
export const DELETE_CAMPAIGN_BY_ID_FAILED = 'DELETE_CAMPAIGN_BY_ID_FAILED';
export const SET_CAMPAIGN_OVERVIEW_ACTIVE_TAB = 'SET_CAMPAIGN_OVERVIEW_ACTIVE_TAB';
export const GET_CAMPAIGN_PLAN_BY_ID = 'GET_CAMPAIGN_PLAN_BY_ID';
export const RECEIVE_CAMPAIGN_PLAN_BY_ID = 'RECEIVE_CAMPAIGN_PLAN_BY_ID';
export const FAILED_TO_GET_CAMPAIGN_PLAN_BY_ID = 'FAILED_TO_GET_CAMPAIGN_PLAN_BY_ID';
export const OVERVIEW_RESET_STATE = 'OVERVIEW_RESET_STATE';
export const EDIT_CAMPAIGN= 'EDIT_CAMPAIGN';
export const EDIT_CAMPAIGN_SUCCESS= 'EDIT_CAMPAIGN_SUCCESS';
export const EDIT_CAMPAIGN_FAIL= 'EDIT_CAMPAIGN_FAIL';
export const GET_ACTION_STATUS_BY_ID = 'GET_ACTION_STATUS_BY_ID';
export const RECEIVE_ACTION_STATUS_BY_ID ='RECEIVE_ACTION_STATUS_BY_ID';
export const FAILED_TO_GET_ACTION_STATUS_BY_ID = 'FAILED_TO_GET_ACTION_STATUS_BY_ID';
//ACTION AND EVENTS
export const GET_ACTIONS= 'GET_ACTIONS';
export const GET_EVENTS= 'GET_EVENTS';
export const CREATE_ACTION ='CREATE_ACTION';
export const CREATE_ACTION_SUCCESS = 'CREATE_ACTION_SUCCESS';
export const CREATE_ACTION_FAIL = 'CREATE_ACTION_FAIL';
export const RECEIVE_ACTIONS = 'RECEIVE_ACTIONS';
export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
export const GET_ACTION_BY_ID = 'GET_ACTION_BY_ID';
export const RECEIVE_ACTION_BY_ID ='RECEIVE_ACTION_BY_ID';
export const GET_ACTION_BY_ID_FAILED='GET_ACTION_BY_ID_FAILED';
export const FAILED_TO_GET_ACTIONS_EVENTS = 'FAILED_TO_GET_ACTIONS_EVENTS'
export const EDIT_ACTION = 'EDIT_ACTION';
export const EDIT_ACTION_SUCCESS = 'EDIT_ACTION_SUCCESS';
export const EDIT_ACTION_FAIL = 'EDIT_ACTION_FAIL';
export const RESET_CAMPAIGNS_NEW_FORM_STATE = 'RESET_CAMPAIGNS_NEW_FORM_STATE';
export const DELETE_ACTION_BY_ID='DELETE_ACTION_BY_ID';
export const DELETE_ACTION_BY_ID_SUCCESS = 'DELETE_ACTION_BY_ID_SUCCESS';
export const DELETE_ACTION_BY_ID_FAILED = 'DELETE_ACTION_BY_ID_FAILED';
export const SEND_TEST_EMAIL = 'SEND_TEST_EMAIL';
export const SEND_TEST_EMAIL_SUCCESS = 'SEND_TEST_EMAIL_SUCCESS';
export const SEND_TEST_EMAIL_FAIL = 'SEND_TEST_EMAIL_FAIL';

export const GET_MULTIPLIERS_COUNT = 'GET_MULTIPLIERS_COUNT';
export const RECEIVE_MULTIPLIERS_COUNT = 'RECEIVE_MULTIPLIERS_COUNT';
export const FAILED_TO_GET_MULTIPLIERS_COUNT = 'FAILED_TO_GET_MULTIPLIERS_COUNT';
export const GET_MULTIPLIERS = 'GET_MULTIPLIERS';
export const RESET_MULTIPLIERS_NEW_STATE = 'RESET_MULTIPLIERS_NEW_STATE';
export const RECEIVE_MULTIPLIERS = 'RECEIVE_MULTIPLIERS';
export const FAILED_TO_GET_MULTIPLIERS = 'FAILED_TO_GET_MULTIPLIERS';
export const CHANGE_POINTS_MULTIPLIER_STORE_SETTINGS = 'CHANGE_POINTS_MULTIPLIER_STORE_SETTINGS';

export const CHANGE_POINT_MULTIPLIER_FORM_DATA = 'CHANGE_POINT_MULTIPLIER_FORM_DATA';
export const CREATE_POINTS_MULTIPLIER = 'CREATE_POINTS_MULTIPLIER';
export const CREATE_POINTS_MULTIPLIER_SUCCESS = 'CREATE_POINTS_MULTIPLIER_SUCCESS';
export const CREATE_POINTS_MULTIPLIER_FAIL = 'CREATE_POINTS_MULTIPLIER_FAIL';
export const CHANGE_POINTS_MULTIPLIER_FORM_SETTINGS = 'CHANGE_POINTS_MULTIPLIER_FORM_SETTINGS';
export const DELETE_POINT_MULTIPLIER = 'DELETE_POINT_MULTIPLIER';
export const DELETE_POINT_MULTIPLIER_SUCCESS = 'DELETE_POINT_MULTIPLIER_SUCCESS';
export const DELETE_POINT_MULTIPLIER_FAIL = 'DELETE_POINT_MULTIPLIER_FAIL';
export const EDIT_POINT_MULTIPLIER = 'EDIT_POINT_MULTIPLIER';
export const EDIT_POINT_MULTIPLIER_SUCCESS = 'EDIT_POINT_MULTIPLIER_SUCCESS';
export const EDIT_POINT_MULTIPLIER_FAIL = 'EDIT_POINT_MULTIPLIER_FAIL';
export const GET_ACTIVE_MULTIPLIERS = 'GET_ACTIVE_MULTIPLIERS';
export const RECEIVE_ACTIVE_MULTIPLIERS = 'RECEIVE_ACTIVE_MULTIPLIERS';
export const FAILED_TO_GET_ACTIVE_MULTIPLIERS = 'FAILED_TO_GET_ACTIVE_MULTIPLIERS';