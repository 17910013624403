import * as actions from '../actions/campaignsNew';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL,BASE_URL_DOTNET} from "../helpers/variables";
import sampleCombine from "xstream/extra/sampleCombine";


export function getCampaigns(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CAMPAIGNS)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/campaigns' +
            (action.payload?
                '?filter[skip]=' + action.payload.filter.skip:'?filter[skip]=0')
                +
            (action.payload?
                '&filter[limit]=' + action.payload.filter.limit:'&filter[limit]=10')
               +
            (action.payload.filter.where?
                '&filter[where]=' + action.payload.filter.where:'&filter[where]=')
            ,
            
            category: 'getCampaigns',
            method: 'GET',
            withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));
        

    let httpResponse$ = sources.HTTP
        .select('getCampaigns')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveCampaigns(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function failedGetCampaigns(sources) {
    let httpResponse$ = sources.HTTP
        .select('getCampaigns')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetCampaigns(response));

    return {
        ACTION: httpResponse$
    }
}


export function getTotalCampaigns(sources){
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token_dotnet']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_TOTAL_CAMPAIGNS)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL_DOTNET + 'api/campaigns/count' ,
            category: 'getTotalCampaigns',
            method: 'GET',
            withCredentials: true,
            headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('getTotalCampaigns')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveTotalCampaigns(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function failedGetTotalCampaigns(sources) {
    let httpResponse$ = sources.HTTP
        .select('getTotalCampaigns')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetTotalCampaigns(response));

    return {
        ACTION: httpResponse$
    }
}