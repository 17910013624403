import React, {Component} from 'react';
import MapSearchBox from "../../components/General/mapSearchBox";

const  MapSearchBoxHOC = props => {
    return (
        <MapSearchBox
            margin={props.margin}
            fullWidth={props.fullWidth}
            box={props.box}
            handleInputChange={props.handleInputChange}
            refs={props.refs}
            onPlacesChanged={props.onPlacesChanged}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC2HVuMFUP1m-0YxdgCHZE70CYAzVFXBIs&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={ <div style={{ height: `400px` }} />}
        />
    )
}



export default MapSearchBoxHOC;

