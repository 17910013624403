import * as ActionTypes from '../actions/index';
import { fromJS } from 'immutable';
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    fetching: false,
    tellers: [],
    rowsPerPage: 10,
    page: 1,
    totalTellers: '',
    teller: {
        id: '',
        username: '',
        email: '',
        stores: []
    },
    fetchingTeller: false,
    tellerStoresPage: 1,
    tellerStoresRowsPerPage: 5,
    fetchingCountTellers: false
});

function countTellers(state, action) {
    return state
        .set('fetchingCountTellers', true);
}

function countTellersSuccess(state, action) {
    return state
        .set('fetchingCountTellers', false)
        .set('totalTellers', action.payload.body.count);
}

function countTellersFail(state, action) {
    return state
        .set('fetchingCountTellers', false);
}

function getTelleById(state, action) {
    return state
        .set('fetchingTeller', true);
}

function receiveTellerById(state, action) {
    let teller = {};
    try {
        teller = action.payload.body
    }
    catch (e) {
        return state;
    }
    return state
        .set('fetchingTeller', false)
        .setIn(['teller', 'id'], teller.id)
        .setIn(['teller', 'username'], teller.username)
        .setIn(['teller', 'email'], teller.email)
        .setIn(['teller', 'stores'], fromJS(teller.stores));
}

function failedToReceiveTellerById(state, action) {
    return state
        .set('fetchingTeller', false);
}

function changeTellersStoreSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function getTellers(state, action) {
    return state
        .set('fetching', true);
}

function receiveTellers(state, action) {
    return state
        .set('fetching', false)
        .set('tellers', fromJS(action.payload.body));
}

function failedToReceiveTellers(state, action) {
    return state
        .set('fetching', false);
}

function resetState(state, action) {
    return defaultState;
}

export const tellers = createReducer(defaultState, {
    [ActionTypes.COUNT_TELLERS]: countTellers ,
    [ActionTypes.COUNT_TELLERS_SUCCESS]: countTellersSuccess ,
    [ActionTypes.COUNT_TELLERS_FAIL]: countTellersFail ,
    [ActionTypes.GET_TELLER_BY_ID]: getTelleById ,
    [ActionTypes.RECEIVE_TELLER_BY_ID]: receiveTellerById ,
    [ActionTypes.FAILED_TO_RECEIVE_TELLER_BY_ID]: failedToReceiveTellerById ,
    [ActionTypes.CHANGE_TELLERS_STORE_SETTINGS]: changeTellersStoreSettings ,
    [ActionTypes.GET_TELLERS]: getTellers ,
    [ActionTypes.RECEIVE_TELLERS]: receiveTellers ,
    [ActionTypes.FAILED_TO_RECEIVE_TELLERS]: failedToReceiveTellers ,
    [ActionTypes.LOG_OFF]: resetState ,
    [ActionTypes.RESET_TELLERS_STATE]: resetState,
    [ActionTypes.RESET_TELLER_OVERVIEW_STATE]: resetState
});