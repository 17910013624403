import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function countEnabledOffers(payload) {
    return {
        type: ActionTypes.COUNT_ENABLED_OFFERS,
        payload: payload
    }
}

export function countEnabledOffersSuccess(payload) {
    return {
        type: ActionTypes.COUNT_ENABLED_OFFERS_SUCCESS,
        payload: payload
    }
}

export function countEnabledOffersFailed(payload) {
    return {
        type: ActionTypes.COUNT_ENABLED_OFFERS_FAILED,
        payload: payload
    }
}

export function changeEnabledOffersOrder(payload) {
    return {
        type: ActionTypes.CHANGE_ENABLED_OFFERS_ORDER,
        payload: payload
    }
}

export function changeEnabledOffersState(payload) {
    return {
        type: ActionTypes.CHANGE_ENABLED_OFFERS_STATE,
        payload: payload
    }
}

export function getEnabledOffers(payload) {
    return {
        type: ActionTypes.GET_ENABLED_OFFERS,
        payload: payload
    }
}

export function getEnabledOffersSuccess(payload) {
    return {
        type: ActionTypes.GET_ENABLED_OFFERS_SUCCESS,
        payload: payload
    }
}

export function getEnabledOffersFailed(payload) {
    return {
        type: ActionTypes.GET_ENABLED_OFFERS_FAILED,
        payload: payload
    }
}

export const resetEnabledOffersState = actionCreator(ActionTypes.RESET_ENABLED_OFFERS_STATE);