import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import React, { Component, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import Radio from '@material-ui/core/Radio';
import CropLandscapeIcon from '@material-ui/icons/CropLandscape';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import CropFreeIcon from '@material-ui/icons/CropFree';
const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  
});
const ImageCropper = (props) => {
    const [srcState, setSrcState] = useState( {
    src: null,
    fileType:"",
  });
    const [cropState, setCropState] = useState( {
    crop: {
      unit: '%',
      width: 30,
       aspect: null
    }
  });
  const [croppedImageUrl, setcroppedImageUrl] = useState( {
  });

  const onSelectFile = (event) => {
    if(props.bigImage===true)
    {
      setCropState({...cropState,crop:{aspect:2/1}})
    }
    else if (props.bigImage===false)
    {
      setCropState({...cropState,crop:{aspect:1/1}})

    }
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (event.target.files[0] === undefined) {

      }
      else if (event.target.files[0].size > 1048576) {
        alert("Image size is too big")
      }
      else {
        reader.readAsDataURL(event.target.files[0]);
      }
      reader.addEventListener('load', () =>{
      setSrcState({ ...srcState,src: reader.result,fileType: reader.result.split("/")[1].split(";")[0]})
     
      }
      );
      // reader.readAsDataURL(event.target.files[0]);
     
    })
 
  }
  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    this.imageRef = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCropState({ ...cropState,crop:crop });
  };

  const makeClientCrop = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      setcroppedImageUrl({ ...croppedImageUrl,croppedImageUrl });
    }
  }

  const getCroppedImg=(image, crop, fileName)=> {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          if(srcState.fileType=="jpeg")
          {
            resolve(canvas.toDataURL("image/jpeg"));
          }
          else{
          resolve(canvas.toDataURL()); 
        }
        },
      );


    });
  }
  
  const handleAspectRatioChange = (event) => {

    setCropState({...cropState,crop:{aspect:event.target.value}});
  
  };



  const handleSave=(isCancel)=>
  {
    if(!props.bigImage && croppedImageUrl.croppedImageUrl && !isCancel ){

   
    props.changeCampaignFormData({
              key: "notificationImage",
              value:croppedImageUrl.croppedImageUrl.split(",")[1],
            }),
            props.changeCampaignFormData({
          key: "notificationImageType",
              value:croppedImageUrl.croppedImageUrl.split("/")[1].split(";")[0],
        })
       }
       else if(props.bigImage && croppedImageUrl.croppedImageUrl && !isCancel ){

   
        props.changeCampaignFormData({
                  key: "notificationBigImage",
                  value:croppedImageUrl.croppedImageUrl.split(",")[1],
                }),
                props.changeCampaignFormData({
              key: "notificationBigImageType",
                  value:croppedImageUrl.croppedImageUrl.split("/")[1].split(";")[0],
            })
           }
          //  else{

          //  }
           setSrcState({src:null});
           setcroppedImageUrl({ ...croppedImageUrl,croppedImageUrl:"" });
           props.toggleCropModal();
           
  }
    return (

      <Dialog
                open={props.cropModal}
                // onClose={ props.toggleCropModal}
                fullWidth={true}
                maxWidth="md"
              >
                <DialogTitle>{"Image picker"}</DialogTitle>
                <DialogContent>

                <div style={{display:'flex'}}>
 
                <Typography
                          variant="subheading"
                          style={{ marginLeft: 40 ,marginBottom:20}}
                        >
                          Choose {props.bigImage===false?"icon":"big"} image <br></br>
                          <Button
                            variant="contained"
                            color="default"
                            style={{ padding: 0, maxWidth: 180, maxHeight: 55,cursor: "pointer", }}
                          > <CloudUploadIcon style={{marginRight:-15,paddingLeft:5}}/>
                            Upload image <br></br> (1 mb)
                           
                            <input
                              name={props.bigImage===false?"notificationImage":"notificationBigImage"}
                              type="file"
                              id={props.bigImage===false?"notificationImage":"notificationBigImage"}
                              accept="image/png, image/jpeg"
                              onChange={onSelectFile}
                                style={{
                                opacity: 0,
                                marginLeft: -160,
                                width: 180,
                                height: 55,
                                cursor: "pointer",
                              }}
                            />
                          </Button> 
                          </Typography>



                          {srcState.src?
            
             <div style={{marginLeft:50}}>
               <Typography style={{padding:5}}><AspectRatioIcon/> Recommended: Icon = 1/1 Big Image = 2/1</Typography>
               <div style={{display:'flex'}}>
               <Typography><CropSquareIcon/></Typography>
               <Radio checked={cropState.crop.aspect==1/1} value={1/1} onChange={(event)=>handleAspectRatioChange(event)}  />
               <Typography><CropLandscapeIcon/></Typography>
               <Radio checked={cropState.crop.aspect==2/1} value={2/1} onChange={(event)=>handleAspectRatioChange(event)}/>
               <Typography><CropFreeIcon/></Typography>
               <Radio checked={cropState.crop.aspect!=2/1 && cropState.crop.aspect!=1/1} value={null} onChange={(event)=>handleAspectRatioChange(event)}/>
               </div>
          </div>
             :null}
             </div>

       <div style={{display:'flex',alignItems:'baseline'}}>
           {srcState.src && (
             <div>
            <Typography>Image</Typography>
          <ReactCrop
            src={srcState.src}
            crop={cropState.crop}
            ruleOfThirds
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
            style={{ maxWidth: 400 }}
          />
          </div>
        )}
        {croppedImageUrl.croppedImageUrl && (
<div>
<Typography> Cropped Image</Typography>
          
          <img alt="Crop" style={{ marginLeft:50,maxWidth: 400}} src={croppedImageUrl.croppedImageUrl} />
       </div> 
       )}
          </div>
        <div style={{display:'flex'}}>
     
       </div>
      </DialogContent>
      <DialogActions>
      <Button color='secondary' onClick={()=>handleSave(true)}>Cancel</Button>
      <Button onClick={()=>handleSave(false)} 
      style={{backgroundColor:'#F1CB42'}}>Crop and Save</Button>
      
      </DialogActions>
      </Dialog>
    );
  }

export default withStyles(styles)(ImageCropper);;

