import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import OfferList from "../../components/Offers/offerList";
import {
    changeOfferSettings,
    countOffers,
    gettingAllOffers,
    resetOffersState,
    toChangeOffer
} from "../../actions/offers";
import _ from 'lodash';
import {toGetCategories} from "../../actions/categories";
import moment from 'moment';
import {_getSkipFilter, getTablePage} from "../../helpers/functions";
import {parseCategories, parseLogin, parseOffers} from "../../helpers/parseImmutables";
import usePrevious from "../../hooks/usePrevious";
import {useHistory} from "react-router-dom";

const OfferListHOC = props => {
    const prevPage = usePrevious(props.offers.page);
    const prevRowsPerPage = usePrevious(props.offers.rowsPerPage);
    const history = useHistory();

    useEffect(() => {
        return () => {
            props.resetOffersState();
        }
    }, [])

    useEffect(() => {
        props.countOffers({
            accessToken: props.login.userInfo.token,
            brandId: props.login.userInfo.brand.id
        });
    },[]);

    useEffect(() => {
        if(
            props.offers.page !== prevPage
            || props.offers.rowsPerPage !== prevRowsPerPage
        ) {
            props.getOffers(props.login.userInfo.brand.id, props.login.userInfo.token,
                {skip: _getSkipFilter(props.offers.page, props.offers.rowsPerPage), limit: props.offers.rowsPerPage});
        }
    }, [
        prevPage,
        prevRowsPerPage,
        props.offers.page,
        props.offers.rowsPerPage,
    ])

    const getOfferArray = () => {
        let arr = [];
        let allOffers = [...props.offers.all];
        _.forEach(allOffers, offer => {
            arr.push(
                {
                    id: offer.id,
                    data: [
                        offer.name,
                        moment(offer.startDate).isValid()?moment(offer.startDate).format('DD/MM/YYYY'):'',
                        moment(offer.endDate).isValid()?moment(offer.endDate).format('DD/MM/YYYY'):'',
                        offer.pointsRequired,
                        offer.discountType?offer.discountType.name:'',
                        offer.discount,
                        // props.categories.all[offer.category].catName,
                        offer.promoted? 'True':'False',

                    ],
                    label: offer.name
                }
            )
        });
        return arr;
    };

    const handleRowSelect = (offerId) => {
        if (props.offers.all[offerId]) {
            props.toggleOffer(offerId, props.offers.all[offerId]);
        }
        else {
            props.toggleOffer(offerId, props.offers.changedOffer);
        }
        history.push('/offer/edit/' + offerId)
    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.offers.totalOffers / rowsPerPage);
        let currentPage = props.offers.page;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeOfferSettings('rowsPerPage', rowsPerPage);
        props.changeOfferSettings('page', currentPage);
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.offers.page,  props.offers.totalOffers, props.offers.rowsPerPage);
        props.changeOfferSettings('page', newPage);
    };

    let tableColumns = [
        'offer name',
        'start date',
        'end date',
        'points required',
        'discount type',
        'discount',
        'promoted',
    ];

    return (
        <OfferList
            page={props.offers.page}
            totalRows={props.offers.totalOffers}
            handlePageChange={handlePageChange}
            rowsPerPage={props.offers.rowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            push={props.push}
            isFetching={props.offers.isFetching}
            handleRowSelect={handleRowSelect}
            tableColumns={tableColumns}
            offers={getOfferArray()}/>
    )
}

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
        offers: parseOffers(state.offers),
        categories: parseCategories(state.categories)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOffers: (brandId, token, filter) => {
            dispatch(gettingAllOffers(brandId, token, filter))
        },
        getAllCategories: () => {
            dispatch(toGetCategories())
        },
        toggleOffer: (selectedOffer, updatedOfferObj) => {
            dispatch(toChangeOffer(selectedOffer, updatedOfferObj))
        },
        changeOfferSettings: (key, value) => {
            dispatch(changeOfferSettings(key, value))
        },
        countOffers: (payload) => {
            dispatch(countOffers(payload))
        },
        resetOffersState: (payload) => {
            dispatch(resetOffersState(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferListHOC);

