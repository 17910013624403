import React from 'react';
import SelectComp from "../SelectComp";
import TableComponent from "../General/table";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from "@material-ui/core/IconButton";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Button from "@material-ui/core/Button";
import Snackbar from '@material-ui/core/Snackbar';
import ListSuggestions from "../General/listSuggestions";
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';

const styles = theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
    },
    stepContent: {
        overflow: 'visible'
    },
    cardWrapper: {
        overflow: 'visible'
    },
    chip: {
        margin: theme.spacing.unit,
    },
});

const OrderForm = (props) => {
    const { classes } = props;

    return (
        <Card className={classes.cardWrapper}>
            <CardHeader
                avatar={
                    <IconButton>
                        <ShoppingCart />
                    </IconButton>
                }
                title="Orders form"
            />
            <CardContent>
                <div className={classes.root}>
                    <Stepper activeStep={props.orderForm.currentStep} orientation="vertical">
                        <Step >
                            <StepLabel>Select User</StepLabel>
                            <StepContent classes={{transition: classes.stepContent}}>
                                <div className={'col-12'}>
                                    <ListSuggestions
                                        placehodler={'Quick search users by email'}
                                        handleFilterTextChange={props._handleFilterFieldChange}
                                        value={props.orderForm.userEmail}
                                        suggestionSelectedHandler={props.suggestionSelectedHandler}
                                        suggestions={props.peopleSuggestions.map(s => ({
                                            label: s.email,
                                            suggestion: s
                                        }))}
                                        onSuggestionsClearRequested={props.onSuggestionsClearRequested}
                                        requestListSuggestions={props._getPeopleSuggestions}
                                    />
                                </div>
                                <div className={'col-12 mt-3'}>
                                    {
                                        props.orderForm.fetchedUser.id?
                                            <Chip
                                                icon={<FaceIcon />}
                                                label={props.orderForm.fetchedUser.email}
                                                onDelete={props._removeFetchedSuggestionUser}
                                                className={classes.chip}
                                            />
                                            :
                                            null
                                    }
                                </div>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>Select products</StepLabel>
                            <StepContent>
                                <div className={'col col-md-4'}>
                                    <form className={'form-horizontal'}>
                                        <div className={'form-group'}>
                                            <SelectComp Required={true} MenuName="Select categories" TargetObj={props.orderForm.selectedCategories} Name="selectedCategories"
                                                        multiple={true}
                                                        fullWidth={true}
                                                        Label="Categories" Selected={props.orderForm.selectedCategories} Values={props.categories.map(item => {return {key:item.id, value: item.name}})}
                                                        PropHandleInputChange={props._handleSelectCategories}/>
                                        </div>
                                        <div className={'form-group'}>
                                            <SelectComp Required={true} MenuName="Select products" TargetObj={props.orderForm.selectedProducts} Name="selectedProducts"
                                                        multiple={true}
                                                        fullWidth={true}
                                                        Label="Products" Selected={props.orderForm.selectedProducts} Values={props.products.map(item => {return {key:item.id, value: item.name}})}
                                                        PropHandleInputChange={props._handleSelectProducts}/>
                                        </div>
                                    </form>
                                </div>
                                {
                                    props.orderForm.selectedProducts.length > 0?
                                        <TableComponent
                                            handleRowSelect={() => null}
                                            label="Products"
                                            columns={props.productsTableColumns}
                                            rows={props.productsTablesRows}
                                            fixedHeader={true}
                                            selectable={false}
                                            multiSelectable={false}
                                            displaySelectAll={false}
                                            showCheckboxes={false}
                                            showRowHover={true}
                                            hidePagination={true}
                                        />
                                        :
                                        null
                                }
                            </StepContent>
                        </Step>
                    </Stepper>
                </div>
            </CardContent>
            <CardActions>
                <Button
                    onClick={props.orderForm.currentStep === 1?props._handleSubmit :props.goNext}
                    color="primary"
                    variant="contained"
                >{props.orderForm.currentStep === 1 ? 'Finish' : 'Next'}</Button>
                {props.orderForm.currentStep > 0 && (
                    <Button
                        disabled={props.orderForm.currentStep === 0}
                        onClick={props.goBack}
                        color="primary"
                        variant="flat"
                    >{'Back'}</Button>
                )}
            </CardActions>
            <Snackbar
                open={!!props.orderForm.errorMessage}
                message={props.orderForm.errorMessage}
                autoHideDuration={6000}
                onClose={() => {props._changeStoreSettings('errorMessage', '')}}
            />
        </Card>
    )
};

export default withStyles(styles)(OrderForm);
