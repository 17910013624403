import React  from 'react';
import InputComp from '../InputComp';
import CategoryDeleteModal from "./categoryDeleteModal";
import Snackbar from '@material-ui/core/Snackbar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ViewComfy from "@material-ui/icons/ViewComfy";

const CategoryFormComponent = (props) => {
    let formClasses = 'form-horizontal';
    return (
        <div>
            {
                props.categories.isDeleteModalOpen && (
                    <CategoryDeleteModal {...props} />
                )
            }
            <Card>
                <CardHeader
                    avatar={
                        <IconButton>
                            <ViewComfy />
                        </IconButton>
                    }
                    title={props.isNew?'New Category':'Edit Category'}
                />
                <CardContent>
                    <div className="col col-lg-4 offset-lg-3 ">
                        <form id="form-category" className={formClasses} onSubmit={props.handleSubmit}>
                            <InputComp Required={true} Placeholder="Category Name" Type="text"
                                       fullWidth={true} margin={'normal'}
                                       Value={props.category.catName} Name="catName" Label="Name"
                                       PropHandleInputChange={props.handleInputChange}/>
                        </form>
                    </div>
                </CardContent>
                <CardActions>
                    <Button
                        color="primary"
                        variant="contained"
                        form="form-category"
                        type="submit">{props.isNew?'Create':'Edit'}</Button>
                    {
                        !props.isNew && (
                            <Button
                                onClick={() => props.changeStoreSettings('isDeleteModalOpen', true)}
                                color="secondary"
                                variant="contained"
                            >Delete</Button>
                        )
                    }
                </CardActions>
            </Card>
            <Snackbar
                open={!!(props.categories.moveProductsFailedResponse)}
                message={props.categories.moveProductsFailedResponse}
                autoHideDuration={6000}
                onClose={() => {props.changeStoreSettings('moveProductsFailedResponse', '')}}
            />
            <Snackbar
                open={!!(props.categories.sendResponse)}
                message={props.categories.sendResponse}
                autoHideDuration={6000}
                onClose={() => {props.changeStoreSettings('sendResponse', '')}}
            />
        </div>
    )
};


export default CategoryFormComponent;
