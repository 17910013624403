import React, {Component} from 'react';
import {connect} from 'react-redux';
import {changeLoginForm, toSignOut, changeUserInfo, toUpdateUserPassword} from '../actions/login';
import AccountSettingsForm from "../components/accountSettingsForm";
import {parseLogin} from "../helpers/parseImmutables";

const UserSettings = props => {

    const handleInputChange = (evt) => {
        props.changeUser(evt.target.name, evt.target.value);
    };

    const handleSubmit = (evt) => {
        if (evt.key === "Enter" || typeof(evt.key) === "undefined") {
            evt.preventDefault();
            if (props.login.userInfo.new_password === props.login.userInfo.retypedNewPassword && props.login.userInfo.new_password !== "") {
                props.updateUserPassword({
                    oldPassword: props.login.password,
                    newPassword: props.login.userInfo.new_password
                }, props.login.userInfo.token);
            }
            else if (props.login.userInfo.new_password !== props.login.userInfo.retypedNewPassword) {
                props.changeInfo('editResponse', 'Passwords does not match.')
            }
        }
    };

    return (
        <AccountSettingsForm
            editResponse={props.login.editResponse}
            changeStoreSettings={props.changeInfo}
            login={props.login}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeInfo: (key, value) => {
            dispatch(changeLoginForm(key, value))
        },
        signOut: (accessToken) => {
            dispatch(toSignOut(accessToken))
        },
        changeUser: (key, value) => {
            dispatch(changeUserInfo(key, value))
        },
        updateUserPassword: (newPassword, token) => {
            dispatch(toUpdateUserPassword(newPassword, token))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);