import * as actions from '../actions/editors';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL,BASE_URL_DOTNET} from "../helpers/variables";
import sampleCombine from "xstream/extra/sampleCombine";

export function requestCountEditors(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.COUNT_EDITORS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/people/editors/count' +
            '?access_token=' + token +
                (action.payload && action.payload.filter?
                    (`&filter=`+JSON.stringify(action.payload.filter)):`&filter=` +JSON.stringify({})),
            category: 'requestCountEditors',
            method: 'GET',
            // headers: {Authorization:  `bearer ${token}`}
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCountEditors')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.countEditorsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestCountEditorsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCountEditors')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.countEditorsFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestEditors(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_EDITORS)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/people/editors' +
            '?access_token=' + token +
                (action.payload.filter?
        (`&filter=`+JSON.stringify(action.payload.filter)):JSON.stringify({})),
            category: 'requestEditors',
            method: 'GET',
            withCredentials: true
            // headers: {Authorization:  `bearer ${token}`}
        }));

    let httpResponse$ = sources.HTTP
        .select('requestEditors')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getEditorsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestEditorsFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestEditors')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getEditorsFailed(response));

    return {
        ACTION: httpResponse$
    }
}