import React from 'react';
import TableComponent from "../General/table";
import {AddListContentButton} from "../General/addListContentButton";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import IconButton from "@material-ui/core/IconButton";
import {isAdmin} from "../../helpers/functions";
import {useHistory} from "react-router-dom";

const OrderList = (props) => {
    const history = useHistory();
    return (
        <Card>
            <CardHeader
                avatar={<IconButton>
                    <ShoppingCart />
                </IconButton>}
                title="Orders list"
            />
            <CardContent>
                <div className={'mt-3'}>
                    <TableComponent
                        handleRowSelect={props.handleRowSelect}
                        label="Orders"
                        columns={props.tableColumns}
                        rows={props.rows}
                        fixedHeader={true}
                        selectable={false}
                        multiSelectable={false}
                        displaySelectAll={false}
                        showCheckboxes={false}
                        showRowHover={true}
                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        handleRowsPerPage={props.handleRowsPerPage}
                        handlePageChange={props.handlePageChange}
                        totalRows={props.totalRows}
                        clickableRows={true}
                        isSortable={true }
                        orderBy={props.orderBy}
                        order={props.order}
                        onRequestSort={props.onRequestSort}

                    />
                </div>
                {isAdmin(props.userRole) && (
                    <AddListContentButton onClick={() =>history.push('/orders/add')}/>
                )}
            </CardContent>
        </Card>
    )
};

export default OrderList;
