import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import StoreList from "../../components/Stores/storeList";
import {changeStoreSettings, gettingAllStores, getTotalStores, resetStoresState, showStore} from "../../actions/stores";
import _ from 'lodash';
import {_getSkipFilter, getTablePage} from "../../helpers/functions";
import {parseLogin, parseStores} from "../../helpers/parseImmutables";
import {useHistory} from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";

const StoreListHOC = props => {
    const history = useHistory();
    const prevPage = usePrevious(props.stores.page);
    const prevRowsPerPage = usePrevious(props.stores.rowsPerPage);

    useEffect(() => {
        return () => {
            props.resetStoresState();
        }
    },[])

    useEffect(() => {
        props.getTotalStores(
            props.login.userInfo.brand.id,
            props.login.userInfo.token
        );
    }, []);

    useEffect(() => {
        if(
            props.stores.page !== prevPage
            || props.stores.rowsPerPage !== prevRowsPerPage){
            props.getStores(props.login.userInfo.brand.id, props.login.userInfo.token,
                {skip: _getSkipFilter(props.stores.page, props.stores.rowsPerPage), limit: props.stores.rowsPerPage});
        }
    }, [
        prevPage,
        prevRowsPerPage,
        props.stores.page,
        props.stores.rowsPerPage,
    ]);


    const getStoreArray = () => {
        let arr = [];
        let allStores = [...props.stores.all];
        _.forEach(allStores, store => {
            arr.push({
                id: store.id,
                data: [
                    store.name,
                    store.location,
                    store.telephone
                ],
                label: store.name
            })
        });
        return arr;
    };

    const handleRowSelect = (storeId) => {
        props.printStore(storeId, props.stores.all[storeId]);

        history.push('/store/edit/' + storeId)
    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.stores.totalStores / rowsPerPage);
        let currentPage = props.stores.page;
        if(parseInt(currentPage) > newTotalPages){
            currentPage = newTotalPages;
        }

        props.changeStoreSettings('rowsPerPage', rowsPerPage);
        props.changeStoreSettings('page', currentPage);
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.stores.page,  props.stores.totalStores, props.stores.rowsPerPage);
        props.changeStoreSettings('page', newPage);
    };

    let tableColumns = [
        'store name',
        'location',
        'telephone'
    ];

    return (
        <StoreList
            page={props.stores.page}
            totalRows={props.stores.totalStores}
            handlePageChange={handlePageChange}
            rowsPerPage={props.stores.rowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            isFetching={props.stores.isFetching}
            handleRowSelect={handleRowSelect}
            tableColumns={tableColumns}
            stores={getStoreArray()}
            storesPositions={props.stores.all.map(s => s.position)}
            brand={props.brand}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
        stores: parseStores(state.stores),
        brand: parseLogin(state.login).userInfo.brand
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getStores: (brandId, token, filter) => {
            dispatch(gettingAllStores(brandId, token, filter))
        },
        printStore: (id, store) => {
            dispatch(showStore(id, store))
        },
        changeStoreSettings: (key, value) => {
            dispatch(changeStoreSettings(key, value))
        },
        getTotalStores: (brandId, token) => {
            dispatch(getTotalStores(brandId, token))
        },
        resetStoresState: payload => {
            dispatch(resetStoresState(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreListHOC);

