import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '../General/table';
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import LocalOffer from '@material-ui/icons/LocalOffer';
import ClearAll from '@material-ui/icons/ClearAll';
import FilterListIcon from '@material-ui/icons/FilterList';
import CardContent from "@material-ui/core/CardContent/CardContent";
import Button from "@material-ui/core/Button/Button";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import EnabledOffersFilterForm from './enabledOffersFilterForm';
import {_getSkipFilter, scrollToElement} from "../../helpers/functions";

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    '@media screen and (max-width: 768px)': {
        pager: {
            paddingBottom: '8% !important',
        },
        pagerToolbar: {
            flexWrap: 'wrap !important',
            justifyContent: 'center !important'
        }
    }
});

const EnabledOffers = props => {
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const enabledOffersListRef = React.createRef();

    const openFilterModal = () => {
        setFilterModalOpen(true);
    };

    const closeFilterModal = () => {
        setFilterModalOpen(false);
    };

    const handleFilterList = (formIM) => {
        const whereAndFilter = props.getEnabledOffersWhereFilter(formIM);
        props.getEnabledOffers({
            filter: {
                where: whereAndFilter,
                skip: _getSkipFilter(props.page, props.rowsPerPage),
                limit: props.rowsPerPage,
                order: props.enabledOffersColumns[props.orderBy].key + ' ' + props.order.toUpperCase()
            }
        });
        props.countEnabledOffers({where: whereAndFilter});

        props.changeEnabledOffersState({key: 'page', value: 1});

        closeFilterModal();
    };

    const clearFilters = () => {
        const whereFilter = props.getEnabledOffersWhereFilter();
        props.getEnabledOffers({
            filter: {
                where: whereFilter,
                skip: _getSkipFilter(props.page, props.rowsPerPage),
                limit: props.rowsPerPage,
                order: props.enabledOffersColumns[props.orderBy].key + ' ' + props.order.toUpperCase()
            }
        });
        props.countEnabledOffers({where: whereFilter});
        props.changeEnabledOffersState({key: 'page', value: 1});
        props.destroy('enabledOffersFilterForm');
    };

    const {classes} = props;
    return (
        <Card>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={filterModalOpen}
                onClose={closeFilterModal}
            >
                <DialogContent>
                    <EnabledOffersFilterForm
                        {...props}
                        handleCancel={closeFilterModal}
                        filterEnabledOffers={handleFilterList}
                    />
                </DialogContent>
            </Dialog>
            <CardHeader
                avatar={<IconButton>
                    <LocalOffer />
                </IconButton>}
                title="Enabled Offers"
            />
            <CardContent>
                <Button color="secondary" className={classes.button} onClick={clearFilters}>
                    Clear filters
                    <ClearAll  className={'ml-1'}/>
                </Button>
                <Button color="primary" className={classes.button} onClick={openFilterModal}>
                    Filter list
                    <FilterListIcon className={'ml-1'}/>
                </Button>
                <div ref={enabledOffersListRef}>
                    <Table
                        handleRowSelect={props.handleRowSelect}
                        label="Clients"
                        rows={props.enabledOffers}
                        columns={props.enabledOffersColumns}

                        fixedHeader={true}
                        selectable={false}
                        multiSelectable={false}

                        displaySelectAll={false}
                        showCheckboxes={false}
                        showRowHover={true}

                        page={props.page}
                        rowsPerPage={props.rowsPerPage}
                        handleRowsPerPage={props.handleRowsPerPage}
                        handlePageChange={(page) => {
                            scrollToElement(enabledOffersListRef.current);
                            props.handlePageChange(page);
                        }}
                        totalRows={props.totalEnabledOffers}
                        clickableRows={true}
                        isSortable={true}
                        orderBy={props.orderBy}
                        order={props.order}
                        onRequestSort={props.onRequestSort}
                    />
                </div>
            </CardContent>
        </Card>
    );
}

EnabledOffers.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnabledOffers);
