import {fromJS} from "immutable";
import * as ActionTypes from '../actions/index';
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    currentStep: 0,
    userEmail: '',
    selectedCategories:fromJS([]),
    selectedProducts:fromJS([]),
    fetchedUser: fromJS({
        id: '',
        username: '',
        email: ''
    }),
    orderItems: fromJS([]),
    errorMessage: ''
});

function failedToCreateNewOrder(state, action) {
    return state
        .set('errorMessage', 'Failed to create new order');
}

function receiveCreateNewOrderResponse(state, action) {
    return defaultState;
}

function changeOrderItems(state, action) {
    return state
        .setIn(['orderItems', action.payload.index,action.payload.key], fromJS(action.payload.value));
}


function changeOrderFormStoreSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

export const orderForm = createReducer(defaultState, {
    [ActionTypes.FAILED_TO_CREATE_NEW_ORDER]: failedToCreateNewOrder,
    [ActionTypes.RECEIVE_CREATE_NEW_ORDER_RESPONSE]: receiveCreateNewOrderResponse,
    [ActionTypes.CHANGE_ORDER_ITEMS]: changeOrderItems,
    [ActionTypes.CHANGE_ORDER_FORM_STORE_SETTINGS]: changeOrderFormStoreSettings,
});