import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";


//action that requests the total campaigns
export function getTotalCampaigns(payload) {
    return {
        type: ActionTypes.GET_TOTAL_CAMPAIGNS,
        payload:payload
    }
}

export function receiveTotalCampaigns(allCampaignsCount) {
    return {
        type: ActionTypes.RECEIVE_TOTAL_CAMPAIGNS,
        payload: allCampaignsCount
    }
}
export function failedToGetTotalCampaigns(response){
    return {
        type: ActionTypes.FAILED_TO_GET_TOTAL_CAMPAIGNS,
        payload: response
    }
}

export function getCampaigns(payload) {
    return {
        type: ActionTypes.GET_CAMPAIGNS,
        payload: payload
    }
}

export function receiveCampaigns(campaigns) {
    return {
        type: ActionTypes.RECEIVE_CAMPAIGNS,
        payload: campaigns
    }
}

export function failedToGetCampaigns(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_CAMPAIGNS,
        payload: response
    }
}

export function changeCampaignStoreSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_CAMPAIGN_STORE_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

export const resetCampaignsNewState = actionCreator(ActionTypes.RESET_CAMPAIGNS_NEW_STATE);
