import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import Explore from "@material-ui/icons/Explore";
import Store from "@material-ui/icons/Store";
import {withStyles} from "@material-ui/core/styles";
import PointsChart from "./Dashboard/pointsChart";
import OrdersChart from "./Dashboard/ordersChart";
import EnabledOffersChart from "./Dashboard/enabledOffersChart";
import ClientsChart from "./Dashboard/clientsChart";
import DateFilterForm from "./General/dateFilterForm";
import accounting from 'accounting';
import moment from "moment";

const styles = theme => ({
    card: {
        // background: Colors.CART_ITEM_ODD_ITEM_CREME,
    }
});

const Dashboard = (props) => {
    const { classes } = props;

    return (
        <div className="">
            {/*<div className={'row'}>
                <div className="col dashboard">
                    <Card className={classes.card}>
                        <CardHeader
                            avatar={<IconButton>
                                <AccountCircle />
                            </IconButton>}
                            title="Clients"
                        />
                        <CardContent>
                            Total: {accounting.formatNumber(props.totalClients, 0, '.')}
                        </CardContent>
                    </Card>
                    <Card className={classes.card}>
                        <CardHeader
                            avatar={<IconButton>
                                <ShoppingCart />
                            </IconButton>}
                            title="Orders"
                        />
                        <CardContent>
                            Total: {accounting.formatNumber(props.totalOrders, 0, '.')}
                        </CardContent>
                    </Card>
                    <Card className={classes.card}>
                        <CardHeader
                            avatar={<IconButton>
                                <EuroSymbol />
                            </IconButton>}
                            title="Profit"
                        />
                        <CardContent>
                            Total: {accounting.formatMoney(props.totalProfit, "€", 2, ".", ",")}
                        </CardContent>
                    </Card>
                    <Card className={classes.card}>
                        <CardHeader
                            avatar={<IconButton>
                                <Store />
                            </IconButton>}
                            title="Stores"
                        />
                        <CardContent>
                            Total: {accounting.formatNumber(props.totalStores, 0, '.')}
                        </CardContent>
                    </Card>
                    <Card className={classes.card}>
                        <CardHeader
                            avatar={<IconButton>
                                <Explore />
                            </IconButton>}
                            title="Total Experiences"
                        />
                        <CardContent>
                            Total: {accounting.formatNumber(props.totalXpOrders, 0, '.')}
                        </CardContent>
                    </Card>
                </div>
            </div>*/}
            <div className={'row'}>
                <div className={'col dashboard'}>
                    <Card className={classes.card}>
                        <CardContent>
                            <DateFilterForm
                                {...props}
                                initialValues={
                                    {
                                        dateFrom: moment().subtract(6, 'days').format('YYYY-MM-DD'),
                                        dateTo: moment().format('YYYY-MM-DD'),
                                        type: 'day'
                                    }
                                }
                            />
                        </CardContent>
                    </Card>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col dashboard'}>
                    <OrdersChart
                        {...props}
                        classes={null}
                        compare={props.filtersForm && props.filtersForm.compareWith}
                        dateType={props.filtersForm && props.filtersForm.type}
                    />
                    <PointsChart
                        {...props}
                        classes={null}
                        compare={props.filtersForm && props.filtersForm.compareWith}
                        dateType={props.filtersForm && props.filtersForm.type}
                    />
                </div>
            </div>
            <div className={'row'}>
                <div className={'col dashboard'}>
                    <EnabledOffersChart
                        {...props}
                        classes={null}
                        compare={props.filtersForm && props.filtersForm.compareWith}
                        dateType={props.filtersForm && props.filtersForm.type}
                    />
                    <ClientsChart
                        {...props}
                        classes={null}
                        compare={props.filtersForm && props.filtersForm.compareWith}
                        dateType={props.filtersForm && props.filtersForm.type}
                    />
                </div>
            </div>
        </div>
    )
};

export default withStyles(styles)(Dashboard);
