import * as actions from '../actions/tellers';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";

export function requestCountTellers(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.COUNT_TELLERS)
        .map(action => ({
            url: BASE_URL + 'api/brands/' + action.payload.brandId + '/tellers/count' +
            '?access_token=' + action.payload.token,
            category: 'requestCountTellers',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCountTellers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.countTellersSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestCountTellersFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCountTellers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.countTellersFail(response));

    return {
        ACTION: httpResponse$
    }
}

//cycle that requests get teller user by id
export function requestGetTellerById(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_TELLER_BY_ID)
        .map(action => ({
            url: BASE_URL + 'api/people/tellers/' + action.payload.tellerId + '?access_token=' + action.payload.token,
            category: 'requestGetTellerById',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetTellerById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveTellerById(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed response from requesting teller by id
export function receiveFailedResponseGetTellerById(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetTellerById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToReceiveTellerById(response));

    return {
        ACTION: httpResponse$
    }
}

//cycle that requests get teller users
export function requestGetTellers(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_TELLERS)
        .map(action => ({
            url: BASE_URL + 'api/brands/' + action.payload.brandId + '/tellers?access_token=' +
            action.payload.token +
            (action.payload.filter && action.payload.filter.limit?('&filter[limit]=' + action.payload.filter.limit):'') +
            (action.payload.filter && action.payload.filter.skip?('&filter[skip]=' + action.payload.filter.skip):''),
            category: 'requestGetTellers',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetTellers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveTellers(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get tellers
export function receiveFailToGetTellers(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetTellers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToReceiveTellers(response));

    return {
        ACTION: httpResponse$
    }
}


//cycle that requests to create new teller user
export function requestCreateTeller(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.CREATE_TELLER)
        .map(action => ({
            url: BASE_URL + 'api/people/tellers?access_token=' + action.payload.token,
            category: 'requestCreateTeller',
            method: 'POST',
            send: action.payload.teller,
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCreateTeller')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveCreateTellerResponse(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request create new teller
export function receiveFailToCreateTeller(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCreateTeller')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToCreateTeller(response));

    return {
        ACTION: httpResponse$
    }
}