import * as actions from '../actions/discounts';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";

//cycle that requests the discounts
export function requestGetDiscounts(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_DISCOUNTS)
        .map(action => ({
            url: BASE_URL + 'api/discounts?access_token=' + action.payload,
            category: 'requestGetDiscounts',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetDiscounts')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveDiscounts(JSON.parse(response.text)));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the discounts
export function failedGetDiscounts(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetDiscounts')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetDiscounts(response));

    return {
        ACTION: httpResponse$
    }
}