import { combineReducers } from 'redux';
import {categories} from "./categories";
import {clientOverview} from "./clientOverview";
import {clients} from "./clients";
import {discounts} from "./discounts";
import {generalReducer} from "./generalReducer";
import {login} from "./login";
import {offers} from "./offers";
import {orderForm} from "./orderForm";
import {orderOverview} from "./orderOverview";
import {orders} from "./orders";
import {pointsForm} from "./pointsForm";
import {pointsOverview} from "./pointsOverview";
import {products} from "./products";
import {stores} from "./stores";
import {tellers} from "./tellers";
import {clientForm} from "./clientForm";
import {tellerOverview} from "./tellerOverview";
import {people} from "./people";
import {LOG_OFF} from "../actions";
import storage from "redux-persist/es/storage";
import {enabledOffers} from "./enabledOffers";
import { reducer as form } from 'redux-form/immutable';
import {statistics} from "./statistics";
import {editor} from "./editor";
import {editors} from "./editors";
import {campaignNewForm} from "./campaignNewForm";
import {campaignsNew} from "./campaignsNew";
import {campaignNewOverview} from "./campaignNewOverview";
import {pointsMultiplier} from "./pointsMultiplier";
import {pointsMultiplierForm} from "./pointsMultiplierForm";


const appReducers = combineReducers({
    editors,
    editor,
    statistics,
    form,
    enabledOffers,
    people,
    tellerOverview,
    clientForm,
    campaignNewForm,
    campaignsNew,
    campaignNewOverview,
    orderForm,
    pointsOverview,
    pointsForm,
    tellers,
    orderOverview,
    orders,
    clientOverview,
    clients,
    discounts,
    products,
    offers,
    stores,
    login,
    generalReducer,
    categories,
    pointsMultiplier,
    pointsMultiplierForm,
});




const RootReducer = (state, action) => {
    if (action.type === LOG_OFF) {
        Object.keys(state).forEach(key => {
            storage.removeItem(`persist:${key}`);
        });
        state = undefined;
    }
    return appReducers(state, action);
};

export default RootReducer;
