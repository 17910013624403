import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";


//action that requests the total campaigns
export function getMultipliersCount(payload) {
    return {
        type: ActionTypes.GET_MULTIPLIERS_COUNT,
        payload:payload
    }
}

export function receiveMultipliersCount(allMultipliersCount) {
    return {
        type: ActionTypes.RECEIVE_MULTIPLIERS_COUNT,
        payload: allMultipliersCount
    }
}
export function failedToGetMultipliersCount(response){
    return {
        type: ActionTypes.FAILED_TO_GET_MULTIPLIERS_COUNT,
        payload: response
    }
}

export function getMultipliers(payload) {
    return {
        type: ActionTypes.GET_MULTIPLIERS,
        payload: payload
    }
}

export function receiveMultipliers(multipliers) {
    return {
        type: ActionTypes.RECEIVE_MULTIPLIERS,
        payload: multipliers
    }
}

export function failedToGetMultipliers(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_MULTIPLIERS,
        payload: response
    }
}

export function getActiveMultipliers(payload) {
    return {
        type: ActionTypes.GET_ACTIVE_MULTIPLIERS,
        payload: payload
    }
}

export function receiveActiveMultipliers(multipliers) {
    return {
        type: ActionTypes.RECEIVE_ACTIVE_MULTIPLIERS,
        payload: multipliers
    }
}

export function failedToGetActiveMultipliers(response) {
    return {
        type: ActionTypes.FAILED_TO_GET_ACTIVE_MULTIPLIERS,
        payload: response
    }
}
export function changePointsMultiplierStoreSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_POINTS_MULTIPLIER_STORE_SETTINGS,
        payload: {
            key,
            value
        }
    }
}


export const resetPointsMultiplierNewState = actionCreator(ActionTypes.RESET_MULTIPLIERS_NEW_STATE);
