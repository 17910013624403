import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function setActiveTab(payload) {
    return {
        type: ActionTypes.SET_ACTIVE_TAB,
        payload: payload
    }
}

//action that opens the delete modal
export function openOrderDeleteModal(payload) {
    return {
        type: ActionTypes.OPEN_ORDER_DELETE_MODAL,
        payload: payload
    }
}

//action that closes the delete modal
export function closeOrderDeleteModal(payload) {
    return {
        type: ActionTypes.CLOSE_ORDER_DELETE_MODAL,
        payload: payload
    }
}

//action that changes store settings
export function changeOrderOverviewStoreSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_ORDER_OVERVIEW_STORE_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

export const resetOrderOverviewState = actionCreator(ActionTypes.RESET_ORDER_OVERVIEW_STATE);
