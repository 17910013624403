import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import Dashboard from "../components/dashboard";
import {countXpOrders, getTotalOrders} from "../actions/orders";
import {getTotalProfit} from "../actions/generalActions";
import {getTotalStores} from "../actions/stores";
import {getTotalClients} from "../actions/clients";
import {
    clearStatisticsCompareValues,
    getClientCompareStatistics,
    getClientStatistics, getEnabledOffersCompareStatistics,
    getEnabledOffersStatistics, getOrdersCompareStatistics,
    getOrdersStatistics, getPointsCompareStatistics,
    getPointsStatistics, getXpOrdersStatistics
} from "../actions/statistics";
import {toJS} from '../components/General/toJs'
import moment from "moment";
import {getFormValues, destroy} from 'redux-form/immutable'
import {fromJS} from "immutable";
import {useHistory} from "react-router-dom";

const DashboardHOC = props => {
    const history = useHistory();

    useEffect(() => {
        // props.getTotalOrders(props.brandId, props.token);
        // props.getTotalProfit(props.brandId, props.token);
        // props.getTotalStores(props.brandId, props.token);
        //props.getTotalClients(props.token);
        //props.countXpOrders();
        handleFilter(fromJS(
            props.filtersForm?
                props.filtersForm
                :
                {
                    dateFrom: moment().subtract(6, 'days').format('YYYY-MM-DD'),
                    dateTo: moment().format('YYYY-MM-DD'),
                    type: 'day'
                }
        ))
    },[]);

    const handleFilter = (formIM) => {
        const form = formIM.toJS();
        props.getOrdersStatistics({
            filter: {
                dateFrom: moment.utc(form.dateFrom).toDate(),
                dateTo: moment.utc(form.dateTo).toDate(),
                type: form.type
            }
        });
        props.getPointsStatistics({
            filter: {
                dateFrom: moment.utc(form.dateFrom).toDate(),
                dateTo: moment.utc(form.dateTo).toDate(),
                type: form.type
            }
        });
        props.getEnabledOffersStatistics({
            filter: {
                dateFrom: moment.utc(form.dateFrom).toDate(),
                dateTo: moment.utc(form.dateTo).toDate(),
                type: form.type
            }
        });
        props.getClientStatistics({
            filter: {
                dateFrom: moment.utc(form.dateFrom).toDate(),
                dateTo: moment.utc(form.dateTo).toDate(),
                type: form.type
            }
        });
        if(form.compareWith === 'previousPeriod'){
            const dateFromM = moment.utc(form.dateFrom);
            const dateToM = moment.utc(form.dateTo);
            const daysDiff = dateToM.diff(dateFromM, 'days') + 1;
            const compareDateFrom = moment.utc(form.dateFrom).subtract(daysDiff, 'days');
            const compareDateTo = moment.utc(form.dateTo).subtract(daysDiff, 'days');

            getCompareStatistics(compareDateFrom, compareDateTo, form.type);
        }

        if(form.compareWith === 'previousYear'){
            const compareDateFrom = moment.utc(form.dateFrom).subtract(1, 'year');
            const compareDateTo = moment.utc(form.dateTo).subtract(1, 'year');

            getCompareStatistics(compareDateFrom, compareDateTo, form.type);
        }

        if(!form.compareWith){
            props.clearStatisticsCompareValues();
        }
    };

    const getCompareStatistics = (compareDateFrom, compareDateTo, formType) => {
        props.getPointsCompareStatistics({
            filter: {
                dateFrom: compareDateFrom.toDate(),
                dateTo: compareDateTo.toDate(),
                type: formType
            }
        });
        props.getClientCompareStatistics({
            filter: {
                dateFrom: compareDateFrom.toDate(),
                dateTo: compareDateTo.toDate(),
                type: formType
            }
        });
        props.getOrdersCompareStatistics({
            filter: {
                dateFrom: compareDateFrom.toDate(),
                dateTo: compareDateTo.toDate(),
                type: formType
            }
        });
        props.getEnabledOffersCompareStatistics({
            filter: {
                dateFrom: compareDateFrom.toDate(),
                dateTo: compareDateTo.toDate(),
                type: formType
            }
        });
    }

    return (
        <Dashboard
            {...props}
            handleFilter={handleFilter}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        totalClients: state.clients.get('totalClients'),
        totalStores: state.stores.get('totalStores'),
        totalProfit: state.generalReducer.get('totalProfit'),
        totalOrders: state.orders.get('totalOrders'),
        totalXpOrders: state.orders.get('totalXpOrders'),
        token: state.login.getIn(['userInfo', 'token']),
        brandId: state.login.getIn(['userInfo', 'brand', 'id']),
        ordersStatistics: state.statistics.get('ordersStatistics'),
        pointsStatistics: state.statistics.get('pointsStatistics'),
        enabledOffersStatistics: state.statistics.get('enabledOffersStatistics'),
        clientStatistics: state.statistics.get('clientStatistics'),
        xpOrdersStatistics: state.statistics.get('xpOrdersStatistics'),
        filtersForm: getFormValues('dateFilterForm')(state),
        pointsCompareStatistics: state.statistics.get('pointsCompareStatistics'),
        clientCompareStatistics: state.statistics.get('clientCompareStatistics'),
        ordersCompareStatistics: state.statistics.get('ordersCompareStatistics'),
        enabledOffersCompareStatistics: state.statistics.get('enabledOffersCompareStatistics')
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTotalOrders: (brandId, token) => {
            dispatch(getTotalOrders(brandId, token))
        },
        getTotalProfit: (brandId, token) => {
            dispatch(getTotalProfit(brandId, token))
        },
        getTotalStores: (brandId, token) => {
            dispatch(getTotalStores(brandId, token))
        },
        getTotalClients: (token) => {
            dispatch(getTotalClients(token))
        },
        getOrdersStatistics: (payload) => {
            dispatch(getOrdersStatistics(payload))
        },
        getPointsStatistics: (payload) => {
            dispatch(getPointsStatistics(payload))
        },
        getEnabledOffersStatistics: (payload) => {
            dispatch(getEnabledOffersStatistics(payload))
        },
        getClientStatistics: (payload) => {
            dispatch(getClientStatistics(payload))
        },
        countXpOrders: (payload) => {
            dispatch(countXpOrders(payload))
        },
        getXpOrdersStatistics: (payload) => {
            dispatch(getXpOrdersStatistics(payload))
        },
        getPointsCompareStatistics: (payload) => {
            dispatch(getPointsCompareStatistics(payload))
        },
        getClientCompareStatistics: (payload) => {
            dispatch(getClientCompareStatistics(payload))
        },
        getOrdersCompareStatistics: (payload) => {
            dispatch(getOrdersCompareStatistics(payload))
        },
        getEnabledOffersCompareStatistics: (payload) => {
            dispatch(getEnabledOffersCompareStatistics(payload))
        },
        clearStatisticsCompareValues: (payload) => {
            dispatch(clearStatisticsCompareValues(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(DashboardHOC));

