import React, { createElement, setState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import androidViewTop from "../../assets/images/androidPreviewTop.png";
import androidViewLeft from "../../assets/images/androidPreviewLeft.png";
import androidViewRight from "../../assets/images/androidPreviewRight.png";
import androidExtendedTop from "../../assets/images/androidExtendedTop.png";
import androidExtendedLeft from "../../assets/images/androidExtendedLeft.png";
import androidExtendedRight from "../../assets/images/androidExtendedRight.png";
import noImage from "../../assets/images/unnamed.png";

const styles = (theme) => ({
    root: {
      width: "90%",
    },
    button: {
      marginTop: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    actionsContainer: {
      marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
      padding: theme.spacing.unit * 3,
    },
    stepContent: {
      overflow: "visible",
    },
    cardWrapper: {
      overflow: "visible",
    },
    chip: {
      margin: theme.spacing.unit,
    },
  });
const AndroidNotification = (props) => {
    return (
        <div>
        <div //android preview
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          maxWidth: "364px",
          maxHeight: "300px",
          whiteSpace: "nowrap",

          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography variant="button" align='center'> android </Typography>
        {/* <div
          style={{
            flex: 1,
          }}
        > */}
          <img
            src={androidViewTop}
            style={{ maxWidth: 364 }}
          ></img>
        {/* </div> */}
        <div
          style={{
            display: "flex",
            flex: 1,
            maxHeight: "70px",
          }}
        >
          <img src={androidViewLeft}></img>
          <div
            style={{
              // display: 'flex',
              flex: 1,
              border: "2px solid rgba(0, 0, 0, 0.05)",
              borderRadius: 10,
              backgroundColor: "#e0e0e0",
              maxWidth: "364px",
              maxHeight: "300px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Typography
            variant='caption'
              style={{
                marginTop: 15,
                marginLeft: 10,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: 220,
color:'black'

              }}
            >
              {props.notificationTitle}
              <br></br>
              {props.notificationText}
            </Typography>

            {props.notificationImage ? (
              <img
                style={{
                  marginTop: -40,
                  marginRight: 20,
                  maxWidth: 50,
                  maxHeight: 50,
                  borderRadius: 5,
                }}
                // src={
                //   props.notificationImage
                // }
src={`data:image/png;base64,${props.notificationImage}`}

                align="right"
              />
            ) : (
              <img
                style={{
                  marginTop: -40,
                  marginRight: 20,
                  maxWidth: 50,
                  maxHeight: 50,
                  borderRadius: 5,
                }}
                src={noImage}
                align="right"
              ></img>
            )}
          </div>
          <img src={androidViewRight}></img>
        </div>
      </div>
      
      
      <div //android extended preview
  style={{
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "370px",
    maxHeight: "400px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }}
>
  {/* <div
    style={
      {
        // flex: 1
      }
    }
  > */}
    <img src={androidExtendedTop}></img>
  {/* </div> */}
  <div
    style={{
      display: "flex",
      flex: 1,
      maxHeight: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
  >
    <img src={androidExtendedLeft}></img>
    <div
      style={{
        flex: 1,

        backgroundColor: "#9e9e9e",
      }}
    >
      <div
        style={{
          border: "2px solid rgba(0, 0, 0, 0.05)",
          borderRadius: 10,
          backgroundColor: "#e0e0e0",
          //backgroundColor: "white",
          align: "center",
          textAlign: "center",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography
        variant='caption'
          style={{
            textAlign: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: 300,
            color:'black',
            marginLeft:5

          }}
        >
          {props.notificationTitle}
          <br></br>
          {props.notificationText}{" "}
        </Typography>
        {props.notificationBigImage ? (
          <img
            style={{
              textAlign: "center",
              padding: 5,
              maxWidth: 100,
              maxHeight: 100,
              borderRadius: 10,
            }}
            // src={
            //   props.notificationImage
            // }
            src={`data:image/png;base64,${props.notificationBigImage}`}

          />
        ) : (
          <img
            style={{
              textAlign: "center",
              // padding: 5,
              maxWidth: 100,
              maxHeight: 100,
              borderRadius: 5,
            }}
            src={noImage}
          ></img>
        )}
        
      </div>
    </div>
    <img src={androidExtendedRight}></img>
  </div>
</div>
</div>)}
    export default withStyles(styles)(AndroidNotification);
