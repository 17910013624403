import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';
import * as ActionTypes from "../actions";

const defaultState = fromJS({
    activeTab: 0
});

function setTellerOverviewActiveTab(state, action) {
    return state
        .set('activeTab', action.payload.activeTab)
}

function resetState(state, action) {
    return defaultState;
}

export const tellerOverview = createReducer(defaultState, {
    [ActionTypes.SET_TELLER_OVERVIEW_ACTIVE_TAB]: setTellerOverviewActiveTab,
    [ActionTypes.RESET_TELLER_OVERVIEW_STATE]: resetState
});
