import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import InputComp from "../InputComp";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardHeader from '@material-ui/core/CardHeader';
import Colors from "../../helpers/constants/colors";

const style = {
    container: {
        height: '100vh',
        backgroundColor: Colors.MAIN_BODY_COLOR

    },
    refresh: {
        position: 'fixed',
        top: '80%',
        left: '50%',
        transform: 'translateX(-50%)'
    },
};


const ForgotPassword = (props) => {
    return (
        <div style={style.container}>
            <div className="container d-flex h-100">
                <div className="row justify-content-center align-self-center  ml-auto mr-auto">
                    <Card className="p-3">
                        <CardHeader
                            title=" Password reset via email"
                            subheader={' Type your email address'}
                        />
                        <CardContent>
                            <form id="forgotPasswordForm"  role="form" onSubmit={props.handleSubmit}>
                                <InputComp Required={true} Type="text" fullWidth={true} margin={'normal'}
                                           Label="Email" Value={props.forgotPasswordForm.email}
                                           Name="email" PropHandleInputChange={props.handleInputChange}/>

                            </form>
                        </CardContent>
                        <CardActions>
                            <Button
                                color="primary"
                                variant="contained"
                                form="forgotPasswordForm"
                                type="submit">Send email</Button>
                        </CardActions>
                    </Card>
                </div>
                <Snackbar
                    open={!!props.forgotPasswordForm.responseMessage}
                    message={props.forgotPasswordForm.responseMessage}
                    autoHideDuration={30000}
                    onClose={ () => { props.changeForgotPasswordFormSettings('responseMessage', '')}}
                />
            </div>
            {
                props.forgotPasswordForm.requesting?
                    <CircularProgress
                        size={40}
                        left={50}
                        top={50}
                        status="loading"
                        style={style.refresh}
                    />
                    :
                    null
            }
        </div>
    )
};

export default ForgotPassword;