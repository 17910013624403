import React from 'react';
import '../../styles/indicator.css';
import CircularProgress from '@material-ui/core/CircularProgress';

const Indicator = (props) => {
    const style = {
        refresh: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%)'
        },
    };

    if(props.isVisible){
        return(
            <CircularProgress
                size={40}
                left={0}
                top={0}
                status="loading"
                style={style.refresh}
            />
        )
    }

    return(
        null
    )
};

export default Indicator;