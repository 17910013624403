import React, {useEffect} from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import Button from "@material-ui/core/Button";

const Container = styled.div`
  border:1px solid lightgrey;
  border-radius: 3px;
  padding:8px;
  margin-botton:8px;
  background-color:#F1CB42;
  width:150px;
`;

const Action = props => {

    useEffect(() => {
    if( props.column ==='column-2'){
        props.actionTypeHandle(props.action.actionType)
    }
       
    }, [props.column])

    return(
        <Draggable draggableId={props.action.id} index={props.index}>
            {(provided,snapshot)=> (
                <Container
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                >
                    {props.action.actionType}

                    <br></br>
                    {props.action.actionType == "Push Notification" && props.column ==='column-2'? <Button onClick={props.notificationModalToggle}> Design Notification</Button> : null}
                    {props.action.actionType == "Newsletter" && props.column ==='column-2'? <Button onClick={props.newsletterModalToggle}> Design Newsletter</Button> : null}

                </Container>
            )}
        </Draggable>

    );
}

export default Action;