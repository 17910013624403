import {fromJS} from "immutable";
import * as ActionTypes from '../actions/index';
import {createReducer} from './helpers/createReducer';
const defaultState = fromJS({
    fetching: false,
    editors: fromJS([]),
    totalEditors: 0,
    page: 1,
    rowsPerPage: 10,
    orderBy: 0,
    order: 'desc'
});

function getEditors(state, action) {
    return state
        .set('fetching', true)
}

function getEditorsSuccess(state, action) {
    return state
        .set('fetching', false)
        .set('editors', fromJS(action.payload.body))
}

function getEditorsFailed(state, action) {
    return state
        .set('fetching', false)
}

function countEditors(state, action) {
    return state;
}

function countEditorsSuccess(state, action) {
    return state
        .set('totalEditors', action.payload.body)
}

function countEditorsFailed(state, action) {
    return state;
}

function changeEditorsState(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value))
}

function changeEditorsOrder(state, action) {
    return state
        .set('order', action.payload.order)
        .set('orderBy', action.payload.orderBy)
}

export const editors = createReducer(defaultState, {
    [ActionTypes.GET_EDITORS]: getEditors,
    [ActionTypes.GET_EDITORS_SUCCESS]: getEditorsSuccess,
    [ActionTypes.GET_EDITORS_FAILED]: getEditorsFailed,
    [ActionTypes.COUNT_EDITORS]: countEditors,
    [ActionTypes.COUNT_EDITORS_SUCCESS]: countEditorsSuccess,
    [ActionTypes.COUNT_EDITORS_FAILED]: countEditorsFailed,
    [ActionTypes.CHANGE_EDITORS_STATE]: changeEditorsState,
    [ActionTypes.CHANGE_EDITORS_ORDER]: changeEditorsOrder
});