import * as actions from '../actions/enabledOffers';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL,BASE_URL_DOTNET} from "../helpers/variables";
import sampleCombine from "xstream/extra/sampleCombine";

export function requestCountEnabledOffers(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.COUNT_ENABLED_OFFERS)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL + 'api/enabledOffers/count' +
            '?access_token=' + token +
             (
                action.payload && action.payload.whereFilter?
                   ( `&where=` + JSON.stringify(action.payload.where)):''
            )
            ,
            category: 'requestCountEnabledOffers',
            method: 'GET',
            withCredentials: true,
            // headers: {Authorization: `bearer ${token}`},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCountEnabledOffers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.countEnabledOffersSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestCountEnabledOffersFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCountEnabledOffers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.countEnabledOffersFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestGetEnabledOffers(sources) {
    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_ENABLED_OFFERS)
        .compose(sampleCombine(token$))
        .map(([action, token]) => ({
            url: BASE_URL + 'api/enabledOffers' +
            '?access_token=' + token +
             (
                action.payload && action.payload.filter?
                   ( `&filter=`+JSON.stringify(action.payload.filter) ):''
            )
            ,
            category: 'requestGetEnabledOffers',
            method: 'GET',
            withCredentials: true
            // headers: {Authorization:  `bearer ${token}` } ,
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetEnabledOffers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getEnabledOffersSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestGetEnabledOffersFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetEnabledOffers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getEnabledOffersFailed(response));

    return {
        ACTION: httpResponse$
    }
}