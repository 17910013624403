import * as ActionTypes from '../actions/index';
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';
import moment from "moment";
import { PDF_TYPES } from '../helpers/constants/productAttachmentTypes';

const defaultProduct = {
    attachment: {
        name: '',
        type: '',
        file: '',
        base64: '',
    },
    cid: "",
    product_name: "",
    description: "",
    value: 0,
    points: 0,
    productId: '',
    imageName: '',
    startDate: "",
    endDate: "",
    pointsApplyOnce: '',
    xp: false,
    xpType: '',
    erpId: ''
};

const defaultState = fromJS({
    isFetching: false,
    all: [],
    adding: false,
    msg: null,
    newProduct: defaultProduct,
    changedProduct: defaultProduct,
    deleting: false,
    showEditing: false,
    showEditingProduct: null,
    showEditingProductCategory: null,
    editingProduct: null,
    isDeleteModalOpen: false,
    fetchingProduct:  false,
    patching: false,
    sendResponse: '',
    isImageModalOpen: false,
    rowsPerPage: 10,
    page: 1,
    totalProducts: '',
    fetchingCountProducts: false,
    fetchedProduct:''
});

function countProducts(state, action) {
    return state
        .set('fetchingCountProducts', true);
}

function countProductsSuccess(state, action) {
    return state
        .set('fetchingCountProducts', false)
        .set('totalProducts', action.payload.body.count);
}

function countProductsFail(state, action) {
    return state
        .set('fetchingCountProducts', false);
}

function getProductBydId(state, action) {
    return state
        .set('fetchingProduct', true);
}

function receiveProductById(state, action) {
    try {
        action.payload = JSON.parse(action.payload.text)
       // console.log(action.payload);
    }
    catch (e) {
        action.payload = null
    }

    const productElementsAttachment = action.payload.productAttachments
        .filter(attached => attached.type === PDF_TYPES.PRODUCT_ELEMENTS);

    const productSpecificationsAttachment = action.payload.productAttachments
        .filter(attached => attached.type === PDF_TYPES.PRODUCT_SPECIFICATIONS);

    const productImagesAttachment = action.payload.productAttachments
        .filter(attached => attached.type.includes('image'));

    const productElements = productElementsAttachment.length ? productElementsAttachment[0] : undefined;
    const productSpecifications = productSpecificationsAttachment.length ? productSpecificationsAttachment[0] : undefined;
    const productImage = productImagesAttachment.length ? productImagesAttachment[0].hash : undefined;

    return state
        .set('fetchedProduct',action.payload )
        .setIn(['changedProduct', 'productId'], action.payload.id)
        .setIn(['changedProduct', 'cid'], action.payload.categories?action.payload.categories[0].id:'')
        .setIn(['changedProduct', 'product_name'], action.payload.name)
        .setIn(['changedProduct', 'description'], action.payload.description)
        .setIn(['changedProduct', 'endDate'], moment(action.payload.endDate).isValid()?
            moment(action.payload.endDate).format('YYYY-MM-DD'):{})
        .setIn(['changedProduct', 'startDate'], moment(action.payload.startDate).isValid()?
            moment(action.payload.startDate).format('YYYY-MM-DD'):{})
        .setIn(['changedProduct', 'value'], action.payload.value)
        .setIn(['changedProduct', 'points'], action.payload.points)
        .setIn(['changedProduct', 'pointsApplyOnce'], action.payload.pointsApplyOnce)
        .setIn(['changedProduct', 'imageName'], productImage)
        .setIn(['changedProduct', PDF_TYPES.PRODUCT_ELEMENTS], fromJS(productElements))
        .setIn(['changedProduct', PDF_TYPES.PRODUCT_SPECIFICATIONS], fromJS(productSpecifications))
        .setIn(['changedProduct', 'attachment'], fromJS(defaultProduct.attachment))
        .setIn(['changedProduct', 'attachment'], fromJS(defaultProduct.attachment))
        .setIn(['changedProduct', 'xp'], action.payload.xp?action.payload.xp:false)
        .setIn(['changedProduct', 'xpType'], action.payload.xpType?action.payload.xpType:'')
        .setIn(['changedProduct', 'erpId'], action.payload.erpId?action.payload.erpId:'')
        .set('fetchingProduct', false);
}

function failedToReceiveProductById(state, action) {
    return state
        .set('fetchingProduct', false)
        .set('sendResponse', 'Failed to get product.');
}

function changeProductFormSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function resetDefaultProductCat(state, action) {
    return state
        .set('all', fromJS(state.all));
}

function editChangedProduct(state, action) {
    return state
        .setIn(['changedProduct', action.payload.key],fromJS(action.payload.value) );
}

function editNewProduct(state, action) {
    return state
        .setIn(['newProduct', action.payload.key], fromJS(action.payload.value));
}

function requestAllProducts(state, action) {
    return state
        .set('isFetching', true);
}

function receiveAllProducts(state, action) {
    return state
        .set('isFetching', false)
        .set('all', fromJS(action.payload.body));
}

function receiveErrorGetProducts(state, action) {
    return state
        .set('isFetching', false);
}

function requestAddProduct(state, action) {
    return state
        .set('adding', true);
}

function receiveAddProduct(state, action) {
    return state
        .set('adding', false)
        .set('newProduct', fromJS(defaultProduct))
        .set('showEditing', false)
        .set('showEditingProduct', null)
        .set('sendResponse', 'Product was created successfully.');
}

function receiveErrorAddProduct(state, action) {
    return state
        .set('adding', false)
        .set('sendResponse', 'Failed to create new product.');
}

function receiveEditProduct(state, action) {
    return state
        .set('adding', false)
        .set('showEditing', false)
        .set('sendResponse', 'Product was updated successfully.')
        .set('showEditingProduct', null)
        .set('patching', false);
}

function receiveErrorEditProduct(state, action) {
    return state
        .set('patching', false)
        .set('sendResponse', 'Failed to update product.');
}

function requestDeleteProduct(state, action) {
    return state
        .set('deleting', true);
}

function receiveDeleteProduct(state, action) {
    return defaultState
        .set('isDeleteModalOpen', false)
        .set('showEditingProduct', fromJS(defaultState).get('showEditingProduct'))
        .set('deleting', false)
        .set('changedProduct', fromJS(defaultState).get('changedProduct'));
}

function receiveErrorDeleteProduct(state, action) {
    return state
        .set('deleting', false)
        .set('sendResponse', 'Failed to delete product');
}

function showingEditProductForm(state, action) {
    return state
        .set('showEditingProduct', fromJS(action.payload));
}

function requestEditProduct(state, action) {
    return state
        .set('patching', true);
}

function resetState(state, action) {
    return defaultState;
}

export const products = createReducer(defaultState, {
    [ActionTypes.COUNT_PRODUCTS]: countProducts,
    [ActionTypes.COUNT_PRODUCTS_SUCCESS]: countProductsSuccess,
    [ActionTypes.COUNT_PRODUCTS_FAIL]: countProductsFail,
    [ActionTypes.GET_PRODUCT_BY_ID]: getProductBydId,
    [ActionTypes.RECEIVE_PRODUCT_BY_ID]: receiveProductById,
    [ActionTypes.FAILED_TO_RECEIVE_PRODUCT_BY_ID]: failedToReceiveProductById,
    [ActionTypes.CHANGE_PRODUCT_FORM_SETTINGS]: changeProductFormSettings,
    [ActionTypes.RESET_DEFAULT_PRODUCT_AND_CATEGORY]: resetDefaultProductCat,
    [ActionTypes.EDIT_STORED_PRODUCT]: editChangedProduct,
    [ActionTypes.EDIT_NEW_PRODUCT]: editNewProduct,
    [ActionTypes.REQUEST_ALL_PRODUCTS]: requestAllProducts,
    [ActionTypes.RECEIVE_ALL_PRODUCTS]: receiveAllProducts,
    [ActionTypes.RECEIVE_ERROR_GET_PRODUCTS]: receiveErrorGetProducts,
    [ActionTypes.REQUEST_ADD_PRODUCT]: requestAddProduct,
    [ActionTypes.RECEIVE_ADD_PRODUCT]: receiveAddProduct,
    [ActionTypes.RECEIVE_ERROR_ADD_PRODUCT]: receiveErrorAddProduct,
    [ActionTypes.RECEIVE_EDIT_PRODUCT]: receiveEditProduct,
    [ActionTypes.RECEIVE_ERROR_EDIT_PRODUCT]: receiveErrorEditProduct,
    [ActionTypes.REQUEST_DELETE_PRODUCT]: requestDeleteProduct,
    [ActionTypes.RECEIVE_DELETE_PRODUCT]: receiveDeleteProduct,
    [ActionTypes.RECEIVE_ERROR_DELETE_PRODUCT]: receiveErrorDeleteProduct,
    [ActionTypes.SHOW_EDIT_PRODUCT]: showingEditProductForm,
    [ActionTypes.REQUEST_EDIT_PRODUCT]: requestEditProduct,
    [ActionTypes.RESET_PRODUCTS_STATE]: resetState
});
