import * as ActionTypes from '../actions/index';
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';
import {editingOffer} from "../actions/offers";

const form = {
    id: '',
    username: '',
    email: '',
    address: '',
    country: '',
    telephone: '',
    points: '',
    leafs: '',
    flowers: '',
    badges: '',
    firstName: '',
    lastName: '',
};

const defaultState = fromJS({
    fetchingClientEnabledOfferById: false,
    fetching: false,
    form: fromJS(form),
    orders: fromJS([]),
    enabledOffers: fromJS([]),
    fetchingEnabledOffers: false,
    totalOrders: '',
    ordersListRowsPerPage: 10,
    ordersListPage: 1,
    activeTab: 0,
    clientExtraPoints: 0,
    postingClientExtraPoints: false,
    postingClientExtraPointsFailMessage: '',
    postingClientExtraPointsSuccessMessage: '',
    totalOffers: '',
    offersListRowsPerPage: 10,
    offersListPage: 1,
    orderOrdersBy: 0,
    ordersOrder: 'desc',
    enabledOffer: fromJS({}),
    deletingEnabledOffer: false,
    deleteEnabledOfferResponse: ''
});

function changeClientOverviewStoreSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function getClientById(state, action) {
    return state
        .set('fetching', true);
}

function receiveClientById(state, action) {
    return state
        .set('fetching', false)
        .setIn(['form', 'id'], action.payload.body.id !== null? action.payload.body.id: '')
        .setIn(['form', 'username'], action.payload.body.username !== null? action.payload.body.username: '')
        .setIn(['form', 'email'], action.payload.body.email !== null? action.payload.body.email: '')
        .setIn(['form', 'address'], action.payload.body.address !== null? action.payload.body.address: '')
        .setIn(['form', 'country'], action.payload.body.country !== null? action.payload.body.country: '')
        .setIn(['form', 'telephone'], action.payload.body.telephone !== null? action.payload.body.telephone: '')
        .setIn(['form', 'points'], action.payload.body.points !== null? action.payload.body.points: '')
        .setIn(['form', 'leafs'], action.payload.body.points !== null? action.payload.body.leafs: '')
        .setIn(['form', 'flowers'], action.payload.body.points !== null? action.payload.body.flowers: '')
        .setIn(['form', 'badges'], action.payload.body.points !== null? action.payload.body.badges: '')
        .setIn(['form', 'firstName'], action.payload.body.firstName !== null? action.payload.body.firstName: '')
        .setIn(['form', 'lastName'], action.payload.body.lastName !== null? action.payload.body.lastName: '')
}

function failedToGetClientById(state, action) {
    return state
        .set('fetching', false)
        .set('form', fromJS(form))
}

function logOff(state, action) {
    return defaultState;
}

function setClientOverviewActiveTab(state, action) {
    return state
        .set('activeTab', action.payload.activeTab)
}

function giveClientExtraPoints(state, action) {
    return state
        .set('postingClientExtraPoints', true)
}

function giveClientExtraPointsSuccess(state, action) {
    return state
        .set('postingClientExtraPoints', false)
        .set('postingClientExtraPointsSuccessMessage', 'Points added successfully')
        .set('clientExtraPoints', 0)
}

function giveClientExtraPointsFail(state, action) {
    let errorMessage = '';
    try {
        errorMessage = action.payload.response.body.error.message
    } catch (e) {
        errorMessage = 'Failed to add extra points'
    }
    return state
        .set('postingClientExtraPoints', false)
        .set('postingClientExtraPointsFailMessage', errorMessage)
}

function getClientOrders(state, action) {
    return state;
}

function getClientOrdersSuccess(state, action) {
    return state
        .set('orders', fromJS(action.payload.body))
}

function getClientOrdersFail(state, action) {
    return state;
}

function countClientOrders(state, action) {
    return state;
}

function countClientOrdersSuccess(state, action) {
    return state
        .set('totalOrders', action.payload.body.count)
}

function countClientOrdersFail(state, action) {
    return state;
}

function getClientEnabledOffers(state, action) {
    return state
        .set('fetchingEnabledOffers', true)
}

function getClientEnabledOffersSuccess(state, action) {
    return state
        .set('fetchingEnabledOffers', false)
        .set('enabledOffers', fromJS(action.payload.body))
}

function getClientEnabledOffersFailed(state, action) {
    return state
        .set('fetchingEnabledOffers', false)
}

function changeClientsOrdersOrder(state, action) {
    return state
        .set('ordersOrder', action.payload.order)
        .set('orderOrdersBy', action.payload.orderBy)
}

function resetState(state, action) {
    const enabledOffer = state.get('enabledOffer').toJS();
    return defaultState
        .set('enabledOffer', fromJS(enabledOffer))
}

function getClientEnabledOfferById(state, action) {
    return state
        .set('fetchingClientEnabledOfferById', true)
}

function getClientEnabledOfferByIdSuccess(state, action) {
    return state
        .set('enabledOffer', fromJS(action.payload.body))
        .set('fetchingClientEnabledOfferById', false)
}

function getClientEnabledOfferByIdFailed(state, action) {
    return state
        .set('fetchingClientEnabledOfferById', false)
}

function deleteEnabledOffer(state, action) {
    return state
        .set('deletingEnabledOffer', true)
}

function deleteEnabledOfferSuccess(state, action) {
    return state
        .set('deletingEnabledOffer', false)
}

function deleteEnabledOfferFailed(state, action) {
    let errorMessage = null;
    try {
        errorMessage = action.payload.response.body.error.message
    } catch (e) {
        errorMessage = 'Failed to delete the enabled offer'
    }

    return state
        .set('deletingEnabledOffer', false)
        .set('deleteEnabledOfferResponse', errorMessage)
}

export const clientOverview = createReducer(defaultState, {
    [ActionTypes.CHANGE_CLIENT_OVERVIEW_STORE_SETTINGS]: changeClientOverviewStoreSettings,
    [ActionTypes.GET_CLIENT_BY_ID]: getClientById,
    [ActionTypes.RECEIVE_CLIENT_BY_ID]: receiveClientById,
    [ActionTypes.FAILED_TO_GET_CLIENT_BY_ID]: failedToGetClientById,
    [ActionTypes.LOG_OFF]: logOff,
    [ActionTypes.SET_CLIENT_OVERVIEW_ACTIVE_TAB]: setClientOverviewActiveTab,
    [ActionTypes.GIVE_CLIENT_EXTRA_POINTS]: giveClientExtraPoints,
    [ActionTypes.GIVE_CLIENT_EXTRA_POINTS_SUCCESS]: giveClientExtraPointsSuccess,
    [ActionTypes.GIVE_CLIENT_EXTRA_POINTS_FAIL]: giveClientExtraPointsFail,
    [ActionTypes.GET_CLIENT_ORDERS]: getClientOrders,
    [ActionTypes.GET_CLIENT_ORDERS_SUCCESS]: getClientOrdersSuccess,
    [ActionTypes.GET_CLIENT_ORDERS_FAIL]: getClientOrdersFail,
    [ActionTypes.COUNT_CLIENT_ORDERS]: countClientOrders,
    [ActionTypes.COUNT_CLIENT_ORDERS_SUCCESS]: countClientOrdersSuccess,
    [ActionTypes.COUNT_CLIENT_ORDERS_FAIL]: countClientOrdersFail,
    [ActionTypes.GET_CLIENT_ENABLED_OFFERS]: getClientEnabledOffers,
    [ActionTypes.GET_CLIENT_ENABLED_OFFERS_SUCCESS]: getClientEnabledOffersSuccess,
    [ActionTypes.GET_CLIENT_ENABLED_OFFERS_FAILED]: getClientEnabledOffersFailed,
    [ActionTypes.CHANGE_CLIENTS_ORDERS_ORDER]: changeClientsOrdersOrder,
    [ActionTypes.RESET_CLIENT_OVERVIEW_STATE]: resetState,
    [ActionTypes.GET_CLIENT_ENABLED_OFFER_BY_ID]: getClientEnabledOfferById,
    [ActionTypes.GET_CLIENT_ENABLED_OFFER_BY_ID_SUCCESS]: getClientEnabledOfferByIdSuccess,
    [ActionTypes.GET_CLIENT_ENABLED_OFFER_BY_ID_FAILED]: getClientEnabledOfferByIdFailed,
    [ActionTypes.DELETE_ENABLED_OFFER]: deleteEnabledOffer,
    [ActionTypes.DELETE_ENABLED_OFFER_SUCCESS]: deleteEnabledOfferSuccess,
    [ActionTypes.DELETE_ENABLED_OFFER_FAILED]: deleteEnabledOfferFailed
});
