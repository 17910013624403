import React, {Component, useEffect} from 'react';
import Table from "../General/table";
import {useParams} from "react-router-dom";

const  ClientEnabledOffers = props => {
    const {id} = useParams();
    useEffect(() => {
        props.getEnabledOffers({
            filter: {
                where: {
                    and: [
                        {personId: id},
                        {or: [{expired: false}, {expired: null}]},
                        {or: [{redeemed: false}, {redeemed: null}]}
                    ]
                },
                include: 'offer'
            }
        })
    }, [])

    return (
        <Table
            handleRowSelect={props.handleOfferRowSelect}
            label="Enabled Offers"
            rows={props.offersTableRows}
            columns={props.offersTableColumns}
            fixedHeader={true}
            selectable={false}
            multiSelectable={false}
            displaySelectAll={false}
            showCheckboxes={false}
            showRowHover={true}
            clickableRows={true}
            page={props.offersListPage}
            rowsPerPage={props.offersListRowsPerPage}
            handleRowsPerPage={props.handleOfferListRowsPerPage}
            handlePageChange={props.handleOfferListPageChange}
            totalRows={props.offersTotalRows}

        />
    )
}

export default ClientEnabledOffers;