import React, { useEffect, useState } from "react";

import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import newsletter from "grapesjs-preset-newsletter";
import "../../../styles/newsletterBuilder.css";
import coffeeLogo from "../../../assets/images/logoCoffee.png";
import fbIcon from "../../../assets/images/fbIcon.png";
import twitIcon from "../../../assets/images/twitterIcon.png";
import instaIcon from "../../../assets/images/instaIcon.png";
import googleplay from "../../../assets/images/googleplay.png"
import applestore from "../../../assets/images/appstore.png"
import ytIcon from "../../../assets/images/ytIcon.png";
import { Button } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";

const NewsletterBuilder = (props) => {
  const [editor, setEditor] = useState();

  useEffect(() => {
    const editorInitial = grapesjs.init({
      container: "#gjs",
      fromElement: true,
      height: "900",
      width: "auto",
      storageManager: { type: 1 },
      plugins: [newsletter],
      assetManager: {
        assets: [
          {
            type: "image",
            src: coffeeLogo,
            height: 800,
            width: 100,
            name: "ciLogo",
          },
          {
            type: "image",
            src: fbIcon,
            height: 36,
            width: 36,
            name: "fbIcon",
          },
          {
            type: "image",
            src: twitIcon,
            height: 36,
            width: 36,
            name: "twitIcon",
          },
          {
            type: "image",
            src: instaIcon,
            height: 36,
            width: 36,
            name: "instaIcon",
          },
          {
            type: "image",
            src: ytIcon,
            height: 36,
            width: 36,
            name: "ytIcon",
          },
        ],
      },
    });
    
    editorInitial.Panels.addButton('options', {
      id: 'undo',
      className: 'fa fa-undo',
      command: 'undo',
      attributes: { title: 'Undo' }
    });
    editorInitial.Panels.addButton('options', {
      id: 'redo',
      className: 'fa fa-repeat',
      command: 'redo',
      attributes: { title: 'Redo' }
    });
   var removeButton = editorInitial.BlockManager.getAll().remove('button');
   var removegrid = editorInitial.BlockManager.getAll().remove('grid-items');
   var removelist = editorInitial.BlockManager.getAll().remove('list-items');
    setEditor(editorInitial);
  }, []);

  const onPreview = () => {
    const htmlOutput = editor.Commands.get("gjs-get-inlined-html").run(editor);
    var newWindow = window.open();
    newWindow.document.write(htmlOutput);
  };

  const onSave = () => {
    const htmlOutput = editor.Commands.get("gjs-get-inlined-html").run(editor);
    var emailObject = {
      target:{
        name:"emailContent",
        value:htmlOutput
      } 
     }
     props.inputChange(emailObject,'test');
    props.toggleModalState();

  };
  return (
    <div>
      <DialogActions>
        <Button
          onClick={props.toggleModalState}
          color='secondary'
          name='Cancel'
        >
          Cancel
        </Button>
        <Button
          style={{ backgroundColor: "#F1CB42" }}
          autoFocus
          onClick={onPreview}
        >
          Preview
        </Button>
        <Button
          style={{ backgroundColor: "#F1CB42" }}
          autoFocus
          name='Save'
          onClick={onSave}
        >
          Save
        </Button>
      </DialogActions>
      
      <div id='gjs' >
        {props.emailTemplate !=null && props.emailTemplate != ""?
      <body  dangerouslySetInnerHTML={{ __html: props.emailTemplate }}></body>
      :
       <body>
          <table
          style={{top:'50%',left:'50%',margin:'auto',width:"100%"}}>
          <tbody >
            <tr>
            <td style={{verticalAlign: 'top', width: '16,66665%'}}>
                </td>
              <td  style={{ padding: 0, margin: 0, verticalAlign: 'top', width: '66.6666%',height:500,textAlign:'center'}}>
                  <img src={coffeeLogo} />
                  </td>
                  <td style={{verticalAlign: 'top', width: '16,66665%'}}>
                </td>
            </tr>
          </tbody>
          </table>
          <table style={{height: 150, margin: '0 auto 10 auto', padding:5, width: '100%'}}>
            <tbody>
            <tr>
            <td style={{verticalAlign: 'top', width: '16,66665%'}}>
                </td>
              <td  style={{ padding: 0, margin: 0, verticalAlign: 'top', width: '66.6666%',height:500,textAlign:'center'}}>
                 

          <table style={{height: 150, margin: '0 auto 10px auto' ,padding: '5px 5px 5px 5px', width: '100%',backgroundColor:'#ff9c00'}}>
          <tbody >
            <tr >
              <td  style={{verticalAlign: 'top', textAlign: 'center',align:"center"}}>
                <a href="https://www.facebook.com/CoffeeIslandGreece" style={{display: 'inline-block', padding: 5, minHeight: 50, minWidth: 50, }}>
                     <img src={fbIcon} />
                 </a>
                <a href="https://twitter.com/CoffeeIsland_GR" style={{display: 'inline-block', padding: 5, minHeight: 50, minWidth: 50 }}>
                <img src={twitIcon} />
                </a>
                <a href="https://www.instagram.com/coffee_island_official/" style={{display: 'inline-block', padding: 5, minHeight: 50, minWidth: 50, }}>
                <img src={instaIcon} />
                </a>
                <a href="https://www.youtube.com/user/CoffeeIslandCompany" style={{display: "inline-block", padding: 5, minHeight: 50, minWidth: 50, }}>
                <img src={ytIcon} />
                </a>
                <div style={{padding: 10}}>Κατεβασε το APP της Coffee Island!
                </div>
                <a href="https://play.google.com/store/apps/details?id=gr.coffeeisland.cimobileapp"  style={{display: 'inline-block', padding: 5, minHeight: 50, minWidth: 50 }}>
                <img src={googleplay} />
                </a>
                <a href="https://apps.apple.com/gr/app/my-coffee-island/id1441140274" style={{display: 'inline-block', padding: 5, minHeight: 50, minWidth: 50 }}>
                <img src={applestore} />
                </a>
                <div  style={{padding: 10}}>
                  <div>© COFFEE ISLAND
                  </div>
                  <div >ΚΑΘΕ ΚΑΦΕΣ
                  </div>
                  <div>ΚΑΙ ΜΙΑ ΙΣΤΟΡΙΑ
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>


                  </td>
                  <td style={{verticalAlign: 'top', width: '16,66665%'}}>
                </td>
            </tr>
            </tbody>
            </table>
          </body>
        }
      </div>
    </div>
  );
};
export default NewsletterBuilder;
