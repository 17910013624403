import * as ActionTypes from '../actions/index';
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    currentStep: 0,
    totalLevels: '',
    levels: [],
    responseMessage: '',
    failedResponseMessage: '',
    selectedCategories: [],
    selectedProducts: [],
    combinationsOfProducts: [],
    posting: false,
    includePointsEarnedPerEuro: false,
    includePointsEarnedFromProducts: false,
    includeCombinationsOfProducts: false,
    includeBirthday: false,
    birthdayPoints: '',
    includeJoinTheProduct: false,
    baseRewardItemValue: '',
    firstSignInReward: '',
    mediumRewardItemValue: '',
    finalRewardItemValue: '',
    joinTheProductPoints: ''
});

function changeCombinationsSettings(state, action) {
    return state
        .set('combinationsOfProducts', fromJS(state.get('combinationsOfProducts').updateIn([action.payload.index, action.payload.key], extra => (fromJS(action.payload.value)))));
}

function changeTheCombinationsOfProducts(state, action) {
    return state
        .setIn(['combinationsOfProducts', action.payload.index, action.payload.key], action.payload.value);
}

function createPointSystem(state, action) {
    return state
        .set('posting', true);
}

function receiveCreatePointSystemResponse(state, action) {
    return defaultState
        .set('posting', false)
        .set('responseMessage', 'Point system was created successfully');
}

function failedToReceiveCreatePointSystem(state, action) {
    return state
        .set('posting', false)
        .set('failedResponseMessage', 'Failed to create new point system');
}

function changeLevelForm(state, action) {
    return state
        .set('levels', fromJS(state.get('levels').updateIn([action.payload.index, action.payload.key], level => (action.payload.value))));
}

function changePointsFormSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function resetPointFormState(state, action) {
    return defaultState;
}

function logOff(state, action) {
    return defaultState;
}

export const pointsForm = createReducer(defaultState, {
    [ActionTypes.CHANGE_COMBINATIONS_SETTINGS]: changeCombinationsSettings,
    [ActionTypes.CHANGE_THE_COMBINATIONS_OF_PRODUCTS]: changeTheCombinationsOfProducts,
    [ActionTypes.CREATE_POINT_SYSTEM]: createPointSystem,
    [ActionTypes.RECEIVE_CREATE_POINT_SYSTEM_RESPONSE]: receiveCreatePointSystemResponse,
    [ActionTypes.FAILED_TO_RECEIVE_CREATE_POINT_SYSTEM]: failedToReceiveCreatePointSystem,
    [ActionTypes.CHANGE_LEVEL_FORM]: changeLevelForm,
    [ActionTypes.CHANGE_POINTS_FORM_SETTINGS]: changePointsFormSettings,
    [ActionTypes.RESET_POINT_FORM_STATE]: resetPointFormState,
    [ActionTypes.LOG_OFF]: logOff,
});