import React, { Component } from 'react'
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';

const theme = {
    container: {
        position: 'relative',
        display: 'inline-block'
    },
    suggestionsContainer: {
        display: 'none'
    },
    input: {
        minWidth: 280,
    },
    suggestionsContainerOpen: {
        display: 'block',
        position: 'absolute',
        top: 51,
        minWidth: 280,
        border: '1px solid #aaa',
        backgroundColor: '#fff',
        fontFamily: 'Helvetica, sans-serif',
        fontWeight: 300,
        fontSize: 16,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        zIndex: 2
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    suggestion: {
        cursor: 'pointer',
        padding: '10px 20px'
    },
    suggestionHighlighted: {
        backgroundColor: '#ddd'
    }
};


const ListSuggestions = props => {


    const getSuggestions = (value) => {
        props.requestListSuggestions({
            filterText: value,
            limit: 5,
            token: props.token
        })
    }

    const getSuggestionValue = (suggestion) => {
        return suggestion.label;
    }

    const renderSuggestion = (suggestion) => {
        return (
            <span>{suggestion.label}</span>
        );
    }

    const handleKeyPress = (evt) => {
        const key = evt.key;
        if(key === 'Enter'){
            props.handleSelectManySuggestions(props.value);
        }
        return null;
    };

    const onChange = (event, { newValue, method }) => {
        props.handleFilterTextChange(newValue);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        getSuggestions(value)
    };

    const onSuggestionsClearRequested = () => {
        props.onSuggestionsClearRequested();
    };

    const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        props.suggestionSelectedHandler(suggestion.suggestion);
    };

    const { value } = props;
    const { suggestions } = props;
    const inputProps = {
        value,
        onChange: onChange,
        placeholder: props.placeholder,
    };

    const renderInputComponent = inputProps => (
        <TextField
            {...inputProps}
        />
    );

    return (
        <Autosuggest
            renderInputComponent={renderInputComponent}
            suggestions={suggestions}
            onSuggestionSelected={onSuggestionSelected }
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            theme={theme}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        token: state.login.getIn(['userInfo', 'token'])
    };
};

const mapDispatchToProps = (state) => {
    return {};
};


export default  connect(mapStateToProps, mapDispatchToProps)(ListSuggestions);