import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import Editor from "../../components/Editor/editor";
import {changeEditorState, createEditor, getEditor, resetEditorState, updateEditor} from "../../actions/editor";
import {toJS} from "../../components/General/toJs";
import {useHistory, useParams} from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";

const EditorHOC = props => {
    let { id } = useParams();
    const prevPatchingEditor = usePrevious(props.patchingEditor);
    const history = useHistory();

    useEffect(() => {
       return () => {
           props.resetEditorState();
       }
    }, [])

    useEffect(() => {
        if(id){
            props.getEditor({personId: id})
        }
    }, [])

    useEffect(() => {
        if(
            !props.patchingEditor
            && prevPatchingEditor
            && !props.errorMessage
        ) {
            history.push('/editors');
        }
    }, [props.errorMessage, props.patchingEditor, prevPatchingEditor])

    const submitForm = (formIM) => {
        const form = formIM.toJS();

        if(id){
            props.updateEditor({
                sendBody: {
                    username: form.username,
                    email: form.email
                },
                personId: props.editor.personId
            });
        } else {
            props.createEditor({sendBody: {
                    ...form,
                    brandId: props.brandId
            }});
        }
    };

    const getInitialValues = () => {
        if(!props.editor.id) {
            return null;
        }

        return {
            username: props.editor.person.username,
            email: props.editor.person.email
        }
    };

    return (
        <Editor
            {...props}
            submitForm={submitForm}
            getInitialValues={getInitialValues}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        brandId: state.login.getIn(['userInfo', 'brand', 'id']),
        editor: state.editor.get('editor'),
        errorMessage: state.editor.get('errorMessage'),
        fetchingEditor: state.editor.get('fetchingEditor'),
        patchingEditor: state.editor.get('patching'),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        createEditor: (payload) => {
            dispatch(createEditor(payload))
        },
        changeEditorState: (payload) => {
            dispatch(changeEditorState(payload))
        },
        getEditor: (payload) => {
            dispatch(getEditor(payload))
        },
        updateEditor: (payload) => {
            dispatch(updateEditor(payload))
        },
        resetEditorState: (payload) => {
            dispatch(resetEditorState(payload))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(EditorHOC));
