import * as ActionTypes from './index';

export function createClient(payload) {
    return {
        type: ActionTypes.CREATE_CLIENT,
        payload: payload
    }
}

export function createClientSuccess(payload) {
    return {
        type: ActionTypes.CREATE_CLIENT_SUCCESS,
        payload: payload
    }
}

export function createClientFail(payload) {
    return {
        type: ActionTypes.CREATE_CLIENT_FAIL,
        payload: payload
    }
}

export function changeClientFormSettings(payload) {
    return {
        type: ActionTypes.CHANGE_CLIENT_FORM_SETTINGS,
        payload: payload
    }
}

export function changeClientFormData(payload) {
    return {
        type: ActionTypes.CHANGE_CLIENT_FORM_DATA,
        payload: payload
    }
}
