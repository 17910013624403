import React from 'react';
import InputComp from "./InputComp";
import SelectComp from "./SelectComp";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Person from "@material-ui/icons/Person";
import Snackbar from '@material-ui/core/Snackbar';

const TellerForm = (props) => {
    return (
        <div className="col-md-12">
            <Card>
                <CardHeader
                    avatar={
                        <IconButton>
                            <Person />
                        </IconButton>
                    }
                    title="Create new teller user"
                />
                <CardContent>
                    <div className="col col-lg-4 offset-lg-3">
                        <form id="form-teller" className="form-horizontal" onSubmit={props.handleSubmit}>
                            <InputComp Value={props.tellerForm.username} Label="Username" Name="username" Type="text"
                                       Required={true}
                                       fullWidth={true} margin={'normal'}
                                       placeholder="username"
                                       PropHandleInputChange={(evt) => props.handleTellerForm(evt.target.name, evt.target.value)}/>
                            <InputComp Required={true} Value={props.tellerForm.email} Label="Email" Name="email" Type="text"
                                       fullWidth={true} margin={'normal'}
                                       placeholder="admin email"
                                       PropHandleInputChange={(evt) => props.handleTellerForm(evt.target.name, evt.target.value)}/>
                            <InputComp Value={props.tellerForm.password} Label="Password" Name="password"
                                       fullWidth={true} margin={'normal'}
                                       Type="password" placeholder="admin password"  Required={true}
                                       PropHandleInputChange={(evt) => props.handleTellerForm(evt.target.name, evt.target.value)}/>
                            <SelectComp Required={true} MenuName="Stores" TargetObj={props.tellerForm.stores} Name="stores"
                                        fullWidth={true} margin={'normal'}
                                        multiple={true}
                                        Label="Stores" Selected={props.tellerForm.stores} Values={props.stores.map(item => {return {key:item.id, value: item.name}})}
                                        PropHandleInputChange={(name, value)=> props.handleTellerForm(name, value)}/>
                        </form>
                    </div>
                    <CardActions>
                        <Button
                            color="primary"
                            variant="contained"
                            form="form-teller"
                            type="submit">Create</Button>
                    </CardActions>
                </CardContent>
            </Card>
            <Snackbar
                open={!!props.tellerSendResponse}
                message={props.tellerSendResponse}
                autoHideDuration={6000}
                onClose={() => {props.changeLoginForm('tellerSendResponse', '')}}
            />
        </div>
    );
};


export default TellerForm;
