import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';
import * as ActionTypes from '../actions/index';

const defaultState = fromJS({
    formData: fromJS({
        id: '',
        username: '',
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        address: '',
        country: '',
        telephone: '',
    }),
    createClientResponseMessage: '',
    creatingClient: false
});

function changeClientFormData(state, action) {
    return state
        .setIn(['formData', action.payload.key], action.payload.value)
}

function changeClientFormSettings(state, action) {
    return state
        .set(action.payload.key, action.payload.value)
}

function createClient(state, action) {
    return state
        .set('creatingClient', true)
}

function createClientSuccess(state, action) {
    return state
        .set('creatingClient', false)
        .set('createClientResponseMessage', 'Client was created successfully.')
        .set('formData', fromJS(defaultState.get('formData')))
}

function createClientFail(state, action) {
    let msg = '';
    try {
        msg = action.payload.response.body.error.message
    } catch (e) {
        msg = 'Failed to create client.'
    }
    return state
        .set('creatingClient', false)
        .set('createClientResponseMessage', msg)
}

function logOff(state, action) {
    return defaultState;
}

export const clientForm = createReducer(defaultState, {
    [ActionTypes.LOG_OFF]: logOff,
    [ActionTypes.CREATE_CLIENT]: createClient,
    [ActionTypes.CREATE_CLIENT_SUCCESS]: createClientSuccess,
    [ActionTypes.CREATE_CLIENT_FAIL]: createClientFail,
    [ActionTypes.CHANGE_CLIENT_FORM_SETTINGS]: changeClientFormSettings,
    [ActionTypes.CHANGE_CLIENT_FORM_DATA]: changeClientFormData,
});