import {fromJS} from "immutable";
import * as ActionTypes from '../actions/index';
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    fetching: false,
    suggestions: fromJS([])
});

function logOff(state, action) {
    return defaultState;
}

function getPeopleSuggestions(state, action) {
    return state
        .set('fetching', true)
}

function getPeopleSuggestionsSuccess(state, action) {
    return state
        .set('fetching', false)
        .set('suggestions', fromJS(action.payload.body))
}

function getPeopleSuggestionsFailed(state, action) {
    return state
        .set('fetching', false)
}

function changePeopleState(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value))
}

export const people = createReducer(defaultState, {
    [ActionTypes.GET_PEOPLE_SUGGESTIONS]: getPeopleSuggestions,
    [ActionTypes.GET_PEOPLE_SUGGESTIONS_SUCCESS]: getPeopleSuggestionsSuccess,
    [ActionTypes.GET_PEOPLE_SUGGESTIONS_FAILED]: getPeopleSuggestionsFailed,
    [ActionTypes.CHANGE_PEOPLE_STATE]: changePeopleState,
    [ActionTypes.LOG_OFF]: logOff ,
});