import React, { Component, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import AppleIcon from "../../assets/images/apple.png";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import AndroidIcon from "@material-ui/icons/Android";
import WebIcon from "@material-ui/icons/Web";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AndroidNotification from "../General/androidNotification";
import IosNotification from "../General/iosNotification";
import WebNotification from "../General/webNotification";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ImageCropper from "../General/imageCropper";
import ImageIcon from '@material-ui/icons/Image';

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
  stepContent: {
    overflow: "visible",
  },
  cardWrapper: {
    overflow: "visible",
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

const NotificationDesignModal =(props) =>{

  const platformMapper = ['web','android','ios',];

  const [modalState, setModalState] = useState({
    cropModal:false })
    const [bigImage, isBigImage] = useState({
      isBigImage:false })

const handleImagePicker= (event)=>
{
  if(event.currentTarget.name === "bigImage")
      {
        isBigImage({...bigImage,isBigImage:true})
      }
      else if(event.currentTarget.name !== "bigImage")
      {
        isBigImage({...bigImage,isBigImage:false})
      }
      toggleCropModal();
}

    const toggleCropModal = (evt) => {
      
        setModalState({
        ...modalState,
        cropModal: !modalState.cropModal,
      });
      }

    return (

<Dialog
                open={props.notificationModalState}
                onClose={props.notificationModalToggle}
                fullWidth={true}
                maxWidth="md"
              >
                <DialogTitle>{"Design Push Notification"}</DialogTitle>
                <DialogContent>
                  <div style={{ display: "flex" }}>
                    
                    
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div style={{ display: "flex" }}>
                        <TextField
                          value={
                            props.campaignNewForm.formData.notificationTitle
                          }
                          id="outlined-textarea"
                          label="Notification Title"
                          type="text"
                          name="notificationTitle"
                          onChange={props.inputChange}
                          variant="outlined"
                          style={{ width: 300 }}
                          inputProps={{ maxLength: 200 }}
                          error={props.campaignNewForm.formData.notificationTitle.trim() === ""}
                          helperText={props.campaignNewForm.formData.notificationTitle.trim() === "" ? 'Required field' : ''}
                        ></TextField>
                        



                        <Button name="image" onClick={(event)=>handleImagePicker(event)} 
                        style={{marginLeft:40,backgroundColor:'#F1CB42', maxHeight:50}}>
                            Upload icon image <ImageIcon style={{marginLeft:5}}/>
                        </Button>
                        <ImageCropper
                    cropModal={modalState.cropModal}
                    toggleCropModal={toggleCropModal}
                    bigImage={bigImage.isBigImage}
                    changeCampaignFormData={props.changeCampaignFormData}
                    // {...props}
                    />
                          {props.campaignNewForm.formData.notificationImage?
                          <Button
             onClick={
               ()=> props.changeCampaignFormData({
                key: "notificationImage",
                value:"",
              })}
              style={{ maxHeight:50}}>
              <HighlightOffIcon/>
            </Button>:null}
                       
                       
                       
            <Button name="bigImage" onClick={(event)=>handleImagePicker(event)} 
            style={{marginLeft:40,backgroundColor:'#F1CB42', maxHeight:50}}>
                            Upload big image <ImageIcon style={{marginLeft:5}} />
                        </Button>         
                          {props.campaignNewForm.formData.notificationBigImage?
                          <Button
             onClick={
               ()=> props.changeCampaignFormData({
                key: "notificationBigImage",
                value:"",
              })}
              style={{ maxHeight:50}}>
            <HighlightOffIcon/>
            </Button>:null}
                        

                        <p></p>
                      </div>
                      <TextField
                        value={props.campaignNewForm.formData.notificationText}
                        id="outlined-textarea"
                        label="Notification Text"
                        type="text"
                        name="notificationText"
                        onChange={props.inputChange}
                        multiline
                        rows={3}
                        variant="outlined"
                        style={{ width: 300, maxHeight: 80 }}
                        inputProps={{ maxLength: 450 }}
                        error={props.campaignNewForm.formData.notificationText.trim() === ""}
                        helperText={props.campaignNewForm.formData.notificationText.trim() === "" ? 'Required field' : ''}
                      ></TextField>
                      <div style={{ display: "flex", alignSelf: "flex-start" }}>
                        <Typography
                          variant="subheading"
                          style={{ marginLeft: 50 }}
                        >
                          Send on
                          <br></br>
                          <input
                            type="date"
                            id="start"
                            max={props.campaignNewForm.formData.endDate ==null?"2100-01-01":props.campaignNewForm.formData.endDate}
                            min={props.campaignNewForm.formData.startDate}
                            name="actionDate"
                            value={props.campaignNewForm.formData.actionDate}
                            onChange={props.inputChange}
                            style={{ maxWidth: 150, alignSelf: "flex-end" }}
                          />
                          <input
                            type="time"
                            name="actionTime"
                            onChange={props.inputChange}
                            value={props.campaignNewForm.formData.actionTime}
                            style={{ maxWidth: 150, alignSelf: "flex-end" }}
                          />
                        </Typography>
                        <p></p>
                        {/* </div> */}
                      </div>
                    </div>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Choose platforms</FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event) =>
                                props.handlePlatform(event, "android")
                              }
                              disabled={props.initialValues ? true : false}
                              checked={props.isSelectedPlatform("android")}
                              name="android"
                            />
                          }
                          label={<AndroidIcon />}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event) =>
                                props.handlePlatform(event, "ios")
                              }
                              checked={props.isSelectedPlatform("ios")}
                              disabled={props.initialValues ? true : false}
                              name="ios"
                            />
                          }
                          label={
                            <img src={AppleIcon} style={{ maxWidth: 24 }} />
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(event) =>
                                props.handlePlatform(event, "web")
                              }
                              checked={props.isSelectedPlatform("web")}
                              disabled={props.initialValues ? true : false}
                              name="web"
                            />
                          }
                          label={<WebIcon />}
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  <TextField
                    value={props.campaignNewForm.formData.url.split('?')[0]}
                    id="outlined-textarea"
                    label="URL"
                    type="text"
                    name="url"
                    onChange={props.inputChange}
                    variant="outlined"
                    style={{ width: 300, paddingBottom: 20 }}
                    inputProps={{ maxLength: 254 }}
                    error={props.campaignNewForm.formData.url.trim() === ""}
                    helperText={props.campaignNewForm.formData.url.trim() === "" ? 'Required field' : ''}
                  ></TextField>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>


                    {platformMapper.filter(platform => props.platforms.includes(platform)).map(platform => {
                      switch(platform){
                        case 'web':
                        return <WebNotification
                        notificationTitle={props.notificationTitle}
                        notificationText={props.notificationText}
                        notificationImage={
                          props.campaignNewForm.formData
                            .notificationImage
                        }
                        url={props.campaignNewForm.formData.url}
                      />
                        case 'android':
                        return <AndroidNotification
                        notificationTitle={props.notificationTitle}
                        notificationText={props.notificationText}
                        notificationImage={
                          props.campaignNewForm.formData
                            .notificationImage
                        }
                        notificationBigImage={props.campaignNewForm.formData
                          .notificationBigImage}                        
                      />
                      case 'ios':
                        return <IosNotification
                        notificationTitle={props.notificationTitle}
                        notificationText={props.notificationText}
                        notificationImage={
                          props.campaignNewForm.formData
                            .notificationImage
                        }
                        notificationBigImage={props.campaignNewForm.formData
                          .notificationBigImage}
                      />
                      
                      default:
                        return null
                      }
                    })}



                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={props.notificationModalToggle}
                    color="secondary"
                    name="Clear"
                  >
                    Clear
                  </Button>
                  <Button
                   style={{backgroundColor:'#F1CB42'}}
                    autoFocus
                    name="Save"
                    onClick={props.notificationModalToggle}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
    )
              }

export default withStyles(styles)(NotificationDesignModal);
