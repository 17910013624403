import React from 'react';
import PropTypes from 'prop-types';
import MaterialInput from './MaterialInput'


const InputComp = (props) => {
    return (
        <div>
            <MaterialInput {...props} />
        </div>
    )
};

InputComp.propTypes = {
    Placeholder: PropTypes.string,
    Step: PropTypes.string,
    Values: PropTypes.string,
    Required: PropTypes.bool,
    Pattern: PropTypes.string,
    Label: PropTypes.string,
    Type: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
    Min: PropTypes.number,
    PropHandleInputChange: PropTypes.func.isRequired
};

export default InputComp;
