import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

//action that changes the combinations settings
export function changeCombinationsSettings(index, key, value) {
    return {
        type: ActionTypes.CHANGE_COMBINATIONS_SETTINGS,
        payload: {
            index,
            key,
            value
        }
    }
}

//action that resets the point form state
export function resetPointFormState() {
    return {
        type: ActionTypes.RESET_POINT_FORM_STATE
    }
}

//action that changes the combination of products property of store
export function changeTheCombinationsOfProducts(index, key, value) {
    return {
        type: ActionTypes.CHANGE_THE_COMBINATIONS_OF_PRODUCTS,
        payload: {
            index,
            key,
            value
        }
    }
}

//action that requests post new point system
export function createPointSystem(pointSystem, token, brandId) {
    return {
        type: ActionTypes.CREATE_POINT_SYSTEM,
        payload: {
            pointSystem,
            token,
            brandId
        }
    }
}

//action that receives response after creating point system
export function receiveCreatePointSystemResponse(response) {
    return {
        type: ActionTypes.RECEIVE_CREATE_POINT_SYSTEM_RESPONSE,
        payload: response
    }
}

//action that receives failed response from trying to create point system
export function failedToReceiveCreatePointSystem(response) {
    return {
        type: ActionTypes.FAILED_TO_RECEIVE_CREATE_POINT_SYSTEM,
        payload: response
    }
}

//action that changes the points form settings
export function changePointsFormSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_POINTS_FORM_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

//action that changes a level form
export function changeLevelForm(index, key, value) {
    return {
        type: ActionTypes.CHANGE_LEVEL_FORM,
        payload: {
            index,
            key,
            value
        }
    }
}
