import React from 'react';
import TableComponent from "../General/table";
import {AddListContentButton} from "../General/addListContentButton";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import LocalOffer from "@material-ui/icons/LocalOffer";
import IconButton from "@material-ui/core/IconButton";
import {useHistory} from "react-router-dom";

const OfferList = (props) => {
    const history = useHistory();
    return (
        <Card>
            <CardHeader
                avatar={<IconButton>
                    <LocalOffer />
                </IconButton>}
                title="Offers list"
            />
            <CardContent>
               <div className={'mt-3'}>
                   <TableComponent
                       handleRowSelect={props.handleRowSelect}
                       label="Offers"
                       columns={props.tableColumns}
                       rows={props.offers}
                       fixedHeader={true}
                       selectable={false}
                       multiSelectable={false}
                       displaySelectAll={false}
                       showCheckboxes={false}
                       showRowHover={true}
                       clickableRows={true}
                       page={props.page}
                       rowsPerPage={props.rowsPerPage}
                       handleRowsPerPage={props.handleRowsPerPage}
                       handlePageChange={props.handlePageChange}
                       totalRows={props.totalRows}
                   />
               </div>
            </CardContent>
            <AddListContentButton onClick={() =>history.push('/offer/add')}/>
        </Card>
    )
};

export default OfferList;
