import * as actions from '../actions/stores';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";

export function requestEditStore(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.SUBMIT_EXISTING_STORE)
        .map(action => ({
            url: BASE_URL + 'api/stores/'+ action.payload.dataId + '?access_token=' + action.payload.data.token ,
            category: 'requestEditStore',
            method: 'PATCH',
            send: {
                name: action.payload.data.storeName,
                location:  action.payload.data.location,
                telephone:  action.payload.data.telephone,
                position: action.payload.data.position
            },
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestEditStore')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveEditStore(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestEditStoreFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestEditStore')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorEditStore(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestGetStores(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_ALL_STORES)
        .map(action => ({
            url:  BASE_URL + "api/brands/" + action.payload.brandId + '/stores?access_token=' +
                action.payload.token  +
                '&filter[where][deleted]=false' +
                (action.payload.filter && action.payload.filter.limit?
                    ('&filter[limit]=' + action.payload.filter.limit):'') +
                (action.payload.filter && action.payload.filter.skip?
                    ('&filter[skip]=' + action.payload.filter.skip):''),
            category: 'requestGetStores',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetStores')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveAllStores(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestGetStoresFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetStores')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorGetStores());

    return {
        ACTION: httpResponse$
    }
}

export function requestAddStore(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.ADD_NEW_STORE)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.data.brid + '/stores?access_token=' +
                action.payload.token ,
            category: 'requestAddStore',
            method: 'POST',
            send: {
                name: action.payload.data.storeName,
                location: action.payload.data.location,
                telephone: action.payload.data.telephone,
                brandId: action.payload.data.brid,
                position: action.payload.data.position
            },
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestAddStore')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveAddStore(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestAddStoreFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestAddStore')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorAddStore());

    return {
        ACTION: httpResponse$
    }
}

export function requestDeleteStore(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_DELETE_STORE)
        .map(action => ({
            url: BASE_URL + 'api/stores/' + action.payload.storeId + '?access_token=' + action.payload.token,
            category: 'requestDeleteStore',
            method: 'DELETE',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestDeleteStore')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveDeleteStore());

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestDeleteStoreFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestDeleteStore')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.receiveErrorDeleteStore());

    return {
        ACTION: httpResponse$
    }
}

export function requestGetTotalStores(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_TOTAL_STORES)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.brandId +'/stores/count?access_token=' +
                action.payload.token +
                '&where[deleted]=false',
            category: 'requestGetTotalStores',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetTotalStores')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveTotalStores(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the clients
export function failedGetTotalStores(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetTotalStores')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetTotalStores(response));

    return {
        ACTION: httpResponse$
    }
}

//cycle that requests the store by id
export function requestGetStoreById(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.REQUEST_STORE_BY_ID)
        .map(action => ({
            url: BASE_URL + 'api/stores/' + action.payload.storeId + '?access_token=' + action.payload.token,
            category: 'requestGetStoreById',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetStoreById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.receiveStoreById(JSON.parse(response.text)));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request get the store by id
export function failedGetStoreById(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetStoreById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.failedToGetStoreById(response));

    return {
        ACTION: httpResponse$
    }
}