import React, {Component} from 'react';
import {connect} from 'react-redux';

//Higher order component for user authorization handling
const AuthorizationHOC = (allowedRoles) =>
    (WrappedComponent) => {
        const WithAuthorization = (props) =>{
            //component, then the wrapped component will be rendered, else a not allowed message appears
            if (allowedRoles.includes(props.userRole)) {
                return <WrappedComponent {...props} />
            } else {
                return <h1>No page for you!</h1>
            }
        }

        const mapStateToProps = (state) => {
            return {
                router: state.router,
                userRole: state.login.getIn(['userInfo','role','name'])
            }
        };

        const mapDispatchToProps = (dispatch) => {
            return {
            }
        };

        return connect(mapStateToProps, mapDispatchToProps)(WithAuthorization);
    };


export default AuthorizationHOC;
