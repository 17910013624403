import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import CategoryList from "../../components/Categories/CategoryList";
import {
    changeCategorySettings, countCategories, resetCategoriesState, showingEditCategoryForm,
    toGetCategories
} from "../../actions/categories";
import _ from 'lodash';
import {_getSkipFilter, getTablePage} from "../../helpers/functions";
import {parseCategories, parseLogin} from "../../helpers/parseImmutables";
import usePrevious from "../../hooks/usePrevious";
import {useHistory} from "react-router-dom";

const CategoryListHOC = props => {
    const prevPage = usePrevious(props.categories.page);
    const prevRowsPerPage = usePrevious(props.categories.rowsPerPage);
    const history = useHistory();

    useEffect(() => {
        return () => {
            props.resetCategoriesState();
        }
    }, [])

    useEffect(() => {
        props.countCategories({
            accessToken: props.login.userInfo.token,
            brandId: props.login.userInfo.brand.id
        });
    }, [])

    useEffect(() => {
        if(
            props.categories.page !== prevPage
            || props.categories.rowsPerPage !== prevRowsPerPage
        ) {
            props.getAllCategories(props.login.userInfo.brand.id, props.login.userInfo.token,
                {skip: _getSkipFilter(props.categories.page, props.categories.rowsPerPage), limit: props.categories.rowsPerPage});
        }
    }, [
        props.categories.page,
        prevPage,
        props.categories.rowsPerPage,
        prevRowsPerPage
    ])

    const getCategoryArray = () => {
        let categoryArray = [];
        let allCategories = [...props.categories.all];
        for (let index in allCategories) {
            if (props.categories.all[index].brid === props.login.userInfo.brid) {
                categoryArray.push(props.categories.all[index]);
            }
        }
        let arr = [];
        _.forEach(allCategories, cat => {
            arr.push(
                {
                    id: cat.id,
                    data: [
                        cat.name
                    ],
                    label: cat.name
                }
            )
        });
        return arr;
    };

    const handleRowSelect = (cid) => {
        props.showEditCategoryForm(props.categories.all[cid], cid);
        history.push('/category/' + cid)
    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.categories.totalCategories / rowsPerPage);
        let currentPage = props.categories.page;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeCategorySettings('rowsPerPage', rowsPerPage);
        props.changeCategorySettings('page', currentPage);
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.categories.page,  props.categories.totalCategories, props.categories.rowsPerPage);
        props.changeCategorySettings('page', newPage);
    };

    let tableColumns = [
        'category name',
    ];
    return (
        <CategoryList
            page={props.categories.page}
            totalRows={props.categories.totalCategories}
            handlePageChange={handlePageChange}
            rowsPerPage={props.categories.rowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            isFetching={props.categories.isFetching}
            handleRowSelect={handleRowSelect}
            tableColumns={tableColumns}
            categories={getCategoryArray()}
            userRole={props.login.userInfo.role}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
        categories: parseCategories(state.categories)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllCategories: (brandId, token, filter) => {
            dispatch(toGetCategories(brandId, token, filter))
        },
        showEditCategoryForm: (cat, id) => {
            dispatch(showingEditCategoryForm(cat, id))
        },
        changeCategorySettings: (key, value) => {
            dispatch(changeCategorySettings(key, value))
        },
        countCategories: (payload) => {
            dispatch(countCategories(payload))
        },
        resetCategoriesState: (payload) => {
            dispatch(resetCategoriesState(payload))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryListHOC);

