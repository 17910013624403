import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function setTellerOverviewActiveTab(payload) {
    return {
        type: ActionTypes.SET_TELLER_OVERVIEW_ACTIVE_TAB,
        payload: payload
    }
}

export const resetTellerOverviewState = actionCreator(ActionTypes.RESET_TELLER_OVERVIEW_STATE);