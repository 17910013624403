import React, { Component } from 'react';
import Layout from "../components/layout";
import {connect} from "react-redux";
import ActivityTrackerHOC from "../components/General/activityTracker";

const LayoutHOC = (props) => {
    return(
        <Layout
            toggleDrawer={props.toggleDrawer}
        />
    )
}

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTrackerHOC(LayoutHOC));