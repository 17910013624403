import React, {Component} from 'react';
import {connect} from 'react-redux';
import {toSignOut, addNewAdmin, tempNewAdmin, changeLoginForm} from '../actions/login';
import AdminForm from "../components/adminForm";
import {isEmail} from "../helpers/functions";
import {parseLogin} from "../helpers/parseImmutables";

const NewAdminContainer = props => {
    const handleNewAdminInput = (evt) => {
        props.changeNewAdmin(evt.target.name, evt.target.value)
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if(!isEmail(props.login.newAdmin.email)){
            props.changeLoginForm('sendNewAdminResponse', 'Invalid email address.')
            return 0;
        }
        props.createAdmin({
            username: props.login.newAdmin.username,
            password: props.login.newAdmin.password,
            email: props.login.newAdmin.email,
            brandId: props.login.userInfo.brand.id,
            accessToken: props.login.userInfo.token
        })
    };

    return (
        <AdminForm
            changeLoginForm={props.changeLoginForm}
            sendResponse={props.login.sendNewAdminResponse}
            handleSubmit={handleSubmit}
            handleNewAdminInput={handleNewAdminInput}
            login={props.login}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: (accessToken) => {
            dispatch(toSignOut(accessToken))
        },
        changeNewAdmin: (newAdminKey, newAdminValue) => {
            dispatch(tempNewAdmin(newAdminKey, newAdminValue))
        },
        createAdmin: (admin) => {
            dispatch(addNewAdmin(admin))
        },
        changeLoginForm: (key, value) => {
            dispatch(changeLoginForm(key, value))
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(NewAdminContainer);


