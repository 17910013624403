import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";
import * as actions from '../actions/clientOverview';
import sampleCombine from "xstream/extra/sampleCombine";


export function requestDeleteEnabledOffer(sources) {

    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.DELETE_ENABLED_OFFER)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/enabledOffers/' + action.payload.enabledOfferId,
            category: 'requestDeleteEnabledOffer',
            method: 'DELETE',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestDeleteEnabledOffer')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 204)
        .map(response => actions.deleteEnabledOfferSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestDeleteEnabledOfferFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestDeleteEnabledOffer')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 204)
        .map(response => actions.deleteEnabledOfferFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestClientEnabledOfferById(sources) {

    const state$ = sources.STATE;
    const token$ = state$.map(state => state.login.getIn(['userInfo', 'token']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CLIENT_ENABLED_OFFER_BY_ID)
        .compose(sampleCombine(token$))
        .map( ([action, token]) => ({
            url: BASE_URL + 'api/enabledOffers/' + action.payload.enabledOfferId,
            category: 'requestClientEnabledOfferById',
            method: 'GET',
            headers: {Authorization: token},
        }));

    let httpResponse$ = sources.HTTP
        .select('requestClientEnabledOfferById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getClientEnabledOfferByIdSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestClientEnabledOfferByIdFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestClientEnabledOfferById')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getClientEnabledOfferByIdFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestClientEnabledOffers(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CLIENT_ORDERS)
        .map(action => ({
            url: BASE_URL + 'api/people/'+ action.payload.clientId +'/enabledOffers?access_token=' +
                action.payload.token,
            category: 'requestClientEnabledOffers',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestClientEnabledOffers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getClientEnabledOffersSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestClientEnabledOffersFailed(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestClientEnabledOffers')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getClientEnabledOffersFailed(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestCountClientOrders(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.COUNT_CLIENT_ORDERS)
        .map(action => ({
            url: BASE_URL + 'api/people/clients/'+ action.payload.clientId +'/orders/count?access_token=' +
                action.payload.token ,
            category: 'requestCountClientOrders',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCountClientOrders')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.countClientOrdersSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestCountClientOrdersFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCountClientOrders')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.countClientOrdersFail(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestGetClientOrders(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GET_CLIENT_ORDERS)
        .map(action => ({
            url: BASE_URL + 'api/people/clients/'+ action.payload.clientId +'/orders?access_token=' +
                action.payload.token +
                (action.payload.filter?
                        '&filter=' + JSON.stringify(action.payload.filter):''
                ),
            category: 'requestGetClientOrders',
            method: 'GET',
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGetClientOrders')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.getClientOrdersSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestGetClientOrdersFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGetClientOrders')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.getClientOrdersFail(response));

    return {
        ACTION: httpResponse$
    }
}

export function requestGiveClientExtraPoints(sources) {

    const state$ = sources.STATE;
    const brandMachineName$ = state$.map(state => state.login.getIn(['userInfo', 'brand', 'machineName']));

    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.GIVE_CLIENT_EXTRA_POINTS)
        .compose(sampleCombine(brandMachineName$))
        .map( ([action, brandMachineName])=> ({
            url: BASE_URL + 'api/people/clients/'+ action.payload.clientId +'/points?access_token=' +
                action.payload.token +  '&brandMachineName=' + brandMachineName ,
            category: 'requestGiveClientExtraPoints',
            method: 'POST',
            send: action.payload.sendBody,
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestGiveClientExtraPoints')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.giveClientExtraPointsSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestGiveClientExtraPointsFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestGiveClientExtraPoints')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.giveClientExtraPointsFail(response));

    return {
        ACTION: httpResponse$
    }
}