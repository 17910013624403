import * as actions from '../actions/clientForm';
import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";

export function requestCreateClient(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.CREATE_CLIENT)
        .map(action => ({
            url: BASE_URL + 'api/people/clients' +
                '?access_token=' + action.payload.token,
            category: 'requestCreateClient',
            method: 'POST',
            send: action.payload.sendBody,
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCreateClient')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => actions.createClientSuccess(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

export function requestCreateClientFail(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCreateClient')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => actions.createClientFail(response));

    return {
        ACTION: httpResponse$
    }
}