import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import OrderList from "../../components/Orders/orderList";
import {
    changeOrdersSortOrder,
    changeOrdersStoreSettings,
    getOrders,
    getTotalOrders,
    resetOrdersState
} from "../../actions/orders";
import {_getSkipFilter, getTablePage} from "../../helpers/functions";
import {parseLogin, parseOrders} from "../../helpers/parseImmutables";
import usePrevious from "../../hooks/usePrevious";
import {useHistory} from "react-router-dom";

const tableColumns = [
    {name: 'Id', key: 'id'},
    {name: 'Date', key: 'date'},
    {name: 'Teller', key: 'teller', excludeFromSorting: true},
    {name: 'Client', key: 'client', excludeFromSorting: true},
    {name: 'Products', key: 'products', excludeFromSorting: true},
    {name: 'Total price', key: 'totalValue'},
    {name: 'Total points', key: 'totalPoints'},
    {name: 'Store', key: 'store', excludeFromSorting: true},
];

const OrderListHOC = props => {
    const history = useHistory();
    const prevPage = usePrevious(props.orders.page);
    const prevRowsPerPage = usePrevious(props.orders.rowsPerPage)
    const prevOrder = usePrevious(props.orders.order)
    const prevOrderBy = usePrevious(props.orders.orderBy)

    useEffect(() => {
        return () => {
            props.resetOrdersState();
        }
    }, [])

    useEffect(() => {
        props.getTotalOrders(props.brandId, props.token);
    }, []);

    useEffect(() => {
        if(
            (props.orders.page !== prevPage) ||
            (props.orders.rowsPerPage !== prevRowsPerPage) ||
            (props.orders.order !== prevOrder) ||
            (props.orders.orderBy !== prevOrderBy)
        ){
            props.getOrders(props.brandId, props.token, {
                skip: _getSkipFilter(props.orders.page, props.orders.rowsPerPage),
                limit: props.orders.rowsPerPage,
                order: tableColumns[props.orders.orderBy].key + ' ' + props.orders.order.toUpperCase()

            })
        }
    },[props.orders.page, prevPage, props.orders.rowsPerPage, prevRowsPerPage,
        props.orders.order, prevOrder, props.orders.orderBy, prevOrderBy])

    const getOrderRows = () => {
       let rows = [];
        let allOrders = [...props.orders.orders];
        _.forEach(allOrders, order => {
            let productString = '';
            _.forEach(order.lineItems, (item, index) => {
                if(!item.name) { return }
                if(index === 0){
                    productString = item.name;
                }
                else {
                    productString += ', ' + item.name
                }
            });
            rows.push({
                id: order.id,
                data: [
                    order.id,
                    moment(order.date).format('DD/MM/YYYY, h:mm:ss a'),
                    order.teller.email,
                    order.mobilePerson.email,
                    productString,
                    order.totalValue,
                    order.totalPoints,
                    order.store.name
                ],
                label: `Order Id: ${order.id}`
            })
        });
       return rows;
    };

    const handleRowSelect = (orderId) => {
        history.push('orders/' + orderId);
    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.orders.totalOrders / rowsPerPage);
        let currentPage = props.orders.page;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeStoreSettings('rowsPerPage', rowsPerPage);
        props.changeStoreSettings('page', currentPage);
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.orders.page,  props.orders.totalOrders, props.orders.rowsPerPage);
        props.changeStoreSettings('page', newPage);
    };

    const onRequestSort = (payload) => {
        props.getTotalOrders(props.brandId, props.token);
        props.changeOrdersSortOrder(payload);
    };

    return (
        <OrderList
            push={props.push}
            page={props.orders.page}
            totalRows={props.orders.totalOrders}
            handlePageChange={handlePageChange}
            rowsPerPage={props.orders.rowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            handleRowSelect={handleRowSelect}
            tableColumns={tableColumns}
            rows={getOrderRows()}
            orderBy={props.orders.orderBy}
            order={props.orders.order}
            onRequestSort={onRequestSort}
            userRole={props.userRole}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        orders: parseOrders(state.orders),
        token: parseLogin(state.login).userInfo.token,
        brandId: parseLogin(state.login).userInfo.brand.id,
        userRole: parseLogin(state.login).userInfo.role
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrders: (brandId, token, filter) => {
            dispatch(getOrders(brandId, token, filter))
        },
        changeStoreSettings: (key, value) => {
            dispatch(changeOrdersStoreSettings(key, value))
        },
        getTotalOrders: (brandId, token) => {
            dispatch(getTotalOrders(brandId, token))
        },
        changeOrdersSortOrder: (payload) => {
            dispatch(changeOrdersSortOrder(payload))
        },
        resetOrdersState: (payload) => {
            dispatch(resetOrdersState(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderListHOC);

