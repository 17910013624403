import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import Button from '@material-ui/core/Button';
import RenderTextField from "../input/renderTextField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import RenderSelectField from "../input/renderSelectField";

const EnabledOffersFilterForm =  (props) =>{
    const {handleEnabledOffersFilter, handleSubmit} = props;
    return (
        <form onSubmit={handleSubmit(handleEnabledOffersFilter)}>
            <div className={'form-group row'}>
                <div className={'form-group col-4'}>
                    <Field name="dateFrom" shrink={true} label={'Date from'} component={RenderTextField}
                           type="date" fullWidth required
                    />
                </div>
                <div className={'form-group col-4'}>
                    <Field name="dateTo" shrink={true} label={'Date to'} component={RenderTextField} type="date"
                           fullWidth required
                    />
                </div>
                <div className={'form-group col-3'} >
                    <Field name="type" label={'Type'} component={RenderSelectField} fullWidth required>
                        <MenuItem value={'hour'} >{'Per hour'}</MenuItem>
                        <MenuItem value={'day'} >{'Per day'}</MenuItem>
                        <MenuItem value={'week'} >{'Per week'}</MenuItem>
                        <MenuItem value={'month'} >{'Per month'}</MenuItem>
                    </Field>
                </div>
            </div>
            <div className={'form-group'} >
                <Button type={'submit'} variant="contained" color="primary">
                    {'Filter'}
                </Button>
            </div>
        </form>
    )

};

export default reduxForm({
    form: 'enabledOffersFilterForm',
})(EnabledOffersFilterForm);
