import {fromJS} from "immutable";
import * as ActionTypes from '../actions/index';
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    posting: false,
    editor: fromJS({}),
    fetchingEditor: false,
    patching: false,
    errorMessage: ''
});

function createEditor(state, action) {
    return state
        .set('posting', true)
}

function createEditorSuccess(state, action) {
    return state
        .set('posting', false)
        .set('errorMessage', 'Editor person was created successfully')
}

function createEditorFailed(state, action) {
    let error = '';
    try {
        error = action.payload.response.body.error.message
    } catch (e) {
        error = 'Failed to create editor user'
    }
    return state
        .set('posting', false)
        .set('errorMessage', error)
}

function changeEditorState(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value))
}

function getEditor(state, action) {
    return state
        .set('fetchingEditor', true)
}

function getEditorSuccess(state, action) {
    return state
        .set('fetchingEditor', false)
        .set('editor', fromJS(action.payload.body))
}

function getEditorFailed(state, action) {
    let error = '';
    try {
        error = action.payload.response.body.error.message
    } catch (e) {
        error = 'Failed to fetch editor'
    }
    return state
        .set('fetchingEditor', false)
        .set('errorMessage', error)
}

function resetState(state, action) {
    return defaultState;
}

function updateEditor(state, action) {
    return state
        .set('patching', true)
}

function updateEditorSuccess(state, action) {
    return state
        .set('patching', false)
        .set('errorMessage', '')
}

function updateEditorFailed(state, action) {
    let error = '';
    try {
        error = action.payload.response.body.error.message
    } catch (e) {
        error = 'Failed to update editor'
    }
    return state
        .set('patching', false)
        .set('errorMessage', error)
}

export const editor = createReducer(defaultState, {
    [ActionTypes.CREATE_EDITOR]: createEditor,
    [ActionTypes.CREATE_EDITOR_SUCCESS]: createEditorSuccess,
    [ActionTypes.CREATE_EDITOR_FAILED]: createEditorFailed,
    [ActionTypes.CHANGE_EDITOR_STATE]: changeEditorState,
    [ActionTypes.GET_EDITOR]: getEditor,
    [ActionTypes.GET_EDITOR_SUCCESS]: getEditorSuccess,
    [ActionTypes.GET_EDITOR_FAILED]: getEditorFailed,
    [ActionTypes.RESET_EDITOR_STATE]: resetState,
    [ActionTypes.UPDATE_EDITOR]: updateEditor,
    [ActionTypes.UPDATE_EDITOR_SUCCESS]: updateEditorSuccess,
    [ActionTypes.UPDATE_EDITOR_FAILED]: updateEditorFailed
});
