import * as ActionTypes from '../actions/index';
import xs from 'xstream';
import {BASE_URL} from "../helpers/variables";
import {failedToReceiveCreatePointSystem, receiveCreatePointSystemResponse} from "../actions/pointsForm";

export function requestCreatePointSystem(sources) {
    const request$ = sources.ACTION
        .filter(action => action.type === ActionTypes.CREATE_POINT_SYSTEM)
        .map(action => ({
            url: BASE_URL + 'api/brands/'+ action.payload.brandId +'/pointSystem?access_token=' + action.payload.token,
            category: 'requestCreatePointSystem',
            method: 'POST',
            send: action.payload.pointSystem,
            withCredentials: true
        }));

    let httpResponse$ = sources.HTTP
        .select('requestCreatePointSystem')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status === 200)
        .map(response => receiveCreatePointSystemResponse(response));

    return {
        ACTION: httpResponse$,
        HTTP: request$
    }
}

//cycle that receives failed request create point system
export function failedToCreatePointSystem(sources) {
    let httpResponse$ = sources.HTTP
        .select('requestCreatePointSystem')
        .map((response) =>
            response.replaceError((err) => xs.of(err))
        )
        .flatten()
        .filter(response => response.status !== 200)
        .map(response => failedToReceiveCreatePointSystem(response));

    return {
        ACTION: httpResponse$
    }
}