import * as ActionTypes from '../actions/index';
import {fromJS} from "immutable";
import {createReducer} from './helpers/createReducer';

const defaultNewAdmin = {
    firstName: "",
    email: "",
    lastName: "",
    brid: "",
    password: "",
    username: ""
};

const defaultState = fromJS({
    tellerSendResponse: '',
    tellerForm: {
        username: '',
        email: '',
        password: '',
        stores: [],
    },
    isFetching: false,
    isAuth: false,
    username: "",
    password: "",
    userInfo: {
        username: '',
        brand:{
            id: '',
            description: '',
            email: '',
            avatar: '',
            name: '',
            telephone: ''
        },
        brid: null,
        firstName: "",
        lastName: "",
        email: "",
        uid: "",
        emailVerified: "",
        photoUrl: "",
        aid: "",
        new_password: "",
        retypedNewPassword: "",
        token: null,
        token_dotnet: null
    },
    newAdmin: defaultNewAdmin,
    adding: false,
    loginResponse: '',
    sendNewAdminResponse: '',
    editResponse: '',
    isEditingPassword: '',
    resetPasswordForm: {
        newPassword: '',
        retypedNewPassword: '',
        resettingPassword: false,
        responseMessage: '',
        resetToken: ''
    },
    forgotPasswordForm: {
        email: '',
        responseMessage: '',
        requesting: false
    }
});

function requestSetNewPassword(state, action) {
    return state
        .setIn(['resetPasswordForm', 'resettingPassword'], true);
}

function receiveResponseSetNewPassword(state, action) {
    return state
        .set('forgotPasswordForm', fromJS(defaultState.get('forgotPasswordForm')))
        .set('resetPasswordForm', fromJS(defaultState.get('resetPasswordForm')))
        .set('loginResponse', 'Password updated. Sign in with your new password.');
}

function failedToReceiveResponseSetNewPassword(state, action) {
    return state
        .setIn(['resetPasswordForm', 'resettingPassword'], false)
        .setIn(['resetPasswordForm', 'responseMessage'], 'Failed to set new password.');
}

function requestResetForgottenPassword(state, action) {
    return state
        .setIn(['forgotPasswordForm', 'requesting'], true);
}

function receiveResetForgottenPassword(state, action) {
    return state
        .setIn(['forgotPasswordForm', 'requesting'], false)
        .setIn(['forgotPasswordForm', 'responseMessage'], 'Email sent.');
}

function failedToReceiveResetForgottenPassword(state, action) {
    return state
        .setIn(['forgotPasswordForm', 'requesting'], false)
        .setIn(['forgotPasswordForm', 'responseMessage'], 'Failed to send email.');
}

function changeForgotPasswordFormSettings(state, action) {
    return state
        .setIn(['forgotPasswordForm', action.payload.key], fromJS(action.payload.value));
}

function changePasswordResetFormSettings(state, action) {
    return state
        .setIn(['resetPasswordForm', action.payload.key ], fromJS(action.payload.value));
}

function createTeller(state, action) {
    return state
        .set('adding', true);
}

function receiveCreateTellerResponse(state, action) {
    return state
        .set('adding', false)
        .set('tellerForm', fromJS(defaultState.get('tellerForm')))
        .set('tellerSendResponse', 'Teller user was created successfully.');
}

function failedToCreateTeller(state, action) {
    return state
        .set('adding', false)
        .set('tellerSendResponse', 'Failed to create teller user.');
}

function handleTellerForm(state, action) {
    return state
        .setIn(['tellerForm', action.payload.key], fromJS(action.payload.value));
}

function editUserPassword(state, action) {
    return state
        .set('isEditingPassword', true);
}

function receiveEditPassword(state, action) {
    return state
        .set('isEditingPassword', false)
        .set('editResponse', 'Password was updated successfully.');
}

function receiveErrorEditPassword(state, action) {
    return state
        .set('isEditingPassword', false)
        .set('editResponse', 'Failed to update password.');
}

function failedToLogIn(state, action) {
    return state
        .set('isFetching', false)
        .set('loginResponse', 'Failed to sign in.');
}

function requestAddAdmin(state, action) {
    return state
        .set('adding', true);
}

function receiveAddAdmin(state, action) {
    return state
        .set('adding', false)
        .set('newAdmin', fromJS(defaultNewAdmin))
        .set('sendNewAdminResponse', 'Admin user was created successfully.');
}

function receiveErrorAddAdmin(state, action) {
    return state
        .set('adding', false)
        .set('sendNewAdminResponse', 'Failed to create new admin.');
}

function tempNewAdmin(state, action) {
    return state
        .setIn(['newAdmin', action.payload.key], fromJS(action.payload.value));
}

function resetUser(state, action) {
    return defaultState;
}

function changeUserInfo(state, action) {
    return state
        .setIn(['userInfo', action.payload.key], action.payload.value);
}

function changeLoginForm(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function requestAuth(state, action) {
    return state
        .set('isFetching', true);
}
// function requestAuthDotnet(state, action) {
//     return state
//         .set('isFetching', true);
// }

function receiveAuth(state, action) {
    return state
        .set('isFetching', false)
        .set('isAuth', true)
        .setIn(['userInfo', 'uid'], action.payload.body.userId)
        .setIn(['userInfo', 'email'], action.payload.body.email)
        .setIn(['userInfo', 'username'], action.payload.body.username)
        .setIn(['userInfo', 'brand'],fromJS(action.payload.body.brand))
        .setIn(['userInfo', 'role'],fromJS(action.payload.body.role))
        .setIn(['userInfo', 'token'], action.payload.body.id);
}

function receiveAuthDotnet(state, action) {
    return state
        .set('isFetching', false)
        .set('isAuth', true)
        .setIn(['userInfo', 'uid'], action.payload.body.userId)
        .setIn(['userInfo', 'email'], action.payload.body.email)
        .setIn(['userInfo', 'username'], action.payload.body.username)
        .setIn(['userInfo', 'brand'],fromJS(action.payload.body.brand))
        .setIn(['userInfo', 'role'],fromJS(action.payload.body.role))
        .setIn(['userInfo', 'token_dotnet'], action.payload.body.id);
}

function logOff(state, action) {
    return defaultState;
}

function locationChange(state, action) {
    return state
        .setIn(['userInfo', 'new_password'], '')
        .setIn(['userInfo', 'retypedNewPassword'], '')
        .set('newAdmin', fromJS(defaultNewAdmin))
        .set('tellerForm', defaultState.get('tellerForm'))
}

function resetState(state, action) {
    return defaultState;
}

export const login = createReducer(defaultState, {
    [ActionTypes.REQUEST_SET_NEW_PASSWORD]: requestSetNewPassword,
    [ActionTypes.RECEIVE_RESPONSE_SET_NEW_PASSWORD]: receiveResponseSetNewPassword,
    [ActionTypes.FAILED_TO_RECEIVE_RESPONSE_SET_NEW_PASSWORD]: failedToReceiveResponseSetNewPassword,
    [ActionTypes.REQUEST_RESET_FORGOTTEN_PASSWORD]: requestResetForgottenPassword,
    [ActionTypes.RECEIVE_RESET_FORGOTTEN_PASSWORD]: receiveResetForgottenPassword,
    [ActionTypes.FAILED_TO_RECEIVE_FORGOTTEN_PASSWORD]: failedToReceiveResetForgottenPassword,
    [ActionTypes.CHANGE_FORGOT_PASSWORD_FORM_SETTINGS]: changeForgotPasswordFormSettings,
    [ActionTypes.CHANGE_PASSWORD_RESET_FORM_SETTINGS]: changePasswordResetFormSettings,
    [ActionTypes.CREATE_TELLER]: createTeller,
    [ActionTypes.RECEIVE_CREATE_TELLER_RESPONSE]: receiveCreateTellerResponse,
    [ActionTypes.FAILED_TO_CREATE_TELLER]: failedToCreateTeller,
    [ActionTypes.HANDLE_TELLER_FORM]: handleTellerForm,
    [ActionTypes.EDIT_USER_PASSWORD]: editUserPassword,
    [ActionTypes.RECEIVE_EDIT_PASSWORD]: receiveEditPassword,
    [ActionTypes.RECEIVE_ERROR_EDIT_PASSWORD]: receiveErrorEditPassword,
    [ActionTypes.FAILED_TO_LOG_IN]: failedToLogIn,
    [ActionTypes.REQUEST_ADD_ADMIN]: requestAddAdmin,
    [ActionTypes.RECEIVE_ADD_ADMIN]: receiveAddAdmin,
    [ActionTypes.RECEIVE_ERROR_ADD_ADMIN]: receiveErrorAddAdmin,
    [ActionTypes.TEMP_NEW_ADMIN]: tempNewAdmin,
    [ActionTypes.RESET_USER]: resetUser,
    [ActionTypes.CHANGE_USER_INFO]: changeUserInfo,
    [ActionTypes.CHANGE_STATE]: changeLoginForm,
    [ActionTypes.REQUEST_USER]: requestAuth,
    // [ActionTypes.REQUEST_USER_DOTNET]: requestAuthDotnet,
    [ActionTypes.RECEIVE_AUTH]: receiveAuth,
    [ActionTypes.RECEIVE_AUTH_DOTNET]: receiveAuthDotnet,
    [ActionTypes.LOG_OFF]: logOff,
    [ActionTypes.RESET_LOGIN_STATE]: locationChange,
    [ActionTypes.RESET_STATE]: resetState
});
