import React, { Component, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "../General/pagination.js";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "../General/Journey/column";
import ListSuggestions from "../General/listSuggestions";
import Chip from "@material-ui/core/Chip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';


const styles = (theme) => ({
    root: {
      width: "90%",
    },
    button: {
      marginTop: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    actionsContainer: {
      marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
      padding: theme.spacing.unit * 3,
    },
    stepContent: {
      overflow: "visible",
    },
    cardWrapper: {
      overflow: "visible",
    },
    chip: {
      margin: theme.spacing.unit,
    },
  });

const FormStepper = (props) =>{
return (
    <Stepper activeStep={props.currentStep} orientation="vertical">
    <Step>
      <StepLabel>Start a Campaign</StepLabel>
      <StepContent>

        <div className={"col-12"} style={{ display: "flex" }}>
       
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: 60,
                marginBottom: 50,
              }}
            >
              <TextField
                value={props.campaignNewForm.formData.title}
                id="outlined-textarea"
                label="Campaign Title*"
                type="text"
                name="title"
                onChange={props.inputChange}
                variant="outlined"
                style={{ width: 300 }}
                inputProps={{ maxLength: 44 }}
                error={props.campaignNewForm.formData.title.trim() === ""}
                helperText={props.campaignNewForm.formData.title.trim() === "" ? 'Required field' : ''}
              ></TextField>



              {props.campaigns.campaigns.length <= 0 || props.campaignNewForm.formData.title.trim()==="" ? null : (
                <FormControl>
                  <Paper style={{backgroundColor:"#D3D3D3" }}> 
                    <Typography variant="subheading"> Choose to extend an existing campaign </Typography>
                   
                      {props.campaigns.campaigns.map((campaign) => (
                      <MenuItem
                        name="title"
                        key={campaign.id}
                        value={campaign.title}
                        onClick={()=>props.handleCampaignFilter(campaign.title)}
                      >
                        {campaign.title}
                      </MenuItem>
                    ))}
</Paper>
                </FormControl>
              )}
              
              <TextField
              value={props.GreekToGreeklish(props.campaignNewForm.formData.title)}
               
                id="outlined-textarea"
                label="Generated UTM"
                disabled={true}
                type="text"
                name="title"
                variant="outlined"
                style={{ width: 300 }}
              ></TextField>
              <br></br>

              <TextField
                value={props.campaignNewForm.formData.description}
                id="outlined-textarea"
                label="Campaign Description*"
                type="text"
                multiline
                rows={4}
                name="description"
                onChange={props.inputChange}
                variant="outlined"
                style={{ width: 300 }}
                inputProps={{ maxLength: 120 }}
                error={props.campaignNewForm.formData.description.trim() === ""}
                helperText={props.campaignNewForm.formData.description.trim() === "" ? 'Required field' : ''}

              ></TextField>
            </div>
            <Paper
              component="ul"
              style={{ width: 400, maxHeight: 200, padding: 10,overflow:'auto' }}
            >
              <TextField
                label="Add Tag"
                type="text"
                name="toAddTag"
                value={props.campaignNewForm.formData.toAddTag}
                onChange={props.inputChange}
              ></TextField>
            <Button
                name="AddTag"
                style={{ color: "#F1CB42" }}
                onClick={() => props.tagHandle("AddTag", "AddTag")}
                type='submit'
              >
                <AddCircleIcon />
              </Button>
              <br></br>
              {props.tags.map((tag, index) => (
                <Chip
                  label={tag}
                  name="DeleteTag"
                  onDelete={() => props.tagHandle("DeleteTag", tag)}
                  style={{ margin: 2, backgroundColor: "#F1CB42" }}
                />
              ))}
            </Paper>
          </div>
        </div>
        {props.campaignNewForm.formData.image ? (
          <div>
            <Typography variant="subheading">
              
              Image:
            </Typography>
            <img
              src={`data:image/png;base64,${props.campaignNewForm.formData.image}`}
              style={{
                maxWidth: 200,
                maxHeight: 200,
                borderRadius: 10,
                padding: 5,
              }}
            />
            
             <Button
             onClick={
               ()=> {props.changeCampaignFormData({
                key: "image",
                value:"",
              })
              document.getElementById("CampaignImage").value = "";
               }
             }>
              <HighlightOffIcon/>
            </Button>
          </div>
        ) : null}
        <div style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="default"
            style={{ padding: 2, maxHeight: 55, maxWidth: 170 }}
          >
            Upload image <br></br> (1 mb)
            <CloudUploadIcon style={{marginRight:15,paddingRight:5}}/>
            <input
        
              name="image"
              type="file"
              id="CampaignImage"
              accept="image/png, image/jpeg"
              onChange={(event) =>
                props.imageHandler(event).then((data) =>
                  props.changeCampaignFormData({
                    key: "image",
                    value: data.split(",")[1],
                  })
                )
              }
              style={{
                opacity: 0,
                marginLeft: -170,
                width: 170,
                height: 55,
                cursor: "pointer",
              }}
            />
          </Button>
          <p></p>
        </div>
        <p></p>
        <Typography variant="subheading">Start Date*</Typography>
        <input
          type="date"
          id="start"
          max="2100-01-01"
          min={moment().format("YYYY-MM-DD")}
          value={props.campaignNewForm.formData.startDate}
          name="startDate"
          onChange={props.inputChange}
        />
        <p></p>
        <Typography variant="subheading">End Date*</Typography>
        <input
          type="date"
          id="end"
          min={props.campaignNewForm.formData.startDate}
          max="2100-12-31"
          value={props.campaignNewForm.formData.endDate}
          name="endDate"
          onChange={props.inputChange}
        />
        <p></p>
      </StepContent>
    </Step>
    <Step>
      <StepLabel>Select Users</StepLabel>
      <StepContent>
        <Typography variant="title">
          Import or Choose Users
        </Typography>
        <p></p>
        <Button
            variant="contained"
            color="default"
            style={{ padding: 3.5, maxHeight: 55, maxWidth: 140}}
          >
            Choose file <br/> (.csv , .xlsx)
            <InsertDriveFileIcon />
        <input
          name="importedUsers"
          id="importedCSVUsers"
          type="file"
          accept=".csv,.xlsx"
          onChange={(e)=>{props.handleFileSelect(e);}}
          style={{
            opacity: 0,
            marginLeft: -130,
            width: 140,
            height: 55,
            cursor: "pointer",
          }}
        />
        </Button>
                         
            

        <p></p>
        <div style={{display:'flex'}}> 
        <Typography variant="subheading" >
        Users Selected: {props.checked.length===0 && props.campaignNewForm.formData.selectAll===true?props.totalRows:props.checked.length}
        
        </Typography>
        {props.checked.length!=0?<Button 
        onClick={()=>{props.setCampaignFormState({ ...props.campaignFormState, checkedUsers: [] }); document.getElementById("importedCSVUsers").value = ""; }}
         style={{marginTop:-10}}><HighlightOffIcon/></Button>:null}
         </div>
        <ListSuggestions
          variant={"outlined"}
          placeholder={"Quick search clients"}
          handleFilterTextChange={props.handleFilterFieldChange}
          value={props.filterField}
          handleSelectManySuggestions={
            props.handleSelectManySuggestions
          }
          suggestionSelectedHandler={
            props.suggestionSelectedHandler
          }
          suggestions={props.suggestions.map((s) => ({
            label: s.id+ " " + s.firstName + " " + s.lastName,
            suggestion: s,
          }))}
          onSuggestionsClearRequested={
            props.onSuggestionsClearRequested
          }
          requestListSuggestions={props.getClientsSuggestions}
        />
        <Button
          className={"ml-3"}
          onClick={props.clearFilters}
          color="primary"
          variant="contained"
        >
          Clear Filters
        </Button>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
               <Checkbox
               onClick={(event) =>{
                props.handleCheck(event, 'all')
                document.getElementById("importedCSVUsers").value = ""; 
              }
              }
                        checked={props.campaignNewForm.formData.selectAll}
                        //inputProps={{ "aria-labelledby": labelId }}
                      /> Select all
                       </TableCell>

              {props.tableColumns.map((column) => (
                <TableCell
                  align={column.numeric ? "right" : "left"}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.loadingCSV === true ? (
              <CircularProgress />
            ) : (
              props.UserList.map((user, index) => {
                const isItemSelected = props.isSelected(user.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    align="right"
                    id={user.id}
                    hover
                    onClick={props.campaignNewForm.formData.selectAll?null:(event) =>
                      props.handleCheck(event, user.id)
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={user.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={props.campaignNewForm.formData.selectAll?true:isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        disabled ={props.campaignNewForm.formData.selectAll}

                      />
                    </TableCell>

                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.telephone}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>

        <Pagination
          page={props.page}
          totalRows={props.totalRows}
          handlePageChange={props.pageChange}
          rowsPerPage={props.rowsPerPage}
          handleRowsPerPage={props.handleRowsPerPage}
          colSpan={props.tableColumns.length}
        />
      </StepContent>
    </Step>
    <Step>
      <StepLabel>Select Actions and Events</StepLabel>
      {
        <StepContent style={{ display: "flex" }}>

          <div style={{ display: "flex" }}>
       
            <Paper
              component="ul"
              style={{
                border: "2px solid rgba(0, 0, 0, 0.05)",
                maxWidth: 300,
                padding: 10,
                maxHeight:150,
                overflow:'auto'
              }}
            >
              <TextField
                label="Add Action Tag"
                type="text"
                value={props.campaignNewForm.formData.toAddTag}
                name="toAddTag"
                onChange={props.inputChange}
              ></TextField>
               <Button
                name="AddActionTag"
                style={{ color: "#F1CB42" }}
                onClick={() =>
                  props.tagHandle("AddActionTag", "AddActionTag")
                }
                type='submit'
              >
                <AddCircleIcon />
              </Button>
         <br></br>
              {props.actionTags.map((actionTag, index) => (
                <Chip
                  label={actionTag}
                  name="DeleteActionTag"
                  onDelete={() =>
                    props.tagHandle("DeleteActionTag", actionTag)
                  }
                  style={{ margin: 2, backgroundColor: "#F1CB42" }}
                />
              ))}
            </Paper>

            {props.initialValues ? (
              <Paper
                style={{
                  margin: 20,
                  maxWidth: 1000,
                  alignSelf: "flex-start",
                  padding: 5,
                }}
              >
                <Typography variant="button" style={{ padding: 5 }}>
                  Existing actions
                  
                    <EditIcon style={{marginLeft:15}} />
                </Typography>

                {props.initialValues.campaignActions.map(
                  (action, index) => (
                    (action.actionType=="Push Notification"?
                    (
                    <Button
                    onClick={props.notificationModalToggle}
                    style={{cursor: 'pointer'}}
                    >
                    <Chip
                      label={
                        action.actionType + " " + action.platform
                      }
                      style={{
                        margin: 3,
                        backgroundColor: "#F1CB42",
                        cursor: 'pointer',
                        
                      }}
                    /></Button>)
                    :action.actionType=="Newsletter"?
                    (
                    <Button
                    onClick={props.toggleEmailModal}
                    style={{maxWidth:120,cursor: 'pointer'}}
                    >
                    <Chip
                      label={
                        action.actionType
                      }
                      style={{
                        margin: 3,
                        backgroundColor: "#F1CB42",
                        cursor: 'pointer'
                      }}
                    /></Button>) :null)
                  )
                )}
              </Paper>
            ) : null}
          </div>
          {props.initialValues ? null : (
            <DragDropContext
              onDragStart={props.onDragStart}
              onDragUpdate={props.onDragUpdate}
              onDragEnd={props.onDragEnd}
            >
              <div style={{ display: "flex" }}>

                {
                  props.actions.length <= 0 ? (
                    <h4> No actions found</h4>
                  ) : (
                    props.dndData.columnOrder.map(
                      (columnId, index) => {
                        const column =
                          props.dndData.columns[columnId];
                        const actions = column.actionIds.map(
                          (actionId) => {return props.actions.find(a => a.id == actionId)}
                        );

                        return (
                          <Column
                            key={column.id}
                            column={column}
                            actions={actions}
                            inputChange={props.inputChange}
                            notificationModalToggle={
                              props.notificationModalToggle
                            }
                            notificationModalState={
                              props.notificationModalState
                            }
                            changeCampaignFormData={
                              props.changeCampaignFormData
                            }
                            newsletterModalToggle={props.toggleEmailModal}
                            actionTypeHandle={(event) =>
                              props.actionTypeHandle(event)
                            }
                          />
                        );
                      }
                    )
                  )
                }
              </div>
            </DragDropContext>
          )}
        </StepContent>
      }
    </Step>
  </Stepper>
)
}
export default withStyles(styles)(FormStepper);
