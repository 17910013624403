import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import ResetPassword from "../components/resetPassword";
import {changePasswordResetFormSettings, requestSetNewPassword} from "../actions/login";
import {parseLogin} from "../helpers/parseImmutables";
import _ from 'lodash';
import {useHistory, useLocation} from "react-router-dom";
import usePrevious from "../hooks/usePrevious";

const ResetPasswordHOC = props => {
    const location = useLocation();
    const prevResettingPassword = usePrevious(props.resetPasswordForm.resettingPassword);
    const history = useHistory();

    useEffect(() => {
        let resetToken = _.last(location.pathname.split('/'));
        props.changePasswordResetFormSettings('resetToken', resetToken);
    }, []);

    useEffect(() => {
        if (
            prevResettingPassword
            && !props.resetPasswordForm.resettingPassword
            && !props.resetPasswordForm.responseMessage
        ){
            history.push('/')
        }
    }, [props.resetPasswordForm.resettingPassword, prevResettingPassword,
        props.resetPasswordForm.resettingPassword
    ])

    const handleInputChange = (evt) => {
        props.changePasswordResetFormSettings(evt.target.name, evt.target.value);
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if(props.resetPasswordForm.newPassword !== props.resetPasswordForm.retypedNewPassword) {
            props.changePasswordResetFormSettings('responseMessage', 'Passwords does not match');
            return 0;
        }
        props.requestSetNewPassword(props.resetPasswordForm.newPassword, props.resetPasswordForm.resetToken);
    };

    return (
        <ResetPassword
            handleSubmit={handleSubmit}
            resetPasswordForm={props.resetPasswordForm}
            handleInputChange={handleInputChange}
            changePasswordResetFormSettings={props.changePasswordResetFormSettings}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        resetPasswordForm: parseLogin(state.login).resetPasswordForm,
        routerPath: state.router.location.pathname
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePasswordResetFormSettings: (key, value) => {
            dispatch(changePasswordResetFormSettings(key, value))
        },
        requestSetNewPassword: (newPassword, resetToken) => {
            dispatch(requestSetNewPassword(newPassword, resetToken))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordHOC);