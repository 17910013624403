import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteCampaignById,
  getCampaignById,
  getCampaignPlanById,
  setCampaignOverviewActiveTab,
  getActionById,
  resetState,
  getActionStatusById,
} from "../../actions/campaignNewOverview";
import {
  parseLogin,
  parseCampaignNewOverview,
  parseClientOverview,
  parseClients,
  parseCampgaignNewForm,
} from "../../helpers/parseImmutables";
import {
  changeCampaignFormData,
  editCampaign,
} from "../../actions/campaignsNewForm";
import _, { isArray } from "lodash";
import CampaignOverview from "../../components/Campaigns/campaignOverview.js";
import { useParams } from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";
import { useHistory } from "react-router-dom";

const tableColumns = [
  //  { id: 'checkbox', numeric: true, disablePadding: true, label: '' },
  { id: "id", numeric: true, disablePadding: true, label: "Person ID" },
  { id: "username", numeric: false, disablePadding: false, label: "Username" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "telephone", numeric: true, disablePadding: false, label: "Telephone" },
];

const CampaignOverviewHOC = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const [campaignOverviewState, setCampaignOverviewState] = useState({
    clients: [],
    platformView: "",
  });

  const [campaignModalState, setCampaignModalState] = useState({
    deleteModalOpen: false,
    clientsModal: false,
    notificationModal: false,
  });

  const prevCampaignOverviewFetching = usePrevious(
    props.campaignOverview.fetching
  );
     const prevDeletingCampaign = usePrevious(props.campaignOverview.deletingCampaing);
  useEffect(() => {
    props.resetState();
    props.getCampaignById(id, props.token);
  }, []);

  useEffect(() => {
    if (
      props.campaignOverview.form.campaignPlanId &&
      !props.campaignOverview.fetching &&
      prevCampaignOverviewFetching &&
      Array.isArray(props.campaignOverview.form.actionIds) &&
      props.campaignOverview.form.campaignActions.length <= 0
    ) {
      props.campaignOverview.form.actionIds.forEach((action) => {
        props.getActionById(action.ActionId, props.token);
      });
    }
  }, [
    props.campaignOverview.form,
    props.campaignOverview.fetching,
    props.token,
    prevCampaignOverviewFetching,
  ]);

  useEffect(() => {
    if (
      props.campaignOverview.form.campaignPlanId &&
      props.campaignOverview.form.listIds.size <= 0 &&
      props.campaignOverview.fetching == false
    ) {
      props.getCampaignPlanById(
        props.campaignOverview.form.campaignPlanId,
        props.token
      );
    }
  }, [props.campaignOverview.form, props.campaignOverview.fetching]);

  useEffect(() => {
if(prevDeletingCampaign && !props.campaignOverview.deletingCampaing)
{
  history.push("/campaigns");

}
  },[prevDeletingCampaign,props.campaignOverview.deletingCampaing]);


  const handleActionStatusRequest = () => {
    props.campaignOverview.form.campaignActions.forEach((action) => {
      props.getActionStatusById(action.messageId, props.token);
    });
  };

  const toggleClientsModal = () => {
    setCampaignModalState({
      ...campaignModalState,
      clientsModal: !campaignModalState.clientsModal,
    });
  };

  const toggleDeleteModal = (evt) => {
    if (evt.currentTarget.name === "deleteModalYes") {
      props.deleteCampaignById(id, props.token);
      // history.push("/campaigns");

    }
    setCampaignModalState({
      ...campaignModalState,
      deleteModalOpen: !campaignModalState.deleteModalOpen,
    });
  };

  const getClientList = () => {
    let arr = [];
    let allClients = [...props.clients.clients];
    _.forEach(allClients, (c) => {
      arr.push({
        id: c.id,
        username: c.username,
        email: c.email,
        telephone: c.telephone,
      });
    });
    return arr;
  };

  const handleViewNotification = async (action, index) => {
    if (index >= 0) {
      props.changeCampaignFormData({
        key: "notificationTitle",
        value:
          props.campaignOverview.form.campaignActions[index].notificationTitle,
      });

      props.changeCampaignFormData({
        key: "notificationText",
        value:
          props.campaignOverview.form.campaignActions[index]
            .notificationDescription,
      });
      props.changeCampaignFormData({
        key: "notificationImage",
        value:
          props.campaignOverview.form.campaignActions[index].notificationImage,
      });
      props.changeCampaignFormData({
        key: "notificationBigImage",
        value:
          props.campaignOverview.form.campaignActions[index].notificationBigImage,
      });
      props.changeCampaignFormData({
        key: "url",
        value: props.campaignOverview.form.campaignActions[index].url,
      });
    }
    if (action.platform === "android") {
      setCampaignOverviewState({
        ...campaignOverviewState,
        platformView: "android",
      });
    }
    if (action.platform === "ios") {
      setCampaignOverviewState({
        ...campaignOverviewState,
        platformView: "ios",
      });
    }
    if (action.platform === "web") {
      setCampaignOverviewState({
        ...campaignOverviewState,
        platformView: "web",
      });
    }
    setCampaignModalState({
      ...campaignModalState,
      notificationModal: !campaignModalState.notificationModal,
    });
  };
 const handleViewNewsletter = async (html, index) => {
  var newWindow = window.open();
  newWindow.document.write(html);
 }
  return (
    <CampaignOverview
      {...props}
      push={props.push}
      toggleDeleteModal={toggleDeleteModal}
      modalState={campaignModalState.deleteModalOpen}
      clientsModalState={campaignModalState.clientsModal}
      campaignNewOverview={props.campaignOverview}
      setCampaignOverviewActiveTab={props.setCampaignOverviewActiveTab}
      userRole={props.userRole}
      toggleClientsModal={toggleClientsModal}
      UserList={getClientList()}
      tableColumns={tableColumns}
      handleActionStatusRequest={handleActionStatusRequest}
      handleViewNotification={handleViewNotification}
      notificationModal={campaignModalState.notificationModal}
      notificationTitle={props.campaignNewForm.formData.notificationTitle}
      notificationImage={props.campaignNewForm.formData.notificationImage}
      notificationBigImage={props.campaignNewForm.formData.notificationBigImage}
      notificationText={props.campaignNewForm.formData.notificationText}
      url={props.campaignNewForm.formData.url}
      platformView={campaignOverviewState.platformView}
      handleViewNewsletter = {handleViewNewsletter}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    campaignOverview: parseCampaignNewOverview(state.campaignNewOverview),
    clientOverview: parseClientOverview(state.clientOverview),
    clients: parseClients(state.clients),
    token: parseLogin(state.login).userInfo.token,
    userRole: state.login.getIn(["userInfo", "role", "name"]),
    campaignNewForm: parseCampgaignNewForm(state.campaignNewForm),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCampaignOverviewActiveTab: (payload) => {
      dispatch(setCampaignOverviewActiveTab(payload));
    },
    getCampaignById: (campaignId, token) => {
      dispatch(getCampaignById(campaignId, token));
    },
    getCampaignPlanById: (campaignPlanId, token) => {
      dispatch(getCampaignPlanById(campaignPlanId, token));
    },
    deleteCampaignById: (campaignId, token) => {
      dispatch(deleteCampaignById(campaignId, token));
    },
    getActionById: (actionId, token) => {
      dispatch(getActionById(actionId, token));
    },
    getActionStatusById: (messageId, token) => {
      dispatch(getActionStatusById(messageId, token));
    },
    resetState: () => {
      dispatch(resetState());
    },
    changeCampaignFormData: (key, value) => {
      dispatch(changeCampaignFormData(key, value));
    },
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(CampaignOverviewHOC);
