import React, {Component} from 'react';
import {connect} from 'react-redux';
import ForgotPassword from "../../components/Auth/forgotPassword";
import {parseLogin} from "../../helpers/parseImmutables";
import {isEmail} from "../../helpers/functions";
import {changeForgotPasswordFormSettings, requestResetForgottenPassword} from "../../actions/login";

const ForgotPasswordHOC = props => {

    const handleInputChange = (evt) => {
        props.changeForgotPasswordFormSettings(evt.target.name, evt.target.value)
    };

    const handleSubmit = (evt) => {
      evt.preventDefault();
      if(!isEmail(props.forgotPasswordForm.email)){
          props.changeForgotPasswordFormSettings('responseMessage', 'Email is not valid.');
          return 0;
      }
      props.requestResetForgottenPassword(props.forgotPasswordForm.email)
    };

    return (
        <ForgotPassword
            forgotPasswordForm={props.forgotPasswordForm}
            changeForgotPasswordFormSettings={props.changeForgotPasswordFormSettings}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
        />
    )
}

const mapStateToProps = state => {
    return {
        forgotPasswordForm: parseLogin(state.login).forgotPasswordForm
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeForgotPasswordFormSettings: (key, value) => {
            dispatch(changeForgotPasswordFormSettings(key, value))
        },
        requestResetForgottenPassword: (email) => {
            dispatch(requestResetForgottenPassword(email))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordHOC);