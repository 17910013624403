import * as ActionTypes from '../actions/index';
import { fromJS } from "immutable";
import {createReducer} from './helpers/createReducer';

const defaultStore = {
    id: '',
    brid: "",
    email: "",
    location: "",
    storeName: "",
    telephone: "",
    password: "",
    position: {
        lat: "",
        lng: ""
    }
};

const defaultState = fromJS({
    isFetching: false,
    adding: false,
    all: [],
    newstore: defaultStore,
    changedStore: defaultStore,
    selectedStore: "",
    showStore: false,
    deleting: false,
    isDeleteModalOpen: false,
    fetchingStore: false,
    patching: false,
    sendResponse: '',
    fetchingTotalStores: false,
    totalStores: '',
    rowsPerPage: 10,
    page: 1,
});

function getTotalStores(state, action) {
    return state
        .set('fetchingTotalStores', true);
}

function receiveTotalStores(state, action) {
    return state
        .set('fetchingTotalStores', false)
        .set('totalStores', action.payload.body.count);
}

function failedToGetTotalStores(state, action) {
    return state
        .set('fetchingTotalStores', false);
}

function requestStoreById(state, action) {
    return state
        .set('fetchingStore', true);
}

function receiveStoreById(state, action) {
    return state
        .setIn(['changedStore', 'brid'], "")
        .setIn(['changedStore', 'email'], "")
        .setIn(['changedStore', 'id'], action.payload.id)
        .setIn(['changedStore', 'location'], action.payload.location)
        .setIn(['changedStore', 'storeName'], action.payload.name)
        .setIn(['changedStore', 'telephone'], action.payload.telephone)
        .setIn(['changedStore', 'password'], "")
        .setIn(['changedStore', 'position', 'lat'], action.payload.position.lat)
        .setIn(['changedStore', 'position', 'lng'], action.payload.position.lng)
        .set('fetchingStore', false);
}

function failedToGetStoreById(state, action) {
    return state
        .set('fetchingStore', false)
        .set('sendResponse', 'Failed to get store.');
}

function changeStoreSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function requestDeleteStore(state, action) {
    return state
        .set('deleting', true);
}

function receiveDeleteStore(state, action) {
    return state
        .set('isDeleteModalOpen', false)
        .set('selectedStore', '')
        .set('deleting', false)
        .set('newstore', fromJS(defaultState.get('newstore')))
        .set('changedStore', fromJS(defaultState.get('changedStore')));
}

function receiveErrorDeleteStore(state, action) {
    return state
        .set('deleting', false)
        .set('sendResponse', 'Failed to delete store');
}

function resetDefaultStore(state, action) {
    return defaultState;
}

function showStore(state, action) {
    return state
        .set('showStore', true)
        .set('selectedStore', action.payload.key);
}

function submitExistingStore(state, action) {
    return state
        .set('patching', true);
}

function addNewStore(state, action) {
    return state
        .set('adding', true);
}

function changeNewStore(state, action) {
    return state
        .setIn(['newstore', action.payload.key], fromJS(action.payload.value));
}

function changeSelectedStore(state, action) {
    return state
        .setIn(['changedStore', action.payload.key], fromJS(action.payload.value));
}

function requestAllStores(state, action) {
    return state
        .set('isFetching', true);
}

function receiveAllStores(state, action) {
    return state
        .set('isFetching', false)
        .set('all', fromJS(action.payload.body));
}

function receiveErrorGetStores(state, action) {
    return state
        .set('isFetching', false);
}

function receiveAddStore(state, action) {
    return state
        .set('adding', false)
        .set('newstore', fromJS(defaultStore))
        .set('sendResponse', 'Store was created successfully.');
}

function receiveErrorAddStore(state, action) {
    return state
        .set('adding', false)
        .set('sendResponse', 'Failed to create store.');
}

function receiveEditStore(state, action) {
    return state
        .set('showStore', false)
        .set('adding', false)
        .set('patching', false)
        .set('sendResponse', 'Store was edited successfully.');
}

function receiveErrorEditStore(state, action) {
    return state
        .set('patching', false)
        .set('sendResponse', 'Failed to update store.');
}

export const stores = createReducer(defaultState, {
    [ActionTypes.GET_TOTAL_STORES]: getTotalStores,
    [ActionTypes.RECEIVE_TOTAL_STORES]: receiveTotalStores,
    [ActionTypes.FAILED_TO_GET_TOTAL_STORES]: failedToGetTotalStores,
    [ActionTypes.REQUEST_STORE_BY_ID]: requestStoreById,
    [ActionTypes.RECEIVE_STORE_BY_ID]: receiveStoreById,
    [ActionTypes.FAILED_TO_GET_STORE_BY_ID]: failedToGetStoreById,
    [ActionTypes.CHANGE_STORE_SETTINGS]: changeStoreSettings,
    [ActionTypes.REQUEST_DELETE_STORE]: requestDeleteStore,
    [ActionTypes.RECEIVE_DELETE_STORE]: receiveDeleteStore,
    [ActionTypes.RECEIVE_ERROR_DELETE_STORE]: receiveErrorDeleteStore,
    [ActionTypes.RESET_DEFAULT_STORE]: resetDefaultStore,
    [ActionTypes.SHOW_STORE]: showStore,
    [ActionTypes.SUBMIT_EXISTING_STORE]: submitExistingStore,
    [ActionTypes.ADD_NEW_STORE]: addNewStore,
    [ActionTypes.TEMP_NEW_STORE]: changeNewStore,
    [ActionTypes.CHANGE_SELECTED_STORE]: changeSelectedStore,
    [ActionTypes.REQUEST_ALL_STORES]: requestAllStores,
    [ActionTypes.RECEIVE_ALL_STORES]: receiveAllStores,
    [ActionTypes.RECEIVE_ERROR_GET_STORES]: receiveErrorGetStores,
    [ActionTypes.RECEIVE_ADD_STORE]: receiveAddStore,
    [ActionTypes.RECEIVE_ERROR_ADD_STORE]: receiveErrorAddStore,
    [ActionTypes.RECEIVE_EDIT_STORE]: receiveEditStore,
    [ActionTypes.RECEIVE_ERROR_EDIT_STORE]: receiveErrorEditStore,
    [ActionTypes.RESET_STORES_STATE]: resetDefaultStore
});