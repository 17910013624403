import React, { createElement, setState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import noImage from "../../assets/images/unnamed.png";

const styles = (theme) => ({
    root: {
      width: "90%",
    },
    button: {
      marginTop: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    actionsContainer: {
      marginBottom: theme.spacing.unit * 2,
    },
    resetContainer: {
      padding: theme.spacing.unit * 3,
    },
    stepContent: {
      overflow: "visible",
    },
    cardWrapper: {
      overflow: "visible",
    },
    chip: {
      margin: theme.spacing.unit,
    },
  });
const WebNotification = (props) => {
    return ( <div style={{display:'flex',width:600}}>


    <Typography variant="button" style={{marginTop:62,paddingRight:10}} > web </Typography>
  
    <div style={{border:"2px solid rgba(0, 0, 0, 0.1)",borderRadius: 5, width:400,marginTop:20,display:'flex',
              backgroundColor: "#e0e0e0",}} >
  
    {props.notificationImage ? (
            <img
              style={{
                textAlign: "center",
                padding: 5,
                maxWidth: 100,
                maxHeight: 100,
                borderRadius: 5,
              }}
              // src={
              //   props.notificationImage
              // }
              src={`data:image/png;base64,${props.notificationImage}`}
            />
          ) : (
            <img
              style={{
                textAlign: "center",
                padding: 5,
                maxWidth: 100,
                maxHeight: 100,
                borderRadius: 5,
              }}
              src={noImage}
            ></img>
          )}
          <div>
          <Typography
            style={{
              textAlign: "left",
              marginTop:25,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 270,
              color:'black'
            }}
          >
            {props.notificationTitle}
            <br></br>
            {props.notificationText}{" "}
  
          </Typography>
          <Typography variant='caption' style={{ whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 270,}}>{props.url.split('?')[0]}</Typography>
  </div>
  
  </div>
  </div>)}
    export default withStyles(styles)(WebNotification);