import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import OffersFormComponent from "../../components/Offers/OffersFormComponent";
import {
    addingOffer, changeNewOffer, changeOfferSettings, changeStoredOffer, editingOffer, getOfferById, gettingAllOffers,
    toChangeOffer, toDeleteOffer
} from "../../actions/offers";
import {gettingAllProducts} from "../../actions/products";
import {toGetCategories} from "../../actions/categories";
import {getDiscounts} from "../../actions/discounts";
import {gettingAllStores} from "../../actions/stores";
import _ from 'lodash';
import moment from 'moment';
import {
    parseCategories, parseDiscounts, parseLogin, parseOffers, parseProducts,
    parseStores
} from "../../helpers/parseImmutables";
import {useHistory, useLocation} from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";

const OfferFormHOC = props => {
    const history = useHistory();
    const location = useLocation();
    const prevDeleting = usePrevious(props.offers.deleting)
    const prevFetchingOffer = usePrevious(props.offers.fetchingOffer);

    useEffect(() => {
        if(prevDeleting && !props.offers.deleting && !props.offers.sendResponse){
            history.push('/offer');
        }
    }, [prevDeleting, props.offers.deleting, props.offers.sendResponse])

    useEffect(() => {
        if(prevFetchingOffer && !props.offers.fetchingOffer){
            if(props.categories.all.length === props.offers.changedOffer.categories.length){
                props.temp_changed_offer('allCategories', true);
            }
            let totalProducts = 0;
            _.forEach(props.offers.changedOffer.categories, c => {
                let category = _.find(props.categories.all, a => {return a.id === c});
                if(category) {
                    totalProducts += category.products.length;
                }
            });
            if(props.offers.changedOffer.products.length === totalProducts){
                props.temp_changed_offer('all_products', true);
            }
        }
    },[
        prevDeleting, props.offers.deleting,
        props.categories.all, props.offers.changedOffer.categories, props.offers.changedOffer.categories,
        props.offers.changedOffer.products,
    ]);

    useEffect(() => {
        props.getAllCategories(props.login.userInfo.brand.id, props.login.userInfo.token);
        props.getDiscounts(props.login.userInfo.token);
        // props.getStores(props.login.userInfo.brand.id);
        if(location.pathname.includes('edit')){
            props.getOfferById(_.last(location.pathname.split('/')), props.login.userInfo.token)
        }
    }, [])

    const handleDateChange = (name, value) => {
        let valueUtc = value;
        value = moment(value).format('YYYY-MM-DD');
        name = name.replace("_readable", '');
        if (location.pathname.includes('edit')) {
            props.temp_changed_offer(name, valueUtc);
            props.temp_changed_offer(name + '_readable', value);
        }
        else if (location.pathname.includes('add')){
            props.temp_offer(name, valueUtc);
            props.temp_offer(name + '_readable', value);
        }
    };

    const handleCheckBoxChange = (name,value) => {
        if (location.pathname.includes('edit')) {
            props.temp_changed_offer(name, value);
        }
        else if (location.pathname.includes('add')) {
            props.temp_offer(name, value);
        }
    };

    const handleSelectChange = (name, value) => {
        if (name === "discount" || name === "points_required") {
            // value = parseInt(value);
        }
        if (location.pathname.includes('edit')) {
            props.temp_changed_offer(name, value);
            if (name === "category" && value === "all") {
                props.temp_changed_offer("product", value);
            }
        }
        else if (location.pathname.includes('add')) {
            props.temp_offer(name, value);
            if (name === "category" && value === "all") {
                props.temp_offer("product", value);
            }
        }
    };

    const handleInputChange = (evt) => {
        var target = evt.target;
        var value = target.value;
        var name = target.name;
        if (name === "discount" || name === "points_required") {
            // value = parseInt(value);
        }
        if (location.pathname.includes('edit')) {
            props.temp_changed_offer(name, value);
            if (name === "category" && value === "all") {
                props.temp_changed_offer("product", value);
            }
        }
        else if (location.pathname.includes('add')) {
            props.temp_offer(name, value);
            if (name === "category" && value === "all") {
                props.temp_offer("product", value);
            }
        }
    };

    const handleSubmit = (evt) => {
        if (evt.key === "Enter" || typeof(evt.key) === "undefined") {
            evt.preventDefault();
            if (location.pathname.includes('edit')) {
                if(!props.offers.changedOffer.discount_type){
                    props.changeStoreSettings('sendResponse', 'Discount type is required.')
                    return 0;
                }
                props.editOffer({...props.offers.changedOffer, brandId: props.login.userInfo.brand.id}, props.offers.selectedOffer, props.login.userInfo.token)
            }
            else if (location.pathname.includes('add')) {
                if(!props.offers.newoffer.discount_type){
                    props.changeStoreSettings('sendResponse', 'Discount type is required.')
                    return 0;
                }
                props.addOffer(props.offers.newoffer, props.login.userInfo.brand.id, props.login.userInfo.token)
            }
        }
    };

    const getProductArray = (withCategoryFilter) => {
        return [] //tempory fix
        let productArray = [];
        if (withCategoryFilter) {
            for (let index in props.products.all) {
                if (props.categories.all[props.products.all[index].cid].brid === props.login.userInfo.brid && props.products.all[index].cid === withCategoryFilter) {
                    productArray.push({
                        key: props.products.all[index].pid,
                        value: props.products.all[index].product_name
                    })
                }

            }
        }
        else {
            for (let index in props.products.all) {
                if (props.categories.all[props.products.all[index].cid].brid === props.login.userInfo.brid) {
                    productArray.push({
                        key: props.products.all[index].pid,
                        value: props.products.all[index].product_name
                    })
                }

            }
        }
        return productArray;
    };

    const getOfferArray = () => {
        let offerArray = [];
        for (let i in props.offers.all) {
            if (props.offers.all[i].brid === props.login.userInfo.brid) {
                offerArray.push(props.offers.all[i]);
            }
        }
        offerArray = offerArray.map((val) => {
            return {key: val.oid, value: val.offer_name}
        });
        return offerArray
    };

    const showOffer = (evt) => {
        if (props.offers.all[evt.target.value]) {
            props.toggleOffer(evt.target.value, props.offers.all[evt.target.value]);
        }
        else {
            props.toggleOffer(evt.target.value, props.offers.changedOffer);
        }
    };

    const handleDelete = (evt) => {
        props.deleteOffer(props.offers.changedOffer.oid, props.login.userInfo.token)

    };

    const getProductOptions = (name, newCategories, offer) => {
        let products = [...offer.products];
        let productsToBeRemoved = [];
        let finalProducts = [];
        if(name === 'categories' && (newCategories.length < offer.categories.length)) {
            productsToBeRemoved = [];
            _.forEach(offer.categories, v => {
                let cat = _.find(newCategories, c => {
                    return c === v
                });
                if (!cat) {
                    let catToBeRemoved = _.find(props.categories.all, all => {
                        return all.id === v
                    });
                    if(catToBeRemoved){
                        _.forEach(catToBeRemoved.products, p => {
                            productsToBeRemoved.push(p.id)
                        })

                    }
                }
            });
            _.forEach(products, p => {
                if(!productsToBeRemoved.includes(p)){
                    finalProducts.push(p)
                }
            });
            return finalProducts;
        }
        else {
            if(offer.all_products){
                let newP = [];
                _.forEach(newCategories, c => {
                    let category = _.find(props.categories.all, i => {return i.id === c});
                    if(category){
                        _.forEach(category.products, p => {
                            if(!newP.includes(p.id)) {
                                newP.push(p.id);
                            }
                        });
                    }
                });
                return newP;
            }
            else {
                return products;
            }
        }
    };

    const handleSelectedProducts = (name, value) => {
        if (location.pathname.includes("add")) {
            let newProducts = getProductOptions(name, value, props.offers.newoffer);
            props.temp_offer(name, value);
            if(name === 'categories')
                props.temp_offer('products', newProducts);
        }
        else if (location.pathname.includes("edit")) {
            let newProducts = [];
            newProducts = getProductOptions(name, value, props.offers.changedOffer);
            props.temp_changed_offer(name, value);
            if(name === 'categories')
                props.temp_changed_offer('products', newProducts)
        }
    };

    const handleSelectedStores = (evt) => {
        let options = evt.target.options;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        if(value.includes('all'))
            value = ['all'];
        if (location.pathname.includes("add")) {
            props.temp_offer(evt.target.name, value)
        }
        else if (location.pathname.includes("edit")) {
            props.temp_changed_offer(evt.target.name, value)
        }
    } ;

    const getProducts = (offer) => {
        let products = [];
        _.forEach(offer.categories, item => {
            let category ;
            try {
                category = _.find(props.categories.all, o => {return o.id === parseInt(item)})
            }
            catch (e){
                category = {products:[]};
            }
            if(category){
                products = [...products, ...category.products.map(p => {return {key: p.id, value: p.name}})]
            }
        });
        return products;
    };

    const includeAllProducts = (name, value) => {
        let offer = location.pathname.includes('/edit')?props.offers.changedOffer:props.offers.newoffer;
        let products =[...offer.products];
        if(value){
            _.forEach(offer.categories, c => {
                let category = _.find(props.categories.all, i => {return i.id === c});
                if(category){
                    _.forEach(category.products, p => {
                        if(!products.includes(p.id)) {
                            products.push(p.id);
                        }
                    });
                }
            });
        }
        if(location.pathname.includes('/edit')){
            props.temp_changed_offer('products', products);
            props.temp_changed_offer(name, value);
        }
        else {
            props.temp_offer('products', products);
            props.temp_offer(name, value);
        }
    };

    const includeAllCategories = (name, value) => {
        let offer = location.pathname.includes('/edit')?props.offers.changedOffer:props.offers.newoffer;
        let changeOffer = location.pathname.includes('/edit')?props.temp_changed_offer:props.temp_offer;
        let categories =[...offer.categories];
        let totalProducts = 0;
        if(value){
            categories = props.categories.all.map(item => item.id);
            _.forEach(props.categories.all, c => {
                totalProducts += c.products.length;
            });
        }
        else {
            _.forEach(offer.categories, c => {
                let category = _.find(props.categories.all, i => {return i.id === c});
                if(category){
                    totalProducts += category.products.length;
                }
            });
        }
        if(totalProducts === offer.products.length) {
            changeOffer('all_products', true);
        }
        else{
            changeOffer('all_products', false);
        }
        changeOffer('categories', categories);
        changeOffer(name, value);
    };

    return (
        <OffersFormComponent
            includeAllCategories={includeAllCategories}
            includeAllProducts={includeAllProducts}
            isFetching={location.pathname.includes('edit') && props.offers.fetchingOffer}
            handleSelectChange={handleSelectChange}
            getProducts={getProducts}
            handleSelectedProducts={handleSelectedProducts}
            stores={props.stores}
            handleSelectedStores={handleSelectedStores}
            discounts={props.discounts}
            handleDelete={handleDelete}
            offersStore={props.offers}
            changeStoreSettings={props.changeStoreSettings}
            selectedOffer={props.offers.selectedOffer}
            showOffer={showOffer}
            offers={getOfferArray()}
            isNew={location.pathname.includes('edit')?false:true}
            categories={props.categories}
            products={location.pathname.includes('edit')?getProductArray(props.offers.changedOffer.category):getProductArray(props.offers.newoffer.category)}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            handleCheckBoxChange={handleCheckBoxChange}
            handleDateChange={handleDateChange}
            TargetOffer={location.pathname.includes('edit')?props.offers.changedOffer:props.offers.newoffer}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        login: parseLogin(state.login),
        router: state.router,
        offers: parseOffers(state.offers),
        categories: parseCategories(state.categories),
        products: parseProducts(state.products),
        discounts: parseDiscounts(state.discounts),
        stores: parseStores(state.stores)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        editOffer: (changedOffer, offerId, token) => {
            dispatch(editingOffer(changedOffer, offerId, token));
        },
        addOffer: (data, offerBrand, token) => {
            dispatch(addingOffer(data, offerBrand, token));
        },
        temp_offer: (offerKey, offerValue) => {
            dispatch(changeNewOffer(offerKey, offerValue))
        },
        temp_changed_offer: (offerKey, offerValue) => {
            dispatch(changeStoredOffer(offerKey, offerValue))
        },
        getProducts: () => {
            dispatch(gettingAllProducts());
        },
        getAllCategories: (brandId, token) => {
            dispatch(toGetCategories(brandId, token))
        },
        getOffers: () => {
            dispatch(gettingAllOffers())
        },
        toggleOffer: (selectedOffer, updatedOfferObj) => {
            dispatch(toChangeOffer(selectedOffer, updatedOfferObj))
        },
        changeStoreSettings: (key, value) => {
            dispatch(changeOfferSettings(key, value))
        },
        deleteOffer: (offerId, token) => {
            dispatch(toDeleteOffer(offerId, token))
        },
        getDiscounts: (token) => {
            dispatch(getDiscounts(token))
        },
        getStores: (brandId) => {
            dispatch(gettingAllStores(brandId))
        },
        getOfferById: (offerId, token) => {
            dispatch(getOfferById(offerId, token))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferFormHOC);

