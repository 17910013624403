import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function changePointsMultiplierFormSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_POINTS_MULTIPLIER_FORM_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

export function changePointsMultiplierFormData(payload) {
    return {
        type: ActionTypes.CHANGE_POINT_MULTIPLIER_FORM_DATA,
        payload: payload
    }
}

export function createPointsMultiplier(payload) {
    return {
        type: ActionTypes.CREATE_POINTS_MULTIPLIER,
        payload: payload
    }
}

export function createPointsMultiplierSuccess(payload) {
    return {
        type: ActionTypes.CREATE_POINTS_MULTIPLIER_SUCCESS,
        payload: payload
    }
}

export function createPointsMultiplierFail(payload) {
    return {
        type: ActionTypes.CREATE_POINTS_MULTIPLIER_FAIL,
        payload: payload
    }
}

export function deletePointMultiplierById(multiplierId) {
    return {
        type: ActionTypes.DELETE_POINT_MULTIPLIER,
        payload:  multiplierId
    }
}

export function deletePointMultiplierByIdSuccess(payload) {
    return {
        type: ActionTypes.DELETE_POINT_MULTIPLIER_SUCCESS,
        payload: payload
    }
}

export function deletePointMultiplierByIdFailed(payload) {
    return {
        type: ActionTypes.DELETE_POINT_MULTIPLIER_FAIL,
        payload: payload
    }
}

export function editPointMultiplierById(payload) {
    return {
        type: ActionTypes.EDIT_POINT_MULTIPLIER,
        payload: payload
    }
}

export function editPointMultiplierByIdSuccess(payload) {
    return {
        type: ActionTypes.EDIT_POINT_MULTIPLIER_SUCCESS,
        payload: payload
    }
}

export function editPointMultiplierByIdFailed(payload) {
    return {
        type: ActionTypes.EDIT_POINT_MULTIPLIER_FAIL,
        payload: payload
    }
}
export const resetMultipliersState = actionCreator(ActionTypes.RESET_MULTIPLIERS_NEW_STATE);