import {fromJS} from "immutable";
import * as ActionTypes from '../actions/index';
import {createReducer} from './helpers/createReducer';

const defaultState = fromJS({
    fetching: false,
    pointSystem: {
        id: '',
        birthdayPoints: '',
        joinTheProductPoints: '',
        brandId: '',
        includeBirthday: false,
        includeJoinTheProduct: false,
        includePointsEarnedPerEuro: false,
        includePointsEarnedFromProducts: false,
        includePointsFromCombinationsOfProducts: false,
        combinationsOfProducts: [],
        levels: [],
    },
    responseMessage: '',
    extraCombinations: [],
    posting: false,
    activeTab: 0,
    patching: false
});

function createPsCombinations(state, action) {
    return state
        .set('posting', true);
}

function receiveCreatePsCombinationsResponse(state, action) {
    return state
        .set('posting', false)
        .set('extraCombinations', fromJS([]));
}

function failedToCreatePsCombinations(state, action) {
    return state
        .set('posting', false);
}

function changePointOverviewStoreSettings(state, action) {
    return state
        .set(action.payload.key, fromJS(action.payload.value));
}

function changeExtraCombinationsSettings(state, action) {
    return state
        .set('extraCombinations', fromJS(state.get('extraCombinations').
        updateIn([action.payload.index, action.payload.key], extra => (fromJS(action.payload.value)))));
}

function getCurrentPointSystem(state, action) {
    return state
        .set('fetching', true);
}

function receiveCurrentPointSystem(state, action) {
    let ps;
    if(action.payload.body.hasOwnProperty('id')){
        ps = action.payload.body;
    }
    else {
        ps = defaultState.get('pointSystem')
    }
    return state
        .set('pointSystem', fromJS(ps))
        .set('fetching', false);
}

function failedToReceiveCurrentPointSystem(state, action) {
    return state
        .set('fetching', false);
}

function setPointsOverviewActiveTab(state, action) {
    return state
        .set('activeTab', action.payload.activeTab)
}

function resetState(state, action) {
    return defaultState;
}

function updatePointSystem(state, action) {
    return state
        .set('patching', true)
}

function updatePointSystemSuccess(state, action) {
    const pointSystem =  state.get('pointSystem').toJS();
    const updatedPointSystem = {
        ...pointSystem,
        ...action.payload.body
    };
    return state
        .set('patching', false)
        .set('pointSystem', fromJS(updatedPointSystem))
        .set('responseMessage', 'Point system was updated successfully')
}

function updatePointSystemFailed(state, action) {
    let error = '';
    try {
        error = action.payload.response.body.error.message
    } catch (e) {
        error = 'Failed to update the point system'
    }
    return state
        .set('patching', false)
        .set('responseMessage', error)
}

export const pointsOverview = createReducer(defaultState, {
    [ActionTypes.CREATE_PS_COMBINATIONS]: createPsCombinations,
    [ActionTypes.RECEIVE_CREATE_PS_COMBINATIONS_RESPONSE]: receiveCreatePsCombinationsResponse,
    [ActionTypes.FAILED_TO_CREATE_PS_COMBINATIONS]: failedToCreatePsCombinations,
    [ActionTypes.CHANGE_POINT_OVERVIEW_STORE_SETTINGS]: changePointOverviewStoreSettings,
    [ActionTypes.CHANGE_EXTRA_COMBINATIONS_SETTINGS]: changeExtraCombinationsSettings ,
    [ActionTypes.GET_CURRENT_POINT_SYSTEM]: getCurrentPointSystem ,
    [ActionTypes.RECEIVE_CURRENT_POINT_SYSTEM]: receiveCurrentPointSystem ,
    [ActionTypes.FAILED_TO_RECEIVE_CURRENT_POINT_SYSTEM]: failedToReceiveCurrentPointSystem ,
    [ActionTypes.SET_POINTS_OVERVIEW_ACTIVE_TAB]: setPointsOverviewActiveTab ,
    [ActionTypes.UPDATE_POINT_SYSTEM]: updatePointSystem,
    [ActionTypes.UPDATE_POINT_SYSTEM_SUCCESS]: updatePointSystemSuccess,
    [ActionTypes.UPDATE_POINT_SYSTEM_FAILED]: updatePointSystemFailed,
    [ActionTypes.RESET_POINTS_OVERVIEW_STATE]: resetState,
});