import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { Provider } from 'react-redux';
import store, {history, persistor} from "./store/store";
import {PersistGate} from "redux-persist/es/integration/react";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import Colors from './helpers/constants/colors';

const theme = createMuiTheme({
    palette: {
        primary: { main: Colors.COFFEE_ISLAND_YELLOW },
        secondary: { main: Colors.COFFEE_BROWN },
    },
});

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <MuiThemeProvider theme={theme}>
                <App History={history}/>
            </MuiThemeProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
