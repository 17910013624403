import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import EnabledOffers from "../../components/EnabledOffers/enabledOffers";
import {parseEnabledOffers} from "../../helpers/parseImmutables";
import _ from "lodash";
import {
    changeEnabledOffersOrder,
    changeEnabledOffersState,
    countEnabledOffers,
    getEnabledOffers, resetEnabledOffersState
} from "../../actions/enabledOffers";
import {_getSkipFilter, getTablePage} from "../../helpers/functions";
import moment from "moment";
import {getFormValues, destroy} from 'redux-form/immutable'
import usePrevious from "../../hooks/usePrevious";
import {useHistory} from "react-router-dom";

const tableColumns = [
    {name: 'Id', key: 'id'},
    {name: 'Person Id', key: 'personId'},
    {name: 'Offer Id', key: 'offerId'},
    {name: 'Redeemed', key: 'redeemed'},
    {name: 'Points when enabled', key: 'pointsWhenEnabled'},
    {name: 'Coupon code', key: 'couponCode'},
    {name: 'Issue Date', key: 'issueDate'},
    {name: 'Erp coupon id', key: 'erpCouponId'},
    {name: 'Expired', key: 'expired'},
    {name: 'Coupon start date', key: 'couponStartDate'},
    {name: 'Coupon end date', key: 'couponEndDate'},
];

const EnabledOffersHOC = props => {
    const prevOrder = usePrevious(props.order);
    const prevOrderBy = usePrevious(props.orderBy)
    const prevPage = usePrevious(props.page);
    const prevRowsPerPage = usePrevious(props.rowsPerPage);
    const history = useHistory();

    useEffect(() => {
        return () => {
            props.resetEnabledOffersState();
        }
    }, [])

    useEffect(() => {
        const whereFilter = getEnabledOffersWhereFilter(props.filtersForm);
        props.countEnabledOffers({where: whereFilter});
    }, [])

    useEffect(() => {
        if( props.page !== prevPage ||
            props.rowsPerPage !== prevRowsPerPage ||
            props.order !== prevOrder ||
            props.orderBy !== prevOrderBy
        ) {
            const whereFilter = getEnabledOffersWhereFilter(props.filtersForm);
            props.countEnabledOffers({where: whereFilter});
            props.getEnabledOffers({
                filter: {
                    skip: _getSkipFilter(props.page, props.rowsPerPage),
                    where: whereFilter,
                    limit: props.rowsPerPage,
                    order: tableColumns[props.orderBy].key + ' ' + props.order.toUpperCase()
                }
            });
        }
    }, [
        props.filtersForm,
        prevOrder,
        prevOrderBy,
        prevPage,
        prevRowsPerPage,
        props.order,
        props.orderBy,
        props.page,
        props.rowsPerPage
    ])

    useEffect(() => {
        return () => {
            props.destroy('enabledOffersFilterForm');
        }
    }, []);

    const getEnabledOffersWhereFilter = (formIM) => {
        if(!formIM){
            return {}
        }

        const form = formIM.toJS();
        const whereFilter = [];
        if(form.couponCode){
            whereFilter.push({couponCode: form.couponCode});
        }

        if(form.erpCouponId){
            whereFilter.push({erpCouponId: form.erpCouponId});
        }

        if(form.id){
            whereFilter.push({id: form.id});
        }

        if(form.offerId){
            whereFilter.push({offerId: form.offerId});
        }

        if(form.personId){
            whereFilter.push({personId: form.personId});
        }

        if(form.expired){
            whereFilter.push({expired: form.expired});
        }

        if(form.redeemed){
            whereFilter.push({redeemed: form.redeemed});
        }

        const issueDateFilter = [];
        if(moment(form.issueDateFrom, 'YYYY-MM-DD').isValid()){
            issueDateFilter.push({issueDate: {gte: moment(form.issueDateFrom, 'YYYY-MM-DD')
                        .set({hour:0,minute:0,second:0,millisecond:0})
                        .toDate()}});
        }

        if(moment(form.issueDateTo, 'YYYY-MM-DD').isValid()){
            issueDateFilter.push({issueDate: {lte: moment(form.issueDateTo, 'YYYY-MM-DD')
                        .set({hour:23,minute:59,second:59,millisecond:59})
                        .toDate()}});
        }

        whereFilter.push({and: issueDateFilter});

        return {or: whereFilter}
    };

    const getListRows = () => {
        let arr = [];
        let allEnabledOffers = [...props.enabledOffers];
        _.forEach(allEnabledOffers, c => {
            arr.push(
                {
                    id: c.id,
                    data: [
                        c.id,
                        c.personId,
                        c.offerId,
                        c.redeemed?'true':'false',
                        c.pointsWhenEnabled,
                        c.couponCode,
                        moment(c.issueDate).isValid()?
                            moment(c.issueDate).format('DD/MM/YYYY HH:mm:ss'):'',
                        c.erpCouponId,
                        c.expired?'true':'false',
                        moment(c.couponStartDate).isValid()?
                            moment(c.couponStartDate).format('DD/MM/YYYY HH:mm:ss'):'',
                        moment(c.couponEndDate).isValid()?
                            moment(c.couponEndDate).format('DD/MM/YYYY HH:mm:ss'):'',
                    ],
                    label: `Enabled offer id: ${c.id}`
                }
            )
        });
        return arr;
    };

    const handleRowSelect = (enabledOfferId) => {
        history.push('/enabled-offer/' + enabledOfferId)

    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.totalEnabledOffers / rowsPerPage);
        let currentPage = props.page;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeEnabledOffersState({key: 'rowsPerPage', value: rowsPerPage});
        props.changeEnabledOffersState({key: 'page', value: currentPage});
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.page,  props.totalEnabledOffers, props.rowsPerPage);
        props.changeEnabledOffersState({key: 'page', value: newPage});
    };


    const onRequestSort = (payload) => {
        props.countEnabledOffers({where: {}});
        props.changeEnabledOffersOrder(payload);
    };

    return(
        <EnabledOffers
            {...props}
            enabledOffers={getListRows()}
            enabledOffersColumns={tableColumns}
            handleRowSelect={handleRowSelect}
            handleRowsPerPage={handleRowsPerPage}
            handlePageChange={handlePageChange}
            onRequestSort={onRequestSort}
            getEnabledOffersWhereFilter={getEnabledOffersWhereFilter}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        enabledOffers: parseEnabledOffers(state.enabledOffers).enabledOffers,
        rowsPerPage: parseEnabledOffers(state.enabledOffers).rowsPerPage,
        page: parseEnabledOffers(state.enabledOffers).page,
        orderBy: parseEnabledOffers(state.enabledOffers).orderBy,
        order: parseEnabledOffers(state.enabledOffers).order,
        totalEnabledOffers: parseEnabledOffers(state.enabledOffers).totalEnabledOffers,
        filtersForm: getFormValues('enabledOffersFilterForm')(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEnabledOffers: (payload) => {
            dispatch(getEnabledOffers(payload))
        },
        changeEnabledOffersOrder: (payload) => {
            dispatch(changeEnabledOffersOrder(payload))
        },
        changeEnabledOffersState: (payload) => {
            dispatch(changeEnabledOffersState(payload))
        },
        countEnabledOffers: (payload) => {
            dispatch(countEnabledOffers(payload))
        },
        destroy: (form) => {
            dispatch(destroy(form))
        },
        resetEnabledOffersState: payload => {
            dispatch(resetEnabledOffersState(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EnabledOffersHOC);