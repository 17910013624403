import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function createCampaign(payload) {
    return {
        type: ActionTypes.CREATE_CAMPAIGN,
        payload: payload
    }
}

export function createCampaignSuccess(payload) {
    return {
        type: ActionTypes.CREATE_CAMPAIGN_SUCCESS,
        payload: payload
    }
}

export function createCampaignFail(payload) {
    return {
        type: ActionTypes.CREATE_CAMPAIGN_FAIL,
        payload: payload
    }
}

export function createAction(payload) {
    return {
        type: ActionTypes.CREATE_ACTION,
        payload: payload
    }
}

export function createActionSuccess(payload) {
    return {
        type: ActionTypes.CREATE_ACTION_SUCCESS,
        payload: payload
    }
}

export function createActionFail(payload) {
    return {
        type: ActionTypes.CREATE_ACTION_FAIL,
        payload: payload
    }
}

export function changeCampaignFormSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_CAMPAIGN_FORM_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

export function changeCampaignFormData(payload) {
    return {
        type: ActionTypes.CHANGE_CAMPAIGN_FORM_DATA,
        payload: payload
    }
}

export function getActions(payload) {
    return {
        type: ActionTypes.GET_ACTIONS,
        payload: payload
    }
}

export function getEvents(payload) {
    return {
        type: ActionTypes.GET_EVENTS,
        payload: payload
    }
}

export function failedToGetActionsEvents(payload)
{
    return {
        type: ActionTypes.FAILED_TO_GET_ACTIONS_EVENTS,
        payload: payload
    }
}

export function receiveActions(actions){
    return {
        type: ActionTypes.RECEIVE_ACTIONS,
        payload: actions
    }
}

export function receiveEvents(events){
    return {
        type: ActionTypes.RECEIVE_EVENTS,
        payload: events
    }
}

export function editCampaign(payload) {
    return {
        type: ActionTypes.EDIT_CAMPAIGN,
        payload: payload
    }
}

export function editCampaignSuccess(payload) {
    return {
        type: ActionTypes.EDIT_CAMPAIGN_SUCCESS,
        payload: payload
    }
}

export function editCampaignFail(payload) {
    return {
        type: ActionTypes.EDIT_CAMPAIGN_FAIL,
        payload: payload
    }
}

export function editAction(payload) {
    return {
        type: ActionTypes.EDIT_ACTION,
        payload: payload
    }
}

export function editActionSuccess(payload) {
    return {
        type: ActionTypes.EDIT_ACTION_SUCCESS,
        payload: payload
    }
}

export function editActionFail(payload) {
    return {
        type: ActionTypes.EDIT_ACTION_FAIL,
        payload: payload
    }
}
export function sendTestEmail(payload) {
    return {
        type: ActionTypes.SEND_TEST_EMAIL,
        payload: payload
    }
}

export function sendTestEmailSuccess(payload) {
    return {
        type: ActionTypes.SEND_TEST_EMAIL_SUCCESS,
        payload: payload
    }
}

export function sendTestEmailFail(payload) {
    return {
        type: ActionTypes.SEND_TEST_EMAIL_FAIL,
        payload: payload
    }
}

export const resetCampaignsNewFormState = actionCreator(ActionTypes.RESET_CAMPAIGNS_NEW_FORM_STATE)
