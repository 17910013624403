import * as ActionTypes from './index';
import {actionCreator} from "../helpers/functions";

export function updatePointSystem(payload) {
    return {
        type: ActionTypes.UPDATE_POINT_SYSTEM,
        payload: payload
    }
}

export function updatePointSystemSuccess(payload) {
    return {
        type: ActionTypes.UPDATE_POINT_SYSTEM_SUCCESS,
        payload: payload
    }
}

export function updatePointSystemFailed(payload) {
    return {
        type: ActionTypes.UPDATE_POINT_SYSTEM_FAILED,
        payload: payload
    }
}

export function setPointsOverviewActiveTab(payload) {
    return {
        type: ActionTypes.SET_POINTS_OVERVIEW_ACTIVE_TAB,
        payload: payload
    }
}

//action that requests create new combinations for the current point system
export function createPsCombinations(combinations, brandId, token) {
    return {
        type: ActionTypes.CREATE_PS_COMBINATIONS,
        payload: {
            combinations,
            brandId,
            token
        }
    }
}

//action that receives create ps combos response
export function receiveCreatePsCombinationsResponse(response) {
    return {
        type: ActionTypes.RECEIVE_CREATE_PS_COMBINATIONS_RESPONSE,
        payload: response
    }
}

//action that receives failed response while trying to create new combos for the current point system
export function failedToCreatePsCombinations(response) {
    return {
        type: ActionTypes.FAILED_TO_CREATE_PS_COMBINATIONS,
        payload: response
    }
}

//action that changes the point overview store settings
export function changePointOverviewStoreSettings(key, value) {
    return {
        type: ActionTypes.CHANGE_POINT_OVERVIEW_STORE_SETTINGS,
        payload: {
            key,
            value
        }
    }
}

//action that changes the extra combinations settings
export function changeExtraCombinationsSettings(index, key, value) {
    return {
        type: ActionTypes.CHANGE_EXTRA_COMBINATIONS_SETTINGS,
        payload: {
            index,
            key,
            value
        }
    }
}

//action that requests get the point system of the brand
export function getCurrentPointSystem(brandId, token) {
    return {
        type: ActionTypes.GET_CURRENT_POINT_SYSTEM,
        payload: {
            brandId,
            token
        }
    }
}

//action that receives the brands current point system
export function receiveCurrentPointSystem(pointSystem) {
    return {
        type: ActionTypes.RECEIVE_CURRENT_POINT_SYSTEM,
        payload: pointSystem
    }
}

//action that receives failed response while requesting the brands current point system
export function failedToReceiveCurrentPointSystem(response) {
    return {
        type: ActionTypes.FAILED_TO_RECEIVE_CURRENT_POINT_SYSTEM,
        payload: response
    }
}

export const resetPointsOverviewState = actionCreator(ActionTypes.RESET_POINTS_OVERVIEW_STATE);