import React, { Component } from 'react';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableRowComponent from "./tableRow";
import Pagination from "./pagination";
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from "@material-ui/core/styles";
import Colors from "../../helpers/constants/colors";
import MediaQuery from 'react-responsive';
import TableList from './Table/tableList';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
    head: {
        // backgroundColor: Colors.CART_CIRCLE_RED
    },
    body: {
        // fontSize: 14,
    },
    table: {
        // color: Colors.FACEBOOK_BUTTON
    },
    headCell: {
        // color: Colors.WHITE
    }
});

const TableComponent = props => {

    const handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (props.orderBy === property && props.order === 'desc') {
            order = 'asc';
        }

        props.onRequestSort({ order, orderBy });
    };

    const { classes } = props;


    return (
        <div className={classes.root}>
            <MediaQuery query="(max-device-width: 768px)">
                <TableList
                    {...props}
                    classes={null}
                />
            </MediaQuery>
            <MediaQuery query="(min-device-width: 769px)">
                <Table
                    className={classes.table}
                    height={props.height}
                    style={{ tableLayout: 'unset' }}
                >
                    <TableHead className={classes.head}>
                        <TableRow >
                            {
                                props.showCheckboxes?
                                    <TableCell padding="checkbox">
                                        <FormControlLabel

                                            control={<Checkbox color="primary" />}
                                            label="Select All"
                                            labelPlacement="top"
                                        />
                                        {/* <Checkbox
                                    label="Select All"
                                    labelPlacement="top"

                                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                                    //     checked={rowCount > 0 && numSelected === rowCount} onChange={onSelectAllClick} inputProps={{ 'aria-label': 'select All' }}
                                         /> */}
                                    </TableCell>
                                    :
                                    null
                            }
                            {

                                props.columns.map((column, index) => {
                                    return (

                                        <TableCell
                                            className={classes.headCell}
                                            key={index}
                                            tooltip={column}
                                            sortDirection={props.orderBy === index ? props.order : false}
                                        >
                                            {
                                                props.isSortable ?
                                                    column.excludeFromSorting ?
                                                        column.name :
                                                        <Tooltip
                                                            title="Sort"
                                                            placement={true ? 'bottom-end' : 'bottom-start'}
                                                            enterDelay={300}
                                                        >
                                                            <TableSortLabel
                                                                active={props.orderBy === index}
                                                                direction={props.order}
                                                                onClick={(evt) => handleRequestSort(evt, index)}
                                                            >
                                                                {column.name}
                                                            </TableSortLabel>
                                                        </Tooltip>
                                                    :
                                                    column
                                            }

                                        </TableCell>

                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.body}>
                        {/* <TableCell padding="checkbox">

                                </TableCell> */}
                        {
                            props.rows.map((row, index) => {

                                    return (
                                        props.showCheckboxes?
                                            <div>

                                                <Checkbox

                                                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                                                    //     checked={rowCount > 0 && numSelected === rowCount} onChange={onSelectAllClick} inputProps={{ 'aria-label': 'select All' }}
                                                />

                                                <TableRowComponent
                                                    isClickable={props.clickableRows}
                                                    key={index}
                                                    row={row}
                                                    handleRowSelect={props.handleRowSelect}
                                                >




                                                </TableRowComponent> </div>
                                            : // edw to IF------------<<<
                                            <TableRowComponent
                                                isClickable={props.clickableRows}
                                                key={index}
                                                row={row}
                                                handleRowSelect={props.handleRowSelect}
                                            >
                                            </TableRowComponent>
                                    )
                                }
                            )

                        }
                    </TableBody>
                </Table>
            </MediaQuery>
            <Divider />
            {
                props.hidePagination ?
                    null
                    :
                    <Pagination
                        page={props.page}
                        totalRows={props.totalRows}
                        handlePageChange={props.handlePageChange}
                        rowsPerPage={props.rowsPerPage}
                        handleRowsPerPage={props.handleRowsPerPage}
                        colSpan={props.columns.length}
                    />
            }
        </div>
    )
};

export default withStyles(styles)(TableComponent);