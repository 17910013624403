import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import EnabledOffer from "../../components/EnabledOffer/enalbedOffer";
import {
    changeClientOverviewStoreSettings,
    deleteEnabledOffer,
    getClientEnabledOfferById
} from "../../actions/clientOverview";
import {parseClientOverview, parseLogin} from "../../helpers/parseImmutables";
import {useParams} from "react-router-dom";

const EnabledOfferHOC = props => {
    const {id} = useParams();

    useEffect(() => {
        if(id){
            props.getClientEnabledOfferById({
                enabledOfferId: id
            })
        }
    }, [])

    return (
        <EnabledOffer
            {...props}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        enabledOffer: parseClientOverview(state.clientOverview).enabledOffer,
        deleteEnabledOfferResponse: parseClientOverview(state.clientOverview).deleteEnabledOfferResponse,
        useRole: parseLogin(state.login).userInfo.role
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getClientEnabledOfferById: (payload) => {
            dispatch(getClientEnabledOfferById(payload))
        },
        deleteEnabledOffer: (payload) => {
            dispatch(deleteEnabledOffer(payload))
        },
        changeClientOverviewStoreSettings: (payload) => {
            dispatch(changeClientOverviewStoreSettings(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EnabledOfferHOC)