import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment";
import Colors from "../../helpers/constants/colors";
import EnabledOffersFilterForm from "./enabledOffersFilterForm";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {withStyles} from "@material-ui/core/styles";
import _ from "lodash";
import {dateTypeMapper} from "../../helpers/functions";

const getData = (props) => {
    return  _.zipWith(props.enabledOffersStatistics, props.enabledOffersCompareStatistics, (p, c) => ({
        total: p?p.total: 0,
        totalRedeemed: p?p.totalRedeemed: 0,
        totalExpired: p?p.totalExpired: 0,
        date: p?moment(p.date).format():null,
        compareTotal: c?c.total:0,
        compareRedeemed: c?c.totalRedeemed:0,
        compareExpired: c?c.totalExpired:0,
        compareDate: c?moment(c.date).format():null
    }));
};

const CustomToolTip = (props) => {
    try {
        return (
            <div className={'recharts-tooltip-wrapper'}>
                <div className={'custom-tooltip'}>
                    {(props.active && props.payload)  && (
                        <div>
                            <div>
                                <p className={'label'}>{`date: ${moment(props.payload[0].payload.date).format('YYYY/MM/DD HH:mm')}`}</p>
                                <p className={'intro'} style={{color: props.payload[0].color}}>{`total: ${props.payload[0].payload.total}`}</p>
                                <p className={'intro'} style={{color: props.payload[1].color}}>{`total redeemed: ${props.payload[1].payload.totalRedeemed}`}</p>
                                <p className={'intro'} style={{color: props.payload[2].color}}>{`total expired: ${props.payload[2].payload.totalExpired}`}</p>
                            </div>
                            {props.compare && (
                                <div>
                                    <p className={'label'}>{`compared date: ${moment(props.payload[0].payload.compareDate).format('YYYY/MM/DD HH:mm')}`}</p>
                                    <p className={'intro'} style={{color: props.payload[3].stroke}}>{`compared total: ${props.payload[3].payload.compareTotal}`}</p>
                                    <p className={'intro'} style={{color: props.payload[4].stroke}}>{`compared total redeemed: ${props.payload[4].payload.compareRedeemed}`}</p>
                                    <p className={'intro'} style={{color: props.payload[5].stroke}}>{`compared total expired: ${props.payload[5].payload.compareExpired}`}</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>

            </div>
        )
    } catch (e) {
        return null
    }
};

const EnabledOffersChart  = (props) => {
    const { classes } = props;
    const data = getData(props);
    return (
        <Card className={classes.card}>
            <CardContent>
                <ResponsiveContainer  width={'100%'} height={300}>
                    <LineChart
                        data={data}
                    >
                        <XAxis
                            dataKey="date"
                            tickFormatter={(tick) => {return dateTypeMapper(tick, props.dateType)}}
                        />
                        <YAxis label={{ value: "Enabled offers", angle: -90, position: 'insideLeft' }} />
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip content={<CustomToolTip compare={props.compare}/>}/>
                        <Legend />
                        <Line isAnimationActive={false} dataKey="total" name={'Total Enabled'} stroke={Colors.COFFEE_BROWN} />
                        <Line isAnimationActive={false} dataKey="totalRedeemed" name={'Total Redeemed'} stroke={Colors.GREEN} />
                        <Line isAnimationActive={false} dataKey="totalExpired" name={'Total Expired'} stroke={Colors.VALIDATION_RED} />
                        {(props.enabledOffersCompareStatistics.length > 0) && (
                            <Line isAnimationActive={false} strokeDasharray="3 4 5 2" dataKey="compareTotal" name={'Compared Total Enabled'} stroke={Colors.COFFEE_BROWN} />
                        )}
                        {(props.enabledOffersCompareStatistics.length > 0) && (
                            <Line isAnimationActive={false} strokeDasharray="3 4 5 2" dataKey="compareRedeemed" name={'Compared Total Redeemed'} stroke={Colors.GREEN} />
                        )}
                        {(props.enabledOffersCompareStatistics.length > 0) && (
                            <Line isAnimationActive={false} strokeDasharray="3 4 5 2" dataKey="compareExpired" name={'Compared Total Expired'} stroke={Colors.VALIDATION_RED} />
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default withStyles({})(EnabledOffersChart);