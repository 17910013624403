import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  changeCampaignFormSettings,
  changeCampaignFormData,
  createCampaign,
  getActions,
  createAction, resetCampaignsNewFormState,
  sendTestEmail
} from "../../actions/campaignsNewForm";
import { getCampaigns,changeCampaignStoreSettings } from "../../actions/campaignsNew";
import { deleteActionById } from "../../actions/campaignNewOverview";

import CampaignNewForm from "../../components/Campaigns/campaignNewForm";
import {
  parseLogin,
  parseCampaigns,
  parseCampgaignNewForm,
  parseClients,
} from "../../helpers/parseImmutables";
import {
  changeClientsOrder,
  getClients,
  changeClientStoreSettings,
  getTotalClients,
  selectClientSuggestion,
  getClientsSuggestions,
} from "../../actions/clients";
import {
  _getClientsSuggestions,
  _getCampaignsSuggestions,
  _getSkipFilter,
  getTablePage,
  GreekToGreeklish,
  arrayUnique
} from "../../helpers/functions";
import _, { forEach } from "lodash";
import initialData from "../../components/General/Journey/initial-data";
import Papa from "papaparse";
import noImage from "../../assets/images/unnamed.png";
import usePrevious from "../../hooks/usePrevious";
import { useHistory } from "react-router-dom";
import { Loyalty } from "@material-ui/icons";
import * as XLSX from 'xlsx';


const tableColumns = [
 // { id: "selectAll", numeric: true, disablePadding: true, label: " " },
  { id: "id", numeric: true, disablePadding: true, label: "Person ID" },
  { id: "username", numeric: false, disablePadding: false, label: "Username" },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "telephone", numeric: true, disablePadding: false, label: "Telephone" },
];

const CampaignNewFormHOC = props => {

  const history = useHistory();

  const [modalState, setModalState] = useState({
    notificationModalOpen: false,
    emailModalOpen:false,

  })
  const [campaignFormState, setCampaignFormState] = useState({
    checkedUsers: props.initialValues
      ? props.initialValues.listIds.map((user) => parseInt(user.UserId))
      : [],
    actions: [
      {actionType: "Push Notification",id:1},
      {actionType: "Newsletter",id:2,}
    ],
    events: [],
    selectedActions:[],
    fields: 1,
    campaignFilter:"",
    toAddTag: "",
    tags: props.initialValues
      ? props.initialValues.tags.map((tag) => tag.Name)
      : [],
    // campaign tags
    actionTags: props.initialValues
      ?
      props.initialValues.actionTags.map(
        (actionTag) => actionTag.Name
      )
      : [],
    loadingCSV: false,
    platforms:
    props.initialValues
    ?
    props.initialValues.campaignActions.map((action)=> action.platform)
    :[],

    dndData: {// drag and drop data
      columns: {
        "column-1": {
          id: "column-1",
          title: "Actions & Events",
          actionIds: [1,2]
        },
        "column-2": {
          id: "column-2",
          title: "Journey",
          actionIds: [],
        },
      },
      columnOrder: ["column-1", "column-2"],
    },
  })
  const prevPage = usePrevious(props.clients.page);
  const prevRowsPerPage = usePrevious(props.clients.rowsPerPage);
  const prevOrder = usePrevious(props.clients.order);
  const prevOrderBy = usePrevious(props.clients.orderBy);
  const prevCampaignActionsSize = usePrevious(props.campaignNewForm.campaignActions.length);
  const prevCampaignActions=usePrevious(props.campaignNewForm.campaignActions);
  const prevCampaignResponseMessage= usePrevious(props.campaignNewForm.createCampaignResponseMessage);
  const prevEditCampaignResponseMessage=usePrevious(props.campaignNewForm.editCampaignResponseMessage);
  const prevCheckedUsers=usePrevious(campaignFormState.checkedUsers.length);
  const prevCampaignFilter=usePrevious(campaignFormState.campaignFilter);
const prevReadyForCampaign = usePrevious(props.campaignNewForm.readyForCampaign);

  useEffect(() => {
    props.resetCampaignsNewFormState()

    props.getTotalClients(props.login.userInfo.token);
    props.getClients({
      token: props.login.userInfo.token,
      filter: {
        skip: _getSkipFilter(
          props.clients.page,
          props.clients.rowsPerPage
        ),
        limit: props.clients.rowsPerPage,
        order:
          tableColumns[0].id + " " + props.clients.order.toUpperCase(),
      },
    });
    return () => {
      props.resetCampaignsNewFormState()
  }
  }, [])

  useEffect(()=>
  {
    if(campaignFormState.dndData.columns["column-2"].actionIds.length != props.campaignNewForm.formData.actionsSelected.length && !props.initialValues)
    {
      var actionNames = [];
      campaignFormState.dndData.columns["column-2"].actionIds.forEach( x =>
       {
         var action = campaignFormState.actions.find(a => a.id==x);
         actionNames.push(action.actionType);
       } )
       props.changeCampaignFormData({
        key: "actionsSelected",
        value: actionNames,
      });
    }
  },[campaignFormState,props.campaignNewForm.formData.actionsSelected])
  useEffect(() => {
    if (props.clients.page !== prevPage
      || props.clients.rowsPerPage !== prevRowsPerPage
      || props.clients.order !== prevOrder
      || props.clients.orderBy !== prevOrderBy
    ) {
      const whereFilter = _getClientsSuggestions(props.clients.filterField).where;
      props.getClients({
        token: props.login.userInfo.token,
        filter: {
          where: whereFilter,
          skip: _getSkipFilter(props.clients.page, props.clients.rowsPerPage),
          limit: props.clients.rowsPerPage,
          order: tableColumns[0].id + ' ' + props.clients.order.toUpperCase()
        }
      })
    }
  }, [
    props.clients.page, props.clients.rowsPerPage, prevPage, prevRowsPerPage,
    props.clients.order, prevOrder, props.clients.orderBy, prevOrderBy,
    props.clients.filterField, props.login.userInfo.token, tableColumns
  ])



useEffect(()=>{
if(
  props.campaignNewForm.createCampaignResponseMessage!=="Campaign was created successfully." &&
   props.campaignNewForm.createCampaignResponseMessage.length > 0 &&
   prevCampaignResponseMessage.length == 0 && props.campaignNewForm.creatingAction === false
    && props.campaignNewForm.creatingCampaign === false
   )
{
  prevCampaignActions.forEach(actionCreated =>
    props.deleteActionById(actionCreated)
    )
}

}, [
  prevCampaignActionsSize, props.campaignNewForm.creatingAction, campaignFormState.platforms,
  props.campaignNewForm.formData, props.campaignNewForm.campaignActions,props.campaignNewForm.createCampaignResponseMessage,
])

  useEffect(() => {
    if (
      props.campaignNewForm.creatingAction === false &&
      props.campaignNewForm.campaignActions.length>0 &&
      props.campaignNewForm.readyForCampaign && 
      arrayUnique(props.campaignNewForm.formData.actionsSelected).length === props.campaignNewForm.campaignActions.length
      // && props.campaignNewForm.createActionResponseMessage==="Action was created successfully."
    ) {
      // action was created and now the campaign will be created
      props.createCampaign({
        sendBody: {
          title: props.campaignNewForm.formData.title,
          description: props.campaignNewForm.formData.description,
          image:props.campaignNewForm.formData.image,//? props.campaignNewForm.formData.image:null,
          campaignPlanToCreateDTO: {
            UtmCampaignName: GreekToGreeklish(props.campaignNewForm.formData.title),
            ActionIds: props.campaignNewForm.campaignActions,
            ListIds: props.campaignNewForm.formData.selectedUsers,
            SelectAll: props.campaignNewForm.formData.selectAll,
            Tags: props.campaignNewForm.formData.selectedTags,
            StartDate: props.campaignNewForm.formData.startDate,
            EndDate: props.campaignNewForm.formData.endDate,
          },
        },
        token: props.token,
      });


    }
  }, [
    prevCampaignActionsSize, props.campaignNewForm.creatingAction, campaignFormState.platforms,campaignFormState.dndData.columns['column-2'],
    props.campaignNewForm.formData, props.campaignNewForm.campaignActions, props.token,prevEditCampaignResponseMessage,
    props.campaignNewForm.editCampaignResponseMessage
  ])

  useEffect(() => {
    if (campaignFormState.tags.length != props.campaignNewForm.formData.selectedTags.length) {
      props.changeCampaignFormData({
        key: "selectedTags",
        value: campaignFormState.tags,
      });
      props.changeCampaignFormData({
        key: "toAddTag",
        value: "",
      });
    }
  }, [
    campaignFormState.tags, props.campaignNewForm.formData.selectedTags
  ])

  useEffect(() => {
    if (campaignFormState.actionTags.length != props.campaignNewForm.formData.actionTags.length) {
      props.changeCampaignFormData({
        key: "actionTags",
        value: campaignFormState.actionTags,
      });
      props.changeCampaignFormData({
        key: "toAddTag",
        value: "",
      });
    }
  }, [campaignFormState.actionTags.length, props.campaignNewForm.formData.actionTags.length])


  const clearFilters = () => {
    props.changeStoreSettings("filterField", "");
    props.getTotalClients(props.login.userInfo.token);
    props.getClients({
      token: props.login.userInfo.token,
      filter: {
        skip: _getSkipFilter(
          props.clients.page,
          props.clients.rowsPerPage
        ),
        limit: props.clients.rowsPerPage,
        order:
        tableColumns[0].id +
          " " +
          props.clients.order.toUpperCase(),
      },
    });
  };

  const suggestionSelectedHandler = (suggestion) => {
    props.selectClientSuggestion(suggestion);
  };

  const handleSelectManySuggestions = (filterText) => {
    const whereFilter = _getClientsSuggestions(filterText).where;
    props.getTotalClients(props.login.userInfo.token, whereFilter);
    props.getClients({
      token: props.login.userInfo.token,
      filter: {
        where: whereFilter,
        skip: _getSkipFilter(
          props.clients.page,
          props.clients.rowsPerPage
        ),
        limit: props.clients.rowsPerPage,
        order:
          tableColumns[props.clients.orderBy].key +
          " " +
          props.clients.order.toUpperCase(),
      },
    });
  };
  const handleFilterFieldChange = (value) => {
    props.changeStoreSettings("filterField", value);
  };

  const onSuggestionsClearRequested = () => {
    props.changeStoreSettings("suggestions", []);
  };

  const imageHandler = (event) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (event.target.files[0] === undefined) {

      }
      else if (event.target.files[0].size > 1048576) {
        alert("Image size is too big")
      }
      else {
        reader.readAsDataURL(event.target.files[0]);


        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }
    });

  };

  const tagHandle = (evt, tag) => {
    const toAddTagRedux = props.campaignNewForm.formData.toAddTag;
    //tag adding handler for both action and campaign tags
    if (evt === "AddTag") {
      const selectedIndex = campaignFormState.tags.indexOf(toAddTagRedux);
      let newSelected = [];
      if (selectedIndex === -1 && toAddTagRedux.split(" ").join("").length > 0) {
        newSelected = newSelected.concat(campaignFormState.tags, toAddTagRedux);
        setCampaignFormState({ ...campaignFormState, tags: newSelected });
        // setCampaignFormState({...campaignFormState, toAddTag: "" });
      }
    } else if (evt === "DeleteTag") {
      const selectedIndex = campaignFormState.tags.indexOf(tag);
      let newSelected = [];
      if (selectedIndex === 0) {
        newSelected = newSelected.concat(campaignFormState.tags.slice(1));
      } else if (selectedIndex === campaignFormState.tags.length - 1) {
        newSelected = newSelected.concat(campaignFormState.tags.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          campaignFormState.tags.slice(0, selectedIndex),
          campaignFormState.tags.slice(selectedIndex + 1)
        );
      }
      setCampaignFormState({ ...campaignFormState, tags: newSelected });
    } else if (evt === "AddActionTag") {
      const selectedIndex = campaignFormState.actionTags.indexOf(toAddTagRedux);
      let newSelected = [];
      if (selectedIndex === -1 && toAddTagRedux.split(" ").join("").length > 0) {
        newSelected = newSelected.concat(
          campaignFormState.actionTags,
          toAddTagRedux
        );
        setCampaignFormState({ ...campaignFormState, actionTags: newSelected });
      }
    } else if (evt === "DeleteActionTag") {
      const selectedIndex = campaignFormState.actionTags.indexOf(tag);
      let newSelected = [];
      if (selectedIndex === 0) {
        newSelected = newSelected.concat(campaignFormState.actionTags.slice(1));
      } else if (selectedIndex === campaignFormState.actionTags.length - 1) {
        newSelected = newSelected.concat(campaignFormState.actionTags.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          campaignFormState.actionTags.slice(0, selectedIndex),
          campaignFormState.actionTags.slice(selectedIndex + 1)
        );
      }
      setCampaignFormState({ ...campaignFormState, actionTags: newSelected });
    }
    // else {
    //   // setCampaignFormState({...campaignFormState, toAddTag: evt.target.value });
    // }


  };

  const actionTypeHandle = (type) => {
    props.changeCampaignFormData({
      key: "selectedActionType",
      value: [...props.campaignNewForm.formData.selectedActionType,type],
    });
  };
  //Drag and drop
  const onDragStart = (start) => {
    document.body.style.transition = "background-color 0.2s ease";
    const homeIndex = campaignFormState.dndData.columnOrder.indexOf(
      start.source.droppableId
    );
    setCampaignFormState({ ...campaignFormState, homeIndex });

  };

  const onDragUpdate = (update) => {
    // const { destination } = update;
    // const opacity = destination
    //   ? destination.index / Object.keys(campaignFormState.dndData.actions).length
    //   : 0;
    // document.body.style.backgroundColor = `rgba( 153, 141, 217, ${opacity})`;
  };

  const onDragEnd = (result) => {
    setCampaignFormState({ ...campaignFormState, homeIndex: null });
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const column = campaignFormState.dndData.columns[source.droppableId];
    const newActionIds = Array.from(column.actionIds);

    const start = campaignFormState.dndData.columns[source.droppableId];
    const finish = campaignFormState.dndData.columns[destination.droppableId];
    if (start === finish) {
      newActionIds.splice(source.index, 1);
      newActionIds.splice(destination.index, 0, draggableId);
      const newColumn = {
        ...column,
        actionIds: newActionIds,
      };
      const newState = {
        ...campaignFormState.dndData,
        columns: {
          ...campaignFormState.dndData.columns,
          [newColumn.id]: newColumn,
        },
      };
      setCampaignFormState({ ...campaignFormState, dndData: newState });

      return;
    }
    //Moving to another column
    const startActionIds = Array.from(start.actionIds);
    startActionIds.splice(source.index, 1);
    const newStart = {
      ...start,
      actionIds: startActionIds,
    };

    const finishActionIds = Array.from(finish.actionIds);
    finishActionIds.splice(destination.index, 0,draggableId); // source.index
    const newFinish = {
      ...finish,
      actionIds: finishActionIds,
    };
    const newState = {
      ...campaignFormState.dndData,
      columns: {
        ...campaignFormState.dndData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
if(destination.droppableId==="column-1" && source.droppableId==="column-2")
{
  if(draggableId == 1)
  {
    setCampaignFormState({ ...campaignFormState, dndData: newState });
    props.changeCampaignFormData({
      key: "selectedActionId",
      value: "",
    });
    setCampaignFormState({ ...campaignFormState, dndData: newState });
    props.changeCampaignFormData({
      key: "selectedActionType",
      value: "",
    });
    props.changeCampaignFormData({
      key: "notificationTitle",
      value: "",
    });
    props.changeCampaignFormData({
      key: "notificationText",
      value: "",
    });
    props.changeCampaignFormData({
      key: "notificationImage",
      value: "",
    });
    props.changeCampaignFormData({
      key: "url",
      value: "",
    });
    props.changeCampaignFormData({
      key: "pushPlatforms",
      value: "",
    });
    props.changeCampaignFormData({
      key: "actionDate",
      value: "",
    });
    props.changeCampaignFormData({
      key: "actionTime",
      value: "",
    });
    setCampaignFormState({ ...campaignFormState, platforms: [], dndData: newState });
  }
  if(draggableId == 2)
  {
    props.changeCampaignFormData({
      key: "emailFrom",
      value: "",
    });
    props.changeCampaignFormData({
      key: "emailSubject",
      value: "",
    });
    props.changeCampaignFormData({
      key: "emailDate",
      value: "",
    });
    props.changeCampaignFormData({
      key: "emailTime",
      value: "",
    });
    props.changeCampaignFormData({
      key: "emailContent",
      value: "",
    });
    
    setCampaignFormState({ ...campaignFormState, dndData: newState });

  }

  }
  else{
    setCampaignFormState({ ...campaignFormState, dndData: newState });
    props.changeCampaignFormData({
      key: "selectedActionId",
      value: newState.columns['column-2'].actionIds,
    });
  }
  };
  //End of drag and drop
  const toggleNotificationModal = (evt) => {
    if (evt.currentTarget.name === "Save") {
      props.changeCampaignFormData({
        key: "pushPlatforms",
        value: campaignFormState.platforms,
      });
    }
    if (evt.currentTarget.name === "Clear") {
      props.changeCampaignFormData({
        key: "notificationTitle",
        value: "",
      });
      props.changeCampaignFormData({
        key: "notificationText",
        value: "",
      });
      props.changeCampaignFormData({
        key: "notificationImage",
        value: "",
      });
      props.changeCampaignFormData({
        key: "url",
        value: '',
      });
      props.changeCampaignFormData({
        key: "pushPlatforms",
        value: [],
      })
      props.changeCampaignFormData({
        key: "actionDate",
        value: '',
      });
      props.changeCampaignFormData({
        key: "actionTime",
        value: '',
      });

      props.initialValues?null:setCampaignFormState({
        ...campaignFormState,
        platforms: ''
      });

    }

    setModalState({
      ...modalState,
      notificationModalOpen: !modalState.notificationModalOpen,
    });
  };

  const handlePlatformCheckbox = (evt, platform) => {
    const selectedPlatform = campaignFormState.platforms.indexOf(platform);
    let newChecks = [];
    if (selectedPlatform === -1) {
      newChecks = newChecks.concat(campaignFormState.platforms, platform);
    } else if (selectedPlatform === 0) {
      newChecks = newChecks.concat(campaignFormState.platforms.slice(1));
    } else if (selectedPlatform === campaignFormState.platforms.length - 1) {
      newChecks = newChecks.concat(campaignFormState.platforms.slice(0, -1));
    } else if (selectedPlatform > 0) {
      newChecks = newChecks.concat(
        campaignFormState.platforms.slice(0, selectedPlatform),
        campaignFormState.platforms.slice(selectedPlatform + 1)
      );
    }
    setCampaignFormState({ ...campaignFormState, platforms: newChecks });
  };

  const isSelectedPlatform = (platform) => campaignFormState.platforms.indexOf(platform) !== -1;

  const handleCampaignFilter = (value) =>
  {
    props.changeCampaignFormData({
      key: "title",
      value: value,
    });
   props.changeCampaignStoreSettings("campaigns",[]);
  };
  const handleTextInputChange = (evt, name) => {//handler for all text fields
    if(evt.target.name=='title')
    {
      props.getCampaigns({
        filter: {
          skip: _getSkipFilter(
            props.campaigns.page,
            props.campaigns.rowsPerPage
          ),
          limit: props.campaigns.rowsPerPage,
          where:evt.target.value,
        },
      });
    }
    props.changeCampaignFormData({
      key: evt.target.name,
      value: evt.target.value,
    });


  };
  const isSelected = (user) => campaignFormState.checkedUsers.indexOf(user) !== -1;
  const handleUsersListCheck = (evt, userId) => {

if(userId==='all'){
  props.changeCampaignFormData({
    key: 'selectAll',
    value: !props.campaignNewForm.formData.selectAll,
  });
  setCampaignFormState({ ...campaignFormState, checkedUsers: [] });

}
else{
    const selectedIndex = campaignFormState.checkedUsers.indexOf(userId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(campaignFormState.checkedUsers, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(campaignFormState.checkedUsers.slice(1));
    } else if (selectedIndex === campaignFormState.checkedUsers.length - 1) {
      newSelected = newSelected.concat(campaignFormState.checkedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        campaignFormState.checkedUsers.slice(0, selectedIndex),
        campaignFormState.checkedUsers.slice(selectedIndex + 1)
      );
    }

    setCampaignFormState({ ...campaignFormState, checkedUsers: newSelected });
 }
  };
   const handleFileSelect = (e) => { // csv parser using papaparse
    setCampaignFormState({ ...campaignFormState, loadingCSV: true });

    if (e.target.files[0] === undefined) {
    }
   else if(e.target.files[0].name.split(".")[1]!="xlsx" && e.target.files[0].name.split(".")[1]!="csv")
    {
      props.changeCampaignFormSettings("validationMessage", " Unsupported file type (check file name)");

    }
    else if (e.target.files[0].name.split(".")[1]=="csv")
    {

      Papa.parse(e.target.files[0], {
        header: true,
        complete: (result) => {
          const csvUsers=result.data.map((user) => parseInt(user["ci_loyalty_id"])).filter(user => parseInt(user));
          const newCheckedUsersArray = arrayUnique(
            campaignFormState.checkedUsers.concat(
              csvUsers
            )
          );
            props.changeCampaignFormSettings("validationMessage",csvUsers.length!=0? csvUsers.length +" loyal users were imported":"No users were found");

          setCampaignFormState({
            ...campaignFormState,
            checkedUsers: newCheckedUsersArray,
          });
        },
      });

  }
  else if (e.target.files[0].name.split(".")[1]=="xlsx"){
    var f = e.target.files[0]
    const reader = new FileReader();
    reader.onload = (evt) => { // evt = on_file_select event

        const file = evt.target.result;
        const wb = XLSX.read(file, {type:'binary'});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array to json */
        const data = XLSX.utils.sheet_to_json(ws);
        /* Update state */
        const xlsxUsers=data.map((user) => parseInt(user["ci loyalty id"])).filter(user => parseInt(user));
        const newCheckedUsersArray = arrayUnique(
          campaignFormState.checkedUsers.concat(
            xlsxUsers
          )
        );
        props.changeCampaignFormSettings("validationMessage", xlsxUsers.length +" loyal users were imported");

        setCampaignFormState({
          ...campaignFormState,
          checkedUsers: newCheckedUsersArray,
        });
    };
    reader.readAsBinaryString(f);

  }

    setCampaignFormState({ ...campaignFormState, loadingCSV: false });
  };


  const handlePageChange = (iconType) => {
    let newPage = getTablePage(
      iconType,
      props.clients.page,
      props.clients.totalClients,
      props.clients.rowsPerPage
    );
    props.changeStoreSettings("page", newPage);
  };

  const handleRowsPerPage = (rowsPerPage) => {
    rowsPerPage = parseInt(rowsPerPage);
    let newTotalPages = _.ceil(props.clients.totalClients / rowsPerPage);
    let currentPage = props.clients.page;
    if (parseInt(currentPage) > newTotalPages) {
      currentPage = newTotalPages;
    }
    props.changeStoreSettings("rowsPerPage", rowsPerPage);
    props.changeStoreSettings("page", currentPage);
  };
  const onRequestSort = (payload) => {
    props.getTotalClients(props.login.userInfo.token);
    props.changeClientsOrder(payload);
  };

  const goNext = () => {//next step handler
    if (props.campaignNewForm.currentStep === 1) {
      props.changeCampaignFormData({
        key: "selectedUsers",
        value: campaignFormState.checkedUsers,
      });
    }

    if (props.campaignNewForm.currentStep === 2) return 0;

    props.changeCampaignFormSettings(
      "currentStep",
      props.campaignNewForm.currentStep + 1
    );
  };
  const goBack = () => {//step back handler
    if (props.campaignNewForm.currentStep === 0) return 0;
    props.changeCampaignFormSettings(
      "currentStep",
      props.campaignNewForm.currentStep - 1
    );
  };

  const getClientList = () => {
    let arr = [];
    let allClients = [...props.clients.clients];
    _.forEach(allClients, (c) => {
      arr.push({
        id: c.id,
        username: c.username,
        email: c.email,
        telephone: c.telephone,
      });
    });
    return arr;
  };
  const responseMessageHandler = () => {
    props.changeCampaignFormSettings("createCampaignResponseMessage", "");
    props.changeCampaignFormSettings("createActionResponseMessage", "");
    props.changeCampaignFormSettings("editCampaignResponseMessage", "");
    props.changeCampaignFormSettings("editActionResponseMessage", "");
    props.changeCampaignFormSettings("validationMessage", "");

  };

  const toggleEmailModal = (evt, id) => {
    setModalState({
      ...modalState,
      emailModalOpen: !modalState.emailModalOpen,
    });
  };

  const sendEmail = (html, sendTo) => {
    props.sendTestEmail({
      sendBody: {
        htmlEmail: html,
        sendTo: sendTo,
      },
    });
  };
const validateForm=()=>{
 var isValid=true;
 var failMessage="";

 //campaign validations
 if( props.campaignNewForm.formData.title.trim().length==0 )
 {
    failMessage=failMessage+ "Title field cannot be empty.";
    isValid=false;}
 if( props.campaignNewForm.formData.description.trim().length==0 )
 { failMessage=failMessage+ "Description field cannot be empty.";isValid=false;}

if( props.campaignNewForm.formData.startDate.trim().length==0 ||props.campaignNewForm.formData.endDate.trim().length==0)
{ failMessage=failMessage+ " Start/End date fields cannot be empty.";isValid=false;}

if( (props.campaignNewForm.formData.selectAll==false && props.campaignNewForm.formData.selectedUsers.length==0))
{ failMessage=failMessage+ "Please select at least one user.";isValid=false;}

if( (props.campaignNewForm.formData.actionsSelected.length==0 ))
{ failMessage=failMessage+ "Please select at least one action";isValid=false;}

//push notification validations
if(props.campaignNewForm.formData.actionsSelected.includes("Push Notification"))
{
 if( props.campaignNewForm.formData.notificationTitle.trim().length==0)
 { failMessage=failMessage+ "Notification title field cannot be empty.";isValid=false;}

 if( props.campaignNewForm.formData.notificationText.trim().length==0)
 { failMessage=failMessage+ "Notification text field cannot be empty.";isValid=false;}

 if( props.campaignNewForm.formData.url.trim().length==0)
 { failMessage=failMessage+ "Notification url field cannot be empty.";isValid=false;}

 if( props.campaignNewForm.formData.actionDate.trim().length==0||props.campaignNewForm.formData.actionTime.trim().length==0)
 { failMessage=failMessage+ "Action date/time fields cannot be empty.";isValid=false;}

 if( props.campaignNewForm.formData.pushPlatforms.length==0)
 { failMessage=failMessage+ "Please select at least one platform.";isValid=false;}

}

//newsletter validations
if(props.campaignNewForm.formData.actionsSelected.includes("Newsletter"))
{
  if( props.campaignNewForm.formData.emailFrom.trim().length==0 )
 {
    failMessage=failMessage+ "Email sender field cannot be empty.";
    isValid=false;}
 if( props.campaignNewForm.formData.emailSubject.trim().length==0 )
 { failMessage=failMessage+ "Email subject field cannot be empty.";isValid=false;}

 if( props.campaignNewForm.formData.emailDate.trim().length==0 ||props.campaignNewForm.formData.emailTime.trim().length==0)
 { failMessage=failMessage+ " Email date/time fields cannot be empty.";isValid=false;}

 if( (props.campaignNewForm.formData.emailContent.trim().length==0))
 { failMessage=failMessage+ "Email content cannot be empty.";isValid=false;}
}


if(failMessage.length>150){
  failMessage="Please fill all the required fields."
 }
props.changeCampaignFormSettings("validationMessage", failMessage);
return isValid
    }

const validatedSubmit = () => {
    if(validateForm()){
      props.handleSubmit();
    }

  }

  return (
    <CampaignNewForm
      {...props}
      defaultActions={props.campaignNewForm.formData.defaultActions}
      push={props.push}
      goNext={goNext}
      goBack={goBack}
      title={props.campaignNewForm.title}
      currentStep={props.campaignNewForm.currentStep}
      inputChange={handleTextInputChange}
      //handleSubmit={props.handleSubmit}
      handleSubmit={validatedSubmit}
      UserList={getClientList()}
      handleCheck={handleUsersListCheck}
      tableColumns={tableColumns}
      rowsPerPage={props.clients.rowsPerPage}
      totalRows={props.clients.totalClients}
      pageChange={handlePageChange}
      page={props.clients.page}
      handleRowsPerPage={handleRowsPerPage}
      onRequestSort={onRequestSort}
      responseMessageHandler={responseMessageHandler}
      checked={campaignFormState.checkedUsers}
      setCampaignFormState={setCampaignFormState}
      campaignFormState={campaignFormState}
      isSelected={isSelected}
      selectedAction={campaignFormState.type}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragUpdate={onDragUpdate}
      dndData={campaignFormState.dndData}
      actionState={campaignFormState.actions}
      actions={campaignFormState.actions}
      // actions={props.campaignNewForm.actions}
      clearFilters={clearFilters}
      handleSelectManySuggestions={handleSelectManySuggestions}
      suggestionSelectedHandler={suggestionSelectedHandler}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      suggestions={props.clients.suggestions}
      getClientsSuggestions={props.getClientsSuggestions}
      handleFilterFieldChange={handleFilterFieldChange}
      filterField={props.clients.filterField}
      handleFileSelect={handleFileSelect}
      loadingCSV={campaignFormState.loadingCSV}
      GreekToGreeklish={GreekToGreeklish}
      notificationTitle={
        props.campaignNewForm.formData.notificationTitle
          ? props.campaignNewForm.formData.notificationTitle
          : "Notification Title"
      }
      notificationText={
        props.campaignNewForm.formData.notificationText
          ? props.campaignNewForm.formData.notificationText
          : "Notification Text"
      }
      notificationImage={
        props.campaignNewForm.formData.notificationImage ? (
          props.campaignNewForm.formData.notificationImage
        ) : (
          <img src={noImage} />
        )
      }
      notificationModalToggle={toggleNotificationModal}
      notificationModalState={modalState.notificationModalOpen}
      tags={campaignFormState.tags}
      actionTags={campaignFormState.actionTags}
      tagHandle={tagHandle}
      toAddTag={campaignFormState.toAddTag}
      actionTypeHandle={(event) => actionTypeHandle(event)}
      changeCampaignFormData={props.changeCampaignFormData}
      imageHandler={imageHandler}
      //campaignImage={campaignFormState.campaignImage}
      handlePlatform={handlePlatformCheckbox}
      initialValues={props.initialValues}
      handleViewNotification={props.handleViewNotification}
      platforms={campaignFormState.platforms}
      isSelectedPlatform={isSelectedPlatform}
      handleCampaignFilter={handleCampaignFilter}
      getCampaigns={props.getCampaigns}
      emailModalOpen={modalState.emailModalOpen}
      toggleEmailModal={toggleEmailModal}
      sendEmail={sendEmail}
      emailFrom={props.campaignNewForm.formData.emailFrom}
      emailSubject={props.campaignNewForm.formData.emailSubject}
      emailDate={props.campaignNewForm.formData.emailDate}
      emailTime={props.campaignNewForm.formData.emailTime}
      testEmailAddress={props.campaignNewForm.formData.testEmailAddress}
      emailContent={props.campaignNewForm.formData.emailContent}
      loadingCampaign = {props.campaignNewForm.editingCampaign || props.campaignNewForm.editingAction|| props.campaignNewForm.creatingCampaign || props.campaignNewForm.creatingAction  }
    />
  );
}

const mapStateToProps = (state) => {
  return {
    campaignNewForm: parseCampgaignNewForm(state.campaignNewForm),
    campaigns: parseCampaigns(state.campaignsNew),
    clients: parseClients(state.clients),
    token: parseLogin(state.login).userInfo.token_dotnet,
    login: parseLogin(state.login),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCampaigns: (payload) => {
      dispatch(getCampaigns(payload));
    },
    changeCampaignStoreSettings: (key, value) => {
      dispatch(changeCampaignStoreSettings(key, value));
    },
    changeCampaignFormSettings: (key, value) => {
      dispatch(changeCampaignFormSettings(key, value));
    },
    changeCampaignFormData: (key, value) => {
      dispatch(changeCampaignFormData(key, value));
    },
    createCampaign: (payload) => {
      dispatch(createCampaign(payload));
    },
    getClients: (payload) => {
      dispatch(getClients(payload));
    },
    changeStoreSettings: (key, value) => {
      dispatch(changeClientStoreSettings(key, value));
    },
    getTotalClients: (token, whereFilter) => {
      dispatch(getTotalClients(token, whereFilter));
    },
    changeClientsOrder: (payload) => {
      dispatch(changeClientsOrder(payload));
    },
    getActions: (payload) => {
      dispatch(getActions(payload));
    },
    getClientsSuggestions: (payload) => {
      dispatch(getClientsSuggestions(payload));
    },
    selectClientSuggestion: (payload) => {
      dispatch(selectClientSuggestion(payload));
    },
    createAction: (payload) => {
      dispatch(createAction(payload));
    },
    resetCampaignsNewFormState: (payload) => {
      dispatch(resetCampaignsNewFormState(payload));
    },
    deleteActionById: (actionId) => {
      dispatch(deleteActionById(actionId));
    },
    sendTestEmail: (payload) => {
      dispatch(sendTestEmail(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignNewFormHOC);
