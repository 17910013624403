import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const RenderTextField = ({
                             input,
                             label,
                             meta: { touched, error },
                             ...custom
                         }) => (
    <FormControl fullWidth={custom.fullWidth} required={custom.required}>
        <InputLabel shrink={custom.shrink}>{label}</InputLabel>
        <Input
            inputProps={custom}
            label={label}
            error={!!(touched && error)}
            startAdornment={custom.startAdornment}
            {...input}
            {...custom}
            autoComplete={'off'}
        />
        {(touched && error) && (
            <FormHelperText>{error}</FormHelperText>
        )}
    </FormControl>
);

export default RenderTextField
