import React from 'react';
import {CartesianGrid, Legend, ComposedChart, Bar, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment";
import Colors from "../../helpers/constants/colors";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {withStyles} from "@material-ui/core/styles";
import _ from "lodash";
import {dateTypeMapper} from "../../helpers/functions";

const getData = (props) => {
    return  _.zipWith(props.ordersStatistics, props.ordersCompareStatistics, (p, c) => ({
        orders: p?p.orders: 0,
        xpOrders: p?p.xpOrders: 0,
        date: p?moment(p.date).format():null,
        compareOrders: c?c.orders:0,
        compareXpOrders: c?c.xpOrders:0,
        compareDate: c?moment(c.date).format():null
    }));
};

const CustomToolTip = (props) => {
    try {
        return (
            <div className={'recharts-tooltip-wrapper'}>
                <div className={'custom-tooltip'}>
                    {(props.active && props.payload) && (
                        <div>
                            <div>
                                <p className={'label'}>{`date: ${moment(props.payload[0].payload.date).format('YYYY/MM/DD HH:mm')}`}</p>
                                <p className={'intro'} style={{color: props.payload[0].fill}}>{`orders: ${props.payload[0].payload.orders}`}</p>
                                <p className={'intro'} style={{color: props.payload[2].fill}}>{`xp orders: ${props.payload[2].payload.xpOrders}`}</p>
                            </div>
                            {props.compare && (
                                <div>
                                    <p className={'label'}>{`compared date: ${moment(props.payload[0].payload.compareDate).format('YYYY/MM/DD HH:MM')}`}</p>
                                    <p className={'intro'} style={{color: props.payload[3].color}}>{`compared orders: ${props.payload[3].payload.compareOrders}`}</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>

            </div>
        )
    } catch (e) {
        return null
    }
};

const OrdersChart  = (props) => {
    const { classes } = props;
    const data = getData(props);
    return (
        <Card className={classes.card}>
            <CardContent>
                <ResponsiveContainer  width={'100%'} height={300}>
                    <ComposedChart
                        data={data}
                    >
                        <XAxis
                            tickFormatter={(tick) => {return dateTypeMapper(tick, props.dateType)}}
                            dataKey="date"
                        />
                        <YAxis label={{ value: "Orders", angle: -90, position: 'insideLeft' }} />
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip content={<CustomToolTip compare={props.compare}/>}/>
                        <Legend />
                        <Bar  isAnimationActive={false} stackId="a" dataKey="orders" name={'orders'} fill={Colors.COFFEE_BROWN} />
                        <Line isAnimationActive={false} dataKey={(dataKeys) => {return dataKeys.orders / 2}} name={'orders'}  stroke='#ff7300' />
                        <Bar  isAnimationActive={false} stackId="a" dataKey="xpOrders" name={'XP orders'} fill={Colors.GREEN} />
                        {(props.ordersCompareStatistics.length > 0) && (
                            <Line isAnimationActive={false} dataKey="compareOrders" name={'Compared orders'} stroke={Colors.ORANGE} strokeDasharray="3 4 5 2"/>
                        )}
                    </ComposedChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default withStyles({})(OrdersChart);