import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import ClientOverview from "../../components/Clients/clientOverview";
import {deleteClientById, getClientById} from "../../actions/clients";
import _ from 'lodash';
import moment from 'moment';
import {
    changeClientOverviewStoreSettings,
    changeClientsOrdersOrder,
    countClientOrders,
    getClientEnabledOffers,
    getClientOrders,
    giveClientExtraPoints, resetClientOverviewState,
    setClientOverviewActiveTab
} from "../../actions/clientOverview";
import {_getSkipFilter, getTableListPostitions, getTablePage} from "../../helpers/functions";
import {
    parseCategories,
    parseClientOverview,
    parseClients,
    parseEnabledOffers,
    parseLogin
} from "../../helpers/parseImmutables";
import {getEnabledOffers} from "../../actions/enabledOffers";
import usePrevious from "../../hooks/usePrevious";
import {useHistory, useLocation} from "react-router-dom";

const orderTableColumns = [
    {name: 'Date', key: 'date'},
    {name: 'Teller', key: 'teller', excludeFromSorting: true},
    {name: 'Products', key: 'products', excludeFromSorting: true},
    {name: 'Total price', key: 'totalValue'},
    {name: 'Total value', key: 'totalPoints'},
    {name: 'Store', key: 'store', excludeFromSorting: true},
];

const offersTableColumns = [
    'Offer name',
    'Coupon start date',
    'Coupon end date',
    'Coupon id',
    'Coupon code',
    'Issue date',
    'Expired',
    'Redeemed'
];

const ClientOverviewHOC = props => {
    const prevOrdersListRowsPerPage = usePrevious(props.clientOverview.ordersListRowsPerPage);
    const prevOrdersListPage = usePrevious(props.clientOverview.ordersListPage);
    const prevOrderOrdersBy = usePrevious(props.clientOverview.orderOrdersBy);
    const prevOrdersOrder = usePrevious(props.clientOverview.ordersOrder);
    const prevPostingClientExtraPoints = usePrevious(props.clientOverview.postingClientExtraPoints);
    const location = useLocation();
    const history = useHistory();
    const prevDeletingClient = usePrevious(props.deletingClient);

    useEffect(() => {
        return () => {
            props.resetClientOverviewState();
        }
    }, [])

    useEffect(() => {
        props.getClientById(_.last(location.pathname.split('/')), props.token);
        props.countClientOrders({token: props.token, clientId: _.last(location.pathname.split('/'))});
    }, [])

    useEffect(() => {
        if(
            (prevOrdersListRowsPerPage !== props.clientOverview.ordersListRowsPerPage)
            || (prevOrdersListPage !== props.clientOverview.ordersListPage)
            || (prevOrderOrdersBy !== props.clientOverview.orderOrdersBy)
            || (prevOrdersOrder !== props.clientOverview.ordersOrder)
        ){
            props.getClientOrders({
                token: props.token,
                clientId: _.last(location.pathname.split('/')),
                filter: {
                    order: orderTableColumns[props.clientOverview.orderOrdersBy].key + ' ' +
                        props.clientOverview.ordersOrder.toUpperCase(),
                    where: {or: [{deleted: null}, {deleted: undefined}, {deleted: false}]},
                    limit: props.clientOverview.ordersListRowsPerPage,
                    offset: _getSkipFilter(props.clientOverview.ordersListPage,
                        props.clientOverview.ordersListRowsPerPage),
                    include: ['teller', 'store', {relation: 'lineItems', scope: {include: 'product'}}]}
            })
        }
    }, [
        props.clientOverview.ordersListRowsPerPage,
        props.clientOverview.ordersListPage, props.clientOverview.orderOrdersBy, props.clientOverview.ordersOrder,
        prevOrdersListRowsPerPage, prevOrdersListPage, prevOrderOrdersBy, prevOrdersOrder,
        props.token, location.pathname
    ])

    useEffect(() => {
        if(
            prevPostingClientExtraPoints
            && !props.clientOverview.postingClientExtraPoints
            && !props.clientOverview.postingClientExtraPointsFailMessage) {
            props.getClientById(_.last(location.pathname.split('/')), props.token)
        }
    }, [
        prevPostingClientExtraPoints, props.clientOverview.postingClientExtraPoints,
        props.clientOverview.postingClientExtraPoints, location.pathname
    ])

    useEffect(() => {
        if(!props.deletingClient && prevDeletingClient){
            history.push('/clients');
        }
    },[props.deletingClient, prevDeletingClient])

    const getOffersTableRows = () => {
        let position = getTableListPostitions(
            props.clientOverview.offersListRowsPerPage,
            props.clientOverview.offersListPage
        );
        const rows = _.slice(props.enabledOffers, position.start, position.end);
        return rows.map(enabledOffer => ({
            id: enabledOffer.id,
            data: [
                enabledOffer.offer.name,
                moment(enabledOffer.couponStartDate).isValid()?moment(enabledOffer.couponStartDate).format('DD/MM/YYYY'):'',
                moment(enabledOffer.couponEndDate).isValid()?moment(enabledOffer.couponEndDate).format('DD/MM/YYYY'):'',
                enabledOffer.erpCouponId,
                enabledOffer.couponCode,
                moment(enabledOffer.issueDate).isValid()?moment(enabledOffer.issueDate).format('DD/MM/YYYY'):'',
                enabledOffer.expired?'Yes':'No',
                enabledOffer.redeemed?'Yes':'No'
            ],
            label: enabledOffer.offer.name
        }));
    };

    const getOrderTableRows = () => {
        let rows = [];
        let allOrders = [...props.clientOverview.orders];
        _.forEach(allOrders, order => {
            let productString = '';
            _.forEach(order.lineItems, (lineItem, index) => {
               if(index === 0){
                   productString = lineItem.product.name;
               }
               else {
                   productString += ', ' + lineItem.product.name;
               }
            });
            rows.push({
                id: order.id,
                data: [
                    moment(order.date).format('DD/MM/YYYY, h:mm:ss a'),
                    order.teller.username,
                    productString,
                    order.totalValue,
                    order.totalPoints,
                    order.store.name
                ],
                label: `Order id: ${order.id}`
            })
        });
        return rows;
    };

    const handleOrderRowSelect = (orderId) => {
        history.push('/orders/' + orderId)
    };

    const handleOfferRowSelect = (enabledOfferId) => {
        history.push('/enabled-offer/' + enabledOfferId)
    };

    const handleRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.clientOverview.totalOrders / rowsPerPage);
        let currentPage = props.clientOverview.ordersListPage;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeStoreSettings('ordersListRowsPerPage', rowsPerPage);
        props.changeStoreSettings('ordersListPage', currentPage);
    };

    const handlePageChange = (iconType) => {
        let newPage  = getTablePage(iconType, props.clientOverview.ordersListPage,  props.clientOverview.totalOrders, props.clientOverview.ordersListRowsPerPage);
        props.changeStoreSettings('ordersListPage', newPage);
    };

    const _submitClientExtraPoints = (evt) => {
        evt.preventDefault();
        props.giveClientExtraPoints({
            sendBody: {points: parseInt(props.clientOverview.clientExtraPoints)?
                    parseInt(props.clientOverview.clientExtraPoints):0},
            token: props.token,
            clientId: props.clientOverview.form.id
        });
    };

    const handleOfferListRowsPerPage = (rowsPerPage) => {
        rowsPerPage = parseInt(rowsPerPage);
        let newTotalPages = _.ceil(props.enabledOffers.length / rowsPerPage);
        let currentPage = props.clientOverview.offersListRowsPerPage;
        if(parseInt(currentPage) > newTotalPages ){
            currentPage = newTotalPages;
        }

        props.changeStoreSettings('offersListRowsPerPage', rowsPerPage);
        props.changeStoreSettings('offersListPage', currentPage);
    };

    const handleOfferListPageChange = (iconType) => {
        let newPage  = getTablePage(
            iconType,
            props.clientOverview.offersListPage,
            props.enabledOffers.length,
            props.clientOverview.offersListRowsPerPage
        );
        props.changeStoreSettings('offersListPage', newPage);
    };

    const onRequestSortOrders = (payload) => {
        props.changeClientsOrdersOrder(payload);
    };

    const handleClientDelete = () => {
        props.deleteClientById({
            token: props.token,
            clientId: props.clientOverview.form.id
        })
    };

    return (
        <ClientOverview
            _submitClientExtraPoints={_submitClientExtraPoints}
            changeClientOverviewStoreSettings={props.changeStoreSettings}
            postingClientExtraPointsFailMessage={props.clientOverview.postingClientExtraPointsFailMessage}
            postingClientExtraPointsSuccessMessage={props.clientOverview.postingClientExtraPointsSuccessMessage}
            clientExtraPoints={props.clientOverview.clientExtraPoints}
            activeTab={props.clientOverview.activeTab}
            setClientOverviewActiveTab={props.setClientOverviewActiveTab}
            page={props.clientOverview.ordersListPage}
            totalRows={props.clientOverview.totalOrders}
            handlePageChange={handlePageChange}
            rowsPerPage={props.clientOverview.ordersListRowsPerPage}
            handleRowsPerPage={handleRowsPerPage}
            handleOrderRowSelect={handleOrderRowSelect}
            orderRows={getOrderTableRows()}
            orderTableColumns={orderTableColumns}
            form={props.clientOverview.form}
            offersTableRows={getOffersTableRows()}
            offersTableColumns={offersTableColumns}
            offersListRowsPerPage={props.clientOverview.offersListRowsPerPage}
            offersTotalRows={props.enabledOffers.length}
            offersListPage={props.clientOverview.offersListPage}
            handleOfferListRowsPerPage={handleOfferListRowsPerPage}
            handleOfferListPageChange={handleOfferListPageChange}
            orderOrdersBy={props.clientOverview.orderOrdersBy}
            ordersOrder={props.clientOverview.ordersOrder}
            onRequestSortOrders={onRequestSortOrders}
            handleClientDelete={handleClientDelete}
            deletingClient={props.deletingClient}
            handleOfferRowSelect={handleOfferRowSelect}
            match={props.match}
            getEnabledOffers={props.getEnabledOffers}
            userRole={props.userRole}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        categories: parseCategories(state.categories).all,
        clientOverview: parseClientOverview(state.clientOverview),
        enabledOffers: parseEnabledOffers(state.enabledOffers).enabledOffers,
        token: parseLogin(state.login).userInfo.token,
        userRole: state.login.getIn(['userInfo', 'role', 'name']),
        deletingClient: parseClients(state.clients).deleting,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getClientById: (clientId, token) => {
            dispatch(getClientById(clientId, token))
        },
        changeStoreSettings: (key, value) => {
            dispatch(changeClientOverviewStoreSettings(key, value))
        },
        setClientOverviewActiveTab: (payload) => {
            dispatch(setClientOverviewActiveTab(payload))
        },
        giveClientExtraPoints: (payload) => {
            dispatch(giveClientExtraPoints(payload))
        },
        getClientOrders: (payload) => {
            dispatch(getClientOrders(payload))
        },
        countClientOrders: (payload) => {
            dispatch(countClientOrders(payload))
        },
        changeClientsOrdersOrder: (payload) => {
            dispatch(changeClientsOrdersOrder(payload))
        },
        deleteClientById: (payload) => {
            dispatch(deleteClientById(payload))
        },
        getEnabledOffers: (payload) => {
            dispatch(getEnabledOffers(payload))
        },
        resetClientOverviewState: (payload) => {
            dispatch(resetClientOverviewState(payload))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientOverviewHOC);

