import React, { Component, Fragment, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import AppleIcon from "../../../assets/images/apple.png";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NewsletterBuilder from "../EmailBuilder/newsletterBuilder";
import EmailBuilderModal from "./emailBuilderModal";
import "../../../styles/newsletterBuilder.css";
import usePrevious from "../../../hooks/usePrevious";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
  stepContent: {
    overflow: "visible",
  },
  cardWrapper: {
    overflow: "visible",
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

const EmailModal =(props) =>{

 const [emailTemplate,setTemplate]= useState("");
 const [modalState, setModalState] = useState(false);

 const prevEmailTemplate = usePrevious(emailTemplate);

 const toggleModalState = () =>{
  //  setTemplate(props.emailState.emailContent);
  setModalState(!modalState);
 }
 const saveEmailTemplate = (output) => {
  var email= "<html><body>" + output + "</body></html>"

  setTemplate(output);
  
 }

 useEffect(() => {
   if(prevEmailTemplate!=emailTemplate && emailTemplate!=null)
   {
    //  var emailObject = {
    //   target:{
    //     name:"emailContent"
    //   } 
    //  }
     var email= "<!doctype html><html><body>" + emailTemplate + "</body></html>"
    //  props.inputChange(emailObject,email);
}
if(props.emailContent.length != emailTemplate.length )
{
  setTemplate(props.emailContent);
}
},[emailTemplate,prevEmailTemplate,props.emailContent]);
 return (

<Dialog
                open={props.emailModalOpen}
                // open={true}


                onClose={props.toggleEmailModal}
                fullWidth={true}
                 maxWidth="md"
              >
                <DialogTitle > {"Campaign newsletter"}
                </DialogTitle>
                <DialogContent  >
               
<div style={{ display: "flex",justifyContent: 'space-evenly'}}>

                
<div style={{ display: "flex",flexDirection:'column', justifyContent: 'space-between', marginLeft:50}}>
                        <TextField
                          value={
                            props.emailFrom
                          }
                          id="outlined-textarea"
                          label="Email Sender"
                          type="text"
                          name="emailFrom"
                          onChange={(evt)=>props.inputChange(evt,evt.target.value)}
                          variant="outlined"
                          style={{ width: 300 }}
                          inputProps={{ maxLength: 200 }}
                          error={props.emailFrom.trim() === ""}
                          helperText={props.emailFrom.trim() === "" ? 'Required field' : ''}
                        ></TextField>
                        <p></p>
                      <TextField
                        value={props.emailSubject}
                        id="outlined-textarea"
                        label="Email subject"
                        type="text"
                        name="emailSubject"
                        onChange={(evt)=>props.inputChange(evt,evt.target.value)}
                        multiline
                        rows={2}
                        variant="outlined"
                        style={{ width: 300, maxHeight: 80 }}
                        inputProps={{ maxLength: 200 }}
                          error={props.emailSubject.trim() === ""}
                helperText={props.emailSubject.trim() === "" ? 'Required field' : ''}
                      ></TextField>
                  
                  <div style={{marginTop:15}}>
                        <Typography
                          variant="subheading">
                          Send on</Typography>
                          <input
                            type="date"
                            id="start"
                            name="emailDate"
                            min={props.campaignNewForm.formData.startDate}
                            max={props.campaignNewForm.formData.endDate}
                            value={props.emailDate}
                            onChange={(evt)=>props.inputChange(evt,evt.target.value)}
                            style={{ maxWidth: 150 }}
                          />
                          <input
                            type="time"
                            name="emailTime"

                            onChange={(evt)=>props.inputChange(evt,evt.target.value)}
                            value={props.emailTime}
                            style={{ maxWidth: 150,  }}
                          />
                        </div>
                        <p></p>
                        <Typography
                          variant="subheading">
                          Content <br></br>
                           <Button   style={{backgroundColor:'#F1CB42'}}
                           onClick={toggleModalState}>{emailTemplate!=''?"Edit email":'Design email'}</Button>
                           
                          
                        {emailTemplate!=null && emailTemplate!="" && <CheckCircleIcon style={{color:'green'}}/>}
                        </Typography>
                        {emailTemplate!=null && emailTemplate!="" &&
                        <div>
                        <Button   style={{backgroundColor:'#F1CB42'}}
                           onClick={() => props.sendEmail(emailTemplate,props.testEmailAddress)}>Send Test email</Button>

                          <TextField
                          value={
                            props.testEmailAddress
                          }
                          id="outlined-textarea"
                          label="Send test to:"
                          name="testEmailAddress"
                          onChange={(evt)=>props.inputChange(evt,evt.target.value)}
                          variant="outlined"
                          style={{ width: 250 ,marginLeft:10}}
                        //   inputProps={{ maxLength: 200 }}
                          error={!props.testEmailAddress.includes("@") && props.testEmailAddress.trim().length>0 }
                        ></TextField>
                        {props.campaignNewForm.sendingTest && <CircularProgress style={{maxHeight:20,maxWidth:20}}/>}
                          </div>}

                </div>
               
               
                {/* {emailTemplate!=null &&
                 <div style={{display:'flex',flexDirection:'column'}}>
                 <Typography variant="subheading">Preview</Typography>
                  <iframe className="preview" src={'data:text/html;charset=utf-8,'+Buffer.from(`<body style={{transform:"scale(0,7)"}}>`+emailTemplate+`</body>`).toString('utf-8')} style={{width:360,height:300,border:'3px'}}>
                    
                  </iframe>
                    </div>         
                 } */}
                        </div>
                        
                <EmailBuilderModal 
                {...props}
                modalState={modalState}
                toggleModalState={toggleModalState}
                saveEmailTemplate={saveEmailTemplate}
                emailTemplate={emailTemplate}
                inputChange = {props.inputChange}
                utms= {""}
                />
                
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={props.toggleEmailModal}
                    color="secondary"
                    name="Cancel"
                  >
                    Cancel
                  </Button>
                  <Button
                   style={{backgroundColor:'#F1CB42'}}
                    autoFocus
                    name="Save"
                    onClick={props.toggleEmailModal}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
    )
              }

export default withStyles(styles)(EmailModal);
