import React from 'react';
import '../../styles/pagination.css'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";
import FirstPage from "@material-ui/icons/FirstPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import LastPage from "@material-ui/icons/LastPage";


const Pagination = (props) => {
    let page = parseInt(props.page);
    let rowsPerPage = parseInt(props.rowsPerPage);
    let start = (page - 1) * (rowsPerPage) + 1;
    let end = ((page - 1) * (rowsPerPage) + rowsPerPage) > parseInt(props.totalRows)? props.totalRows : ((page - 1) * (rowsPerPage) + rowsPerPage);
    return (
        <div className="pagination">
            <div className="pagination__rowsPerPage">
                <span >Rows per page</span>
                <Select value={props.rowsPerPage}
                        className={'pagination__amount-menu'}
                        onChange={(evt) => props.handleRowsPerPage(evt.target.value)}>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                </Select>
            </div>
            {
                props.totalRows?
                    <span >{start + ' - ' + end + ' of ' + props.totalRows}</span>
                    :
                    <span >{'0' + ' - ' + end + ' of ' + props.totalRows}</span>
            }
            <div className="pagination__icons">
                <IconButton className={'pagination__icons__icon'} onClick={() => props.handlePageChange('firstPage')}>
                    <FirstPage />
                </IconButton>
                <IconButton className={'pagination__icons__icon'} onClick={() => props.handlePageChange('leftPage')}>
                    <ChevronLeft />
                </IconButton>
                <IconButton className={'pagination__icons__icon'} onClick={() => props.handlePageChange('rightPage')}>
                    <ChevronRight />
                </IconButton>
                <IconButton className={'pagination__icons__icon'} onClick={() => props.handlePageChange('lastPage')}>
                    <LastPage />
                </IconButton>
            </div>
        </div>
    )
};

export default Pagination;